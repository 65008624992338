import React from 'react';

import { MobileView } from 'react-device-detect';

import { RiMailCheckFill } from 'react-icons/ri';
import { GoCheck } from 'react-icons/go';

import Utils from '../../../providers/Utils';
import Ws from '../../../providers/Ws';
import LocalStorage from '../../../providers/LocalStorage';

import MServiceNav from '../../../commons/MServiceNav';
import MSwitch from '../../../commons/MSwitch';
import TextareaDescription from '../../../commons/TextareaDescription';

import BirdAndPlantImage from '../../../assets/bird_and_plant_mobile.png';
import SuccessSaveToast from '../../../commons/SuccessSaveToast';
import ErrorToast from '../../../commons/ErrorToast';
import ErrorMessage from '../../../commons/ErrorMessage';
import ServicesFuncs from '../../logic/ServicesFuncs';
import SvEmailReview from '../../../commons/service/SvEmailReview';
import Loader from '../../../commons/Loader';
import Declarations from '../../../providers/Declarations';

export default class MReviewMail extends React.Component{

    constructor(props){

        super(props)

        this.state = {

            detaliiServiciu: {},

            showToastPopup: false,
            showToastPopupError: false,
            actionTx: '',
            eroareTx: '',

            hasReviewStatus: false,
            reviewDescStatus: false,
            triggerSave: false,

            isLoading: true
        }
    }

    WS = new Ws();
    UT = new Utils();
    SVF = new ServicesFuncs();

    componentData = new Declarations().serviceData['review'];

    componentStatus = {

        isReviewOk: true
    };

    errorMessage = '';

    componentDidMount(){

        if(this.props.match.params.tk.length > 10){

            this.getData(); 
        }

        window.scrollTo(0,0);
    }

    getData = async () =>{

        const TOKEN_CONT = new LocalStorage().GetAccountToken();

        let q1 = `select * from services where account_tk = '${TOKEN_CONT}' and token = '${this.props.match.params.tk}' `;

        let _dateServiciu = this.WS.sqlCommand(`select`, q1);

        let [dateServiciu] = await Promise.all([_dateServiciu])

        if(dateServiciu.success){

            // alert('Ayaya!')

            if(dateServiciu.content.length === 0){

                this.setState({

                    eroareTx: 'Serviciu inexistent.',
                    isLoading: false
                });

                return;
            }

            this.componentData = new Declarations().updateComponentsData(this.componentData, dateServiciu.content[0]);
            
            this.setState({

                detaliiServiciu: dateServiciu.content[0],
                isLoading: false
            },
                () => {
                    
                }
            )

            
        }
        else{

            this.setState({

                showToastPopupError: true,
                actionTx: 'Eroare preluare date review serviciu.'
            });

            throw new Error('eroare server response:  ' + dateServiciu.message)
        }
    }

    checkSaveButtonStatus = () => {

        let status = null;

        if(!this.state.hasReviewStatus && !this.state.reviewDescStatus){

            status = false;
        }
        else{

            status = true;
        }

        this.setState({

            canSave: status
        });
    }

    inputStatus = (status, statusName, inputName, data, isVerify = false) => {

        if(isVerify){

            this.componentStatus[statusName] = data;
        }
        else{

            this.componentData[inputName] = data;

            
    
            this.setState({
                
                [statusName]: status
            },
                () => {
    
                    this.checkSaveButtonStatus();
                }
            );
        }
    }

    saveToDb = async (result) => {

        const token = this.UT.generateToken(); //used only when creating a service
        const TOKEN_CONT = new LocalStorage().GetAccountToken();
        // let userData = await this.WS.getUserData()

         if (this.props.match.params.tk.length >= 10){

            // alert("Modific!")
            let uq = this.SVF.onSaveServiceData(result, false, this.props.match.params.tk);
             
            

            let updateServiciu = await this.WS.sqlCommand(`update`, uq.data.substr(1, uq.data.length - 2));

            if(updateServiciu.success){

                // alert("Serviciu Modificat cu Succes!");
                this.setState({

                    canSave: false,
                    showToastPopup: true,
                    actionTx: 'a fost actualizata cu succes!',

                    hasReviewStatus: false,
                    reviewDescStatus: false,
                    triggerSave: false
                },
                
                    async () => {

                        await this.getData();
                    }
                );
            }
            else{

                this.setState({

                    showToastPopupError: true,
                    actionTx: 'Eroare actualizare date review serviciu.'
                });

                throw new Error('Eroare Server Response:  ' + updateServiciu.message)
            }
        }
        
    }

    onAddErrorMessage = (errorTx) => {

        this.errorMessages = errorTx;
    }

    onCheckAndSave = async (result) => {

        if(this.componentStatus['isReviewOk']){

            const queryArr = new Declarations().generateQuerySet('service', this.componentData)['review'];

            await this.saveToDb(queryArr);
        }
        else{

            this.setState({

                triggerSave: false,
                showToastPopupError: true,
                actionTx: this.errorMessages
            });
        }
    }

    showToastError = (errorTx) => {

        this.setState({

            showToastPopupError: true,
            actionTx: errorTx
        });
    }

    onTriggerSave = () => {

        this.setState({

            triggerSave: true
        },
            
            () => {

                this.onCheckAndSave();
            }
        );
    }

    onClosePopup = (type) => {

        this.setState({

            [type]: false
        })
    }

    render(){

        return(
            <MobileView>

            {
                this.state.showToastPopupError && (

                    <ErrorToast 
                        action={this.state.actionTx}
                        onClosePopup = {this.onClosePopup}
                    />
                )
            }


            {
                this.state.showToastPopup && (

                    <SuccessSaveToast 
                        onClosePopup = {this.onClosePopup}
                        saveName = 'Functionalitatea review'
                        action = {this.state.actionTx}
                    />
                )
            }

            <div 
                className="rootable-page"
                style={{
                    position: 'relative',
                    minHeight:  window.screen.height <= 568 ? 'calc(100vh + 60px)' : '100vh',
                    background: `url(${BirdAndPlantImage}) no-repeat`,
                    backgroundPosition: 'center bottom 20px'
                }}
            >

                <div
                    style={{
                        position: 'relative'
                    }} 
                >

                    <MServiceNav 
                        icon={<RiMailCheckFill />}
                        title='Cerere Review'
                        path={'/ca/dashboard/cab/msv/'+this.props.match.params.tk}
                    />

                    {
                        this.state.canSave &&
                        <GoCheck
                            className="m-add-service"
                            onClick={this.onTriggerSave}
                        />
                    }

                </div>

                {
                    this.state.isLoading && (

                        <Loader 
                            fullPage = {true}
                        />
                    )
                }

                {
                    !this.state.isLoading && (

                        <div
                            style={{
                                padding: 20
                            }}
                        >   
                            
                            {
                                this.state.eroareTx.length > 0 && (
                                    <div 
                                        className="m-edit-s-card"
                                        style={{
                                            marginTop: 20
                                        }}
                                    >
                                        <ErrorMessage 
                                            eroareTx = {this.state.eroareTx}
                                        />
                                    </div>
                                )
                            }
                        
                            {
                                this.state.eroareTx.length === 0 && 
                                (

                                    <SvEmailReview 
                                        triggerSave = {this.state.triggerSave}
                                        serviceDetails = {this.state.detaliiServiciu}
                                        inputStatus = {this.inputStatus}
                                        showToastError = {this.showToastError}
                                        onAddErrorMessage = {this.onAddErrorMessage}
                                    />
                                )
                            }  

                        </div>
                    )
                }

            </div>

                {/* <div
                    style={{
                        width: '100%',
                        position: 'fixed',
                        left: 0,
                        bottom: 0
                    }}
                >

                    <img 
                        src={BirdAndPlantImage}
                        alt="Bird and plant..."
                        style={{
                            display: 'block',
                            margin: 'auto'
                        }}
                    />

                </div> */}

            </MobileView>
        )
    }
}