import LocalStorage from "./LocalStorage";
import { async } from "q";
// import Utils from '../providers/Utils';
export default class Ws {

    //loginServerAddress = 'http://192.168.100.2:4444/api/'

    // SET LIVE WEB-APP
    // loginServerAddress = 'http://89.44.121.117:11000/api/'
    // filesAddress = 'http://89.44.121.117:11000/Storage'


    // loginServerAddress = 'http://89.44.121.117:10000/api/'
    // filesAddress = 'http://89.44.121.117:10000/Storage'

    // loginServerAddress = 'http://localhost/api/'
    // filesAddress = 'http://localhost/Storage'

    LS = new LocalStorage();

    // serverAddress = "http://192.168.100.2:36000/api/"
    // storageAddres = "http://192.168.100.2:36000/Storage/"

    // before cloudflare
    // serverAddress = "http://89.44.121.117:32000/api/"
    // storageAddres = "http://89.44.121.117:32000/Storage/"

    // AYAYA serverAddress = "http://89.44.121.117:12000/api/"


    // serverAddress = "http://89.44.121.117:12000/api/"
    serverAddress = "https://plannarybk.auths.online/api/"
    storageAddres = "https://plannarybk.auths.online/Storage/Avatars/"
    

    appKey = "8dhsa90jcdu9r03hfjdhfu09ehvjdsruefnhh90h";




    // Select = async (tabela: string, cols: any, where: any, activeLog = null) => {

    //     // full obeject here:

    //     // let fullPostObject = {
    //     //     Scope:'',
    //     //     Ref:'',
    //     //     Table:'',
    //     //     Cols:'',
    //     //     Where:'',
    //     //     CustomQuery:'',
    //     //     CustomParams:''
    //     // }

    //     let pObj = {
    //         Scope: 'Select',
    //         Table: tabela,
    //         Cols: cols,
    //         Where: where
    //     }

    //     return await this.postData('Crud/Operations', pObj, null, activeLog)
    // }

    // Select = async (tabela: string, cols: string, where: any, activeLog = null) => {
    //     let pObj = {
    //         Scope: 'Select',
    //         Table: tabela,
    //         Cols: cols,
    //         Where: where
    //     }
    //     return await this.postData('Crud/Operation',pObj,null,activeLog)
    // }

    // CustomSelect = async (query: any, params: any, log = null) => {
    //     let toPost = {
    //         Scope: 'CustomSelect',
    //         CustomQuery: query,
    //         CustomParams: params
    //     }

    //     return await this.postData('Crud/Operations', toPost, null, log)
    // }

    // Insert = async (table: string, cols: any, log = null) => {
    //     let toPost = {
    //         Scope: 'Insert',
    //         Table: table,
    //         Cols: cols
    //     }

    //     return await this.postData('Crud/Operations', toPost, null, log)
    // }

    // Update = async (table: string, cols: any, where: any, log = null) => {
    //     let toPost = {
    //         Scope: 'Update',
    //         Table: table,
    //         Cols: cols,
    //         Where: where
    //     }

    //     return await this.postData('Crud/Operations', toPost, null, log)
    // }

    // LogActiuneUser = async (modul: string, actiune: string, desc: string, note: any, id: number, ruta: string) => {
    //     let postObject = {
    //         Modul: modul,
    //         Actiune: actiune,
    //         Descriere: desc,

    //         Note: note,
    //         IdOperat: id,
    //         Ruta: ruta
    //     }

    //     let rezLog = await this.postData('User/LogActiune', postObject)
    //     
    // }

    sqlCommand = async (tip,command) => {
        let userToken = await this.getUserToken()
        
        
        let addr = `${this.serverAddress}Jsrunner/TotalCommand/?apiKey=${this.appKey}&tip=${tip}`
        addr = encodeURI(addr)

        let r = await fetch(addr, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
                // 'Content-Type': 'text/plain'
            },
            body: JSON.stringify(command)
        })

        if (r.status === 200) {
            let rj = await r.json(); // pentru tratarea de exceptii cu mesaje punctuale in 
            return rj // fiercare pagina
        }
        else {
            console.error("POST SERVER COMMAND STATUS ERR", r)
            return null
        }

    }

    postData = async (adresa, postObject, params = null, activeLog = null) => {

        let userToken = await this.getUserToken()

        let addr = `${this.serverAddress}${adresa}?apiKey=${this.appKey}&userToken=${userToken}`

        if (params) {
            for (let k in params) {
                addr += '&' + k + '=' + params[k]
            }
        }

        addr = encodeURI(addr)

        if (activeLog) {
            
            
        }

        let r = await fetch(addr, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
                //  'Content-Type': 'text/plain'
            },
            body: JSON.stringify(postObject)
        })

        

        if (activeLog) {
            
            let rjs = await r.text()
            
        }

        if (r.status === 200) {
            let rj = await r.json(); // pentru tratarea de exceptii cu mesaje punctuale in 
            return rj                     // fiercare pagina
        }
        else {
            console.error("POST SERVER STATUS ERR", r)
            return null
        }

    }

    getData = async (adresa, params = null, activeLog = null) => {

        let userToken = await this.getUserToken()

        

        if (userToken) {
            let addr = `${this.serverAddress}${adresa}?apiKey=${this.appKey}&userToken=${userToken}`

            if (params) {
                for (let k in params) {
                    addr += '&' + k + '=' + params[k]
                }
            }

            addr = encodeURI(addr)

            if (activeLog) {
                
            }

            let r = await fetch(addr)

            if (activeLog) {
                
                let rjs = await r.text()
                
            }

            if (r.status === 200) {
                return await r.json(); // pentru tratarea de exceptii cu mesaje punctuale in fiecare pagina

            }
            else {
                console.error("GET SERVER STATUS ERR", r)
                return null
            }

        }
        else {
            console.error("userData is null ? server ? token ?")
            return null
        }
    }

    // login = async (u: string, p: string) => {
    //     if (u && p) {
    //         let addr = this.loginServerAddress + 'Login/Loginup/?u=' + u + '&p=' + p
    //         addr = encodeURI(addr)

    //         
    //         let r = await fetch(addr)
    //         

    //         if (r.status === 200) {
    //             let rJson = await r.json();
    //             if (rJson.Succes) {
    //                 return rJson.Content
    //             }
    //             else {
    //                 console.error("LOGIN SERVER RESPONSE ERR", rJson.Messsage)
    //                 return null
    //             }
    //         }
    //         else {
    //             console.error("LOGIN SERVER STATUS ERR", r)
    //             return null
    //         }
    //     }
    // }

    getUserData = async ()=>{
        let userData = await this.LS.GetUserData();
        if (userData) {
            return userData;
        }
        return null
    }


    getUserToken = async () => {

        let userData = await this.LS.GetUserData();
        // 
        if (userData) {
            let userToken = userData["userData"][0]['wrk_token'];
            return userToken;
        }
        return ''

    }



    uploadFile = async(adresa, filesList, params, activeLog=null )=>{

        let userToken = await this.getUserToken()

        let addr = `${this.serverAddress}${adresa}?apiKey=${this.appKey}&userToken=${userToken}`

        if (params) {
            for (let k in params) {
                addr += '&' + k + '=' + params[k]
            }
        }

        addr = encodeURI(addr)

        //
        const files = Array.from(filesList)
        const formData = new FormData()

        files.forEach((file, i) => {
            formData.append('files', file, file.name)
        })

        if(activeLog){
            
            
        }

        let r = await fetch(addr,{
            method:'POST',
            // headers: {
            //     'Accept': 'application/json',
            //     'Content-Type': 'application/json'
            //   },
            body:formData 
        })

        if(activeLog){
            
            let rjs = await r.text()
            
        }

        if(r.status === 200){
            return await r.json(); // pentru tratarea de exceptii cu mesaje punctuale in 
                                    // fiercare pagina
        }
        else{
            console.error("uploadFile SERVER STATUS ERR" , r)
            return null
        }
    }


    generateStaticProvider = (filePath) =>{

        //let a = `http://89.44.121.117:32000/api/staticprovider/getfile/?filepath=Storage\\Masini\\Documente\\${f}`

        //storageAddres = "http://89.44.121.117:32000/Storage/"

        return this.storageAddres.replace(`Storage/`,`api/staticprovider/getfile/?filepath=${filePath}`)


    }
}