import React from 'react';
import Masks from '../providers/Masks';
import Utils from '../providers/Utils';

export default class AppointmentHourSelect extends React.Component{

    constructor(props){

        super(props);
    }

    UT = new Utils();
    M = new Masks();

    render(){

        return(

            <>
            
                {
                    this.props.daySelectedSchedule.map((csEl, idx) => {

                        let currentDay = new Date();
                        currentDay.setHours(0, 0, 0, 0);
                        let selectedDate = this.M.sqlDateToJSDate(this.props.dateSelected);
                        selectedDate.setHours(this.UT.convertToTime(csEl['ora']).getHours(), 
                                                this.UT.convertToTime(csEl['ora']).getMinutes(), this.UT.convertToTime(csEl['ora']).getSeconds())

                        let diffHours = 1;

                        // if(this.M.sqlDateToJSDate(this.state.dateSelected).getTime() === currentDay.getTime()){

                        //     // diffHours = parseInt(this.UT.convertToTime(csEl['ora']).getHours() - new Date().getHours())
                        //     diffHours = Math.abs( new Date().getHours() - this.UT.convertToTime(csEl['ora']).getHours() );
                        //     
                        // }

                        diffHours = ( new Date().getTime() - selectedDate.getTime() ) / 1000;

                        diffHours /= (60 * 60);
                        diffHours = Math.abs(Math.round(diffHours));

                        // 
                        // parseInt(this.props.serviceDetails['break_before']) +
                        // parseInt(this.props.serviceDetails['break_after']) ) )

                        if(csEl.ora === this.props.confirmHour){

                            return(

                                <div 
                                    key={'schd-hour-'+idx}
                                    className="select-hour selected"
                                    onClick={this.props.onConfirmHour}
                                >
                                    
                                    <p  
                                        
                                        style={{
                                            position: 'relative',
                                            margin: 0,                                    
                                            fontSize: '12px' 
                                        }}
                                    >

                                        Confirma

                                    </p>

                                    <p  
                                        
                                        style={{
                                            position: 'relative',
                                            margin: 0,
                                            fontSize: '16px' 
                                        }}
                                    >

                                        {csEl.ora}

                                    </p>

                                </div>
                            )
                        }
                        else if( ( +csEl.disponibilitate >= (parseInt(this.props.serviceDetails.duration) + 
                        // parseInt(this.props.serviceDetails['break_before']) +
                        parseInt(this.props.serviceDetails['break_after']) ) ) && (diffHours > 12)
                        
                        ){
                        
                            return(

                                <div 
                                    key={'schd-hour-'+idx}
                                    className="select-hour"
                                    onClick={
                                        () => {
                                            this.props.onSelectHour(csEl.ora)
                                        }
                                    }
                                >

                                    <p  
                                        
                                        style={{
                                            position: 'relative',
                                            margin: 0,
                                            top: 1,
                                            color: '#455A64',
                                            fontSize: '16px' 
                                        }}
                                    >

                                        {csEl.ora}

                                    </p>

                                </div>
                            )
                        }
                        else{

                            return(

                                <div 
                                    key={'schd-hour-'+idx}
                                    className="select-hour"
                                    style={{
                                        cursor: 'default'
                                    }}
                                >

                                    <p  
                                        
                                        style={{
                                            position: 'relative',
                                            margin: 0,
                                            top: 1,
                                            color: '#455A64',
                                            fontSize: '16px' 
                                        }}
                                    >

                                        <strike>{csEl.ora}</strike>

                                    </p>

                                </div>
                            )
                        }

                    }, this)
                }
            </>
        )
    }
}