import React from 'react';

import './Commons.css';

export default class LinkPreview extends React.Component{

    constructor(props){

        super(props);
    }

    render(){

        return(

            <>
            
            <div 
            >

                {/* <LinkPreview 
                    link={this.state.link}
                /> */}

                
                <p 
                    className="link-preview"
                    style={{
                        fontSize: this.props.fontSize || '12px'
                    }}
                >
                    Preview
                </p>

                {
                    !this.props.isAccLink && (

                        <p 
                            className="user-link" 
                            style={{
                                marginTop: -10,
                                fontSize: this.props.fontSize || '12px',
                                wordWrap: 'break-word'
                            }}
                        >
                            app.planary.ro/{this.props.accountLink}{this.props.link ? '/' : ''}<span>{this.props.link}</span>
                        </p>
                    )
                }

                {
                    this.props.isAccLink && (

                        <p 
                            className="user-link" 
                            style={{
                                marginTop: -10,
                                fontSize: this.props.fontSize || '12px',
                                wordWrap: 'break-word'
                            }}
                        >
                            app.planary.ro/<span>{this.props.accountLink}</span>
                        </p>
                    )
                }

                {
                    !this.props.hideOptions && (

                        <div 
                            className="link-options-wrapper" 
                            style={{
                                marginTop: this.props.commandsMargin || -10
                            }}
                        >

                            {
                                this.props.enableLinks && (

                                    <>
                                    
                                        <p 
                                            className="link-option"
                                            style={{
                                                color: '#44A5FF',
                                                fontSize: this.props.fontSizeCommands || '12px'
                                            }}
                                            onClick={this.props.onCopyLinkToClipBoard}
                                        >
                                            Copiaza Link
                                        </p>

                                        <p 
                                            className="link-option"
                                            style={{
                                                color: '#44A5FF',
                                                fontSize: this.props.fontSizeCommands || '12px'
                                            }}
                                            onClick={this.props.onShowToastSocial}
                                        >
                                            Share Link
                                        </p>

                                        <p 
                                            className="link-option"
                                            style={{
                                                color: '#44A5FF',
                                                fontSize: this.props.fontSizeCommands || '12px'
                                            }}
                                            onClick={this.props.onClickViewPage}
                                        >
                                            Vezi Pagina
                                        </p>
                                    
                                    </>
                                )
                            }

                            {
                                !this.props.enableLinks && (

                                    <>
                                    
                                        <p 
                                            className="link-option"
                                            style={{
                                                cursor: 'default',
                                                fontSize: this.props.fontSizeCommands || '12px'
                                            }}
                                        >
                                            Copiaza Link
                                        </p>

                                        <p 
                                            className="link-option"
                                            style={{
                                                cursor: 'default',
                                                fontSize: this.props.fontSizeCommands || '12px'
                                            }}
                                        >
                                            Share Link
                                        </p>

                                        <p 
                                            className="link-option"
                                            style={{
                                                cursor: 'default',
                                                fontSize: this.props.fontSizeCommands || '12px'
                                            }}
                                        >
                                            Vezi Pagina
                                        </p>
                                    
                                    </>
                                )
                            }

                        </div>
                    )
                }

            </div>

            </>
        );
    }
}