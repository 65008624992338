import React from 'react';

import ErrorMessage from '../../commons/ErrorMessage';
import PlanaryLogo from '../../assets/planary-logo-badge 1.png';

import Masks from '../../providers/Masks';
import Utils from '../../providers/Utils';
import Ws from '../../providers/Ws';

export default class TermsAndConditions extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            serviceData: {},
            errorTx: ''
        }
    }

    WS = new Ws();
    M = new Masks();
    UT = new Utils();

    componentDidMount(){

        this.getServiceTermsAndCoditions();
    }

    getServiceTermsAndCoditions = async () => {

        let selectService = `select * from services where account_tk = (select token from accounts where web_link = '${this.props.match.params.companyWebLink}' )
                                                    and web_link = '${this.props.match.params.serviceWebLink}'  `;
        
        let serviceData = await this.WS.sqlCommand(`select`, selectService);

        if(serviceData.success){

            if(serviceData.content.length === 0){

                this.setState({

                    errorTx: 'Serviciul nu exista! Va rugam sa reintroduceti link-ul.'
                });

                return;
            }

            this.setState({

                serviceData: serviceData.content[0]
            });
        }
        else{

            this.setState({

                errorTx: 'Termenii si conditiile serviciului nu au putut fi preluate de catre server.'
            });

        }
    }

    render(){

        return(

            <div className="tos-wrapper">
                
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <img 
                        src = {PlanaryLogo}
                        alt = 'Planary Logo'
                        style={{
                            width: '60px'
                        }}
                    />

                    <span className="tos-logo-tx">
                        Planary
                    </span>
                </div>

                {
                    this.state.errorTx.length > 0 && (

                        <div 
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100vh'
                            }}
                        >

                            <ErrorMessage 
                        
                                eroareTx = {this.state.errorTx}
                            />

                        </div>
                    )
                }

                {
                    this.state.errorTx.length === 0 && (

                        <div 
                        >
                            <br />
                            <br />

                            <span className="tos-h-title">
                                Termeni si Conditii Generale
                            </span>

                            <br />
                            <br />

                            <p className="tos-paragraph">
                                Bine aţi venit pe platforma Planary. Vă rugăm să citiţi cu atenţie Termenii şi Condiţiile de utilizare ale acestui website prezentate mai jos. 
                                Accesul/vizitarea acestui website de către dumneavoastră, precum și utilizarea serviciilor oferite prin intermediul prezentului website se supun 
                                Termenilor şi Condiţiilor de utilizare, implică acceptul explicit al dumneavoastră cu privire la acestea şi reprezintă întreaga înţelegere dintre părţi, 
                                în lipsa încheierii unui alt contract în acest sens. În situația Partenerilor Planary, prevederile contractului încheiat se vor completa cu Termenii si 
                                Condițiile de utilizare ale acestui website, în măsura în care acestea nu conțin prevederi contrare.
                                <br />
                                <br />
                                Compania Dragon Corp, în calitate de administrator al website-ului planary.ro, şi autor al conţinutului acestuia îşi rezervă dreptul de a schimba şi 
                                actualiza în orice moment conţinutul acestui website, prețurile afișate de parteneri, precum şi Politica de Confidenţialitate cat şi Termenii şi 
                                Condiţiile de folosire, fără nici un fel de notificare prealabilă. De aceea, vă rugăm să vizitaţi periodic această secţiune pentru a verifica Termenii 
                                şi Condiţiile pe care aţi agreat să le respectaţi. În nici un caz, echipa companiei nu va fi răspunzătoare pentru niciun fel de daune directe, indirecte, 
                                speciale, accidentale sau consecvente, inclusiv, dar fără a se limita la: pierderea datelor sau a profilului, care rezultă din utilizarea sau utilizarea 
                                defectuoasa a serviciilor de pe acest site, chiar daca echipa companiei sau un reprezentant autorizat a fost informat despre posibilitatea unor astfel de 
                                daune. Daca utilizarea de catre dvs. a serviciilor de pe acest site duce la necesitatea întreținerii, reparării sau corectarii rezultatului final, va 
                                asumati orice costuri aferente acestora. Compania nu va raspunde niciodata pentru serviciile de orice natură prestate de Partenerii înscriși pe 
                                platforma Planary, în mod direct sau în mod indirect, în cadrul afacerilor în care aceștia activeaza și nici pentru serviciile prestate de alti 
                                prestatori de servicii care utilizează platforma Planary.
                            </p>

                            <br />

                            <span className="tos-h-subtitle">
                                1. Cine suntem noi?
                            </span>

                            <br />
                            <br />

                            <p className="tos-paragraph">

                                Ne numim Dragon Corp, fiind o persoană juridică română, societate comerciala cu raspundere limitata, cu sediul in Constanta, bd. Ferdinand Nr 97, Bl B1. 
                                Ap.13, având CIF 39896675. Website-ul Planary accesibil pe https://planary.ro, platforma Planary accesibila pe https://app.planary.ro, brandul Planary si 
                                toate materialele de brand aferente sunt proprietatea Dragon Corp.
                                <br />
                                <br />
                                Pentru orice informații referitoare la activitatea noastră, ne puteți contacta la adresa e-mail : office@planary.ro

                            </p>

                            <br />

                            <span className="tos-h-subtitle">
                                2. Definiții
                            </span>

                            <br />
                            <br />

                            <p className="tos-paragraph">

                                <b>Compania</b>: S.C. Dragon Corp S.R.L, persoana juridica romana cu raspundere limitata, cu sediul in municipiul Constanta, Bd. Ferdinand Nr. 97, Bl. B1, 
                                Ap.13, orasul Constanta, având CIF 39896675.

                                <br />
                                <br />

                                <b>Planary / Site / Website / Aplicatie</b> : Se refera la website-ul si/sau aplicatia care se regasesc pe domeniul http://planary.ro si prezinta insemnele 
                                distincte respective;

                                <br />
                                <br />

                                <b>Partener</b>: Proprietari/administratori de companii, PFA-uri sau profesioniști (spre exemplu: psihologi, recruiteri, traineri etc) care dețin un cont pe platforma 
                                Planary.

                                <br />
                                <br />

                                <b>Utilizator</b>: clientul sau posibilul client care accesează și utilizează platforma Planary în scopul de a stabili, modifica sau anula programări la partenerii 
                                înscriși pe platforma Planary sau, după caz, în scopul de a deveni Partener prin încheierea unui contract cu Dragon Corp și deținerea unui cont pe platforma 
                                Planary.

                                <br />
                                <br />

                                <b>Serviciu</b>: reprezinta serviciul furnizat de Planary ce se refera în principal (insa fara a se limita) la:

                            </p>

                            <br />

                            <ul className="tos-paragraph">

                                <li>
                                    posibilitatea utilizatorilor de a se inscrie pe platforma Planary, devenind Parteneri și de a-și promova activitatea și serviciile în cadrul acesteia;
                                </li>

                                <li>
                                    posibilitatea utilizatorilor de a stabili, modifica, sau anula programări la Partenerii înscrisi pe platforma Planary;
                                </li>

                                <li>
                                    posibilitatea partenerilor deținători de cont business pe platforma Planary de a accesa și monitoriza în mod virtual programările stabilite în 
                                    contul lor precum și, activitatea angajaților (daca este cazul);
                                </li>

                                <li>
                                    orice alte beneficii rezultate în urma utilizarii funcțiilor platformei Planary.
                                </li>

                            </ul>

                            <br />

                            <p className="tos-paragraph">
                                <b>Serviciu terță parte</b>: agenții de publicitate, sponsori pentru concursuri, partenerii promoționali și de marketing și alte persoane care furnizează 
                                conținutul nostru sau ale căror produse sau servicii considerăm ca pot prezenta interes.

                                <br />
                                <br />

                                <b>Dispozitiv</b>: orice dispozitiv conectat la internet, cum ar fi un telefon, tabletă, computer sau orice alt dispozitiv care poate fi utilizat pentru a 
                                vizita site-ul și pentru a utiliza serviciile oferite.
                            </p>

                            <br />

                            <span className="tos-h-subtitle">
                                3. Aplicabilitate
                            </span>

                            <br />
                            <br />

                            <p className="tos-paragraph">
                                Termenii şi condiţiile prezentate sunt valabile şi se aplică pentru toate paginile site-ului din domeniul https://planary.ro şi pentru care compania are 
                                calitatea de autor şi administrator. Toate aceste pagini (aşa cum există ele în prezent sau vor fi ulterior implementate) precum şi website-ul 
                                https://planary.ro vor fi denumite generic, în mod individual sau colectiv Planary, Site şi/sau Site-uri în cele ce urmează. În cazul utilizării unui link 
                                pentru a naviga de la Planary la un alt website strain de domeniul https://planary.ro, termenii si conditiile Planary nu mai sunt aplicabile. Navigarea și 
                                interacțiunea pe orice alt website, inclusiv cele care au un link pe platforma Planary, sunt supuse regulilor si politicilor proprii ale website-ului în cauză. 
                                Astfel tertii pot folosi propriile cookie-uri sau alte metode pentru a colecta informații despre dvs, compania nefiind răspunzătoare pentru regulile și politicile 
                                proprii ale acestora. În cadrul prezentului document, cuvintele la singular includ pluralul, cuvintele la plural includ singularul, cuvintele care denotă genul 
                                masculin includ femininul, şi cuvintele care denotă genul feminin includ masculinul. Prin utilizarea site-ului https://planary.ro acceptați în mod expres și 
                                sunteți de acord cu „Termenii și condițiile” Planary.
                            </p>

                            <br />

                            <span className="tos-h-subtitle">
                                4. Drepturi de autor
                            </span>

                            <br />
                            <br />

                            <p className="tos-paragraph">
                                Compania este administratorul site-ului şi beneficiază de toate drepturile legale recunoscute autorilor, conform legislaţiei române şi internaţionale. 
                                În lipsa altor precizări, toate materialele, incluzând imagini, ilustraţii, design-uri, icoane, fotografii, clipuri audio/video, ş.a.m.d. sunt fie 
                                proprietatea companiei, partenerii contractuali având un drept de folosința asupra acestora în cadrul site-ului, fie proprietatea partenerilor 
                                contractuali, compania avînd un drept de folosință asupra acestora.
                            </p>

                            <br />

                            <span className="tos-h-subtitle">
                                5. Licenţe de utilizare
                            </span>

                            <br />
                            <br />

                            <p className="tos-paragraph">
                                Compania va acorda o licenţă limitată pentru a accesa, descarca, vizita și utiliza site-ul, fără posibilitatea de a-l modifica în lipsa unui acord din 
                                partea companiei. Această licenţă nu include:
                            </p>

                            <br />

                            <ul className="tos-paragraph">

                                <li>
                                    revânzarea sau utilizarea în scopuri comerciale a informaţiilor prezentate pe site;
                                </li>

                                <li>
                                    utilizarea tehnicilor tip data mining, roboţilor sau tehnologiilor similare care permit extragerea şi analiza datelor. Conţinutul site-ului, 
                                    integral şi/sau parţial, nu poate fi reprodus, duplicat, copiat, revândut, vizitat sau exploatat în scopuri comerciale fără acceptul scris 
                                    prealabil al companiei;
                                </li>

                            </ul>

                            <br />

                            <p className="tos-paragraph">
                                Compania va acorda o licenţă limitată, revocabilă şi ne-exclusivă de a vă crea un hyperlink către pagina home a site-ului atâta vreme cât acest link 
                                nu descrie compania sau un partener/afiliat al acestuia într-o manieră falsă, greşită sau jignitoare. Licenţa acordată nu va permite utilizarea în 
                                cadrul acestui hyperlink a mărcii Planary fără acordul prealabil scris al companiei. Planary poate opri (permanent sau temporar) furnizarea serviciului 
                                sau a oricăror caracteristici față de utilizator la discreția exclusivă a companiei, fără notificarea prealabilă a utilizatorilor sau a partenerilor. 
                                De asemenea, utilizatorul poate înceta să utilizeze serviciul fără notificarea prealabilă a Planary. Se agreează posibilitatea dezactivării contului de 
                                către Planary în mod discreționar, respectiv se agreează consecințele ce decurg din acest aspect: lipsa accesului la serviciu, la detaliile contului sau 
                                la orice fișiere sau materiale conținute în cont. Compania își rezervă dreptul de a modifica, suspenda sau întrerupe, temporar sau permanent, website-ul 
                                sau orice serviciu la care se conectează, cu sau fără notificare prealabilă și fără a fi răspunzător de eventualele prejudicii cauzate.
                            </p>

                            <br />

                            <span className="tos-h-subtitle">
                                6. Politica de Retur
                            </span>

                            <br />
                            <br />

                            <p className="tos-paragraph">
                                Partenerul care a platit un abonament Planary are dreptul să renunțe la abonament, respectiv să primească banii înapoi în termen de 14 (paisprezece) 
                                zile calendaristice de la achizitie sau prelungirea de abonament, fără a suporta alte costuri. Potrivit prevederilor OUG nr.34/2014, perioada de 
                                renunțare la un abonament expiră în termen de 14 (paisprezece) zile din ziua încheierii sau a prelungirii sale. Pentru a-si exercita dreptul de 
                                retragere partenerul trebuie să informeze compania cu privire la decizia sa de a se retrage din prezentul contract, utilizând o declarație neechivocă, 
                                de exemplu, o scrisoare trimisă prin poștă, fax sau e-mail. În cazul in care partenerul își exercita dreptul de retragere din contract în cadrul 
                                termenului legal de retragere din contract, acesta trebuie să returneze și eventualele cadouri care au însoțit serviciul respectiv. Compania va 
                                rambursa suma în maxim 30 (trei zeci) zile de la data informării de catre partener asupra deciziei sale de retragere din contract. Suma va fi 
                                returnata astfel: pentru comenzile achitate cu cardul de debit sau de credit – prin restituire în contul din care a fost efectuată plata.
                                <br />
                                <br />
                                Pentru orice detalii sau neclarități privind returul vă rugăm să ne contactați pe email la adresa office@planary.ro
                            </p>

                            <br />

                            <span className="tos-h-subtitle">
                                7. Condiţii de utilizare. Garanţii.
                            </span>

                            <br />
                            <br />

                            <p className="tos-paragraph">

                                Site-ul este furnizat de către companie pe o bază „aşa cum este” şi „atât cât este disponibil”. Compania nu garantează că site-ul, serverele pe care 
                                este găzduit, sau e-mailurile trimise de Platforma sunt fără viruşi sau alte componente informatice cu caracter potenţial dăunător. Utilizatorul 
                                foloseşte Site-ul pe riscul propriu, compania fiind libera de orice răspundere pentru eventualele daune directe sau indirecte cauzate de utilizarea 
                                sau accesarea/vizitarea site-ului sau ca urmare a utilizării informaţiilor de pe site. Compania nu este răspunzătoare de erorile sau omisiunile care 
                                pot interveni în redactarea sau prezentarea materialelor de pe site. Compania nu acordă nici un fel de garanţie pentru conţinutul şi utilizarea 
                                acestui site și nici pentru veridicitatea, caracterul complet, acuratetea, actualitatea, respectarea drepturilor de autor, legalitate, decență, 
                                calitate, preț, îndeplinirea obligațiilor, sau orice alt aspect al informațiilor publicate în cadrul siteului.
                                <br />
                                <br />
                                Serviciile prezentate pe site constituie oferte din partea partenerilor Planary. Compania nu este responsabila pentru serviciile oferite de 
                                partenerii Planary, în mod direct sau indirect, inclusiv, dar fără a ne limita la : acuratețe, veridicitate, caracter complet, actualitate, 
                                valabilitate, respectarea drepturilor de autor, legalitate, decență, calitate, preț, îndeplinirea obligațiilor, sau orice alt aspect al acestora. 
                                Compania nu își asumă răspunderea pentru serviciile partenerilor. Serviciile partenerilor și link-urile către acestea sunt furnizate exclusiv 
                                pentru a ușura procesul de selecție al utilizatorilor site-ului Planary, respectiv pentru a beneficia de o experiență facilă în alegerea partenerilor, 
                                fără a fi responsabil de utilizarea și accesarea acestora de către utilizatori. compania nu este responsabila şi nu oferă nicio garanţie în ce 
                                priveşte accesarea site-urilor care se află în proprietatea şi administrarea unor persoane terţe, chiar dacă Planary oferă informaţii despre acestea, 
                                întrucât aparţin unor parteneri Planary.
                                <br />
                                <br />
                                Orice site aparţinând altor persoane este complet independent de prezentul site şi vă asumaţi luarea tuturor măsurilor de protecţie în momentul 
                                accesării respectivelor site-uri fără posibilitatea de a atrage răspunderea compania pentru orice daună suferită datorită accesării lor. 
                                Nici un sfat oral sau informații scrise date de Planary sau de oricare dintre afiliații, angajații, ofițerii, directorii, agenții sau altii
                                asemenea, nu vor crea garanții.

                            </p>

                            <br />

                            <span className="tos-h-subtitle">
                                8. Securitate
                            </span>

                            <br />
                            <br />

                            <p className="tos-paragraph">
                                Efectuarea de operaţiuni neautorizate asupra acestui site şi tentativa de efectuare a acestora, incluzând dar fără a se limita la: utilizarea abuzivă, 
                                utilizarea frauduloasă, accesul neautorizat, modificarea, copierea de informaţii în vederea comercializării lor, blocarea accesului, ş.a.m.d., vor fi 
                                pedepsite conform legii. În cazul în care suspectaţi că, din diverse motive confidenţialitatea datelor dumneavoastră personale sau din categoria 
                                datelor tip „business contact” a fost compromisă, veţi notifica imediat compania, la adresa de e-mail: office@planary.ro.
                            </p>

                            <br />

                            <span className="tos-h-subtitle">
                                9. Comunicări
                            </span>

                            <br />
                            <br />

                            <p className="tos-paragraph">
                                Utilizatorii site-ului pot trimite comentarii, note, sugestii, idei sau observaţii cu privire la conţinutul şi structura site-ului, 
                                respectiv pot adresa întrebări atâta timp cât conţinutul acestora nu este ilegal, obscen, ameninţător, defăimător, care să afecteze viaţa privată, 
                                drepturile de proprietate intelectuală, ale Planary sau ale unor terţe părţi, şi care nu conţin viruşi, derulează campanii politice/electorale, 
                                solicitări comerciale, scrisori în lanţ, mesaje mail masă, sau orice altă formă de spam. Nu puteţi folosi o adresă de e-mail falsă sau să impersonaţi 
                                o altă persoană sau entitate. Planary îşi rezervă dreptul, dar nu şi obligaţia, de a îndepărta sau edita o astfel de informaţie transmisă. 
                                Prin trimiterea unei astfel de informaţii, dacă nu este altfel specificat, acordaţi Planary şi partenerilor/afiliaţilor săi licenţa/dreptul gratuit, 
                                ne-exclusiv, perpetuu, irevocabil şi sub-licenţiabil de a utiliza, reproduce, modifica, adapta, publica, traduce, deriva, distribui sau a le afişa pe 
                                orice fel de medii, în toată lumea. Acordaţi dreptul Planary şi partenerilor/afiliaţilor săi de a utiliza numele pe care l-aţi trimis în conjuncţie 
                                cu informaţia, dacă aceştia vor decide astfel. Declaraţi şi garantaţi că deţineţi sau controlaţi toate drepturile cu privire la informaţiile transmise, 
                                că acestea sunt corecte şi că utilizarea acestora nu intră în contradicţie cu Termenii şi Condiţiile de utilizare, că nu vor cauza daune către o terţă 
                                parte şi că veţi despăgubi integral compania sau partenerii/afiliaţii Planary pentru toate cererile în legatură cu prejudiciile rezultate din informaţia 
                                astfel transmisă. Orice feedback, comentarii, idei, îmbunătățiri sau sugestii furnizate de utilizator către Planary cu privire la site-ul web/aplicatie vor 
                                rămâne proprietatea exclusivă a Planary. Planary va fi liber să utilizeze, să copieze, să modifice, să publice sau să redistribuie sugestiile în orice scop 
                                și în orice mod, fără niciun credit sau nicio compensație.
                            </p>

                            <br />

                            <span className="tos-h-subtitle">
                                10. Minori
                            </span>

                            <br />
                            <br />

                            <p className="tos-paragraph">
                                Compania nu vinde produse şi/sau servicii care să fie cumpărate de către minori. Dacă sunteţi minor şi accesaţi site-ul o puteţi face doar dacă sunteţi 
                                sub supravegherea unui părinte sau a tutorelui legal.
                            </p>

                            <br />

                            <span className="tos-h-subtitle">
                                11. Drepturi de proprietate intelectuală
                            </span>

                            <br />
                            <br />

                            <p className="tos-paragraph">
                                Compania respectă drepturile intelectuale ale altora. Dacă consideraţi că munca dumneavoastră a fost copiată de o manieră care să încalce dreptul de 
                                proprietate intelectuală, vă rugăm adresaţi către Planary o notificare care să cuprindă:
                            </p>

                            <br />

                            <ul className="tos-paragraph">

                                <li>
                                    semnătură fizică sau electronică a persoanei autorizate să reprezinte pe posesorul dreptului de proprietate;
                                </li>

                                <li>
                                    o descriere a materialelor protejate de drepturi care apreciaţi că au fost încălcate;
                                </li>

                                <li>
                                    o indicare a locaţiei pe Site unde apreciaţi că aceste drepturi au fost încălcate;
                                </li>

                                <li>
                                    informații de contact, inclusiv: nume, prenume / denumire, adresa, numărul de telefon şi adresa e-mail a dumneavoastră;
                                </li>

                                <li>
                                    o declaraţie pe proprie răspundere conform căreia sunteţi convins că utilizarea în dispută nu a fost autorizată de deţinătorul drepturilor, ale 
                                    împuternicitului sau ale legii, că acţionaţi cu bună credinţă corectă şi că sunteţi deţinătorul drepturilor sau acţionaţi în numele său.
                                </li>

                            </ul>

                            <br />

                            <span className="tos-h-subtitle">
                                12. Servicii terțe
                            </span>

                            <br />
                            <br />

                            <p className="tos-paragraph">
                                Planary poate afișa, include sau pune la dispoziție conținutul unei terțe părți (inclusiv date, informații, aplicații și alte servicii sau produse) 
                                sau poate oferi link-uri către website-uri sau servicii terțe. Planary nu va fi responsabil pentru serviciile terțe, inclusiv, dar fără a se limita la: 
                                acuratețe, caracter complet, veridicitate, actualitate, valabilitate, respectarea drepturilor de autor, legalitate, decență, calitate, preț, 
                                îndeplinirea obligațiilor, sau orice alt aspect al acestora. Compania nu își asumă răspunderea pentru servicii terțe.
                            </p>

                            <br />

                            <span className="tos-h-subtitle">
                                13. Comunicările comerciale
                            </span>

                            <br />
                            <br />

                            <p className="tos-paragraph">
                                În eventualitatea desfășurării unor diferite campanii de promovare sau de informare în ceea ce privește activitatea companiei., precum și a 
                                partenerilor noștri, comunicările comerciale, de orice fel, vor fi transmise prin intermediul poștei electronice doar ulterior exprimării în 
                                prealabil a consimțământului dvs. expres pentru a primi asemenea comunicări. Vă puteți retrage consimțământul în orice moment, prin transmiterea 
                                unei simple cereri la următoarea adresă de e-mail : office@planary.ro.
                            </p>

                            <br />

                            <span className="tos-h-subtitle">
                                14. Răspundere
                            </span>

                            <br />
                            <br />

                            <p className="tos-paragraph">
                                Compania nu este responsabila pentru serviciile oferite de partenerii Planary, inclusiv, dar fără a ne limita la: acuratețe, veridicitate, caracter 
                                complet, actualitate, valabilitate, respectarea drepturilor de autor, legalitate, decență, calitate, preț, îndeplinirea obligațiilor, sau orice alt 
                                aspect al acestora. Compania nu își asumă răspunderea pentru serviciile partenerilor, aceștia fiind unici răspunzători pentru neîndeplinirea 
                                obligațiilor față de utilizator. În acest sens, utilizatorul se obligă să notifice partenerul pentru orice prejudiciu adus, compania nefiind ținuta să 
                                răspundă pentru acestea. În măsura în care nu contravine prevederilor legale, Planary nu este răspunzător pentru niciun fel de daune speciale, 
                                accidentale, indirecte sau consecutive (inclusiv, dar fără a se limita la : daune pentru pierderea contului, a datelor sau alte informații, pentru 
                                întreruperea afacerii, pentru vătămări corporale, pentru atingerea vieții private rezultate din sau în orice mod legate de utilizarea sau incapacitatea 
                                de a utiliza website-ul/aplicația, software-ul tertului si/sau hardware-ul tertului utilizat cu site-ul web/aplicatia), chiar dacă Planary a fost 
                                informat despre posibilitatea unor astfel de daune. 
                                <br />
                                <br />
                                Partenerii au obligația de a gestiona individual propria agendă, disponibilitate și orar, precum și programările, fiind unicii răspunzători pentru 
                                eventualele neconcordanțe sau omisiuni. Utilizatorul are obligația de a nu acorda recenzii, comentarii sau păreri negative, obscene, neadevărate, sau 
                                care conțin cuvinte jignitoare sau injurioase despre Planary sau companie, pe orice platformă, aplicație, website și în orice modalitate, în situația în 
                                care partenerii își încalcă obligațiile față de utilizator sau au un comportament neadecvat față de acesta. Planary își rezervă dreptul de a gestiona 
                                și/sau șterge orice recenzie, comentariu sau părere care îndeplinește condițiile anterior menționate și/sau de a apela la instanțele competente în 
                                vederea obligării utilizatorului la ștergerea acestora. Planary nu își asumă responsabilitatea în cazul în care un serviciu este listat la un preț 
                                incorect sau cuprinde informații incorecte din cauza unor erori dactilografice. 
                                <br />
                                <br />
                                Chestiunile ce țin de: anularea/modificarea programărilor, recalcularea prețului serviciilor prestate, despăgubirea clienților sau a profesioniștilor, 
                                ce pot rezulta din erori dactilografice, vor fi rezolvate de cele doua părți implicate (cea care presteaza serviciul si cea care beneficiaza de serviciu) 
                                după cum consideră acestea, fără implicații pentru Planary sau companie.
                            </p>

                            <br />

                            <span className="tos-h-subtitle">
                                15. Încetare
                            </span>

                            <br />
                            <br />

                            <p className="tos-paragraph">
                                Prezenta înțelegere dintre părți poate fi denunțată unilateral de către utilizator sau companie, fără alte formalități sau notificare prealabilă și fără 
                                intervenția instanței de judecată. Prezenta înțelegere poate înceta și prin ștergerea site-ului/aplicației și copiilor acestuia/acesteia de pe dispozitiv de 
                                către utilizator. Încetarea prezentei înțelegeri nu limitează dreptul companiei de a acționa în instanță în vederea recuperării oricărui prejudiciu produs sau 
                                în eventualitatea încălcării oricărei obligații a utilizatorului.
                            </p>

                            <br />

                            <span className="tos-h-subtitle">
                                16. Politica de confidenţialitate
                            </span>

                            <br />
                            <br />

                            <p className="tos-paragraph">
                                Vă rugăm să accesaţi şi secţiunea Politică de confidenţialitate care se aplică de asemenea accesului/vizitei dumneavoastră pe site.
                            </p>

                            <br />

                            <span className="tos-h-subtitle">
                                17. Legislaţie
                            </span>

                            <br />
                            <br />

                            <p className="tos-paragraph">
                                Termenii şi condiţiile de folosire se supun legislaţiei române. In caz de litigiu, se va încerca mai întâi o rezolvare pe cale amiabilă, în termen de 
                                30 de zile lucrătoare de la înregistrarea reclamaţiei la sediul companiei. In cazul în care nu se poate ajunge la o înţelegere în termenul precizat 
                                anterior, va fi considerată competentă instanţa judecătorească din aceeaşi structură administrativă cu sediul companiei. Dacă, din orice motiv, o 
                                instanță din jurisdicția competentă consideră că o prevedere sau o parte din acești “Termeni si Conditii” nu este aplicabilă, restul prevederilor vor 
                                avea aplicabilitate în continuare. Orice renunțare la orice prevedere a acestor “Termeni si Conditii” va intra in vigoare numai dacă este scrisă și 
                                semnată de un reprezentant autorizat al companiei.
                            </p>

                            <br />

                            <span className="tos-h-subtitle">
                                18. Diverse 
                            </span>

                            <br />
                            <br />

                            <p className="tos-paragraph">
                                Niciun eșec de exercitare și nici o întârziere în exercitarea, de către oricare dintre părți, a vreunui drept sau a oricarei competențe în temeiul 
                                prezentului acord nu va funcționa ca o renunțare la acel drept sau putere. Nici un exercițiu unic sau partial al vreunui drept în temeiul prezentului 
                                acord nu va împiedica exercitarea în continuare a acelui drept sau a oricărui alt drept acordat.
                                <br />
                                <br />
                                Planary își rezervă dreptul de a publica/include pe website concursuri, promoții, tombole sau alte activități care necesită trimiterea de materiale 
                                sau informații care privesc utilizatorul. Planary își rezervă dreptul de a stabili condițiile de eligibilitate, precum restricții de vârstă, așezare 
                                geografică etc. Înscrierea la oricare dintre activități presupune consimțământul utilizatorului cu privire la regulamentul stabilit în acest sens.
                                <br />
                                <br />
                                Planary își rezervă dreptul de a folosi cookie-uri. Cookie-urile sunt fișiere text mici stocate pe dispozitivul dvs. când navigați și utilizați 
                                site-uri sau alte servicii online, de exemplu pentru a facilita anumite funcții, cum ar fi navigarea pe site. Acestea sunt utilizate pentru a 
                                îmbunătăți experiența utilizatorului, selecta limba, agrega statistici despre numărul de vizitatori pe site și pentru a obține date despre modul în 
                                care este utilizat site-ul. Aceste date permit dezvoltarea și utilizarea site-ului. Site-ul poate incorpora uneori conținut și instrumente de partajare 
                                din rețele de socializare. Prin urmare, acești furnizori pot folosi cookie-uri pe site. Planary nu are acces sau control asupra acestor cookie-uri sau 
                                asupra datelor pe care le colectează și nu poate fi ținut răspunzător pentru acestea.
                                <br />
                                <br />
                                Planary nu garantează și nu se angajează ca website-ul să îndeplinească cerințele așteptate de utilizator sau partener, că va obține rezultatele 
                                dorite de aceștia, că va fi compatibil sau va funcționa cu orice alt software sau dispozitiv, că va opera fără întrerupere să îndeplinească orice 
                                standarde de performanță sau fiabilitate sau că nu deține/va deține erori.
                                <br />
                                <br />
                                Compania îşi rezervă dreptul de a notifica autoritățile competente în cazul în care, prin intermediul site-ului, ia cunoștiință despre orice activitate 
                                ilegala desfășurată de către parteneri, utilizatori sau terți.
                                <br />
                                <br />
                                Compania își rezervă dreptul de a face adăugiri, ștergeri sau modificări ale conținutului serviciului în orice moment, fără notificare prealabilă
                            </p>

                            {
                                (this.state.serviceData['cancel_policy_text'] || '').length > 0 && (

                                    <>
                                    
                                        <br />

                                        <span className="tos-h-title">
                                            Termeni si conditii {this.state.serviceData['name']}
                                        </span>

                                        <br />
                                        <br />

                                        <p 
                                            className="tos-paragraph"
                                            style={{
                                                whiteSpace: 'pre-wrap' 
                                            }}
                                        >
                                            {this.state.serviceData['cancel_policy_text']}
                                        </p>

                                    </>
                                )
                            }

                            <br />
                            <br />

                        </div>
                    )

                }

            </div>
        );
    }
}