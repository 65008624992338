import React from 'react';

import Ws from '../../providers/Ws';
import Utils from '../../providers/Utils';
import Declarations from '../../providers/Declarations';

import { MdAlarm } from 'react-icons/md';
import { RiArrowRightSLine } from 'react-icons/ri';
import { ImLocation2 } from 'react-icons/im';
import { IoVideocam } from 'react-icons/io5';
import { FaSkype } from 'react-icons/fa';
import { HiCurrencyDollar } from 'react-icons/hi';
import { IoIosArrowForward } from 'react-icons/io';

import { Link } from 'react-router-dom';

import { BrowserView, MobileView } from 'react-device-detect';

import './Client.css';
import Chameleon from '../../assets/chameleon.png';
import CompanyAvatar from '../../assets/foxStat.jpeg';
import CanaryPlantImage from '../../assets/canary_plant_bg.png';
import BirdBesidesPlantImage from '../../assets/bird_and_plant_mobile.png';
import LocalStorage from '../../providers/LocalStorage';
import { ReactComponent  as GMeetIcon} from '../../assets/Google_Meet-Logo.wine.svg';
import {ReactComponent as ZoomIcon } from '../../assets/Zoom Icon.svg';
import { ReactComponent as LocIcon } from '../../assets/pin_drop.svg';
import { Helmet } from 'react-helmet';
import AvatarAccount from '../../commons/AvatarAccount';
import HourInterval from '../../commons/HourInterval';
import LocationDesc from '../../commons/LocationDesc';
import IconText from '../../commons/IconText';
import ErrorMessage from '../../commons/ErrorMessage';
import Loader from '../../commons/Loader';

export default class SelectServices extends React.Component{

    constructor(props){

        super(props)
        
        this.state = {

            listaServicii: [],
            listaLocatii: [],
            eroareTx: '',
            accountName: '',
            accoutImgSrc: '',
            isLoading: true
        }
    }

    WS = new Ws()
    UT = new Utils()

    componentDidMount(){

        // if(this.props.match.params.company_web_link){

        //     if(this.props.match.params.company_web_link !== 'ca'){

        //         this.getServices()
        //     } 
        // }

        this.getServices();
    }

    componentWillUnmount(){

    }

    extractCompanyName = (companyName) => {

        if(companyName.length < 1){

            return { err: 'Company name not found', data: null };
        }
        else{

            return {err: null, data: companyName}
        }
    }

    getServices = async () => {

        const selectServices = 
        `select * from services where account_tk = ( select token from accounts where web_link = '${this.props.match.params.company_web_link}' ) 
                                and ifnull(deleted,0)!=1 and ifnull(draft,0)!=1      `;

        const getServices = await this.WS.sqlCommand(`select`, selectServices); 

        let selectUsers = `select * from users where account_id = ( select token from accounts where web_link = '${this.props.match.params.company_web_link}' )`;
        let users = await this.WS.sqlCommand(`select`, selectUsers);

        if(getServices.content.length === 0){

            this.setState({
                
                eroareTx: 'Link companie gresit! Fii sigur ca ai introdus link-ul corect.',
                isLoading: false
            })  
            
        }

        if(getServices.success){

            const currentMember = users.content.filter( user => user.web_link === this.props.match.params.memberLink);
            const accountData =  await this.UT.getAccountAvAndName(true, this.props.match.params.company_web_link);
            let companyDisplayName = this.extractCompanyName(accountData['name']);

            if(companyDisplayName.err !== null){

                companyDisplayName = '';
            }
            else{

                companyDisplayName = companyDisplayName.data;
            }

            this.setState({
                listaServicii: getServices.content,
                accountName: this.props.match.params.memberLink ? currentMember[0]['nume'] : companyDisplayName,
                accoutImgSrc: this.WS.serverAddress + 'staticprovider/getimg?filepath=Storage/Avatars/' + accountData['avatar'],
                isLoading: false
            },
                () => {

                    this.setListaLocatii(getServices.content)
                }
            )
             
        }
        else{

            throw new Error("Server response error: " + getServices.message)
        }
    }
    
    setListaLocatii = (services) => {

        const listaLocatii = new Declarations().listaLocatiiIntalnire;
        let listaLocatiiNoua = []

        for(let i = 0; i < services.length; i++){

            let found = false;
            let index = 0;

            for(let j = 0; j < listaLocatii.length; j++){

                if(services[i].location === listaLocatii[j].id){

                    found = true;
                    index = j
                }
            }

            if(found){

                listaLocatiiNoua.push({

                    order: services[i].id,
                    ...listaLocatii[index]
                })
            }
        }

        this.setState({

            listaLocatii: listaLocatiiNoua
        })
    }

    // onSelectServiceMobileView = (link) => {

    //     this.props.history.push(link);
    // }

    onGoToService = (sEl) => {

        if(this.props.match.params.memberLink){

            this.props.history.push(`/${this.props.match.params.company_web_link}/${this.props.match.params.memberLink}/${sEl.web_link }/calendar`);
        }
        else{

            this.props.history.push("/" + this.props.match.params.company_web_link + "/" + sEl.web_link + "/calendar");
        }
    }

    render(){

        return(
            <React.Fragment>

            <BrowserView>

            <div className="client-center">

                <div className="client-wrapper">

                    {
                        this.state.isLoading && (

                            <Loader 
                                fullPage = {true}
                            />
                        )
                    }

                    <br />

                    {
                        this.state.eroareTx.length === 0 && 
                        !this.state.isLoading &&
                        (

                            <div style={{margin: '0 auto'}}>

                                {/* <div className="company-avatar-outer">

                                    <div className="company-avatar-inner">
                                        <img src={this.state.accoutImgSrc} alt="Avatar companie" />
                                    </div>

                                </div> */}

                                <AvatarAccount 
                                    isNotLogged = {true}
                                    accToken = {this.props.match.params.company_web_link}
                                    border = '8px solid #fff'
                                />

                                <br />

                                <h2
                                    style={{
                                        margin: 0,
                                        fontSize: '24px',
                                    }}
                                >
                                    {this.state.accountName}
                                </h2>
                    
                                <br />

                                <p
                                    style={{
                                        margin: 0,
                                        fontSize: '14px',
                                        color: '#455A64'
                                    }}
                                >
                                    Salut, si bine ai venit! Selecteaza unul din serviciile de mai jos pentru a incepe programarea.
                                </p>

                            </div>
                        )
                    }

                    {
                        !this.state.isLoading && (

                            <div className="client-card" style={{marginTop: 30}}>

                            {
                                this.state.eroareTx.length > 0 && (

                                    <ErrorMessage 
                                        eroareTx = {this.state.eroareTx}
                                    />
                                )        
                            }

                            {/* {
                                this.state.isLoading && (

                                    <Loader 
                                    containerH = '100%'
                                    />
                                )
                            }     */}

                            {
                                this.state.listaServicii.length === 0 && this.state.eroareTx.length === 0 && 
                                !this.state.isLoading &&
                                (

                                    <h3
                                        style={{
                                            margin: 0,
                                            color: '#455A64',
                                            fontSize: '18px',
                                        }}
                                    >
                                        {this.state.companyDisplayName} nu are nici un serviciu activ.
                                    </h3>
                                )
                            }  

                            {   
                                !this.state.isLoading &&
                                this.state.listaServicii.length > 0 &&
                                this.state.listaServicii.map((sEl, idx) => {

                                    return(
                                    // <Link 
                                    //     to={"/" + this.props.match.params.company_web_link + "/" + sEl.web_link + "/calendar"}
                                    //     style={{
                                    //         textDecoration: 'none'
                                    //     }}
                                    // >
                                    <React.Fragment key={'appoint-loc-'+idx}>
                                        <br />
                                        <div
                                            
                                            className="client-appoint-card"
                                            onClick={() => {this.onGoToService(sEl)}}
                                            style={{
                                                cursor: 'pointer'
                                            }}
                                        >   
                                            
                                            <div 
                                                className="col-l-9 col-m-9"
                                                style={{
                                                    float: 'none',
                                                }}
                                            >

                                                <h3
                                                    style={{
                                                        margin: 0,
                                                        color: '#455A64',
                                                        fontSize: '18px',
                                                        textAlign: 'left'
                                                    }}
                                                >
                                                    {sEl.name}
                                                </h3>

                                                <br />

                                                <div 
                                                    className="limit-desc-wrapper"
                                                    style={{
                                                        maxHeight: '100px',
                                                        WebkitLineClamp: '3'
                                                    }} 
                                                >

                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            color: '#777777',
                                                            fontSize: '16px',
                                                            textAlign: 'left',
                                                            whiteSpace: 'pre-wrap',
                                                        }}
                                                    >
                                                        
                                                        {
                                                        
                                                            sEl.description
                                                        
                                                        }

                                                    </p>

                                                </div>
                                                
                                            </div>

                                            <div 
                                                className="col-l-2 col-m-2"
                                                style={{
                                                    float: 'none'
                                                }}
                                            >

                                                <div > {/* className="client-appoint-info" */}


                                                    <HourInterval 
                                                        durata = {sEl['duration']}
                                                        justifyContent = 'none'
                                                        marginTop = '0px'
                                                        iconSize = '22px'
                                                        iconCol = '#777777'
                                                        topIcon = '4px'
                                                        txSize = '16px'
                                                        txWeight = '500'
                                                        txCol = '#455A64'
                                                        topTx = '2px'
                                                        leftTx = '10px'
                                                    />

                                                    {
                                                        +sEl.price > 0 && (

                                                            <IconText 
                                                                icon = {

                                                                    <HiCurrencyDollar 
                                                                        style={{
                                                                            margin: 0,
                                                                            color: '#777777',
                                                                            fontSize: '22px'
                                                                        }}
                                                                    /> 
                                                                }
                                                                
                                                                tx = {sEl['price'] === 0 ? 'Fara tarif' : sEl['price'] + ' Lei'}
                                                                containerTop = '6px'
                                                                txWeight = '500'
                                                                txCol = {sEl['price'] === 0 ? '#60D84C' :'rgb(69, 90, 100)'}
                                                                txSize = '16px'
                                                            />
                                                        )
                                                    }

                                                    <LocationDesc 
                                                        location = {sEl['location'].trim()}
                                                        locationDesc = { 

                                                            new Declarations().listaLocatiiIntalnire.filter(llEl => llEl['id'] === sEl['location'].trim())[0]['descClient']
                                                        }
                                                        iconCls = 'loc-icon-dark'
                                                        // distance = '22px'
                                                        marginTop = '6px'
                                                        zoomW = '20px' zoomH = '10px'
                                                        officeW = '22px' officeH = '22px'       
                                                        googleW = '24px' googleH = '24px' 
                                                        skypeSize = '22px'  
                                                        leftOfffice = '0px'               
                                                        txSize = '16px'
                                                        txWeight = '500'
                                                    />

                                                </div>

                                            </div>

                                            <div 
                                                className="col-l-1 col-m-1"
                                                style={{
                                                    float: 'none',
                                                    textAlign: 'right',
                                                    margin: 0
                                                    
                                                }}
                                            >
                                                
                                            {/* <Link to={"/"+this.props.match.params.company_link+"/"+sEl.token}>
                                                <RiArrowRightSLine 
                                                    className="appoint-go"
                                                />
                                            </Link> */}

                                            
                                                <RiArrowRightSLine 
                                                    className="appoint-go"
                                                />
                                            

                                            </div>

                                        </div>

                                        {   
                                            +idx < +this.state.listaServicii.length-1 &&
                                            <>
                                                <br />
                                                <div
                                                    style={{
                                                        width: '100%',
                                                        height: '1px',
                                                        background: '#EBEBEB'
                                                    }}
                                                >
                                                </div>
                                            </>
                                        }

                                    </React.Fragment>
                                    // </Link>
                                        
                                    )
                                })
                            }

                        </div>
                        )
                    }

                </div> 

            </div>

            {
                !this.state.isLoading && (
                    <>

                    <div
                        style={{
                            width: '100%',
                        }}
                    >

                        <img 
                            src={CanaryPlantImage}
                            alt="Canary and plant..."
                            style={{
                                display: 'block',
                                margin: 'auto',
                                width: '400px'
                            }}
                        />

                    </div>

                    <p
                        style={{
                            
                            paddingTop: '20px',
                            paddingBottom: '20px',
                            fontSize: 14,
                            color: '#455A64'
                        }}
                    >
                        Programari prin  <a 
                                            href="https://planary.ro"
                                            style={{
                                                color: '#455A64'
                                            }}
                                        >
                                            Planary.ro
                                        </a>
                            
                    </p>

                    </>
                )
            }
            
            </BrowserView>

            <MobileView>

                {
                    this.state.isLoading && (

                        <Loader 
                            fullPage = {true}
                        />
                    )
                }


                {
                    this.state.eroareTx.length === 0 &&
                    !this.state.isLoading && (

                        <div className="m-client-header">

                            {/* <div className="company-avatar-outer mobile">

                                <div className="company-avatar-inner">
                                    <img src={this.state.accoutImgSrc} alt="Avatar companie" />
                                </div>

                            </div> */}
                            <AvatarAccount 
                                isNotLogged = {true}
                                accToken = {this.props.match.params.company_web_link}
                                border = '4px solid #fff'
                                width = '102px'
                                height = '102px'
                                top = '20px'
                            />

                            <h4
                                className="title"
                            >
                                {this.state.accountName}
                            </h4>

                            <p
                                style={{

                                    margin: 0,
                                    marginTop: -10,
                                    color: '#455A64',
                                    fontSize: 14
                                }}
                            >
                                Salut, si bine ai venit! Selecteaza unul din serviciile de 
                                mai jos pentru a incepe programarea.
                            </p>

                        </div>
                    )

                    
                }

                {
                    !this.state.isLoading && (

                        <>
                        
                            <div className="m-client-services-card">

                            {
                                this.state.eroareTx.length > 0 &&
                                !this.state.isLoading && (

                                    <ErrorMessage 
                                        eroareTx = {this.state.eroareTx}
                                    />
                                )
                            }

                            <div className="m-client-service">

                                {
                                    this.state.listaServicii.length === 0 && this.state.eroareTx.length === 0 && 
                                    !this.state.isLoading &&
                                    (

                                        <h3
                                            style={{
                                                margin: 0,
                                                color: '#455A64',
                                                fontSize: '18px',
                                            }}
                                        >
                                            {this.state.companyDisplayName} nu are nici un serviciu activ.
                                        </h3>
                                    )
                                }  
                            

                                {
                                    !this.state.isLoading &&
                                    this.state.listaServicii.map((sEl, idx) => {

                                        return(

                                            <div 
                                                key={'m-client-service-'+idx}
                                                className="m-appoint-client-info"  
                                                onClick={
                                                    () => {

                                                        this.onGoToService(sEl);
                                                    }
                                                }  
                                            >

                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                    }}
                                                >

                                                    <div 
                                                        style={{
                                                            width: '83.33%'
                                                        }}
                                                    >

                                                        <h3
                                                            style={{
                                                                margin: 0,
                                                                fontSize: '18px',
                                                                textAlign: 'left'
                                                            }}
                                                        >
                                                            {
                                                                sEl.name
                                                            }
                                                        </h3>

                                                    </div>

                                                    <div 
                                                        
                                                        style={{
                                                            position: 'relative',
                                                            width: '16.66%',
                                                            textAlign: 'right'
                                                        }}
                                                    >
                                                        <Link to={"/" + this.props.match.params.company_web_link + "/" + sEl.web_link + "/calendar"}>

                                                        <IoIosArrowForward 
                                                            style={{
                                                                position: 'absolute',
                                                                top: '50%',
                                                                transform: 'translateY(-50%)',
                                                                right: 0,
                                                                margin: 0,
                                                                fontSize: 32,
                                                                color: '#323232'
                                                            }}
                                                        
                                                        />
                                                        </Link>

                                                    </div>

                                                </div>

                                                <HourInterval 
                                                    durata = {sEl['duration']}
                                                    justifyContent = 'none'
                                                    marginTop = '10px'
                                                    iconSize = '22px'
                                                    iconCol = '#777777'
                                                    topIcon = '4px'
                                                    txSize = '16px'
                                                    txCol = '#455A64'
                                                    topTx = '2px'
                                                    leftTx = '10px'
                                                />

                                                <LocationDesc 
                                                    location = {sEl['location'].trim()}
                                                    locationDesc = { 

                                                        new Declarations().listaLocatiiIntalnire.filter(llEl => llEl['id'] === sEl['location'].trim())[0]['descClient']
                                                    }
                                                    iconCls = 'loc-icon-dark'
                                                    // distance = '22px'
                                                    marginTop = '6px'
                                                    zoomW = '20px' zoomH = '10px'
                                                    officeW = '22px' officeH = '22px'       
                                                    googleW = '24px' googleH = '24px' 
                                                    skypeSize = '22px'  
                                                    leftOfffice = '0px'               
                                                    txSize = '16px'
                                                    txWeight = '500'
                                                />

                                                {
                                                    +sEl.price > 0 && (

                                                        <IconText 
                                                            icon = {

                                                                <HiCurrencyDollar 
                                                                    style={{
                                                                        margin: 0,
                                                                        color: '#777777',
                                                                        fontSize: '22px'
                                                                    }}
                                                                /> 
                                                            }
                                                            
                                                            tx = {sEl['price'] === 0 ? 'Fara tarif' : sEl['price'] + ' Lei'}
                                                            containerTop = '6px'
                                                            txWeight = '500'
                                                            txCol = {sEl['price'] === 0 ? '#60D84C' :'rgb(69, 90, 100)'}
                                                            txSize = '16px'
                                                        />
                                                    )
                                                }

                                                <div className="limit-desc-wrapper">

                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            marginTop: 20,
                                                            textAlign: 'left',
                                                            color: '#777777',
                                                            fontSize: 16,
                                                            whiteSpace: 'pre-wrap'
                                                        }}
                                                    >
                                                        {
                                                            sEl.description
                                                        }
                                                    </p>

                                                </div>

                                                {
                                                    idx < this.state.listaServicii.length-1  &&
                                                    <div
                                                        style={{
                                                            marginTop: 30,
                                                            marginBottom: 30,
                                                            width: '100%',
                                                            height: '1px',
                                                            background: '#EBEBEB'
                                                        }}
                                                    >
                                                    </div> 
                                                }

                                                
                                            </div>
                                        )

                                    })
                                }
                

                            </div> 

                        </div>

                        <div
                            style={{
                                width: '100%',
                                marginTop: 20
                            }}
                        >

                            <img 
                                src={BirdBesidesPlantImage}
                                alt="Canary and plant..."
                                style={{
                                    display: 'block',
                                    margin: 'auto',
                                }}
                            />

                        </div>

                        <p
                            style={{
                                
                                paddingTop: 20,
                                paddingBottom: 60,
                                fontSize: 14,
                                color: '#455A64'
                            }}
                        >
                            Programari prin  <a 
                                                href="https://planary.ro"
                                                style={{
                                                    color: '#455A64'
                                                }}
                                            >
                                                Planary.ro
                                            </a>
                            
                        </p>
                        
                        </>
                    )
                }
                

            </MobileView>

            </React.Fragment>

            
        )
    }
}