import React from 'react';

import { MdDeleteForever } from 'react-icons/md';
import { RiCalendar2Fill } from 'react-icons/ri';

import Ws from '../../../../providers/Ws';
import Utils from '../../../../providers/Utils';
import Masks from '../../../../providers/Masks';
import LocalStorage from '../../../../providers/LocalStorage';
import Declarations from '../../../../providers/Declarations';

import SelectOthers from '../../../../commons/SelectOthers';
import AuthInput from '../../../../commons/AuthInput';
import PopWarningDeleteSchedule from '../../PopWarningDeleteSchedule';
import MServiceNav from '../../../../commons/MServiceNav';
import { MobileView } from 'react-device-detect';
import { GoCheck } from 'react-icons/go';
import SuccessSaveToast from '../../../../commons/SuccessSaveToast';
import ErrorToast from '../../../../commons/ErrorToast';
import ScheduleInterval from '../../../../commons/ScheduleInterval';
import ScheduleManagerFuncs from '../../../logic/ScheduleManagerFuncs';
import ErrorMessage from '../../../../commons/ErrorMessage';
import ScheduleName from '../../../../commons/schedule/ScheduleName';
import ScheduleConfig from '../../../../commons/schedule/ScheduleConfig';
import Loader from '../../../../commons/Loader';

export default class MServiceScheduleCreate extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            canSave: false,

            schedules: [],
            services: [],
        
            scheduleName: '',
            selectedSchedule: [],
            canCreate: false,
        
            selectedScheduleTk: '',
            showWarningPopup: false,
            showToastPopup: false,
            showToastPopupError: false,
            actionTx: '',
            warningServiceList: [],
            errorTx: '',
            deletedFlag: false,
            allSchedules: [],

            triggerSave: false,
            isLoading: true,

            nameStatus: false,
            luStStatus: false,
            luEndStatus: false,
            maStStatus: false,
            maEndStatus: false,
            miStStatus: false,
            miEndStatus: false,
            joStStatus: false,
            joEndStatus: false,
            viStStatus: false,
            viEndStatus: false,
            saStStatus: false,
            saEndStatus: false,
            duStStatus: false,
            duEndStatus: false,
        }
    }

    
    WS = new Ws();
    M = new Masks();
    UT = new Utils();
    SMF = new ScheduleManagerFuncs();

    componentsData = new Declarations().scheduleData;

    componentsStatus = {

        isNameOk: true,
        isScheudleOk: true
    }

    componentDidMount(){

        window.scrollTo(0,0);

        if(this.props.match.params.scheduleTk){

            if(this.props.match.params.scheduleTk.length > 10){

                this.getSchedules();
            }
            else{

                this.setState({

                    canCreate: true
                })
            }
        }


        // this.setIntervalOrar();
        // this.setScheduleWeekDays();
    }

    getSchedules = async () => {

        const ACC_TOKEN = new LocalStorage().GetAccountToken();

        let selectSchedulesQuery = `select * from programs where token = '${this.props.match.params.scheduleTk}' and ifnull(sters,0)!= 1 `;
        let selectServicesQuery = `select * from services where account_tk = '${ACC_TOKEN}' and ifnull(deleted,0)!=1 and ifnull(draft,0)!=1`;
        let selectAllSchedulesQuery = `select * from programs where account_tk = '${ACC_TOKEN}' and ifnull(sters,0)!= 1 `;

        let _schedules = this.WS.sqlCommand(`select`, selectSchedulesQuery);
        let _services = this.WS.sqlCommand(`select`, selectServicesQuery);
        let _allSchedules = this.WS.sqlCommand(`select`, selectAllSchedulesQuery);

        let [schedules, services, allSchedules] = await Promise.all( [_schedules, _services, _allSchedules] );
        
        
        
        

        if(schedules.success && services.success && allSchedules.success){

            if(schedules.content.length === 0){

                this.setState({
                    
                    errorTx: 'Programul accesat nu exista!'
                });

                return;
            }

            this.setState({

                schedules: schedules.content,
                services: services.content,
                allSchedules: allSchedules.content,
                canCreate: false
            },
                () => {

                    // this.setScheduleInterval(schedules.content);
                    this.onDisplayClickedSchedule();
                }
            )
        }
        else{

            this.setState({
                    
                errorTx: 'Datele programului nu pot fi preluate de catre server!'
            });

            throw new Error("Error fetching schedules or services: " + schedules.message + " " + services.message + " " + allSchedules);
        }
    }

    changeSaveButtonStatus = () => {

        let status = null;

        if(!this.state.nameStatus && 
            !this.state.luStStatus && !this.state.luEndStatus && 
            !this.state.maStStatus && !this.state.maEndStatus && 
            !this.state.miStStatus && !this.state.miEndStatus &&
            !this.state.joStStatus && !this.state.joEndStatus &&
            !this.state.viStStatus && !this.state.viEndStatus &&
            !this.state.saStStatus && !this.state.saEndStatus &&
            !this.state.duStStatus && !this.state.duEndStatus 
        ){
            status = false;
        }
        else{

            status = true;
        }

        this.setState({

            canSave: status
        });
    }

    inputStatus = (status, statusName, inputName, data, isVerify = false) => {

        if(isVerify){

            this.componentsStatus[statusName] = data;
        }
        else{

            this.componentsData[inputName] = data;

            
    
            this.setState({
                
                [statusName]: status
            },
                () => {
    
                    this.changeSaveButtonStatus();
                }
            );
        }
    }


    onSaveToDb = async () => {
   
        const componentsDataWithScheduleTk = {
            ...this.componentsData, 
            selectedScheduleTk: this.props.match.params.scheduleTk,
            allSchedules: this.state.allSchedules
        };
        const savingRez = await this.SMF.onSaveScheduleToDb(false, componentsDataWithScheduleTk);

        if(savingRez.err !== null){

            this.setState({

                showToastPopupError: true,
                actionTx: savingRez.err
            });
        }
        else{

            this.setState({
                showToastPopup: true,
                canSave: false,
                actionTx: 'a fost actualizat cu succes!',
                triggerSave: false
                
            });

            await this.getSchedules();
        }
    }

    onDeleteSchedule = async () => {

        let scheduleTk = this.props.match.params.scheduleTk;

        let servicesWithSelectedSchedule = this.state.services.filter(sEl => sEl['program_tk'] === scheduleTk);

        
        

        if(servicesWithSelectedSchedule.length > 0){

            this.setState({

                showWarningPopup: true,
                warningServiceList: servicesWithSelectedSchedule.map(sEl => {return sEl.name})
            })
        }

        if(servicesWithSelectedSchedule.length === 0){    

            let deleteScheduleQuery = `update programs set sters = 1 where token = '${scheduleTk}' `;

            let deleteSchedule = await this.WS.sqlCommand(`update`, deleteScheduleQuery);

            if(deleteSchedule.success){

                this.setState({

                    showToastPopup: true,
                    actionTx: 'a fost sters cu succes!',
                    deletedFlag: true
                });
            }
            else{

                this.setState({

                    showToastPopupError: true,
                    actionTx: 'Eroare stergere program.'
                });
                // alert("A aparut o eroare la stergerea programului!");
                throw new Error("Error deleting schedule: " + deleteSchedule.message);
            }
        }
    }   

    // generateScheduleStateArray = () => {

    //     const scheduleArray = [

    //         {
    //             ziStart: this.state.luStart,
    //             ziEnd: this.state.luEnd
    //         },

    //         {
    //             ziStart: this.state.maStart,
    //             ziEnd: this.state.maEnd
    //         },

    //         {
    //             ziStart: this.state.miStart,
    //             ziEnd: this.state.miEnd
    //         },

    //         {
    //             ziStart: this.state.joStart,
    //             ziEnd: this.state.joEnd
    //         },

    //         {
    //             ziStart: this.state.viStart,
    //             ziEnd: this.state.viEnd
    //         },

    //         {
    //             ziStart: this.state.saStart,
    //             ziEnd: this.state.saEnd
    //         },

    //         {
    //             ziStart: this.state.duStart,
    //             ziEnd: this.state.duEnd
    //         }
    //     ]

    //     return scheduleArray;
    // }

    // setIntervalOrar = () => {

    //     const intervalOrar = new Declarations().listaIntervalOrarFix.map(liEl => {

    //         if(liEl === 'Indisp'){

    //             return(

    //                 {
    //                     val: null,
    //                     tx: liEl
    //                 }
    //             )
    //         }
    //         else{

    //             return(

    //                 {
    //                     val: liEl + ':00',
    //                     tx: liEl
    //                 }
    //             )
    //         }
    //     });

    //     

    //     this.setState({

    //         interval: intervalOrar
    //     })
    // }

    // setScheduleWeekDays = () => {

    //     const weekDaysList = new Declarations().listaZileSaptamana;
        
    //     this.setState({

    //         weekDays: weekDaysList
    //     })
    // }

    onDisplayClickedSchedule = () => {

        let schedule = this.state.schedules;

        for(let key in schedule[0]){

            if( ( (schedule[0][key] || '').length < 2 ) || (schedule[0][key] === null ) ||  ( (schedule[0][key] || '') === '00:00:00') ){

                schedule[0][key] = null;
            }
        }

        this.componentsData = new Declarations().updateComponentsData(this.componentsData, schedule[0], 'schedule');

        

        this.setState({

            scheduleName: schedule[0]['name'],
            selectedSchedule: schedule,
            isLoading: false
        });
    }

    resetStatus = () => {

        this.setState({

            nameStatus: false,
            luStStatus: false,
            luEndStatus: false,
            maStStatus: false,
            maEndStatus: false,
            miStStatus: false,
            miEndStatus: false,
            joStStatus: false,
            joEndStatus: false,
            viStStatus: false,
            viEndStatus: false,
            saStStatus: false,
            saEndStatus: false,
            duStStatus: false,
            duEndStatus: false,
        },
            () => {

                this.changeSaveButtonStatus();
            }
        );
    }

    onTriggerResetSchedule = async () => { //async

        // let defaultSchedule = [];
        this.componentsData = new Declarations().scheduleData;

        await this.onSaveToDb(true);  
  
    }

    onCheckAndSave = async (isCreate) => {

        const result = Object.values(this.componentsStatus).every(item => item === true)
        
        if(isCreate){
            
            // 
            this.onTriggerResetSchedule(); //await
        }
        else if(result){
            
            await this.onSaveToDb(false);
        }
        else{

            this.setState({

                triggerSave: false
            });
        }

    }

    onTriggerSave = () => {

        this.setState({

            triggerSave: true
        },
            async () => {

                await this.onCheckAndSave();
            }
        );
    }

    showToastError = (errorTx) => {

        this.setState({

            showToastPopupError: true,
            actionTx: errorTx
        });
    }

    onClosePopup = (popupType) => {

        if(this.state.deletedFlag){

            this.props.history.push('/ca/dashboard/cab/programe');

        }
        
        this.setState({

            [popupType]: false
        });
    }


    render(){

        return(

            <MobileView>

            <div>

                {
                    this.state.showToastPopupError && (

                        <ErrorToast 
                            action={this.state.actionTx}
                            onClosePopup = {this.onClosePopup}
                        />
                    )
                }


                
                {
                    this.state.showToastPopup && (

                        <SuccessSaveToast 
                            onClosePopup = {this.onClosePopup}
                            saveName = 'Programul'
                            action = {this.state.actionTx}
                        />
                    )
                }

                {
                    this.state.showWarningPopup && (

                        <PopWarningDeleteSchedule 
                        
                            servicesList = {this.state.warningServiceList}
                            onClosePopup = {this.onClosePopup}
                        />
                    )
                }

                <div
                    style={{
                        position: 'relative'
                    }} 
                >

                    <MServiceNav 
                        icon={<RiCalendar2Fill />}
                        title='Personalizeaza Program'
                        path={'/ca/dashboard/cab/programe'}
                    />
                    
                    {
                        this.state.canSave &&
                        <GoCheck
                            className="m-add-service"
                            onClick={this.onTriggerSave}
                        />
                    }


                </div>

                {
                    this.state.isLoading && (

                        <Loader 
                            fullPage = {true}
                        />
                    )
                }

                {
                    !this.state.isLoading && (

                        <div
                        style={{
                            padding: 20
                        }}
                    >
                        <div className="m-edit-s-card">
    
                            {
                                this.state.errorTx.length > 0 && (
    
                                    <ErrorMessage 
                                        eroareTx = {this.state.errorTx}
                                    />
                                )
                            }
    
                            
    
                            {
                                this.state.errorTx.length === 0 && (
                                    <div>
    
                                        <ScheduleName 
                                            triggerSave = {this.state.triggerSave}
                                            inputStatus = {this.inputStatus}
                                            scheduleName = {this.state.scheduleName}
                                            schedules = {this.state.schedules}
                                            selectedScheduleTk = {this.props.match.params.scheduleTk}
                                        />
    
                                        <div
                                            style={{
                                                marginTop: '20px',
                                                marginBottom: '20px',
                                                width: '100%',
                                                height: '1px',
                                                background: '#EBEBEB'
                                            }}
                                        ></div>
    
                                        <ScheduleConfig 
                                            selectedSchedule = {this.state.selectedSchedule}
                                            triggerSave = {this.state.triggerSave}
                                            showSave = {false}
                                            inputStatus = {this.inputStatus}
                                            resetStatus = {this.resetStatus}
                                            showToastError = {this.showToastError}
                                        />
    
                                        <div
                                            style={{
                                                marginTop: '20px',
                                                marginBottom: '20px',
                                                width: '100%',
                                                height: '1px',
                                                background: '#EBEBEB'
                                            }}
                                        ></div>
    
                                        {
                                            this.props.match.params.scheduleTk &&
                                            this.props.match.params.scheduleTk.length > 10 && (
    
                                                <h6
                                                    className="delete-service"
                                                    style={{
                                                        textAlign: 'center'
                                                    }}
                                                    onClick = {this.onDeleteSchedule}
                                                >
                                                    <p 
                                                        style={
                                                            {
                                                                display: 'inline-block', 
                                                                position: 'relative', 
                                                                top: 6, 
                                                                left: 0, 
                                                                margin: 0,
                                                                fontSize: '24px',
                                                            }
                                                        }
                                                    >
                                                        <MdDeleteForever />
                                                    </p>    
    
                                                    <p 
                                                        style={
                                                            {
                                                                display: 'inline-block', 
                                                                position: 'relative', 
                                                                left: 6,
                                                                margin: 0,
                                                                fontSize: '14px',
                                                                fontWeight: '500',
                                                            }
                                                        }
                                                    >
                                                        Sterge Acest Program
                                                    </p>
                                                    
                                                </h6>
                                            )
                                        }
    
                                </div>
                                )
                            }
    
                        </div>
    
                    </div>
                        
                    )
                }

            </div>
            </MobileView>
        )
    }
}