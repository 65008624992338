import React from 'react';
import { MdAlarm } from 'react-icons/md';

import InputValidation from '../../module/logic/InputValidation';

import ProfileFuncs from '../../module/logic/ProfileFuncs';
import Masks from '../../providers/Masks';
import Utils from '../../providers/Utils';
import Ws from '../../providers/Ws';

import AuthInput from '../AuthInput';


export default class CompanyDataInputs extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            cui: this.props.data['cui'],
            reg_com: this.props.data['reg_com'],
            nume: this.props.data['nume'],

            cuiTmp: this.props.data['cui'],
            reg_comTmp: this.props.data['reg_com'],
            numeTmp: this.props.data['nume'],

            isNumeCompanieValid: false,
            isNrRegComValid: false,
            isCuiValid: false,

            numeCompanieError: false,
            nrRegComError: false,
            cuiError: false,

            numeCompanieErrorTx: '',
            nrRegComErrorTx: '',
            cuiErrorTx: '',
        
        }
    }

    WS = new Ws();
    M = new Masks();
    UT = new Utils();
    PF = new ProfileFuncs();
    IV = new InputValidation();

    componentDidMount(){


    }

    componentDidUpdate(prevProps, _){
        
        if(prevProps.triggerSave !== this.props.triggerSave){

            this.onCheckInputs();
        }

        if(prevProps['data'] !== this.props['data']){

            this.setState({

                cuiTmp: this.props.data['cui'],
                reg_comTmp: this.props.data['reg_com'],
                numeTmp: this.props.data['nume'],
            });
        }
    }

    onSendInputStatus = () => {

        const result = {

            cui: this.IV.onCheckCui(this.state.cui),
            numeCompanie: this.IV.onCheckLegalName(this.state.nume),
            nrRegCom: this.IV.onCheckNrRegCom(this.state.reg_com),

        }

        if(result['cui']['isOk'] && result['numeCompanie']['isOk'] && result['nrRegCom']['isOk'] ){

            this.props.inputStatus(true, 'isCompanyDataOk', 'isCompanyDataOk', true, true);
        }
        else{

            this.props.inputStatus(true, 'isCompanyDataOk', 'isCompanyDataOk', false, true);
        }
        
    }

    onCheckInputs = () => {

        const checkRes = {

            cui: this.IV.onCheckCui(this.state.cui),
            numeCompanie: this.IV.onCheckLegalName(this.state.nume),
            nrRegCom: this.IV.onCheckNrRegCom(this.state.reg_com),
        }

        this.setState({

            isNumeCompanieValid: checkRes['numeCompanie']['verified'],
            numeCompanieError: checkRes['numeCompanie']['error'],
            numeCompanieErrorTx: checkRes['numeCompanie']['errorTx'],

            isNrRegComValid: checkRes['nrRegCom']['verified'],
            nrRegComError: checkRes['nrRegCom']['error'],
            nrRegComErrorTx: checkRes['nrRegCom']['errorTx'],

            cuiError: checkRes['cui']['error'],
            isCuiValid: checkRes['cui']['verified'], 
            cuiErrorTx: checkRes['cui']['errorTx'],
        });
        
    }

    onModifyInput = (ev) => {

        let doSave = true;

        if(ev.target.value === this.state[ev.target.name+'Tmp']){

            doSave = false;
        }

        this.setState({

            [ev.target.name] : ev.target.value,
        },
        
            () => {

                this.props.inputStatus(doSave, ev.target.name+'Status', ev.target.name , ev.target.value);

                let duration = 500;
                clearTimeout(this.inputTimer);
                    this.inputTimer = setTimeout( async ()=>{
                        this.onSendInputStatus();
                }, duration);
            }
        );

        // 
        
    }

    render(){

        return(

            <>
            
                {
                    this.props.showTitle && (

                        <h3
                            style={{
                                margin: 0
                            }}
                        >
                            <p
                                style={{
                                    position: 'relative',
                                    display: 'inline-block',
                                    margin: 0,
                                    fontSize: '24px',
                                    color: '#FFC727'
                                }}
                            >
                                <MdAlarm />
                            </p>

                            <p
                                style={{
                                    position: 'relative',
                                    display: 'inline-block',
                                    top: -4,
                                    margin: 0,
                                    fontSize: '18px',
                                    fontWeight: '500',
                                    color: '#455A64'
                                }}
                            >
                                &nbsp; Nume Legal
                            </p>

                        </h3>
                    )
                }

                <div 
                    className="form-control"
                    style={{
                        marginTop: 10
                    }}
                >

                    <AuthInput 
                        label='Nume Companie'
                        labelStyle={{
                            fontSize: '10px',
                            top: 6
                        }}
                        value={this.state.nume}
                        name='nume'
                        onChange={this.onModifyInput}
                        iserror={this.state.numeCompanieError}
                        errormsg={this.state.numeCompanieErrorTx}
                        verified={this.state.isNumeCompanieValid}
                        onFocus = {
                            () => {

                                this.setState({
                                    numeCompanieErrorTx: null
                                })
                            }
                        }
                    />

                </div>

                {
                    this.props.showTitle && (

                        <>
                            <br />

                            <h3
                                style={{
                                    margin: 0
                                }}
                            >
                                <p
                                    style={{
                                        position: 'relative',
                                        display: 'inline-block',
                                        margin: 0,
                                        fontSize: '24px',
                                        color: '#FFC727'
                                    }}
                                >
                                    <MdAlarm />
                                </p>

                                <p
                                    style={{
                                        position: 'relative',
                                        display: 'inline-block',
                                        top: -4,
                                        margin: 0,
                                        fontSize: '18px',
                                        fontWeight: '500',
                                        color: '#455A64'
                                    }}
                                >
                                    &nbsp; Identificatori Legali
                                </p>

                            </h3>
                        </>

                    )
                }

                <div 
                    className="form-control"
                    style={{
                        marginTop: 10
                    }}
                >

                    <AuthInput 
                        label='C.I.F'
                        labelStyle={{
                            fontSize: '10px',
                            top: 6
                        }}
                        value={this.state.cui}
                        name='cui'
                        onChange={this.onModifyInput}
                        iserror={this.state.cuiError}
                        errormsg={this.state.cuiErrorTx}
                        verified={this.state.isCuiValid}
                        onFocus = {
                            () => {

                                this.setState({
                                    cuiErrorTx: null
                                })
                            }
                        }
                    />

                </div>

                <div 
                    className="form-control"
                    style={{
                        marginTop: 10
                    }}
                >

                    <AuthInput 
                        label='Nr Registru Comert'
                        labelStyle={{
                            fontSize: '10px',
                            top: 6
                        }}
                        value={this.state.reg_com}
                        name='reg_com'
                        onChange={this.onModifyInput}
                        iserror={this.state.nrRegComError}
                        errormsg={this.state.nrRegComErrorTx}
                        verified={this.state.isNrRegComValid}
                        onFocus = {
                            () => {

                                this.setState({
                                    nrRegComErrorTx: null
                                })
                            }
                        }
                    />

                </div>

            </>
        );
    }
}