import LocalStorage from "../../providers/LocalStorage";
import Masks from "../../providers/Masks";
import Utils from "../../providers/Utils";
import Ws from "../../providers/Ws";


export default class ServicesFuncs{

    WS = new Ws();
    UT = new Utils();
    M = new Masks();

    calculateWorkingDays = (serviceDetails, dataStart, todayDate, oneDay) => {

        const dataWorkDaysEnd = this.UT.addWorkDays(new Date(dataStart),  +serviceDetails['dispo_nr_zile']);
        let zileRamase = 0;

        

        // const weekNumber = this.UT.getWeekNumber(todayDate, dataWorkDaysEnd, this.UT.diffWeeks(dataWorkDaysEnd, new Date(dataStartZile)) );
        const weekNumber = this.UT.diffWeeks(dataWorkDaysEnd, new Date(todayDate))

        
        

        if( todayDate.getDay() === 6 || todayDate.getDay() === 0){ 

            // zileRamase = +serviceDetails['dispo_nr_zile'] - (5 * weekNumber);
            let weekendDate = new Date(todayDate);

            if(todayDate.getDay() === 6){

                weekendDate = this.UT.addDaysToDate(new Date(todayDate), -1);
            }
            else if(todayDate.getDay() === 0){

                weekendDate = this.UT.addDaysToDate(new Date(todayDate), -2);
            }

            zileRamase = Math.round( Math.abs( ( dataWorkDaysEnd - this.UT.addDaysToDate(new Date(weekendDate), (weekNumber + 1) * 2) ) / oneDay ) );
        }
        else if(todayDate.getDay() !== 0 || todayDate.getDay() !== 6){

            // zileRamase = +serviceDetails['dispo_nr_zile'] - (todayDate.getDay() * weekNumber);
            zileRamase = Math.round( Math.abs( ( dataWorkDaysEnd - this.UT.addDaysToDate(new Date(todayDate), ( (weekNumber * 2) ) ) ) / oneDay ) );

            const daysDiff = +serviceDetails['dispo_nr_zile'] - zileRamase 
            

            zileRamase += daysDiff;

        }
        else if(todayDate.getTime() >= dataWorkDaysEnd.getTime()){

            zileRamase = 0;
        }

        return zileRamase;
    }

    setCurrentDisponibilityRadio = (serviceDetails) => {

        let result = {

            daysInput: false,
            calendarInput: false,
            unlimInput: false
        }

        if( ( +serviceDetails['dispo_nr_zile'] > 0 ) && 
            ( (serviceDetails['dispo_data_end'] || '').length < 2)
        ){

            result.daysInput = true;
            result.calendarInput = false;
            result.unlimInput = false;
        }

        else if( ( +serviceDetails['dispo_nr_zile'] === 0 ) && 
        ( (serviceDetails['dispo_data_end'] || '').length < 2 ) ){

            result.daysInput = false;
            result.calendarInput = false;
            result.unlimInput = true;
        }

        else  if( ( +serviceDetails['dispo_nr_zile'] === 0 ) && ( (serviceDetails['dispo_data_start'] || '').length > 2 ) &&
        ( (serviceDetails['dispo_data_end'] || '').length > 2 )
        ){

            result.daysInput = false;
            result.calendarInput = true;
            result.unlimInput = false;
        }
        else{

            result.daysInput = true;
            result.calendarInput = false;
            result.unlimInput = false;
        }

        return result;
    }

    onSaveServiceData = (dataArr, isCreate, serviceToken, table = 'services') => {

        let ACC_TOKEN = '';

        if(table === 'services'){

            ACC_TOKEN = new LocalStorage().GetAccountToken();
        }

        let serviceQueryString = '` ';

        if(dataArr.length < 1){

            return {
                data: null, 
                err: 'Date serviciu inexistente.'
            }
        }

        if(isCreate){

            serviceQueryString = '`' + `insert into ${table}(token, account_tk, `;

            for(let i = 0; i < dataArr.length - 1; i++){

                serviceQueryString += dataArr[i].itemName + `, `; 
            }

            serviceQueryString += dataArr[dataArr.length - 1].itemName + `)`; 

            serviceQueryString += ` select '${serviceToken}', '${ACC_TOKEN}', `;

            for(let i = 0; i < dataArr.length - 1; i++){

                serviceQueryString += dataArr[i].itemData + `, `; 
            }

            serviceQueryString += dataArr[dataArr.length - 1].itemData + '`';
        }
        else{

            serviceQueryString = '`' + `update ${table} set `;

            for(let i = 0; i < dataArr.length - 1; i++){

                serviceQueryString += dataArr[i].itemName + ` = `; 
                serviceQueryString += dataArr[i].itemData + `, `; 
            }

            serviceQueryString += dataArr[dataArr.length - 1].itemName + ` = ` + dataArr[dataArr.length - 1].itemData;
            serviceQueryString += ` where token = '${serviceToken}' ` + '`' ;
        }

        return {

            data: serviceQueryString,
            err: null
        }
    }

    
    getIntegrationsData = async (isPublic = false, accToken, tranToken = null, isUserAsignat = false) => {

        let ACC_TOKEN = '';
        let tranTokenStr;
        
        if(isPublic){

            ACC_TOKEN = accToken;
            tranTokenStr = tranToken;
        }
        else{

            ACC_TOKEN = new LocalStorage().GetAccountToken();
        }

        let selectUsersContactsQuery;

        if(isUserAsignat){

            selectUsersContactsQuery  = `select * from user_contacts where token_user = (select user_asignat_token from trc where token = '${tranTokenStr}') `;
        }
        else{

            selectUsersContactsQuery = `select * from user_contacts where token_user = ( select token from users where account_id = '${ACC_TOKEN}' LIMIT 1 ) `;
        }
        // let selectUsersContactsQuery  = `select * from user_contacts where token_user = (select user_asignat_token from trc where token = '${this.props.match.params.tranToken}') `;
        let _usersContacts = this.WS.sqlCommand(`select`, selectUsersContactsQuery);

        let selectAccountIntegrationsQuery = `select * from accounts_integrations where account_token = '${ACC_TOKEN}' `;
        let _accountIntegrations = this.WS.sqlCommand(`select`, selectAccountIntegrationsQuery);

        let selectAccountPayments = `select * from accounts_payments_methods where account_token = '${ACC_TOKEN}' `;
        let _accountPayments = this.WS.sqlCommand(`select`, selectAccountPayments);

        let selectSmartBillIntegration = `select * from accounts_integrations where account_token = '${ACC_TOKEN}'
                                                and ifnull(activ,0)=1 `;

        let _smartBillIntegration = this.WS.sqlCommand(`select`, selectSmartBillIntegration);

        let [usersContacts, accountIntegrations, accountPayments, smartBillIntegration]  = 
                        await Promise.all( [ _usersContacts, _accountIntegrations, _accountPayments, _smartBillIntegration ] );

        if(usersContacts.success && accountIntegrations.success && 
            accountPayments.success &&  smartBillIntegration.success ){

                return{

                    err: null,
                    data: {

                        usersContacts: usersContacts.content,
                        accountIntegrations: accountIntegrations.content,
                        accountPayments: accountPayments.content,
                        smartBillIntegration: smartBillIntegration.content
                    }
                }
        }
        else{

            return{

                err: 'Eroare preluare date integrari.',
                data: null
            }
        }
    }

    onSelectIntegration = (integrationsList, integration) => {

        switch(integration){

            case 'zoom':
                return integrationsList['data']['usersContacts'].findIndex(el => el['tip'] === 'zoom' && (el['zoom_access_token'] || '').length > 0 ) > -1;

            case 'skype':
                return integrationsList['data']['usersContacts'].findIndex(el => el['tip'] === 'skype') > -1;

            case 'gmeet':
                // return integrationsList['data']['usersContacts'].findIndex(el => el['tip'] === 'google') > -1;
                return integrationsList['data']['usersContacts'].findIndex(el => el['tip'] === 'google' && (el['google_access_token'] || '').length > 0 ) > -1;

            case 'office':
                return true;
        }
    }
}