import React from 'react';

import Masks from '../providers/Masks';
import Utils from '../providers/Utils';
import Declarations from '../providers/Declarations';
import { IoIosArrowForward } from 'react-icons/io';
import HourInterval from './HourInterval';
import LocationDesc from './LocationDesc';
import { MdPayment, MdThumbDown, MdThumbUp } from 'react-icons/md';

export default class PopupAppointElem extends React.Component{

    constructor(props){

        super(props);
    }

    M = new Masks();
    UT = new Utils();

    render(){

        return(

            <>
                <div className="popup-appoint-title">

                    <div className="popup-appoint-title-left">

                        <h3
                            style={{
                                margin: 0,
                                fontSize: '18px',
                                color: '#455A64',
                                textAlign: 'left'
                            }}
                        >
                            {this.props.appointElem['clie_nume'] + ' ' + this.props.appointElem['clie_prenume']}
                        </h3>

                        <div
                            className="row"
                            style={{
                                marginTop: 8
                            }}
                        >

                            <div className="col-l-1 col-m-1 col-s-1">

                                <div 
                                                                                
                                    style={{
                                        position: 'relative',
                                        width: '14px',
                                        height: '14px',
                                        left: '4px',
                                        // background: +alEl.draft === 0 ? serviceColor[0].col : '#455A64',
                                        background: +this.props.appointElem['draft'] === 1 && this.props.appointElem['src'] === 'ca' ? '#455A64' : this.props.svColor,
                                        borderRadius: '50%'
                                    }}
                                >
                                    
                                </div>

                            </div>

                            <div className="col-l-11 col-m-11 col-s-11">

                                <p
                                    style={{
                                        position: 'relative',
                                        margin: 0,
                                        top: -4,
                                        left: -4,
                                        textAlign: 'left',
                                        fontSize: '16px'
                                    }} 
                                >
                                    {this.props.appointElem['service_name']}
                                </p>

                            </div>

                        </div>

                    </div>

                    <div className="popup-appoint-title-right">

                        <IoIosArrowForward 
                            style={{
                                position: 'relative',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                color: '#323232',
                                fontSize: '30px',
                                cursor: 'pointer'
                            }}

                            onClick={

                                () => {

                                    this.props.onShowCurrentAppointModal(this.props.appointElem['token'])
                                }
                            }
                            
                        />

                    </div>

                    

                </div>

                <div
                    style={{
                        width: '100%',   
                    }}
                >
                    <HourInterval 
                        ora = {this.props.appointElem['ora_cal']}
                        durata = {this.props.appointElem['duration']}
                        justifyContent = 'none'
                        marginTop = '0px'
                        iconSize = '22px'
                        txSize = '16px'
                        topTx = '-1px'
                        leftTx = '10px'
                    />

                </div>

                <div className="row">

                    <div className="col-l-6 col-m-6 col-s-6">

                        <LocationDesc 
                            location = {this.props.appointElem['location'].trim()}
                            locationDesc = { this.props.descClient }
                            // distance = '22px'
                            marginTop = '5px'
                            zoomW = '22px' zoomH = '11px'
                            officeW = '22px' officeH = '22px'       
                            googleW = '24px' googleH = '24px' 
                            skypeSize = '22px'  
                            leftOfffice = '-1px'               
                            txSize = '16px'
                        />

                    </div>

                    <div className="col-l-6 col-m-6 col-s-6">

                        {
                            this.props.appointElem.location !== 'office' && (this.props.appointElem['host_meeting_link'] || '').length > 0 && (

                                <a
                                    href={this.props.appointElem['host_meeting_link']}
                                    style={{
                                        display: 'block',
                                        position: 'relative',
                                        right: 20,
                                        margin: 0,
                                        textAlign: 'right',
                                        color: '#2577C2',
                                        cursor: 'pointer'
                                    }}
                                >
                                    <u>Acceseaza</u>
                                </a>
                            )
                        }

                    </div>

                </div>
                
                {
                    this.props.member ? (

                        this.props.member.length > 0 ? (

                            <div
                                className="row"
                                style={{
                                    marginTop: 8
                                }}
                                // style={{
                                //     display: 'flex',
                                //     width: '100%',
                                //     marginTop: 8   
                                // }}
                            >
                                <div
                                    className="col-l-1 col-m-1 col-s-1"
                                >
            
                                    <div
                                        style={{
                                            position: 'relative',
                                            left: -5,
                                            width: '32.5px',
                                            height: '32.5px',
                                            borderRadius: '50%',
                                            background: 'lightblue',
                                            overflow: 'hidden'
                                        }}
                                    >
                                        <img 
                                            // src={CompanyAvatar} 
                                            alt="Avatar client" 
                                            style={{
                                                width: '100%',
                                                height: '100%'
                                            }}
                                        />
            
                                    </div>
            
                                </div>
            
                                <div
                                    // style={{
                                    //     width: '90%'
                                    // }}
                                    className="col-l-11 col-m-11 col-s-11"
                                >
            
                                    <p
                                        style={{
                                            margin: 0,
                                            fontSize: '16px',
                                            color: '#263238',
                                            textAlign: 'left'
                                        }}
                                    >
                                        {this.props.member[0].nume}
                                    </p>
            
                                    <p
                                        style={{
                                            margin: 0,
                                            fontSize: '9px',
                                            color: '#777777',
                                            textAlign: 'left'
                                        }}
                                    >
                                        {this.props.member[0].functie}
                                    </p>
            
                                </div>
            
                            </div>
                        ) : null

                    ) : null
                }

                <div
                    style={{
                        width: '100%',
                        marginTop: 5   
                    }}
                >
                    
                    <h3 
                        className="title"
                        style={{
                            textAlign: 'left',
                            margin: 0
                        }}
                    >
                        
                        <p 
                            style={{
                                    display: 'inline-block', 
                                    position: 'relative', 
                                    top: 4, 
                                    left: 0, 
                                    margin: 0,
                                    color: +this.props.appointElem.draft === 1 ? '#D3766A': '#31A81D',
                                    fontSize: '22px'
                                }}
                        >
                            {
                                +this.props.appointElem.price > 0 && (

                                    <MdPayment /> 
                                )
                            }

                            {
                                +this.props.appointElem.price < 1 && +this.props.appointElem.draft === 1 && (
                                    
                                    <MdThumbDown />
                                ) 
                            }

                            {
                                +this.props.appointElem.price < 1 && +this.props.appointElem.draft === 0 && (
                                    
                                    <MdThumbUp />
                                ) 
                            }
                        </p>

                        <p 
                            style={{
                                display: 'inline-block', 
                                position: 'relative', 
                                top: -1,
                                left: 10,
                                margin: 0,
                                color: +this.props.appointElem.draft === 1 ? '#D3766A': '#31A81D',
                                fontWeight: '400',
                                fontSize: '16px'
                            }}
                        >
                            {
                                +this.props.appointElem.price > 0 ? +this.props.appointElem.price +  ' Lei' : 'Gratuit' 
                                
                            }

                            {
                                ( +this.props.appointElem.draft === 0 && +this.props.payments.length > 0 ) ? ' - Platit' : ( +this.props.appointElem.draft === 0 && +this.props.payments.length === 0 )  ? ' - Confirmat': ' - Neconfirmat'
                            }


                        </p>
                        
                    </h3>
                    
                </div>

                <div
                    style={{
                        marginTop: 10,
                        marginBottom: 10,
                        width: '96%',
                        height: '1px',
                        background: '#EBEBEB'
                    }}
                >
                </div>
            
            </>
        );
    }
}