import React from 'react';

import { MdAlarmAdd, MdDeleteForever, MdKeyboardArrowRight } from 'react-icons/md';
import { RiCalendar2Fill } from 'react-icons/ri';

import Ws from '../../../../providers/Ws';
import Utils from '../../../../providers/Utils';
import Masks from '../../../../providers/Masks';
import LocalStorage from '../../../../providers/LocalStorage';
import { MobileView } from 'react-device-detect';
import MServiceNav from '../../../../commons/MServiceNav';
import { GoCheck } from 'react-icons/go';
import SuccessSaveToast from '../../../../commons/SuccessSaveToast';
import ErrorToast from '../../../../commons/ErrorToast';
import ErrorMessage from '../../../../commons/ErrorMessage';
import ScheduleManagerFuncs from '../../../logic/ScheduleManagerFuncs';
import Loader from '../../../../commons/Loader';

export default class MServiceSchedule extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            schedules: [],
            interval: [],
            showToastPopupError: false,
            actionTx: '',
            errorTx: '', 
            isLoading: true
        }
    }

    WS = new Ws();
    M = new Masks();
    UT = new Utils();
    SMF = new ScheduleManagerFuncs();

    componentDidMount(){

        window.scrollTo(0,0);
        this.getSchedules();
    }

    getSchedules = async () => {

        const ACC_TOKEN = new LocalStorage().GetAccountToken();

        //let selectSchedulesQuery = `select * from programs where account_tk = '${ACC_TOKEN}' and ifnull(sters,0)!= 1 `;

        let selectSchedulesQuery = `select * from programs where account_tk = '${ACC_TOKEN}' `;

        let schedules = await this.WS.sqlCommand(`select`, selectSchedulesQuery);
        let availableSchedules = schedules.content.filter(sEl => (+sEl['sters'] || 0) === 0);
        
        

        if(schedules.success){

            this.setState({

                schedules: availableSchedules,
                allSchedules: schedules.content,
                isLoading: false
            },
                () => {

                    // this.setScheduleInterval(schedules.content);
                }
            )
        }
        else{

            this.setState({

                errorTx: 'Eroare preluare programe din server.',
                isLoading: false
            });
            throw new Error("Error fetching schedules or services: " + schedules.message);
        }
    }

    goToScheduleManager = (scheduleTk) => {

        const token = scheduleTk || 'creare';

        

        this.props.history.push('/ca/dashboard/cab/program/' + token);
    }

    onGeneratePlaceholderName = () => {

        // const ACC_TOKEN = new LocalStorage().GetAccountToken();

        // let selectSchedules = `select * from programs where account_tk = '${ACC_TOKEN}' `;
        // let placeholderNameSchedules = await this.WS.sqlCommand(`select`, selectSchedules);

        let placeholderNameSchedules = this.state.allSchedules;
        placeholderNameSchedules = placeholderNameSchedules.filter(pnEl => pnEl['name'].includes('Program nou'));

        const length = placeholderNameSchedules.length;

        if(length > 0){

            return 'Program nou ' + (length + 1);
        }
        else{

            return 'Program nou';
        }
    }

    onCreateSchedule = async () => {

        const defaultSchedule = {

            allSchedules: this.state.allSchedules ,
            luStart: '08:00:00',
            luEnd: '20:00:00',
            maStart: '08:00:00',
            maEnd: '20:00:00',
            miStart: '08:00:00',
            miEnd: '20:00:00',
            joStart: '08:00:00',
            joEnd: '20:00:00',
            viStart: '08:00:00',
            viEnd: '20:00:00',
            saStart: null,
            saEnd: null,
            duStart: null,
            duEnd: null,
        };
        const creatingRez = await this.SMF.onSaveScheduleToDb(true, defaultSchedule);

        if(creatingRez.err !== null){

            this.setState({

                showToastPopupError: true,
                actionTx: creatingRez.err
            });
        }
        else{

            this.props.history.push('/ca/dashboard/cab/program/' + creatingRez['data']['scheduleToken']);
        }
    }

    onClosePopup = (type) => {

        this.setState({

            [type]: false
        })
    }

    render(){

        return(

            <MobileView>

            <div>

            {
                this.state.showToastPopupError && (

                    <ErrorToast 
                        action={this.state.actionTx}
                        onClosePopup = {this.onClosePopup}
                    />
                )
            }

                <div
                    style={{
                        position: 'relative'
                    }} 
                >

                    <MServiceNav 
                        icon={<RiCalendar2Fill />}
                        title='Programe Personalizate'
                        path={'/ca/dashboard/cab'}
                    />
                    
                    {/* {
                        this.state.canSave &&
                        <GoCheck
                            className="m-add-service"
                            onClick={this.saveToDb}
                        />
                    } */}


                </div>

                {
                    this.state.isLoading && (

                        <Loader 
                            fullPage = {true}
                        />
                    )
                }


               {
                   !this.state.isLoading && (

                    <div
                        style={{
                            padding: 20
                        }}   
                    >
                        {
                            this.state.errorTx.length > 0 && (

                                <div className="m-edit-s-card">

                                    <ErrorMessage 
                                        eroareTx = {this.state.errorTx}
                                    />

                                </div>
                            )
                        }

                        {
                            this.state.errorTx.length === 0 && (

                                <div className="m-edit-s-card">

                                {
                                    this.state.schedules && 
                                    this.state.schedules.map( (scEl, idx) => {

                                        const schedule = this.SMF.setScheduleInterval(scEl);

                                        return(
                                            <div key={`schedule-${idx}`}>

                                                <div
                                                    className="schedule-select-card"
                                                    style={{
                                                        border: '1px solid #EBEBEB',
                                                        textAlign: 'left',
                                                        padding: '14px'
                                                    }}
                                                    onClick={

                                                        () => {

                                                            this.goToScheduleManager(scEl.token)
                                                        }
                                                    }
                                                >
                                                    <div
                                                        style={{width: '91.66%'}}
                                                    >
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                fontSize: '14px',
                                                                fontWeight: '400',
                                                                color: '#263238'
                                                            }}
                                                        >
                                                            {scEl.name}
                                                        </p>
                                                        
                                                        {
                                                            schedule.map( (schEl, idx) => {

                                                                if(schEl.outcast === false && schEl.indisp === false){

                                                                    return(

                                                                        <p
                                                                            key={`schedule-day-${idx}`}
                                                                            style={{
                                                                                margin: 0,
                                                                                marginTop: '2px',
                                                                                fontSize: '10px',
                                                                                fontWeight: '400',
                                                                                color: '#263238'
                                                                            }}
                                                                        >
                                                                            {
                                                                                `${schEl.ziStart} - ${schEl.ziEnd} : ${schEl.hourStart} - ${schEl.hourEnd}` 
                                                                            }
                                                                        </p>
                                                                    )
                                                                }
                                                                else if(schEl.outcast === true && schEl.indisp === false){

                                                                    return(

                                                                        <p
                                                                            key={`schedule-day-${idx}`}
                                                                            style={{
                                                                                margin: 0,
                                                                                marginTop: '2px',
                                                                                fontSize: '10px',
                                                                                fontWeight: '400',
                                                                                color: '#263238'
                                                                            }}
                                                                        >
                                                                            {
                                                                                `${schEl.ziStart} : ${schEl.hourStart} - ${schEl.hourEnd}` 
                                                                            }
                                                                        </p>
                                                                    )
                                                                }
                                                                else if(schEl.outcast === false && schEl.indisp === true && ( ( schEl.ziStart !== 'S' ) || ( schEl.ziStart !== 'D' ) ) ){

                                                                    return(

                                                                        <p
                                                                            key={`schedule-day-${idx}`}
                                                                            style={{
                                                                                margin: 0,
                                                                                marginTop: '2px',
                                                                                fontSize: '10px',
                                                                                fontWeight: '400',
                                                                                color: '#263238'
                                                                            }}
                                                                        >
                                                                            {
                                                                                `${schEl.ziStart} : ` 
                                                                            }
                                                                            <span style={{color: '#DD6A57'}}>Indisponibil</span>
                                                                        </p>
                                                                    )
                                                                }
                                                            })
                                                        }

                                                    </div>

                                                    <div
                                                        style={{width: '8.33%', textAlign: 'right'}}
                                                    >
                                                        <MdKeyboardArrowRight   
                                                            style={{
                                                                fontSize: '40px',
                                                                color: '#44A5FF',
                                                                cursor: 'pointer'
                                                            }}
                                                        />
                                                    </div>

                                                </div>

                                                <br />

                                            </div>
                                        )
                                    })
                                }

                                <div 
                                    className="create-schedule-button"
                                    onClick={
                                        () => {

                                            this.onCreateSchedule()
                                        }
                                    }
                                >
                                    <MdAlarmAdd />
                                </div>

                                </div>
                            )
                        }

                    </div>
                   )
               }

            </div>

            </MobileView>
        )
    }
}