import React from 'react';

import { BrowserView } from 'react-device-detect';
import { FaSkype } from 'react-icons/fa';

import { IoIosArrowForward } from 'react-icons/io';
import { MdAlarm, MdClear } from 'react-icons/md';
import Declarations from '../../providers/Declarations';

import Masks from '../../providers/Masks';
import Utils from '../../providers/Utils';
import Ws from '../../providers/Ws';
import CrAppoAddBreak from './create_appointment_popup/CrAppoAddBreak';
import CrAppoAddBreakSuccess from './create_appointment_popup/CrAppoAddBreakSuccess';
import CrAppoCalendar from './create_appointment_popup/CrAppoCalendar';
import CrAppoClientForm from './create_appointment_popup/CrAppoClientForm';
import CrAppoCreateSuccessful from './create_appointment_popup/CrAppoCreateSuccessful';
import CrAppoShowService from './create_appointment_popup/CrAppoShowService';

export default class CreateAppointPopup extends React.Component{

    constructor(props){

        super(props);

        this.state ={

            serviceDetails: {},
            headerTitle: 'Selecteaza Pauza sau serviciul pe care doresti sa il programezi',
            showServices: true,
            showAddBreak: false,
            showCalendar: false,
            showAddClientData: false,
            showAppointmentSuccess: false,
            showAddBreakSuccess: false,
            breakDuration: 0,
            scheduledDate: '',
            tranToken: '' //GIASMyKgpFYCVeoEFP6oVikzHfa7cLQW
        }
    }

    WS = new Ws();
    UT = new Utils();
    M = new Masks();

    localData = {

        locatiiIntalnire: new Declarations().listaLocatiiIntalnire
    }

    componentDidMount(){


    }

    onShowAddBreak = () => {

        this.setState({

            headerTitle: 'Selecteaza durata pauzei pe care doresti sa o adaugi',
            showAddBreak: true,
            showServices: false,
            showCalendar: false,
            showAddClientData: false,
            showAppointmentSuccess: false,
            showAddBreakSuccess: false
        })
    }

    onShowCalendar = (duration, service) => {

        let serviceDetails = {};

        

        if(service){

            if(Object.keys(service).length > 0){

                

                serviceDetails = service;
            }
        }

        

        this.setState({

            headerTitle: 'Selecteaza data si ora la care doresti sa adaugi acest eveniment in calendarul tau.',
            showCalendar: true,
            showAddBreakSuccess: false,
            showServices: false,
            showAddBreak: false,
            showAddClientData: false,
            showAppointmentSuccess: false,
            breakDuration: duration,
            serviceDetails: service
        })
    }

    onShowBreakAddSuccess = (result) => {

        let humanReadableDate = this.M.dayOfWeek(this.M.sqlDateToJSDate(result['date']).getDay()) + ' ' +
        this.M.sqlDateToJSDate(result['date']).getDate() + ' ' +
        this.M.monthName(this.M.sqlDateToJSDate(result['date']).getMonth()) + ', Ora ' +
        result['hour'].substr(0,5);

        this.setState({

            headerTitle: '',
            showAddBreakSuccess: true,
            showCalendar: false,  
            showServices: false,
            showAddBreak: false,
            showAddClientData: false,
            showAppointmentSuccess: false,
            scheduledDate: humanReadableDate
        })
    }

    onShowClientForm = (tranTokenClient) => {

        this.setState({

            headerTitle: 'Completeaza datele de contact ale clientului si o descriere a invitatiei la aceasta programare.',
            showAddClientData: true,
            showCalendar: false,
            showAddBreakSuccess: false,
            showServices: false,
            showAddBreak: false,
            showAppointmentSuccess: false,
            tranToken: tranTokenClient
        })
    }

    onShowClientAppointSuccess = (tranTokenClient) => {

        this.setState({

            headerTitle: '',
            showAppointmentSuccess: true,
            showAddClientData: false,
            showCalendar: false,
            showAddBreakSuccess: false,
            showServices: false,
            showAddBreak: false,
            tranToken: tranTokenClient
        })
    }

    onRemoveHeaderTitle = () => {

        this.setState({

            headerTitle: '',
        })
    }

    onCloseCreateAppointPopup = (ev) => {

        if( ev.target.id === 'modal-popup' ||  ev.target.id === 'close-modal-btn'){

            this.props.onCloseCreateAppointPopup();
        }
  
        
    }

    onUpdateCal = () => {

        this.props.onUpdateCal();
    }

    render(){

        return(

            <BrowserView>
            
            <div 
                id="modal-popup"
                className="calendar-modal-container"
                onClick={(ev) => {this.onCloseCreateAppointPopup(ev)}}
            >

                <div 
                    className="calendar-popup-card"
                    style={{
                        width: '500px',
                        height: 'auto',
                        // height: this.state.showAddBreak || this.state.showAddBreakSuccess ? '250px' : '100vh',
                        // maxHeight: '80%',
                        // overflowY: 'auto',
                        textAlign: 'left',
                        padding: '20px 24px 20px 40px'
                    }}
                >

                    <MdClear 
                        id="close-modal-btn"
                        style={{
                            position: 'absolute',
                            top: 10,
                            right: 10,
                            fontSize: '24px',
                            color: '#323232',
                            cursor: 'pointer'
                        }}
                        onClick={(ev) => {this.onCloseCreateAppointPopup(ev)}}

                    />

                    <p 
                        style={{
                            margin: 0,
                            marginTop: 22,
                            fontSize: '16px',
                            fontWeight: '400',
                            color: '#455A64'
                        }}
                    >
                        {this.state.headerTitle}
                    </p>

                    {/* <br /> */}

                    {
                        this.state.showServices && (

                            <div
                                style={{
                                    marginTop: '20px'
                                }}
                            >
                                <div
                                    style={{
                                        width: '100%',
                                        height: '1px',
                                        background: '#EBEBEB'
                                    }}
                                ></div>
        
                                <br />
        
                                <div>
        
                                    <div 
                                        style={{
                                            
                                            display: 'flex'
                                        }}
                                    >
        
                                        <div
                                            style={{
                                                width: '91.66%'
                                            }}
                                        >
                                            <p
                                                style={{
                                                    position: 'relative',
                                                    top: '50%',
                                                    transform: 'translateY(-50%)',
                                                    margin: 0,
                                                    fontSize: '14px',
                                                    fontWeight: '700',
                                                    color: '#000'
                                                }}
                                            >
                                                Adauga Pauza
                                            </p>
                                        </div>
        
                                        <div
                                            style={{
                                                position: 'relative',
                                                width: '8.33%'
                                            }}
                                        >   
        
                                            <IoIosArrowForward 
                                                style={{
                                                    position: 'relative',
                                                    top: '50%',
                                                    transform: 'translateY(-50%)',
                                                    right: 0,
                                                    margin: 0,
                                                    fontSize: 32,
                                                    color: '#323232',
                                                    cursor: 'pointer'
                                                }}
                                                onClick={this.onShowAddBreak}
                                            />
        
                                        </div>
        
                                    </div>
        
                                    <br />
        
                                    <div
                                        style={{
                                            width: '100%',
                                            height: '1px',
                                            background: '#EBEBEB'
                                        }}
                                    ></div>
        
                                </div>
                                
                                {
                                    this.props.servicesList &&
                                    this.props.servicesList.length > 0 && 
                                    this.props.servicesList.map( (sEl, idx) => {
        
                                        let locationName = this.localData.locatiiIntalnire.filter(liEl => liEl.id === (sEl.location || '').trim())
        
                                        if((+sEl.deleted === 0 && +sEl.draft === 0) || (sEl.deleted === null && sEl.draft === null)){

                                            return(
        
                                                <div key={`popup-service-no-${idx}`}>
            
                                                    <CrAppoShowService 
                                                        name = {sEl.name}
                                                        duration = {sEl.duration}
                                                        location = {sEl.location}
                                                        locationName = {locationName[0].descClient}
                                                        price = {sEl.price}
                                                        description = {sEl.description}
                                                        serviceDetails={sEl}
                                                        onShowCalendar={this.onShowCalendar}
                                                    />
            
                                                    {
                                                        idx < this.props.servicesList.length - 1 && (
    
                                                            <div
                                                                style={{
                                                                    width: '100%',
                                                                    height: '1px',
                                                                    background: '#EBEBEB'
                                                                }}
                                                            ></div>
                                                        )
                                                    }
            
                                                </div>
                                            )
                                        }
                                    })
                                }
        
                            </div>
                        )
                    }

                    {
                        this.state.showAddBreak && (

                            <CrAppoAddBreak 
                                onShowCalendar = {this.onShowCalendar}
                                onCloseCreateAppointPopup = {this.onCloseCreateAppointPopup}
                            />
                        )
                    }

                    {
                        this.state.showCalendar && (

                            <CrAppoCalendar 
                                breakDuration = {this.state.breakDuration}
                                serviceDetails = {this.state.serviceDetails}
                                onShowBreakAddSuccess = {this.onShowBreakAddSuccess}
                                onShowClientForm = {this.onShowClientForm}
                                onCloseCreateAppointPopup = {this.onCloseCreateAppointPopup}
                                onRemoveHeaderTitle = {this.onRemoveHeaderTitle}
                                dayCal = {this.props.dayCal}
                                dataStart = {this.props.dataStart}
                                dataEnd = {this.props.dataEnd}
                            />
                        )
                    }

                    {
                        this.state.showAddBreakSuccess && (

                            <CrAppoAddBreakSuccess 
                            
                                breakDuration = {this.state.breakDuration}
                                data = {this.state.scheduledDate}
                                onCloseCreateAppointPopup = {this.onCloseCreateAppointPopup}
                                onUpdateCal = {this.onUpdateCal}

                            />
                        )
                        
                    }

                    {
                        this.state.showAddClientData && (

                            <CrAppoClientForm 
                                tranToken = {this.state.tranToken}
                                onShowClientAppointSuccess = {this.onShowClientAppointSuccess}
                                onCloseCreateAppointPopup = {this.onCloseCreateAppointPopup}
                                onUpdateCal = {this.onUpdateCal}
                            />
                        )
                    }

                    {
                        this.state.showAppointmentSuccess && (

                            <CrAppoCreateSuccessful 
                                tranToken = {this.state.tranToken}
                                onCloseCreateAppointPopup = {this.onCloseCreateAppointPopup}
                                
                            />
                        )
                    }
                    </div>
                    
                </div>
            
            </BrowserView>
        )
    }
}