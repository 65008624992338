import React from 'react';

import { IoIosArrowForward } from 'react-icons/io';
import ButtonForm from '../../../commons/ButtonForm';
import IconText from '../../../commons/IconText';
import { ReactComponent as PinDrop } from '../../../assets/pin_drop.svg';

import Masks from '../../../providers/Masks';
import Utils from '../../../providers/Utils';
import Ws from '../../../providers/Ws';
import ServicesFuncs from '../../logic/ServicesFuncs';
import RegisterHeader from '../RegisterHeader';
import SvLocation from '../../../commons/service/SvLocation';
import Declarations from '../../../providers/Declarations';
import { RiFolderReduceFill } from 'react-icons/ri';
import SvNameAndLink from '../../../commons/service/SvNameAndLink';
import SvDescription from '../../../commons/service/SvDescription';
import ErrorToast from '../../../commons/ErrorToast';
import { BrowserView, MobileView } from 'react-device-detect';
import ButtonsNavRegister from '../../../commons/ButtonsNavRegister';

import CanaryPlantImage from '../../../assets/canary_plant_bg.png';
import MBirdAndPlantImage from '../../../assets/bird_and_plant_mobile.png';
import LocalStorage from '../../../providers/LocalStorage';
import Loader from '../../../commons/Loader';

export default class SvInfoConfig extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            actionTx: '',
            eroareTx: '',
            svName: '',
            svLink: '',
            svDesc: '',
            serviceTk: '',
            accLink: '',

            triggerSave: false,
            isLoading: true
        };
    }

    componentData = {...new Declarations().serviceData['svNameAndLink'], ...new Declarations().serviceData['description']}

    componentStatus = {

        isNameAndLinkOk: false,
        isDescrOk: true
    }
    WS = new Ws();
    M = new Masks();
    UT = new Utils();
    SF = new ServicesFuncs();

    componentDidMount(){

        this.onGetService();
    }

    onGetService = async () => {

        const TOKEN_CONT = new LocalStorage().GetAccountToken();

        

        let querySelectService = `select * from services where account_tk = '${TOKEN_CONT}' `;
        let service = await this.WS.sqlCommand(`select`, querySelectService);

        if(service.success){

            

            if(service.content[0]['name'] !== null && service.content[0]['name'] !== undefined &&
                 service.content[0]['web_link'] !== null && service.content[0]['web_link'] !== undefined
            ){

                if(service.content[0]['name'].length > 0 && service.content[0]['web_link'].length > 0 ){

                    this.componentStatus.isNameAndLinkOk = true;
                    this.componentData = new Declarations().updateComponentsData(this.componentData, service.content[0]);
                    // this.componentData.denumire = service.content[0]['name'];
                    // this.componentData.link = service.content[0]['web_link'];
                    // this.componentData.denumire = service.content[0]['description'];
                }
                
            } 

            let accLink = await this.UT.getAccountLink();

            

            this.setState({

                serviceTk: service.content[0]['token'],
                svName: service.content[0]['name'] || '',
                svLink: service.content[0]['web_link'] || '',
                svDesc: service.content[0]['description'] || '',
                accLink: accLink
            })
        }

        this.setState({

            isLoading: false
        })
    }

    inputStatus = (status, statusName, inputName, data, isVerify = false) => {

        if(isVerify){

            this.componentStatus[statusName] = data;
        }
        else{

            this.componentData[inputName] = data;

            
    
            this.setState({
                
                [statusName]: status
            },
                () => {
    
                    // this.checkSaveButtonStatus();
                }
            );
        }
    }

    showToastError = (message) => {

        this.setState({

            showToastPopupError: true,
            actionTx: message
        });
    }

    onClosePopup = (type) => {

        this.setState({

            [type]: false
        })
    }

    onCheckAndSave = async () => {

        const result = Object.values(this.componentStatus).every(item => item === true);;
        const TOKEN_CONT = new LocalStorage().GetAccountToken();

        
        
        if(result){

            // const queryObject = new Declarations().generateQuerySet('profile', this.componentsData);
            // const queryArr = new Declarations().onGenerateQueryArray(queryObject);

            let queryUpdateService = `update services set 
                    name = '${this.componentData.denumire}', web_link = '${this.componentData.link.toLowerCase()}', 
                    description = '${this.componentData.descriereSv}' where token = '${this.state.serviceTk}'
                `;

            let updateService = await this.WS.sqlCommand(`update`, queryUpdateService);

            if(updateService.success){

                // this.props.history.push('');
                this.props.history.push("/inregistrare/serviciu/locatie-serviciu");
            }
        }
        else{

            
        }
    }

    onTriggerSave = () => {

        this.setState({

            triggerSave: true
        },
        
            () => {

                this.onCheckAndSave();
            }
        )
    }

    
    render(){

        if(this.state.isLoading){

            return(

                <Loader 
                    fullPage = {true}
                />
            );
        }

        return(
            <>
            
            <BrowserView>

                <div className='register-container-desktop'>

                    {
                        this.state.showToastPopupError && (

                            <ErrorToast 
                                action={this.state.actionTx}
                                onClosePopup = {this.onClosePopup}
                            />
                        )
                    }

                    <RegisterHeader  isServiceStep = {true}/>

                    <div className='service-details-card' style={{marginTop: '20px', textAlign: 'left'}}>


                        <div className='row'>

                            <div className='col-l-6 col-m-6 col-s-6'>

                                <IconText 
                                    icon = {
                                        <RiFolderReduceFill 
                                            style={{
                                                fontSize: '22px',
                                                color: '#FFC727'
                                            }}
                                        />
                                    }
                                    tx = 'Denumire Serviciu si Link'
                                    txSize = '16px'
                                    txWeight = '500'
                                    txCol = '#455A64'
                                />         

                                <p 
                                    className='register-tx'
                                    style={{marginTop: '16px'}}
                                >

                                    Numele programarii sau serviciului tau, asa cum va vedea clientul tau in panoul de Programari.
                                    
                                </p>

                                <SvNameAndLink 
                                    name = {this.state.svName}
                                    link = {this.state.svLink}
                                    token = {this.state.serviceTk}
                                    accountLink = {this.state.accLink}
                                    hideOptions = {true}
                                    fontSize = '14px'
                                    inputStatus = {this.inputStatus}
                                    showToastError = {this.showToastError}
                                    // onCopyLinkToClipBoard = {this.onCopyLinkToClipBoard}
                                    // onShowToastSocial = {this.onShowToastSocial}
                                    triggerSave = {this.state.triggerSave}
                                    
                                    style={{
                                        marginTop: '16px'
                                    }}
                                />

                            </div>

                            <div className='col-l-6 col-m-6 col-s-6' style={{paddingLeft: '20px'}}>

                                <p className='register-tx register-title' style={{color: '#455A64'}}>

                                    Scurta Descriere
                                </p>

                                <p className='register-tx' style={{marginTop: '16px'}}>

                                    Scurta descriere a programarii sau serviciului tau, asa 
                                    cum va vedea clientul tau in panoul de Programari.
                                </p>

                                <SvDescription 
                                    descriere = {this.state.svDesc}
                                    triggerSave = {this.state.triggerSave}
                                    // showToastError = {this.showToastError}
                                    inputStatus = {this.inputStatus}

                                    style={{
                                        marginTop: '16px'
                                    }}
                                />

                                <ButtonForm 
                                    type='button'
                                    tx={
                                        <>
                                            <p style={{display: 'inline-block', position: 'relative', top: '-2px', margin: 0}}>
                                                Continua &nbsp;
                                            </p> 
                                            <IoIosArrowForward className='register-next-arrow'/>
                                        </>
                                    }
                                    
                                    style={{
                                        marginTop: '20px',
                                        width: 'auto',
                                        height: '56px',
                                        background: '#F8BC05',
                                        fontSize: '16px',
                                        padding: '0px 35px 0px 35px',
                                    }}
                                    onClick={
                                        this.onTriggerSave
                                    }
                                />
                                
                            </div>


                        </div>

                    </div>

                    <img 
                        className='bottom-pg-img'
                        src={CanaryPlantImage}
                        alt = 'Planary...'
                        style={{
                            marginTop: '20px'
                        }}
                    />

                </div>

            </BrowserView>

             <MobileView>

                <div 
                    style={{
                        padding: '20px',
                        minHeight: '1100px',
                        background: `url(${MBirdAndPlantImage}) bottom no-repeat`
                    }}
                >
                    
                    <RegisterHeader isServiceStep = {true} />
                    
                    <div className="m-edit-s-card" style={{marginTop: '20px', textAlign: 'left'}}>

                        <IconText 
                            icon = {
                                <RiFolderReduceFill 
                                    style={{
                                        fontSize: '22px',
                                        color: '#FFC727'
                                    }}
                                />
                            }
                            tx = 'Denumire Serviciu si Link'
                            txSize = '16px'
                            txWeight = '500'
                            txCol = '#455A64'
                        />         

                        <p 
                            className='register-tx sm'
                            style={{marginTop: '16px'}}
                        >

                            Numele programarii sau serviciului tau, asa cum va vedea clientul tau in panoul de Programari.
                            
                        </p>

                        <SvNameAndLink 
                            name = {this.state.svName}
                            link = {this.state.svLink}
                            token = {this.state.serviceTk}
                            accountLink = {this.state.accLink}
                            hideOptions = {true}
                            fontSize = '14px'
                            inputStatus = {this.inputStatus}
                            showToastError = {this.showToastError}
                            // onCopyLinkToClipBoard = {this.onCopyLinkToClipBoard}
                            // onShowToastSocial = {this.onShowToastSocial}
                            triggerSave = {this.state.triggerSave}
                            
                            style={{
                                marginTop: '16px'
                            }}
                        />

                        <p className='register-tx sm register-title bold' style={{color: '#455A64'}}>

                            Scurta Descriere
                        </p>

                        <p className='register-tx sm' style={{marginTop: '16px'}}>

                            Scurta descriere a programarii sau serviciului tau, asa 
                            cum va vedea clientul tau in panoul de Programari.
                        </p>

                        <SvDescription 
                            descriere = {this.state.svDesc}
                            triggerSave = {this.state.triggerSave}
                            // showToastError = {this.showToastError}
                            inputStatus = {this.inputStatus}

                            style={{
                                marginTop: '16px'
                            }}
                        />

                        
                        <ButtonsNavRegister 
                            tx = 'Continua'
                            onPressBtn = {this.onTriggerSave}
                            onBack = {this.props.history.goBack}
                        />

                    </div>
                    
                </div>

            </MobileView>

            </>
        );
    }
}