import React from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import ButtonForm from '../../../commons/ButtonForm';
import ScheduleName from '../../../commons/schedule/ScheduleName';
import Declarations from '../../../providers/Declarations';

import Masks from '../../../providers/Masks';
import Utils from '../../../providers/Utils';
import Ws from '../../../providers/Ws';
import ServicesFuncs from '../../logic/ServicesFuncs';
import RegisterHeader from '../RegisterHeader';

import Image from '../../../assets/calendar_bg.png';
import ScheduleConfig from '../../../commons/schedule/ScheduleConfig';
import IconText from '../../../commons/IconText';
import { RiCalendar2Fill } from 'react-icons/ri';
import { BrowserView, MobileView } from 'react-device-detect';
import ButtonsNavRegister from '../../../commons/ButtonsNavRegister';

import CanaryPlantImage from '../../../assets/canary_plant_bg.png';
import MBirdAndPlantImage from '../../../assets/bird_and_plant_mobile.png';
import LocalStorage from '../../../providers/LocalStorage';
import ErrorToast from '../../../commons/ErrorToast';
import ScheduleManagerFuncs from '../../logic/ScheduleManagerFuncs';
import Loader from '../../../commons/Loader';

export default class FirstSchedule extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            triggerSave: false,
            showToastPopupError: false,
            actionTx: '',

            scheduleName: '',
            schedules: [],
            selectedSchedule: [],
            selectedScheduleTk: '',
            isLoading: true,
        }
    }

    WS = new Ws();
    UT = new Utils();
    M = new Masks();
    SF = new ServicesFuncs();
    SMF = new ScheduleManagerFuncs();

    componentsData = new Declarations().scheduleData;

    componentsStatus = {

        isNameOk: false,
        isScheudleOk: true
    }

    componentDidMount(){

        this.onGetSchedule();
    }

    inputStatus = (status, statusName, inputName, data, isVerify = false) => {

        if(isVerify){

            this.componentsStatus[statusName] = data;
        }
        else{

            this.componentsData[inputName] = data;

            
    
            this.setState({
                
                [statusName]: status
            },
                () => {
    
                    // this.changeSaveButtonStatus();
                }
            );
        }
    }

    onGetSchedule = async () => {

        const ACC_TOKEN = new LocalStorage().GetAccountToken();

        let selectSchedulesQuery = `select * from programs where account_tk = '${ACC_TOKEN}' and ifnull(sters,0)!= 1 `;
        let scheduleFromDb = await this.WS.sqlCommand(`select`, selectSchedulesQuery);

        if(scheduleFromDb.success){

            let schedule = [];

            

            if(scheduleFromDb.content[0] !== null && scheduleFromDb.content[0] !== undefined){

                schedule.push(scheduleFromDb.content[0]);

                if(schedule.length > 0){

                    for(let key in schedule[0]){
    
                        if( ( (schedule[0][key] || '').length < 2 ) || (schedule[0][key] === null ) ||  ( (schedule[0][key] || '') === '00:00:00') ){
        
                            schedule[0][key] = null;
                        }
                    }
        
                    this.componentsData = new Declarations().updateComponentsData(this.componentsData, schedule[0], 'schedule');

                    

                    this.componentsStatus.isNameOk = true;
    
                    this.setState({
    
                        selectedSchedule: schedule,
                        selectedScheduleTk: schedule[0]['token']
                    })
                }
            }
        }

        this.setState({

            isLoading: false
        });
    }

    onSaveToDb = async (isCreate) => {

        const componentsDataWithScheduleTk = {
            ...this.componentsData, 
            selectedScheduleTk: this.state.selectedScheduleTk,
            allSchedules: this.state.allSchedules
        };

        const savingRez = await this.SMF.onSaveScheduleToDb(isCreate, componentsDataWithScheduleTk);

        if(savingRez.err !== null){

            this.setState({

                showToastPopupError: true,
                actionTx: savingRez.err
            });
        }
        else{

            this.props.history.push('/inregistrare/serviciu/integrari-aplicatii')
            
        }
    }

    onCheckAndSave = async (isCreate) => {

        const result = Object.values(this.componentsStatus).every(item => item === true)

        
        
        if(result){

            if(isCreate){
            
                // 
                await this.onSaveToDb(true); //await
            }
            else if(!isCreate){
                
                await this.onSaveToDb(false);
            }
        }
        else{

            this.setState({

                triggerSave: false
            });
        }

    }

    onTriggerSave = () => {

        let status = this.state.selectedSchedule.length > 0 ? false : true;

        this.setState({

            triggerSave: true
        },
            async () => {

                await this.onCheckAndSave(status);
            }
        );
    }

    showToastError = (errorTx) => {

        this.setState({

            showToastPopupError: true,
            actionTx: errorTx
        });
    }

    onClosePopup = (popupType) => {

        this.setState({

            [popupType]: false
        })
    }

    render(){

        if(this.state.isLoading){

            return(

                <Loader 
                    fullPage = {true}
                />
            );
        }

        return(
            <>

              {
                    this.state.showToastPopupError && (

                        <ErrorToast 
                            action={this.state.actionTx}
                            onClosePopup = {this.onClosePopup}
                        />
                    )
                }


            <BrowserView>

                <div className='register-container-desktop'>

                    <RegisterHeader 
                        isServiceStep = {true}
                    />

                    <div className='service-details-card' style={{marginTop: '20px', textAlign: 'left'}}>

                        <div className='row'>

                            <div className='col-l-6 col-m-6 col-s-6'>

                                <IconText 
                                    icon = {
                                        <RiCalendar2Fill 
                                            style={{
                                                color: 'rgb(255, 199, 39)',
                                                fontSize: '20px'
                                            }}
                                        />
                                    }
                                    tx = 'Program'
                                    txSize = '16px'
                                    txWeight = '500'
                                    txCol = '#455A64'
                                />

                                <p className='register-tx' style={{marginTop: '16px'}}>
                                    Creaza un nume pentru programul tau, pentru a-l putea identifica usor.
                                </p>

                                <ScheduleName 
                                    triggerSave = {this.state.triggerSave}
                                    inputStatus = {this.inputStatus}
                                    scheduleName = {this.state.selectedSchedule !== null && this.state.selectedSchedule !== undefined && this.state.selectedSchedule.length > 0 ?
                                    this.state.selectedSchedule[0]['name'] : ''
                                    }
                                    schedules = {this.state.schedules}
                                    selectedScheduleTk = ''

                                    style={{
                                        marginTop: '16px'
                                    }}
                                />

                                <div 
                                    className='separator-line'
                                    style={{
                                        marginTop: '16px'
                                    }}
                                ></div>

                                <p className='register-tx' style={{marginTop: '16px'}}>
                                    Programul de Baza este primul tip de disponibilitate pe care il ofera Planary. 
                                    <br /> <br />
                                    Vei putea persoanliza si mai mult disponibilitatea ta pe servicii si poti adauga chiar perioade de Vacanta.
                                    <br /> <br />
                                    Completeaza disponibilitatea ta in zona alaturata.
                                </p>

                                <img
                                    className='register-image'
                                    src={Image}
                                    alt="Planary"
                                />

                            </div>

                            <div className='col-l-6 col-m-6 col-s-6' style={{paddingLeft: '20px'}}>

                                <ScheduleConfig 
                                    selectedSchedule = {this.state.selectedSchedule}
                                    triggerSave = {this.state.triggerSave}
                                    showSave = {false}
                                    inputStatus = {this.inputStatus}
                                    resetStatus = {this.resetStatus}
                                    showToastError = {this.showToastError}
                                    onCheckAndSave = {this.onTriggerSave}
                                />

                                <ButtonForm 
                                    type='button'
                                    tx={
                                        <>
                                            <p style={{display: 'inline-block', position: 'relative', top: '-2px', margin: 0}}>
                                                Continua &nbsp;
                                            </p> 
                                            <IoIosArrowForward className='register-next-arrow'/>
                                        </>
                                    }
                                    
                                    style={{
                                        marginTop: '20px',
                                        width: 'auto',
                                        height: '56px',
                                        background: '#F8BC05',
                                        fontSize: '16px',
                                        padding: '0px 35px 0px 35px'
                                    }}
                                    onClick={
                                        () => {

                                            this.onTriggerSave()
                                        }
                                    }
                                />
                                
                            </div>


                        </div>

                    </div>

                    <img 
                        className='bottom-pg-img'
                        src={CanaryPlantImage}
                        alt = 'Planary...'
                        style={{
                            marginTop: '20px'
                        }}
                    />

                </div>

            </BrowserView>

            <MobileView>

                <div 
                    style={{
                        padding: '20px',
                        minHeight: '1250px',
                        background: `url(${MBirdAndPlantImage}) bottom no-repeat`
                    }}
                >
                
                    <RegisterHeader 
                        isServiceStep = {true}
                    />
                    
                    <div className="m-edit-s-card" style={{marginTop: '20px', textAlign: 'left'}}>
                        
                        

                        <IconText 
                            icon = {
                                <RiCalendar2Fill 
                                    style={{
                                        color: 'rgb(255, 199, 39)',
                                        fontSize: '20px'
                                    }}
                                />
                            }
                            tx = 'Program'
                            txSize = '16px'
                            txWeight = '500'
                            txCol = '#455A64'
                        />  

                        <p className='register-tx sm' style={{marginTop: '16px'}}>
                            Programul de Baza este primul tip de disponibilitate pe care il ofera Planary. 
                            Vei putea persoanliza si mai mult disponibilitatea ta pe servicii si poti adauga chiar perioade de Vacanta.
                            Completeaza disponibilitatea ta in zona alaturata.
                        </p> 

                        <ScheduleName 
                            triggerSave = {this.state.triggerSave}
                            inputStatus = {this.inputStatus}
                            scheduleName = {this.state.selectedSchedule !== null && this.state.selectedSchedule !== undefined && this.state.selectedSchedule.length > 0 ?
                                this.state.selectedSchedule[0]['name'] : ''
                                }
                            schedules = {this.state.schedules}
                            selectedScheduleTk = ''

                            style={{
                                marginTop: '16px'
                            }}
                        />

                        <div 
                            className='separator-line'
                            style={{
                                marginTop: '16px',
                                marginBottom: '16px'
                            }}
                        ></div> 

                        <ScheduleConfig 
                            selectedSchedule = {this.state.selectedSchedule}
                            triggerSave = {this.state.triggerSave}
                            showSave = {false}
                            inputStatus = {this.inputStatus}
                            resetStatus = {this.resetStatus}
                            showToastError = {this.showToastError}
                            onCheckAndSave = {this.onTriggerSave}
                        />  
                        
                        <ButtonsNavRegister 
                            tx = 'Continua'
                            onPressBtn={
                                () => {

                                    this.onTriggerSave()
                                }
                            }
                            onBack = {this.props.history.goBack} 
                        />

                    </div>

                </div>

            </MobileView>

            </>
        );
    }
}