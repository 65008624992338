import React from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import Masks from '../../providers/Masks';
import Utils from '../../providers/Utils';
import Ws from '../../providers/Ws';
import ConfigSwitch from '../ConfigSwitch';
import MSwitch from '../MSwitch';
import TextareaDescription from '../TextareaDescription';

export default class SvTermsAndConditions extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            hasCancel: this.props.serviceDetails['has_cancel_policy'] || 0,
            descriere: this.props.serviceDetails['cancel_policy_text'] || '',
            descLength: 0,

            hasCancelTmp: this.props.serviceDetails['has_cancel_policy'] || 0,
            descriereTmp:  this.props.serviceDetails['cancel_policy_text'] || '',
        };
    }

    WS = new Ws();
    M = new Masks();
    UT = new Utils();

    componentDidMount(){

        this.setDescriereServiciu();
    }

    componentDidUpdate(prevProps, _){

        if(prevProps.triggerSave !== this.props.triggerSave){

            this.onCheckDataValidity();
        }

        if(prevProps['serviceDetails'] !== this.props['serviceDetails']){

            this.setState({

                reviewCerereTmp: this.props.serviceDetails['has_cancel_policy'] || 0,
                descriereTmp: this.props.serviceDetails['cancel_policy_text'] || '',
            });
        }
    }

    setTermsAndConditionsData = () => {

        this.setState({

            descLength: this.setDescriereServiciu()
        });
    }

    onChangeSwitch = () => {

        let doSave = true;

        if(+this.state.hasCancelTmp === +!this.state.hasCancel){

            doSave = false;
        }

        this.setState({

            hasCancel: !+this.state.hasCancel,
            
        },
        
            () => {

                this.props.inputStatus(doSave, 'hasCancelStatus', 'hasCancel', this.state.hasCancel ? 1 : 0);

                let duration = 500;
                clearTimeout(this.inputTimer);
                this.inputTimer = setTimeout( ()=> {

                    this.onCheckDataValidity();
                    
                }, duration);
            }
        );
    }

    setDescriereServiciu = () => {

        let length = this.state.descriere.replaceAll(/\s/g,'').length;

        return length;
    }

    onChangeDescriere = (ev) => {

        let count = ev.target.value.replaceAll(/\s/g,'').length;
        let doSave = true;

        if(count <= 500){
            
            if(this.state.descriereTmp === ev.target.value){

                doSave = false;
            }

            this.setState({

                descriere: ev.target.value,
                descLength: +count
            },
                () => {

                    this.props.inputStatus(doSave, 'cancelDescStatus', 'cancelDesc', ev.target.value);

                    let duration = 500;
                    clearTimeout(this.inputTimer);
                    this.inputTimer = setTimeout( ()=> {

                        this.onCheckDataValidity();
                        
                    }, duration);
                }
            );
        }
        else{

            const errorTx = 'Ai atins limita de caractere a termenilor si conditiilor!';
            this.props.showToastError(errorTx);
            // alert('Limita caractere atinsa!')
        }

    }

    onCheckDataValidity = () => {

        if(this.state.descriere.length < 1 && this.state.hasCancel){

            const errorTx = 'Trebuie sa existe un mesaj de review pentru a putea fi activat.';
            this.props.inputStatus(true, 'isCancelOk', 'isCancelOk', false, true);
            this.props.onAddErrorMessage(errorTx);
        }
        else {
            
            this.props.inputStatus(true, 'isCancelOk', 'isCancelOk', true, true);
        }

        // let result = {};

        // if(this.state.descriere.length < 1){

        //     result = {

        //         data: null, 
        //         err: 'Trebuie sa existe un mesaj de review pentru a putea fi activat.'
        //     }
        // }
        // else {
            
        //     result = this.onGenerateQuery();
        // }

        // this.props.onCheckAndSave(result);
    }

    onGenerateQuery = () => {

        const switchDigit = this.state.hasCancel ? 1 : 0;

        const result = {

            data: [
                {
                    itemName: 'has_cancel_policy',
                    itemData: `${switchDigit}`
                },
                
                {
                    itemName: 'cancel_policy_text',
                    itemData: `'` + this.state.descriere + `'`
                },
            ],
            err: null
        }

        return result;
    }

    render(){

        return(

            <>
                <BrowserView>
                
                    <div className="form-control">

                        <ConfigSwitch 
                            title={this.state.hasCancel ? 'Functionalitate Activa' : 'Functionalitate Inactiva'}
                            id='cancel'
                            for='cancel'
                            checked={this.state.hasCancel}
                            onChange={this.onChangeSwitch}
                        />

                    </div>

                    <p
                        style={{
                            margin: 0,
                            textAlign: 'left',
                            fontSize: '14px',
                            fontWeight: '400',
                            color: '#000000'
                        }}
                    >

                        Termenii si Conditiile tale de desfasurare sau anulare a programarii.

                    </p>

                    <div 
                        className="form-control"
                        style={{
                            marginTop: 20
                        }}
                    >

                        <TextareaDescription 
                        
                            style={{
                                height: 290
                            }}
                            value={this.state.descriere}
                            onChange={this.onChangeDescriere}
                            textLenght={this.state.descLength}

                        />

                    </div>

                </BrowserView>

                <MobileView>

                    <MSwitch
                        title={+this.state.hasCancel ? 'Functionalitate Activa' : 'Functionalitate Inactiva'}
                        checked={this.state.hasCancel}
                        onChange={this.onChangeSwitch}
                    />

                    <div 
                        className="m-edit-s-card"
                        style={{
                            marginTop: 20
                        }}
                    >

                        <p 
                            style={{
                                margin: 0,
                                paddingBottom: '8px',
                                fontSize: '14px',
                                textAlign: 'left'
                            }}
                        >
                            Termenii si Conditiile tale de desfasurare sau anulare a programarii. 
                        </p>
                        
                        <div 
                            className="form-control"
                            style={{
                                marginBottom: 30
                            }}
                        >

                            <TextareaDescription 
                            
                                style={{
                                    height: 200
                                }}
                                value={this.state.descriere}
                                onChange={this.onChangeDescriere}
                                textLenght={this.state.descLength}

                            />

                        </div>

                    </div>

                </MobileView>
            
            </>
        )
    }
}