import LocalStorage from "../../providers/LocalStorage";
import Masks from "../../providers/Masks";
import Utils from "../../providers/Utils";
import Ws from "../../providers/Ws";
import InputValidation from '../logic/InputValidation';

export default class ProfileFuncs{

    WS = new Ws();
    UT = new Utils();
    M = new Masks();
    IV = new InputValidation();

    onUpdateAccountData = async (profileData, email) => {

        const TOKEN_CONT = new LocalStorage().GetAccountToken();

        let uq = this.SF.onSaveServiceData(profileData, false, TOKEN_CONT, 'accounts');
                     
        let _updateData = this.WS.sqlCommand(`update`, uq.data.substr(1, uq.data.length - 2));

        let uq2 = `update user_contacts set value = '${email}' where tip = 'email' and token_user = ( select token from users where account_id = '${TOKEN_CONT}' )`;
        let _updateEmail = this.WS.sqlCommand(`update`, uq2);


        let [updateData, updateEmail] = await Promise.all( [_updateData, _updateEmail] );

        if(updateData.success && updateEmail.success){

            return {
                data: 'Succes!',
                err: null
            }
        }
        else{

            return {err: 'Eroare actualizare date!', data: null}
            // alert("Fail update!")
        }
    }

    onCheckAndSave = (profile) => {

        let result = {

            adresa: this.IV.onCheckCityOrAddress(profile['adresa'], 'Adresa este prea scurta sau contine caractere speciale.'),
            cui: this.IV.onCheckCui(profile['cui']),
            oras: this.IV.onCheckCityOrAddress(profile['oras'], 'Nume oras prea scurt sau contine caractere speciale.'),
            telefon: this.IV.onCheckPhone(profile['telefon']),
            numeCompanie: this.IV.onCheckLegalName(profile['numeCompanie']),
            nrRegCom: this.IV.onCheckNrRegCom(profile['nrRegCom']),
            email: this.IV.onCheckEmail(profile['email']),
            nume: this.IV.onCheckName(profile['nume'])
        };

        return result;
    }

}