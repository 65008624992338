import React from 'react';

import { BrowserView, MobileView } from 'react-device-detect';

import { ImLocation2 } from 'react-icons/im';
import { IoVideocam } from 'react-icons/io5';
import { FaSkype } from 'react-icons/fa';
import { ReactComponent as ZoomIcon } from '../../assets/zoom_icon.svg';
import { ReactComponent as LocIcon } from '../../assets/pin_drop.svg';
import GoogleMeetIcon from '../../assets/Google_Meet-Logo.wine.svg';
import { ReactComponent  as GMeetIcon} from '../../assets/Google_Meet-Logo.wine.svg';
import { GoVerified } from 'react-icons/go';

import Declarations from '../../providers/Declarations';
import Masks from '../../providers/Masks';
import Utils from '../../providers/Utils';
import Ws from '../../providers/Ws';
import Location from '../Location';
import ServicesFuncs from '../../module/logic/ServicesFuncs';
import { MdPower } from 'react-icons/md';

export default class SvLocation extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            listaLocatii: [],
            locatieCurenta: this.props.locatieCurenta || 'zoom',
            locatieCurentaTmp: this.props.locatieCurenta || 'zoom',

            zoomInput: true,
            skypeInput: false,
            officeInput: false,
            gmeetInput: false,
            
        }
    }

    WS = new Ws();
    UT = new Utils();
    M = new Masks();

    componentDidMount(){

        this.setLocationData();
    }

    componentDidUpdate(prevPros, _){

        if(prevPros.triggerSave !== this.props.triggerSave){

            this.onGenerateQuery();
        }

        if(prevPros['locatieCurenta'] !== this.props['locatieCurenta']){

            this.setState({

                locatieCurentaTmp: this.props.locatieCurenta
            });
        }
    }   

    setLocationData = () => {

        this.setState({

            listaLocatii: this.setListaLocatii(this.state.locatieCurenta)
        },
            
            () => {

                this.setCheckLocatie(this.state.locatieCurenta);
            }
        );
    }

    setListaLocatii = () =>{

        let listaLocatii = new Declarations().listaLocatiiIntalnire;

        return listaLocatii;
    }

    onGenerateQuery = () => {

        const result = {

            data: [
                {
                    itemName: 'location',
                    itemData: `'` + this.state.locatieCurenta + `'`
                }
            ],
            err: null
        }

        // this.props.onCheckAndSave(result);
    }

    setCheckLocatie = (location) => {

        let inputNames = ['zoomInput', 'skypeInput', 'officeInput', 'gmeetInput'];

        const locatieCurenta = new Declarations().listaLocatiiIntalnire.filter(lEl => lEl.id === location);

        const nonSelectedLoc = inputNames.filter(iEl => iEl !== locatieCurenta[0].id+'Input');

        this.setState({

            [locatieCurenta[0].id+'Input']: true,
            [nonSelectedLoc[0]]: false,
            [nonSelectedLoc[1]]: false,
            [nonSelectedLoc[2]]: false
        });
    }

    setChangeLocatie = (locId, inputName) => {

        let inputNames = ['zoomInput', 'skypeInput', 'officeInput', 'gmeetInput'];

        const locatieCurenta = new Declarations().listaLocatiiIntalnire.filter(lEl => lEl.id === locId);

        const nonSelectedLoc = inputNames.filter(iEl => iEl !== inputName);

        let doSave = true;

        if(locatieCurenta[0].id === this.state.locatieCurentaTmp){

            doSave = false;
        }

        this.setState({
            locatieCurenta: locatieCurenta[0].id,
            [locatieCurenta[0].id+'Input']: true,
            [nonSelectedLoc[0]]: false,
            [nonSelectedLoc[1]]: false,
            [nonSelectedLoc[2]]: false
        },
            () => {

                // this.checkSaveButtonStatus()
                this.props.inputStatus(doSave, 'locatieStatus', 'locatie', locatieCurenta[0].id);
            }
        )

       
    }

    onChangeLocatie = (ev) =>{

        this.setChangeLocatie(ev.target.value, ev.target.name)
    }

    onChangeLocatieCard = (input, locId) => {

        this.setChangeLocatie(locId, input)
    }

    onLocationSelect = (location) =>{

        let doSave = true;

        if(this.state.locatieCurentaTmp === location.id){

            doSave = false;
        }

        this.setState({

            locatieCurenta: location.id,
            locationStatus: doSave
            // canSave: doSave
        },
        
            () => {

                this.props.inputStatus(doSave, 'locatieStatus', 'locatie', location.id);
            }
        )

    }

    render(){

        return(
            <>

                {/* <BrowserView> */}

                    {
                        !this.props.showAlt && (

                            <Location

                                currentLocation = {new Declarations().listaLocatiiIntalnire.filter(llEl => llEl.id === this.state.locatieCurenta)[0]}
                                id = {this.state.locatieCurenta}
                                listaLocatii={this.state.listaLocatii}
                                onLocationSelect={this.onLocationSelect}
                                integrations = {this.props.integrations}
                            />
                        )
                    }
                
                {/* </BrowserView> */}

                {/* <MobileView> */}

                {
                    this.props.showAlt && (

                        <div>

                            {this.state.listaLocatii.map((lEl, idx) => {

                                const isActive = new ServicesFuncs().onSelectIntegration(this.props.integrations, lEl['id']);

                                return(

                                    <div 
                                        key={'m-location-'+idx}
                                        className="form-control"
                                        style={{
                                            display: 'flex',
                                            marginTop: 10
                                        }}
                                    >   
                                        <div 
                                            className="m-location-left"
                                            style={{
                                                maxHeight: '100%',
                                                textAlign: 'left'
                                            }}
                                        >

                                            <input 
                                                className="input-radio" type="radio"
                                                style={{

                                                    position: 'relative',
                                                    top: '50%',
                                                    transform: 'translateY(-50%)',
                                                }}
                                                value={lEl.id}
                                                name={lEl.id+'Input'}
                                                onChange={ (ev) => {

                                                    if(isActive){

                                                        this.onChangeLocatie(ev);
                                                    }
                                                }}

                                                checked={
                                                    this.state[lEl.id+'Input']
                                                } 
                                            />

                                        </div>

                                        <div 
                                            className="m-location-right"
                                            style={{
                                                border: '1px solid #EBEBEB',
                                                borderRadius: '5px'
                                            }}
                                        >

                                            <div
                                                className={lEl.id === this.state.locatieCurenta ? 'location-card m-selected-loc' : 'location-card'}
                                                onClick={
                                                    () => {
                                                        
                                                        if(isActive){

                                                            this.onChangeLocatieCard(lEl.id+'Input', lEl.id)
                                                        }
                                                    }
                                                }
                                                style={{
                                                    padding: '0px 14px 0px 14px'
                                                }}
                                            >

                                                <div className="loc-content-wrapper">

                                                    <div className="loc-left-half">

                                                        <div className="loc-icon-wrapper">

                                                            {
                                                                lEl.id === 'zoom' && (

                                                                    <ZoomIcon
                                                                        className="zoom-icon-loc"
                                                                        style={{
                                                                            margin: 0,
                                                                        }}
                                                                    />
                                                                )
                                                                
                                                            }

                                                            {
                                                                lEl.id === 'skype' && (

                                                                    <FaSkype
                                                                        className="location-icon"
                                                                        style={{
                                                                            margin: 0,
                                                                        }}
                                                                    />
                                                                )
                                                                
                                                            }

                                                            {
                                                                lEl.id === 'office' && (

                                                                    <LocIcon
                                                                        className="office-icon-loc"
                                                                        style={{
                                                                            margin: 0,
                                                                        }}
                                                                    />
                                                                )
                                                                
                                                            }

                                                            {
                                                                lEl.id == 'gmeet' &&(

                                                                    <GMeetIcon 
                                                                        className="location-icon gmeet"
                                                                        style={{
                                                                            margin: 0,
                                                                            width: '24px',
                                                                            height: '22px'
                                                                        }}
                                                                    />
                                                                )
                                                            
                                                            }

                                                        </div>

                                                        <div className="loc-tx-wrapper">

                                                            <div className="location-info">

                                                                    <p>
                                                                        {lEl.tx}
                                                                        <br />
                                                                        <span>{lEl.shortTx}</span>
                                                                    </p>


                                                            </div>

                                                        </div>

                                                    </div>

                                                    <div className="loc-right-half">

                                                        <div className="loc-sync-wrapper">

                                                            <div className="sync-info" style={{display: 'inline-block'}}>

                                                                {
                                                                    isActive && (

                                                                        <p>
                                                                            <GoVerified 
                                                                                className="sync-icon"
                                                                                style={{
                                                                                    marginRight: '0'
                                                                                }}
                                                                            />
                                                                            <br />
                                                                            <span>Sync Ok</span>
                                                                        </p>
                                                                    )
                                                                }

                                                                {
                                                                    !isActive && (

                                                                        <p>
                                                                            <MdPower 
                                                                                className="sync-icon connect"
                                                                                style={{
                                                                                    marginRight: '0'
                                                                                }}
                                                                            />
                                                                            <br />
                                                                            <span>Conecteaza</span>
                                                                        </p>
                                                                    )
                                                                }


                                                            </div>

                                                        </div> 

                                                    </div>

                                                </div>

                                            </div>

                                        </div>

                                    </div>
                                )
                                })
                            }
                        </div>
                    )
                }

                {/* </MobileView> */}
            </>
        )
    }
}