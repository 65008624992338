import React from 'react';

import { FaCalendarCheck } from 'react-icons/fa';
import { GoCheck } from 'react-icons/go';


import { MobileView } from 'react-device-detect';

import Utils from '../../../providers/Utils';
import Ws from '../../../providers/Ws';
import LocalStorage from '../../../providers/LocalStorage';
import MServiceNav from '../../../commons/MServiceNav';
import SelectOthers from '../../../commons/SelectOthers';
import AuthInput from '../../../commons/AuthInput';
import Declarations from '../../../providers/Declarations';

import LaptopImage from '../../../assets/laptop_and_mug.png';
import Masks from '../../../providers/Masks';
import SuccessSaveToast from '../../../commons/SuccessSaveToast';
import ErrorToast from '../../../commons/ErrorToast';
import ServicesFuncs from '../../logic/ServicesFuncs';
import ErrorMessage from '../../../commons/ErrorMessage';
import SvDisponibility from '../../../commons/service/SvDisponibility';
import Loader from '../../../commons/Loader';

export default class MDisponibilitate extends React.Component{

    constructor(props){

        super(props)
        this.state = {

            detaliiServiciu: {},
            
            canSave: false,
           
            showToastPopup: false,
            showToastPopupError: false,
            actionTx: '',
            eroareTx: '',

            nrZileStatus: false,
            tipZileStatus: false,
            radioStatusDispo: false,
            dataStartStatus: false,
            dataEndStatus: false,

            triggerSave: false,
            isLoading: true
        }
    }

    WS = new Ws();
    UT = new Utils();
    M = new Masks();
    SF = new ServicesFuncs();

    componentData = new Declarations().serviceData['disponibility'];

    componentStatus = {

        isDispoOk: true
    }

    errorMessages = '';

    componentDidMount(){
        
        if(this.props.match.params.tk.length > 10){

            this.getData();
        }

        window.scrollTo(0,0);
    }

    getData = async () =>{

        const TOKEN_CONT = new LocalStorage().GetAccountToken();

        let q1 = `select * from services where account_tk = '${TOKEN_CONT}' and token = '${this.props.match.params.tk}' `;

        let _dateServiciu = this.WS.sqlCommand(`select`, q1);

        let [dateServiciu] = await Promise.all([_dateServiciu]);
        

        if(dateServiciu.success){

            if(dateServiciu.content.length === 0){

                this.setState({

                    eroareTx: 'Serviciu inexistent.',
                    isLoading: false
                });

                return;
            }

            // alert('Ayaya!')

            this.componentData = new Declarations().updateComponentsData(this.componentData, dateServiciu.content[0]);
            
            this.setState({

                detaliiServiciu: dateServiciu.content[0],
                isLoading: false

            },
                () => {

                }
            )

            
        }
        else{

            this.setState({

                showToastPopupError: true,
                actionTx: 'Eroare preluare date disponibilitate serviciu din server.'
            });
            throw new Error('eroare server response:  ' + dateServiciu.message)
        }
    }

    checkSaveButtonStatus = () => {

        let status = null;

        if(!this.state.nrZileStatus && !this.state.tipZileStatus && 
            !this.state.radioStatusDispo && !this.state.dataStartStatus &&
            !this.state.dataEndStatus
        ){

            status = false;
        }
        else{

            status = true;
        }

        this.setState({

            canSave: status
        });
    }

    inputStatus = (status, statusName, inputName, data, isVerify = false) => {

        if(isVerify){

            this.componentStatus[statusName] = data;
        }
        else{

            this.componentData[inputName] = data;

            
    
            this.setState({
                
                [statusName]: status
            },
                () => {
    
                    this.checkSaveButtonStatus();
                }
            );
        }
    }

    onAddErrorMessage = (errorTx) => {

        this.errorMessages = errorTx;
    }

    saveToDb = async (result) => {

        const token = this.UT.generateToken(); //used only when creating a service
        const TOKEN_CONT = new LocalStorage().GetAccountToken();
        // let userData = await this.WS.getUserData()

         if (this.props.match.params.tk.length >= 10){

            // alert("Modific!")
            let uq = this.SF.onSaveServiceData(result, false, this.props.match.params.tk);
             
            

            let updateServiciu = await this.WS.sqlCommand(`update`, uq.data.substr(1, uq.data.length - 2));

            if(updateServiciu.success){

                await this.getData();

                this.setState({

                    canSave: false,
                    showToastPopup: true,
                    actionTx: 'a fost actualizata cu succes!',

                    nrZileStatus: false,
                    tipZileStatus: false,
                    radioStatusDispo: false,
                    dataStartStatus: false,
                    dataEndStatus: false,
                    
                    triggerSave: false
                })
            }
            else{

                this.setState({

                    showToastPopupError: true,
                    actionTx: 'Eroare actualizare disponibilitate serviciu.'
                });

                throw new Error('Eroare Server Response:  ' + updateServiciu.message)
            }
        }
        
    }

    onClosePopup = (type) => {

        this.setState({

            [type]: false
        })
    }

    onAddErrorMessage = (errorTx) => {

        this.errorMessages = errorTx;
    }

    onCheckAndSave = async () => {

        if(this.componentStatus['isDispoOk']){

            const queryArr = new Declarations().generateQuerySet('service', this.componentData)['disponibility'];

            await this.saveToDb(queryArr);
        }
        else{

            this.setState({

                triggerSave: false,
                showToastPopupError: true,
                actionTx: this.errorMessages
            })
        }
    }

    onTriggerSave = () => {

        this.setState({

            triggerSave: true
        },
            () => {

                this.onCheckAndSave();
            }
        );
    }

    showToastError = (errorTx) => {

        this.setState({

            showToastPopupError: true,
            actionTx: errorTx,
        });
    }

    render(){

        return(
            <MobileView>

            {
                this.state.showToastPopupError && (

                    <ErrorToast 
                        action={this.state.actionTx}
                        onClosePopup = {this.onClosePopup}
                    />
                )
            }


            {
                this.state.showToastPopup && (

                    <SuccessSaveToast 
                        onClosePopup = {this.onClosePopup}
                        saveName = 'Perioada de disponibilitate'
                        action = {this.state.actionTx}
                    />
                )
            }

            <div 
                className="rootable-page"
                style={{
                    position: 'relative',
                    height: '100vh',
                    background: `url(${LaptopImage}) no-repeat`,
                    backgroundPosition: 'center bottom 20px',
                    backgroundSize: '50%',
                    paddingBottom: '20px'
                }}
            >

                <div
                    style={{
                        position: 'relative'
                    }} 
                >

                    <MServiceNav
                        icon={<FaCalendarCheck />}
                        title='Disponibilitate'
                        path={'/ca/dashboard/cab/msv/'+this.props.match.params.tk}
                    />

                    {
                        this.state.canSave &&
                        <GoCheck
                            className="m-add-service"
                            onClick={this.onTriggerSave}
                        />
                    }

                </div>

                {
                    this.state.isLoading && (

                        <Loader 
                            fullPage = {true}    
                        />
                    )
                }

               {
                   !this.state.isLoading && (

                    <div 
                        style={{
                            padding: 20
                        }}
                    >

                        <div className="m-edit-s-card">

                            {
                                this.state.eroareTx.length > 0 && (

                                    <ErrorMessage 
                                    
                                        eroareTx = {this.state.eroareTx}
                                    />
                                ) 
                            }

                            {
                                this.state.eroareTx.length === 0 && 
                                (

                                    <SvDisponibility 
                                        serviceDetails = {this.state.detaliiServiciu}
                                        triggerSave = {this.state.triggerSave}
                                        inputStatus = {this.inputStatus}
                                        showToastError = {this.showToastError}
                                        mobileSelector = {true}
                                        onAddErrorMessage = {this.onAddErrorMessage}
                                    />
                                )
                            }

                        </div>

                    </div>

                   )
               }

                {/* <div
                    style={{
                        width: '100%',
                        position: 'fixed',
                        left: 0,
                        bottom: 0
                    }}
                >

                    <img 
                        src={LaptopImage}
                        alt="Bird and plant..."
                        style={{
                            display: 'block',
                            margin: 'auto'
                        }}
                    />

                </div> */}

            </div>
            </MobileView>
        )
    }
}