import React from 'react';

import { MobileView } from 'react-device-detect';

import { GoCheck } from 'react-icons/go';
import { FaCalendarCheck } from 'react-icons/fa';

import Utils from '../../../providers/Utils';
import Ws from '../../../providers/Ws';
import LocalStorage from '../../../providers/LocalStorage';

import MServiceNav from '../../../commons/MServiceNav';
import Declarations from '../../../providers/Declarations';
import SelectProgram from '../../../commons/SelectProgram';
import ButtonForm from '../../../commons/ButtonForm';

import CalendarImage from '../../../assets/calendar_bg.png';
import SuccessSaveToast from '../../../commons/SuccessSaveToast';
import ErrorToast from '../../../commons/ErrorToast';
import DisplaySchedule from '../../../commons/DisplaySchedule';
import ErrorMessage from '../../../commons/ErrorMessage';
import ServicesFuncs from '../../logic/ServicesFuncs';
import SvSchedule from '../../../commons/service/SvSchedule';
import Loader from '../../../commons/Loader';

export default class MProgram extends React.Component{

    constructor(props){

        super(props)

        this.state={

            detaliiServiciu: {},
            programe: [],
            
            canSave: false,
            showToastPopup: false,
            showToastPopupError: false,
            actionTx: '',
            eroareTx: '',

            programStatus: false,
            triggerSave: false,
            
            isLoading: true
        }
    }

    WS = new Ws();
    UT = new Utils();
    SVF = new ServicesFuncs();

    componentData = new Declarations().serviceData['price'];
    
    componentStatus = {

        isPriceOk: true
    };

    componentDidMount(){

        if(this.props.match.params.tk.length > 10){

            // this.getDateServiciu()
            this.getData();
        }

        // if(this.props.match.params.tk.length < 10 || 
        //     this.props.match.params.tk === null
        //     ){

        //     this.getDateProgram()
        // }

        window.scrollTo(0,0);

    }

    getData = async () => {

        const TOKEN_CONT = new LocalStorage().GetAccountToken();

        let q1 = `select * from services where account_tk = '${TOKEN_CONT}' and token = '${this.props.match.params.tk}' `;
        let q2 = `select * from programs where account_tk = '${TOKEN_CONT}' and ifnull(sters,0)!=1`;

        let _dateServiciu = this.WS.sqlCommand(`select`, q1);
        let _programe = this.WS.sqlCommand(`select`, q2);

        let [dateServiciu, programe] = await Promise.all([_dateServiciu,  _programe])

        if(dateServiciu.success && programe.success){

            // alert('Ayaya!')

            if(dateServiciu.content.length === 0){

                this.setState({

                    eroareTx: 'Serviciu inexistent.',
                    isLoading: false
                });

                return;
            }
            
            this.componentData = new Declarations().updateComponentsData(this.componentData, dateServiciu.content[0]);

            this.setState({

                detaliiServiciu: dateServiciu.content[0],
                programe: programe.content,
                isLoading: false

            },
                () => {

                }
            )

            
        }
        else{

            this.setState({

                showToastPopupError: true,
                actionTx: 'Eroare preluare date program din server.'
            });
            throw new Error('eroare server response:  ' + dateServiciu.message + '\n' + programe.message)
        }
    }

    checkSaveButtonStatus = () => {

        let status = null;

        if(!this.state.programStatus){

            status = false;
        }
        else{

            status = true;
        }

        this.setState({

            canSave: status
        });
    }

    inputStatus = (status, statusName, inputName, data, isVerify = false) => {

        if(isVerify){

            this.componentStatus[statusName] = data;
        }
        else{

            this.componentData[inputName] = data;

            
    
            this.setState({
                
                [statusName]: status
            },
                () => {
    
                    this.checkSaveButtonStatus();
                }
            );
        }
    }

    saveToDb = async (result) => {

        const token = this.UT.generateToken(); //used only when creating a service
        const TOKEN_CONT = new LocalStorage().GetAccountToken();
        // let userData = await this.WS.getUserData()

         if (this.props.match.params.tk.length >= 10){

            // alert("Modific!")
            let uq = this.SVF.onSaveServiceData(result, false, this.props.match.params.tk);
             
            

            let updateServiciu = await this.WS.sqlCommand(`update`, uq.data.substr(1, uq.data.length - 2));

            if(updateServiciu.success){

                // alert("Serviciu Modificat cu Succes!");
                this.setState({

                    canSave: false,
                    showToastPopup: true,
                    actionTx: 'a fost actualizat cu succes!',

                    programStatus: false,
                    triggerSave: false
                },
                
                    async () => {

                        await this.getData();
                    }
                )
            }
            else{

                this.setState({

                    showToastPopupError: true,
                    actionTx: 'Eroare preluare date program din server.'
                });
                throw new Error('Eroare Server Response:  ' + updateServiciu.message)
            }
        }
        
    }

    getDateProgram = async () => {

        const TOKEN_CONT = new LocalStorage().GetAccountToken();

        let q1 = `select * from programs where account_tk = '${TOKEN_CONT}' and ifnull(sters,0)!=1`;

        let _programs = this.WS.sqlCommand(`select`, q1);
        
        let [programs] = await Promise.all([_programs]);

        if(programs.success){

            this.setState({

                programe: programs.content,
                programTk: programs.content[0].token,
            },

                () => {

                    
                }
            )

            
        }
        else{

            this.setState({

                showToastPopupError: true,
                actionTx: 'Eroare preluare date program din server.'
            });
            throw new Error('eroare server response:  ' + programs.message)
        }
    }



    createScheduleList = (schedule) => {

        let zile = [
            {
                zi_start: schedule.lu_start === null ? null : schedule.lu_start.substring(0,5),
                zi_end: schedule.lu_end === null ? null : schedule.lu_end.substring(0,5)
            },

            {
                zi_start: schedule.ma_start === null ? null : schedule.ma_start.substring(0,5),
                zi_end: schedule.ma_end === null ? null : schedule.ma_end.substring(0,5)
            },

            {
                zi_start: schedule.mi_start === null ? null : schedule.mi_start.substring(0,5),
                zi_end: schedule.mi_end === null ? null : schedule.mi_end.substring(0,5)
            },

            {
                zi_start: schedule.jo_start === null ? null : schedule.jo_start.substring(0,5),
                zi_end: schedule.jo_end === null ? null : schedule.jo_end.substring(0,5)
            },

            {
                zi_start: schedule.vi_start === null ? null : schedule.vi_start.substring(0,5),
                zi_end: schedule.vi_end === null ? null : schedule.vi_end.substring(0,5)
            },

            {
                zi_start: schedule.sa_start === null ? null : schedule.sa_start.substring(0,5),
                zi_end: schedule.sa_end === null ? null : schedule.sa_end.substring(0,5)
            },

            {
                zi_start: schedule.du_start === null ? null : schedule.du_start.substring(0,5),
                zi_end: schedule.du_end === null ? null : schedule.du_end.substring(0,5)
            }
        ]

        return zile;
    }

    onCheckAndSave = async () => {

        if(this.componentStatus['isScheduleOk']){

            const queryArr = new Declarations().generateQuerySet('service', this.componentData)['schedule'];

            await this.saveToDb(queryArr);
        }
        else{

            this.setState({

                triggerSave: false,
            });
        }
    }

    onClosePopup = (type) => {

        this.setState({

            [type]: false
        })
    }

    onTriggerSave = () => {

        this.setState({

            triggerSave: true
        },
        
            () => {

                this.onCheckAndSave();
            }
        );
    }


    render(){

        return(
            <MobileView>

                {
                    this.state.showToastPopupError && (

                        <ErrorToast 
                            action={this.state.actionTx}
                            onClosePopup = {this.onClosePopup}
                        />
                    )
                }


            {
                this.state.showToastPopup && (

                    <SuccessSaveToast 
                        onClosePopup = {this.onClosePopup}
                        saveName = 'Programul'
                        action = {this.state.actionTx}
                    />
                )
            }

            <div 
                className="rootable-component"
                style={{

                    position: 'relative',
                    height: '100%',
                    // background: `url(${CalendarImage}) bottom no-repeat`,
                    // backgroundSize: '300px 125px'
                }}
            >
                
                <div
                    style={{
                        position: 'relative'
                    }} 
                >

                    <MServiceNav 
                        icon={<FaCalendarCheck />}
                        title='Program'
                        path={'/ca/dashboard/cab/msv/'+this.props.match.params.tk}
                    />

                    {
                        this.state.canSave &&
                        <GoCheck
                            className="m-add-service"
                            onClick={this.onTriggerSave}
                        />
                    }

                </div>

                {
                    this.state.isLoading && (

                        <Loader 
                            fullPage = {true}
                        />
                    )
                }

                {
                    !this.state.isLoading && (

                        <>
                        
                            <div 
                                style={{
                                    padding: 20
                                }}
                            >
                                <div className="m-edit-s-card">

                                    {
                                        this.state.eroareTx.length > 0 && (

                                            <ErrorMessage 
                                            
                                                eroareTx = {this.state.eroareTx}
                                            />
                                        )
                                    }

                                    {
                                        this.state.eroareTx.length === 0 && 
                                        (

                                            <SvSchedule 
                                                triggerSave = {this.state.triggerSave}
                                                programe = {this.state.programe}
                                                programTk = {this.state.detaliiServiciu['program_tk']}
                                                inputStatus = {this.inputStatus}
                                            />
                                                
                                        )
                                    }

                                    
                                </div>  

                            </div>

                            <div
                                style={{
                                    width: '100%',
                                    position: 'absolute',
                                    left: 0,
                                    bottom: -120
                                }}
                            >

                                <img 
                                    src={CalendarImage}
                                    alt="Calendar..."
                                    style={{
                                        display: 'block',
                                        margin: 'auto',
                                        width: '300px',
                                        height: '125px'
                                    }}
                                />

                            </div>
                        
                        </>
                    ) 
                }

            </div>
            </MobileView>
        )
    }
}