import React from 'react';
import Masks from '../../providers/Masks';
import Utils from '../../providers/Utils';
import Ws from '../../providers/Ws';
import ServicesFuncs from '../logic/ServicesFuncs';

import Logo from '../../assets/planary-logo-badge 1.png';
import { IoNotifications } from 'react-icons/io5';
import { FaUser, FaUsers } from 'react-icons/fa';
import AvatarAccount from '../../commons/AvatarAccount';
import LocalStorage from '../../providers/LocalStorage';

export default class RegisterHeader extends React.Component{

    constructor(props){

        super(props);
        this.state = {

            displayName: 'Planary'
        };
    }

    WS = new Ws();
    UT = new Utils();
    M = new Masks();
    SF = new ServicesFuncs();

    componentDidMount(){

        if(this.props.isServiceStep){

            this.onGetDisplayName();
        }
    }

    onGetDisplayName = async () => {

        let accountData = await this.UT.getAccountAvAndName(false, new LocalStorage().GetAccountToken());

        this.setState({

            displayName: accountData['name']
        });
    }    

    render(){

        return(

            <div className='register-header'>

                <div className='avatar'>

                    {
                        this.props.isServiceStep ? (

                            <AvatarAccount 
                                isNotLogged = {false}
                                accToken = {new LocalStorage().GetAccountToken()}
                                margin = '0px'
                                width = '50px'
                                height = '50px'
                            />
                        ) : (

                            <img 
                                className='reg-avatar-image'
                                src={Logo}
                                alt="Planary"
                            />
                        )
                    }

                    <p className='register-tx reg-avatar-tx'>{this.state.displayName}</p>
                </div>

                <div className='icons'>

                    <IoNotifications className='reg-icon'/>
                    <FaUser className='reg-icon'/>

                </div>

            </div>
        );
    }
}