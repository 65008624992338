import React from 'react';
import { MdDone } from 'react-icons/md';

export default class ButtonSaveDashboard extends React.Component{

    constructor(props){

        super(props);
    }

    render(){

        return(

            <>
            
            {
                this.props.canSave && (

                    <a 
                        className="nav-item save"
                        style={{
                            color: '#31A81D'
                        }}
                        onClick={this.props.onClick}
                    >

                        <p 
                            style={
                                {
                                    display: 'inline-block', 
                                    position: 'relative', 
                                    top: 6, 
                                    right: 10,
                                    margin: 0,
                                    fontSize: '22px',
                            
                                }
                            }
                        >
                            <MdDone />
                        </p>

                        <p 
                            style={
                                {
                                    display: 'inline-block', 
                                    position: 'relative',
                                    top: 1, 
                                    left: 0,
                                    margin: 0,
                                    fontWeight: '500'
                                }
                            }
                        >
                            Salveaza
                        </p>
                        
                    </a>
                )
            }

            {
                !this.props.canSave && (

                    <a 
                        className="nav-item save"
                        style={{
                            color: 'rgb(116, 131, 139)',
                            cursor: 'default'
                        }}
                    >

                        <p 
                            style={
                                {
                                    display: 'inline-block', 
                                    position: 'relative', 
                                    top: 6, 
                                    right: 10,
                                    margin: 0,
                                    fontSize: '22px',
                            
                                }
                            }
                        >
                            <MdDone />
                        </p>

                        <p 
                            style={
                                {
                                    display: 'inline-block', 
                                    position: 'relative',
                                    top: 1, 
                                    left: 0,
                                    margin: 0,
                                    fontWeight: '500'
                                }
                            }
                        >
                            Salveaza
                        </p>
                        
                    </a>
                )
            }
            
            </>
        )
    }
}