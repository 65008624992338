import React from 'react';

import Ws from '../../providers/Ws';
import LocalStorage from '../../providers/LocalStorage';
import AuthInput from '../../commons/AuthInput';

import { FaFacebookF } from 'react-icons/fa';
import { GrGoogle } from 'react-icons/gr';

import CanaryImage  from '../../assets/canary_plant_bg.png';
import planaryLogo from '../../assets/planary-logo-badge.svg';
import GirlImage from '../../assets/girl_standing.png';
import CanaryPlantImage from '../../assets/canary_besides_plant.png';
import * as ReactGA from 'react-ga';


import {
    BrowserView,
    isMobile,
    MobileView,

} from "react-device-detect";


import './Login.css';
import ButtonForm from '../../commons/ButtonForm';
import MAuthInput from '../../commons/MAuthInput';

export default class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user: '',
            parola: '',
            showLoading: false, 
            userRez: '',
            userErr: false,
            passRez: '',
            passErr: false,
        }
    }

    localData = {

        linkCreareCont: ''
    }

    componentDidMount() {

        this.onSetLinkCreareCont();

        if (window.addEventListener) {
            window.addEventListener("message", this.onMessage, false);        
        } 

    }

    componentWillUnmount(){
        if (window.addEventListener) {
            window.removeEventListener("message", this.onMessage, false);        
        } 

    }

    onSetLinkCreareCont = () => {

        if(isMobile){

            this.localData.linkCreareCont = '/#/inregistrare/date/incepe'
        }
        else{

            this.localData.linkCreareCont = '/#/inregistrare/date/companie'
        }

        
    }

    onMessage = (event) => {
        // Check sender origin to be trusted
        if (event.origin !== "https://auth.planary.ro") return;
    
        var data = event.data;
    
        // 
        // if (typeof(window[data.func]) == "function") {
        //     window[data.func].call(null, data.message);
            
        // }

        // alert(`wellcome ${data.email} : ${data.name} : ${data.picture}`)
        
        if(data.email){

            if(data.email.includes("@")){

                this.socialLogin(data.email, data.name, data.picture)
            }
        }   

    }

    checkAndLoginDemo = () => {
        this.setState({ user: 'demo', parola: 'demo' }, () => { this.login() })
    }

    checkAndLogin = () => {

        this.setState({

            showLoading: true
        },   
            async () => {

                if(this.validareDateLogin()){

                    await this.login();
                }
                else{

                    

                    this.setState({

                        showLoading: false
                    })
                }
            }
        );
        
    }

    validareDateLogin = () =>{

        let passErr = false;
        let passRez = '';

        let isPassOk = true;
        let isOk = false;

        // if(this.state.user.length >= 4 && 
        //     this.state.user.includes('@') && 
        //     this.state.user.includes(".")){

        //         this.setState({
        //             userRez: '',
        //             userErr: false
        //         })

        // }
        // else{

        //     this.setState({
        //         userRez: 'Email invalid! Incearca din nou',
        //         userErr: true
        //     })
        //     isOk = false
        // }

        
        if(this.state.parola.length >= 4){

            isPassOk = true
        }

        else{

            passErr = true;
            passRez = 'Parola este prea scurta. Incearca din nou!'

            isPassOk = false
        }

        this.setState({
            passRez: passRez,
            passErr: passErr
        })

        

        if(isPassOk){

            
            isOk = true;
        }

        

        return isOk;
    }


    login = async () => {
        let WS = new Ws()
        let addr = WS.serverAddress + "user/login/?apiKey=" + WS.appKey + "&email=" + this.state.user + "&pwd=" + this.state.parola

        

        let lr = await fetch(addr)
        let loginResponse = await lr.json()

        
        if (loginResponse.success) {

            // alert("succes login")
            new LocalStorage().SetUserData(loginResponse.content)

            // alert("local user data setata cu succes")
            

            document.location.href = "/";
           
            // this.props.history.push('/ca');
        }
        else {

            this.setState({
                userRez: 'Adresa de email nu exista sau este gresita!',
                userErr: true,
                showLoading: false

                // passRez: 'Username sau parola gresita!',
                // passErr: true
            })
        }
    }

    onChange = field => ev => {
        this.setState({ [field]: ev.target.value })
    }

    onKeyDown = (e) => {
        if (e.key === 'Enter') {
            // 
            this.checkAndLogin()
        }
    }

    socialLogin = async (email, name, picture) => {

        let WS = new Ws();
        let addr = WS.serverAddress + "user/sociallogin/?apiKey=" + WS.appKey + "&email=" + email + "&nume=" + name + "&poza=" + picture;

        

        let lr = await fetch(addr)
        let loginResponse = await lr.json()

        
        if (loginResponse.success) {

            // alert("succes login")
            new LocalStorage().SetUserData(loginResponse.content)

            // alert("local user data setata cu succes")
            

            document.location.href = "/";
           
            // this.props.history.push('/ca');
        }
        else {
            // alert('eroare logare'+loginResponse.message)
        }

    }

    render() {
        return (
        <React.Fragment>
            <div className="login-container">

                <div style={{width: '470px'}}> 
                {/* className="col-l-3 col-m-4 col-s-11" */}
                    <BrowserView>
                    
                    <div className="form-control" style={{marginTop: '50px'}}>
                        <img 
                            src={planaryLogo} 
                            alt="Planary Logo"
                            style={{
                                width: '100px',
                                height: '100px'
                            }} 
                        />
                    </div>

                    <div 
                        className="form-control"
                        style={{

                            marginTop: -20
                        }}
                    >
                        <h1
                            style={{
                                fontSize: '36px',
                                color: '#535353'
                            }}
                        >
                            Planary
                        </h1>

                    </div>
                    
                    </BrowserView>
            
                    <div className="login-card">

                        <MobileView>

                        <div className="form-control">
                            <img 
                                src={planaryLogo} 
                                alt="Planary Logo" 
                                style={{
                                    width: '95px',
                                    height: '95px'
                                }}
                            />
                        </div>

                        <div 
                            className="form-control"
                            style={{

                                marginTop: -20
                            }}
                        >
                            <h1
                                style={{
                                    fontSize: '24px',
                                    color: '#535353'
                                }}
                            >
                                Planary
                            </h1>

                        </div>

                        </MobileView>
                        
                        <BrowserView>
                        
                        <div className="form-control">

                            <AuthInput 
                                type="text"
                                mPlaceholder="Username sau eMail" 
                                label="Username sau Email"
                                value={this.state.user}
                                onChange={this.onChange('user')}
                                onKeyDown={this.onKeyDown}
                                iserror = {this.state.userErr}
                                errormsg= {this.state.userRez}
                                placeholder = 'Adresa ta email'
                                onFocus = {
                                    () => {
        
                                        this.setState({
                                            userRez: null
                                        })
                                    }
                                }
                            />

                        </div>

                        <div className="form-control" style={{marginTop: 20}}>

                            <AuthInput 
                                type="password" 
                                label="Parola"
                                mPlaceholder="Parola"
                                value={this.state.parola}
                                onChange={this.onChange('parola')}
                                onKeyDown={this.onKeyDown}
                                iserror={this.state.passErr}
                                errormsg={this.state.passRez}
                                placeholder = 'Parola ta'
                                onFocus = {
                                    () => {
        
                                        this.setState({
                                            passRez: null
                                        })
                                    }
                                }
                            />

                        </div>
                        </BrowserView>

                        <MobileView>

                        <div className="form-control">

                            <AuthInput 
                                type="text"
                                label="Username sau Email"
                                value={this.state.user}
                                onChange={this.onChange('user')}
                                onKeyDown={this.onKeyDown}
                                iserror = {this.state.userErr}
                                errormsg= {this.state.userRez}
                                placeholder = 'Adresa ta email'
                                onFocus = {
                                    () => {
        
                                        this.setState({
                                            userRez: null
                                        })
                                    }
                                }
                            />

                        </div>

                        <div className="form-control" style={{marginTop: 20}}>

                            <AuthInput
                                type="password" 
                                label="Parola"
                                value={this.state.parola}
                                onChange={this.onChange('parola')}
                                onKeyDown={this.onKeyDown}
                                iserror={this.state.passErr}
                                errormsg={this.state.passRez}
                                placeholder = 'Parola ta'
                                onFocus = {
                                    () => {
        
                                        this.setState({
                                            passRez: null
                                        })
                                    }
                                }
                            />

                        </div>

                        </MobileView>

                        <div className="form-control" style={{marginTop: 40}}>

                            
                            <ButtonForm
                                className="login-btn" 
                                type="button" 
                                tx={
                                    this.state.showLoading ? 'Autentificare in curs...' : "Autentifica-te"
                                }
                                style={{
                                    height: '40px'
                                }}
                                onClick={this.checkAndLogin}
                            />

                        </div>

                        <div className="form-control" style={{marginTop: 10}}>

                            <a href="#" className="signin-link">Ai uitat parola?</a>

                        </div>

                        
                        <div className="form-control" style={{marginTop: 60}}>

                            <iframe
                                style={{
                                    position: 'absolute',
                                    top: -7,
                                    border: 'none',
                                    width: '100%',
                                    height: '64px',
                                    opacity: 0
                                }}
                                src='https://auth.planary.ro/fb.html'
                            >

                            </iframe>

                            <ButtonForm 
                                className="facebook-btn" 
                                type="button" 
                                icon={<p style={{display: 'inline-block', position: 'relative', top: 2, margin: 0}}><FaFacebookF /></p> }
                                tx="Login cu Facebook"
                                style={{
                                    height: '40px'
                                }}
                            />

                        </div>   

                        <div className="form-control" style={{marginTop: 20}}>

                            <iframe
                                style={{
                                    position: 'absolute',
                                    top: -7,
                                    border: 'none',
                                    // margin: '0 auto',
                                    // width: '100%',
                                    width: '100%',
                                    height: '64px',
                                    opacity: 0
                                }}
                                src='https://auth.planary.ro/google_own.html'
                            >

                            </iframe>

                            <ButtonForm 
                                className="google-btn" 
                                type="button" 
                                icon={<p style={{display: 'inline-block', position: 'relative', top: 2, margin: 0}}><GrGoogle /></p> }
                                tx="Login cu Google"
                                style={{
                                    height: '40px'
                                }}
                            />

                        </div>
                    
                        <div className="form-control" style={{marginTop: 60}}>

                            <p className="no-account">Nu ai cont?</p>
                            <a href='/#/inregistrare/date/cont' className="signin-link">Creeaza unul nou</a>

                        </div>


                    </div>

                    
                    <BrowserView>
                        
                        <div
                            style={{
                                position: 'relative',
                                top: 20,

                                width: '100%',
                            }}
                        >

                            <img
                                src={CanaryImage}
                                alt="Canary Plant..."
                                style={{
                                    display: 'block',
                                    margin: '0 auto',
                                    width: '400px'
                                }}
                            />

                        </div>
                        
                    </BrowserView>
            
                </div>

            </div>

            <MobileView>

                    <div
                        style={{
                            margin: 0,
                            padding: 0,
                            position: 'absolute',
                            width: '100%',
                            height: '820px',
                            top: 0,
                            left: 0,
                            opacity: '0.7',
                            zIndex: -1,
                            backgroundImage: `url(${CanaryPlantImage}), url(${GirlImage})`,
                            backgroundPosition: 'left bottom, right bottom',
                            backgroundRepeat: 'no-repeat, no-repeat'
                        }}
                    >

                       

                    </div>

                </MobileView>

           </React.Fragment>
        );
    }


}  // end class