import logo from './logo.svg';
import './App.css';
import './module/dashboard/Dashboard.css';
import Login from './module/login/Login';
import Routes from './skeleton/Routes';

import { useState, useEffect } from 'react';

function App() {
  
  return (
    <div className="App">
 
        <Routes />

    </div>
  );
}

export default App;
