import React from 'react';

export default class SwitchOption extends React.Component{

    constructor(props){

        super(props);
    }

    componentDidMount(){


    }

    render(){

        return(

            <div className='security-option-row'>

                <p 
                    className='security-tx px14'
                    style={{
                        color: this.props.txCol || '#455A64'
                    }}
                >

                    {this.props.tx}

                </p>
                
                <div className="switch">
                    <input 
                        type="checkbox" 
                        id={this.props.id}
                        checked={this.props.checked}
                        onChange={this.props.onChange}
                    />
                    <label for={this.props.for}></label>
                </div> 

            </div>
        )
    }
}