import React from 'react';

export default class RedirectToSuccesAppoint extends React.Component{

    constructor(props){

        super(props)
    }

    componentDidMount(){

        this.onConfirmAppointment();
    }

    onConfirmAppointment = async () => {

        const updateTrcQuery = `update trc set 
                                            draft = 0
                                            
                                where token = '${this.props.match.params.tranToken}'
        
        `

        const updateTransaction = await this.WS.sqlCommand(`update`, updateTrcQuery);
        
        if(updateTransaction.success){

            // alert("Tranzactie finalizata cu succes!")
            this.props.history.push("/trc/" + this.props.match.params.tranToken+
                                "/finalizare-succes"
                                )

        }
        else{

            throw new Error("Server respnse error: " + updateTransaction.message)
        }
        
    }


    render(){

        return(
            <div></div>
        )
    }
}