import React from 'react';

import { MdPhotoCamera } from 'react-icons/md';
import FoxLogo from '../assets/foxStat.jpeg';
import DefaultAvatar from '../assets/canary-default-avatar.svg';

import LocalStorage from '../providers/LocalStorage';
import Ws from '../providers/Ws';

export default class Avatar extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            avatar: ''
        }
    }

    WS = new Ws();

    componentDidMount(){

        // 
    }

    

    uploadFile = async (evt) => {

        const ACCTOKEN = this.props.accToken;

        const files = evt.target.files;
        // const fileExtension = files.toString().substr(files.toString().length - 3);
        const fileExtension = files[0]['type'];
        const fileName = files[0]['name'];
        // 

        if(fileExtension.includes('image/png') || fileExtension.includes('image/jpeg') || 
            fileExtension.includes('image/jpg')
        ){

            let objToSend;
            
            if(this.props.wrkToken){

                objToSend = {tokenCont: ACCTOKEN};
            }
            else{

                objToSend = {tokenCont: ACCTOKEN};
            }
            let u = await this.WS.uploadFile('uploader/avatarcomerciant', files, objToSend);

            // 

            if(u.success){

                if(this.props.showSuccesUpload){

                    this.props.showSuccesUpload()
                }

                if(this.props.saveAvatar){

                    await this.props.saveAvatar(fileName);
                }
                
            }
            else{

                if(this.props.showErrorUpload){

                    this.props.showErrorUpload('A aparut o eroare la upload.')
                }
            }
        }
        else{

            // 

            if(this.props.showErrorUpload){

                this.props.showErrorUpload('Fisierul trebuie sa fie de tip .jpg sau .png!');
            }
            return;
        }

        
    }

    render(){

        return(

            <>
            
            <div 
                style={{
                    position: 'relative',
                    margin: this.props.margin || 'auto',
                    width: this.props.containerW || '200px',
                    height: this.props.containerH || '200px',
                }}
            >

                <div 
                    className="onboarding-avatar"
                    style={this.props.avatarOuterStyle}
                >

                    <img 
                        src={(this.props.avatarSrc || '').length > 0 ? this.props.avatarSrc : DefaultAvatar} 
                        className="onboard-avatar-img" 
                        alt="Foxie Fox!"
                    />

                </div>

                <div 
                    className="avatar-uploader"
                    style={this.props.uploaderStyle}
                >

                    <input 
                        type="file" 
                        onChange = {this.uploadFile}
                    />
                    <MdPhotoCamera 
                        className="avatar-uploader-icon"
                        style={this.props.cameraStyle}
                    />
                
                </div>

            </div>

            {
                !this.props.hideSize && (

                    <p 
                        className="avatar-dimensions" 
                        style={{
                            margin: this.props.margin || '20px auto'
                        }}
                    >
                        Dimensiuni maxime ale imaginii:
                        1000x1000px si 1Mb.
                    </p>
                )
            }
            
            </>
        )
    }
}