import Ws from "./Ws";
import Utils from "./Utils";
import { get } from "http";

export default class LocalStorage {

    localUserKey = "localUserKey"

    userRightsKey = 'userRightsKey'
    localUserPreferencesKey = "localUserPreferences"


    pageScrollsPositionsKey = "pageScrollsPositions"

    // USER DATA REGION
    GetUserData = () => {

        // 

        if (localStorage.getItem(this.localUserKey)) {

            // 

            let ud = localStorage.getItem(this.localUserKey)

            let U = new Utils()

            // 
            return JSON.parse(U.decryptText(ud));

        }
        else {
            
            return null;
        }

    }

    SetUserData = (us) => {
        if (us) {
            let encUs = new Utils().encryptText(JSON.stringify(us))
            localStorage.setItem(this.localUserKey, encUs)
            return true
        }
        else {
            localStorage.setItem(this.localUserKey, null)
            return false
        }
    }




    // USER RIGHTS REGION
    GetUserRights = () => {
        if (localStorage.getItem(this.userRightsKey)) {
            let ud = localStorage.getItem(this.userRightsKey)
            let U = new Utils()
            return JSON.parse(U.decryptText(ud));
        }
        else
            return null;
    }

    SetUserRights = (encryptedValue) => {
        if (encryptedValue) {
            localStorage.setItem(this.userRightsKey, encryptedValue)
            return true
        }
        else {
            localStorage.setItem(this.userRightsKey, null)
            return false
        }
    }

    FetchAndSaveUserRights = async () => {

        let WS = new Ws()
        let cs = await WS.getData('User/GetOwnRights')
        if (cs.Succes) {

            let serverContentAsString = JSON.stringify(cs.Content)
            let U = new Utils()
            let save = this.SetUserRights(U.encryptText(serverContentAsString, true))

            if (save) {
                // 
            }
            else {
                console.error("eroare salvare user rightsi")
            }
        }
        else {
            console.error("Eroare salvare user rights: ", cs.Message)
        }
    }



    // USER PREFERENCES REGION
    userPreferencesObjectModel = {
        language: 'ro',
        projectView: 'list', //
        licitatiiView: 'cards',
        theme: 'set',
    }

    GetUserPreferences = () => {

        this.CheckModelVsLocalStorage()

        if (localStorage.getItem(this.localUserPreferencesKey)) {
            let ud = localStorage.getItem(this.localUserPreferencesKey)

            //

            return JSON.parse(ud);
        }
        else {
            localStorage.setItem(this.localUserPreferencesKey, JSON.stringify(this.userPreferencesObjectModel))
            console.warn("No local preferences were found. Default values applyed")
            return this.userPreferencesObjectModel
        }
    }

    SetUserPreferences = (key, value) => {
        // daca cheia exista in model object
        this.CheckModelVsLocalStorage()

        if (this.userPreferencesObjectModel[key]) {

            if (localStorage.getItem(this.localUserPreferencesKey)) {

                let lPref = localStorage.getItem(this.localUserPreferencesKey);

                lPref = JSON.parse(lPref);
                lPref[key] = value;

                localStorage.setItem(this.localUserPreferencesKey, JSON.stringify(lPref))
                return true;
            }
            else {
                console.warn("User preferences not found. Object will be created.")

                let lPref = this.userPreferencesObjectModel;
                lPref[key] = value;

                localStorage.setItem(this.localUserPreferencesKey, JSON.stringify(lPref))
                return true;
            }

        }
        else {
            // daca cheia nu exista in object model, nu salvam: se pierde controlul in structura obiectului
            console.error("ERR: key not in preferences object model. Operation is not permited.")
            return false;
        }
    }

    CheckModelVsLocalStorage = () => {
        if (localStorage.getItem(this.localUserPreferencesKey)) {

            let ud = localStorage.getItem(this.localUserPreferencesKey)
            ud = JSON.parse(ud);

            let shouldUpdate = false
            for (let k in this.userPreferencesObjectModel) {
                //
                if (!ud.hasOwnProperty(k)) {
                    shouldUpdate = true;
                    ud[k] = this.userPreferencesObjectModel[k]
                    console.warn("User preferences object model key " + k + " not found and prepare to add")
                }

                if (shouldUpdate) {
                    localStorage.setItem(this.localUserPreferencesKey, JSON.stringify(ud))
                    console.warn("New keys successfully added in local user preferences")
                }
            }
        }
        else {
            localStorage.setItem(this.localUserPreferencesKey, JSON.stringify(this.userPreferencesObjectModel))
            console.warn("Cheking local preferences: No local preferences were found. Default values applyed")
        }
    }

    SetDefaultPreferences = () => {
        //
        //

        localStorage.setItem(this.localUserPreferencesKey, JSON.stringify(this.userPreferencesObjectModel))
        // 
        return true;
    }


    // PAGE SCROLL POSITIONS FEATURES

    SetScrollPosition = (page, offset, idHighlightElement = null) => {

        let toSave = {
            page: page,
            offset: offset,
            idHighlightElement: idHighlightElement
        }

        let s = localStorage.getItem(this.pageScrollsPositionsKey)

        if (String(s || "").length > 6) {

            let getScrollPositions = JSON.parse(s)
            let pageExists = getScrollPositions.filter(sc => sc["page"] === page)
            if (pageExists.length > 0) {

                for (let i = 0, n = getScrollPositions.length; i < n; i++) {
                    if (getScrollPositions[i]["page"] === page) {
                        getScrollPositions[i]["offset"] = offset
                        getScrollPositions[i]["idHighlightElement"] = idHighlightElement

                        break;
                    }
                }
            }
            else {
                getScrollPositions.push(toSave)
            }

            localStorage.setItem(this.pageScrollsPositionsKey, JSON.stringify(getScrollPositions))
        }
        else {

            let toSaveAsArray = [toSave]
            localStorage.setItem(this.pageScrollsPositionsKey, JSON.stringify(toSaveAsArray))
        }
    }

    GetScrollPositions = page => {
        let s = localStorage.getItem(this.pageScrollsPositionsKey)

        if (s) {
            let getScrollPositions = JSON.parse(s)
            for (let i = 0, n = getScrollPositions.length; i < n; i++) {

                if (getScrollPositions[i]["page"] === page) {
                    return {
                        offset: getScrollPositions[i]["offset"],
                        idHighlightElement: getScrollPositions[i]["idHighlightElement"]
                    }
                }
            }
            return {
                offset: 0,
                idHighlightElement: null
            }
        }

        else {
            return {
                offset: 0,
                idHighlightElement: null
            }
        }

    }

    GetAccountToken = () => {

        let userData = this.GetUserData();
        if(userData){

            const TOKEN_CONT = userData['accountData'][0].token;

            

            return TOKEN_CONT;
        }
        else{

            return null;
        }
    }
}