import React from 'react';

import Ws from '../../providers/Ws';
import Utils from '../../providers/Utils';
import Declarations from '../../providers/Declarations';

import ClientServiceInfo from './ClientServiceInfo';

import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';
import {IoIosArrowForward, IoIosArrowBack} from 'react-icons/io';

import { Link } from 'react-router-dom';
import { BrowserView, MobileView } from 'react-device-detect';
import MClientServiceInfo from './MClientServiceInfo';
import CompanyAvatar from '../../assets/foxStat.jpeg';
import Masks from '../../providers/Masks';
import LocalStorage from '../../providers/LocalStorage';

import CanaryPlantImage from '../../assets/canary_plant_bg.png';
import IntervalViewClient from './IntervalViewClient';
import IntervalView from '../dashboard/IntervalView';
import ErrorMessage from '../../commons/ErrorMessage';
import CalendarClientFuncs from '../logic/CalendarClientFuncs';
import ClientValidationFuncs from '../logic/ClientValidationFuncs';
import AvatarAccount from '../../commons/AvatarAccount';
import AppointmentHourSelect from '../../commons/AppointmentHourSelect';
import DateChanger from '../../commons/DateChanger';
import Loader from '../../commons/Loader';
import CalendarAndHour from '../../commons/client_appointments/CalendarAndHour';
import TeamMemberCard from '../../commons/TeamMemberCard';

export default class CalendarClientEdit extends React.Component{

    constructor(props){

        super(props)

        this.state = {

            serviceDetails: {},
            programe: [],
            locatieCurenta: {},
            showSchedule: false,
            currentSchedule: [],
            confirmHour: '',
            dateSelected: undefined,
            daySelectedSchedule: [],
            dataStart: new Date(2021, 6 , 1),
            dataEnd: new Date(2021, 6 , 30),
            monthAppointments: [],
            isInvite: false,
            accountName: '',
            accountImgSrc: '',
            eroareTx: '',
            tipAbonament: null,
            memmbersList: [],
            member: null,
            isLoading: true
        }
    }

    WS = new Ws();
    UT = new Utils();
    M = new Masks();
    CLF = new CalendarClientFuncs();
    CVF = new ClientValidationFuncs();

    fetchParamteres = {

        isToken: true,
        token: this.props.match.params.tranToken,
        isPublic: true,
        appointScreen: 'calendar',
        appointSrc: 'pacient',
        appointType: 'programare',
        // errorFunc: this.props.onRemoveHeaderTitle
    }

    componentDidMount(){

         this.getServiceAndInvitationData();
        
        // this.initDate();
        
        window.scrollTo(0,0);
    }

    getServiceAndInvitationData = async () => {

        
        let selectInvite = `select * from trc where token = '${this.props.match.params.tranToken}'   `;

        let selectService = `select * from services where account_tk = ( select account_tk from trc where token = '${this.props.match.params.tranToken}' )  
                                                    and token = ( select service_tk from trc where token = '${this.props.match.params.tranToken}' )    `;



        let selectProgram = `select * from programs where account_tk = ( select account_tk from trc where token = '${this.props.match.params.tranToken}' )  and ifnull(sters,0)!=1`;
        let selectAccount = `select * from accounts where token = ( select account_tk from trc where token = '${this.props.match.params.tranToken}' )`;
        let selectUsers = `select * from users where account_id = ( select account_tk from trc where token = '${this.props.match.params.tranToken}' )`;

        let _service = this.WS.sqlCommand(`select`, selectService);
        let _schedule = this.WS.sqlCommand(`select`, selectProgram);
        let _invite = this.WS.sqlCommand(`select`, selectInvite);
        let _account = this.WS.sqlCommand(`select`, selectAccount);
        let _users = this.WS.sqlCommand(`select`, selectUsers);

        let [service, schedule, invite, account, users] = await Promise.all([_service, _schedule, _invite, _account, _users])

        const currentSchedule = schedule.content.filter(scEl => scEl.token === service.content[0]['program_tk'])

        if(service.success && schedule.success && invite.success){

            if( ( +invite.content[0]['anulat'] || 0) === 1 ){

                this.setState({
                    eroareTx: 'Programarea a fost anulata!',
                    isLoading: false
                });

                return;
            }

            const member = users.content.length > 0 ? users.content.filter( member => member.token === invite.content[0].user_asignat_token) : null;

            this.setState({

                inviteDay: invite.content[0]['data_cal'],
                inivteData: invite.content[0],
                serviceDetails: service.content[0],
                programe: schedule.content,
                currentSchedule: currentSchedule,
                tipAbonament: account.content[0]['abonament'],
                memmbersList: users.content,
                member: member[0]
            },
                () => {

                    this.setLocatieCurenta(service.content[0].location)
                }
            )

        }
        else{

            throw new Error("Server response error: " + service.message + '\n' + schedule.message)
        }
    }

    setLocatieCurenta = (location) => {

        let locatieCurenta = new Declarations().listaLocatiiIntalnire.filter(lEl => lEl.id === location)

        this.setState({

            locatieCurenta: locatieCurenta[0],
            isLoading: false
        })
    }

    initDate = () => {

        let dataProgramarii = this.state.inivteData['data_cal'];
        let dataProgramariiAsJs = this.M.sqlDateToJSDate(dataProgramarii);

        let nextMonthStart = new Date(dataProgramariiAsJs.getFullYear(), dataProgramariiAsJs.getMonth(), 1);
        
        let backTrick = this.M.addMonths(new Date(nextMonthStart), 1);
        backTrick = this.M.addDaysToDate(backTrick, -1);

        let nextMonthEnd = backTrick;

        return{

            err: null,
            data: {

                dataStart: nextMonthStart,
                dataEnd: nextMonthEnd,
                accountTk: `account_tk = ( select account_tk from trc where token = '${this.props.match.params.tranToken}' )`
            }
        }
  
    }

    onShowClientForm = (tranToken) => {

        if(this.props.match.params.memberLink){

            this.props.history.push(`/trc/${this.props.match.params.memberLink}/${tranToken}/datele-mele`);
        }
        else{

            this.props.history.push("/trc/" + tranToken + "/datele-mele");
        }
    }

    onSetContainerExpansion = () => {

        this.setState({

            showSchedule: true
        });
    }

    onSelectUser = (user) => {

        this.setState({

            member: user
        });
    }
   

    render(){

        const columnLeft = this.state.showSchedule ? 'col-l-4 col-m-4 col-s-12' : 'col-l-6 col-m-6 col-s-12';
        const columnRight = this.state.showSchedule ? 'col-l-5 col-m-5 col-s-12' : 'col-l-6 col-m-6 col-s-12';
        const wrapper = this.state.showSchedule ? 'client-appoint-wrapper expanded' : 'client-appoint-wrapper';

        return(
            <React.Fragment>
            
            {
                this.state.isLoading && (

                    <Loader 
                        fullPage = {true}
                    />
                )
            }

            <BrowserView>

            {
                !this.state.isLoading && (

                    <>
                         <div className="client-appoint-container">

                            <div className={wrapper}>

                                <div 
                                    className='client-schedule-card'
                                    style={{
                                        padding: '40px 40px 40px 40px'
                                    }}
                                >
                                    <Link to={'/invite/acc/'+this.props.match.params.tranToken}>
                                    <h3  
                                        className="appoint-back"            
                                        style={{

                                            textAlign: 'left',
                                            margin: 0,
                                        }}
                                    >
                                        
                                        <p 
                                            style={{
                                                    display: 'inline-block', 
                                                    position: 'relative', 
                                                    top: 4, 
                                                    left: 0, 
                                                    margin: 0,
                                                    fontSize: '40px'
                                                }}
                                        >
                                            <RiArrowLeftSLine /> 

                                        </p>

                                        <p 
                                            style={{
                                                display: 'inline-block', 
                                                position: 'relative',
                                                top: -10, 
                                                left: 0,
                                                margin: 0,
                                                fontWeight: '500',
                                                fontSize: '16px'
                                            }}
                                        >
                                        Inapoi
                                        </p>
                                        
                                    </h3>
                                    </Link>

                                    {
                                        this.state.eroareTx.length > 0 && (

                                            <ErrorMessage 
                                                eroareTx = {this.state.eroareTx}
                                            />
                                        )
                                    }

                                    {
                                        this.state.eroareTx.length === 0 && 
                                        this.state.serviceDetails && 
                                        (

                                            <div className="row client-cal-row">

                                                <div 
                                                    className={columnLeft}
                                                    style={{
                                                        paddingRight: 10,
                                                        borderRight: '1px solid #EBEBEB',
                                                        maxHeight: '500px',
                                                        overflowY: 'auto'
                                                    }}
                                                >

                                                    <ClientServiceInfo 
                                                        marginTopAvatar = '35px'
                                                        name={this.state.serviceDetails.name}
                                                        duration={this.state.serviceDetails.duration}
                                                        locatieCurenta={this.state.locatieCurenta}
                                                        price={this.state.serviceDetails.price}
                                                        description={this.state.serviceDetails.description}
                                                        accountName = {this.state.accountName}
                                                        isNotLogged = {false}
                                                        accToken = {this.state.serviceDetails['account_tk']}
                                                        member = {this.state.member}
                                                        // accountImg = {this.state.accountImgSrc}
                                                    />

{
                                                        this.state.tipAbonament === 'anteprenor' ? (

                                                            <div
                                                                style={{
                                                                    width: '100%',
                                                                    // height: '300px',
                                                                    // overflowY: 'auto'
                                                                }}
                                                            >
                                                                <p
                                                                    style={{
                                                                        fontSize: 14,
                                                                        color: '#455A64',
                                                                        fontWeight: '500'
                                                                    }}
                                                                >
                                                                    Selectati un membru
                                                                </p>

                                                                <TeamMemberCard 
                                                                    name = {this.state.accountName}
                                                                    desc = {`Programare direct pe serviciul ${this.state.serviceDetails.name}`}
                                                                    selected = {this.state.member === null}
                                                                    avatarSrc = {this.state.accountImgSrc}
                                                                    forSelect = {true}
                                                                    onClick = {() => this.onSelectUser(null)}
                                                                    style={{
                                                                        marginTop: '10px'
                                                                    }}
                                                                />

                                                                {
                                                                    this.state.memmbersList.map( (member, index) => {

                                                                        return(

                                                                            <TeamMemberCard 
                                                                                key={`membru-${index}`}
                                                                                name = {member.nume}
                                                                                desc = {member.functie}
                                                                                selected = {this.state.member?.wrk_token === member.wrk_token}
                                                                                forSelect = {true}
                                                                                onClick = {() => this.onSelectUser(member)}
                                                                                style={{
                                                                                    marginTop: '10px'
                                                                                }}
                                                                            />
                                                                        );
                                                                    })
                                                                }

                                                            </div>

                                                        ) : null
                                                    }

                                                </div>

                                                <CalendarAndHour 
                                                    initDate = {this.initDate}
                                                    fetchParamteres = {this.fetchParamteres}
                                                    dayCal = {this.state.inviteDay}
                                                    serviceDetails = {this.state.serviceDetails}
                                                    renderPublicCalendar = {true}
                                                    // onShowBreakAddSuccess = {this.onShowBreakAddSuccess}
                                                    onShowClientForm = {this.onShowClientForm}
                                                    onSetContainerExpansion = {this.onSetContainerExpansion}
                                                    member = {this.state.member}
                                                />
                                            </div>
                                        )
                                    }

                                </div>

                            </div>

                        </div>

                        <div
                            style={{
                                width: '100%',
                                marginTop: 40
                            }}
                        >

                            <img 
                                src={CanaryPlantImage}
                                alt="Canary and plant..."
                                style={{
                                    display: 'block',
                                    margin: 'auto',
                                    width: '400px'
                                }}
                            />

                        </div>

                        <p
                            style={{
                                
                                paddingTop: '20px',
                                paddingBottom: '20px',
                                fontSize: 14,
                                color: '#455A64'
                            }}
                        >
                            Programari prin  <a 
                                                href="https://planary.ro"
                                                style={{
                                                    color: '#455A64'
                                                }}
                                            >
                                                Planary.ro
                                            </a>
                                
                        </p>
                    </>
                )
            }

            </BrowserView>

            <MobileView>
            
               {
                   !this.state.isLoading && (

                        <>
                        
                             <Link to={'/'+this.props.match.params.company_web_link}>
                                 
                                <h3  
                                    className="m-appoint-back"            
                                    style={{

                                        textAlign: 'left',
                                        margin: 0,
                                    }}
                                > 
                                    <p 
                                        style={{
                                                display: 'inline-block', 
                                                position: 'relative', 
                                                top: 4, 
                                                left: 0, 
                                                margin: 0,
                                                fontSize: '40px'
                                            }}
                                    >
                                        <RiArrowLeftSLine /> 

                                    </p>

                                    <p 
                                        style={{
                                            display: 'inline-block', 
                                            position: 'relative',
                                            top: -10, 
                                            left: 0,
                                            margin: 0,
                                            fontWeight: '500',
                                            fontSize: '16px'
                                        }}
                                    >
                                    Inapoi
                                    </p>
                                    
                                </h3>
                            </Link>


                            <div
                                className="m-client-header"
                                style={{

                                    marginTop: -20
                                }}
                            >

                                {/* <div className="company-avatar-outer mobile">

                                    <div className="company-avatar-inner">
                                        <img src={this.state.accountImgSrc} alt="Avatar companie" />
                                    </div>

                                </div> */}

                                {
                                    this.state.serviceDetails['account_tk'] && (

                                        <AvatarAccount 
                                            isNotLogged = {false}
                                            accToken = {this.state.serviceDetails['account_tk']}
                                            border = '4px solid #fff'
                                            width = '102px'
                                            height = '102px'
                                            top = '20px'
                                        />
                                    )
                                }

                                <h4
                                    className="title"
                                >
                                    {this.state.accountName}
                                </h4>

                                <div
                                    style={{
                                        position: 'relative',
                                        minHeight: '900px',
                                        background: `url(${CanaryPlantImage}) bottom no-repeat`,
                                        backgroundSize: '300px 100px'
                                    }}
                                >

                                    <div
                                        style={{
                                            marginTop: 30,
                                            borderRadius: '20px',
                                            background: '#fff',
                                            boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)'
                                        }}
                                    >

                                        {
                                            this.state.eroareTx.length > 0 && (

                                                <div
                                                    style={{
                                                        padding: '40px 30px'
                                                    }}
                                                >

                                                    <ErrorMessage 
                                                        eroareTx = {this.state.eroareTx}
                                                    />

                                                </div>
                                            )
                                        }

                                        {

                                            this.state.eroareTx.length === 0 && (

                                                <>

                                                <div style={{padding: '40px 30px'}}>

                                                    <MClientServiceInfo 
                                                        
                                                        name={this.state.serviceDetails.name}
                                                        duration={this.state.serviceDetails.duration}
                                                        location={this.state.locatieCurenta}
                                                        price={this.state.serviceDetails.price}
                                                        description={this.state.serviceDetails.description}
                                                        member = {this.state.member}
                                                    />

                                                </div>

                                                <h3
                                                    style={{
                                                        margin: 0,
                                                        fontSize: 18,
                                                        color: '#455A64'
                                                    }}
                                                >

                                                    {
                                                        this.state.dateSelected &&
                                                        this.M.dayOfWeek(this.M.sqlDateToJSDate(this.state.dateSelected).getDay()) + ' ' +
                                                        this.M.sqlDateToJSDate(this.state.dateSelected).getDate() + ' ' +
                                                        this.M.monthName(this.M.sqlDateToJSDate(this.state.dateSelected).getMonth() ) || 'Selecteaza o data libera'
                                                    }

                                                </h3>

                                                <br />

                                                <div

                                                >
                                                    <DateChanger
                                                        nextMonth = {this.nextMonth}
                                                        date = {`${this.M.monthName(this.state.dataStart.getMonth())} ${this.state.dataStart.getFullYear()}`}
                                                        arrowSize = '28px'
                                                        arrowTop = '0px'
                                                        dateFont = '16px'
                                                        dateTop = '-7px'
                                                        arrowColor = '#455A64'
                                                        dateColor = '#455A64'
                                                        showBackArrow = {this.state.dataStart.getMonth() !== new Date().getMonth()}
                                                    />

                                                </div>
                                                

                                                <div className="m-calendar">

                                                    <IntervalViewClient 
                                                        dataStart={this.state.dataStart}
                                                        dataEnd={this.state.dataEnd}
                                                        elements={this.state.monthAppointments}
                                                        onClickDate={this.onClickDate}
                                                        dateSelected={this.state.dateSelected}
                                                    />

                                                </div>
                                                

                                                {
                                                    this.state.showSchedule &&
                                                
                                                <div id='select-hours' className="m-select-hour-container">

                                                    <AppointmentHourSelect 
                                                        daySelectedSchedule = {this.state.daySelectedSchedule}
                                                        dateSelected = {this.state.dateSelected}
                                                        serviceDetails = {this.state.serviceDetails}
                                                        confirmHour = {this.state.confirmHour}
                                                        onSelectHour = {this.onSelectHour}
                                                        onConfirmHour = {this.onConfirmHour}
                                                    />

                                                </div>

                                                }

                                                </>
                                            )
                                        }

                                    </div>

                                </div>


                                <p
                                    style={{
                                        
                                        paddingTop: 10,
                                        paddingBottom: 20,
                                        fontSize: 14,
                                        color: '#455A64'
                                    }}
                                >
                                    Programari prin  <a 
                                                        href="#"
                                                        style={{
                                                            color: '#455A64'
                                                        }}
                                                    >
                                                        Planary.ro
                                                    </a>
                                    
                                </p>

                            </div>
                        
                        </>
                   )
               }
                
            </MobileView>

            </React.Fragment>

        )
    }
}