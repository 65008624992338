import React from 'react';
import ClientValidationFuncs from '../../module/logic/ClientValidationFuncs';
import Masks from '../../providers/Masks';
import Utils from '../../providers/Utils';
import Ws from '../../providers/Ws';
import AuthInput from '../AuthInput';

export default class ClientDataForm extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            name: '',
            email: this.props.tranData['clie_email'] || '',
            phone: this.props.tranData['clie_tf'] || '',
            details: this.props.tranData['clie_detalii'] || '',
            clie_cui: this.props.tranData['clie_cui'] || '',
            clie_adresa: this.props.tranData['clie_adresa'] || '',
            clie_oras: this.props.tranData['clie_oras'] || '',
        };
    }

    WS = new Ws();
    M = new Masks();
    UT = new Utils();
    CVF = new ClientValidationFuncs();

    componentDidMount(){

        this.setClientName();
    }

    componentDidUpdate(prevProps, _){
        
        if(prevProps.triggerSave !== this.props.triggerSave){

            this.onCheckInputs();
        }
    }

    setClientName = () => {

        const numeClient = this.props.tranData.clie_nume + ' ' + this.props.tranData.clie_prenume;

        this.setState({

            name:  numeClient.length === 0 || 
            String(numeClient) === 'undefined undefined' ||
            String(numeClient) === 'null null' ? '' : numeClient,
        },
            () => {

                this.onSetDetailsLength();
            }
        );
    }

    onVerifyInputs = async () => {


        const inputs = {

            name: this.state.name,
            email: this.state.email,
            phone: this.state.phone,
            cui: this.state.clie_cui,
            address: this.state.clie_adresa,
            city: this.state.clie_oras
        };

        const result = await this.CVF.onCheckClientDataInputs(inputs, true);
      
        if(+this.props.isSmartBill > 0 && 
            +this.props.tranData['price'] > 0    
        ){

            if(result['name']['isOk'] && result['email']['isOk'] && result['phone']['isOk'] &&
            result['cui']['isOk'] && result['address']['isOk'] && result['city']['isOk'] 
            ){

                this.props.inputStatus(true, 'isClientDataOk', 'isClientDataOk', true, true);
            }
            else{

                this.props.inputStatus(true, 'isClientDataOk', 'isClientDataOk', false, true);
            }
        }
        else{

            
            if(result['name']['isOk'] && result['email']['isOk'] && result['phone']['isOk'] 
                ){

                this.props.inputStatus(true, 'isClientDataOk', 'isClientDataOk', true, true);
            }
            else{

                this.props.inputStatus(true, 'isClientDataOk', 'isClientDataOk', false, true);
            }
        
        }
    }

    onCheckInputs = async () => {

        const inputs = {

            name: this.state.name,
            email: this.state.email,
            phone: this.state.phone,
            cui: this.state.clie_cui,
            address: this.state.clie_adresa,
            city: this.state.clie_oras
        };

        const result = await this.CVF.onCheckClientDataInputs(inputs, true);

        this.setState({

            nameError: result['name']['error'],
            emailError: result['email']['error'],
            phoneError: result['phone']['error'],
            cuiError: result['cui']['error'],
            addressError: result['address']['error'],
            cityError: result['city']['error'],

            nameValid: result['name']['verified'],
            emailValid: result['email']['verified'],
            phoneValid: result['phone']['verified'],
            cuiValid: result['cui']['verified'],
            addressValid: result['address']['verified'],
            cityValid: result['city']['verified'],

            nameErrorTx: result['name']['errorTx'],
            emailErrorTx: result['email']['errorTx'],
            phoneErrorTx: result['phone']['errorTx'],
            cuiErrorTx: result['cui']['errorTx'],
            addressErrorTx: result['address']['errorTx'],
            cityErrorTx: result['city']['errorTx'],
        });
        
        // this.props.onCheckAndSave(result);
    }

    onChangeName = (ev) =>{

        this.setState({

            name: ev.target.value
        },
            () => {
                const clientNameArr = this.state.name.split(" ");
                const firstName = clientNameArr[0];
                let lastName = '';

                for(let i = 1; i < clientNameArr.length; i++){

                    lastName += clientNameArr[i] + ' ';
                }

                lastName = lastName.substr(0, lastName.length - 1) 
                this.props.inputStatus(true, 'nameStatus', 'clie_nume', firstName);
                this.props.inputStatus(true, 'nameStatus', 'clie_prenume', lastName);

                let duration = 500;
                clearTimeout(this.inputTimer);
                    this.inputTimer = setTimeout( async ()=>{
                await this.onVerifyInputs();
                }, duration);
            }
        
        );
    }

    onChangeEmail = (ev) =>{

        this.setState({

            email: ev.target.value
        },
            () => {

                this.props.inputStatus(true, 'emailStatus', 'clie_email', ev.target.value);

                let duration = 500;
                clearTimeout(this.inputTimer);
                    this.inputTimer = setTimeout( async ()=>{
                await this.onVerifyInputs();
                }, duration);
            }
        );
    }

    onChangePhone = (ev) =>{

        this.setState({

            phone: ev.target.value
        },
        
            () => {

                this.props.inputStatus(true, 'phoneStatus', 'clie_tf', ev.target.value);

                let duration = 500;
                clearTimeout(this.inputTimer);
                    this.inputTimer = setTimeout( async ()=>{
                await this.onVerifyInputs();
                }, duration);
            }
        )
    }

    onSetDetailsLength = () => {

        let length = this.state.details.replaceAll(/\s/g,'').length;

        this.setState({

            detailsLimit: length
        });
    }


    onChangeDetails = (ev) =>{

        let count = ev.target.value.replaceAll(/\s/g,'').length;

        if(count <= 280){

            this.setState({

                details: ev.target.value,
                detailsLimit: +count
            },
            
                () => {

                    this.props.inputStatus(true, 'detailsStatus', 'clie_detalii', ev.target.value);

                    let duration = 500;
                    clearTimeout(this.inputTimer);
                        this.inputTimer = setTimeout( async ()=>{
                    await this.onVerifyInputs();
                    }, duration);
                }
            )
        }
    }

    onChangeFacturare = (ev) => {

        this.setState({

            [ev.target.name]: ev.target.value
        },
            () => {

                this.props.inputStatus(true, `${ev.target.name}Status`, ev.target.name , ev.target.value);

                let duration = 500;
                clearTimeout(this.inputTimer);
                    this.inputTimer = setTimeout( async ()=>{
                await this.onVerifyInputs();
                }, duration);
            }
        );
    }


    render(){

        return(

            <div>

                <div className="form-control">

                    <AuthInput 
                        label="Nume si Prenume"
                        labelStyle={{
                            top: 6,
                            fontSize: '10px'
                        }}
                        type="text"
                        value={this.state.name}
                        placeholder="ex: Ion Popescu"
                        onChange={this.onChangeName}
                        iserror={this.state.nameError}
                        errormsg={this.state.nameErrorTx}
                        verified={this.state.nameValid}
                        onFocus = {
                            () => {

                                this.setState({
                                    nameErrorTx: null
                                })
                            }
                        }
                />

                </div>

                <div 
                    className="form-control"
                    style={{
                        marginTop: 10
                    }}
                    >

                    <AuthInput
                        label="Adresa Email"
                        labelStyle={{
                            top: 6,
                            fontSize: '10px'
                        }}
                        type="email"
                        value={this.state.email}
                        placeholder="ex: ionpopescu@mail.ro"
                        onChange={this.onChangeEmail}
                        iserror={this.state.emailError}
                        errormsg={this.state.emailErrorTx}
                        verified={this.state.emailValid}
                        onFocus = {
                            () => {

                                this.setState({
                                    emailErrorTx: null
                                })
                            }
                        }
                    />

                </div>

                <div 
                    className="form-control"
                    style={{
                        marginTop: 10
                    }}
                    >

                    <AuthInput 
                        label="Numar de Telefon"
                        labelStyle={{
                            top: 6,
                            fontSize: '10px'
                        }}
                        type="text"
                        value={this.state.phone}
                        placeholder="ex: 0735311123"
                        onChange={this.onChangePhone}
                        iserror={this.state.phoneError}
                        errormsg={this.state.phoneErrorTx}
                        verified={this.state.phoneValid}
                        onFocus = {
                            () => {

                                this.setState({
                                    phoneErrorTx: null
                                })
                            }
                        }
                    />

                </div>

                <p
                    style={{

                        margin: 0,
                        marginTop: 10,
                        textAlign: 'left',
                        color: '#263238',
                        fontSize: '14px'
                    }}
                    >
                    Adauga mai jos orice consideri ca va 
                    ajuta pentru pregatirea intalnirii.
                    </p>

                    <div 
                    className="form-control"
                    style={{
                        marginTop: 10
                    }}
                >

                <textarea
                    className="appoint-desc"
                    value={this.state.details}
                    placeholder="Scrie aici orice consideri ca poate ajtua buna desfasurare a acestei programari..."
                    onChange={this.onChangeDetails}

                />

                <p
                    className="appoint-desc-limit"
                >
                    {this.state.detailsLimit} / 280 Caractere
                </p>

                <label
                    className="appoint-desc-label"
                    style={{
                        top: 6
                    }}
                >
                    Detalii
                </label>

                </div>

                {
                    +this.props.isSmartBill > 0 && 
                    +this.props.tranData['price'] > 0 &&
                    (

                        <div>

                            <h3 
                                className="client-data-datele-mele-title"
                                style={{
                                    marginTop: 20,
                                    color: '#455A64',
                                    fontSize: '16px',
                                    textAlign: 'left'
                                }}
                            >
                                Date facturare
                            </h3>

                            <div 
                                className="form-control"
                                style={{
                                    marginTop: 10
                                }}
                            >

                                <AuthInput 
                                    label="CUI/CNP"
                                    labelStyle={{
                                        top: 6,
                                        fontSize: '10px'
                                    }}
                                    type="text"
                                    name = "clie_cui"
                                    value={this.state.clie_cui}
                                    placeholder="ex: 5000303450054"
                                    onChange={this.onChangeFacturare}
                                    iserror={this.state.cuiError}
                                    errormsg={this.state.cuiErrorTx}
                                    verified={this.state.cuiValid}
                                    onFocus = {
                                        () => {

                                            this.setState({
                                                cuiErrorTx: null
                                            })
                                        }
                                    }
                                />
                                
                            </div>

                            <div 
                                className="form-control"
                                style={{
                                    marginTop: 10
                                }}
                            >

                                <AuthInput 
                                    label="Adresa"
                                    labelStyle={{
                                        top: 6,
                                        fontSize: '10px'
                                    }}
                                    name = "clie_adresa"
                                    type="text"
                                    value={this.state.clie_adresa}
                                    placeholder="ex: Str. Bucovina Nr. 3"
                                    onChange={this.onChangeFacturare}
                                    iserror={this.state.addressError}
                                    errormsg={this.state.addressErrorTx}
                                    verified={this.state.addressValid}
                                    onFocus = {
                                        () => {

                                            this.setState({
                                                addressErrorTx: null
                                            })
                                        }
                                    }
                                />

                                <div 
                                    className="form-control"
                                    style={{
                                        marginTop: 10
                                    }}
                                >

                                    <AuthInput 
                                        label="Oras"
                                        labelStyle={{
                                            top: 6,
                                            fontSize: '10px'
                                        }}
                                        name = "clie_oras"
                                        type="text"
                                        value={this.state.clie_oras}
                                        placeholder="ex: Bucuresti"
                                        onChange={this.onChangeFacturare}
                                        iserror={this.state.cityError}
                                        errormsg={this.state.cityErrorTx}
                                        verified={this.state.cityValid}
                                        onFocus = {
                                            () => {

                                                this.setState({
                                                    cityErrorTx: null
                                                })
                                            }
                                        }
                                    />
                                    
                                </div>
                                
                            </div>

                        </div>
                    )
                }

            </div>
        );
    }
    
}