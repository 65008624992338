import React from 'react';
import { BrowserView, MobileView } from 'react-device-detect';

import { MdClear } from 'react-icons/md';
import AlertImage from '../../assets/Feeling sorry-cuate 1.png';
import ButtonForm from '../../commons/ButtonForm';
import LocalStorage from '../../providers/LocalStorage';
import Ws from '../../providers/Ws';
import AuthInput from '../AuthInput';

export default class ModalSmartbill extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            emailErr: false,
            emailErrTx: '',
            emailVerified: false,
            email: '',

            tokenErr: false,
            tokenErrTx: '',
            tokenVerified: false,
            token: ''
        };
    }

    WS = new Ws();

    componentDidMount(){

        this.onSetSmartbillData();
    }

    onClosePopup = (ev) => {

        if( ev.target.id === 'modal-popup' ||  ev.target.id === 'close-modal-btn'){

            this.props.onClosePopup("showSmartBill");
        }
  
    }

    onSetSmartbillData = () => {

        let smartbillData = this.props.smartBillIntegration.filter(el => el['tip'] === 'smart_bill' && (el['smart_bill_api_key'] || '').length > 0);
        let smartBillEmail = '';
        let smartBillToken = '';

        if(smartbillData.length > 0){

            smartBillEmail = smartbillData[0]['smart_bill_email'];
            smartBillToken = smartbillData[0]['smart_bill_api_key'];
        }

        this.setState({

            email: smartBillEmail,
            token: smartBillToken
        })
    }

    onCheckSmartBillData = () => {

        let emailErr = false;
        let emailErrTx = '';
        let emailValid = true;

        let tokenErr = false;
        let tokenErrTx = '';
        let tokenValid = true;

        if(this.state.email.length < 6 || !this.state.email.includes('@') || 
        !this.state.email.includes('.')){

            emailErr = true;
            emailErrTx = 'Format email invalid.'
            emailValid = false;
        }
        else{

            emailErr = false;
            emailErrTx = ''
            emailValid = true;
        }

        if(this.state.token.length < 8){

            tokenErr = true;
            tokenErrTx = 'Token-ul este prea scurt';
            tokenValid = false;
        }
        else{

            tokenErr = false;
            tokenErrTx = '';
            tokenValid = true;
        }

        this.setState({

            emailErr: emailErr,
            emailErrTx: emailErrTx,
            emailVerified: emailValid,

            tokenErr: tokenErr,
            tokenErrTx: tokenErrTx,
            tokenVerified: tokenValid
        },
            async () => {

                if(emailValid && tokenValid){

                    await this.onSaveSmartbillData();
                }
            }
        )

        // await this.props.onRefreshData();
        // this.props.onClosePopup("showSmartBill");
    }

    onGetCuiFirma = async () => {

        const accountToken = new LocalStorage().GetAccountToken();

        let queryCui = `select cui from accounts where token = '${accountToken}' `;
        let cuiFirmaRes = await this.WS.sqlCommand(`select`, queryCui);

        if(cuiFirmaRes.success){

            return {data: cuiFirmaRes.content[0]['cui'], err: null};
        }
        else{

            return {data: null, err: 'Eroare preluare CUI din db.'};
        }
        
    }

    onSaveSmartbillData = async () => {

        let isFirstIntegration = this.props.smartBillIntegration.findIndex(el => el['tip'] === 'smart_bill' && (el['smart_bill_api_key'] || '').length > 0) <= -1;

        const userData = new LocalStorage().GetUserData();
        const accountToken = new LocalStorage().GetAccountToken();
        const userToken = userData.userData[0]['token'];

        let querySmartBill = '';
        let smartbillData = [];

        let cuiFirma = await this.onGetCuiFirma();

        if(cuiFirma.err === null){

            if(isFirstIntegration){

                querySmartBill = `insert into accounts_integrations(tip, user_token, account_token,
                    activ, smart_bill_cui, smart_bill_serie, smart_bill_email, smart_bill_api_key) 
                    select
                    'smart_bill', '${userToken}', '${accountToken}', 1, '${cuiFirma.data}', 
                    'PLN', '${this.state.email}', '${this.state.token}'
                `
                smartbillData = await this.WS.sqlCommand(`insert`, querySmartBill);
            }
            else{
    
                querySmartBill = `update accounts_integrations set smart_bill_email = '${this.state.email}',  smart_bill_api_key = '${this.state.token}'
                where account_token = '${accountToken}' and tip = 'smart_bill' `;

                smartbillData = await this.WS.sqlCommand(`update`, querySmartBill);
            }
    
            if(smartbillData.success){
                
                await this.props.onRefreshData();
                this.props.onClosePopup("showSmartBill");
            }
        }
    }   
    
    render(){

        return(
      
            <div
                id="modal-popup"
                className="calendar-modal-container"
                onClick={(ev) => {this.onClosePopup(ev)}}
            >

                <div 
                    className="calendar-popup-card"
                    style={{
                        padding: '30px',
                        textAlign: 'left'
                    }}
                >

                    <MdClear
                        id="close-modal-btn"
                        style={{
                            position: 'absolute',
                            top: 10,
                            right: 10,
                            fontSize: '24px',
                            color: '#323232',
                            cursor: 'pointer'
                        }}

                        onClick={(ev) => {this.onClosePopup(ev)}}
                    />

                    <p
                        style={{
                            margin: 0,
                            fontSize: '16px',
                            fontWeight: '400',
                            color: '#455A64'
                        }}
                    >
                        Pentru a integra contul de Smartbill va rugam completati datele de mai jos! Tokenul se 
                        gaseste in contul de SmartBill Facturare/Gestiune, dupa in sectiunea Contul Meu, iar mai apoi accesati Integrari, 
                        in partea de jos a paginii, in zona dedicata API.
                    </p>

                    <br />

                    <div className='form-control'>
                        <AuthInput 
                            type="text"
                            label='Email'
                            labelStyle={{
                                fontSize: '10px'
                            }}
                            iserror={this.state.emailErr}
                            errormsg={this.state.emailErrTx}
                            value={this.state.email}
                            onChange={(ev) => this.setState({email: ev.target.value})}
                            verified={this.state.emailVerified}
                            onFocus = {
                                () => {
    
                                    this.setState({
                                        // denumireError: false,
                                        emailErrTx: null
                                    })
                                }
                            }
                        />
                    </div>

                    <div 
                        className='form-control'
                        style={{marginTop: 12}}
                    >

                        <AuthInput 
                            type="text"
                            label='Token'
                            labelStyle={{
                                fontSize: '10px'
                            }}
                            iserror={this.state.tokenErr}
                            errormsg={this.state.tokenErrTx}
                            value={this.state.token}
                            onChange={(ev) => this.setState({token: ev.target.value})}
                            verified={this.state.tokenVerified}
                            onFocus = {
                                () => {
    
                                    this.setState({
                                        // denumireError: false,
                                        tokenErrTx: null
                                    })
                                }
                            }
                        />
                    </div>

                    <br />

                    <div style={{textAlign: 'center'}}>

                        <ButtonForm
                            // id="close-modal-btn"
                            tx='Salveaza'
                            type='button'
                            onClick={this.onCheckSmartBillData}
                            style={{
                                width: '150px',
                                background: '#FFC727'
                            }}
                        />
                        
                    </div>

                </div>

                

            </div>

        )
    }
}