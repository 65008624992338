

export default class EmailTemplatesDeclarations {


    onGenerateValideazaEmailTemplate = (linkValidare) => {

        const body = `
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta http-equiv="X-UA-Compatible" content="IE=edge">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Planary - Programare Noua</title>

            <style>

                html,body{

                    margin: 0;
                    padding: 0;
                    box-sizing: border-box;
                    height: 100%;
                }


                *, *:before, *:after {
                    box-sizing: border-box;

                }
                @media screen and (max-width: 600px){

                    .main{

                        width: 100% !important;
                        padding: 0 !important;
                    }

                    .card{
                        padding: 30px !important;
                    }
                }

            </style>

        </head>

        <body
            style="
                background: #F6F6F6;
                font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
            sans-serif;
            "
        >

        <div 
            class="main"
            style="position: relative; 
                width: 620px; 
                margin: 20px auto;
            
            "
        >
            <div 
                style="
                    width: 100%;
                    display: flex;
                "
                >
                <img 
                    src="https://i.ibb.co/FDndkP4/planary-logo-badge-1.png"
                />

                <p
                    style="
                        position: relative;
                        margin: 0;
                        margin-top: 14px;
                        margin-left: 8px;
                        font-size: 18px;
                        font-weight: 500;
                        color: #000;
                    "
                >
                    Planary
                </p>
                
            </div>

        </div>

        <div 
            class="main"
            style="position: relative; 
                width: 620px; 
                margin: 20px auto;
            "
        >

            <div
                class="card"
                style="
                    margin-top: 20px;
                    padding: 50px;
                    width: 100%;
                    height: auto;
                    background: #fff;
                    border-radius: 10px;
                    text-align: left;
                "
            >

                <h1
                    style="
                        margin: 0;
                        padding:0;
                        font-size: 36px;
                        color: #E6B323;
                        font-weight: 500;
                    "
                >
                    Salutare!
                </h1>

                <br>

                <p
                    style="
                        margin: 0;
                        font-size: 16px;
                        font-weight: 400px;
                        color: #455A64;
                    "
                >
                    Ai creat un cont nou pe Planary, aplicatia ta pentru administrarea
                    programarilor. Ne bucuram sa te avem alaturi de comunitatea noastra
                    frumoasa de profesionisti.
                    <br>
                    <br>
                    Pentru a finaliza inregistrarea avem nevoie sa validam adresa ta de e-mail.
                    <br>
                    <br>
                    Apasa butonul de mai jos sau click dreapta si copiaza link-ul de mai jos in 
                    bara de adresa a browser-ului tau pentru a finaliza validarea.
                </p>

                <br>

                <a
                    href="${linkValidare}"
                    style="
                        display: block;
                        width: 250px;
                        background: #F8BC05;
                        border-radius: 100px;
                        padding: 15px;
                        color: #fff;
                        font-weight: 500;
                        text-align: center;
                        text-decoration: none;
                    "
                >
                    Valideaza
                </a>
                
                <br>

                <div
                    style="
                        width: 100%;
                        height: 1px;
                        background: #EBEBEB;
                    "
                >
                </div>

                <br>

                <a
                    href="${linkValidare}"
                    style="
                        display: block;
                        margin: 0;
                        font-size: 16px;
                        font-weight: 400;
                        color: #44A5FF;
                        cursor: pointer;
                        text-decoration: none;
                        word-wrap: break-word;
                    "
                >
                    ${linkValidare}
                </a>

                <br>

                <div
                    style="
                        width: 100%;
                        height: 1px;
                        background: #EBEBEB;
                    "
                >

                </div>

                <br>

                <p
                    style="
                        margin: 0;
                        font-size: 16px;
                        font-weight: 400px;
                    "
                >
                    Dupa validare vei intra in tutorialul de completare a datelor tale, dar te 
                    vom ajuta si cu crearea primului tau serviciu.
                </p>

                <br>

                <p
                    style="
                        margin: 0;
                        font-size: 16px;
                        font-weight: 400px;
                    "
                >
                    Te asteptam in cont!
                    <br>
                    Echipa Planary.
                </p>

            </div> 

            <br>

            <div style="margin: 0 auto; width: 90%;">

                <p
                    style="
                        margin: 0;
                        font-size: 14px;
                        font-weight: 400;
                        color: #000;
                    "
                >

                    Pentru a te asigura ca nu ratezi mesajele Planary, adauga adresa <b>info@planary.ro</b> in 
                    lista ta de adrese mail, contacte sau lista de adrese sigure.
                </p>

                <br>

                <p
                    style="
                        margin: 0;
                        font-size: 14px;
                        font-weight: 400;
                        color: rgb(69,90,100);
                    "
                >

                    Ai primit acest meaj email pentru ca ai creat un cont nou pe Planary.ro. Daca 
                    doresti sa nu mai primesti mesaje de la Planary apasa <a href="#">aici</a>
                </p>

                <br>

                <p
                    style="
                        margin: 0;
                        font-size: 14px;
                        font-weight: 400;
                        color: rgb(69,90,100);
                    "
                >

                    C 2021 Planary.ro. Toate drepturile rezervate. Logo-ul Planary este marca 
                    inregistrata a Planary S.R.L pe teritoriul Europei si statelor membre.
                </p>

            </div>
            

        </div>


        </body>

        </html>
        `;

        return body;
    }

    onGenerateMembruCreatEmail = (email, parola, echipa) => {

        const body = `
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta http-equiv="X-UA-Compatible" content="IE=edge">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Planary - Programare Noua</title>
        
            <style>
        
                html,body{
        
                    margin: 0;
                    padding: 0;
                    box-sizing: border-box;
                    height: 100%;
                }
        
        
                *, *:before, *:after {
                    box-sizing: border-box;
        
                }
                @media screen and (max-width: 600px){
        
                    .main{
        
                        width: 100% !important;
                        padding: 0 !important;
                    }
        
                    .card{
                        padding: 30px !important;
                    }
                }
        
            </style>
        
        </head>
        
        <body
            style="
                background: #F6F6F6;
                font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
            sans-serif;
            "
        >
        
        <div 
            class="main"
            style="position: relative; 
                width: 620px; 
                margin: 20px auto;
            
            "
        >
            <div 
                style="
                    width: 100%;
                    display: flex;
                "
                >
                <img 
                    src="https://i.ibb.co/FDndkP4/planary-logo-badge-1.png"
                />
        
                <p
                    style="
                        position: relative;
                        margin: 0;
                        margin-top: 14px;
                        margin-left: 8px;
                        font-size: 18px;
                        font-weight: 500;
                        color: #000;
                    "
                >
                    Planary
                </p>
                
            </div>
        
        </div>
        
        <div 
            class="main"
            style="position: relative; 
                width: 620px; 
                margin: 20px auto;
            "
        >
        
            <div
                class="card"
                style="
                    margin-top: 20px;
                    padding: 50px;
                    width: 100%;
                    height: auto;
                    background: #fff;
                    border-radius: 10px;
                    text-align: left;
                "
            >
        
                <h1
                    style="
                        margin: 0;
                        padding:0;
                        font-size: 36px;
                        color: #E6B323;
                        font-weight: 500;
                    "
                >
                    Salutare!
                </h1>
        
                <br>
        
                <p
                    style="
                        margin: 0;
                        font-size: 16px;
                        font-weight: 400px;
                        color: #455A64;
                    "
                >
                    Acesta este contul tau Planary in cadrul echipei ${echipa}. Ne bucuram sa te avem alaturi de comunitatea noastra
                    frumoasa de profesionisti.
                    <br>
                    <br>
                    Mai jos ai credentialele tale pentru autentificare.
                    <br>
                    <br>
                    Parola este una sigura, generata automat. O poti schimba din setarile contului tau Planary:
                </p>
        
                <br>
        
                <p
                    style="
                        margin: 0;
                        font-size: 16px;
                        font-weight: 400px;
                        color: #455A64;
                    "
                >
                    Email: ${email}
                </p>
        
                <p
                    style="
                        margin: 0;
                        font-size: 16px;
                        font-weight: 400px;
                        color: #455A64;
                    "
                >
                    Parola: ${parola}
                </p>
                
                <br>
        
                <div
                    style="
                        width: 100%;
                        height: 1px;
                        background: #EBEBEB;
                    "
                >
                </div>
        
                <br>
        
                <a
                    href="#"
                    style="
                        display: block;
                        margin: 0;
                        font-size: 16px;
                        font-weight: 400;
                        color: #44A5FF;
                        cursor: pointer;
                        text-decoration: none;
                        word-wrap: break-word;
                    "
                >
                    https://app.planary.ro/#/login 
                </a>
        
                <br>
        
                <div
                    style="
                        width: 100%;
                        height: 1px;
                        background: #EBEBEB;
                    "
                >
        
                </div>
        
                <br>
        
                <p
                    style="
                        margin: 0;
                        font-size: 16px;
                        font-weight: 400px;
                    "
                >
                    Nu iti fa griji! Beneficiezi de asistenta pentru configurarea contului tau din partea echipei Planary.
                </p>
        
                <br>
        
                <p
                    style="
                        margin: 0;
                        font-size: 16px;
                        font-weight: 400px;
                    "
                >
                    Te asteptam in cont!
                    <br>
                    Echipa Planary.
                </p>
        
            </div> 
        
            <br>
        
            <div style="margin: 0 auto; width: 90%;">
        
                <p
                    style="
                        margin: 0;
                        font-size: 14px;
                        font-weight: 400;
                        color: #000;
                    "
                >
        
                    Pentru a te asigura ca nu ratezi mesajele Planary, adauga adresa <b>info@planary.ro</b> in 
                    lista ta de adrese mail, contacte sau lista de adrese sigure.
                </p>
        
                <br>
        
                <p
                    style="
                        margin: 0;
                        font-size: 14px;
                        font-weight: 400;
                        color: rgb(69,90,100);
                    "
                >
        
                    Ai primit acest meaj email pentru ca ai creat un cont nou pe Planary.ro. Daca 
                    doresti sa nu mai primesti mesaje de la Planary apasa <a href="#">aici</a>
                </p>
        
                <br>
        
                <p
                    style="
                        margin: 0;
                        font-size: 14px;
                        font-weight: 400;
                        color: rgb(69,90,100);
                    "
                >
        
                    C 2021 Planary.ro. Toate drepturile rezervate. Logo-ul Planary este marca 
                    inregistrata a Planary S.R.L pe teritoriul Europei si statelor membre.
                </p>
        
            </div>
            
        
        </div>
        
        
        </body>
        
        </html>
        `;

        return body;
    }
}