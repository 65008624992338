import React from 'react';

import Avatar from '../../commons/Avatar';
import CalendarBg from '../../assets/calendar_bg.png';
import UserIcon from '../../assets/account_circle.svg';
import AuthInput from '../../commons/AuthInput';
import GpsIcon from '../../assets/pin_drop.svg';
import LaptopAndMugImg from '../../assets/laptop_and_mug.png';

import { MdAlarm, MdDone } from 'react-icons/md';
import Ws from '../../providers/Ws';
import LocalStorage from '../../providers/LocalStorage';
import ButtonSaveDashboard from '../../commons/ButtonSaveDashboard';

import SuccessSaveToast from '../../commons/SuccessSaveToast';
import Utils from '../../providers/Utils';
import ErrorToast from '../../commons/ErrorToast';
import { BrowserView, MobileView } from 'react-device-detect';
import ProfileFuncs from '../logic/ProfileFuncs';
import Declarations from '../../providers/Declarations';

import UserDataInputs from '../../commons/profile/UserDataInputs';
import CompanyDataInputs from '../../commons/profile/CompanyDataInputs';
import AddressDataInputs from '../../commons/profile/AddressDataInputs';

export default class Profil extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            dateProfil: {},
            emailProfil: '',
            isMainAccount: true,

            showToastPopup: false,
            showToastPopupError: false,
            actionTx: '',
            avatarSrc: '',

            canSave: false,

            display_nameStatus: false,
            adresaStatus: false,
            cuiStatus: false,
            orasStatus: false,
            telefonStatus: false,
            reg_comStatus: false,
            emailStatus: false, 
            numeStatus: false,

            triggerSave: false,
            isLoading: true
        };
    }

    WS = new Ws();
    UT = new Utils();
    PF = new ProfileFuncs();

    componentsData = new Declarations().destructureDataObject(new Declarations().profileData);

    componentsStatus = {
        
        isUserDataOk: true,
        isCompanyDataOk: true,
        isAddressDataOk: true
    };

    componentDidMount(){

        
        this.getAccountData();
    }

    extractContactData = (userContactsArray, contactType) =>{

        let _contactRows = userContactsArray.filter(ucEl => ucEl['tip'] == contactType);

        if(_contactRows.length < 1){

            return { err: 'Contact type not found', data: null };
        }

        return { err: null, data: _contactRows[0]['value'] };
    }

    getAccountData = async () => {

        const TOKEN_CONT = new LocalStorage().GetAccountToken();
        const WRK_TOKEN =  await this.WS.getUserToken();
        const tokenUser = new LocalStorage().GetUserData().userData[0].token;

        let q = `select * from accounts where token = '${TOKEN_CONT}'  `;
        

        let _accountData = this.WS.sqlCommand(`select`, q);
        // 

        let q2 = `select * from user_contacts where token_user = '${tokenUser}' `;
        // let q2 = `select * from users where account_id = '${TOKEN_CONT}' `;
        let _userData = this.WS.sqlCommand(`select`, q2);

        const q3 = `select * from users where account_id = '${TOKEN_CONT}' `;
        let _users = this.WS.sqlCommand(`select`, q3);

        let [accountData, userData, users] = await Promise.all( [_accountData, _userData, _users] );
        
        if(accountData.success && userData.success){

            let contactEmail = this.extractContactData(userData.content, 'email');
            const isMainAccount = tokenUser === users.content[0].token;

            

            if(contactEmail.err !== null){

                contactEmail = '';
            }
            else{

                contactEmail = contactEmail.data;
            }

            const accData = await this.UT.getAccountAvAndName(false, TOKEN_CONT);

            let currentUser = users.content.filter( user => user.token === tokenUser );
            currentUser = {

                ...currentUser[0],
                display_name: currentUser[0].nume
            }
            
            let profileData;

            if(isMainAccount){

                profileData = {

                    ...accountData.content[0],
                    email: contactEmail
                };
            }
            else{

                profileData = {

                    ...currentUser,
                    email: contactEmail
                };
            }

            this.componentsData = new Declarations().updateComponentsData(this.componentsData, profileData, 'profile');

            

            this.setState({

                dateProfil: isMainAccount ? accountData.content[0] : currentUser,
                emailProfil: contactEmail,
                isMainAccount: isMainAccount,
                
                avatarSrc: this.WS.serverAddress + 'staticprovider/getimg?filepath=Storage/Avatars/' + accData['avatar'],
                isLoading: false
            });

        }
        else{

            this.setState({

                showToastPopupError: true,
                actionTx: 'Eroare preluare date profil din server.'
            });

            throw new Error("Failed to get account data from server: " + accountData.message)
        }
    }

    checkSaveButtonStatus = () => {

        let status = null;

        if(!this.state.display_nameStatus && !this.state.emailStatus &&
            !this.state.telefonStatus && !this.state.numeStatus &&
            !this.state.cuiStatus && !this.state.reg_comStatus && 
            !this.state.adresaStatus && !this.state.orasStatus
        ){

            status = false;
        }
        else{

            status = true;
        }

        this.setState({

            canSave: status
        });
    }
    
    onUpdateAccountData = async (queryArr) => {
        
        const updateRes = await this.PF.onUpdateAccountData(queryArr, this.state.emailProfil);

        if(updateRes.err === null){

            // alert("Succes update!")
            this.setState({

                canSave: false,
       
                showToastPopup: true,
                actionTx: 'au fost salvate cu succes!',

                display_nameStatus: false,
                adresaStatus: false,
                cuiStatus: false,
                orasStatus: false,
                telefonStatus: false,
                reg_comStatus: false,
                emailStatus: false, 
                numeStatus: false,

                triggerSave: false,
            })
        }
        else{

            this.setState({

                showToastPopupError: true,
                actionTx: 'Eroare actualizare date profil.'
            })
            // alert("Fail update!")
        }
    }

    
    inputStatus = (status, statusName, inputName, data, isVerify = false) => {

        if(isVerify){

            this.componentsStatus[statusName] = data;
        }
        else{

            this.componentsData[inputName] = data;

            
    
            this.setState({
                
                [statusName]: status
            },
                () => {
    
                    this.checkSaveButtonStatus();
                }
            );
        }
    }

    onCheckAndSave = async () => {

        const result = Object.values(this.componentsStatus).every(item => item === true)
        
        if(result){

            if(this.state.isMainAccount){

                const queryObject = new Declarations().generateQuerySet('profile', this.componentsData);
                const queryArr = new Declarations().onGenerateQueryArray(queryObject);
                
                await this.onUpdateAccountData(queryArr);
            }
            else{

                const q = `update users set nume = '${this.componentsData.display_name}', email = '${this.componentsData.email}',
                            telefon = '${this.componentsData.telefon}'

                            where token = '${new LocalStorage().GetUserData().userData[0].token}'
                            `;

                let res = await this.WS.sqlCommand(`update`, q);

                if(res.success){

                    this.setState({

                        canSave: false,
               
                        showToastPopup: true,
                        actionTx: 'au fost salvate cu succes!',
        
                        display_nameStatus: false,
                        adresaStatus: false,
                        cuiStatus: false,
                        orasStatus: false,
                        telefonStatus: false,
                        reg_comStatus: false,
                        emailStatus: false, 
                        numeStatus: false,
        
                        triggerSave: false,
                    });
                }
                else{

                    this.setState({

                        showToastPopupError: true,
                        actionTx: 'Eroare actualizare date profil.'
                    })
                }
            }
        }
        else{

            this.setState({

                triggerSave: false
            });
        }
    }

    onClosePopup = (type) => {

        this.setState({

            [type]: false
        });
    }   

    showSuccesUpload = () => {

        this.setState({

            showToastPopup: true,
            actionTx: 'au fost salvate cu succes.'
        },
        
            async () => {

                await this.getAccountData();
            }
        );
    }

    showErrorUpload = (tx) => {

        this.setState({

            showToastPopupError: true,
            actionTx: tx
        });
    }
    

    onTriggerSave = () => {

        this.setState({

            triggerSave: true
        },
        
            () => {

                this.onCheckAndSave();
            }
        )
    }

    render(){

        return(

            // <LoadScript googleMapsApiKey={key} libraries={lib}>
            
            <div>

                {
                    this.state.showToastPopupError && (

                        <ErrorToast 
                            action={this.state.actionTx}
                            onClosePopup = {this.onClosePopup}
                        />
                    )
                }

                {
                    this.state.showToastPopup && (

                        <SuccessSaveToast 
                            onClosePopup = {this.onClosePopup}
                            saveName = 'Datele profilului'
                            action = {this.state.actionTx}
                        />
                    )
                }

                {/* <BrowserView> */}

                <div 
                    className="wrapper-dashboard" 
                    style={{

                        margin:'30px auto',
                        minHeight: '960px',
                        background: `url(${LaptopAndMugImg}) bottom no-repeat`
                    }}
                >

                    <ButtonSaveDashboard 
                        canSave = {this.state.canSave}
                        onClick = {this.onTriggerSave}
                    />

                    <div className="service-details-card">

                        <div className="row">

                            <div className="col-l-4 col-m-4 col-s-12">

                                <Avatar 
                                    avatarSrc = {this.state.avatarSrc}
                                    showSuccesUpload = {this.showSuccesUpload}
                                    showErrorUpload = {this.showErrorUpload}
                                    accToken = {new LocalStorage().GetAccountToken()}
                                />

                                <img 
                                    className="bg-img-profile"
                                    src={CalendarBg}
                                    alt="Calendar and furniture..."
                                    style={{
                                        position: 'relative',
                                        display: 'block',
                                        top: this.state.isMainAccount ? 120 : 0,
                                        margin: '0 auto',
                                        marginBottom: 0,
                                        width: '349.25px',
                                        height: '138px'
                                    }}
                                />

                            </div>

                            <div 
                                className="col-l-4 col-m-4 col-s-12"
                                style={{
                                    textAlign: 'left'
                                }}
                            >

                                <h3
                                    style={{
                                        margin: 0
                                    }}
                                >
                                    <p
                                        style={{
                                            position: 'relative',
                                            display: 'inline-block',
                                            margin: 0,
                                            
                                        }}
                                    >
                                        <img 
                                            src={UserIcon}
                                            alt="user icon"
                                            
                                        />
                                    </p>

                                    <p
                                        style={{
                                            position: 'relative',
                                            display: 'inline-block',
                                            top: -4,
                                            margin: 0,
                                            fontSize: '18px',
                                            fontWeight: '500',
                                            color: '#455A64'
                                        }}
                                    >
                                        &nbsp; Utilizator
                                    </p>

                                </h3>

                                {
                                    Object.keys(this.state.dateProfil).length > 0 && (

                                        <UserDataInputs 
                                            data = {this.state.dateProfil}
                                            email = {this.state.emailProfil}
                                            triggerSave = {this.state.triggerSave}
                                            inputStatus = {this.inputStatus}
                                            disableLink = {true}
                                        />
                                    )
                                }
                                
                                <br />

                                {
                                    Object.keys(this.state.dateProfil).length > 0 && 
                                    this.state.isMainAccount &&
                                    (

                                        <CompanyDataInputs 
                                            data = {this.state.dateProfil}
                                            triggerSave = {this.state.triggerSave}
                                            inputStatus = {this.inputStatus}
                                            showTitle = {true}
                                        />
                                    )
                                }

                            </div>

                           {
                             this.state.isMainAccount ? (

                                <div 
                                    className="col-l-4 col-m-4 col-s-12"
                                    style={{
                                        textAlign: 'left'
                                    }}
                                >

                                    <h3
                                        style={{
                                            margin: 0
                                        }}
                                    >
                                        <p
                                            style={{
                                                position: 'relative',
                                                display: 'inline-block',
                                                margin: 0,
                                                fontSize: '24px',
                                                color: '#FFC727'
                                            }}
                                        >
                                            <img 
                                                src={GpsIcon}
                                                alt="user icon"
                                                
                                            />
                                        </p>

                                        <p
                                            style={{
                                                position: 'relative',
                                                display: 'inline-block',
                                                top: -4,
                                                margin: 0,
                                                fontSize: '18px',
                                                fontWeight: '500',
                                                color: '#455A64'
                                            }}
                                        >
                                            &nbsp; Locatie Fizica
                                        </p>

                                    </h3>

                                    {
                                        Object.keys(this.state.dateProfil).length > 0 && (

                                            <AddressDataInputs 
                                                data = {this.state.dateProfil}
                                                triggerSave = {this.state.triggerSave}
                                                inputStatus = {this.inputStatus}
                                            />
                                        )
                                    }
                                </div>
                             ) : null
                           }

                        </div>

                    </div>

                </div>

                {/* <button
                    type="button"
                    onClick={this.onUpdateAccountData}
                >
                    Salveaza
                </button> */}
                {/* </BrowserView>

                <MobileView>



                </MobileView> */}

            </div>
            // </LoadScript>
        )
    }
}