import React from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import ButtonForm from './ButtonForm';

export default class ButtonsNavRegister extends React.Component{

    constructor(props){

        super(props);
    }

    render(){

        return(

            <div 
                className='register-btn-container'
            >
                <div className='back-btn-container'>

                    <div className='back-btn'>
                        <IoIosArrowBack 
                            onClick={this.props.onBack}
                        />
                    </div>

                </div>

            <div className='continue-btn-container'>

                    <ButtonForm
                        type='button'
                        tx={
                            <>
                                <p style={{display: 'inline-block', position: 'relative', top: '-2px', margin: 0}}>
                                    {this.props.tx} &nbsp;
                                </p> 
                                <IoIosArrowForward className='register-next-arrow'/>
                            </>
                        }
                        
                        style={{
                            ...this.props.style,
                            width: '100%',
                            height: '56px',
                            background: '#F8BC05',
                            fontSize: '16px',
                            padding: '0px 35px 0px 35px'
                        }}
                        onClick={
                            () => {
                                this.props.onPressBtn()
                                // this.onCheckAndSave(true)
                            }
                        }
                    />
            </div>

        </div>
        );
    }
}