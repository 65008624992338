import React from 'react';

import Masks from '../../../providers/Masks';
import Utils from '../../../providers/Utils';
import Ws from '../../../providers/Ws';
import ServicesFuncs from '../../logic/ServicesFuncs';

import RegisterHeader from '../RegisterHeader';
import Image from '../../../assets/Businesswoman-cuate 1.png';
import IconText from '../../../commons/IconText';

import { MdAlarm, MdLocalActivity, MdPalette, MdSchool, MdSettingsInputComponent, MdTouchApp } from 'react-icons/md';
import ButtonForm from '../../../commons/ButtonForm';
import { IoIosArrowForward } from 'react-icons/io';
import { RiCupFill, RiFolderReduceFill } from 'react-icons/ri';
import ErrorToast from '../../../commons/ErrorToast';
import Declarations from '../../../providers/Declarations';
import SvNameAndLink from '../../../commons/service/SvNameAndLink';

import { ReactComponent as NotesIcon } from '../../../assets/notes.svg';
import SvDescription from '../../../commons/service/SvDescription';
import { ReactComponent as PinDrop } from '../../../assets/pin_drop.svg';
import SvLocation from '../../../commons/service/SvLocation';
import SvDuration from '../../../commons/service/SvDuration';
import { FaBook, FaCalendarCheck } from 'react-icons/fa';
import SvDisponibility from '../../../commons/service/SvDisponibility';
import SvBreaks from '../../../commons/service/SvBreaks';

import CanaryPlantImage from '../../../assets/canary_plant_bg.png';
import { BrowserView, MobileView } from 'react-device-detect';
import ButtonsNavRegister from '../../../commons/ButtonsNavRegister';
import ServiceAccessRow from '../../../commons/ServiceAccessRow';
import { GoCheck } from 'react-icons/go';
import SvColor from '../../../commons/service/SvColor';

import MBirdAndPlantImage from '../../../assets/bird_and_plant_mobile.png';
import { Carousel } from 'react-responsive-carousel';
import { CarouselProvider, Slide, Slider } from 'pure-react-carousel';

// import { Swiper, SwiperSlide } from 'swiper/react';


import SwiperCore, { A11y, Navigation, Pagination, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.min.css';
// import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/navigation/navigation.min.css';
import "swiper/components/pagination/pagination.scss";
import LocalStorage from '../../../providers/LocalStorage';
import Loader from '../../../commons/Loader';

SwiperCore.use([Navigation, Pagination]);

export default class SvFinalConfig extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            detaliiServiciu: {},
            integrations: [],
            actionTx: '',
            eroareTx: '',
            showToastPopupError: false,
            activeSwipeIndex: 0,
            serviceTk: '',
            accLink: '',
            culoare: new Declarations().listaCulori[0],
            disponibilitate: '',

            triggerSave: false,
            isLoading: true
        };
    }

    tipsNTricks = [

        'Doresti sa incasezi plati pentru servicile tale la  crearea unei programari de catre clientii tai? ' +
        '\n\nConecteaza serviciul SmartBill.ro pentru a beneficia de acces la un sistem modern de contabilitate si EuPlatesc.ro pentru procesare de plati prin card Bancar, ' +
        'disponibile in meniul Utilizator, sectiunea Integrari.',

        'Integreaza Google pentru a-ti organiza intalnirile in calendarul Google.',

        'Alte tips and tricks...'

    ];

    WS = new Ws();
    M = new Masks();
    UT = new Utils();
    SF = new ServicesFuncs();
    

    // componentData = {
    //     ...new Declarations().serviceData['svNameAndLink'], 
    //     ...new Declarations().serviceData['description'],
    //     ...new Declarations().serviceData['disponibility'],
    //     ...new Declarations().serviceData['location'],
    //     ...new Declarations().serviceData['duration'],
    //     ...new Declarations().serviceData['breaks'],
    // }
    componentData = new Declarations().destructureDataObject(new Declarations().serviceData);

    componentStatus = {

        // isNameAndLinkOk: true,
        // isDescrOk: true
        isNameAndLinkOk: true,
        isLocationOk: true,
        isDescrOk: true,
        isDurationOk: true,
        isDispoOk: true,
        isBreakOk: true,
        isColorOk: true,
    }

    errorMessages = '';

    componentDidMount(){

        this.getServiceData();
        this.onGetService();
    }

    onGetService = async () => {

        const TOKEN_CONT = new LocalStorage().GetAccountToken();

        let querySelectService = `select * from services where account_tk = '${TOKEN_CONT}' `;
        let service = await this.WS.sqlCommand(`select`, querySelectService);

        if(service.success){

            this.componentData = new Declarations().updateComponentsData(this.componentData, service.content[0]);
            const accLink = await this.UT.getAccountLink();

            const culoareObj = new Declarations().listaCulori.filter( cul => cul.val === service.content[0]['color']);

            
            this.setState({

                serviceTk: service.content[0]['token'],
                detaliiServiciu: service.content[0],
                accLink: accLink,
                culoare: culoareObj[0]
            }, () => {
                
                this.setDisponibility(service.content[0]);
            });
        }
    }

    setDisponibility = (serviceDetails) => {

        let disponibilityStatus = '';

        if( ( +serviceDetails['dispo_nr_zile'] > 0 ) && 
            ( (serviceDetails['dispo_data_end'] || '').length < 2 )
        ){
            
            const oneDay = 24 * 60 * 60 * 1000;
            const dataStartZile = this.M.sqlDateToJSDate(serviceDetails['dispo_data_start']);
            const dataEndZile = this.M.addDaysToDate(this.M.sqlDateToJSDate(serviceDetails['dispo_data_start']), +serviceDetails['dispo_nr_zile']);
            let todayDate = new Date();
            todayDate.setHours(0,0,0,0);

            let zileRamase = Math.round( Math.abs( (dataEndZile - todayDate) / oneDay ) );

            if(serviceDetails['dispo_tip_zile'] === 'work-days'){

                zileRamase = this.SF.calculateWorkingDays(serviceDetails, dataStartZile, todayDate, oneDay);
            }

            

            const daysType = ( serviceDetails['dispo_tip_zile'] || '').length > 0 && ( serviceDetails['dispo_tip_zile'] || '') === 'work-days' ? 'zile lucratoare' : 'zile calendaristice';

            const dataHumanReadable = `${dataStartZile.getDate()}  ${this.M.monthName(dataStartZile.getMonth())}  ${dataStartZile.getFullYear()}`;
            disponibilityStatus = zileRamase + ' ' + daysType + ' de la ' + dataHumanReadable;
        }

        else if( ( +serviceDetails['dispo_nr_zile'] === 0 ) && 
        ( (serviceDetails['dispo_data_end'] || '').length < 2) ){

            disponibilityStatus = 'Perioada nelimitata';
        }

        else  if( ( +serviceDetails['dispo_nr_zile'] === 0 ) && ( (serviceDetails['dispo_data_start'] || '').length > 2 ) &&
        ( (serviceDetails['dispo_data_end'] || '').length > 2 )
        ){

            const dataStart = this.M.sqlDateToJSDate(serviceDetails['dispo_data_start']);
            const dataEnd = this.M.sqlDateToJSDate(serviceDetails['dispo_data_end']);
            disponibilityStatus = `Periodata ${dataStart.getDate()} ${this.M.monthName(dataStart.getMonth()).substr(0,3)} ${dataStart.getFullYear()} - 
                                    ${dataEnd.getDate()} ${this.M.monthName(dataEnd.getMonth()).substr(0,3)} ${dataEnd.getFullYear()}
        `;
        }
        
        this.setState({

            disponibilitate: disponibilityStatus
        },
            () => {

                this.setState({

                    isLoading: false
                });
            }
        );
    }

    getServiceData = async () => {

        const integrations = await this.SF.getIntegrationsData();

        if(integrations.err !== null){


        }
        else{
            this.setState({

                integrations: integrations
            });
        }
    }

    inputStatus = (status, statusName, inputName, data, isVerify = false) => {

        

        if(isVerify){

            this.componentStatus[statusName] = data;
        }
        else{

            this.componentData[inputName] = data;    
    
            this.setState({
                
                [statusName]: status
            },
                () => {
    
                    // this.checkSaveButtonStatus();
                }
            );
        }
    }

    onAddErrorMessage = (errorTx) => {

        this.errorMessages = errorTx;
    }

    showToastError = (message) => {

        this.setState({

            showToastPopupError: true,
            actionTx: message
        });
    }

    onClosePopup = (type) => {

        this.setState({

            [type]: false
        })
    }

    onChangeBulletColor = (index) => {


    }

    onCheckAndSave = async () => {

        const result = Object.values(this.componentStatus).every(item => item === true)
        
        if(result){

            const queryObject = new Declarations().generateQuerySet('service', this.componentData);
            const queryArr = new Declarations().onGenerateQueryArray(queryObject);
            
            let uq = this.SF.onSaveServiceData(queryArr, false, this.state.serviceTk);
             
            

            let updateServiciu = await this.WS.sqlCommand(`update`, uq.data.substring(1, uq.data.length - 2));

            if(updateServiciu.success){

                // this.props.history.push('/');
                window.location.href = '/'
            }
            else{

                this.setState({
                    showToastPopupError: true,
                    actionTx: 'Eroare creare serviciu.'
                });
                throw new Error('Eroare Server Response:  ' + updateServiciu.message)
            }
        }
        else{

            

            let showError = false;
            let errorTx = '';

            if(this.errorMessages.length > 0){
                
                showError = true;

                // for(let i = 0; i < this.errorMessages.length; i++){

                //     errorTx += this.errorMessages[i] + '\n';
                // }
            }

            this.setState({

                showToastPopupError: showError,
                actionTx: this.errorMessages,
                triggerSave: false
            });
        }
    }

    onTriggerSave = () => {

        this.setState({

            triggerSave: true
        },
            async () => {

                await this.onCheckAndSave();
            }
        );
    }

    render(){

        if(this.state.isLoading){

            return(

                <Loader 
                    fullPage = {true}
                />
            );
        }

        return(
            <>

            <BrowserView>

                <div 
                    className='wrapper-dashboard' 

                    style={{

                        margin:'0 auto',
                        minHeight: '2400px',
                        background: `url(${CanaryPlantImage}) bottom no-repeat`,
                        backgroundSize: '380px'
                    }}
                >

                    {
                        this.state.showToastPopupError && (

                            <ErrorToast 
                                action={this.state.actionTx}
                                onClosePopup = {this.onClosePopup}
                            />
                        )
                    }

                    <RegisterHeader isServiceStep = {true} />

                    <div className='service-details-card' style={{marginTop: '20px', textAlign: 'left'}}>

                        <div className="row">

                            <div className='col-l-8 col-m-8 col-s-8'>

                                <IconText 
                                    icon = {
                                        <MdSchool 
                                            style={{
                                                fontSize: '22px',
                                                color: '#FFC727'
                                            }}
                                        />
                                    }
                                    tx = 'Aproape Gata!'
                                    txSize = '16px'
                                    txWeight = '500'
                                    txCol = '#455A64'

                                /> 
                                

                                <p className='register-tx' style={{marginTop: '16px'}}>

                                    Ai tot ce iti trebuie pentru a crea primul tau serviciu! 
                                    Revizuieste datele serviciului tau mai jos si ajusteaza daca 
                                    este nevoie si apasa butonul Finalizeaza.

                                </p>

                                <div className='register-center-tx-wrapper' style={{margin: '0'}}>

                                    <img
                                        className='register-image'
                                        src={Image}
                                        alt="Planary"
                                    />

                                </div>

                                <ButtonForm 
                                    type='button'
                                    tx={
                                        <>
                                            <p style={{display: 'inline-block', position: 'relative', top: '-2px', margin: 0}}>
                                                Finalizeaza &nbsp;
                                            </p> 
                                            <IoIosArrowForward className='register-next-arrow'/>
                                        </>
                                    }
                                    
                                    style={{
                                        marginTop: '20px',
                                        width: 'auto',
                                        height: '56px',
                                        background: '#F8BC05',
                                        fontSize: '16px',
                                        padding: '0px 35px 0px 35px',
                                    }}
                                    onClick={this.onTriggerSave}
                                />

                            </div>
                            
                            <div className='col-l-8 col-m-8 col-s-8' style={{marginTop: '20px'}}>

                                <IconText 
                                    icon = {
                                        <RiFolderReduceFill 
                                            style={{
                                                fontSize: '22px',
                                                color: '#FFC727'
                                            }}
                                        />
                                    }
                                    tx = 'Denumire Serviciu si Link'
                                    txSize = '16px'
                                    txWeight = '500'
                                    txCol = '#455A64'
                                    containerTop = '26px'
                                /> 

                                <SvNameAndLink 
                                    name = {this.state.detaliiServiciu['name']}
                                    link = {this.state.detaliiServiciu['web_link']}
                                    token = {this.state.serviceTk}
                                    accountLink = {'foxstat'}
                                    fontSize = '14px'
                                    fontSizeCommands = '16px'
                                    inputStatus = {this.inputStatus}
                                    showToastError = {this.showToastError}
                                    // onCopyLinkToClipBoard = {this.onCopyLinkToClipBoard}
                                    // onShowToastSocial = {this.onShowToastSocial}
                                    triggerSave = {this.state.triggerSave}
                                    
                                    style={{
                                        marginTop: '16px'
                                    }}
                                />

                                <IconText 
                                    icon = {
                                        <NotesIcon />
                                
                                    }
                                    tx = 'Descrierea Serviciului'
                                    txSize = '16px'
                                    txWeight = '500'
                                    txCol = '#455A64'
                                    // containerTop = '26px'
                                /> 

                                <SvDescription 
                                    descriere = {this.state.detaliiServiciu['description']}
                                    triggerSave = {this.state.triggerSave}
                                    // showToastError = {this.showToastError}
                                    inputStatus = {this.inputStatus}

                                    style={{
                                        marginTop: '16px'
                                    }}
                                />
                                
                                <IconText 
                                    icon = {
                                        <PinDrop />
                                
                                    }
                                    tx = 'Unde se va desfasura programarea?'
                                    txSize = '16px'
                                    txWeight = '500'
                                    txCol = '#455A64'
                                    containerTop = '26px'
                                /> 

                                {
                                    this.state.integrations['data'] && 
                                    (

                                        <div style={{marginTop: '16px', textAlign: 'center'}}>

                                            <SvLocation 
                                                locatieCurenta = {this.state.detaliiServiciu['location']}
                                                integrations = {this.state.integrations}
                                                triggerSave = {this.state.triggerSave}
                                                inputStatus = {this.inputStatus}
                                            />

                                        </div>
                                    )
                                }

                                <IconText 
                                    icon = {
                                        <MdAlarm
                                            style={{
                                                fontSize: '22px',
                                                color: '#FFC727'
                                            }}
                                        />
                                    }
                                    tx = 'Cat va dura serviciul?'
                                    txSize = '16px'
                                    txWeight = '500'
                                    txCol = '#455A64'
                                    containerTop = '26px'
                                /> 

                                <SvDuration 
                                                
                                    duration = {this.state.detaliiServiciu['duration']}
                                    triggerSave = {this.state.triggerSave}
                                    inputStatus = {this.inputStatus}
                                    style={{
                                        marginTop: '16px'
                                    }}
                                />

                                <IconText 
                                    icon = {
                                        <FaCalendarCheck 
                                            style={{
                                                fontSize: '22px',
                                                color: '#FFC727'
                                            }}
                                        />
                                    }
                                    tx = 'Disponibilitate'
                                    txSize = '16px'
                                    txWeight = '500'
                                    txCol = '#455A64'
                                    containerTop = '26px'
                                /> 

                                <SvDisponibility 
                                    serviceDetails = {this.state.detaliiServiciu}
                                    triggerSave = {this.state.triggerSave}
                                    inputStatus = {this.inputStatus}
                                    showToastError = {this.showToastError}
                                    // mobileSelector = {true}
                                    onAddErrorMessage = {this.onAddErrorMessage}

                                    style={{
                                        marginTop: '16px'
                                    }}
                                />

                                <IconText 
                                    icon = {
                                        <RiCupFill 
                                            style={{
                                                fontSize: '22px',
                                                color: '#FFC727'
                                            }}
                                        />
                                    }
                                    tx = 'Acorda-ti pauze'
                                    txSize = '16px'
                                    txWeight = '500'
                                    txCol = '#455A64'
                                    containerTop = '26px'
                                /> 

                                <SvBreaks 
                                    triggerSave = {this.state.triggerSave}
                                    pauzaInainteSesiune = {this.state.detaliiServiciu['break_before']}
                                    pauzaDupaSesiune = {this.state.detaliiServiciu['break_after']}
                                    inputStatus = {this.inputStatus}
                                />

                                <IconText 
                                    icon = {
                                        <MdPalette 
                                            style={{
                                                fontSize: '22px',
                                                color: '#FFC727'
                                            }}
                                        />
                                    }
                                    tx = 'Culoare in calendar'
                                    txSize = '16px'
                                    txWeight = '500'
                                    txCol = '#455A64'
                                    containerTop = '26px'
                                /> 

                                <br />

                                <SvColor 
                                    culoare = {this.state.detaliiServiciu['color']}
                                    triggerSave = {this.state.triggerSave}
                                    inputStatus = {this.inputStatus}
                                />


                            </div>

                            <div className='col-l-8 col-m-8 col-s-8'>
                                
                            </div>

                        </div>

                    </div>

                </div>

            </BrowserView>


            <MobileView>

                <div 
                    style={{
                        padding: '20px',
                        minHeight: '1700px',
                        background: `url(${MBirdAndPlantImage}) bottom no-repeat`
                    }}
                >
                    
                    <RegisterHeader isServiceStep = {true} />
                    
                    <div className="m-edit-s-card" style={{marginTop: '20px', textAlign: 'left'}}>

                        <IconText 
                            icon = {
                                <MdSchool 
                                    style={{
                                        fontSize: '22px',
                                        color: '#FFC727'
                                    }}
                                />
                            }
                            tx = 'Aproape Gata!'
                            txSize = '16px'
                            txWeight = '500'
                            txCol = '#455A64'

                        /> 

                        <p className='register-tx sm' style={{marginTop: '16px'}}>

                            Ai tot ce iti trebuie pentru a crea primul tau serviciu! 
                            Revizuieste datele serviciului tau mai jos si ajusteaza daca 
                            este nevoie si apasa butonul Finalizeaza.

                        </p>

                        <img
                            className='register-image'
                            src={Image}
                            alt="Planary"
                        />

                        <IconText 
                            icon = {
                                <MdSettingsInputComponent 
                                    style={{
                                        color: 'rgb(255, 199, 39)',
                                        fontSize: '20px'
                                    }}
                                />
                            }
                            tx = 'Integreaza Planary'
                            txSize = '16px'
                            txWeight = '500'
                            txCol = '#455A64'
                        />
                        
                        <Swiper
                            // modules={[Pagination]}
                            spaceBetween={30}
                            slidesPerView={1}
                            pagination={{

                                clickable: true,
                                // renderBullet: function (index, className) {
                                //     return '<span class="' + className + '">' + "</span>";
                                // },
                            }} 
                            // onSlideChange={this.onChangeBulletColor}
                            // onSwiper={(swiper) => 
                        >
                            
                            {
                                this.tipsNTricks.map( (tEl, idx) => {

                                    return(
                                        <div key={`slider-${idx}`}>

                                            <SwiperSlide >

                                                <p className='register-tx sm' 
                                                    style={{
                                                        marginTop: '16px',
                                                        marginBottom: '32px', 
                                                        whiteSpace: 'pre-wrap'
                                                    }}
                                                >
                                                    {tEl}
                                                </p>

                                            </SwiperSlide>

                                            
                                        </div>
                                    );
                                })
                            }

                        </Swiper>

                        <IconText 
                            icon = {
                                <MdTouchApp 
                                    style={{
                                        color: '#C7C7C7',
                                        fontSize: '22px'
                                    }}
                                />
                            }
                            tx = 'Swipe pentru mai multe Tips & Tricks'
                            txSize = '14px'
                            txWeight = '500'
                            txCol = '#C7C7C7'
                            // containerTop = '22px'
                            side = 'center'
                        />

                        {/* <p className='register-tx sm' style={{marginTop: '16px'}}>

                            Doresti sa incasezi plati pentru servicile tale la  crearea unei 
                            programari de catre clientii tai? 
                            <br />
                            <br />
                            Conecteaza serviciul SmartBill.ro pentru a beneficia de acces la un sistem 
                            modern de contabilitate si EuPlatesc.ro pentru procesare de plati prin card Bancar, 
                            disponibile in meniul Utilizator, sectiunea Integrari.

                        </p> */}


                    </div>

                    <div className="m-edit-s-card" style={{marginTop: '20px', textAlign: 'left'}}>

                        <IconText 
                            icon = {
                                <MdLocalActivity 
                                    style={{
                                        color: 'rgb(255, 199, 39)',
                                        fontSize: '20px'
                                    }}
                                />
                            }
                            tx = 'Informatii de Baza'
                            txSize = '16px'
                            txWeight = '500'
                            txCol = '#455A64'
                        />

                        <ServiceAccessRow 
                            link = {'/inregistrare/serviciu/denumire-serviciu/'+ this.state.serviceTk}
                            isNotDashboard = {true}
                            titleTx = {this.state.detaliiServiciu['name']}
                            subTx = {`http://planary.ro/${this.state.accLink}/${this.state.detaliiServiciu['web_link']}`}
                            distance = '0px'
                            subTxCol = '#A6A6A6'
                        />

                        <ServiceAccessRow 
                            link = {'/inregistrare/serviciu/locatie-serviciu/' + this.state.serviceTk}
                            isNotDashboard = {true}
                            titleTx = 'Locatie'
                            isLocation = {true}
                            location = {this.state.detaliiServiciu['location']}
                            locationDesc = {
                                new Declarations().listaLocatiiIntalnire.filter(loc => loc.id === this.state.detaliiServiciu['location'])[0]['descClient']
                            }
                            containerStyle = {{

                                borderTop: 'none',
                                padding: '0px 0px 15px 0px'
                            }}
                        />

                        <ServiceAccessRow 
                            link = {'/inregistrare/serviciu/durata-serviciu/' + this.state.serviceTk}
                            isNotDashboard = {true}
                            titleTx = 'Durata Serviciu'
                            subTxIcon = {

                                <MdAlarm 
                                    style={{
                                        
                                        left: 0, 
                                        margin: 0,
                                        fontSize: '16px',
                                        color: '#A6A6A6'
                                    }}
                                />
                            }
                            subTx = {this.state.detaliiServiciu['duration'] + ' Minute'}
                            subTxSize = '12px'
                            subTxWeight = '500'
                            containerStyle = {{

                                borderTop: 'none',
                                padding: '0px 0px 15px 0px'
                            }}
                        />

                        <ServiceAccessRow 
                            link = {'/inregistrare/serviciu/descriere-serviciu/' + this.state.serviceTk}
                            isNotDashboard = {true}
                            titleTx = 'Descriere'
                            subTx = {this.state.detaliiServiciu['description'] || 'Nu exista descriere'}
                            distance = '0px'
                            containerStyle = {{

                                borderTop: 'none',
                                padding: '0px 0px 15px 0px'
                            }}
                            isLimit = {true}
                        />

                        <ServiceAccessRow 
                            link = {'/inregistrare/serviciu/culoare-serviciu/' + this.state.serviceTk}
                            isNotDashboard = {true}
                            subTxIcon = {

                                <span 
                                    className="m-color"
                                    style={{
                                        // background: this.state.culoare?.col
                                        background: this.state.culoare['col']
                                    }}
                                >
                                    <GoCheck 
                                        style={{

                                            position: 'relative',
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            left: '6px',
                                            color: '#fff'
                                        }}
                                    />
                                    
                                </span>
                            }
                            distance = '36px'
                            subTx = 'Culoare in Calendar'
                            subTxSize = '14px'
                            subTxWeight = '500'
                            subTxCol = '#000000'
                            containerStyle = {{

                                border: 'none',
                                padding: '0px 0px 15px 0px'
                            }}
                        />

                        <ServiceAccessRow 
                            link = {'/inregistrare/serviciu/disponibilitate-serviciu/' + this.state.serviceTk}
                            isNotDashboard = {true}
                            titleTx = 'Disponibilitate'
                            subTxIcon = {

                                <FaCalendarCheck 
                                    style={{
                                        // top: '1px',
                                        left: 0, 
                                        margin: 0,
                                        fontSize: '16px',
                                        color: '#A6A6A6'
                                    }}
                                />
                            }
                            subTxTopTitle = '1px'
                            subTx = {this.state.disponibilitate}
                            subTxSize = '12px'
                            subTxWeight = '500'
                            subTxCol = '#455A64'
                        />

                        {/* <ServiceAccessRow 
                            link = {'program-serviciu/'+this.props.match.params.tk}
                            titleTx = 'Program'
                            subTxIcon = {

                                <FaBook 
                                    style={{
                                        top: '1px',
                                        left: 0, 
                                        margin: 0,
                                        fontSize: '16px',
                                        color: '#A6A6A6'
                                    }}
                                />
                            }
                            subTx = {'Program 1'}
                            subTxSize = '12px'
                            subTxWeight = '500'
                            subTxCol = '#455A64'
                            containerStyle = {{

                                borderTop: 'none',
                                padding: '0px 0px 15px 0px'
                            }}
                        /> */}

                        <ServiceAccessRow 
                            link = {'/inregistrare/serviciu/pauze-serviciu/' + this.state.serviceTk}
                            isNotDashboard = {true}
                            titleTx = 'Pauze'
                            subTxIcon = {

                                <MdAlarm 
                                    style={{
                                        
                                        left: 0, 
                                        margin: 0,
                                        fontSize: '16px',
                                        color: '#A6A6A6'
                                    }}
                                />
                            }
                            subTx = {this.state.detaliiServiciu['break_before'] + ' minute inainte'}
                            subTxSize = '12px'
                            subTxWeight = '500'
                            subTxCol = '#455A64'
                            containerStyle = {{

                                border: 'none',
                                padding: '0px 0px 15px 0px'
                            }}

                            additionalRow = {

                                <IconText 
                                    containerTop = '8px'
                                    top = '5px'
                                    distance = '22px'
                                    icon = {

                                        <MdAlarm 
                                            style={{
                                                
                                                left: 0, 
                                                margin: 0,
                                                fontSize: '16px',
                                                color: '#A6A6A6'
                                            }}
                                        />
                                    }
                                    tx = {this.state.detaliiServiciu['break_after'] + ' minute dupa'}
                                    txSize = '12px'
                                    txWeight = '500'
                                    txCol = '#455A64'
                                />
                            }
                        />

                        <ButtonsNavRegister 
                            tx = 'Finalizeaza'
                            onPressBtn = {this.onTriggerSave}
                            onBack={this.props.history.goBack}
                        />

                    </div>
                    
                </div>


            </MobileView>
            </>
        );
    }
}