import React from 'react';

import Ws from '../../providers/Ws';
import Utils from '../../providers/Utils';
import Declarations from '../../providers/Declarations';

import ClientServiceInfo from './ClientServiceInfo';

import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';

import { Link } from 'react-router-dom';
import AuthInput from '../../commons/AuthInput';
import ButtonForm from '../../commons/ButtonForm';
import { BrowserView, MobileView } from 'react-device-detect';
import MClientServiceInfo from './MClientServiceInfo';


import CompanyAvatar from '../../assets/foxStat.jpeg';
import CanaryPlantImage from '../../assets/canary_plant_bg.png';
import ErrorImg from '../../assets/Feeling sorry-cuate 1.png';
import BirdBesidesPlantImage from '../../assets/bird_and_plant_mobile.png';
import Masks from '../../providers/Masks';
import ErrorMessage from '../../commons/ErrorMessage';
import ClientValidationFuncs from '../logic/ClientValidationFuncs';
import AvatarAccount from '../../commons/AvatarAccount';
import Loader from '../../commons/Loader';
import ServicesFuncs from '../logic/ServicesFuncs';
import ClientDataForm from '../../commons/client_appointments/ClientDataForm';

export default class ClientDataReg extends React.Component{

    constructor(props){

        super(props)

        this.state = {

            transactionDetails: {},
            locatieCurenta: {},
            isSmartBill: 0,

            accountName: '',
            accountImgSrc: '',
            member: [],

            eroareTx: '',
            termsLink: 'https://planary.ro/termeni-si-conditii/',
            isInvite: false,
            acceptTerms: false,
            termsError: false,
            isLoading: true,
            triggerSave: false
        }
    }

    WS = new Ws();
    UT = new Utils();
    M = new Masks();
    CVF = new ClientValidationFuncs();
    SF = new ServicesFuncs();

    componentsData = new Declarations().destructureDataObject(new Declarations().clientData);

    componentsStatus = {
        
        isClientDataOk: false
    };

    componentDidMount(){

        this.getServiceData();
        window.scrollTo(0,0);
    }

    getServiceData = async () => {

        // let selectService = `select * from services where account_tk = '${this.props.tokenCont}' and token = '${this.props.tokenService}' `;
        let selectService = `select * from trc where token= '${this.props.match.params.tranToken}'    `;
        let selectSmartBillIntegration = `select count(*) as rez from accounts_integrations where account_token = 
                                                (select account_tk from trc where token = '${this.props.match.params.tranToken}') 
                                                and ifnull(activ,0)=1 `;

        let _service = this.WS.sqlCommand(`select`, selectService);
        let _smartBillIntegration = this.WS.sqlCommand(`select`, selectSmartBillIntegration);

        const qUsers = `select * from users where account_id =  (select account_tk from trc where token = '${this.props.match.params.tranToken}') `;
        let _users = this.WS.sqlCommand(`select`, qUsers);

        const qAccount = `select * from accounts where token = (select account_tk from trc where token = '${this.props.match.params.tranToken}') `;
        let _account = this.WS.sqlCommand(`select`, qAccount);

        let [service, smartBillIntegration, users, account] = 
                        await Promise.all([_service, _smartBillIntegration, _users, _account]);

        if(service.success && smartBillIntegration){

            if(service.content.length === 0){

                this.setState({
                    eroareTx: 'Link-ul de completare a datelor este gresit.'
                })
            }

            const errorTxOverlap = await this.CVF.checkIfAppointIsOverlaping(service.content[0]['data_cal'], service.content[0]['ora_cal'], 
                                        this.props.match.params.tranToken, false,  service.content[0]['account_tk']);
            const errorTxCommon = this.CVF.clientPageCommonExceptionMessage(service.content[0]);

            if(errorTxOverlap.length > 0){

                this.setState({

                    eroareTx: errorTxOverlap,
                    isLoading: false
                });
            }

            if(errorTxCommon.length > 0){

                this.setState({

                    eroareTx: errorTxCommon,
                    isLoading: false
                });

            }

            if(service.content.length > 0){

                let isInviteType = false;

                if(( service.content[0].src || '' ) === 'ca' && (service.content[0]['replaned_trc_tk'] || '').length < 10){

                    isInviteType = true;
                }

                const smartBillRez = smartBillIntegration.content[0]['rez'];
                
                const accountData =  await this.UT.getAccountAvAndName(false, service.content[0]['account_tk']);

                // const termsLink = await this.getTermsAndConditionsLink(service.content[0]);

                this.componentsData = new Declarations().updateComponentsData(this.componentsData, service.content[0], 'client');

                if(smartBillRez){

                    if((service.content[0]['clie_nume'] || '').length > 0 && (service.content[0]['clie_prenume'] || '').length &&
                        (service.content[0]['clie_tf'] || '').length > 0 && (service.content[0]['clie_cui'] || '').length > 0 &&
                        (service.content[0]['clie_adresa'] || '').length > 0 && (service.content[0]['clie_oras'] || '').length > 0
                    ){

                        this.componentsStatus.isClientDataOk = true;
                    }
                }
                else{

                    if((service.content[0]['clie_nume'] || '').length > 0 && (service.content[0]['clie_prenume'] || '').length &&
                        (service.content[0]['clie_tf'] || '').length > 0
                    ){

                        this.componentsStatus.isClientDataOk = true;
                    }
                }

                const user = users.content.filter( user => user.token === service.content[0].user_asignat_token);

                this.setState({

                    transactionDetails: service.content[0],
                    isInvite: isInviteType,
                    accountName: this.props.match.params.memberLink && account.content[0]['abonament'] === 'premium' ? user[0]['nume'] : accountData['name'],
                    accountImgSrc: this.WS.serverAddress + 'staticprovider/getimg?filepath=Storage/Avatars/' + accountData['avatar'],
                    // termsLink: termsLink,
                    isSmartBill: smartBillRez,
                    isLoading: false,
                    member: this.props.match.params.memberLink && account.content[0]['abonament'] === 'premium' ? [] : user
                },
                    () => {

                        this.setLocatieCurenta(service.content[0].location);
                    }
                )
            }

            // 
            // 

            const uQ = ` update trc set screen = 'datele-mele' where token = '${this.props.match.params.tranToken}'   `
            const updateScreen = await this.WS.sqlCommand(`update`, uQ);

            if(!updateScreen.success){

                // 
            }

        }
        else{

            throw new Error("Server response error: " + service.message)
        }
    }

    getTermsAndConditionsLink = async (transactionDetails) => {

        // 

        let selectAccountWebLink = ` select * from accounts where token = '${transactionDetails['account_tk']}' `;
        let selectServiceWebLink = `select web_link from services where token = '${transactionDetails['service_tk']}' `;

        let _serviceWebLink = await this.WS.sqlCommand(`select`, selectServiceWebLink);
        let _accountWebLink = await this.WS.sqlCommand(`select`, selectAccountWebLink);

        let [serviceWebLink, accountWebLink] = await Promise.all( [_serviceWebLink, _accountWebLink] ); 

        if(serviceWebLink.success && accountWebLink.success){

            // 

            const accLink = accountWebLink.content[0]['web_link'];
            const svLink = serviceWebLink.content[0]['web_link'];

            const link = `/#/${accLink}/${svLink}/termeni-si-conditii`;

            return link;
        }
        else{

            throw new Error('Error selecting web links from service and account ', serviceWebLink.message, accountWebLink.message);
        }
    }

    setLocatieCurenta = (location) => {

        let locatieCurenta = new Declarations().listaLocatiiIntalnire.filter(lEl => lEl.id === location)

        this.setState({

            locatieCurenta: locatieCurenta[0]
        })
    }

    inputStatus = (status, statusName, inputName, data, isVerify = false) => {

        if(isVerify){

            this.componentsStatus[statusName] = data;
        }
        else{

            this.componentsData[inputName] = data;
        }
    }

    onConfirmClientData = async (result) => {


        let updateTrcQuery = this.SF.onSaveServiceData(result, false, this.props.match.params.tranToken, 'trc');
             
        // 

        let updateTransactions = await this.WS.sqlCommand(`update`, updateTrcQuery.data.substr(1, updateTrcQuery.data.length - 2));

        if(updateTransactions.success){

            // alert("Date tranzactie adaugate cu succes!")
            if(this.props.match.params.memberLink){

                this.props.history.push(`/trc/${this.props.match.params.memberLink}/${this.props.match.params.tranToken}/finalizare`);
            }
            else{

                this.props.history.push("/trc/" + this.props.match.params.tranToken 
                                    + "/finalizare"
                )
            }
        }
        else{

            throw new Error("Server error response: ", updateTransactions.message)
        } 
    }

    onCheckDataAndSave = async () => {

        let termsError = false;
        let isTerms = false;


        if(this.state.acceptTerms){

            termsError = false;

            isTerms = true;
        }
        else{

            termsError = true;
            isTerms = false;
        }

       const result = Object.values(this.componentsStatus).every(item => item === true);

        if(result && isTerms){

            const queryObject = new Declarations().generateQuerySet('client', this.componentsData);
            const queryArr = new Declarations().onGenerateQueryArray(queryObject);

            // 

            await this.onConfirmClientData(queryArr);
        }
        else{

            this.setState({

                termsError: termsError,
                triggerSave: false
            });
        }
    }

    // onPressBack = async () => {

    //     const selectAccountLinkQuery = `select web_link from accounts where token = '${this.state.transactionDetails.account_tk}' 
    //                                    `                             
                                                                        
                                                                    

    //     const selectServiceLinkQuery = `select web_link from services where account_tk = '${this.state.transactionDetails.account_tk}' 
    //         and token = '${this.state.transactionDetails.service_tk}'
            
    //     `

    //    const selectAccountLink = await this.WS.sqlCommand(`select`, selectAccountLinkQuery)
    //    const selectServiceLink = await this.WS.sqlCommand(`select`, selectServiceLinkQuery)

    //     if(selectAccountLink.success && selectServiceLink.success){

    //         this.props.history.push("/" + selectAccountLink.content[0].web_link + "/" +
    //                                         selectServiceLink.content[0].web_link + "/calendar"                       
    //         )
    //     }
    //     else{
    //         throw new Error("Server response error: " + selectAccountLink.message + "\n" + selectServiceLink.message)
    //     }
    // }

    onInviteAccept = async () => {

        // let ss = await this.WS.getData('/invite/confirm', {trcToken: this.props.match.params.tranToken});

        // 

        // if(ss.success){

        //     alert("Succes confirmare!")
        //     this.props.history.push('/trc/'+this.props.match.params.tranToken+'/cfp');
        // }
        // else{

        //     alert("Confirmare esuata!") //schimba cu state cand face Sergiu design
        // }

        // let _adr = `${this.WS.serverAddress}invite/confirm?apikey=${this.WS.appKey}&trcToken=${this.props.match.params.tranToken}`;
        // let addr = encodeURI(_adr);

        // let r = await fetch(addr)

        // if (r.status === 200) {
        //     let ss = await r.json(); // pentru tratarea de exceptii cu mesaje punctuale in fiecare pagina
        //     

        //     if(ss.success){

        //         alert("Succes confirmare!")
        //         // this.props.history.push('/trc/'+this.props.match.params.tranToken+'/cfp');
        //         this.props.history.push('/trc/'+this.props.match.params.tranToken+'/finalizare'); 
        //     }
        //     else{
        //         this.setState({

        //             eroareTx: 'Confirmarea programarii a esuat!'
        //         })
        //         throw new Error("Error creating meeting: " + ss.message);
        //     }
        // }
        // else {
        //     console.error("GET SERVER STATUS ERR", r)
        // }

        if(this.state.acceptTerms){

            this.props.history.push('/trc/' + this.props.match.params.tranToken + '/finalizare');
        }
        else{

            this.setState({

                termsError: true
            });

            return;
        }    
    }

    onAcceptTerms = () => {

        this.setState({

            acceptTerms: !this.state.acceptTerms
        })
    }

    onTriggerSave = () => {

        this.setState({

            triggerSave: true
        },
            async () => {

                await this.onCheckDataAndSave();
            }
        
        );
    }

    render(){

        return(
            <React.Fragment>

            <BrowserView>

            {
                this.state.isLoading && (

                    <Loader 
                        fullPage = {true}
                    />
                )
            }

            {
                !this.state.isLoading && (

                    <>
                    
                         <div className="client-appoint-container">
                
                            <div className="client-appoint-wrapper data-reg">

                                <div 
                                    className="client-schedule-card"
                                    style={{
                                        padding: '40px 40px 40px 40px'
                                    }}
                                >

                                    {/* <Link to={'/' + this.props.match.params.company_link
                                            + '/' + this.props.match.params.service_link +
                                            '/calendar'}> */}
                                    <h3  
                                        className="appoint-back"            
                                        style={{

                                            textAlign: 'left',
                                            margin: 0,
                                        }}
                                        // onClick={this.onPressBack}
                                        onClick={this.props.history.goBack}
                                    >
                                        
                                        <p 
                                            style={{
                                                    display: 'inline-block', 
                                                    position: 'relative', 
                                                    top: 4, 
                                                    left: 0, 
                                                    margin: 0,
                                                    fontSize: '40px'
                                                }}
                                        >
                                            <RiArrowLeftSLine /> 

                                        </p>

                                        <p 
                                            style={{
                                                display: 'inline-block', 
                                                position: 'relative',
                                                top: -10, 
                                                left: 0,
                                                margin: 0,
                                                fontWeight: '500',
                                                fontSize: '16px'
                                            }}
                                        >
                                            Inapoi
                                        </p>
                                        
                                    </h3>
                                    {/* </Link> */}

                                    {
                                        // Object.keys(this.state.transactionDetails).length > 0 &&
                                        this.state.eroareTx.length === 0 &&

                                        (
                                            <div className="row client-data-reg-row">

                                                <div 
                                                    className="col-l-6 col-m-6 col-s-12"
                                                    style={{
                                                        paddingRight: 20,
                                                        // borderRight: '1px solid #EBEBEB'
                                                        maxHeight: '630px',
                                                        overflowY: 'auto'
                                                    }}
                                                >

                                                {/* {
                                                    this.state.transactionDetails['account_tk'] && ( */}

                                                        <ClientServiceInfo 
                                                            marginTopAvatar = '35px'
                                                            accToken = {this.state.transactionDetails['account_tk']}
                                                            isNotLogged = {false}
                                                            accountName = {this.state.accountName}
                                                            name={this.state.transactionDetails.service_name}
                                                            duration={this.state.transactionDetails.duration}
                                                            locatieCurenta={this.state.locatieCurenta}
                                                            price={this.state.transactionDetails.price}
                                                            description={this.state.transactionDetails.service_description}
                                                            date={this.state.transactionDetails['data_cal']}
                                                            hour={this.state.transactionDetails['ora_cal']}
                                                            editDate = {true}
                                                            tranToken = {this.props.match.params.tranToken}
                                                            member = {this.state.member}
                                                        />
                                                    {/* )
                                                } */}

                                                </div>

                                                <div 
                                                    className="col-l-6 col-m-6 col-s-12"
                                                    style={{
                                                        paddingLeft: 20,
                                                        borderLeft: '1px solid #EBEBEB'
                                                    }}
                                                >
                                                    {/* <br /> <br /> <br /> <br /> <br /> <br /> <br /> */}
                                                    
                                                    <h3 
                                                        className="client-data-datele-mele-title"
                                                        style={{
                                                            marginTop: 163,
                                                            color: '#455A64',
                                                            fontSize: '16px',
                                                            textAlign: 'left'
                                                        }}
                                                    >
                                                        Datele Tale
                                                    </h3>

                                                    <ClientDataForm 
                                                        tranData = {this.state.transactionDetails}
                                                        isSmartBill = {this.state.isSmartBill}
                                                        triggerSave = {this.state.triggerSave}
                                                        inputStatus = {this.inputStatus}
                                                    />

                                                    <div 
                                                        className="terms-wrapper" 
                                                        style={{
                                                            paddingTop: '16px',
                                                            paddingBottom: '12px'
                                                        }}
                                                    >

                                                        <div 
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                width: '8.33%'      
                                                            }}
                                                        >

                                                            <input 
                                                                className={this.state.termsError ? "terms-checkbox-error" : "terms-checkbox" }
                                                                type="checkbox"
                                                                onClick={this.onAcceptTerms}
                                                            />
                                                            <span className="terms-checkmark"></span>

                                                        </div>

                                                        <div 
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                width: '91.66%'
                                                            }}
                                                        >

                                                            <a 
                                                                href={this.state.termsLink}
                                                                className={this.state.termsError ? "terms-text terms-error" : "terms-text"}
                                                                target="_blank"
                                                            >
                                                                Accept <u>Termenii si Conditiile</u>.
                                                            </a>

                                                        </div>
                                                        
                                                    </div>

                                                    

                                                    <div 
                                                        className="form-control"
                                                        style={{
                                                            marginTop: 10,
                                                            textAlign: 'left'
                                                        }}
                                                    >
                                                        
                                                     

                                                        <ButtonForm 
                                                            className="client-schedule-btn"
                                                            tx={this.state.isInvite ? 'Confirma' : 'Programeaza'}
                                                            onClick = {this.onTriggerSave}
                                                            
                                                        />
                                                        
                                                        
                                                        
                                                        
                                                    </div>
                                                    
                                                </div>
                            
                                            </div> 
                                        )

                                    }

                                    {
                                        this.state.eroareTx.length > 0 && (

                                            <ErrorMessage 
                                                eroareTx = {this.state.eroareTx}
                                            />
                                        )
                                    }                         

                                </div>

                            </div>

                        </div>

                        <div
                            style={{
                                    width: '100%',
                                    marginTop: 20
                                }}
                        >

                            <img 
                                src={CanaryPlantImage}
                                alt="Canary and plant..."
                                style={{
                                    display: 'block',
                                    margin: 'auto',
                                    width: '400px'
                                }}
                            />

                        </div>

                        <p
                            style={{
                                
                                paddingTop: '5px',
                                paddingBottom: '10px',
                                fontSize: 14,
                                color: '#455A64'
                            }}
                        >
                            Programari prin  <a 
                                                href="https://planary.ro"
                                                style={{
                                                    color: '#455A64'
                                                }}
                                            >
                                                Planary.ro
                                            </a>
                                
                        </p>
                    
                    </>
                )
            }
            
           
            
            </BrowserView>

            <MobileView>

                {
                    this.state.isLoading && (

                        <Loader 
                            fullPage = {true}
                        />
                    )
                }

                {
                    !this.state.isLoading && (

                        <>
                        
                        <h3  
                            className="m-appoint-back"            
                            style={{

                                textAlign: 'left',
                                margin: 0,
                            }}
                            // onClick={this.onPressBack}
                            onClick={this.props.history.goBack}
                        > 
                            <p 
                                style={{
                                        display: 'inline-block', 
                                        position: 'relative', 
                                        top: 4, 
                                        left: 0, 
                                        margin: 0,
                                        fontSize: '40px'
                                    }}
                            >
                                <RiArrowLeftSLine /> 

                            </p>

                            <p 
                                style={{
                                    display: 'inline-block', 
                                    position: 'relative',
                                    top: -10, 
                                    left: 0,
                                    margin: 0,
                                    fontWeight: '500',
                                    fontSize: '16px'
                                }}
                            >
                            Inapoi
                            </p>
                            
                        </h3>

                        {
                            this.state.eroareTx.length === 0 && (

                                <div
                                    className="m-client-header"
                                    style={{

                                        marginTop: -20
                                    }}
                                >

                                    {/* <div className="company-avatar-outer mobile">

                                        <div className="company-avatar-inner">
                                            <img src={this.state.accountImgSrc} alt="Avatar companie" />
                                        </div>

                                    </div> */}

                                    {
                                        this.state.transactionDetails['account_tk'] && (

                                            <AvatarAccount 
                                                isNotLogged = {false}
                                                accToken = {this.state.transactionDetails['account_tk']}
                                                border = '4px solid #fff'
                                                width = '102px'
                                                height = '102px'
                                                top = '20px'
                                            />
                                        )
                                    }

                                    <h4
                                        className="title"
                                    >
                                        {this.state.accountName}
                                    </h4>

                                </div>
                            )
                        }

                        <div
                            style={{
                                position: 'relative',
                                minHeight: '1050px',
                                background: `url(${BirdBesidesPlantImage}) bottom no-repeat`,
                                
                            }}
                        >

                            <div className="m-client-services-card">


                                {
                                    this.state.eroareTx.length === 0 && 

                                    <div>

                                    <MClientServiceInfo 
                                        
                                        name={this.state.transactionDetails.service_name}
                                        duration={this.state.transactionDetails.duration}
                                        location={this.state.locatieCurenta}
                                        description={
                                        <p
                                            style={{
                                                margin: 0, 
                                                marginTop: -10,
                                                color: '#A6A6A6',
                                                fontSize: 14
                                            }}
                                        >
                                            Detaliile de conectare vor fi trimise prin e-mail la 
                                            adresa furnizata
                                            
                                        </p>
                                        }
                                        price={this.state.transactionDetails.price}
                                        date={this.state.transactionDetails['data_cal']}
                                        hour={this.state.transactionDetails['ora_cal']}
                                        editDate = {true}
                                        tranToken = {this.props.match.params.tranToken}
                                        member = {this.state.member}
                                        
                                    />

                                    <h3
                                        style={{

                                            margin: 0,
                                            color: '#455A64',
                                            fontSize: 16
                                        }}
                                    >
                                        Datele Tale
                                    </h3>

                                    <br />

                                    <ClientDataForm 
                                        tranData = {this.state.transactionDetails}
                                        isSmartBill = {this.state.isSmartBill}
                                        triggerSave = {this.state.triggerSave}
                                        inputStatus = {this.inputStatus}
                                    />

                                    <div 
                                        className="terms-wrapper" 
                                        style={{
                                            paddingTop: '12px',
                                            paddingBottom: '12px'
                                        }}
                                    >

                                        <div 
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                width: '8.33%'      
                                            }}
                                        >

                                            <input 
                                                className={this.state.termsError ? "terms-checkbox-error" : "terms-checkbox" }
                                                type="checkbox"
                                                onClick={this.onAcceptTerms}
                                            />
                                            <span className="terms-checkmark"></span>

                                        </div>

                                        <div 
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                width: '91.66%',

                                            }}
                                        >

                                            <a 
                                                href={this.state.termsLink}
                                                className={this.state.termsError ? "terms-text terms-error" : "terms-text"}
                                                target="_blank"
                                            >
                                                Accept <u>Termenii si Conditiile</u>.
                                            </a>

                                        </div>
                                        

                                    </div>
                                        

                                    <div 
                                        className="form-control"
                                        style={{
                                            marginTop: 20,
                                            textAlign: 'left'
                                        }}
                                    >
                                        
                                        <ButtonForm 
                                            className="client-schedule-btn"
                                            tx={this.state.isInvite ? 'Confirma' : 'Programeaza'}
                                            onClick = {this.onTriggerSave}
                                            
                                        />
                                        
                                    </div>

                                    </div>
                                }

                                {
                                    this.state.eroareTx.length > 0 && (

                                        <ErrorMessage 
                                            eroareTx = {this.state.eroareTx}
                                        />
                                    )  
                                }

                            </div>

                            {/* <div
                                style={{
                                        width: '100%',
                                        marginTop: 20
                                    }}
                            >

                                <img 
                                    src={BirdBesidesPlantImage}
                                    alt="Canary and plant..."
                                    style={{
                                        display: 'block',
                                        margin: 'auto',
                                    }}
                                />

                            </div> */}

                        </div>

                        <p
                                style={{
                                    
                                    paddingTop: 10,
                                    paddingBottom: 20,
                                    fontSize: 14,
                                    color: '#455A64'
                                }}
                            >
                                Programari prin  <a 
                                                    href="#"
                                                    style={{
                                                        color: '#455A64'
                                                    }}
                                                >
                                                    Planary.ro
                                                </a>
                                
                            </p>
                        
                        </>
                    )
                }

            </MobileView>

            </React.Fragment>
        )
    }
}