import React from 'react';
import InputValidation from '../../module/logic/InputValidation';
import LocalStorage from '../../providers/LocalStorage';
import Masks from '../../providers/Masks';
import Utils from '../../providers/Utils';
import Ws from '../../providers/Ws';
import AuthInput from '../AuthInput';
import LinkPreview from '../LinkPreview';
import history from '../../skeleton/history';

export default class SvNameAndLink extends React.Component{

    constructor(props){

        super(props);
        
        this.state = {

            name: this.props.name || '',
            link: this.props.link || '',
            nameLength: 0,
            accountLink: '',
            canSave: false,
            enableLinks: true,

            nameTmp: this.props.name || '',
            linkTmp: this.props.link || '',

            nameError: false,
            nameErrorTx: '',
            nameVerified: false,

            linkError: false,
            linkErrorTx: '',
            linkVerified: false
        }
    }

    WS = new Ws();
    UT = new Utils();
    M = new Masks();
    IV = new InputValidation();

    componentDidMount(){

        this.setServiceData();
    }

    componentDidUpdate(prevProps, _){

        if(prevProps.triggerSave !== this.props.triggerSave){

            this.onCheckInputs();
        }

        if(prevProps['name'] !== this.props['name']){

            this.setState({

                enableLinks: true,
                nameTmp: this.props.name,
                linkTmp: this.props.link,
            });
        }
    }

    setServiceData = () => {

        let doEnableLinks = false;

        if(this.props.name.length > 0 && this.props.link.length > 0){

            doEnableLinks = true;
        }

        this.setState({

            nameLength: this.setNameLength(),
            accountLink: this.setAccountLink(),
            enableLinks: doEnableLinks
        });
    }

    setNameLength = () => {

        let length = this.state.name.replaceAll(/\s/g,'').length;

        return length;
    }

    onChangeDenumire = ev =>{
        
        let count = ev.target.value.replaceAll(/\s/g,'').length;
        let linkPrelucrat = ev.target.value.replaceAll(/\s/g,'-');
        let doSave = true;
        let doEnableLinks = false;

        if(ev.target.value === this.state.nameTmp){

            doSave = false;

            if(count > 0){

                doEnableLinks = true;
            }
        }

        if(count <= 120){

            this.setState({

                name: ev.target.value,
                nameLength: +count,
                link: linkPrelucrat.toLowerCase(),
                enableLinks: doEnableLinks
            },
            
                () => {

                    
                    this.props.inputStatus(doSave, 'nameStatus', 'denumire', ev.target.value);
                    this.props.inputStatus(doSave, 'linkStatus', 'link', linkPrelucrat);

                    let duration = 500;
                    clearTimeout(this.inputTimer);
                    this.inputTimer = setTimeout( async ()=>{
                       await this.onVerifyInputs();
                    }, duration);

                }
            );

            
        }
        else{   

            const errorTx = 'Limita de caractere a denumirii serviciului a fost atinsa.';
            this.props.showToastError(errorTx);

            // return;
        }
    }

    onEnteredLink = (ev) =>{

        let doSave = true;
        let doEnableLinks = false;

        if(ev.target.value === this.state.linkTmp){

            doSave = false;
            if(+ev.target.value.length > 0){

                doEnableLinks = true;
            }
        }

        this.setState({

            link: ev.target.value,
            enableLinks: doEnableLinks
        },
        
            () => {

                this.props.inputStatus(doSave, 'linkStatus', 'link', ev.target.value);

                let duration = 500;
                clearTimeout(this.inputTimer);
                    this.inputTimer = setTimeout( async ()=>{
                await this.onVerifyInputs();
                }, duration);

            }
        );

        this.props.inputStatus(doSave, 'linkStatus', 'link', ev.target.value);
    }

    onVerifyInputs = async () => {

        const TOKEN_CONT = new LocalStorage().GetAccountToken();

        let nameAndLinkRes = await this.IV.onCheckServiceLinkAndName(TOKEN_CONT,
            this.state.link, this.state.name, this.props.token
        );

        if(nameAndLinkRes.err !== null){

            //send isOk = false to parent 
        }
        else{   

            if(nameAndLinkRes['data']['svName']['isOk'] && nameAndLinkRes['data']['svLink']['isOk']){

                this.props.inputStatus(true, 'isNameAndLinkOk', 'isNameAndLinkOk', true, true);

            }
            else{

                this.props.inputStatus(true, 'isNameAndLinkOk', 'isNameAndLinkOk', false, true);

            }

            //send isOk = true to parent
        } 
    }

    onCheckInputs = async () => {

        const TOKEN_CONT = new LocalStorage().GetAccountToken();

        let nameAndLinkRes = await this.IV.onCheckServiceLinkAndName(TOKEN_CONT,
            this.state.link, this.state.name, this.props.token
        );

        // let result = {};
        let result = false;

        if(nameAndLinkRes.err !== null){

            result = {

                err: nameAndLinkRes.err,
                data: null
            };
            
        }
        else{

            this.setState({

                nameError: nameAndLinkRes['data']['svName']['error'],
                linkError: nameAndLinkRes['data']['svLink']['error'],
                nameErrorTx: nameAndLinkRes['data']['svName']['errorTx'],
                linkErrorTx: nameAndLinkRes['data']['svLink']['errorTx'],
                nameVerified: nameAndLinkRes['data']['svName']['verified'],
                linkVerified: nameAndLinkRes['data']['svLink']['verified'],
            });
   
        }
        
        // this.props.onCheckAndSave(result);
    }

     ///////////////////////////////////////////////////
    //                                               //
    //   Copy Link and View Page related functions   //
    //                                               //
    ///////////////////////////////////////////////////

    onCopyLinkToClipBoard = async () => {
        
        const linkRes = await this.UT.onCopyLinkToClipBoard(this.state.link);
        let result = {};

        if(linkRes.err !== null){

            result = {

                data: null,
                err: linkRes.err
            }
        }
        else{

            result = {

                data: linkRes.data,
                err: null
            }
        }

        this.props.onCopyLinkToClipBoard(result); 
    }

    onClickViewPage = async () => {

        const viewPageRes = await this.UT.onClickViewPage(this.state.link);

        if(viewPageRes.err !== null){

            const errorTx = viewPageRes.err;
            this.props.showToastError(errorTx);
        }
        else{

            window.open(viewPageRes.data);
        }
    }

    setAccountLink = async () => {

        const accLink = await this.UT.getAccountLink();

        return accLink || '';
    }

    render(){

        return(

            <div style={this.props.style}>

                <div className="form-control">

                    <AuthInput 
                        type="text"
                        label='Denumire Serviciu'
                        labelStyle={{
                            fontSize: this.props.labelSize || '10px'
                        }}
                        iserror={this.state.nameError}
                        errormsg={this.state.nameErrorTx}
                        value={this.state.name}
                        onChange={this.onChangeDenumire}
                        verified={this.state.nameVerified}
                        onFocus = {
                            () => {

                                this.setState({
                                    // denumireError: false,
                                    nameErrorTx: null
                                })
                            }
                        }
                    />

                    <p
                            style={{
                                marginTop: 5,
                                textAlign: 'left',
                                fontSize: '12px'
                            }}
                        >
                            {this.state.nameLength}/120 Caractere
                    </p>

                </div>

                <div 
                    className="form-control"
                    style={{
                        marginTop: 10
                    }}
                >

                    <AuthInput 
                        type="text"
                        label='Link'
                        labelStyle={{
                            fontSize: this.props.labelSize || '10px'
                        }}
                        iserror={this.state.linkError}
                        errormsg={this.state.linkErrorTx}
                        value={this.state.link}
                        onChange={this.onEnteredLink}
                        verified={this.state.linkVerified}
                        onFocus = {
                            () => {

                                this.setState({
                                    // denumireError: false,
                                    linkErrorTx: null
                                })
                            }
                        }
                    />

                </div>

                <div 
                    className="form-control"
                    style={{
                        marginTop: 10
                    }} 
                >

                    <LinkPreview 
                        accountLink = {this.props.accountLink}
                        link={this.state.link}
                        enableLinks = {this.state.enableLinks}
                        onCopyLinkToClipBoard= {this.onCopyLinkToClipBoard}
                        onShowToastSocial = {this.props.onShowToastSocial}
                        onClickViewPage = {this.onClickViewPage}
                        fontSize = {this.props.fontSize}
                        fontSizeCommands = {this.props.fontSizeCommands}
                        commandsMargin = {this.props.commandsMargin || '0px'}
                        hideOptions = {this.props.hideOptions || false}
                    />

                </div>

            </div>
        )
    }
}