import React from 'react';
import { MdAlarm } from 'react-icons/md';
import Masks from '../providers/Masks';
import Utils from '../providers/Utils';
import Ws from '../providers/Ws';
import IconText from './IconText';

export default class HourInterval extends React.Component{

    constructor(props){

        super(props);
    }

    WS = new Ws();
    M = new Masks();
    UT = new Utils();

    componentDidUpdate(){


    }

    render(){

        return(

            <IconText 
                containerTop = {this.props.marginTop || '6px'}
                containerW = {this.props.containterWidth}
                distance = {this.props.iconWidth || '32px'}
                icon = {

                    <MdAlarm 
                        style={{
                            top: this.props.topIcon || 2, 
                            left: this.props.leftIcon || 0, 
                            margin: 0,
                            color: this.props.iconCol || '#A6A6A6',
                            fontSize: this.props.iconSize || '20px'
                        }}
                    /> 
                }

                tx = {

                    this.props.zi && this.props.ora && this.props.durata &&(this.props.zi + ', ' + this.props.data + ' ' + this.props.luna.substr(0,3) + ', ' +
                    
                        this.props.ora.substr(0,5) + ' - ' + this.UT.addMinutes(this.UT.convertToTime(this.props.ora.substr(0,5)), 
                        this.props.durata).toTimeString().substr(0,5) 
                    ) || 

                    this.props.ora && this.props.durata && (         
                        this.props.ora.substr(0,5) + ' - ' + this.UT.addMinutes(this.UT.convertToTime(this.props.ora.substr(0,5)), 
                        this.props.durata).toTimeString().substr(0,5) ) || 

                    !this.props.ora && this.props.durata && (

                        this.props.durata + ' Minute')
                }

                txSize = {this.props.txSize || '14px'}
                txCol = {this.props.txCol || '#455A64'}
                txWeight = {this.props.txWeight || '400'}
            />

            // <div
            //     style={{
            //         display: 'flex',
            //         alignItems: 'center',
            //         marginTop: this.props.marginTop || '6px',
            //         width: this.props.containterWidth || '100%',  
            //     }}
            // >

            //     <div
            //         style={{
            //             display: 'flex',
            //             justifyContent: this.props.justifyContent || 'center',
            //             alignItems: 'center',
            //             width: this.props.iconWidth || '22px'
            //         }}
            //     >

            //         <p 
            //             style={{
            //                 // display: 'inline-block', 
            //                 position: 'relative', 
            //                 top: this.props.topIcon || 2, 
            //                 left: this.props.leftIcon || 0, 
            //                 margin: 0,
            //                 color: this.props.iconCol || '#A6A6A6',
            //                 fontSize: this.props.iconSize || '20px'
            //             }}
            //         >
            //             <MdAlarm /> 

            //         </p>

            //     </div>
                        
            //     <div >

            //         <p 
            //             style={{
            //                 // display: 'inline-block', 
            //                 position: 'relative', 
            //                 top: this.props.topTx || 0,
            //                 left: this.props.leftTx || '10px',
            //                 margin: 0,
            //                 color: this.props.txCol || '#455A64',
            //                 fontWeight: this.props.txWeight || '400',
            //                 fontSize: this.props.txSize || '14px'
            //             }}
            //         >

            //             {this.props.zi && this.props.zi + ', ' + this.props.data + ' ' + this.props.luna.substr(0,3) + ', '  }
                        
            //             {
            //                 this.props.ora && this.props.durata &&
                            
            //                 this.props.ora.substr(0,5) + ' - ' + this.UT.addMinutes(this.UT.convertToTime(this.props.ora.substr(0,5)), 
            //                 this.props.durata).toTimeString().substr(0,5) 
            //             }

            //             {
            //                 !this.props.ora && this.props.durata && 

            //                 this.props.durata + ' Minute'
            //             }
                       
            //         </p>

            //     </div>       
                
            // </div>
        )
    }
}