import React from 'react';
import SelectOthers from './SelectOthers';

export default class ScheduleInterval extends React.Component{

    constructor(props){

        super(props);
    }

    render(){

        return(

            <div>

            {   
                this.props.weekDays && 
                this.props.weekDays.map((wEl, idx) => {

                    return(
                        <div key={`weekday-${idx}`}>
                        <div className="schedule-editor-wrapper">

                            <div 
                                style={{
                                    width: this.props.daysWidth || '20%', 
                                    textAlign: 'left'
                                }}
                            >

                                <p
                                    style={{
                                        margin: 0,
                                        marginTop: '2px',
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        color: '#000'
                                    }}
                                >
                                    {wEl.ziua}
                                </p>

                            </div>

                            <div 
                                style={{
                                    width: this.props.inputWidth || '40%', 
                                    textAlign: 'left'
                                }}
                            >

                                <div className="form-control">

                                    <SelectOthers
                                        keyName='hourstart-'
                                        label="Inceput"
                                        list={this.props.interval}
                                        style={{
                                            width: '97%'
                                        }}
                                        tipCurent={this.props.days[wEl.ziua.toLowerCase().substr(0,2)+'Start'] || 'Indisp'}
                                        name={wEl.ziua.toLowerCase().substr(0,2)+'Start'}
                                        onChange={this.props.onChangeScheduleHourStart}
                                    />

                                </div>

                            </div>

                            <div style={{width: this.props.inputWidth || '40%'}}>

                                <div className="form-control">

                                    <SelectOthers
                                        keyName='hourend-'
                                        label="Sfarsit"
                                        list={this.props.interval}
                                        style={{
                                            width: '97%'
                                        }}
                                        tipCurent={this.props.days[wEl.ziua.toLowerCase().substr(0,2)+'End'] || 'Indisp'}
                                        name={wEl.ziua.toLowerCase().substr(0,2)+'End'}
                                        onChange={this.props.onChangeScheduleHourEnd}
                                    />

                                </div>

                            </div>

                        </div>
                        <br />

                        </div>

                    )
                })
            }

            </div>
        )
    }
}