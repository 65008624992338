import React from "react";
import Declarations from "../providers/Declarations";


export default class SelectBreak extends React.Component{

    constructor(props){

        super(props);
    }


    render(){

        const inputClass = 'select-input' + ' ' + this.props.className;

        return(

            <div>
                
                <select 
                    className={inputClass} 
                    style={this.props.style}
                    onChange={this.props.onChange}      
                >

                    {   
                        this.props.duration.map(((dpEl, idx) => {

                            if(+dpEl.val === +this.props.break){

                                
                                return(
                                    <option 
                                        key={this.props.myKey + idx}
                                        value={+dpEl.val}
                                        selected
                                    >
                                        {dpEl.tx}
                                    </option>
                                );
                            }
                            else{

                                return(

                                    <option 
                                        key={this.props.myKey + idx}
                                        value={+dpEl.val}
                                    >
                                        {dpEl.tx}
                                    </option>
    
                                );
                            }

                        
                        }))
                    }

                </select>

                <label className="select-input-label">
                    {this.props.label}
                </label>

            </div>
        );
    }
}