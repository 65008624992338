import React from 'react';

import { MobileView } from 'react-device-detect';

import { RiCalendar2Fill } from 'react-icons/ri';

import MServiceNav from '../../../../commons/MServiceNav';
import ButtonForm from '../../../../commons/ButtonForm';
import SelectInput from '../../../../commons/SelectInput';
import CalendarImage from '../../../../assets/calendar_bg.png';

import Declarations from '../../../../providers/Declarations';

export default class MCrAppoAddBreak extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            durataCurenta: 30
        };
    }

    localData = {

        listaDurate: new Declarations().listaDurata
    }


    onChangeDurata = (ev) => {

        this.setState({

            durataCurenta: ev.target.value
        });
    }

    onGoToCalendar = () => {

        this.props.history.push('/ca/dashboard/cab/adauga-in-calendar/'+this.state.durataCurenta+'/'+this.props.match.params.intervalDate);
    }

    render(){

        return(

            <MobileView>     

            <MServiceNav
                icon={<RiCalendar2Fill />}
                title='Adauga in calendar'
                path={'/ca/dashboard/cab/creare-programare/'+this.props.match.params.intervalDate}
            />


            <div 
                style={{
                    padding: '20px'
                }}
            >

                <div
                    className="m-create-appointment-card"
                    style={{
                        padding: '20px',
                        borderRadius: '10px',
                        textAlign: 'left'
                    }}
                >

                    <div>
                        <RiCalendar2Fill 
                        
                            style={{

                                display: 'inline-block',
                                position: 'relative',
                                top: 4,
                                margin: 0,
                                fontSize: '20px',
                                color: '#FFC727'
                            }}
                        />
                        <p
                            style={{
                                display: 'inline-block',
                                position: 'relative',
                                margin: 0,
                                fontSize: '14px',
                                fontWeight: '500',
                                color: '#455A64'
                            }}
                        >
                            &nbsp; Selecteaza Durata Pauzei
                        </p>

                    </div>

                    <br />

                    <div
                        style={{
                            width: '100%',
                            height: '1px',
                            background: '#EBEBEB'
                        }}
                    ></div>

                    <br />

                    <div className="form-control">

                        <SelectInput 
                            myKey='durata-pauza-no-'
                            label={'Durata Pauzei'}
                            listaDurataServiciu={this.localData.listaDurate}
                            durataCurenta={this.state.durataCurenta}   
                            onChange={this.onChangeDurata}

                            labelStyle={{
                                top: 6,
                                fontSize: '10px'
                            }}
                        />

                    </div>
                    
                    <br />

                    <ButtonForm 
                        tx='Selecteaza Data'
                        style={{
                            fontSize: '16px',
                            background: '#F8BC05'
                        }}
                        onClick = {this.onGoToCalendar}
                    />

                </div>

                <img 
                    src={CalendarImage}
                    alt="Calendar Image"
                    style={{
                        display: 'block',
                        margin: '30px auto',
                        width: '280px',
                    }}
                />

            </div>

            </MobileView>
        )
    }
}