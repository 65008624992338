import React from 'react';

import Ws from '../../providers/Ws';
import Utils from '../../providers/Utils';
import Declarations from '../../providers/Declarations';

import ClientServiceInfo from './ClientServiceInfo';

import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';
import {IoIosArrowForward, IoIosArrowBack} from 'react-icons/io';

import { Link } from 'react-router-dom';
import { BrowserView, MobileView } from 'react-device-detect';
import MClientServiceInfo from './MClientServiceInfo';
import CompanyAvatar from '../../assets/foxStat.jpeg';
import Masks from '../../providers/Masks';
import LocalStorage from '../../providers/LocalStorage';

import CanaryPlantImage from '../../assets/canary_plant_bg.png';
import IntervalViewClient from './IntervalViewClient';
import ErrorMessage from '../../commons/ErrorMessage';
import CalendarClientFuncs from '../logic/CalendarClientFuncs';
import { Helmet } from 'react-helmet';
import AvatarAccount from '../../commons/AvatarAccount';
import AppointmentHourSelect from '../../commons/AppointmentHourSelect';
import DateChanger from '../../commons/DateChanger';
import Loader from '../../commons/Loader';
import CalendarAndHour from '../../commons/client_appointments/CalendarAndHour';
import TeamMemberCard from '../../commons/TeamMemberCard';

export default class CalendarClient extends React.Component{

    constructor(props){

        super(props)

        this.state = {

            serviceDetails: {},
            programe: [],
            locatieCurenta: {},
            currentSchedule: [],
            showSchedule: false,
            accountName: '',
            serviceLink: '',
            accoutImgSrc: '',
            eroareTx: '',
            tipAbonament: null,
            memmbersList: [],
            member: null,
            isLoading: true
        }
    }

    WS = new Ws();
    UT = new Utils();
    M = new Masks();
    CLF = new CalendarClientFuncs();

    fetchParamteres = {

        isToken: false,
        token: this.props.match.params.company_web_link,
        isPublic: true,
        appointScreen: 'calendar',
        appointSrc: 'pacient',
        appointType: 'programare',
        // errorFunc: this.props.onRemoveHeaderTitle
    }


    componentDidMount(){

         this.getServiceData();
        
        window.scrollTo(0,0);
    }

    componentDidUpdate(prevProps) {

        if (this.props.location !== prevProps.location) {

            this.getServiceData();
        
            window.scrollTo(0,0);
        }
      }

    getServiceData = async () => {

        // let selectService = `select * from services where account_tk = '${this.props.tokenCont}' and token = '${this.props.tokenService}' `;
        // let selectProgram = `select * from programs where account_tk = '${this.props.tokenCont}' and ifnull(sters,0)!=1`;

        let selectService = `select * from services where account_tk = ( select token from accounts where web_link = '${this.props.match.params.company_web_link}' )  
                                                and web_link = '${this.props.match.params.service_web_link}'       `;
    


        let selectProgram = `select * from programs where account_tk = ( select token from accounts where web_link = '${this.props.match.params.company_web_link}' ) and ifnull(sters,0)!=1`;
        let selectAccount = `select * from accounts where token = ( select token from accounts where web_link = '${this.props.match.params.company_web_link}' )`;
        let selectUsers = `select * from users where account_id = ( select token from accounts where web_link = '${this.props.match.params.company_web_link}' )`;

        let _service = this.WS.sqlCommand(`select`, selectService);
        let _schedule = this.WS.sqlCommand(`select`, selectProgram);
        let _account = this.WS.sqlCommand(`select`, selectAccount);
        let _users = this.WS.sqlCommand(`select`, selectUsers);

        let [service, schedule, account, users] = await Promise.all([_service, _schedule, _account, _users]);

        // const currentSchedule = schedule.content.filter(scEl => scEl.token === service.content[0]['program_tk'])
  
        // let currentSchedule = [];

        if(service.success && schedule.success){

            // currentSchedule = schedule.content.filter(scEl => scEl.token === service.content[0]['program_tk'])
            let accountData =  await this.UT.getAccountAvAndName(true, this.props.match.params.company_web_link);

            if(service.content.length < 1){

                this.setState({

                    eroareTx: 'Serviciul nu exista!',
                    isLoading: false
                });

                return;
            }

            if(+service.content[0]['deleted'] === 1){

                this.setState({

                    eroareTx: 'Serviciul a fost anulat de catre comerciant!',
                    isLoading: false
                });

                return;
            } 

            const currentSchedule = schedule.content.filter(scEl => scEl.token === service.content[0]['program_tk']);
            const currentUser = users.content.filter(user => user.web_link === this.props.match.params.memberLink);
            
            this.setState({

                serviceDetails: service.content[0],
                programe: schedule.content,
                currentSchedule: currentSchedule,
                accountName: this.props.match.params.memberLink ? currentUser[0]['nume'] : accountData['name'],
                accoutImgSrc: this.WS.serverAddress + 'staticprovider/getimg?filepath=Storage/Avatars/' + accountData['avatar'], 
                tipAbonament: account.content[0]['abonament'],
                memmbersList: users.content,   
                member: this.props.match.params.memberLink ? currentUser[0] : null
            },
                () => {

                    this.setLocatieCurenta(service.content[0].location)
                }
            )

            // 
            // 
            // 
            // 

        }
        else{

            this.setState({

                eroareTx: 'Eroare server.'
            });
            // throw new Error("Server response error: " + service.message + '\n' + schedule.message)
        }
    }

    setLocatieCurenta = (location) => {

        let locatieCurenta = new Declarations().listaLocatiiIntalnire.filter(lEl => lEl.id === location)

        this.setState({

            locatieCurenta: locatieCurenta[0],
            isLoading: false
        });
    }

    //this.props.history.push("/trc/" + tranToken + "/datele-mele")
    // true, this.props.match.params.company_web_link, true

    initDate = () => {

        let dt = new Date();
        let backTrick = this.M.addMonths(dt, -1);
        let data = new Date(backTrick.getFullYear(), backTrick.getMonth(), 1);

        let nextMonthStart = this.M.addMonths(new Date(data), 1)
        let nextMonthEnd = this.M.firstOrLastDayOfMonth( nextMonthStart.getMonth(), nextMonthStart.getFullYear(), true)

        return{

            err: null,
            data: {

                dataStart: nextMonthStart,
                dataEnd: nextMonthEnd,
                accountTk: `account_tk = ( select token from accounts where web_link = '${this.props.match.params.company_web_link}' )`
            }
        }
  
    }

    onShowClientForm = (tranToken) => {

        if(this.props.match.params.memberLink){

            this.props.history.push(`/trc/${this.props.match.params.memberLink}/${tranToken}/datele-mele`);
        }
        else{

            this.props.history.push("/trc/" + tranToken + "/datele-mele");
        }
    }

    onSetContainerExpansion = () => {

        this.setState({

            showSchedule: true
        });
    }

    onSelectUser = (user) => {

        if(this.state.tipAbonament === 'antreprenor' || user === null){

            this.setState({

                member: user
            });
        }
        else{

            this.props.history.push(`/${this.props.match.params.company_web_link}/${user['web_link']}/${this.props.match.params.service_web_link}/calendar`);
        }
    }

    render(){

        const columnLeft = this.state.showSchedule ? 'col-l-4 col-m-4 col-s-12' : 'col-l-6 col-m-6 col-s-12';
        const wrapper = this.state.showSchedule ? 'client-appoint-wrapper expanded' : 'client-appoint-wrapper';

        return(
            <React.Fragment>

            {
                this.state.isLoading && (

                    <Loader 
                        fullPage = {true}
                    />
                )
            }
            
            <BrowserView>

            {
                !this.state.isLoading && (

                    <>
                    
                        <div className="client-appoint-container">

                            <div className={wrapper}>

                                <div 
                                    className='client-schedule-card'
                                    style={{
                                        padding: '40px 40px 40px 40px'
                                    }}
                                >
                                    {/* <Link to={'/'+this.props.match.params.company_web_link}> */}
                                    <h3  
                                        className="appoint-back"
                                        onClick={this.props.history.goBack}
                                        style={{

                                            textAlign: 'left',
                                            margin: 0,
                                        }}
                                    >
                                        
                                        <p 
                                            style={{
                                                    display: 'inline-block', 
                                                    position: 'relative', 
                                                    top: 4, 
                                                    left: 0, 
                                                    margin: 0,
                                                    fontSize: '40px'
                                                }}
                                        >
                                            <RiArrowLeftSLine /> 

                                        </p>

                                        <p 
                                            style={{
                                                display: 'inline-block', 
                                                position: 'relative',
                                                top: -10, 
                                                left: 0,
                                                margin: 0,
                                                fontWeight: '500',
                                                fontSize: '16px'
                                            }}
                                        >
                                        Inapoi
                                        </p>
                                        
                                    </h3>
                                    {/* </Link> */}

                                    {
                                        this.state.eroareTx.length > 0 && (

                                            <ErrorMessage 
                                                eroareTx = {this.state.eroareTx}
                                            />
                                        )
                                    }

                                    {
                                        this.state.eroareTx.length === 0 && (

                                            <div className="row client-cal-row">

                                                <div 
                                                    className={columnLeft}
                                                    style={{
                                                        paddingRight: 10,
                                                        borderRight: '1px solid #EBEBEB',
                                                        maxHeight: '500px',
                                                        overflowY: 'auto'
                                                    }}
                                                >

                                                    <ClientServiceInfo 
                                                        marginTopAvatar = '35px'
                                                        name={this.state.serviceDetails.name}
                                                        duration={this.state.serviceDetails.duration}
                                                        locatieCurenta={this.state.locatieCurenta}
                                                        price={this.state.serviceDetails.price}
                                                        description={this.state.serviceDetails.description}
                                                        accountName = {this.state.accountName}
                                                        accToken = {this.props.match.params.company_web_link}
                                                        isNotLogged = {true}
                                                        // accountImg = {this.state.accoutImgSrc}
                                                        member={[]}
                                                    />

                                                    {
                                                        this.state.tipAbonament === 'anteprenor' || 
                                                        (this.state.tipAbonament === 'premium' && 
                                                            this.props.match.params.memberLink === undefined
                                                        ) ? (

                                                            <div
                                                                style={{
                                                                    width: '100%',
                                                                    // height: '300px',
                                                                    // overflowY: 'auto'
                                                                }}
                                                            >
                                                                <p
                                                                    style={{
                                                                        fontSize: 14,
                                                                        color: '#455A64',
                                                                        fontWeight: '500'
                                                                    }}
                                                                >
                                                                    Selectati un membru
                                                                </p>

                                                                <TeamMemberCard 
                                                                    name = {this.state.accountName}
                                                                    desc = {`Programare direct pe serviciul ${this.state.serviceDetails.name}`}
                                                                    selected = {this.state.member === null}
                                                                    avatarSrc = {this.state.accoutImgSrc}
                                                                    forSelect = {true}
                                                                    onClick = {() => this.onSelectUser(null)}
                                                                    style={{
                                                                        marginTop: '10px'
                                                                    }}
                                                                />

                                                                {
                                                                    this.state.memmbersList.map( (member, index) => {

                                                                       if(index > 0){

                                                                            return(

                                                                                <TeamMemberCard 
                                                                                    key={`membru-${index}`}
                                                                                    name = {member.nume}
                                                                                    desc = {member.functie}
                                                                                    selected = {this.state.member?.wrk_token === member.wrk_token}
                                                                                    forSelect = {true}
                                                                                    onClick = {() => this.onSelectUser(member)}
                                                                                    style={{
                                                                                        marginTop: '10px'
                                                                                    }}
                                                                                />
                                                                            );
                                                                       }
                                                                    })
                                                                }

                                                            </div>

                                                        ) : null
                                                    }

                                                </div>

                                                <CalendarAndHour 
                                                    initDate = {this.initDate}
                                                    fetchParamteres = {this.fetchParamteres}
                                                    serviceDetails = {this.state.serviceDetails}
                                                    renderPublicCalendar = {true}
                                                    // onShowBreakAddSuccess = {this.onShowBreakAddSuccess}
                                                    onShowClientForm = {this.onShowClientForm}
                                                    onSetContainerExpansion = {this.onSetContainerExpansion}
                                                    member = {this.state.member}
                                                    memberLink = {this.props.match.params.memberLink}
                                                />

                                                
                                            </div>
                                        )
                                    }

                                </div>

                            </div>

                        </div>

                        <div
                            style={{
                                width: '100%',
                                marginTop: 40
                            }}
                        >

                            <img 
                                src={CanaryPlantImage}
                                alt="Canary and plant..."
                                style={{
                                    display: 'block',
                                    margin: 'auto',
                                    width: '400px'
                                }}
                            />

                        </div>

                        <p
                            style={{
                                
                                paddingTop: '20px',
                                paddingBottom: '20px',
                                fontSize: 14,
                                color: '#455A64'
                            }}
                        >
                            Programari prin  <a 
                                                href="https://planary.ro"
                                                style={{
                                                    color: '#455A64'
                                                }}
                                            >
                                                Planary.ro
                                            </a>
                                
                        </p>
                    
                    </>
                )
            }

            </BrowserView>

            <MobileView>
            
               {
                   !this.state.isLoading && (

                        <>
{/*                         
                        <Link to={'/'+this.props.match.params.company_web_link}> */}
                            <h3  
                                className="m-appoint-back" 
                                onClick={this.props.history.goBack}           
                                style={{

                                    textAlign: 'left',
                                    margin: 0,
                                }}
                            > 
                                <p 
                                    style={{
                                            display: 'inline-block', 
                                            position: 'relative', 
                                            top: 4, 
                                            left: 0, 
                                            margin: 0,
                                            fontSize: '40px'
                                        }}
                                >
                                    <RiArrowLeftSLine /> 

                                </p>

                                <p 
                                    style={{
                                        display: 'inline-block', 
                                        position: 'relative',
                                        top: -10, 
                                        left: 0,
                                        margin: 0,
                                        fontWeight: '500',
                                        fontSize: '16px'
                                    }}
                                >
                                Inapoi
                                </p>
                                
                            </h3>
                        {/* </Link> */}


                        <div
                            className="m-client-header"
                            style={{

                                marginTop: -20
                            }}
                        >

                            <AvatarAccount 
                                isNotLogged = {true}
                                accToken = {this.props.match.params.company_web_link}
                                border = '4px solid #fff'
                                width = '102px'
                                height = '102px'
                                top = '20px'
                            />
                            {/* <div className="company-avatar-outer mobile">

                                <div className="company-avatar-inner">
                                    <img src={this.state.accoutImgSrc} alt="Avatar companie" />
                                </div>

                            </div> */}

                            <h4
                                className="title"
                            >
                                {this.state.accountName}
                            </h4>

                        </div>

                        <div
                            style={{
                                position: 'relative',
                                minHeight: '900px',
                                background: `url(${CanaryPlantImage}) bottom no-repeat`,
                                backgroundSize: '300px 100px'
                            }}
                        >

                            <div
                                style={{
                                    marginTop: 30,
                                    borderRadius: '20px',
                                    background: '#fff',
                                    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)'
                                }}
                            >

                                <div style={{padding: '40px 30px'}}>

                                    <MClientServiceInfo 
                                        
                                        name={this.state.serviceDetails.name}
                                        duration={this.state.serviceDetails.duration}
                                        location={this.state.locatieCurenta}
                                        price={this.state.serviceDetails.price}
                                        description={this.state.serviceDetails.description}
                                        member={[]}
                                    />
                                        <p
                                            style={{
                                                fontSize: 14,
                                                color: '#455A64',
                                                fontWeight: '500'
                                            }}
                                        >
                                            Selectati un membru
                                        </p>

                                      {
                                        this.state.tipAbonament === 'anteprenor' || 
                                        (this.state.tipAbonament === 'premium' && 
                                            this.props.match.params.memberLink === undefined
                                        ) ? (

                                            <div
                                                style={{
                                                    width: '100%',
                                                    height: '200px',
                                                    overflowY: 'auto'
                                                }}
                                            >
                                                <TeamMemberCard 
                                                    name = {this.state.accountName}
                                                    desc = {`Programare direct pe serviciul ${this.state.serviceDetails.name}`}
                                                    selected = {this.state.member === null}
                                                    avatarSrc = {this.state.accoutImgSrc}
                                                    forSelect = {true}
                                                    onClick = {() => this.onSelectUser(null)}
                                                    style={{
                                                        marginTop: '10px'
                                                    }}
                                                />

                                                {
                                                    this.state.memmbersList.map( (member, index) => {

                                                        if(index > 0){

                                                            return(

                                                                <TeamMemberCard 
                                                                    key={`membru-${index}`}
                                                                    name = {member.nume}
                                                                    desc = {member.functie}
                                                                    selected = {this.state.member?.wrk_token === member.wrk_token}
                                                                    forSelect = {true}
                                                                    onClick = {() => this.onSelectUser(member)}
                                                                    style={{
                                                                        marginTop: '10px'
                                                                    }}
                                                                />
                                                            );
                                                        }
                                                    })
                                                }

                                            </div>

                                        ) : null
                                    }

                                </div>

                                
                                <CalendarAndHour 
                                    initDate = {this.initDate}
                                    fetchParamteres = {this.fetchParamteres}
                                    serviceDetails = {this.state.serviceDetails}
                                    renderPublicCalendar = {true}
                                    // onShowBreakAddSuccess = {this.onShowBreakAddSuccess}
                                    onShowClientForm = {this.onShowClientForm}
                                    memberLink = {this.props.match.params.memberLink}
                                    //onSetContainerExpansion = {this.onSetContainerExpansion}
                                />
                            </div>

                        </div>

                        <p
                            style={{
                                
                                paddingTop: 10,
                                paddingBottom: 20,
                                fontSize: 14,
                                color: '#455A64'
                            }}
                        >
                            Programari prin  <a 
                                                href="#"
                                                style={{
                                                    color: '#455A64'
                                                }}
                                            >
                                                Planary.ro
                                            </a>
                            
                        </p>

                        </>
                   )
               }
                
                
            </MobileView>

            </React.Fragment>

        )
    }
}