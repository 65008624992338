import React from 'react';

import LocalStorage from '../../../providers/LocalStorage';
import Ws from '../../../providers/Ws';
import Utils from '../../../providers/Utils';
import Declarations from '../../../providers/Declarations';


import {
    BrowserView,
    MobileView,

} from "react-device-detect";
import { Link } from 'react-router-dom';

import { MdLocalActivity } from 'react-icons/md';
import { RiArrowRightSLine } from 'react-icons/ri';
import { RiFolderReduceFill } from 'react-icons/ri';
import { ImLocation2 } from 'react-icons/im';
import { MdShortText } from 'react-icons/md';
import { MdAlarm } from 'react-icons/md';
import { FaRegMoneyBillAlt } from 'react-icons/fa';
import { FaCalendarCheck } from 'react-icons/fa';
import { RiCupFill } from 'react-icons/ri';
import { IoMdColorPalette } from 'react-icons/io';
import { GoCheck } from 'react-icons/go';
import { BiPlus } from 'react-icons/bi';
import { IoVideocam } from 'react-icons/io5';
import { FaSkype } from 'react-icons/fa';
import { ReactComponent as ZoomIcon } from '../../../assets/zoom_icon.svg';
import { ReactComponent as LocIcon } from '../../../assets/pin_drop.svg';
import { FaFlask } from 'react-icons/fa';
import { FaBook } from 'react-icons/fa';
import { MdLocalPostOffice } from 'react-icons/md';
import { RiExchangeFundsLine } from 'react-icons/ri';
import { BiNotificationOff } from 'react-icons/bi';
import { MdDeleteForever } from 'react-icons/md';
import MServiceNav from '../../../commons/MServiceNav';
import GoogleMeetIcon from '../../../assets/Google_Meet-Logo.wine.svg';
import { ReactComponent  as ArticleIcon} from '../../../assets/articleIcon.svg';
import SuccessSaveToast from '../../../commons/SuccessSaveToast';

import Masks from '../../../providers/Masks';
import ErrorToast from '../../../commons/ErrorToast';
import ErrorMessage from '../../../commons/ErrorMessage';
import ServiceAccessRow from '../../../commons/ServiceAccessRow';
import IconText from '../../../commons/IconText';
import ServicesFuncs from '../../logic/ServicesFuncs';
import Loader from '../../../commons/Loader';


export default class MDetaliiServiciu extends React.Component{

    constructor(props){

        super(props)

        this.state = {
            
            accountLink: 'link-cont',
            abonament: 'gratuit',
            detaliiServiciu: {},
            programe: [],
            locatie: {},
            culoare: {},
            nume: 'Nume Serviciu',
            link: 'creeaza-link',
            durata: 60,
            disponibilitate: '30 zile lucratoare',
            tarif: 0,
            descriere: '',
            nrZile: 30,
            pauzaInainte: 15,
            pauzaDupa: 15,
            tipZile: 'zile lucratoare',
            numeProgram: '',
            programCurent: '',
            recallZile: 20,
            recallTipZile: '',
            recallTipZileTx: '',
            hasRecall: 1,
            hasReview: 1,
            hasCancel: 1,
            tarifTx: 'Seteaza Tarif',
            showToastPopup: false,
            actionTx: '',
            errorTx: '',
            isLoading: true
        }
    }

    WS = new Ws();
    UT = new Utils();
    M = new Masks();
    SF = new ServicesFuncs();

    componentDidMount(){

        // if(this.props.match.params.tk.length > 10){

        //     // this.getDateServiciu()
        //     this.getDate()
        // }

        // if(this.props.match.params.tk.length < 10 || 
        //     this.props.match.params.tk === null
        //     ){

        //     this.getDateProgram()
        //     this.setRecallTipZileImplicit()
        //     this.setCuloareImplicita()
        //     this.setLocatieImplicita()
        // }

       this.getDate();

        window.scrollTo(0,0); 

    }

    getDate = async () => {

        const TOKEN_CONT = new LocalStorage().GetAccountToken();

        let q1 = `select * from programs where account_tk = '${TOKEN_CONT}' and ifnull(sters,0)!=1`;
        let q2 = `select * from services where account_tk = '${TOKEN_CONT}' and token = '${this.props.match.params.tk}' `;

        let _programs = this.WS.sqlCommand(`select`, q1);
        let _dateServiciu = this.WS.sqlCommand(`select`, q2);
        const qAccount = `select * from accounts where token = '${TOKEN_CONT}' `;
        let _account = await this.WS.sqlCommand(`select`, qAccount);

        let faraTarifTx = 'Seteaza Tarif';

        let [programs, dateServiciu, account] = await Promise.all([_programs, _dateServiciu, _account])

        

        if(programs.success && dateServiciu.success){

            
            if(dateServiciu.content.length === 0){

                this.setState({

                    errorTx: 'Serviciul nu exista!'
                });

                return;
            }

            if(+dateServiciu.content[0]['deleted'] === 1){

                this.setState({

                    errorTx: 'Serviciul este dezactivat sau indisponibil.'
                });

                return;
            }

            if(+dateServiciu.content[0]['draft'] === 0){

                faraTarifTx = 'Fara Tarif';
            }

            const accLink = await this.UT.getAccountLink();

            // alert('Ayaya!')
            
            this.setState({
                accountLink: accLink || 'contul-meu',
                abonament: account.content[0].abonament,
                programe: programs.content,
                detaliiServiciu: dateServiciu.content[0],
                nume: dateServiciu.content[0].name || 'Nume Serviciu',
                link: dateServiciu.content[0]['web_link'] || 'link-serviciu',
                durata: +dateServiciu.content[0].duration,
                tarif: +dateServiciu.content[0].price,
                descriere: dateServiciu.content[0].description || '',
                nrZile: dateServiciu.content[0]['dispo_nr_zile'],
                pauzaInainte: dateServiciu.content[0]['break_before'],
                pauzaDupa: dateServiciu.content[0]['break_after'],
                recallZile: dateServiciu.content[0]['recall_nr_zile'],
                recallTipZile: dateServiciu.content[0]['recall_tip_zile'],
                hasRecall: +dateServiciu.content[0]['has_recall'],
                hasReview: +dateServiciu.content[0]['review_cere'],
                hasCancel: +dateServiciu.content[0]['has_cancel_policy'],
                tarifTx: faraTarifTx,
                isLoading: false
            },
                () => {
                    this.setLocatie(dateServiciu.content[0])
                    this.setCuloare(dateServiciu.content[0])
                    this.setTipZile(dateServiciu.content[0])
                    this.setProgramCurent(dateServiciu.content[0], programs.content)
                    this.setRecallTipZile(dateServiciu.content[0]['recall_tip_zile'])
                    this.setDisponibility(dateServiciu.content[0])
                    this.getDateProgram()
                    // this.setRecallTipZileImplicit()
                    // this.setCuloareImplicita()
                    // this.setLocatieImplicita()
                }
            )

            
            
            
        }
        else{

            throw new Error('eroare server response:  ' + dateServiciu.message + '\n' + programs.message)
        }
        
    }

    getDateProgram = async () => {

        const TOKEN_CONT = new LocalStorage().GetAccountToken();

        let q1 = `select * from programs where account_tk = '${TOKEN_CONT}' and ifnull(sters,0)!=1`;

        let _programs = this.WS.sqlCommand(`select`, q1);
        
        let [programs] = await Promise.all([_programs]);

        if(programs.success){

            this.setState({

                programe: programs.content,
                isLoading: false
            },

                () => {

                    // this.setProgram(programs.content)
                }
            )

            
        }
        else{

            throw new Error('eroare server response:  ' + programs.message)
        }
    }

    

    setDisponibility = (serviceDetails) => {

        let disponibilityStatus = '';

        if( ( +serviceDetails['dispo_nr_zile'] > 0 ) && 
            ( (serviceDetails['dispo_data_end'] || '').length < 2 )
        ){
            
            const oneDay = 24 * 60 * 60 * 1000;
            const dataStartZile = this.M.sqlDateToJSDate(serviceDetails['dispo_data_start']);
            const dataEndZile = this.M.addDaysToDate(this.M.sqlDateToJSDate(serviceDetails['dispo_data_start']), +serviceDetails['dispo_nr_zile']);
            let todayDate = new Date();
            todayDate.setHours(0,0,0,0);

            let zileRamase = Math.round( Math.abs( (dataEndZile - todayDate) / oneDay ) );

            if(serviceDetails['dispo_tip_zile'] === 'work-days'){

                zileRamase = this.SF.calculateWorkingDays(serviceDetails, dataStartZile, todayDate, oneDay);
            }

            

            const daysType = ( serviceDetails['dispo_tip_zile'] || '').length > 0 && ( serviceDetails['dispo_tip_zile'] || '') === 'work-days' ? 'zile lucratoare' : 'zile calendaristice';

            const dataHumanReadable = `${dataStartZile.getDate()}  ${this.M.monthName(dataStartZile.getMonth())}  ${dataStartZile.getFullYear()}`;
            disponibilityStatus = zileRamase + ' ' + daysType + ' de la ' + dataHumanReadable;
        }

        else if( ( +serviceDetails['dispo_nr_zile'] === 0 ) && 
        ( (serviceDetails['dispo_data_end'] || '').length < 2) ){

            disponibilityStatus = 'Perioada nelimitata';
        }

        else  if( ( +serviceDetails['dispo_nr_zile'] === 0 ) && ( (serviceDetails['dispo_data_start'] || '').length > 2 ) &&
        ( (serviceDetails['dispo_data_end'] || '').length > 2 )
        ){

            const dataStart = this.M.sqlDateToJSDate(serviceDetails['dispo_data_start']);
            const dataEnd = this.M.sqlDateToJSDate(serviceDetails['dispo_data_end']);
            disponibilityStatus = `Periodata ${dataStart.getDate()} ${this.M.monthName(dataStart.getMonth()).substr(0,3)} ${dataStart.getFullYear()} - 
                                    ${dataEnd.getDate()} ${this.M.monthName(dataEnd.getMonth()).substr(0,3)} ${dataEnd.getFullYear()}
        `;
        }
        
        this.setState({

            disponibilitate: disponibilityStatus
        });
    }

    disableService = async () =>{

        let dq = `update services set deleted = ${1}
                    where token = '${this.props.match.params.tk}'
        `

        
        

        let disablerviciu = await this.WS.sqlCommand(`update`, dq);

        if(disablerviciu.success){

            // alert("Serviciu Dezactivat cu Succes!");
            this.setState({

                showToastPopup: true,
                actionTx: 'dezactivat'
            });
        }
        else{

            throw new Error('Eroare Server Response:  ' + disablerviciu.message)
        }
    }
    

    setLocatie = (service) => {

        let locatieCurenta = new Declarations().listaLocatiiIntalnire.filter(lEl => lEl.id === service.location);

        this.setState({

            locatie: locatieCurenta[0]
        })  
    }

    setCuloare = (service) => {

        let culoareCurenta = new Declarations().listaCulori.filter(cEl => cEl.val === service.color);

        this.setState({

            culoare: culoareCurenta[0]
        })
    }

    setTipZile = (service) => {

        let tipZileList = new Declarations().tipZile.filter(zEl => zEl.val === service['dispo_tip_zile'])
        let tipZile = tipZileList[0]?.tx.toLowerCase()

        this.setState({

            tipZile: tipZile
        })

    }

    createScheduleList = (schedule) => {

        let zile = [
            {
                zi: 'L',
                zi_start: schedule?.lu_start === null ? null : schedule?.lu_start.substring(0,5),
                zi_end: schedule?.lu_end === null ? null : schedule?.lu_end.substring(0,5)
            },

            {   
                zi: 'Ma',
                zi_start: schedule?.ma_start === null ? null : schedule?.ma_start.substring(0,5),
                zi_end: schedule?.ma_end === null ? null : schedule?.ma_end.substring(0,5)
            },

            {   
                zi: 'Mi',
                zi_start: schedule?.mi_start === null ? null : schedule?.mi_start.substring(0,5),
                zi_end: schedule?.mi_end === null ? null : schedule?.mi_end.substring(0,5)
            },

            {   
                zi: 'J',
                zi_start: schedule?.jo_start === null ? null : schedule?.jo_start.substring(0,5),
                zi_end: schedule?.jo_end === null ? null : schedule?.jo_end.substring(0,5)
            },

            {   
                zi: 'V',
                zi_start: schedule?.vi_start === null ? null : schedule?.vi_start.substring(0,5),
                zi_end: schedule?.vi_end === null ? null : schedule?.vi_end.substring(0,5)
            },

            {   
                zi: 'S',
                zi_start: schedule?.sa_start === null ? null : schedule?.sa_start.substring(0,5),
                zi_end: schedule?.sa_end === null ? null : schedule?.sa_end.substring(0,5)
            },

            {   
                zi: 'D',
                zi_start: schedule?.du_start === null ? null : schedule?.du_start.substring(0,5),
                zi_end: schedule?.du_end === null ? null : schedule?.du_end.substring(0,5)
            }
        ]

        return zile;
    }

    setProgramCurent = (service, programe) => {

        let programCurent = programe.filter(pEl => pEl.token === service['program_tk']);

        
        let zile = this.createScheduleList(programCurent[0])
        let zileDisponibile = zile.filter(zEl => {

            if((zEl.zi_start === null) || (zEl.zi_end === null)){

                return false;
            }

            return true;
        })

        let programDisponibil = `${zileDisponibile[0].zi} - ${zileDisponibile[zileDisponibile.length-1].zi} : ${zileDisponibile[0].zi_start} - ${zileDisponibile[zileDisponibile.length-1].zi_end}` 
                                        
        this.setState({

            numeProgram: programCurent[0]?.name,
            programCurent: programDisponibil
        })
    }

    setProgram = (programe) => {

        let programCurent = programe[0];

        let zile = this.createScheduleList(programCurent)
        let zileDisponibile = zile.filter(zEl => {

            if((zEl.zi_start === null) || (zEl.zi_end === null)){

                return false;
            }

            return true;
        })

        let programDisponibil = `${zileDisponibile[0].zi} - ${zileDisponibile[zileDisponibile.length-1].zi} : ${zileDisponibile[0].zi_start} - ${zileDisponibile[zileDisponibile.length-1].zi_end}` 
                                        
        this.setState({

            numeProgram: programCurent.name,
            programCurent: programDisponibil
        })
    }

    setRecallTipZile = (type) => {

        let tip = type;
        let tipZileArr = new Declarations().tipZile.filter(zEl => zEl.val === tip)
        let tipZile = tipZileArr[0]?.tx.toLowerCase()

        this.setState({

            recallTipZileTx: tipZile
        })

        // 
    }

    setRecallTipZileImplicit = () => {

        let tipZileArr = new Declarations().tipZile[1]
        let tipZile = tipZileArr.tx.toLowerCase()

        this.setState({

            recallTipZileTx: tipZile
        })

    }

    setCuloareImplicita = () => {

        let culoareCurenta = new Declarations().listaCulori[0];

        this.setState({

            culoare: culoareCurenta
        })
    }

    setLocatieImplicita = () => {

        let locatieCurenta = new Declarations().listaLocatiiIntalnire[0];

        this.setState({

            locatie: locatieCurenta
        })  
    }

    onClosePopup = (type) => {

        this.setState({

            [type]: false
        });
    }

    render(){

        return(
            
            <MobileView>


                {
                    this.state.showToastPopup && (

                        <SuccessSaveToast 
                            onClosePopup = {this.onClosePopup}
                            saveName = 'Serviciul'
                            action = {this.state.actionTx}
                        />
                    )
                }

                <MServiceNav
                    path="/ca/dashboard/cab"
                    icon={<RiFolderReduceFill />}
                    title={this.props.match.params.tk.length < 10 ? 'Detalii Serviciu' : 'Creare Serviciu'}
                />

                {
                    this.state.isLoading && (

                        <Loader 
                            fullPage = {true}
                        />
                    )
                }

                {
                    !this.state.isLoading && (

                        <div
                            style={{
                                padding: '20px'
                            }}
                        >
        
                            <div className="m-serv-details-card">
        
                                {
                                    this.state.errorTx.length > 0 && (
        
                                        <ErrorMessage                         
                                            eroareTx = {this.state.errorTx}
                                        />
                                    )
                                }
        
                                {
                                    this.state.errorTx.length === 0 && (
        
                                        <>
                                            <h6
                                                style={{
        
                                                    textAlign: 'left',
                                                    margin: 0
                                                }}
                                            >
                                                <p 
                                                    style={
                                                        {
                                                            display: 'inline-block', 
                                                            position: 'relative', 
                                                            top: 5, 
                                                            left: 0, 
                                                            margin: 0,
                                                            fontSize: '20px',
                                                            color: '#FFC727'
                                                    
                                                        }
                                                    }
                                                >
                                                    <MdLocalActivity />
                                                </p>
        
                                                <p 
                                                    style={
                                                        {
                                                            display: 'inline-block', 
                                                            position: 'relative', 
                                                            left: 10,
                                                            margin: 0,
                                                            fontSize: '14px',
                                                            fontWeight: '500',
                                                            color: '#263238'
                                                        }
                                                    }
                                                >
                                                    Informatii de baza
                                                </p>
                                                
                                            </h6>
        
                                            {/*
                                                /////////////////////////
                                                            1
                                                ////////////////////////
                                            */}
                                            
        
                                            <ServiceAccessRow 
                                                link = {'denumire-serviciu/'+this.props.match.params.tk}
                                                titleTx = {this.state.nume}
                                                subTx = {`http://planary.ro/${this.state.accountLink}/${this.state.link}`}
                                                distance = '0px'
                                                subTxCol = '#A6A6A6'
                                            />
        
                                            {/*
                                                /////////////////////////
                                                            2
                                                ////////////////////////
                                            */}
        
                                            <ServiceAccessRow 
                                                link = {'locatie-serviciu/'+this.props.match.params.tk}
                                                titleTx = 'Locatie'
                                                isLocation = {true}
                                                location = {this.state.locatie?.id}
                                                locationDesc = {this.state.locatie['descClient']}
                                                containerStyle = {{
        
                                                    borderTop: 'none',
                                                    padding: '0px 0px 15px 0px'
                                                }}
                                            />
        
        
                                            {/*
                                                /////////////////////////
                                                            3
                                                ////////////////////////
                                            */}
        
                                            <ServiceAccessRow 
                                                link = {'durata-serviciu/'+this.props.match.params.tk}
                                                titleTx = 'Durata Serviciu'
                                                subTxIcon = {
        
                                                    <MdAlarm 
                                                        style={{
                                                            
                                                            left: 0, 
                                                            margin: 0,
                                                            fontSize: '16px',
                                                            color: '#A6A6A6'
                                                        }}
                                                    />
                                                }
                                                subTx = {this.state.durata + ' Minute'}
                                                subTxSize = '12px'
                                                subTxWeight = '500'
                                                containerStyle = {{
        
                                                    borderTop: 'none',
                                                    padding: '0px 0px 15px 0px'
                                                }}
                                            />
        
                                            {/*
                                                /////////////////////////
                                                            4
                                                ////////////////////////
                                            */}
        
                                            <ServiceAccessRow 
                                                link = {'tarif-serviciu/'+this.props.match.params.tk}
                                                titleTx = 'Tarif Serviciu'
                                                subTxIcon = {
        
                                                    <FaRegMoneyBillAlt 
                                                        style={{
                                                            top: '1px',
                                                            left: 0, 
                                                            margin: 0,
                                                            fontSize: '16px',
                                                            color: +this.state.tarif === 0 ? '#60D84C' :'#A6A6A6'
                                                        }}
                                                    />
                                                }
                                                subTx = {`${+this.state.tarif === 0 ? this.state.tarifTx : this.state.tarif + ' Lei'} `}
                                                subTxSize = '12px'
                                                subTxWeight = '500'
                                                containerStyle = {{
        
                                                    borderTop: 'none',
                                                    padding: '0px 0px 15px 0px'
                                                }}
                                            />
                                        
        
                                            {/*
                                                /////////////////////////
                                                            5
                                                ////////////////////////
                                            */}
        
                                            <ServiceAccessRow 
                                                link = {'descriere-serviciu/'+this.props.match.params.tk}
                                                titleTx = 'Descriere'
                                                subTx = {this.state.descriere.length === 0 ? 'Creeaza o descriere' : this.state.descriere}
                                                distance = '0px'
                                                containerStyle = {{
        
                                                    borderTop: 'none',
                                                    padding: '0px 0px 15px 0px'
                                                }}
                                                isLimit = {true}
                                            />
                                            
                                        
                                            {/*
                                                /////////////////////////
                                                            6
                                                ////////////////////////
                                            */}
        
                                            <ServiceAccessRow 
                                                link = {'culoare-calendar/'+this.props.match.params.tk}
                                                subTxIcon = {
        
                                                    <span 
                                                        className="m-color"
                                                        style={{
                                                            background: this.state.culoare?.col
                                                        }}
                                                    >
                                                        <GoCheck 
                                                            style={{
        
                                                                position: 'relative',
                                                                top: '50%',
                                                                transform: 'translateY(-50%)',
                                                                left: '6px',
                                                                color: '#fff'
                                                            }}
                                                        />
                                                        
                                                    </span>
                                                }
                                                distance = '36px'
                                                subTx = 'Culoare in Calendar'
                                                subTxSize = '14px'
                                                subTxWeight = '500'
                                                subTxCol = '#000000'
                                                containerStyle = {{
        
                                                    border: 'none',
                                                    padding: '0px 0px 15px 0px'
                                                }}
                                            />
                                            
                                        </>
                                    )
                                }
        
        
                            </div>
        
                            {
                                this.state.errorTx.length === 0 && (
                                    
                                    <>
                                    
                                    <div 
                                        className="m-serv-details-card"
                                        style={{
                                            marginTop: 20
                                        }}
                                    >
                                        <h6
                                            style={{
        
                                                textAlign: 'left',
                                                margin: 0
                                            }}
                                        >
                                            <p 
                                                style={
                                                    {
                                                        display: 'inline-block', 
                                                        position: 'relative', 
                                                        top: 4, 
                                                        left: 0, 
                                                        margin: 0,
                                                        fontSize: '18px',
                                                        color: '#FFC727'
                                                
                                                    }
                                                }
                                            >
                                                <FaFlask />
                                            </p>
        
                                            <p 
                                                style={
                                                    {
                                                        display: 'inline-block', 
                                                        position: 'relative', 
                                                        left: 10,
                                                        margin: 0,
                                                        fontSize: '14px',
                                                        fontWeight: '500',
                                                        color: '#263238'
                                                    }
                                                }
                                            >
                                                Disponibilitate
                                            </p>
                                            
                                        </h6>
        
                                        
                                        {/*
                                            /////////////////////////
                                                        1
                                            ////////////////////////
                                        */}
        
                                        <ServiceAccessRow 
                                            link = {'disponibilitate-serviciu/'+this.props.match.params.tk}
                                            titleTx = 'Disponibilitate'
                                            subTxIcon = {
        
                                                <FaCalendarCheck 
                                                    style={{
                                                        // top: '1px',
                                                        left: 0, 
                                                        margin: 0,
                                                        fontSize: '16px',
                                                        color: '#A6A6A6'
                                                    }}
                                                />
                                            }
                                            subTxTopTitle = '1px'
                                            subTx = {this.state.disponibilitate}
                                            subTxSize = '12px'
                                            subTxWeight = '500'
                                            subTxCol = '#455A64'
                                        />
                                        
                                        {/*
                                            /////////////////////////
                                                        2
                                            ////////////////////////
                                        */}
        
                                        <ServiceAccessRow 
                                            link = {'program-serviciu/'+this.props.match.params.tk}
                                            titleTx = 'Program'
                                            subTxIcon = {
        
                                                <FaBook 
                                                    style={{
                                                        top: '1px',
                                                        left: 0, 
                                                        margin: 0,
                                                        fontSize: '16px',
                                                        color: '#A6A6A6'
                                                    }}
                                                />
                                            }
                                            subTx = {this.state.numeProgram}
                                            subTxSize = '12px'
                                            subTxWeight = '500'
                                            subTxCol = '#455A64'
                                            containerStyle = {{
        
                                                borderTop: 'none',
                                                padding: '0px 0px 15px 0px'
                                            }}
                                        />
        
        
                                                                {/*
                                            /////////////////////////
                                                        3
                                            ////////////////////////
                                        */}
                                        
                                        <ServiceAccessRow 
                                            link = {'pauze-serviciu/'+this.props.match.params.tk}
                                            titleTx = 'Pauze'
                                            subTxIcon = {
        
                                                <MdAlarm 
                                                    style={{
                                                        
                                                        left: 0, 
                                                        margin: 0,
                                                        fontSize: '16px',
                                                        color: '#A6A6A6'
                                                    }}
                                                />
                                            }
                                            subTx = {this.state.pauzaInainte + ' minute inainte'}
                                            subTxSize = '12px'
                                            subTxWeight = '500'
                                            subTxCol = '#455A64'
                                            containerStyle = {{
        
                                                border: 'none',
                                                padding: '0px 0px 15px 0px'
                                            }}
        
                                            additionalRow = {
        
                                                <IconText 
                                                    containerTop = '8px'
                                                    top = '5px'
                                                    distance = '22px'
                                                    icon = {
        
                                                        <MdAlarm 
                                                            style={{
                                                                
                                                                left: 0, 
                                                                margin: 0,
                                                                fontSize: '16px',
                                                                color: '#A6A6A6'
                                                            }}
                                                        />
                                                    }
                                                    tx = {this.state.pauzaDupa + ' minute dupa'}
                                                    txSize = '12px'
                                                    txWeight = '500'
                                                    txCol = '#455A64'
                                                />
                                            }
                                        />
        
                                    </div>
        
                                    {
                                        this.state.abonament !== 'gratuit' && this.state.abonament !== null ? (

                                            <>
                                                <div 
                                                    className="m-serv-details-card"
                                                    style={{
                                                        marginTop: 20
                                                    }}
                                                >
                                                    <h6
                                                        style={{
                    
                                                            textAlign: 'left',
                                                            margin: 0
                                                        }}
                                                    >
                                                        <p 
                                                            style={
                                                                {
                                                                    display: 'inline-block', 
                                                                    position: 'relative', 
                                                                    top: 6, 
                                                                    left: 0, 
                                                                    margin: 0,
                                                                    fontSize: '24px',
                                                                    color: '#FFC727'
                                                            
                                                                }
                                                            }
                                                        >
                                                            <MdLocalPostOffice />
                                                        </p>
                    
                                                        <p 
                                                            style={
                                                                {
                                                                    display: 'inline-block', 
                                                                    position: 'relative', 
                                                                    left: 10,
                                                                    margin: 0,
                                                                    fontSize: '14px',
                                                                    fontWeight: '500',
                                                                    color: '#263238'
                                                                }
                                                            }
                                                        >
                                                            Mesaje Post Serviciu
                                                        </p>
                                                        
                                                    </h6>
                    
                                                    
                                                    {/*
                                                        /////////////////////////
                                                                    1
                                                        ////////////////////////
                                                    */}
                    
                                                    <ServiceAccessRow 
                                                        link = {'recall/'+this.props.match.params.tk}
                                                        titleTx = 'Recall'
                                                        subTxIcon = {
                    
                                                            <RiExchangeFundsLine 
                                                                style={{
                                                                    left: 0, 
                                                                    margin: 0,
                                                                    fontSize: '16px',
                                                                    color: '#A6A6A6'
                                                                }}
                                                            />
                                                        }
                                                        subTx = {+this.state.hasRecall === 1 ? this.state.recallZile + ' ' + this.state.recallTipZileTx : <span>Recall <span style={{color: '#FA8989'}}>Inactiv</span></span>} 
                                                        subTxSize = '12px'
                                                        subTxWeight = '500'
                                                        subTxCol = '#455A64'
                                                
                                                    />
                                                    
                                                    {/*
                                                        /////////////////////////
                                                                    2
                                                        ////////////////////////
                                                    */}
                    
                                                    <ServiceAccessRow 
                                                        link = {'cerere-review/'+this.props.match.params.tk}
                                                        titleTx = 'Email Review'
                                                        subTxIcon = {
                    
                                                            <RiExchangeFundsLine 
                                                                style={{
                                                                    left: 0, 
                                                                    margin: 0,
                                                                    fontSize: '16px',
                                                                    color: '#A6A6A6'
                                                                }}
                                                            />
                                                        }
                                                        subTx = {+this.state.hasReview === 1 ? 
                                                                    <span> Mesajul email cerere review este <span style={{color: 'green'}}>Activ</span></span> : 
                                                                    
                                                                    <span> Mesajul email cerere review este <span style={{color: '#FA8989'}}>Inactiv</span></span>
                                                                }
                                                        subTxSize = '12px'
                                                        subTxWeight = '500'
                                                        subTxCol = '#455A64'
                                                        containerStyle = {{
                    
                                                            border: 'none',
                                                            padding: '0px 0px 15px 0px'
                                                        }}
                                                    />
                                                    
                                                </div>
                    
                                                
                                                <div 
                                                    className="m-serv-details-card"
                                                    style={{
                    
                                                        marginTop: 20
                                                    }}
                                                >
                    
                                                    <h6
                                                        style={{
                    
                                                            textAlign: 'left',
                                                            margin: 0
                                                        }}
                                                    >
                                                        <p 
                                                            style={
                                                                {
                                                                    display: 'inline-block', 
                                                                    position: 'relative', 
                                                                    top: 2, 
                                                                    left: 0, 
                                                                    margin: 0,
                                                                    fontSize: '24px',
                                                                    color: '#FFC727'
                                                            
                                                                }
                                                            }
                                                        >
                                                            <ArticleIcon className="terms-icon" />
                                                        </p>
                    
                                                        <p 
                                                            style={
                                                                {
                                                                    display: 'inline-block', 
                                                                    position: 'relative', 
                                                                    left: 10,
                                                                    margin: 0,
                                                                    fontSize: '14px',
                                                                    fontWeight: '500',
                                                                    color: '#263238'
                                                                }
                                                            }
                                                        >
                                                            Termeni si Conditii
                                                        </p>
                                                        
                                                    </h6>
                    
                                                    {/*
                                                        /////////////////////////
                                                                    1
                                                        ////////////////////////
                                                    */}
                                                    <ServiceAccessRow 
                                                        link = {'politica-anulare/'+this.props.match.params.tk}
                                                        titleTx = 'Termeni si Conditii Proprii'
                                                        subTxIcon = {
                    
                                                            <RiExchangeFundsLine 
                                                                style={{
                                                                    left: 0, 
                                                                    margin: 0,
                                                                    fontSize: '16px',
                                                                    color: '#A6A6A6'
                                                                }}
                                                            />
                                                        }
                                                        subTx = {+this.state.hasCancel === 1 ? 
                                                                    <span> Termeni si Conditii Proprii <span style={{color: 'green'}}>Activati</span></span> : 
                                                                    
                                                                    <span> Termeni si Conditii Proprii <span style={{color: '#FA8989'}}>Inactivi</span></span>
                                                                }
                                                        subTxSize = '12px'
                                                        subTxWeight = '500'
                                                        subTxCol = '#455A64'
                                                        containerStyle = {{
                    
                                                            borderBottom: 'none',
                                                            padding: '20px 0px 15px 0px'
                                                        }}
                                                    />
                    
                                                </div>
                                            
                                            </>
                                        ) : null
                                    }
                                    
                                    </>
                                )
                            }
        
                            {
                                this.props.match.params.tk.length > 10 && +this.state.detaliiServiciu['draft'] === 0 && (
        
                                    <h6
                                        className="delete-service"
                                        onClick={this.disableService}
                                    >
                                        <p 
                                            style={
                                                {
                                                    display: 'inline-block', 
                                                    position: 'relative', 
                                                    top: 6, 
                                                    left: 0, 
                                                    margin: 0,
                                                    fontSize: '24px',
                                                }
                                            }
                                        >
                                            <MdDeleteForever />
                                        </p>    
        
                                        <p 
                                            style={
                                                {
                                                    display: 'inline-block', 
                                                    position: 'relative', 
                                                    left: 10,
                                                    margin: 0,
                                                    fontSize: '14px',
                                                    fontWeight: '500',
                                                }
                                            }
                                        >
                                            Sterge Serviciu
                                        </p>
                                            
                                    </h6>
                                )
        
                            
                            }
        
                        </div>
                    )
                }

            </MobileView>
        )
    }
}