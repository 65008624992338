import React from 'react';

import { BrowserView, MobileView } from 'react-device-detect';
import { FaAngleRight } from 'react-icons/fa';
import { GoCheck } from 'react-icons/go';
import { MdPersonAdd, MdSupervisorAccount } from 'react-icons/md';

import MServiceNav from '../../../commons/MServiceNav';
import TeamMemberCard from '../../../commons/TeamMemberCard';
import LaptopAndMugImg from '../../../assets/laptop_and_mug.png';

import Masks from '../../../providers/Masks';
import Utils from '../../../providers/Utils';
import Ws from '../../../providers/Ws';
import IconText from '../../../commons/IconText';
import ButtonSaveDashboard from '../../../commons/ButtonSaveDashboard';
import MembruEchipa from './MembruEchipa';
import LocalStorage from '../../../providers/LocalStorage';
import Loader from '../../../commons/Loader';
import ErrorToast from '../../../commons/ErrorToast';
import SuccessSaveToast from '../../../commons/SuccessSaveToast';

export default class Echipa extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            canSave: false,
            tipAbonament: null,
            utilizatori: [],
            programe: [],
            userWrkToken: null,
            isLoading: true
        };
    }

    WS = new Ws();
    UT = new Utils();
    M = new Masks();

    componentDidMount(){

        this.onSetData();
    }

    onSetData = async () => {

        const accountToken = new LocalStorage().GetAccountToken();
        const userData = new LocalStorage().GetUserData();

        const q = `select * from users where account_id = '${accountToken}' `;
        let usersRes = await this.WS.sqlCommand(`select`, q);

        let sq = `select * from users`;
        

        let eq = await this.WS.sqlCommand(`select`, sq);
        

        if(usersRes.success){

            this.setState({

                utilizatori: usersRes.content
            }, () => {this.onGetPrograme()});
        }
    }

    onGetPrograme = async () => {

        const accountToken = new LocalStorage().GetAccountToken();

        const qPrograms = `select * from programs where account_tk = '${accountToken}' and ifnull(sters,0)!=1`;
        let programs = await this.WS.sqlCommand(`select`, qPrograms);

        this.setState({

            programe: programs.content,
        }, () => {this.onGetTipAbonament()});
    }

    onGetTipAbonament = async () => {

        let accountToken = new LocalStorage().GetAccountToken();

        let q = `select * from accounts where token = '${accountToken}' `;
        let res = await this.WS.sqlCommand(`select`, q);

        if(res.success){

            this.setState({

                tipAbonament: res.content[0].abonament,
                isLoading: false,
            });
        }
    }

    onSelectUser = (user) => {

        this.setState({

            userWrkToken: user.wrk_token
        });
    }

    onGoToUserPage = (user) => {

        this.props.history.push(`echipa/membru/${user.wrk_token}`);
    }

    onSelectAddMember = () => {

        this.setState({

            userWrkToken: null
        });
    }

    
    onTriggerSave = () => {

        this.setState({

            canSave: true
        });
    }

    render(){

        if(this.state.isLoading){

            return <Loader containerH = '300px' />;
        }

        return(

            <>

                <BrowserView>
                
                    <div 
                        className="wrapper-dashboard" 
                        style={{

                            margin:'30px auto',
                            minHeight: '800px',
                            background: `url(${LaptopAndMugImg}) bottom no-repeat`
                        }}
                    >

                        
                        <div className="service-details-card separated-card">

                            <div className='row'>

                                <div className='col-l-4 col-m-4 col-s-12'>

                                    <IconText 
                                        icon = {
                                            <MdSupervisorAccount 
                                                style={{
                                                    color: 'rgb(255, 199, 39)',
                                                    fontSize: '20px'
                                                }}
                                            />
                                        }
                                        tx = 'Echipa'
                                        txSize = '18px'
                                        txWeight = '500'
                                        txCol = '#74838B'
                                    />

                                    {
                                        this.state.tipAbonament === 'anteprenor' ? (

                                            <p
                                                style={{
                                                    margin: 0,
                                                    fontWeight: '400',
                                                    fontSize: '16px',
                                                    color: '#455A64',
                                                    fontWeight: 500
                                                }}
                                            >
                                                {this.state.utilizatori.length - 1} / 3 utilizatori ramasi.
                                            </p>
                                        ) : null
                                    }

                                    {
                                        this.state.utilizatori.length > 0 ? (

                                            
                                            this.state.utilizatori.map( (user, index) => {

                                                const program = this.state.programe.filter( (program) => program.token === user.program_tk);

                                                return(

                                                    <TeamMemberCard 
                                                        key={`membru-${index}`}
                                                        name = {user.nume}
                                                        desc = {user.functie}
                                                        schedule = {program[0]?.name || ''}
                                                        onClick = {() => this.onSelectUser(user)}
                                                        style={{
                                                            marginTop: '10px'
                                                        }}
                                                    />
                                                )
                                            })
                                            
                                        ) : (

                                            <p
                                                style={{
                                                    position: 'relative',
                                                    top: '60px',
                                                    margin: 0,
                                                    fontWeight: '400',
                                                    fontSize: '16px',
                                                    color: '#455A64'
                                                }}
                                            >
                                                Nu ai adaugat nici un membru.
                                            </p>
                                        )
                                    }

                                    <div className="teamMember-add" onClick={this.onSelectAddMember}>
                                        <MdPersonAdd 
                                            className="teamMember-icon"
                                        />
                                    </div>
                                    
                                </div>

                                <div className='col-l-5 col-m-5 col-s-12'>

                                <IconText 
                                        icon = {
                                            <MdSupervisorAccount 
                                                style={{
                                                    color: 'rgb(255, 199, 39)',
                                                    fontSize: '20px'
                                                }}
                                            />
                                        }
                                        tx = 'Membru Echipa'
                                        txSize = '18px'
                                        txWeight = '500'
                                        txCol = '#74838B'
                                    />

                                    <MembruEchipa 
                                        isDesktop = {true}
                                        userWrkToken = {this.state.userWrkToken}
                                        tipAbonament = {this.state.tipAbonament}
                                        onSetData = {this.onSetData}
                                        onSelectUser = {this.onSelectUser}
                                        onTriggerSave = {this.onTriggerSave}
                                    /> 

                                </div>

                            </div>  

                        </div>

                    </div>
                
                </BrowserView>
            
                <MobileView>

                    <div
                        style={{
                            position: 'relative'
                        }} 
                    >

                        <MServiceNav 
                            icon={<MdSupervisorAccount />}
                            title='Echipa'
                            path={'/ca/dashboard/cab'}
                        />

                        {
                            this.state.canSave &&
                            <GoCheck
                                className="m-add-service"
                                // onClick={this.saveToDb}
                            />
                        }

                    </div>

                    <div 
                        style={{
                            padding: 20
                        }}
                    >

                        <div className="m-edit-s-card">

                            {
                                this.state.tipAbonament === 'anteprenor' ? (

                                    <p
                                        style={{
                                            margin: 0,
                                            fontWeight: '400',
                                            fontSize: '16px',
                                            color: '#455A64',
                                            fontWeight: 500
                                        }}
                                    >
                                        {this.state.utilizatori.length - 1} / 3 utilizatori ramasi.
                                    </p>
                                ) : null
                            }

                            {
                                this.state.utilizatori.length > 0 ? (

                                    
                                    this.state.utilizatori.map( (user, index) => {

                                        return(

                                            <TeamMemberCard 
                                                key={`membru-${index}`}
                                                name = {user.nume}
                                                desc = {user.functie}
                                                schedule = {''}
                                                onClick = {() => this.onGoToUserPage(user)}
                                                style={{
                                                    marginTop: '10px'
                                                }}
                                            />
                                        )
                                    })
                                    
                                ) : (

                                    <p
                                        style={{
                                            position: 'relative',
                                            top: '60px',
                                            margin: 0,
                                            fontWeight: '400',
                                            fontSize: '16px',
                                            color: '#455A64'
                                        }}
                                    >
                                        Nu ai adaugat nici un membru.
                                    </p>
                                )
                            }

                            <div className="teamMember-add">
                                <MdPersonAdd 
                                    className="teamMember-icon"
                                />
                            </div>

                        </div>

                    </div>

                </MobileView>

            </>
        );
    }
}