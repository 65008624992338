import React from 'react';

import Masks from '../../providers/Masks';
import Utils from '../../providers/Utils';
import Ws from '../../providers/Ws';
import LocalStorage from '../../providers/LocalStorage';
import { BrowserView } from 'react-device-detect';
import { MdClear } from 'react-icons/md';

export default class TestInv extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            serviceDetails: {},
            nume: '',
            prenume: '',
            email: '',
            telefon: '',
            dateSelected: '',
            confirmHour: '',
        };
    }

    WS = new Ws();
    M = new Masks();
    UT = new Utils();

    componentDidMount(){

        this.generateDemoState();
    }   

    generateDemoState = async () => {


        const ACC_TOKEN = new LocalStorage().GetAccountToken();
        const webLinkService = 'serviciu-skype'

        let selectService = `select * from services where account_tk = '${ACC_TOKEN}' 
                                                    and web_link = '${webLinkService}' and ifnull(deleted,0)!=1 and ifnull(draft,0)!=1       `;

                                                    

        let serviceData = await this.WS.sqlCommand(`select`, selectService);
        

        if(serviceData.success){

            this.setState({

                serviceDetails: serviceData.content[0],
                nume: 'Stoian',
                prenume: 'Lucian',
                email: 'stoianlucian28@gmail.com',
                telefon: '0736353573',
                dateSelected: '2021-10-07',
                confirmHour: '10:00'
            })
        }
        else{

            throw new Error("Server response error: " + serviceData.message)
        }

        
    }

    onCreateInvite = async () => {

        const tranToken = this.UT.generateToken();
        const insertQueryTran = ` insert into trc (token, cand_creat, account_tk, 
                                     service_tk, service_name, service_description,
                                     duration, location, price, 
                                     data_cal, ora_cal, draft, clie_nume, clie_prenume,
                                     clie_email, clie_tf, src,
                                     screen
                                ) 

                                select '${tranToken}', NOW(), '${this.state.serviceDetails.account_tk}',
                                        '${this.state.serviceDetails.token}', '${this.state.serviceDetails.name}', '${this.state.serviceDetails.description}',
                                        '${this.state.serviceDetails.duration}', '${this.state.serviceDetails.location}', '${this.state.serviceDetails.price}',
                                        '${this.state.dateSelected}', '${this.state.confirmHour}', 1, 
                                        '${this.state.nume}', '${this.state.prenume}', '${this.state.email}',
                                        '${this.state.telefon}', 'ca',
                                        'popup'
                                    
                                    `

        

        const createTran = await this.WS.sqlCommand(`insert`, insertQueryTran)
        
        

        if(createTran.success){

            // alert("Succes!");

            let ss = await this.WS.getData('/invite/create', {trcToken: tranToken});
            
        
    
        }
        else{

            throw new Error("Server response error: " + createTran.message)
        }
    }

    render(){

        return(

        <BrowserView>
        <div 
            className="calendar-modal-container"
        >

            <div className="calendar-popup-card">

            <MdClear
                style={{
                    position: 'absolute',
                    top: 10,
                    right: 10,
                    fontSize: '24px',
                    color: '#323232',
                    cursor: 'pointer'
                }}

                onClick={this.onClosePopup}
            />

                <button
                    type="button"
                    onClick={this.onCreateInvite}
                >
                    Trimite Link Inv
                </button>

            </div>

        </div>
        </BrowserView>
        )
    }
}