import React from 'react';


import Masks from '../../../providers/Masks';
import Utils from '../../../providers/Utils';
import Ws from '../../../providers/Ws';
import LocalStorage from '../../../providers/LocalStorage';

import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import ErrorImage from '../../../assets/Feeling sorry-cuate 1.png';

import CrAppoIntervalView from './CrAppoIntervalView';
import ErrorMessage from '../../../commons/ErrorMessage';
import CalendarClientFuncs from '../../logic/CalendarClientFuncs';
import AppointmentHourSelect from '../../../commons/AppointmentHourSelect';
import DateChanger from '../../../commons/DateChanger';
import Loader from '../../../commons/Loader';
import CalendarAndHour from '../../../commons/client_appointments/CalendarAndHour';
import TeamMemberCard from '../../../commons/TeamMemberCard';

export default class CrAppoCalendar extends React.Component{

    constructor(props){

        super(props);

        this.state ={

            member: null, 
            members: [],
            abonament: '',
            avatar: ''
        };
    }

    WS = new Ws();
    UT = new Utils();
    M = new Masks();
    CLF = new CalendarClientFuncs();

    componentDidMount(){

       this.onSetAccountData();
    }

    fetchParamteres = {

        isToken: false,
        token: new LocalStorage().GetAccountToken(),
        isPublic: false,
        appointScreen: 'popup',
        appointSrc: 'ca',
        appointType: 'programare',
        errorFunc: this.props.onRemoveHeaderTitle
    }

    onSetAccountData = async () => {

        const accountToken = new LocalStorage().GetAccountToken();

        const qAccount = `select * from accounts where token = '${accountToken}' `;
        let _account = this.WS.sqlCommand(`select`, qAccount);

        const qUsers = `select * from users where account_id = '${accountToken}' `;
        let _users = this.WS.sqlCommand(`select`, qUsers);

        let [ account, users ] = await Promise.all( [ _account, _users] );

        const userToken =  new LocalStorage().GetUserData().userData[0].token;
        const isMainAccount = users.content[0].token === userToken;
        const memberObj = isMainAccount ? null : users.content.filter(user => user.token === userToken);

        console.log("member obj => ", memberObj)

        this.setState({

            abonament: account.content[0].abonament,
            members: users.content,
            avatar: this.WS.serverAddress + 'staticprovider/getimg?filepath=Storage/Avatars/' + account.content[0]['avatar'], 
            member: memberObj[0]
                            
        });
    }

    initDate = () => {

        let nextMonthStart = new Date(this.props.dataStart);

        let nextMonthEnd = new Date(this.props.dataEnd);

        let todayDate = new Date();

        todayDate.setHours(0,0,0,0);

        if( ( ( nextMonthStart.getMonth() < todayDate.getMonth() ) || 
                 ( nextMonthStart.getFullYear() < todayDate.getFullYear() ) ) || 
                 (
                    ( nextMonthEnd.getMonth() < todayDate.getMonth() ) ||
                    ( nextMonthEnd.getFullYear() < todayDate.getFullYear() )
                 )
        ){

            // this.props.onRemoveHeaderTitle();
            return {

                err: {

                    errorTx: 'Intervalul de date este vechi.',
                    errorFunc: this.props.onRemoveHeaderTitle()
                },

                data: null
            };
        }
        else{

            const TOKEN_CONT = new LocalStorage().GetAccountToken();

            return{

                err: null,
                data: {

                    dataStart: nextMonthStart,
                    dataEnd: nextMonthEnd,
                    accountTk: `account_tk = '${TOKEN_CONT}'`
                }
            }
        }
    }

    onSelectUser = (user) => {

        this.setState({

            member: user
        });
    }

    render(){

        return(

            <div
            >

                {/* <p 
                    style={{
                        margin: 0,
                        marginTop: 22,
                        fontSize: '16px',
                        fontWeight: '400',
                        color: '#455A64'
                    }}
                >
                    Selecteaza data si ora la care doresti sa adaugi acest eveniment in calendarul tau.

                </p> */}

            {
                this.state.abonament === 'anteprenor' ? (

                        <div
                            style={{
                                width: '100%',
                                // height: '300px',
                                // overflowY: 'auto'
                            }}
                        >
                            <p
                                style={{
                                    fontSize: 14,
                                    color: '#455A64',
                                    fontWeight: '500'
                                }}
                            >
                                Selectati un membru
                            </p>

                            <TeamMemberCard 
                                name = {new LocalStorage().GetUserData().accountData[0]['display_name']}
                                desc = {`Programare direct pe serviciul ${this.props.serviceDetails.name}`}
                                selected = {this.state.member === null}
                                avatarSrc = {this.state.avatar}
                                forSelect = {true}
                                onClick = {() => this.onSelectUser(null)}
                                style={{
                                    marginTop: '10px'
                                }}
                            />

                            {
                                this.state.members.map( (member, index) => {

                                    if(index > 0){

                                        return(

                                            <TeamMemberCard 
                                                key={`membru-${index}`}
                                                name = {member.nume}
                                                desc = {member.functie}
                                                selected = {this.state.member?.wrk_token === member.wrk_token}
                                                forSelect = {true}
                                                onClick = {() => this.onSelectUser(member)}
                                                style={{
                                                    marginTop: '10px'
                                                }}
                                            />
                                        );
                                    }
                                })
                            }

                        </div>

                    ) : null
                }

                <CalendarAndHour 
                    initDate = {this.initDate}
                    fetchParamteres = {this.fetchParamteres}
                    dayCal = {this.props.dayCal}
                    serviceDetails = {this.props.serviceDetails}
                    calendarEntryType = {this.props.breakDuration}
                    renderDashboardCalendar = {true}
                    onShowBreakAddSuccess = {this.props.onShowBreakAddSuccess}
                    onShowClientForm = {this.props.onShowClientForm}
                    member = {this.state.member}
                />
                 

            </div>
        )
    }
}