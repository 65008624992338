import React from 'react';

import LocalStorage from '../../providers/LocalStorage';
import Ws from '../../providers/Ws';
import Declarations from '../../providers/Declarations';

import { IoSettingsSharp } from 'react-icons/io5';
import { MdAlarm, MdPinDrop } from 'react-icons/md';
import { FaRegMoneyBillAlt } from 'react-icons/fa';
import { IoVideocam } from 'react-icons/io5';
import { FaSkype } from 'react-icons/fa';
import { ImLocation2 } from 'react-icons/im';
import { GoChevronDown, GoChevronUp } from 'react-icons/go';
import {ReactComponent as ZoomIcon} from '../../assets/zoom_icon.svg';
import {ReactComponent as LocIcon} from '../../assets/pin_drop.svg';

import cancelPresentation from '../../assets/cancel_presentation.svg';
import ButtonForm from '../../commons/ButtonForm';
import CanaryPlantBg from '../../assets/canary_plant_bg.png';
import GoogleMeetIcon from '../../assets/Google_Meet-Logo.wine.svg';
import SuccessSaveToast from '../../commons/SuccessSaveToast';

import {
    BrowserView,
    MobileView,

} from "react-device-detect";

import './Dashboard.css';
import { Link, Redirect } from 'react-router-dom';
import Utils from '../../providers/Utils';
import CopiedLinkPopUp from '../../commons/CopiedLinkPopUp';
import Masks from '../../providers/Masks';
import ErrorToast from '../../commons/ErrorToast';
import SharedSocialToast from '../../commons/SharedSocialToast';
import LocationDesc from '../../commons/LocationDesc';
import IconText from '../../commons/IconText';
import HourInterval from '../../commons/HourInterval';
import ServiceCard from '../../commons/ServiceCard';


export default class Servicii extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            listaServicii: [],
            listaServiciiDisabled: [],
            listaLocatiiInt: [],
            listaCulori: [],
            programe: [],
            programTk: '',
            programName: '',
            redirect: null,
            showDisabled: false,
            copiedLink: '',
            isLinkCardShown: false,
            showToastPopup: false,
            showToastPopupError: false,
            showToastSocial: false,
            actionTx: '',
            companyWebLink: '',
            serviceLink: ''
        }
    }

    WS = new Ws();
    UT = new Utils();
    M = new Masks();

    componentDidMount(){

        this.getServicii();
        this.getDateProgram();
        window.scrollTo(0,0);
    }

    componentDidUpdate(){

        if(this.props.updateServiceList === true){
            
            this.getServicii();
            this.props.onUpdateServiceList(false);
        }
    }

    componentWillUnmount(){

    }


    getServicii = async () => {

        const TOKEN_CONT = new LocalStorage().GetAccountToken();

        let selectServices = 
        `select * from services where account_tk = '${TOKEN_CONT}' and ifnull(deleted,0)!=1 and ifnull(draft,0)!=1`;

        let selectServicesDisabled = 
        `select * from services where account_tk = '${TOKEN_CONT}' and ifnull(deleted,0)=1 and ifnull(draft,0)!=1`;

        let _getServices = this.WS.sqlCommand(`select`, selectServices);
        let _getServicesDisabled = this.WS.sqlCommand(`select`, selectServicesDisabled);

        let [getServices, getServicesDisabled] = await Promise.all([_getServices, _getServicesDisabled])

        
        

        if(getServices.success && getServicesDisabled.success){

            const accData = await this.UT.getAccountAvAndName(false, TOKEN_CONT);

            this.setState({

                listaServicii: getServices.content,
                listaServiciiDisabled: getServicesDisabled.content,
                companyWebLink: accData['webLink']
            },
                () => {

                    this.getDetaliiLocatie(getServices.content, getServicesDisabled.content);
                    this.getCuloriServicii(getServices.content)
                }
            )
            

            

        }
        else{

            this.setState({
                showToastPopupError: true,
                actionTx: 'Eroare preluare date serviciu.'
            });
            
            throw new Error('eroare server response:  ' + getServices.message + '\n' + getServicesDisabled.message)
        }
    }

    getDateProgram = async () => {

        const TOKEN_CONT = new LocalStorage().GetAccountToken();

        let q1 = `select * from programs where account_tk = '${TOKEN_CONT}' and ifnull(sters,0)!=1`;

        let _programs = this.WS.sqlCommand(`select`, q1);
        
        let [programs] = await Promise.all([_programs]);

        if(programs.success){

            if(programs.content.length > 0){

                this.setState({

                    programe: programs.content,
                    programName: programs.content[0]['name'] || '',
                    programTk: programs.content[0]['token'] || '',
                })
            }

            
        }
        else{

            this.setState({
                showToastPopupError: true,
                actionTx: 'Eroare preluare programe.'
            });
            throw new Error('eroare server response:  ' + programs.message)
        }
    }

    getDetaliiLocatie = (getServices, getServicesDisabled) => {
        
        const listaLocatii = new Declarations().listaLocatiiIntalnire;
        const locationsCombined = [...getServices, ...getServicesDisabled]
        const listaLocatiiNoua = [];
        // let listaLocatiiNoua = [];

        for(let i = 0; i < locationsCombined.length; i++){

            let found = false;
            let index = 0;

            for(let j = 0; j < listaLocatii.length; j++){

                if(locationsCombined[i].location === listaLocatii[j].id){

                    found = true;
                    index = j;

                }
        
            }

            if(found){

                listaLocatiiNoua.push({
                    
                    order: locationsCombined[i].id,
                    ...listaLocatii[index]
                });

            }
        }

        this.setState({

            listaLocatiiInt: listaLocatiiNoua
        })

        
    }

    getCuloriServicii = (getServices) => {

        const listaCuloriArr = new Declarations().listaCulori;
        let listaCuloriNoua = [];

        for(let i = 0; i < getServices.length; i++){

            let found = false;
            let index = 0;

            for(let j = 0; j < listaCuloriArr.length; j++){

                if(getServices[i].color === listaCuloriArr[j].val){

                    found = true;
                    index = j;

                }

                // 
        
            }

            if(found){

                listaCuloriNoua.push({
                    
                    order: getServices[i].id,
                    ...listaCuloriArr[index]
                });
                
            }
        }

        this.setState({

            listaCulori: listaCuloriNoua
        })
    }

    onServiciuClick = (tk, name ,time) => {

        let path = name;
        let durata = '';
        const listaDurata = new Declarations().listaDurata;

        for(let i = 0; i < listaDurata.length; i++){

            if(+listaDurata[i].val === +time){

                durata = listaDurata[i].tx;
            }
        } 

        this.props.goDetaliiServiciu(tk, path, durata)
    }

    createServiceMobile = async () => {

        const token = this.UT.generateToken(); //used only when creating a service
        const TOKEN_CONT = new LocalStorage().GetAccountToken();

        let dataStartCreation = new Date();
        dataStartCreation.setHours(0,0,0,0);

        let createServiceQuery = `insert into services(token, account_tk, 
            break_after, break_before, color, dispo_nr_zile, dispo_tip_zile,
            draft, duration, has_cancel_policy, has_recall, location, 
            price, program_tk, recall_nr_zile, recall_tip_zile, review_cere,
            schedule_descr, dispo_data_start

            ) 
            
            select '${token}', '${TOKEN_CONT}',
            15, 0, 'red', 30, 'work-days', 
            1, 60, 0, 0, 'office',
            0, '${this.state.programTk}', 20, 'calendar-days', 0,
            '${this.state.programName}', '${this.M.specificDateToDateField(dataStartCreation)}'

            `
        

        let createService = await this.WS.sqlCommand(`insert`, createServiceQuery);
        

        if(createService.success){

            let link = '/ca/dashboard/cab/msv/'+token
            // alert('Serviciu creat cu succes!')
            this.setState({

                redirect: link
            })
        }
        else {

            this.setState({
                showToastPopupError: true,
                actionTx: 'Eroare creare serviciu nou.'
            });

            throw new Error('Eroare Server Response:  ' + createService.message)
        }

    }

    onShowDisabled = () =>{

        this.setState({
            showDisabled: !this.state.showDisabled
        })
    }

    onEnableService = async (token) =>{

        
        let eq = `update services set deleted = 0
                    where token = '${token}'
        `

        

        let disablerviciu = await this.WS.sqlCommand(`update`, eq);

        if(disablerviciu.success){

            // alert("Serviciu Reactivat cu Succes!");
            // window.location.reload();
            this.setState({

                showToastPopup: true,
                actionTx: 'a fost reactivat cu succes!'
            })
            await this.getServicii();
        }
        else{

            this.setState({
                showToastPopupError: true,
                actionTx: 'Eroare reactivare serviciu.'
            });
            throw new Error('Eroare Server Response:  ' + disablerviciu.message)
        }
    }

    onCopyLinkToClipBoard = async (serviceLink) => {
        
        const linkRes = await this.UT.onCopyLinkToClipBoard(serviceLink);

        if(linkRes.err !== null){

            this.setState({
                showToastPopupError: true,
                actionTx: linkRes.err
            });
        }
        else{

            this.setState({
                copiedLink: linkRes.data,
                isLinkCardShown: true
            }); 
        }
    }

    onLinkCardClose = (close) => {

        this.setState({

            isLinkCardShown: close
        })
    }

    onClickViewPage = async (serviceLink) => {

        const viewPageRes = await this.UT.onClickViewPage(serviceLink);  

        if(viewPageRes.err !== null){

            this.setState({
                showToastPopupError: true,
                actionTx: viewPageRes.err
            });
            
            
        }
        else{

            window.open(viewPageRes.data);
        }
    }

    onClosePopup = (type) => {

        this.setState({

            [type]: false
        });
    }

    onShowToastSocial = (webLink) => {

        this.setState({

            serviceLink: webLink,
            showToastSocial: true
        });
    }
    

    render(){

        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        
        return(

            <div className="wrapper-dashboard" style={{margin: '10px auto'}}>
                
                {
                    this.state.showToastSocial && (

                        <SharedSocialToast 
                            onClosePopup = {this.onClosePopup}
                            company = {this.state.companyWebLink}
                            webLink = {this.state.serviceLink}
                        />
                    )
                }

                

                {
                    this.state.showToastPopupError && (

                        <ErrorToast 
                            action={this.state.actionTx}
                            onClosePopup = {this.onClosePopup}
                        />
                    )
                }

                {
                    this.state.showToastPopup && (

                        <SuccessSaveToast 
                            onClosePopup = {this.onClosePopup}
                            saveName = 'Serviciul'
                            action = {this.state.actionTx}
                        />
                    )
                }

                {
                    this.state.isLinkCardShown &&
                    <CopiedLinkPopUp 
                        link={this.state.copiedLink}
                        onClosePopup = {this.onLinkCardClose}
                    />
                }
                
                <BrowserView>

                    <div 
                        className="row services-container"
                    >

                    {

                        this.state.listaServicii.map(svEl => {

                            return(
                                <div 
                                    key={'services-id-' + svEl['id']}
                                    className="col-s-12 col-m-6 col-l-4"
                                >
                                    
                                    <ServiceCard 
                                        isDisabled = {false}
                                        serviceElem = {svEl}
                                        onEnableService = {this.onEnableService}
                                        listaCulori = {this.state.listaCulori}
                                        onServiciuClick = {this.onServiciuClick}
                                        onCopyLinkToClipBoard = {this.onCopyLinkToClipBoard}
                                        onShowToastSocial = {this.onShowToastSocial}
                                        onClickViewPage = {this.onClickViewPage}
                                    /> 
            

                                </div>

                            
            
                            );
                        })

                    }

                    </div>

                    {
                        this.state.listaServiciiDisabled.length > 0 && (

                            <h3 
                                className="title"
                                style={{
                                    textAlign: 'left',
                                    margin: 0,
                                    marginTop: 20,
                                    cursor: 'pointer'
                                }}
                                onClick={this.onShowDisabled}
                            >
                                
                                <p 
                                    style={{
                                            display: 'inline-block', 
                                            position: 'relative', 
                                            top: 3, 
                                            left: 0, 
                                            margin: 0,
                                            color: 'rgb(255, 199, 39)'
                                            
                                        }}
                                >
                                    <img src={cancelPresentation} alt="Servicii Sterse Icon" />

                                </p>

                                <p 
                                    style={{
                                        display: 'inline-block', 
                                        position: 'relative', 
                                        left: 10,
                                        margin: 0,
                                        color: '#455A64',
                                        fontWeight: '500',
                                        fontSize: '16px'
                                    }}
                                >
                                    Servicii Sterse
                                </p>

                                <p 
                                    style={{
                                        display: 'inline-block', 
                                        position: 'relative', 
                                        top: 5,
                                        left: 20,
                                        margin: 0,
                                        color: '#455A64',
                                        fontWeight: '500',
                                    }}
                                >
                                    {this.state.showDisabled && <GoChevronUp />}
                                    {!this.state.showDisabled && <GoChevronDown />}
                                </p>
                                
                            </h3>
                        )
                    }

                    {
                        this.state.showDisabled &&
                        
                        <div 
                            className="row services-container"
                            style={{

                                paddingBottom: 140
                            }}
                        >

                        {

                            this.state.listaServiciiDisabled.map(svEl => {

                                return(
                                    <div 
                                        key={'services-id-' + svEl['id']}
                                        className="col-s-12 col-m-6 col-l-4"
                                    >
                                        
                                        <ServiceCard 
                                            isDisabled = {true}
                                            serviceElem = {svEl}
                                            onEnableService = {this.onEnableService}
                                            listaCulori = {this.state.listaCulori}
                                        /> 
                

                                    </div>

                                
                
                                );
                            })

                        }

                        </div>
                    }


                    <div
                        style={{
                            width: '100%',
                            height: 300
                        }}
                    >
                        <img 
                            src={CanaryPlantBg} 
                            style={{
                                marginTop: 40,
                                width: '400px'
                            }}
                        />
                    </div>

                </BrowserView>

                <MobileView>

                    <div 
                        className="row services-container"
                    >

                    {

                        this.state.listaServicii.map(svEl => {

                            return(
                                <div 
                                    key={'services-id-' + svEl['id']}
                                    className="col-s-12 col-m-6 col-l-4"
                                >
                                    
                                    <ServiceCard 
                                        isDisabled = {false}
                                        serviceElem = {svEl}
                                        listaCulori = {this.state.listaCulori}
                                        onCopyLinkToClipBoard = {this.onCopyLinkToClipBoard}
                                        onShowToastSocial = {this.onShowToastSocial}
                                        onClickViewPage = {this.onClickViewPage}
                                    /> 

                                </div>

                            
            
                            );
                        })

                    }

                    </div>

                    <br />

                    <h3 
                        style={{
                            textAlign: 'center',
                            margin: 0,

                        }}
                        onClick={this.onShowDisabled}
                    >
                        
                        <p 
                            style={{
                                    display: 'inline-block', 
                                    position: 'relative', 
                                    top: 3, 
                                    left: 0, 
                                    margin: 0,
                                    color: 'rgb(255, 199, 39)'
                                    
                                }}
                        >
                            <img src={cancelPresentation} alt="Servicii Sterse Icon" />

                        </p>

                        <p 
                            style={{
                                display: 'inline-block', 
                                position: 'relative', 
                                left: 10,
                                margin: 0,
                                color: '#455A64',
                                fontWeight: '500',
                                fontSize: '16px'
                            }}
                        >
                            Servicii Sterse
                        </p>

                        <p 
                            style={{
                                display: 'inline-block', 
                                position: 'relative', 
                                top: 5,
                                left: 20,
                                margin: 0,
                                color: '#455A64',
                                fontWeight: '500',
                            }}
                        >
                            {this.state.showDisabled && <GoChevronUp />}
                            {!this.state.showDisabled && <GoChevronDown />}
                        </p>
                        
                    </h3>
                    {
                        this.state.showDisabled &&
                        
                    <div 
                        className="row services-container"
                    >

                    {

                        this.state.listaServiciiDisabled.map((svEl, idx) => {

                            return(
                                <div 
                                    key={'services-id-' + svEl['id']}
                                    className="col-s-12 col-m-6 col-l-4"
                                >
                                    
                                    <ServiceCard 
                                        isDisabled = {true}
                                        serviceElem = {svEl}
                                        onEnableService = {this.onEnableService}
                                        listaCulori = {this.state.listaCulori}
                                    />
            

                                </div>

                            );
                        })

                    }

                        </div>
                    }

                    <br />
                    <br />

                    <ButtonForm 
                        className="login-btn"
                        type="button"
                        tx="Creaza Serviciu Nou"
                        style={{
                            marginTop: 20,
                            marginBottom: 20,
                            fontSize: '14px'
                        }}
                        onClick={
                            this.createServiceMobile
                        }
                    />
                    

                </MobileView>

            </div>
        );
    }
}