import React from 'react';

import LocalStorage from '../../../providers/LocalStorage';

import { MobileView } from 'react-device-detect';

import { RiFolderReduceFill } from 'react-icons/ri';
import { GoCheck } from 'react-icons/go';

import MServiceNav from '../../../commons/MServiceNav';
import AuthInput from '../../../commons/AuthInput';
import Ws from '../../../providers/Ws';
import Utils from '../../../providers/Utils';

import MBirdAndPlantImage from '../../../assets/bird_and_plant_mobile.png';
import CopiedLinkPopUp from '../../../commons/CopiedLinkPopUp';
import SuccessSaveToast from '../../../commons/SuccessSaveToast';
import ErrorToast from '../../../commons/ErrorToast';
import SharedSocialToast from '../../../commons/SharedSocialToast';
import LinkPreview from '../../../commons/LinkPreview';
import InputValidation from '../../logic/InputValidation';
import ErrorMessage from '../../../commons/ErrorMessage';
import ServicesFuncs from '../../logic/ServicesFuncs';
import SvNameAndLink from '../../../commons/service/SvNameAndLink';
import Loader from '../../../commons/Loader';
import Declarations from '../../../providers/Declarations';

export default class MDenumServiciu extends React.Component{

    constructor(props){

        super(props)

        this.state = {

            detaliiServiciu: {},
           
            accountLink: '',
            
            canSave: false,
            
            isLinkCardShown: false,
            showToastPopup: false,
            showToastPopupError: false,
            actionTx: '',
            showToastSocial: false,
            eroareTx: '',

            nameStatus: false,
            linkStatus: false,
            triggerSave: false,
            isLoading: true
        }
    }

    WS = new Ws();
    UT = new Utils();
    IV = new InputValidation();
    SVF = new ServicesFuncs();

    componentData = new Declarations().serviceData['svNameAndLink'];

    componentStatus = {

        isNameAndLinkOk: true
    }

    componentDidMount(){

        if(this.props.match.params.tk.length > 10){

            this.getData();
        }

        

        window.scrollTo(0,0);
    }

    getData = async () =>{

        const TOKEN_CONT = new LocalStorage().GetAccountToken();

        let q1 = `select * from services where account_tk = '${TOKEN_CONT}' and token = '${this.props.match.params.tk}' `;

        let _dateServiciu = this.WS.sqlCommand(`select`, q1);

        let [dateServiciu] = await Promise.all([_dateServiciu]);

        if(dateServiciu.success){

            if(dateServiciu.content.length === 0){

                this.setState({

                    isLoading: false,
                    eroareTx: 'Serviciu inexistent.'
                });

                return;
            }

            if(+dateServiciu.content[0]['deleted'] === 1){

                this.setState({

                    isLoading: false,
                    eroareTx: 'Serviciul este dezactivat.'
                });

                return;
            }

            // alert('Ayaya!')

            // this.updateComponentsData(dateServiciu.content[0]);
            this.componentData = new Declarations().updateComponentsData(this.componentData, dateServiciu.content[0]);
            
            
            this.setState({
                detaliiServiciu: dateServiciu.content[0],
            },
                () => {
                    
                    this.setAccountLink();
                }
            )

            
        }
        else{

            this.setState({

                showToastPopupError: true,
                actionTx: 'Eroare preluare date server.'
            });


            throw new Error('eroare server response:  ' + dateServiciu.message)
        }
    }

    checkSaveButtonStatus = () => {

        let status = null;

        if(!this.state.nameStatus && !this.state.linkStatus){

            status = false;
        }
        else {

            status = true;
        }

        this.setState({

            canSave: status
        });
    }

    inputStatus = (status, statusName, inputName, data, isVerify = false) => {

        if(isVerify){

            this.componentStatus[statusName] = data;
        }
        else{

            this.componentData[inputName] = data;

            
    
            this.setState({
                
                [statusName]: status
            },
                () => {
    
                    this.checkSaveButtonStatus();
                }
            );
        }
    }

    saveToDb = async (result) => {

        const token = this.UT.generateToken(); //used only when creating a service
        const TOKEN_CONT = new LocalStorage().GetAccountToken();
        // let userData = await this.WS.getUserData()

         if (this.props.match.params.tk.length >= 10){

            // alert("Modific!")
            // let uq = `update services set  name = '${this.state.denumire}', web_link = '${this.state.link}',
            //                                 draft = 0
            //                                 where token = '${this.props.match.params.tk}'
            //                                 ` 

            // 

            let uq = this.SVF.onSaveServiceData(result, false, this.props.match.params.tk);
             
            

            let updateServiciu = await this.WS.sqlCommand(`update`, uq.data.substr(1, uq.data.length - 2));

            if(updateServiciu.success){

                // alert("Serviciu Modificat cu Succes!");
                this.setState({
                    canSave: false,
                    showToastPopup: true,
                    actionTx: 'au fost actualizate cu succes!',

                    nameStatus: false,
                    linkStatus: false,
                    triggerSave: false
                },
                
                    async () => {

                        await this.getData();
                    }
                );
            }
            else{

                this.setState({

                    showToastPopupError: true,
                    actionTx: 'Eroare actualizare denumire si link.'
                });

                throw new Error('Eroare Server Response:  ' + updateServiciu.message)
            }
        }
        
    }

    onCheckAndSave = async () => {

                
        if(this.componentStatus['isNameAndLinkOk']){

            const queryArr = new Declarations().generateQuerySet('service', this.componentData)['svNameAndLink']
            
            
            
            await this.saveToDb(queryArr);
        }
        else{

            this.setState({

                triggerSave: false
            });
        }
    }


    ///////////////////////////////////////////////////
    //                                               //
    //   Copy Link and View Page related functions   //
    //                                               //
    ///////////////////////////////////////////////////


    onCopyLinkToClipBoard = async (result) => {
        
        // const linkRes = await this.UT.onCopyLinkToClipBoard(this.state.link);

        if(result.err !== null){

            this.setState({
                showToastPopupError: true,
                actionTx: result.err
            });
        }
        else{

            this.setState({
                copiedLink: result.data,
                isLinkCardShown: true
            }); 
        }
    }

    onLinkCardClose = (close) => {

        this.setState({

            isLinkCardShown: close
        })
    }

    setAccountLink = async () => {

        const accLink = await this.UT.getAccountLink();

        

        this.setState({

            accountLink: accLink || '',
            isLoading: false,
        });
    }
    
    onClosePopup = (type) => {

        this.setState({

            [type]: false
        })
    }

    onShowToastSocial = () => {

        this.setState({

            showToastSocial: true
        });
    }

    showToastError = (errorTx) => {

        this.setState({

            showToastPopupError: true,
            actionTx: errorTx
        });
    }

    onTriggerSave = () => {

        this.setState({

            triggerSave: true
        },
            async () => {

                await this.onCheckAndSave()
            }
        );
    }

    render(){

        return(

            <MobileView>

                {
                    this.state.showToastSocial && (

                        <SharedSocialToast 
                            onClosePopup = {this.onClosePopup}
                            company = {this.state.accountLink}
                            webLink = {this.state.detaliiServiciu['web_link']}
                        />
                    )
                }

                {
                    this.state.showToastPopupError && (

                        <ErrorToast 
                            action={this.state.actionTx}
                            onClosePopup = {this.onClosePopup}
                        />
                    )
                }


                {
                    this.state.showToastPopup && (

                        <SuccessSaveToast 
                            onClosePopup = {this.onClosePopup}
                            saveName = 'Denumirea si link-ul serviciului'
                            action = {this.state.actionTx}
                        />
                    )
                }

                {
                    this.state.isLinkCardShown && (

                        <CopiedLinkPopUp 
                            link = {this.state.copiedLink}
                            onClosePopup = {this.onLinkCardClose}
                        />
                    )
                    
                }
                

                <div 
                    className="rootable-page"
                    style={{
                        position: 'relative',
                        minHeight: '100vh',
                        background: `url(${MBirdAndPlantImage}) bottom no-repeat`
                    }}
                >

                    <div
                        style={{
                            position: 'relative'
                        }} 
                    >

                        <MServiceNav 
                            icon={<RiFolderReduceFill />}
                            title='Denumire Serviciu si Link'
                            path={'/ca/dashboard/cab/msv/'+this.props.match.params.tk}
                        />

                        {
                            this.state.canSave &&
                            <GoCheck
                                className="m-add-service"
                                onClick={this.onTriggerSave}
                                
                                // onClick={this.onCheckAndSave}
                            />
                        }

                    </div>

                    {
                        this.state.isLoading && (

                            <Loader 
                                fullPage = {true}
                            />
                        )
                    }

                    {
                        !this.state.isLoading && (

                            <div
                                style={{
                                    padding: 20
                                }}
                            >

                                <div className="m-edit-s-card">

                                    {
                                        this.state.eroareTx.length > 0 && (

                                            <ErrorMessage 
                                            
                                                eroareTx = {this.state.eroareTx}
                                            />
                                        ) 
                                    }

                                    {
                                        /*
                                            http://localhost:3000/#/ca/dashboard/cab/msv/ijAlFNe3Zl88tSPXGINRgXiIjxAkoydo
                                        
                                        */
                                    }   

                                    {
                                        this.state.eroareTx.length === 0 && (

                                            <SvNameAndLink 
                                                name = {this.state.detaliiServiciu['name'] || ''}
                                                link = {this.state.detaliiServiciu['web_link'] || ''}
                                                token = {this.state.detaliiServiciu['token']}
                                                accountLink = {this.state.accountLink}
                                                inputStatus = {this.inputStatus}
                                                showToastError = {this.showToastError}
                                                onCopyLinkToClipBoard = {this.onCopyLinkToClipBoard}
                                                onShowToastSocial = {this.onShowToastSocial}
                                                triggerSave = {this.state.triggerSave}
                                                isRouted = {true}
                                            />
                                        )
                                    }
                        

                                </div>

                            </div>

                        )
                    }

                    
                    <div
                        style={{
                            width: '100%',
                            position: 'absolute',
                            left: 0,
                            bottom: -160
                        }}
                    >

                        {/* <img 
                            src={MBirdAndPlantImage}
                            alt="Bird and plant..."
                            style={{
                                position: 'relative',
                                display: 'block',
                                margin: 'auto'
                            }}
                        /> */}

                    </div>
                    
                </div>
            </MobileView>
        )
    }
}