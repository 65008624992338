import React from 'react';

import { ReactComponent as BirdLogo } from '../assets/Canary.svg';

export default class Loader extends React.Component{

    constructor(props){

        super(props);
    }

    render(){

        return(

            // <div class="loading-wrapper">

            //     <div class="bar one"></div>

            //     <div class="bar two"></div>

            //     <div class="bar three"></div>

            // </div>

            // <div 
            //     class="container"
            //     style={{
            //         position: this.props.fullPage ? 'fixed' : 'relative',
            //         height: this.props.fullPage ? '100%' : (this.props.containerH || '70vh'),
            //     }}
            // >

            //     <div class="loader">
            //         <div class="loader-shap"></div>
            //         <div class="loader-shap"></div>
            //         <div class="loader-shap"></div>
            //     </div>
                
            // </div>

            <div 
                class="container"
                style={{
                    position: this.props.fullPage ? 'fixed' : 'relative',
                    height: this.props.fullPage ? '100%' : (this.props.containerH || '70vh'),
                }}
            >

                <div class="circle-loader">

                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>

                </div>

            </div>
        );
    }
}