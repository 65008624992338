import React from 'react';
import { MobileView } from 'react-device-detect';

export default class MSwitch extends React.Component{

    constructor(props){

        super(props)
    }

    render(){

        return(
            <MobileView>
            <div className="m-edit-s-card">

                <div className="row">

                    <div className="col-s-8">

                        <p
                        style={{
                            color: '#455A64',
                            fontSize: '14px',
                            textAlign: 'left'
                        }} 
                        >
                            {this.props.title}
                        </p>

                    </div>

                    <div 
                        className="col-s-4"
                    >

                        <div 
                            className="form-control"
                            style={{
                                right: 15,
                                width: '42px',
                                height: '21px',
                                float: 'right'
                            }}
                        >
                            <div className="switch">
                                <input 
                                    type="checkbox" 
                                    id="toggleAll" 
                                    checked={this.props.checked}
                                    onChange={this.props.onChange}
                                />
                                <label for="toggleAll"></label>
                            </div>

                        </div>

                    </div>

                </div>

            </div>
            </MobileView>
        )
    }
}