import React from 'react';
import { Link } from 'react-router-dom';

import planaryLogo from '../../assets/planary-logo-badge.svg';
import ButtonForm from '../../commons/ButtonForm';
import CanaryImage  from '../../assets/canary_plant_bg.png';
import GirlImage from '../../assets/girl_standing.png';
import CanaryPlantImage from '../../assets/canary_besides_plant.png';

import {
    BrowserView,
    MobileView,

} from "react-device-detect";

export default class Welcome extends React.Component{

    constructor(props){

        super(props)
    }

    render(){

        return(

            <div 
                className="welcome-container"
                style={{
                    backgroundImage: `url(${CanaryImage})`,
                    backgroundSize: '320px',
                    backgroundPosition: 'bottom',
                    backgroundRepeat: 'no-repeat'
                }}
            >

                <div className="col-l-2 col-m-3 col-s-10">

                    <BrowserView>
                    
                    <div className="form-control">
                        <img src={planaryLogo} alt="Planary Logo" />
                    </div>

                    <div 
                        className="form-control"
                        style={{

                            marginTop: -20
                        }}
                    >
                        <h1
                            style={{
                                fontSize: '36px',
                                color: '#535353'
                            }}
                        >
                            Planary
                        </h1>

                    </div>

                    </BrowserView>

                    <MobileView>
                    
                    <div className="form-control">
                        <img 
                            src={planaryLogo} 
                            alt="Planary Logo" 
                            style={{
                                width: '132px',
                                height: '132px'
                            }}
                        />
                    </div>

                    <div 
                        className="form-control"
                        style={{

                            marginTop: -20
                        }}
                    >
                        <h1
                            style={{
                                fontSize: '30px',
                                color: '#535353'
                            }}
                        >
                            Planary
                        </h1>

                    </div>

                    </MobileView>

                    <div 
                        className="form-control"
                        style={{
                            marginTop: 40
                        }}
                    >
                    
                    <Link to='/login'>
                        <ButtonForm 
                            className="login-btn"
                            type="button"
                            tx="Contul Meu"
                        />
                    </Link>

                    </div>

                    <div className="form-control" style={{marginTop: 20}}>

                        <p className="no-account">Nu ai cont?</p>
                        <a href="/#/inregistrare/date/cont" className="signin-link">Creeaza unul nou</a>

                    </div>
{/* 
                    <MobileView>

                        <div
                            style={{
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                width: '100%',
                                zIndex: -1,
                                opacity: '0.7'
                            }}
                        >

                            <img
                                src={CanaryPlantImage}
                                alt="Canary Plant..."
                                style={{
                                    position: 'absolute',
                                    display: 'block',
                                    left: 0,
                                    bottom: 0
                                
                                }}
                            />

                            <img
                                src={GirlImage}
                                alt="Canary Plant..."
                                style={{
                                    position: 'absolute',
                                    display: 'block',
                                    right: 0,
                                    bottom: 0
                                }}
                            />

                        </div>

                    </MobileView>

                    <BrowserView>
                    
                        <div
                            style={{
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                width: '100%',
                            }}
                        >

                            <img
                                src={CanaryImage}
                                alt="Canary Plant..."
                                style={{
                                    display: 'block',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    width: '400px',
                                }}
                            />

                        </div>

                    </BrowserView> */}

                </div>

            </div>
        )
    }
}