import React from 'react';
import { BrowserView, MobileView } from 'react-device-detect';

export default class SumarInfoClient extends React.Component{

    constructor(props){

        super(props)
    }

    render(){

        return(

        <>
            <BrowserView>
            
                <p 
                    style={{
                        margin:0,
                        marginTop: 10,
                        fontSize: '16px'
                    }}
                >
                        Detaliile de conectare au fost trimise prin e-mail la adresa furnizata
                </p>

                <div 
                    className="client-details"
                    style={{
                        marginTop: 50,
                        paddingBottom: 40
                    }}
                >

                    <div className="row">

                        <div className="col-l-2 col-m-2">

                            <h3 
                                style={{
                                    margin: 0,
                                    fontSize: '16px',
                                    textAlign: 'left'
                                }}
                            >
                                Nume: 
                            </h3>
                            
                        </div>

                        <div className="col-l-10 col-m-10">

                            <p 
                                style={{
                                    margin: 0,
                                    fontSize: '16px',
                                    textAlign: 'left'
                                }}
                            >
                                {this.props.nume} {this.props.prenume}
                            </p>

                        </div>

                    </div>

                    <div
                        className="row"
                        style={{
                            marginTop: 10
                        }}
                    >

                        <div className="col-l-2 col-m-2">

                            <h3 
                                style={{
                                    margin: 0,
                                    fontSize: '16px',
                                    textAlign: 'left'
                                }}
                            >
                                Email: 
                            </h3>
                            
                        </div>

                        <div className="col-l-10 col-m-10">

                            <p 
                                style={{
                                    margin: 0,
                                    fontSize: '16px',
                                    textAlign: 'left',
                                    color: '#263238'
                                }}
                            >
                                {this.props.email}
                            </p>

                        </div>

                    </div>

                    <div
                        className="row"
                        style={{
                            marginTop: 10
                        }}
                    >

                        <div className="col-l-2 col-m-2">

                            <h3 
                                style={{
                                    margin: 0,
                                    fontSize: '16px',
                                    textAlign: 'left'
                                }}
                            >
                                Telefon:  
                            </h3>
                            
                        </div>

                        <div className="col-l-10 col-m-10">

                            <p 
                                style={{
                                    margin: 0,
                                    fontSize: '16px',
                                    textAlign: 'left',
                                }}
                            >
                                {this.props.telefon}
                            </p>

                        </div>

                    </div>

                    <div
                        className="row"
                        style={{
                            marginTop: 10
                        }}
                    >

                        <div className="col-l-2 col-m-2">

                            <h3 
                                style={{
                                    margin: 0,
                                    fontSize: '16px',
                                    textAlign: 'left'
                                }}
                            >
                                Detalii:  
                            </h3>
                            
                        </div>

                        <div className="col-l-10 col-m-10">

                            <p 
                                style={{
                                    margin: 0,
                                    fontSize: '16px',
                                    textAlign: 'left',
                                }}
                            >
                                {this.props.detalii || 'Nu ai oferit detalii referitoare la aceasta programare'}
                            </p>

                        </div>

                    </div>

                </div>
            
            
            </BrowserView>

            <MobileView>

                <h3
                    style={{

                        margin: 0,
                        color: '#455A64',
                        fontSize: 16
                    }}
                >
                    Datele Tale
                </h3>

                <br />

                
                <div className="row">

                    <div className="col-s-3">

                        <p
                            style={{
                                margin: 0,
                                textAlign: 'left',
                                fontSize: 16
                            }}
                        >
                            Nume:
                        </p>

                    </div>

                    <div className="col-s-9">

                    <p
                        style={{
                            margin: 0,
                            textAlign: 'left',
                            fontSize: 16
                        }}
                    >
                        <b>{this.props.nume} {this.props.prenume}</b>
                    </p>

                    </div>

                </div>

                <div 
                    className="row"
                    style={{
                        marginTop: 10
                    }}
                >

                    <div className="col-s-3">

                        <p
                            style={{
                                margin: 0,
                                textAlign: 'left',
                                fontSize: 16
                            }}
                        >
                            Email:
                        </p>

                    </div>

                    <div className="col-s-9">

                    <p
                        style={{
                            margin: 0,
                            textAlign: 'left',
                            fontSize: 16,
                            color: '#263238'
                        }}
                    >
                        <b>{this.props.email}</b>
                    </p>

                    </div>

                </div>


                <div 
                    className="row"
                    style={{
                        marginTop: 10
                    }}
                >

                    <div className="col-s-3">

                        <p
                            style={{
                                margin: 0,
                                textAlign: 'left',
                                fontSize: 16
                            }}
                        >
                            Telefon:
                        </p>

                    </div>

                    <div className="col-s-9">

                    <p
                        style={{
                            margin: 0,
                            textAlign: 'left',
                            fontSize: 16,
                        }}
                    >
                        <b>{this.props.telefon}</b>
                    </p>

                    </div>

                </div>

                <div 
                    className="row"
                    style={{
                        marginTop: 10
                    }}
                >

                    <div className="col-s-3">

                        <p
                            style={{
                                margin: 0,
                                textAlign: 'left',
                                fontSize: 16
                            }}
                        >
                            Detalii:
                        </p>

                    </div>

                    <div className="col-s-9">

                        <p
                            style={{
                                margin: 0,
                                textAlign: 'left',
                                fontSize: 16,
                            }}
                        >
                            <b>{this.props.detalii || 'Nu ai oferit detalii referitoare la aceasta programare.'}</b>
                        </p>

                    </div>

                </div>


            </MobileView>
        
        </>
        )
    }
}