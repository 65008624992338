import React from 'react';

import { MobileView } from 'react-device-detect';

import { MdAlarm, MdPayment, MdClear, MdStayPrimaryPortrait, MdThumbUp, MdThumbDown } from 'react-icons/md';
import { IoVideocam } from 'react-icons/io5';
import { FaSkype, FaRegMoneyBillAlt } from 'react-icons/fa';
import { ImLocation2 } from 'react-icons/im';
import { ImPlus } from 'react-icons/im';
import { AiFillCalendar, AiOutlineMail } from 'react-icons/ai';
import { ReactComponent  as GMeetIcon} from '../../../assets/Google_Meet-Logo.wine.svg';
import { ReactComponent  as ArticleIcon} from '../../../assets/articleIcon.svg';
import { ReactComponent as ZoomIcon } from '../../../assets/zoom_icon.svg';
import { ReactComponent as LocIcon } from '../../../assets/pin_drop.svg';

import CompanyAvatar from '../../../assets/foxStat.jpeg';

import ButtonForm from '../../../commons/ButtonForm';

import Declarations from '../../../providers/Declarations';
import Utils from '../../../providers/Utils';
import Masks from '../../../providers/Masks';
import Ws from '../../../providers/Ws';
import HourInterval from '../../../commons/HourInterval';
import LocationDesc from '../../../commons/LocationDesc';

export default class MAppointmentPopUp extends React.Component{

    constructor(props){

        super(props);

        this.state={

            lunaCurenta: '',
            ziCurenta: '',
            currentAppoint: {},
            currentAppointToken: '',
            currentServiceColor: '',
            appointLocation: '',
            meetingId: '',
            meetingPass: '',
            appointDateEnd: null
        }
    }

    WS = new Ws();
    M = new Masks();
    UT = new Utils();

    localData = {

        listaLocatii: new Declarations().listaLocatiiIntalnire,
        listaLuniAn: new Declarations().listaLuni,
        listaCulori: new Declarations().listaCulori
    }

    componentDidMount(){

        if(this.props.tokenAppointClicked){

            if(this.props.tokenAppointClicked.length > 10){

                this.getMeetingData()
            }
        }

        this.setLunaZiCurenta()
    }

    
    setLunaZiCurenta = () => {

        const lunaCurentaAppoint = this.localData.listaLuniAn.filter(llEl => llEl.idx === this.props.popupData.getMonth())
        
         this.setState({
             lunaCurenta: lunaCurentaAppoint[0].luna,
             ziCurenta: this.M.dayOfWeek(this.props.popupData.getDay()),
         })
     }

     getMeetingData = async () => {

        let selectAppointmentData = `select * from trc where token = '${this.props.tokenAppointClicked}'   `;
        let selectMeetingData = `select * from trc_links where trc_token = '${this.props.tokenAppointClicked}'    `;
        // 

        let _meetingData = this.WS.sqlCommand(`select`, selectMeetingData);
        let _appointmentData = this.WS.sqlCommand(`select`, selectAppointmentData);

        let [meetingData, appointmentData] = await Promise.all([_meetingData, _appointmentData])
        

        let meetingIdPlatform = "";

        

        let meetingPassPlatform = "";

        if(meetingData.content.length > 0 && (meetingData.content[0]['zoom_meeting_id'] !== null) ){

            meetingIdPlatform = meetingData.content[0]['zoom_meeting_id'].substr(0,3) + " " + meetingData.content[0]['zoom_meeting_id'].substr(3,4) 
            + " " + meetingData.content[0]['zoom_meeting_id'].substr(7,9);

            meetingPassPlatform = meetingData.content[0]['zoom_password'];
        }

        let selectedAppoint = appointmentData.content;
        
        const selectedAppointService = this.props.serviceList.filter(saEl => saEl.token === selectedAppoint[0]['service_tk'])
        const culoareServiciu = this.localData.listaCulori.filter(cEl => cEl.val === selectedAppointService[0].color)
 
        const locatieCurenta = this.localData.listaLocatii.filter(lEl => lEl.id === selectedAppoint[0].location);

        let tokenForPayment = this.props.tokenAppointClicked;

        if( (selectedAppoint[0]['replaned_trc_tk'] || '').length > 10 ){

            tokenForPayment = selectedAppoint[0]['replaned_trc_tk'];
        }

        const paymentTrc = this.props.payments.filter(pEl => {

            if( (pEl['trc_token'] === tokenForPayment) && (+pEl.succes === 1) ){

                return pEl;
            }
       });

       

       selectedAppoint = selectedAppoint.map(sEl => {

            return (

                {
                    ...sEl,
                    paymentSuccess: paymentTrc.length > 0 ? paymentTrc[0].succes : 0
                }
            )
       })


        if(meetingData.success && appointmentData.success){

            this.setState({

                currentAppoint: selectedAppoint[0],
                meetingId: meetingIdPlatform,
                meetingPass: meetingPassPlatform,
                currentServiceColor: culoareServiciu[0].col,
                appointLocation: locatieCurenta[0],
                appointDateEnd: this.UT.setDisponibility(selectedAppointService[0])
            });
        }
        else{

            throw new Error("Meeting error response: " + meetingData.message + " " + appointmentData.message);
        }
    }

    onCloseAppointmentPopUpMobile = () => {

        this.props.onCloseAppointmentPopUpMobile();
    }

    render(){

        return(

            <MobileView>

            <div 
                className="calendar-modal-container"
            >

                <div 
                    className="calendar-popup-card"
                    style={{
                        width: '100%',
                        // height: '320px',
                        padding: '30px 20px',
                        // overflowY: 'auto'
                        textAlign: 'left'
                    }}
                >   

                    <MdClear 
                        style={{
                            position: 'absolute',
                            top: 10,
                            right: 10,
                            fontSize: '24px',
                            color: '#323232',
                            cursor: 'pointer'
                        }}

                        onClick={this.onCloseAppointmentPopUpMobile}
                    />

                    {
                        this.state.currentAppoint && 
                        Object.keys(this.state.currentAppoint).length > 0 &&
                        (


                               <div>

                                    <h5
                                        style={{
                                            position: 'relative',
                                            left: '4px',
                                            margin: 0,
                                            fontSize: '14px',
                                            color: '#455A64'
                                        }}
                                    >
                                        {this.state.currentAppoint['clie_nume'] + ' ' + this.state.currentAppoint['clie_prenume']}
                                    </h5>

                                    <div
                                        // className="row"
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginTop: 8
                                        }}
                                    >

                                        <div 
                                            // className="col-s-1"
                                            style={{
                                                width: '22px',
                                                display: 'flex',
                                                justifyContent: 'center'
                                            }}
                                        >

                                            <div 
                                                                                            
                                                style={{
                                                    position: 'relative',
                                                    width: '14px',
                                                    height: '14px',
                                                    // left: '4px',
                                                    background: +this.state.currentAppoint['draft'] === 1 && this.state.currentAppoint['src'] === 'ca' ? '#455A64' : this.state.currentServiceColor,
                                                    borderRadius: '50%'
                                                }}
                                            >
                                                
                                            </div>

                                        </div>

                                        <div>

                                            <p
                                                style={{
                                                    position: 'relative',
                                                    margin: 0,
                                                    top: 0,
                                                    left: '10px',
                                                    textAlign: 'left',
                                                    fontSize: '14px'
                                                }} 
                                            >
                                                {this.state.currentAppoint['service_name']}
                                            </p>

                                        </div>

                                    </div>

                                    <HourInterval 
                                        zi = {this.state.ziCurenta}
                                        data = {this.props.popupData.getDate()}
                                        luna = {this.state.lunaCurenta}
                                        ora = {this.state.currentAppoint['ora_cal']}
                                        durata = {this.state.currentAppoint['duration']}
                                        txSize = '14px'
                                    />

                                    {   
                                            this.state.appointDateEnd &&
                                            this.state.appointDateEnd.getTime() !== new Date(2021, 0, 1).getTime ||
                                            this.state.appointDateEnd.getTime() > new Date().getTime() && (

                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        marginTop: '2px',
                                                        marginBottom: '2px',
                                                        width: '100%',
                                                    }}
                                                >
                                                    <p
                                                        style={{
                                                            position: 'relative',
                                                            left: '30px',
                                                            margin: 0,
                                                            fontSize: '14px',
                                                            fontWeight: '400',
                                                            color: '#D3766A',
                                                            
                                                        }}
                                                    >
                                                        Depasit
                                                    </p>
            
                                                </div>
                                            )
                                        } 

                                    <div 
                                        // className="row"
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >

                                        <div 
                                            //className="col-l-6 col-m-6 col-s-6"
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                width: '50%'
                                            }}
                                        >

                                            <div 
                                                // className="title"
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    width: '22px'
                                                }}
                                            >
                                                
                                                <p 
                                                    style={{
                                                            // display: 'inline-block', 
                                                            position: 'relative', 
                                                            top: 2, 
                                                            left: 0, 
                                                            margin: 0,
                                                            color: +this.state.currentAppoint.price > 0 ? '#A6A6A6' : '#31A81D',
                                                            fontSize: '22px'
                                                        }}
                                                >
                                                    <FaRegMoneyBillAlt />

                                                </p>
 
                                            </div>

                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <p 
                                                    style={{
                                                        // display: 'inline-block', 
                                                        position: 'relative', 
                                                        // top: -2,
                                                        left: '10px',
                                                        margin: 0,
                                                        color: +this.state.currentAppoint.price > 0 ? '#455A64' : '#31A81D',
                                                        fontWeight: '400',
                                                        fontSize: '14px'
                                                    }}
                                                >
                                                    {+this.state.currentAppoint.price > 0 ? this.state.currentAppoint.price + " Lei" : "Fara Tarif"} 
                                                </p>

                                            </div>

                                        </div>

                                        <div 
                                            // className="col-l-6 col-m-6 col-s-6"
                                            style={{

                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'flex-end',
                                                width: '50%'
                                            }}
                                        >

                                            <h3 
                                                className="title"
                                                style={{
                                                    paddingRight: '10px',
                                                    textAlign: 'right',
                                                    margin: 0
                                                }}
                                            >
                                                
                                                <p 
                                                    style={{
                                                            display: 'inline-block', 
                                                            position: 'relative', 
                                                            top: +this.state.currentAppoint.draft === 0 ? 3 : 5, 
                                                            left: 0, 
                                                            margin: 0,
                                                            color: +this.state.currentAppoint.draft === 0 ? '#31A81D' : '#D3766A',
                                                            fontSize: '22px'
                                                        }}
                                                >
                                                    {
                                                        +this.state.currentAppoint.draft === 0  && +this.state.currentAppoint.paymentSuccess === 0 && (

                                                            <MdThumbUp />
                                                        )
                                                    }

                                                    {
                                                        +this.state.currentAppoint.draft === 0 && +this.state.currentAppoint.paymentSuccess === 1 && (

                                                            <MdPayment />
                                                        )
                                                    }

                                                    {
                                                        +this.state.currentAppoint.draft === 1 && (

                                                            <MdThumbDown />
                                                        )
                                                    }

                                                </p>

                                                <p 
                                                    style={{
                                                        display: 'inline-block', 
                                                        position: 'relative', 
                                                        top: -2,
                                                        left: 10,
                                                        margin: 0,
                                                        color: +this.state.currentAppoint.draft === 0 ? '#31A81D' : '#D3766A',
                                                        fontWeight: '400',
                                                        fontSize: '14px'
                                                    }}
                                                >
                                                    { (+this.state.currentAppoint.draft === 0 && +this.state.currentAppoint.paymentSuccess === 0) ? "Confirmat" : (+this.state.currentAppoint.draft === 0 && +this.state.currentAppoint.paymentSuccess === 1 ) ? 'Platit' : "Neconfirmat"} 
                                                </p>
                                                
                                            </h3>

                                        </div>

                                    </div>

                                    <LocationDesc 
                                        location = {this.state.currentAppoint.location}
                                        locationDesc = {this.state.appointLocation.descClient}
                                        officeW = '24px' officeH = '24px'       
                                        skypeSize = '20px'                 
                                        txSize = '14px'
                                    />

                                    <a
                                        href={this.state.currentAppoint['host_meeting_link']}
                                        style={{
                                            display: 'block',
                                            position: 'relative',
                                            margin: 0,
                                            marginLeft: 32, 
                                            marginTop: 10, 
                                            fontSize: '12px',
                                            textDecoration: 'none',
                                            color: '#2577C2',
                                        }}
                                    >
                                        {this.state.currentAppoint['host_meeting_link']}
                                    </a>
                                        
                                    {
                                        this.state.currentAppoint.location === 'zoom' &&
                                        ( this.state.currentAppoint['host_meeting_link'] || '').length > 0 &&
                                        (
                                        <React.Fragment>
                                            <p
                                                style={{
                                                    position: 'relative',
                                                    margin: 0,
                                                    marginLeft: 32,
                                                    marginTop: 8,
                                                    textAlign: 'left',
                                                    color: '#2577C2',
                                                    fontSize: '12px',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                <u>
                                                    Meeting ID
                                                    {   
                                                        " " + this.state.meetingId
                                                    }
                                                    
                                                </u>
                                            </p>

                                            <p
                                                style={{
                                                    position: 'relative',
                                                    margin: 0,
                                                    marginLeft: 32,
                                                    marginTop: 4,
                                                    textAlign: 'left',
                                                    color: '#2577C2',
                                                    fontSize: '12px',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                <u>
                                                    Passcode:
                                                    {
                                                        " " + this.state.meetingPass 
                                                    }
                                                
                                                </u>
                                            </p>
                                            
                                        </React.Fragment>
                                        )
                                    }

                                    <div
                                        style={{
                                            marginTop: 20,
                                            marginBottom: 10,
                                            width: '100%',
                                            height: '1px',
                                            background: '#EBEBEB'
                                        }}
                                    >
                                    </div>

                                    <div 
                                        // className="row"
                                        style={{
                                            display: 'flex',
                                            // alignItems: 'center'
                                        }}
                                    >
                                        
                                        <div 
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                width: '22px'
                                            }}
                                        >

                                            <p 
                                                style={{
                                                        position: 'relative', 
                                                        top: 2, 
                                                        left: 0, 
                                                        margin: 0,
                                                        color: '#A6A6A6',
                                                        fontSize: '20px'
                                                    }}
                                            >
                                                <ArticleIcon 
                                                    className="article-icon"
                                                />  

                                            </p>

                                        </div>

                                        <div>

                                            <p 
                                                style={{
                                                    position: 'relative', 
                                                    top: 4,
                                                    left: '10px',
                                                    margin: 0,
                                                    color: '#455A64',
                                                    fontWeight: '400',
                                                    fontSize: '14px'
                                                }}
                                            >
                                                {this.state.currentAppoint['clie_detalii'] || 'n/a'}
                                            </p>

                                        </div>
                                        
                                    </div>

                                    <div 
                                        //className="row"
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >
                                        
                                        <div 
                                            // className="col-s-1"
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                width: '22px'
                                            }}
                                        >

                                            <p 
                                                style={{
                                                        position: 'relative', 
                                                        top: 2, 
                                                        left: 0, 
                                                        margin: 0,
                                                        color: '#A6A6A6',
                                                        fontSize: '20px'
                                                    }}
                                            >
                                                <AiOutlineMail />

                                            </p>

                                        </div>

                                        <div>

                                            <p 
                                                style={{
                                                    position: 'relative', 
                                                    // top: 4,
                                                    left: '10px',
                                                    margin: 0,
                                                    color: '#455A64',
                                                    fontWeight: '400',
                                                    fontSize: '14px'
                                                }}
                                            >
                                                {this.state.currentAppoint['clie_email']}
                                            </p>

                                        </div>
                                        
                                    </div>

                                    <div 
                                        // className="row"
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >
                                        
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                width: '22px'
                                            }}
                                        >

                                            <p 
                                                style={{
                                                        position: 'relative', 
                                                        top: 2, 
                                                        left: 0, 
                                                        margin: 0,
                                                        color: '#A6A6A6',
                                                        fontSize: '20px'
                                                    }}
                                            >
                                                <MdStayPrimaryPortrait />

                                            </p>

                                        </div>

                                        <div>

                                            <p 
                                                style={{
                                                    position: 'relative', 
                                                    // top: 4,
                                                    left: '10px',
                                                    margin: 0,
                                                    color: '#455A64',
                                                    fontWeight: '400',
                                                    fontSize: '14px',
                                                }}
                                            >
                                                {this.state.currentAppoint['clie_tf']}
                                            </p>

                                        </div>
                                        
                                    </div>

                                </div>
                        )
                    }

                    
                    
                </div>

            </div>

            </MobileView>
        )
    }
}