import React from 'react';
import { MobileView } from 'react-device-detect';

import Masks from '../../providers/Masks';
import Utils from '../../providers/Utils';
import Ws from '../../providers/Ws';

import ButtonForm from '../ButtonForm';
import DisplaySchedule from '../DisplaySchedule';
import SelectOthers from '../SelectOthers';
import SelectProgram from '../SelectProgram';

export default class SvSchedule extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            programe: this.props.programe,
            programAfisat: [],
            programTk: this.props.programTk,
            programTkTmp: this.props.programTk,
        };
    }

    WS = new Ws();
    M = new Masks();
    UT = new Utils();

    componentDidMount(){

        if(typeof this.props.programe !== 'undefined'){

            this.setListaProgram();
        }
    }

    componentDidUpdate(prevProps, _){

        if(prevProps.triggerSave !== this.props.triggerSave){

            this.onGenerateQuery();
        }

        if(prevProps['programTk'] !== this.props['programTk']){

            this.setState({

                programTkTmp: this.props.programTk
            },
                () => {

                    this.setListaProgram();
                }
            );
        }
    }

    createScheduleList = (schedule) => {

        let zile = [
            {
                zi_start: schedule.lu_start === null ? null : schedule.lu_start.substring(0,5),
                zi_end: schedule.lu_end === null ? null : schedule.lu_end.substring(0,5)
            },

            {
                zi_start: schedule.ma_start === null ? null : schedule.ma_start.substring(0,5),
                zi_end: schedule.ma_end === null ? null : schedule.ma_end.substring(0,5)
            },

            {
                zi_start: schedule.mi_start === null ? null : schedule.mi_start.substring(0,5),
                zi_end: schedule.mi_end === null ? null : schedule.mi_end.substring(0,5)
            },

            {
                zi_start: schedule.jo_start === null ? null : schedule.jo_start.substring(0,5),
                zi_end: schedule.jo_end === null ? null : schedule.jo_end.substring(0,5)
            },

            {
                zi_start: schedule.vi_start === null ? null : schedule.vi_start.substring(0,5),
                zi_end: schedule.vi_end === null ? null : schedule.vi_end.substring(0,5)
            },

            {
                zi_start: schedule.sa_start === null ? null : schedule.sa_start.substring(0,5),
                zi_end: schedule.sa_end === null ? null : schedule.sa_end.substring(0,5)
            },

            {
                zi_start: schedule.du_start === null ? null : schedule.du_start.substring(0,5),
                zi_end: schedule.du_end === null ? null : schedule.du_end.substring(0,5)
            }
        ]

        return zile;
    }

    setListaProgram = () => {

        let programCurent = this.props.programe.filter(pEl => pEl.token === this.state.programTk)

        let zile = this.createScheduleList(programCurent[0]);

        this.setState({

            programAfisat: zile
        });
    }

    onChangeProgram = (ev) => {

        let programCurent = this.state.programe.filter(pEl => pEl.token === ev.target.value)
        let zile = this.createScheduleList(programCurent[0]);
        let doSave = true;

        if(ev.target.value === this.state.programTkTmp){

            doSave = false;
        }

        this.setState({

            programTk: ev.target.value,
            programAfisat: zile
        },
        
            () => {

                this.props.inputStatus(doSave, 'programStatus', 'programTk', ev.target.value);
                this.props.inputStatus(doSave, 'programStatus', 'scheduleDescr', programCurent[0]['name']);
            }
        );
    }

    onGenerateQuery = () => {

        const scheduleName = this.state.programe.filter(schEl => schEl['token'] === this.state.programTk);

        const result = {
    
            data: [
                {
                    itemName: 'program_tk',
                    itemData: `'` + this.state.programTk + `'`
                },

                {
                    itemName: 'schedule_descr',
                    itemData: `'` + scheduleName[0]['name'] + `'`
                }
            ],
            err: null
        }

        // this.props.onCheckAndSave(result);
    }

    render(){

        return(
            
            this.state.programe && (

                <div>
        
                    <p 
                        style={{
                            margin: 0,
                            textAlign: 'left',
                            fontSize: this.props.txSize || '12px',
                            color: '#263238'
                        }}
                    >

                        Alege Programul Standard sau unul din Programele 
                        personalizate pe care le-ai creat

                    </p>

                    <div 
                        className="form-control"
                        style={{
                            marginTop: this.props.schBoxMargin || 10
                        }}
                    >

                        <SelectOthers
                            keyName = 'interval-program-'
                            label='Program'
                            list={this.state.programe.map(pEl => {

                                return {
                                    val: pEl['token'],
                                    tx: pEl['name']
                                }
                            })
                            
                            }
                            // programCurent={this.state.programTk}
                            onChange={this.onChangeProgram}
                            tipCurent={this.state.programTk}
                        />


                    </div>

                    <div
                        style={{
                            marginTop: this.props.schBoxMargin || 10
                        }}
                    >

                        <DisplaySchedule 
                            programAfisat = {this.state.programAfisat}
                            onShowEditSchedule = {this.props.onShowEditSchedule}
                        />

                    </div>

                    <MobileView>

                        <ButtonForm 
                            className="login-btn"
                            tx='Creaza un Program Nou'
                            style={{
                                marginTop: 20,
                                fontSize: '14px'
                            }}
                            onClick={

                                () => {

                                    this.props.history.push('/ca/dashboard/cab/programe');
                                }
                            }
                        />

                    </MobileView>

                </div>

            )
            
        );
    }
}