import React from 'react';

import { MobileView } from 'react-device-detect';

import { RiCalendar2Fill } from 'react-icons/ri';
import ErrorImage from '../../../../assets/Feeling sorry-cuate 1.png';
import LaptopImage from '../../../../assets/laptop_and_mug.png';

import MServiceNav from '../../../../commons/MServiceNav';

import Masks from '../../../../providers/Masks';
import Utils from '../../../../providers/Utils';
import Ws from '../../../../providers/Ws';
import Declarations from '../../../../providers/Declarations';

import CrAppoInfo from '../../create_appointment_popup/CrAppoInfo';
import AuthInput from '../../../../commons/AuthInput';
import { AiFillWarning } from 'react-icons/ai';
import ButtonForm from '../../../../commons/ButtonForm';
import ErrorMessage from '../../../../commons/ErrorMessage';
import LocalStorage from '../../../../providers/LocalStorage';
import ErrorToast from '../../../../commons/ErrorToast';
import ClientValidationFuncs from '../../../logic/ClientValidationFuncs';
import CalendarClientFuncs from '../../../logic/CalendarClientFuncs';
import ClientDataForm from '../../../../commons/client_appointments/ClientDataForm';

export default class MCrAppoCreate extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            appointDetails: {},
            eroareTx: '',
            locationName: '',
           
            showToastPopup: false,
            showToastPopupError: false,
            actionTx: '',

            path: ''
        }
    }

    WS = new Ws();
    M = new Masks();
    UT = new Utils();
    CVF = new ClientValidationFuncs();
    CCF = new CalendarClientFuncs();

    componentsData = new Declarations().destructureDataObject(new Declarations().clientData);

    componentsStatus = {
        
        isClientDataOk: true
    };

    componentDidMount(){

        this.getAppointmentDetails();
    }

    getAppointmentDetails = async () => {

        let selectAppointment = `select * from trc where token = '${this.props.match.params.tranToken}'  `;
        let _appointment =  this.WS.sqlCommand(`select`, selectAppointment);

        let screenQuery = `update trc set screen = 'client-formular-ca' where token = '${this.props.match.params.tranToken}' `;
        let _updateScreen = this.WS.sqlCommand(`update`, screenQuery);

        let [appointment, updateScreen] = await Promise.all( [_appointment, _updateScreen] );

        

        if(appointment.success && updateScreen.success){

            if(appointment.content.length === 0){

                this.setState({

                    eroareTx: 'Programarea accesata nu este disponibila'
                });

                return;
            }

            this.componentsData = new Declarations().updateComponentsData(this.componentsData, appointment.content[0], 'client');

            this.setState({

                appointDetails: appointment.content[0]
            },
                () => {

                    this.setLocationName(appointment.content[0])
                    this.setPath();
                }
            );
        }
        else{

            this.setState({

                eroareTx: 'Server-ul nu poate comunica cu client-ul.'
            });

            throw new Error("Error getting appointment data: " + appointment.message);
        }
    }

    setLocationName = (appointment) => {

        const locationNameAppoint = new Declarations().listaLocatiiIntalnire.filter(lEl => lEl.id === appointment.location);

        this.setState({

            locationName: locationNameAppoint[0].descClient
        });
    }

    setPath = async () => {

        const appointDate = this.M.sqlDateToJSDate(this.state.appointDetails['data_cal']);

        let dataStart = this.M.firstOrLastDayOfMonth(new Date(appointDate).getMonth(), new Date(appointDate).getFullYear(), false);
        let dataEnd = this.M.firstOrLastDayOfMonth(new Date(dataStart).getMonth(), new Date(dataStart).getFullYear(), true);

        let serviceWebLinkQuery = `select web_link from services where token = '${this.state.appointDetails['service_tk']}' `;
        let serviceWebLink = await this.WS.sqlCommand(`select`, serviceWebLinkQuery);

        if(serviceWebLink.success){

            const path = `/ca/dashboard/cab/adauga-in-calendar/${serviceWebLink.content[0]['web_link']}/{"dataStart":"${this.M.specificDateToDateField(dataStart)}", "dataEnd":"${this.M.specificDateToDateField(dataEnd)}"}`;

            this.setState({
    
                path: path
            });
        }
        else{

            this.setState({

                eroareTx: 'Server-ul nu poate comunica cu client-ul.'
            });
        }
    }

    // onConfirmClientData = async () => {

    //     const ACCTOKEN = new LocalStorage().GetAccountToken();

    //     const errorTxOverlap = await this.CVF.checkIfAppointIsOverlaping(this.state.appointDetails['data_cal'], this.state.appointDetails['ora_cal'], ACCTOKEN, true);

    //     if(errorTxOverlap.length > 0){

    //         this.setState({

    //             eroareTx: errorTxOverlap
    //         });

    //         return;
    //     }

    //     const clientNameArr = this.state.name.split(" ");
    //     const firstName = clientNameArr[0];
    //     let lastName = '';

    //     for(let i = 1; i < clientNameArr.length; i++){

    //         lastName += clientNameArr[i] + ' ';
    //     }

    //     lastName = lastName.substr(0, lastName.length - 1) 

    //     

    //     const updateTrcQuery = `update trc set 
    //                             clie_nume = '${firstName}',
    //                             clie_prenume = '${lastName}',
    //                             clie_email = '${this.state.email}',
    //                             clie_tf = '${this.state.phone}',
    //                             clie_detalii = '${this.state.details}'

    //                             where token = '${this.props.match.params.tranToken}'
    //     `

    //     const updateTransactions = await this.WS.sqlCommand(`update`, updateTrcQuery);

    //     if(updateTransactions.success){

    //         let ss = await this.WS.getData('/invite/create', {trcToken: this.props.tranToken});
    //         // this.createPayment();

    //         if(ss.success){

    //             this.props.history.push('/ca/dashboard/cab/adauga-programare/succes/'+this.props.match.params.tranToken);
    //         }
    //         else{

    //             // this.setState({

    //             //     eroareTx: 'Trimiterea mail-ului catre pacient a esuat!'
    //             // })

    //             this.setState({

    //                 showToastPopupError: true,
    //                 actionTx: 'Trimiterea mail-ului catre pacient a esuat!'
    //             });
    //         }
            
    //     }
    //     else{

    //         this.setState({

    //             showToastPopupError: true,
    //             actionTx: 'Server-ul nu a raspuns cererii.'
    //         });
    //         throw new Error("Server error response: ", updateTransactions.message)
    //     }
        
        
    // }

    inputStatus = (status, statusName, inputName, data, isVerify = false) => {

        if(isVerify){

            this.componentsStatus[statusName] = data;
        }
        else{

            this.componentsData[inputName] = data;
        }
    }

    onConfirmClientData = async (clientData) => {

        const ACCTOKEN = new LocalStorage().GetAccountToken();

        const errorTxOverlap = await this.CVF.checkIfAppointIsOverlaping(this.state.appointDetails['data_cal'], this.state.appointDetails['ora_cal'], ACCTOKEN, true);

        if(errorTxOverlap.length > 0){

            this.setState({

                eroareTx: errorTxOverlap
            });

            return;
        }
        const formResponse = await this.CCF.onSaveClientFormData(this.props.match.params.tranToken, clientData);

        if(formResponse.success){

            this.props.history.push('/ca/dashboard/cab/adauga-programare/succes/'+this.props.match.params.tranToken);
        }
        else{

            this.setState({

                eroareTx: formResponse.err
            });
        }
    }
  
 

    onCheckDataAndSave = async () => {

       const result = Object.values(this.componentsStatus).every(item => item === true);

        if(result){

            const queryArr = new Declarations().generateQuerySet('client', this.componentsData)['clientInfo'];

            

            await this.onConfirmClientData(queryArr);
        }
        else{

            this.setState({

                triggerSave: false
            });
        }
    }

    onTriggerSave = () => {

        this.setState({

            triggerSave: true
        },
            () => {

                this.onCheckDataAndSave();
            }
        
        );
    }



    render(){

        return(

            <MobileView>

                
            {
                this.state.showToastPopupError && (

                    <ErrorToast 
                        action={this.state.actionTx}
                        onClosePopup = {this.onClosePopup}
                    />
                )
            }

            <MServiceNav
                icon={<RiCalendar2Fill />}
                title='Adauga in calendar'
                path={this.state.path}
            />

            <div
                style={{
                    padding: '20px'
                }}
            >

                <div
                    className="m-create-appointment-card"
                    style={{
                        padding: '20px',
                        borderRadius: '10px',
                        textAlign: 'left'
                    }}
                >

                    {
                        this.state.eroareTx.length > 0 && (

                            <ErrorMessage 
                                eroareTx = {this.state.eroareTx}
                            />
                        )
                    }

                    {
                        this.state.eroareTx.length === 0 && (

                            <>
                                <div>
                                    <RiCalendar2Fill 
                                    
                                        style={{

                                            display: 'inline-block',
                                            position: 'relative',
                                            top: 4,
                                            margin: 0,
                                            fontSize: '20px',
                                            color: '#FFC727'
                                        }}
                                    />
                                    <p
                                        style={{
                                            display: 'inline-block',
                                            position: 'relative',
                                            margin: 0,
                                            fontSize: '14px',
                                            fontWeight: '500',
                                            color: '#455A64'
                                        }}
                                    >
                                        &nbsp; Adauga detaliile programarii
                                    </p>

                                </div>

                                <br />

                                <div
                                    style={{
                                        width: '100%',
                                        height: '1px',
                                        background: '#EBEBEB'
                                    }}
                                ></div>

                                <br />
    
                                <CrAppoInfo
                                    serviceName = {this.state.appointDetails['service_name']}
                                    duration = {this.state.appointDetails.duration}
                                    price = {this.state.appointDetails.price}
                                    location = {this.state.appointDetails.location}
                                    locationName = {this.state.locationName}
                                    date = {this.state.appointDetails['data_cal']}
                                    hour = { this.state.appointDetails['ora_cal'] }
                                    titleStyle = {{
                                        margin: 0,
                                        fontSize: '14px',
                                        fontWeight: '700',
                                        textAlign: 'center'
                                    }}
                                />

                                <br />

                                <div
                                    style={{
                                        width: '100%',
                                        height: '1px',
                                        background: '#EBEBEB'
                                    }}
                                ></div>

                                <br />

                                <p
                                    style={{
                                        margin: 0,
                                        textAlign: 'center',
                                        fontWeight: '700',
                                        fontSize: '16px',
                                        color: '#455A64'
                                    }}
                                >
                                    Datele Clientului
                                </p>

                                <br />

                                <ClientDataForm 
                                    tranData = {this.state.appointDetails}
                                    triggerSave = {this.state.triggerSave}
                                    inputStatus = {this.inputStatus}
                                />

                                <br />

                                <div
                                    
                                >
                                    <AiFillWarning 
                                        style={{
                                            display: 'inline-block',
                                            position: 'relative',
                                            top: 4,
                                            margin: 0,
                                            fontSize: '20px',
                                            color: '#EB9481'
                                        }}
                                    />

                                    <p
                                        style={{
                                            display: 'inline-block',
                                            position: 'relative',
                                            margin: 0,
                                            fontSize: '14px',
                                            fontWeight: '500',
                                            color: '#455A64'
                                        }}
                                    >
                                        &nbsp; Aceasta este o invitatie.
                                    </p>

                                </div>

                                <p
                                    style={{
                                        margin: 0,
                                        marginTop: 10,
                                        fontSize: '12px',
                                        fontWeight: '500',
                                        color: '#455A64'
                                    }}
                                >
                                    Clientul tau va primi o <u>invitatie</u> la aceasta programare, pe care trebuie sa o accepte si sa o finalizeze. 
                                    Clientul poate schimba data  si ora programarii, o poate refuza, sau o poate ignora.
                                </p>

                                <br />

                                <ButtonForm 
                                    tx='Programeaza'
                                    style={{
                                        background: '#F8BC05'
                                    }}
                                    onClick={this.onCheckDataAndSave}
                                />

                            </>
                        )
                    }
                    

                </div>

                <img 
                    src={LaptopImage}
                    alt="Calendar Image"
                    style={{
                        display: 'block',
                        margin: '30px auto',
                        width: '200px',
                    }}
                />

            </div>

            </MobileView>
        )
    }
}