import React from 'react';

export default class ConsentCheck extends React.Component{

    constructor(props){

        super(props);
    }

    render(){

        return(

            <div 
                className="terms-wrapper" 
                style={{
                    paddingTop: '16px',
                    paddingBottom: '12px'
                }}
            >

                <div 
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '8.33%'      
                    }}
                >

                    <input 
                        className={this.props.termsError ? "terms-checkbox-error" : "terms-checkbox" }
                        type="checkbox"
                        onClick={this.props.onAcceptTerms}
                    />
                    <span className="terms-checkmark"></span>

                </div>

                <div 
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '91.66%'
                    }}
                >

                    <a 
                        // href={this.props.termsLink || ''}
                        className={this.props.termsError ? "terms-text terms-error" : "terms-text"}
                        target="_blank"
                    >
                        {this.props.tx}
                    </a>

                </div>
                
            </div>
        );
    }
}