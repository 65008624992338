import React from 'react';
import { BrowserView, MobileView } from 'react-device-detect';

export default class DisplaySchedule extends React.Component{

    constructor(props){

        super(props);
    }

    render(){

        return(
            
        <>

        <BrowserView>
        
            <div 
                className="row schedule-card"
                style={{
                    textAlign: 'left'
                }}
            >

                <div 
                    className="col-l-6 col-m-6 col-s-6"
                    style={{
                        textAlign: 'left',

                        fontWeight: '500',

                    }}
                >

                    <p className="schd-tx">Luni</p>
                    <p className="schd-tx">Marti</p>
                    <p className="schd-tx">Miercuri</p>
                    <p className="schd-tx">Joi</p>
                    <p className="schd-tx">Vineri</p>
                    <p className="schd-tx">Sambata</p>
                    <p className="schd-tx">Duminica</p>
                    <div style={{height: 5}}></div>
                    <span 
                        style={{
                                
                            margin: 0,
                            marginTop: 5,
                            color: '#44A5FF',
                            cursor: 'pointer',
                            textAlign: 'left',
                            fontWeight: '400',
                            fontSize: '14px'
                        }}
                        onClick={this.props.onShowEditSchedule}
                    >
                        Editeaza Program
                    </span>

                </div>

                <div 
                    className="col-l-6 col-m-6 col-s-6"
                    style={{
                        textAlign: 'right',
                        fontWeight: '500'
                    }}
                >   

                    {   
                        this.props.programAfisat.map((pEl, idx) => {

                            if((pEl.zi_start === null || pEl.zi_end === null) ||
                                (pEl.zi_start.length <= 2 || pEl.zi_end.length <= 2)){

                                    return(
                                        <p 
                                            className="schd-tx"
                                            key={'schedule-'+idx}
                                            style={{color: '#FA8989'}}
                                        >
                                            Indisponibil
                                        </p>
                                    )
                                }
                                else{

                                    return(
                                        <p 
                                            key={'schedule-'+idx}
                                            className="schd-tx"
                                        >
                                            {pEl.zi_start}-{pEl.zi_end}
                                        </p>
                                    )
                                }
                        }, this)
                    }

                </div>

                

            </div>
        
        </BrowserView>

        <MobileView>
            <div 
                className="m-sched-card"
                style={{
                    marginTop: 10,
                }}
            >
                <div className="row">

                    <div 
                        className="col-l-6 col-m-6 col-s-6"
                        style={{
                            textAlign: 'left',
                            fontWeight: 500
                        }}
                    >
                        <p>Luni</p>
                        <p>Marti</p>
                        <p>Miercuri</p>
                        <p>Joi</p>
                        <p>Vineri</p>
                        <p>Sambata</p>
                        <p>Duminica</p>

                        <div style={{height: 5}}></div>
                        <span 
                            style={{
                                    
                                margin: 0,
                                marginTop: 5,
                                color: '#44A5FF',
                                cursor: 'pointer',
                                textAlign: 'left',
                                fontWeight: '400',
                                fontSize: '14px'
                            }}
                            onClick={

                                () => {

                                    this.props.history.push('/ca/dashboard/cab/program/'+this.state.programTk);
                                }
                            }
                        >
                            Editeaza Program
                        </span>

                    </div>

                    <div 
                        className="col-l-6 col-m-6 col-s-6"
                        style={{
                            textAlign: 'right',
                            fontWeight: 400
                        }}
                    >

                        {   
                            this.props.programAfisat.map((pEl, idx) => {

                                if((pEl.zi_start === null || pEl.zi_end === null) ||
                                    (pEl.zi_start.length <= 2 || pEl.zi_end.length <= 2)){

                                        return(
                                            <p 
                                                key={'schedule-'+idx}
                                                style={{color: '#FA8989'}}
                                            >
                                                Indisponibil
                                            </p>
                                        )
                                    }
                                    else{

                                        return(
                                            <p key={'schedule-'+idx}>{pEl.zi_start}-{pEl.zi_end}</p>
                                        )
                                    }
                            }, this)
                        }

                    </div>

                </div>

            </div>

        </MobileView>

        </>

        );
    }
}