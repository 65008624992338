import React from 'react';

import Masks from '../../../providers/Masks';
import Utils from '../../../providers/Utils';
import Ws from '../../../providers/Ws';
import ServicesFuncs from '../../logic/ServicesFuncs';
import Declarations from '../../../providers/Declarations';

import '../Register.css'

import UserDataInputs from '../../../commons/profile/UserDataInputs';
import AuthInput from '../../../commons/AuthInput';
import ButtonForm from '../../../commons/ButtonForm';
import RegisterHeader from '../RegisterHeader';

import { IoIosArrowForward } from 'react-icons/io';
import { BrowserView, MobileView } from 'react-device-detect';


import Image from '../../../assets/Businesswoman-cuate 1.png';
import CanaryPlantImage from '../../../assets/canary_plant_bg.png';
import MBirdAndPlantImage from '../../../assets/bird_and_plant_mobile.png';
import LocalStorage from '../../../providers/LocalStorage';
import EmailValidation from '../EmailValidation';
import EmailTemplatesDeclarations from '../../../providers/EmailTemplatesDeclarations';

export default class Account extends React.Component{

    constructor(props){

        super(props);
        this.state = {

            data: {

                display_name: '',
                web_link: '',
                email: '', 
                telefon: '',
            },

            parola: '',
            parolaErr: false,
            parolaValid: false,
            parolaErrTx: '',

            display_nameStatus: false,
            telefonStatus: false,
            emailStatus: false, 

            triggerSave: false,

            showEmailValidation: false
        };
    }

    WS = new Ws();
    UT = new Utils();
    M = new Masks();
    SF = new ServicesFuncs();

    componentsData = {

        email: new Declarations().profileData['utilizator']['email'],
        display_name: new Declarations().profileData['utilizator']['display_name'],
        telefon: new Declarations().profileData['utilizator']['telefon'],
        parola: ''
    }

    componentsStatus = {
        
        isUserDataOk: false,
    };

    componentDidMount(){

        // this.test();
    }

    // test = async () => {

    //     let q = `select * from users `;
    //     let res = await this.WS.sqlCommand(`select`, q);

    //     
    // }

    inputStatus = (status, statusName, inputName, data, isVerify = false) => {

        if(isVerify){

            this.componentsStatus[statusName] = data;
        }
        else{

            this.componentsData[inputName] = data;

            
    
            this.setState({
                
                [statusName]: status
            },
                () => {
    
                    // this.checkSaveButtonStatus();
                }
            );
        }
    }

    onCheckPassword = () => {

        if(this.state.parola.length > 3){

            return {

                parolaErr: false,
                parolaValid: true,
                parolaErrTx: ''
            }
        }
        else{

            const passErrorText = 'Parola prea scurta! (minim 3 caractere)'

            return {

                parolaErr: true,
                parolaValid: false,
                parolaErrTx: passErrorText
            };
        }
    }

    login = async (user, parola) => {
        let WS = new Ws()
        let addr = WS.serverAddress + "user/login/?apiKey=" + WS.appKey + "&email=" + user + "&pwd=" + parola

        

        let lr = await fetch(addr)
        let loginResponse = await lr.json()

        
        if (loginResponse.success) {

            // alert("succes login")
            new LocalStorage().SetUserData(loginResponse.content)

            // alert("local user data setata cu succes")
            

            document.location.href = "/inregistrare/cont/validare-email";
           
            // this.props.history.push('/ca');
        }
    }

    onSetDatePrincipaleAccount = async () => {
        
        const token = this.UT.generateToken();
        const abonament = 'gratuit';

        let queryAccount = `insert into accounts(token, telefon, display_name, cand_creat, abonament) 
                select
                '${token}', '${this.componentsData.telefon}', '${this.componentsData.display_name}',
                Now(), '${abonament}'
                `;

        let _accountData = this.WS.sqlCommand(`insert`, queryAccount);

        const userToken = this.UT.generateToken();
        let queryUser = `insert into users(nume, email, pwd_c, account_id, token)
                        select
                        '${this.componentsData.display_name}', '${this.componentsData.email}', '${this.componentsData.parola}', '${token}',
                            '${userToken}'
                        `;

        // 
        let _userData = this.WS.sqlCommand(`insert`, queryUser);

        let [ accountData, userData ] = await Promise.all([ _accountData, _userData ]);

        // 

        if(accountData.success && userData.success){

            // await this.login(this.componentsData.email, this.componentsData.parola);
            let linkValidare = '';
            if (window.location.hostname.indexOf('localhost') !== -1){

                linkValidare = `http://localhost:3000/#/inregistrare/cont/succes-validare-email/${userToken}`;
            } 
            else{

                linkValidare = `https://app.planary.ro/#/inregistrare/cont/succes-validare-email/${userToken}`;
            }
            
            const emailValidationBody = new EmailTemplatesDeclarations().onGenerateValideazaEmailTemplate(linkValidare);

            let emailRes = await this.UT.onGenerateMailTemplateAndSendToEmail(
                emailValidationBody, this.componentsData.email, `Planary - Validare email`,
            );

            if(emailRes.success){

                this.setState({

                    showEmailValidation: true
                });
            }
        }
        else{

            
        }
    }

    onCheckAndSave = async () => {

        const result = Object.values(this.componentsStatus).every(item => item === true);
        const passRes = this.onCheckPassword();

        this.componentsData.parola = this.state.parola;

        
        
        if(result && passRes.parolaValid){

            // const queryObject = new Declarations().generateQuerySet('profile', this.componentsData);
            // const queryArr = new Declarations().onGenerateQueryArray(queryObject);

            this.setState({

                parolaErr: passRes.parolaErr,
                parolaValid: passRes.parolaValid,
                parolaErrTx: passRes.parolaErrTx,
            },
                async () => {

                    await this.onSetDatePrincipaleAccount();
                    // alert("Proceeding to email validation!")
                }
            );
        }
        else{

            this.setState({

                triggerSave: false,
                parolaErr: passRes.parolaErr,
                parolaValid: passRes.parolaValid,
                parolaErrTx: passRes.parolaErrTx
            });
        }
    }

    onTriggerSave = () => {

        this.setState({

            triggerSave: true
        },
        
            () => {

                this.onCheckAndSave();
            }
        )
    }
    render(){

        return(

            <>

            {
                this.state.showEmailValidation ? (

                    <EmailValidation 
                        email = {this.componentsData.email}
                    />
                ) : (

                    <>

                    <BrowserView>

                    <div className='register-container-desktop'>
        
                        <RegisterHeader />
        
                        <div className='service-details-card' style={{marginTop: '20px', textAlign: 'left'}}>
        
                            <p className='register-tx register-title'>
                                Salutare!
                            </p>
        
                            <div className='row' style={{marginTop: '16px'}}>
        
                                <div className='col-l-6 col-m-6 col-s-6'>
        
                                    <p className='register-tx'>
                                        Bine ai venit la Planary!
                                    </p>
        
                                    <img
                                        className='register-image'
                                        src={Image}
                                        alt="Planary"
                                    />
        
                                </div>
        
                                <div className='col-l-6 col-m-6 col-s-6' style={{paddingLeft: '20px'}}>
        
                                    <p className='register-tx'>
                                        Pentru a crea un cont nou de utilizator completeaza datele tale mai jos.
                                    </p>
        
                                    <UserDataInputs 
                                        hideLink = {true}
                                        isAccountEmail = {true}
                                        data = {this.state.data}
                                        triggerSave = {this.state.triggerSave}
                                        inputStatus = {this.inputStatus}
                                        style={{
                                            marginTop: '30px'
                                        }}
                                    />
                                    <div 
                                        className="form-control"
                                        style={{
                                            marginTop: 10
                                        }}
                                    >
                                        <AuthInput 
                                            label='Parola'
                                            labelStyle={{
                                                fontSize: '10px',
                                                top: 6
                                            }}
                                            type='password'
                                            value={this.state.parola}
                                            onChange={(ev) => this.setState({parola: ev.target.value})}
                                            iserror={this.state.parolaErr}
                                            errormsg={this.state.parolaErrTx}
                                            verified={this.state.parolaValid}
                                            onFocus = {
                                                () => {
            
                                                    this.setState({
                                                        parolaErrTx: null
                                                    })
                                                }
                                            }
                                        />
                                    </div>
        
                                    <ButtonForm 
                                        type='button'
                                        tx={
                                            <>
                                                <p style={{display: 'inline-block', position: 'relative', top: '-2px', margin: 0}}>
                                                    Continua &nbsp;
                                                </p> 
                                                <IoIosArrowForward className='register-next-arrow'/>
                                            </>
                                        }
                                        
                                        style={{
                                            marginTop: '20px',
                                            width: 'auto',
                                            height: '56px',
                                            background: '#F8BC05',
                                            fontSize: '16px',
                                            padding: '0px 35px 0px 35px'
                                        }}
                                        onClick={
                                            () => {
        
                                                this.onTriggerSave()
                                            }
                                        }
                                    />
                                    
                                </div>
        
        
                            </div>
        
                        </div>
        
                        <img 
                            className='bottom-pg-img'
                            src={CanaryPlantImage}
                            alt = 'Planary...'
                            style={{
                                marginTop: '20px'
                            }}
                        />
        
                    </div>
        
                    </BrowserView>
        
                    <MobileView>
        
                        <div 
                            style={{
                                padding: '20px',
                                minHeight: '1100px',
                                background: `url(${MBirdAndPlantImage}) bottom no-repeat`
                            }}
                        >
                            
                            <RegisterHeader />
                            
                            <div className="m-edit-s-card" style={{marginTop: '20px', textAlign: 'left'}}>
                                
                                <p className='register-tx sm register-title bold'>
                                    Salutare!
                                </p>
        
                                <p className='register-tx sm register-title' style={{marginTop: '16px'}}>
                                    Bine ai venit la Planary! 
                                </p>
        
                                <img
                                    className='register-image'
                                    src={Image}
                                    alt="Planary"
                                />
        
                                <p className='register-tx sm register-title' style={{marginTop: '16px'}}>
                                    Pentru a crea un cont nou de utilizator completeaza datele tale mai jos.
                                </p>
        
                                <UserDataInputs 
                                    hideLink = {true}
                                    data = {this.state.data}
                                    triggerSave = {this.state.triggerSave}
                                    inputStatus = {this.inputStatus}
                                    style={{
                                        marginTop: '30px'
                                    }}
                                />
                                <div 
                                    className="form-control"
                                    style={{
                                        marginTop: 10
                                    }}
                                >
                                    <AuthInput 
                                        label='Parola'
                                        labelStyle={{
                                            fontSize: '10px',
                                            top: 6
                                        }}
                                        type='password'
                                        value={this.state.parola}
                                        onChange={(ev) => this.setState({parola: ev.target.value})}
                                        iserror={this.state.parolaErr}
                                        errormsg={this.state.parolaErrTx}
                                        verified={this.state.parolaValid}
                                        onFocus = {
                                            () => {
        
                                                this.setState({
                                                    parolaErrTx: null
                                                })
                                            }
                                        }
                                    />
                                </div>
        
                                <div 
                                    style={{
                                        textAlign: 'center'
                                    }}
                                >
                                    <ButtonForm
                                        type='button'
                                        tx={
                                            <>
                                                <p style={{display: 'inline-block', position: 'relative', top: '-2px', margin: 0}}>
                                                    Continua &nbsp;
                                                </p> 
                                                <IoIosArrowForward className='register-next-arrow'/>
                                            </>
                                        }
                                        
                                        style={{
                                            marginTop: '20px',
                                            width: '100%',
                                            height: '56px',
                                            background: '#F8BC05',
                                            fontSize: '16px',
                                            padding: '0px 35px 0px 35px'
                                        }}
                                        onClick={
                                            () => {
        
                                                this.onTriggerSave()
                                            }
                                        }
                                    />
                                </div>
        
                            </div>
        
                        </div>
        
                    </MobileView>

                    </>
                )
            }

            </>
        )
    }
}
