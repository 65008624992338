
export default class Masks {

    dataFriendlyFromSql = sqlDate => {
    
        let substractedDate = sqlDate.substring(0, 10)
        let sp = substractedDate.split('-')
        if (!sp) {
            throw new Error(`Data sql ${sqlDate} este in format incorect`)
        }

        let an = sp[0]
        let luna = sp[1]
        let ziua = sp[2]

        let ziuaCurenta = new Date();
        let anCurent = ziuaCurenta.getFullYear();
        let lunaCurenta = ziuaCurenta.getMonth();
        let dayCurent = ziuaCurenta.getDate();

        let dataFromSql = new Date(an, luna - 1, ziua)
        let dataActuala = new Date(anCurent, lunaCurenta, dayCurent)

        //

        if (dataFromSql.getTime() == dataActuala.getTime()) {
            return "Astazi " + dataFromSql.getDate() + " " + this.monthName(dataFromSql.getMonth()).substr(0,3)
        }
        else {
            let ieri = new Date(anCurent, lunaCurenta, dayCurent)
            ieri.setDate(ieri.getDate() - 1)

            //

            if (dataFromSql.getTime() == ieri.getTime()) {
                return "Ieri " + dataFromSql.getDate() + " " + this.monthName(dataFromSql.getMonth()).substr(0,3)
            }

            else {
                let maine = new Date(anCurent, lunaCurenta, dayCurent)
                maine.setDate(maine.getDate() + 1)

                //

                if (dataFromSql.getTime() == maine.getTime()) {
                    return "Maine " + dataFromSql.getDate() + " " + this.monthName(dataFromSql.getMonth()).substr(0,3)
                }
                else {
                    let luni = ['Ian', 'Feb', 'Mar', 'Apr', 'Mai', 'Iun', 'Iul', 'Aug', 'Sep', 'Oct', 'Noi', 'Dec']

                    return dataFromSql.getDate() + " " + luni[dataFromSql.getMonth()]
                }
            }
        }
    } // end func


    dataFriendly(d) {
        // 1878-02-09T00:00:00 = 9 Februarie 1878
        // 
        if (d) {

            let firstCut = d.split('T')
            let splitData = firstCut[0].split('-')

            let an = Number(splitData[0])

            let luna = splitData[1].substring(0, 1) === '0' ? splitData[1].substring(1, 2) : splitData[1]
            luna = Number(luna)

            let ziua = splitData[2].substring(0, 1) === '0' ? splitData[2].substring(1, 2) : splitData[2]
            ziua = Number(ziua)

            const luni = ['', 'Ianuarie', 'Februarie', 'Martie', 'Aprilie', 'Mai', 'Iunie', 'Iulie', 'August', 'Septembrie', 'Octombrie', 'Noiembrie', 'Decembrie']

            return `${ziua} ${luni[luna]} ${an}`
        }
        else {
            return null
        }
    }

    dateTimeFriendlyFromSql = dataFromSql => {
        let dataFriendly = this.dataFriendly(dataFromSql)

        let time = dataFromSql.split('T')
        if (!time[1]) {
            throw new Error("Time is not in the correct format !")
        }
        let fTime = time[1]

        return dataFriendly + " " + fTime.substring(0, 5)
    }

    curentDateToDateField = () => {
        // yyyy-MM-dd
        let cur = new Date()
        let an = cur.getFullYear()
        let luna = cur.getMonth() + 1
        let ziua = cur.getDate()

        let ziuaAsString = String(ziua).length == 1 ? `0${ziua}` : String(ziua)
        let lunaAsString = String(luna).length == 1 ? `0${luna}` : String(luna)

        return an + "-" + lunaAsString + "-" + ziuaAsString
    }

    specificDateToDateField = (dt) => {
        // yyyy-MM-dd
        let cur = new Date(dt)
        let an = cur.getFullYear()
        let luna = cur.getMonth() + 1
        let ziua = cur.getDate()

        let ziuaAsString = String(ziua).length == 1 ? `0${ziua}` : String(ziua)
        let lunaAsString = String(luna).length == 1 ? `0${luna}` : String(luna)

        return an + "-" + lunaAsString + "-" + ziuaAsString
    }

    specificDateToDateFieldDots = (dt) => {
        // FROM    yyyy-MM-dd    TO   dd.MM.yyyy
        let cur = new Date(dt)
        let an = cur.getFullYear()
        let luna = cur.getMonth() + 1
        let ziua = cur.getDate()

        let ziuaAsString = String(ziua).length == 1 ? `0${ziua}` : String(ziua)
        let lunaAsString = String(luna).length == 1 ? `0${luna}` : String(luna)

        return `${ziuaAsString}.${lunaAsString}.${an}`
    }

    addDaysToDateAndConvertToField = (dt, nrZile) => {

        var result = new Date(dt);
        result.setDate(result.getDate() + nrZile);

        return this.specificDateToDateField(result)
    }

    addDaysToDate = (dt, nrZile) => {

        var result = new Date(dt);
        result.setDate(result.getDate() + nrZile);

        return result
    }

    addHoursToDate = (dt, hours) => {
        var result = new Date(dt);
        result.setHours(result.getHours() + hours)

        return result
    }

    sqlDateTimeToDateField = sqlDate => {
        if (!sqlDate) {
            console.warn("Err: sql date is null ??")
            return null;
        }

        if (sqlDate.indexOf('T') == -1 || sqlDate.indexOf('-') == -1) {
            console.error("Err sql date nu este in format corect datetime")
            return null;
        }

        let sp = sqlDate.split('T')
        let ds = sp[0].split('-')

        let asDate = new Date(ds[0], ds[1] - 1, ds[2])

        return this.specificDateToDateField(asDate)
    }

    sqlDateToJSDate = sqlDate => {
    
        if (!sqlDate) {
            console.warn("Err: sql date is null ??")
            return null;
        }

        if (sqlDate.indexOf('-') == -1) {
            console.error("Err: sql date without - separator ??")
            return null;
        }

        // daca e datetime
        if (sqlDate.indexOf('T') == -1) {
            
            let ds = sqlDate.split('-')

            let asDate = new Date(ds[0], ds[1] - 1, ds[2])
            return asDate
        }
        else {

            let sp = sqlDate.split('T')
            let ds = sp[0].split('-')

            let asDate = new Date(ds[0], ds[1] - 1, ds[2])
            return asDate
        }

    }

    sqlTimeToSimpleFormat = (t) => {
        // vine 17:30:00
        if (!t) {
            return null
        }

        let sp = t.split(':')
        if (sp.length > 1) {
            return `${sp[0]}:${sp[1]}`
        }
        else {
            return t
        }
    }

    getCurrentTime = () => {
        // returneaza 12:45
        let hour = new Date().getHours()
        let minutes = new Date().getMinutes()
        return `${hour}:${minutes}`
    }

    addMonths = (date, months) => {

        let dx = new Date(date);
        var d = dx.getDate();

        date.setMonth(date.getMonth() + +months);
        if (date.getDate() != d) {
            date.setDate(0);
        }
        return date;
    }

    generateListaLuniFromInterval(dataStart, dataEnd, datesAreSqlFormat) {

        let dStart = datesAreSqlFormat ? this.sqlDateToJSDate(dataStart) : dataStart
        let dEnd = datesAreSqlFormat ? this.sqlDateToJSDate(dataEnd) : dataEnd

        if (dEnd < dStart) throw 'Data start nu poate fi mai mica decat data end'
        if (this.addMonths(dEnd, -1) < dStart) throw 'Data end trebuie sa fie cu o luna mai mare decat data start'

        let rez = []
        for (let i = 0; i < 120; i++) {

            rez.push({
                an: dEnd.getFullYear(),
                luna: dEnd.getMonth() + 1,
                displayMonth: this.sqlDateToMonth(this.specificDateToDateField(dEnd)),
            })

            dEnd = this.M.addMonths(dEnd, -1);

            if (dEnd < dStart) {
                break;
            }
        }

        return rez;
    }

    numberWithDots = (nr) => {
        return nr.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    dayOfWeek = (dayNr) => {

        let dayName = ''

        switch (String(dayNr)) {
            case "0":
                dayName = 'Duminica';
                break;
            case '1':
                dayName = 'Luni';
                break;
            case '2':
                dayName = 'Marti';
                break;
            case '3':
                dayName = 'Miercuri';
                break;
            case '4':
                dayName = 'Joi';
                break;
            case '5':
                dayName = 'Vineri';
                break;
            case '6':
                dayName = 'Sambata';
                break;
            default:
                dayName = 'fara zi';
        }

        return dayName;
    }

    monthName = (monthNr) => {

        let dayName = ''

        switch (String(monthNr)) {
            case "0":
                dayName = 'Ianuarie';
                break;
            case '1':
                dayName = 'Februarie';
                break;
            case '2':
                dayName = 'Martie';
                break;
            case '3':
                dayName = 'Aprilie';
                break;
            case '4':
                dayName = 'Mai';
                break;
            case '5':
                dayName = 'Iunie';
                break;
            case '6':
                dayName = 'Iulie';
                break;
            case '7':
                dayName = 'August';
                break;
            case '8':
                dayName = 'Septembrie';
                break;
            case '9':
                dayName = 'Octombrie';
                break;
            case '10':
                dayName = 'Noiembrie';
                break;
            case '11':
                dayName = 'Decembrie';
                break;
            default:
                dayName = '...';
        }

        return dayName;
    }

    compareDates = (dt1, dt2) => {
        return (
            dt1.getFullYear() === dt2.getFullYear() &&
            dt1.getMonth() === dt2.getMonth() &&
            dt1.getDate() === dt2.getDate()
        )
    }

    getTimeDifferenceBetweenStrings = (st1, st2) => {
        if (st1.indexOf(':') < 0 || st2.indexOf(":") < 0) {
            console.error("st1 or st2: time incorect format", st1, st2)
            return null
        }

        const sp1 = st1.split(':')
        const sp2 = st2.split(':')

        const dt1 = new Date(2000, 1, 1, Number(sp1[0]), Number(sp1[1]))
        const dt2 = new Date(2000, 1, 1, Number(sp2[0]), Number(sp2[1]))

        // 
        // 

        // const rez =  Math.floor(Math.abs(dt1 - dt2) / (60*60*1000) );
        const rez = (Math.abs(dt1 - dt2) / (60 * 60 * 1000)).toFixed(2);

        // 08.06.2021
        // adaugare... ce este dupa virgula in rez, reprezinta procentul dintr-o ora si nu minutele... asadar mergem mai departe....
        let ora = rez.split('.')[0]
        let procentMinute = rez.split('.')[1]
        let minute = Math.ceil((procentMinute / 100) * 60)

        let diferantaOra = `${ora}.${minute}`

        // 
        return diferantaOra;
    }

    // neterminata
    // getTimeSumBetweenString = (st1, st2) => {

    //     if (st1.indexOf(':') < 0 || st2.indexOf(":") < 0) {
    //         console.error("st1 or st2: time incorect format", st1, st2)
    //         return null
    //     }

    //     const sp1 = st1.split(':')
    //     const sp2 = st2.split(':')

    //     const dt1 = new Date(2000, 1, 1, Number(sp1[0]), Number(sp1[1]))
    //     const dt2 = new Date(2000, 1, 1, Number(sp2[0]), Number(sp2[1]))

    //     const rez = (Math.abs(dt1 - dt2) / (60 * 60 * 1000)).toFixed(2);
    // }

    addHoursToStringHour = (stringHour, hourUnits, dataCal = null) => {
        if (!stringHour) {
            console.warn("Err: sql date is null ??")
            return null;
        }

        if (stringHour.indexOf(':') == -1) {
            console.error("Err: hour without : : separator ??")
            return null;
        }

        const sp = stringHour.split(':')
        let dt2 = '';
        if (dataCal) {
            const jsDate = this.sqlDateToJSDate(dataCal);

            const dt1 = new Date(jsDate.getFullYear(), jsDate.getMonth(), jsDate.getDate(), Number(sp[0]), Number(sp[1]))
            dt2 = dt1.setTime(dt1.getTime() + hourUnits * 60 * 60 * 1000)

        } else {
            const dt1 = new Date(2000, 1, 1, Number(sp[0]), Number(sp[1]))
            dt2 = dt1.setTime(dt1.getTime() + hourUnits * 60 * 60 * 1000)
        }

        return dt2
    }

    getDaysDifferenceBetweenDates = (dt1, dt2) => {
        // 
        let cal = (Math.abs(dt1 - dt2) / (24 * 60 * 60 * 1000)) + 1
        // 
        return cal.toFixed(0);

    }

    formatareData = (jsDate, format) => {

        let zi = jsDate.getDate()
        zi = zi < 10 ? '0' + zi : String(zi)
        let luna = jsDate.getMonth() + 1
        luna = luna < 10 ? '0' + luna : String(luna)
        return format.replace("yyyy", jsDate.getFullYear()).replace('MM', luna).replace('dd', zi)

    }

    firstOrLastDayOfMonth = (month, year, last = false) => {
        const l = month
        const a = year

        let d = new Date(a, l, 1)

        if (!last) {
            return d
        } else {
            return this.addDaysToDate(this.addMonths(d, 1), -1)
        }
    }

    formatNumberToDB = (nr) => {

        if (!nr) {
            console.error('Masks - formatNumberToDB() - eroare numar primit')
            return null;
        }

        nr = String(nr)

        let isVirgula = nr.indexOf(",") === -1 ? false : true;
        let isPunct = nr.indexOf(".") === -1 ? false : true

        let newNr;
        if (isVirgula && isPunct && nr.indexOf(",") > nr.indexOf(".")) {

            newNr = nr.replaceAll(".", "").replace(",", ".") // ordinea conteaza

        } else if (isVirgula && isPunct) {

            newNr = nr.replaceAll(",", "")

        } else if (!isPunct && isVirgula) {

            newNr = nr.replace(",", ".")

        } else {

            newNr = nr

        }
        // 
        return Number(newNr);

    }

    formatNumber = (nr, separatorMiimi = '.', separatorZecimale = ',', zecimaleMandatory = true) => {
        // 1234567.15 =>  1.234.567,15
        // 
        let isNegative = false
        // START closure
        function f(intreg) {

            let miime = []
            let newString = ''

            for (let i = intreg.length - 1; i >= 0; i--) {

                miime.push(intreg[i])

                if (miime.length === 3) {

                    newString += miime.join("")

                    if (i !== 0) {
                        newString += separatorMiimi
                    }
                    miime = []
                }

                if (i === 0) {
                    newString += miime.join("")
                }

            }

            return newString
        }
        // END closure

        // daca primeste null / undefined returneaza 0
        if (nr!=0 && !nr) {
            console.error('Masks - formatNumber() - eroare numar primit')
            return 0;
        }

        // daca primeste un string, il intoarce tot pe acela
        if (typeof nr == 'string') {
            return nr
        }
        
        nr = String(nr);
        
        // verificam daca umarul este negativ, si ii stergem minusul, pentru a-l adauga la final
        if (nr && nr.indexOf('-') === 0) {
            isNegative = true
            nr = nr.replace("-","")
        }

        let isPunct = nr.indexOf(".") === -1 ? false : true
        let newString = ''

        if (isPunct) {
            // let nrPuncte = Math.floor(Number(intreg) / 3)

            let intreg = nr.split('.')[0]
            let zecimale = nr.split('.')[1]

            let _newStr = f(intreg).split("").reverse().join("")

            if (zecimale.length == 1) {
                zecimale += '0'
            } else if (zecimale.length === 0) {
                zecimale += '00'
            }

            if (isNegative) {
                newString = "-" + _newStr + separatorZecimale + zecimale
            } else{
                newString = _newStr + separatorZecimale + zecimale
            }

        } else {

            newString = f(nr).split("").reverse().join("");
            
            if (isNegative) {
                newString = "-" + newString
            } 

            if (zecimaleMandatory) {
                newString = newString + separatorZecimale + '00'
            }

        }

        return newString;

    }

    // Functia numarul 2 de formatare
    formatareNumere = (
        nr,
        decimalSeparator = ".",
        groupingSeparator = " ",
        nrDecimals = 2
    ) => {
        let tx = String(Number(nr));
        let txSplited = tx.split(".");
        let left = txSplited.length > 0 ? txSplited[0] : tx;
        let right = txSplited.length > 1 ? txSplited[1] : "";
        // 
        // 

        let newLeft = [];
        for (let i = left.length - 1; i >= 0; i--) {
            newLeft.push(left.charAt(i));
            if (i < left.length - 1 && (left.length - i) % 3 == 0) {
                newLeft.push(groupingSeparator);
            }
        }

        let newRight = right.substring(0, nrDecimals);

        if (nrDecimals == 0 || newRight.length == 0) {
            return `${[...newLeft].reverse().join("")}`;
        }
        return `${[...newLeft].reverse().join("")}${decimalSeparator}${newRight}`;
    };

    currentDateTimeforSQL = () => {
        //2021-07-01 18:21:52.593

        let c = new Date()
        return `${this.specificDateToDateField(c)} ${c.getHours()}:${c.getMinutes()}:00.000`

    }

}