import React from 'react';

import InputValidation from '../../module/logic/InputValidation';

import ProfileFuncs from '../../module/logic/ProfileFuncs';
import Masks from '../../providers/Masks';
import Utils from '../../providers/Utils';
import Ws from '../../providers/Ws';

import AuthInput from '../AuthInput';

export default class AddressDataInputs extends React.Component{

    constructor(props){

        super(props);

        this.state = {
            
            adresa: this.props.data['adresa'],
            oras: this.props.data['oras'],

            adresaTmp: this.props.data['adresa'],
            orasTmp: this.props.data['oras'],

            isOrasValid: false,
            isAdresaValid: false,

            adresaError: false,
            orasError: false,

            adresaErrorTx: '',
            orasErrorTx: '',
        };
    }

    WS = new Ws();
    M = new Masks();
    UT = new Utils();
    PF = new ProfileFuncs();
    IV = new InputValidation();

    componentDidMount(){


    }

    componentDidUpdate(prevProps, _){
        
        if(prevProps.triggerSave !== this.props.triggerSave){

            this.onCheckInputs();
        }

        if(prevProps['data'] !== this.props['data']){

            this.setState({

                adresaTmp: this.props.data['adresa'],
                orasTmp: this.props.data['oras'],
            });
        }
    }

    onSendInputStatus = () => {

        const result = {

            adresa: this.IV.onCheckCityOrAddress(this.state.adresa, 'Adresa este prea scurta sau contine caractere speciale.'),
            oras: this.IV.onCheckCityOrAddress(this.state.oras, 'Nume oras prea scurt sau contine caractere speciale.'),

        }

        if(result['adresa']['isOk'] && result['oras']['isOk'] ){

            this.props.inputStatus(true, 'isAddressDataOk', 'isAddressDataOk', true, true);
        }
        else{

            this.props.inputStatus(true, 'isAddressDataOk', 'isAddressDataOk', false, true);
        }
        
    }

    onCheckInputs = () => {

        const checkRes = {

            adresa: this.IV.onCheckCityOrAddress(this.state.adresa, 'Adresa este prea scurta sau contine caractere speciale.'),
            oras: this.IV.onCheckCityOrAddress(this.state.oras, 'Nume oras prea scurt sau contine caractere speciale.'),
        }

        this.setState({

            adresaError: checkRes['adresa']['error'],
            isAdresaValid: checkRes['adresa']['verified'],
            adresaErrorTx: checkRes['adresa']['errorTx'],

            isOrasValid: checkRes['oras']['verified'],
            orasError: checkRes['oras']['error'],
            orasErrorTx: checkRes['oras']['errorTx'],
        });
        
    }

    onModifyInput = (ev) => {

        let doSave = true;

        if(ev.target.value === this.state[ev.target.name+'Tmp']){

            doSave = false;
        }

        this.setState({

            [ev.target.name] : ev.target.value,
        },
        
            () => {

                this.props.inputStatus(doSave, ev.target.name+'Status', ev.target.name , ev.target.value);

                let duration = 500;
                clearTimeout(this.inputTimer);
                    this.inputTimer = setTimeout( async ()=>{
                        this.onSendInputStatus();
                }, duration);
            }
        );

        // 
        
    }

    render(){

        return(

            <div style={this.props.style}>
            
                {
                    !this.props.inline && (

                        <>

                            <div 
                                className="form-control"
                                style={{
                                    marginTop: 5
                                }}
                            >

                                <AuthInput 
                                    label='Adresa'
                                    labelStyle={{
                                        fontSize: '10px',
                                        top: 6
                                    }}
                                    value={this.state.adresa}
                                    name='adresa'
                                    onChange={this.onModifyInput}
                                    iserror={this.state.adresaError}
                                    errormsg={this.state.adresaErrorTx}
                                    verified={this.state.isAdresaValid}
                                    onFocus = {
                                        () => {

                                            this.setState({
                                                adresaErrorTx: false
                                            })
                                        }
                                    }
                                />

                            </div>

                            <div 
                                className="form-control"
                                style={{
                                    marginTop: 10
                                }}
                            >

                                <AuthInput 
                                    label='Oras'
                                    labelStyle={{
                                        fontSize: '10px',
                                        top: 6
                                    }}
                                    value={this.state.oras}
                                    name='oras'
                                    onChange={this.onModifyInput}
                                    iserror={this.state.orasError}
                                    errormsg={this.state.orasErrorTx}
                                    verified={this.state.isOrasValid}
                                    onFocus = {
                                        () => {

                                            this.setState({
                                                orasErrorTx: false
                                            })
                                        }
                                    }
                                />

                            </div>

                        </>
                    )
                
                }

                {
                    this.props.inline && (

                        <div className='row'>

                            <div 
                                className='col-l-6 col-m-6 col-s-6'
                                style={{
                                    paddingRight: '10px'
                                }}
                            >

                                <div 
                                    className="form-control"
                                    style={{
                                        marginTop: 5
                                    }}
                                >

                                    <AuthInput 
                                        label='Adresa'
                                        labelStyle={{
                                            fontSize: '10px',
                                            top: 6
                                        }}
                                        value={this.state.adresa}
                                        name='adresa'
                                        onChange={this.onModifyInput}
                                        iserror={this.state.adresaError}
                                        errormsg={this.state.adresaErrorTx}
                                        verified={this.state.isAdresaValid}
                                        onFocus = {
                                            () => {

                                                this.setState({
                                                    adresaErrorTx: false
                                                })
                                            }
                                        }
                                    />

                                </div>

                            </div>

                            <div 
                                className='col-l-6 col-m-6 col-s-6'
                                style={{
                                    paddingLeft: '10px'
                                }}
                            >

                                <div 
                                    className="form-control"
                                    style={{
                                        marginTop: 5
                                    }}
                                >

                                    <AuthInput 
                                        label='Oras'
                                        labelStyle={{
                                            fontSize: '10px',
                                            top: 6
                                        }}
                                        value={this.state.oras}
                                        name='oras'
                                        onChange={this.onModifyInput}
                                        iserror={this.state.orasError}
                                        errormsg={this.state.orasErrorTx}
                                        verified={this.state.isOrasValid}
                                        onFocus = {
                                            () => {

                                                this.setState({
                                                    orasErrorTx: false
                                                })
                                            }
                                        }
                                    />

                                </div>

                            </div>

                        </div>
                    )
                }

                <p
                    style={{
                        margin: 0,
                        marginTop: 10,
                        fontSize: '10px',
                        fontWeight: '400',
                        color: '#777777'
                    }}
                >
                    Locatie Google Maps
                </p>

                <iframe 
                    style={{
                        width: '100%',
                        height:'410px'
                    }} 
                    id="gmap_canvas" 
                    // src="https://maps.google.com/maps?q=2880%20Broadway,%20New%20York&t=&z=13&ie=UTF8&iwloc=&output=embed" 
                    src={`https://maps.google.com/maps?q=${this.state.adresa},%20${this.state.oras}&t=&z=13&ie=UTF8&iwloc=&output=embed`} 
                    frameBorder="0" 
                    scrolling="no"
                    marginHeight="0" 
                    marginWidth="0"
                >

                </iframe>
                
            </div>
        )
    }
}