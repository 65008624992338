import React from 'react';
import { FaAngleRight } from 'react-icons/fa';

export default class TeamMemberCard extends React.Component{

    constructor(props){

        super(props);
    }

    componentDidMount(){


    }

    render(){

        return(

            <div 
                className={this.props.selected ? "teamMember-card selected" : "teamMember-card"}

                onClick={this.props.onClick}
                
                style={this.props.style}
            >

                <div className="left">

                    <div className="member-avatar-wrapper">

                        <div className="member-avatar">
                            <img 
                                src = {this.props.avatarSrc || ''}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                }}
                            />
                        </div>

                    </div>

                    <div className="member-info">

                        <p
                            className="infoTx name"
                        >
                            {this.props.name}
                        </p>

                        <p
                            className="infoTx desc"
                        >
                            {this.props.desc}
                        </p>

                        <p
                            className="infoTx schedule"
                        >
                            {this.props.schedule}
                        </p>

                    </div>

                </div>

                <div className="right">

                    {
                        !this.props.forSelect ? (

                            <FaAngleRight 
                                className="teamMember-enter"
                            />
                        ) : null
                    }

                </div>

            </div>
        )
    }
}