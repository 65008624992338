import React from 'react';
import { BrowserView, isMobile, MobileView } from 'react-device-detect';
import { FaTrophy } from 'react-icons/fa';
import { GoCheck, GoVerified } from 'react-icons/go';
import { IoMdThumbsUp } from 'react-icons/io';
import { MdBusinessCenter, MdDone, MdSchool, MdSettingsInputComponent, MdWork } from 'react-icons/md';
import { RiLeafFill } from 'react-icons/ri';
import IntegrationsCard from '../../../commons/IntegrationsCard';
import MServiceNav from '../../../commons/MServiceNav';
import MBirdAndPlantImage from '../../../assets/bird_and_plant_mobile.png';
import ErrorMessage from '../../../commons/ErrorMessage';

export default class AccSubscription extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            canSave: false,
            benefitsListSub: [],
            currentSubscription: {},
            errorTx: ''
        };
    }

    types = ['free', 'basic', 'antrepenor', 'premium'];

    subscriptionDetails = {

        free: {

            title: 'Incercare Gratuita',
            price: 'Incercare gratuita',
            icon: <MdSchool 
                className='acc-sub-icon'
            />
        },

        basic:{

            title: 'Basic',
            price: '50 Lei',
            shortTx: ' / Luna',
            icon: <RiLeafFill 
                    className='acc-sub-icon'
                />
        },

        antrepenor: {

            title: 'Antrepenor',
            price: '70 Lei',
            shortTx: ' / Luna',
            icon: <MdBusinessCenter 
                    className='acc-sub-icon'
                />
        },

        premium: {

            title: 'Premium',
            price: 'Cere oferta',
            icon: <FaTrophy 
                    className='acc-sub-icon'
                />
        }
    }

    benefitsList = {

        free: [

            'Pana la 100 Programari / Luna',
            '1 Calendar',
            'Confirmari si Mesaje Reminder via eMail',
            'Management de Programari',
            // 'Client Self Service 24/7',
            'Programari din Website, Social media si alte resurse externe',
            'Support Live Chat'
        ],

        basic: [

            'Pana la 100 Programari / Luna',
            'Confirmari si Remindere via eMail',
            'Client Self Service 24/7',
            'Integrare Google Calendar',
            'Integrare Plati',
            'Support Live Chat',
        ],

        antrepenor: [

            'Pana la 700 Programari / Luna',
            'Adauga Echipa Ta',
            'Comunicari via eMail si SMS',
            'Client Self Service 24/7',
            'Intergrare Google Calendar',
            'Integrare Plati',
            'QR Code cu linkul tau.',
            'Suport Live Chat'
        ],

        premium: [

            'Programari Nelimitate',
            'Comunicari via eMail si SMS',
            'Membrii Echipei au Cont Propriu',
            'Procedura Recall',
            'Termeni de Anulare per Serviciu',
            'Integrare Plati',
            'Suport Live Chat si Video'
        ]
    };

    componentDidMount(){

        const type = this.onGetSubscriptionType()
        this.onLoadSubscriptionDetails(type);
    }

    componentDidUpdate(prevProps, _){

        if(prevProps['type'] !== this.props['type']){

            const type = this.onGetSubscriptionType()
            this.onLoadSubscriptionDetails(type);
        }
    }

    onGetSubscriptionType = () => {

        let type = '';

        if(isMobile){

            if(this.props.match.params.type){

                if( (this.props.match.params.type || '').length > 0){
    
                    type = this.props.match.params.type;
                }
            }
        }
        else{

            if(this.props.type){

                if( (this.props.type || '').length > 0){
    
                    type = this.props.type;
                }
            }
        }

        return type;
    }

    onLoadSubscriptionDetails = (type) => {

        const isTypeValid = this.types.filter(tEl => tEl === type);

        if(isTypeValid.length === 0){

            this.setState({

                errorTx: 'Tipul abonamentului este invalid!'
            });

            return;
        }
        else{

            this.setState({

                benefitsListSub: [...this.benefitsList[type]],
                currentSubscription: this.subscriptionDetails[type]
            },
                () => {
    
                }
            )
        }
    }

    onGetSubscriptionType = () => {

        
        let type = '';

        if(isMobile){

            if(this.props.match.params.type){

                if( (this.props.match.params.type || '').length > 0){
    
                    type = this.props.match.params.type;
                }
            }
        }
        else{

            if(this.props.type){

                if( (this.props.type || '').length > 0){
    
                    type = this.props.type;
                }
            }
        }

        return type;
    }

    onGenerateBenefitsText = () => {

        const type = this.onGetSubscriptionType();

        switch(type){

            case 'free':
                return 'Basic';

            case 'basic':
                return 'Antreprenor';

            case 'antrepenor':
                return 'Premium';

            default:
                return 'Basic';
        }
    } 

    onGenerateSubscriptionText = () => {

        const type = this.onGetSubscriptionType();

        switch(type){

            case 'free':
                return 'Incercarea Gratuita';

            case 'basic':
                return 'Abonamentul Basic';

            case 'antrepenor':
                return 'Abonamentul Antreprenor';

            case 'premium':
                return 'Abonamentul Premium'

            default:
                return 'Abonamentul Basic';
        }
    }

     onGenerateDataFacturareText = () => {

        const type = this.onGetSubscriptionType();
        
        if(type === 'free'){

            return {

                text: 'Contul gratuit expira la',
                data: '23 Aprilie 2021'
            }
        }
        else{

            return {

                text: 'Urmatoarea data de facturare',
                data: '23 Aprilie 2021'
            }
        }
    }

    render(){

        return(
            <>
                <BrowserView>
                
                    <div className='accsub-header'>

                        {/* <RiLeafFill 
                            className='acc-sub-icon'
                        /> */}

                        {this.state.currentSubscription['icon']}

                        <p className='acc-sub-tx'>
                            {
                                this.onGetSubscriptionType() === 'free' ? 
                                'Incercare Gratuita' : 
                                'Abonament'
                            }
                        </p>

                        <p className='acc-sub-tx title'>
                            {this.state.currentSubscription['title']}
                        </p>

                        <GoVerified 
                            style={{
                                marginTop: '16px',
                                color: '#31A81D',
                                fontSize: '18px'
                            }}
                        />

                        <p className='acc-sub-tx'>
                            Activ
                        </p>

                    </div>
                    
                    <br />

                    <div className='sub-info-section'>

                        <p className='tx' style={{fontWeight: '700'}}>
                            {/* {+this.state.currentSubscription['price'] ? 
                                `${this.state.currentSubscription['price']} Lei` : 
                                'Incercare Gratuita'
                            
                            }   */}
                            {this.state.currentSubscription['price']}
                             

                            <span style={{fontSize: '12px', fontWeight: '400'}}>{this.state.currentSubscription['shortTx']}</span>
                        </p>

                        <p className='tx' style={{marginTop: '8px'}}>
                            {this.onGenerateDataFacturareText().text}
                        </p>

                        <p className='tx' style={{marginTop: '8px', fontWeight: '700'}}>
                            {this.onGenerateDataFacturareText().data}
                        </p>

                        <br />

                        {
                            this.onGetSubscriptionType() !== 'free' ? (

                                <p 
                                    className='tx' 
                                    style={{color: '#44A5FF'}}
                                >
                                    Istoric Plati/Facturi
                                </p>
                            ) : null
                        }

                    </div>

                    <br />

                    <div className='sub-benefits-section'>

                        <p 
                            className='tx'
                            style={{
                                fontWeight: '500'
                            }}
                        >
                            Ce beneficii iti ofera <br />
                            {this.onGenerateSubscriptionText()}?
                        </p>

                        <br />

                        <div className='benefits-container'>

                            <div className='benefits-list'>

                                {
                                    this.state.benefitsListSub.length > 0 &&
                                    this.state.benefitsListSub.map( (bEl, idx) => {

                                        return (

                                            <div 
                                                className='element'
                                                key={`benefit-${idx}`
                                            }>
                                                <div className='icon'>
                                                    <MdDone 
                                                        style={{
                                                            color: '#31A81D'
                                                        }}
                                                    />
                                                </div>

                                                <div className='info-tx'>

                                                    <p className='tx'>

                                                        {bEl}

                                                    </p>

                                                </div>
                                            
                                            </div>
                                        )  
                                    }, this)
                                }

                            </div>

                        </div>

                        <br />

                        {
                            this.onGetSubscriptionType() !== 'premium' ? (

                                <p 
                                    className='tx' 
                                    style={{color: '#44A5FF'}}
                                >
                                    Obtine mai multe beneficii cu <br />
                                    abonamentul {this.onGenerateBenefitsText()}
                                </p>
                            ) : null
                        }

                    </div>
                
                </BrowserView>

                <MobileView>

                    <div style={{position: 'relative'}}>

                        <MServiceNav 
                            icon={<MdWork />}
                            title='Abonamentul Meu'
                            path={'/ca/dashboard/cab/abonamente'}
                        />

                        {
                            this.state.canSave &&
                            <GoCheck
                                className="m-add-service"
                                // onClick={this.saveToDb}
                            />
                        }

                    </div>

                    <div 
                        style={{
                            padding: '20px'
                        }}
                    >


                        <div 
                            className="m-edit-s-card"
                            style={{
                                textAlign: 'center'
                            }}
                        >

                            {
                                this.state.errorTx.length > 0 && (

                                    <ErrorMessage 
                                        eroareTx = {this.state.errorTx} 
                                    />
                                )
                            }

                            {
                                this.state.errorTx.length === 0 && (

                                    <>
                                    
                                        <div className='accsub-header'>

                                        {/* <RiLeafFill 
                                            className='acc-sub-icon'
                                        /> */}

                                        {this.state.currentSubscription['icon']}

                                        <p className='acc-sub-tx'>
                                            Abonament
                                        </p>

                                        <p className='acc-sub-tx title'>
                                            {this.state.currentSubscription['title']}
                                        </p>

                                        <GoVerified 
                                            style={{
                                                marginTop: '16px',
                                                color: '#31A81D',
                                                fontSize: '18px'
                                            }}
                                        />

                                        <p className='acc-sub-tx'>
                                            Activ
                                        </p>

                                    </div>
                                    
                                    <br />

                                    <div className='sub-info-section'>

                                        <p className='tx' style={{fontWeight: '700'}}>

                                            {+this.state.currentSubscription['price'] ? 
                                                `${this.state.currentSubscription['price']} Lei` : 
                                                'Incercare Gratuita'
                                            } 
                                            <span style={{fontSize: '12px', fontWeight: '400'}}>/ Luna</span>

                                        </p>

                                        <p className='tx' style={{marginTop: '8px'}}>
                                            {this.onGenerateDataFacturareText().text}
                                        </p>

                                        <p className='tx' style={{marginTop: '8px', fontWeight: '700'}}>
                                            {this.onGenerateDataFacturareText().data}
                                        </p>

                                        <br />

                                        {
                                            this.onGetSubscriptionType() !== 'free' ? (

                                                <p 
                                                    className='tx' 
                                                    style={{color: '#44A5FF'}}
                                                >
                                                    Istoric Plati/Facturi
                                                </p>
                                            ) : null
                                        }

                                    </div>

                                    <br />

                                    <div className='sub-benefits-section'>

                                        <p 
                                            className='tx'
                                            style={{
                                                fontWeight: '500'
                                            }}
                                        >
                                            Ce beneficii iti ofera <br />
                                            {this.onGenerateSubscriptionText()}?
                                        </p>

                                        <br />

                                        <div className='benefits-list'>

                                            {   
                                                this.state.benefitsListSub.length > 0 &&
                                                this.state.benefitsListSub.map( (bEl, idx) => {

                                                    return (

                                                        <div 
                                                            className='element'
                                                            key={`benefit-${idx}`
                                                        }>
                                                            <div className='icon'>
                                                                <MdDone 
                                                                    style={{
                                                                        color: '#31A81D'
                                                                    }}
                                                                />
                                                            </div>

                                                            <div className='info-tx'>

                                                                <p className='tx'>

                                                                    {bEl}

                                                                </p>

                                                            </div>
                                                        
                                                        </div>
                                                    )  
                                                })
                                            }

                                        </div>

                                        <br />

                                        {
                                            this.onGetSubscriptionType() !== 'premium' ? (

                                                <p 
                                                    className='tx' 
                                                    style={{color: '#44A5FF'}}
                                                >
                                                    Obtine mai multe beneficii cu <br />
                                                    abonamentul {this.onGenerateBenefitsText()}
                                                </p>
                                            ) : null
                                        }

                                    </div>

                                    </>
                                )
                            }

                        </div>

                    </div>

                    <img 
                        src={MBirdAndPlantImage} 
                        style={{
                            position: 'relative',
                            margin: '10px auto',
                            width: '200px'
                        }}
                    />
                    

                </MobileView>

            </>
        )
    }
}