import React from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import { FaFacebookSquare, FaTwitterSquare } from 'react-icons/fa';
import { MdClear, MdThumbUp } from 'react-icons/md';

export default class SharedSocialToast extends React.Component{

    constructor(props){

        super(props);
    }

    onClosePopup = (ev) => {

        if( ev.target.id === 'modal-popup' ||  ev.target.id === 'close-modal-btn'){

            this.props.onClosePopup("showToastSocial");

        }
    }


    render(){

        // let encodedURL =`https://app.planary.ro/%23/${this.props.company}/${this.props.webLink}/calendar`
        let encodedURL =`https://planary.ro/preview/${this.props.company}/${this.props.webLink}/`

        return(

            <>
            <BrowserView>
            
            <div 
                id = "modal-popup"
                className="calendar-modal-container"
                onClick={(ev) => {this.onClosePopup(ev)}}
            >

                <div 
                    className="calendar-popup-card"
                    style={{
                        width: '500px',
                        padding: '50px 20px'
                    }}
                >

                    <MdClear 
                        id="close-modal-btn"
                        style={{
                            position: 'absolute',
                            top: 10,
                            right: 10,
                            fontSize: '24px',
                            color: '#323232',
                            cursor: 'pointer'
                        }}

                        onClick={(ev) => {this.onClosePopup(ev)}}
                    />

                    <div>

                        <MdThumbUp 
                            style={{
                                display: 'inline-block',
                                position: 'relative',
                                top: '4px',
                                fontSize: '22px',
                                color: '#FFC727'
                            }}
                        />

                        <p
                            style={{
                                display: 'inline-block',
                                position: 'relative',
                                left: '8px',
                                margin: 0,
                                color: '#455A64',
                                fontWeight: '500',
                                fontSize: '18px'
                            }}
                        >
                            Share pe Social Media
                        </p>

                    </div>

                    <br />

                    <div
                        style={{
                            margin: '0 auto',
                            width: '70%'
                        }}
                    >

                        <p
                            style={{
                                margin: 0,
                                color: '#455A64',
                                fontWeight: '400',
                                fontSize: '16px',
                            }}
                        >
                            Distribuie link-ul tau pe retelele Social Media pentru a ajuta clientii tai sa isi inregistreze programari. 
                            Selecteaza reteaua pe care doresti sa distribui.
                        </p>

                        <br />
                        
                        
                            <FaFacebookSquare 
                                style={{
                                    display: 'inline-block',
                                    position: 'relative',
                                    right: '4px',
                                    fontSize: '60px',
                                    color: '#4464B1',
                                    cursor: 'pointer'
                                }}

                                onClick = {

                                    () => {
                                        var x = window.screen.width/2 - 700/2;
                                        var y = window.screen.height/2 - 450/2;

                                        window.open(`https://facebook.com/sharer.php?u=${encodedURL}`, 'fb', 'resizable,height=400,width=550,left='+x+',top='+y)
                                    }
                                }
                            />

                        
                        
                        <a
                            href={`https://twitter.com/intent/tweet?text=Programeaza-te&url=${encodedURL}`}
                            target="_blank"
                        >
                            <FaTwitterSquare 
                                style={{
                                    display: 'inline-block',
                                    position: 'relative',
                                    left: '4px',
                                    fontSize: '60px',
                                    color: '#57ACEF',
                                    cursor: 'pointer'
                                }}

                               
                            />

                        </a>

                    </div>

                </div>

            </div>
            
            </BrowserView>

            <MobileView>

            <div 
                id="modal-popup"
                className="calendar-modal-container"
                onClick={(ev) => {this.onClosePopup(ev)}}
            >

                <div 
                    className="calendar-popup-card"
                    style={{
                        width: '100%',
                        padding: '24px',
                    }}
                >

                    <MdClear 
                        id="close-modal-btn"
                        style={{
                            position: 'absolute',
                            top: 10,
                            right: 10,
                            fontSize: '24px',
                            color: '#323232',
                            cursor: 'pointer'
                        }}

                        onClick={(ev) => {this.onClosePopup(ev)}}
                    />

                    <div>

                        <MdThumbUp 
                            style={{
                                display: 'inline-block',
                                position: 'relative',
                                top: '4px',
                                fontSize: '22px',
                                color: '#FFC727'
                            }}
                        />

                        <p
                            style={{
                                display: 'inline-block',
                                position: 'relative',
                                left: '8px',
                                margin: 0,
                                color: '#455A64',
                                fontWeight: '500',
                                fontSize: '16px'
                            }}
                        >
                            Share pe Social Media
                        </p>

                    </div>

                    <br />

                    <div
                        style={{
                            margin: '0 auto',
                            width: '80%'
                        }}
                    >

                        <p
                            style={{
                                margin: 0,
                                color: '#455A64',
                                fontWeight: '400',
                                fontSize: '14px',
                            }}
                        >
                            Distribuie link-ul tau pe retelele Social Media pentru a ajuta clientii tai sa isi inregistreze programari. 
                            Selecteaza reteaua pe care doresti sa distribui.
                        </p>

                        <br />
                        
                        <a
                            href={`https://facebook.com/sharer.php?u=${encodedURL}`}
                            target="_blank"
                        >
                            <FaFacebookSquare 
                                style={{
                                    display: 'inline-block',
                                    position: 'relative',
                                    right: '8px',
                                    fontSize: '50px',
                                    color: '#4464B1',
                                    cursor: 'pointer'
                                }}
                            />

                        </a>
                        
                        <a
                            href={`https://twitter.com/intent/tweet?text=Programeaza-te&url=${encodedURL}`}
                            target="_blank"
                        >
                            <FaTwitterSquare 
                                style={{
                                    display: 'inline-block',
                                    position: 'relative',
                                    left: '8px',
                                    fontSize: '50px',
                                    color: '#57ACEF',
                                    cursor: 'pointer'
                                }}
                            />

                        </a>

                    </div>
                    
                </div>
                
            </div>

            </MobileView>
            </>
        );
    }
}