import React from 'react';

import { MdAlarm } from 'react-icons/md';
import { ImLocation2 } from 'react-icons/im';
import { IoVideocam } from 'react-icons/io5';
import { FaSkype } from 'react-icons/fa';
import { HiCurrencyDollar } from 'react-icons/hi';
import { RiCalendar2Fill } from 'react-icons/ri';
import { ReactComponent  as GMeetIcon} from '../../assets/Google_Meet-Logo.wine.svg';
import {ReactComponent as ZoomIcon } from '../../assets/Zoom Icon.svg';
import { ReactComponent as LocIcon } from '../../assets/pin_drop.svg';

import { BrowserView } from 'react-device-detect';
import Masks from '../../providers/Masks';

export default class SumarInfo extends React.Component{

    constructor(props){

        super(props);
    }

    M = new Masks();

    render(){

        return(
        <>
            <BrowserView>

                    <h1
                        style={{
                            margin: 0,
                            color: '#455A64',
                            fontSize: '36px'
                        }}
                    >
                        {this.props.serviceName}
                    </h1>

                    <div className="summary-info-row">

                        <h3       
                            style={{

                                textAlign: 'left',
                                margin: 0,
                                marginRight: 20,
                                marginBottom: 20,
                                
                            }}
                        >
                        
                            <p 
                                style={{
                                        display: 'inline-block', 
                                        position: 'relative', 
                                        top: 4, 
                                        left: 0, 
                                        margin: 0,
                                        color: '#323232',
                                        fontSize: '22px'
                                    }}
                            >
                                <RiCalendar2Fill /> 

                            </p>

                            <p 
                                style={{
                                    display: 'inline-block', 
                                    position: 'relative', 
                                    left: 10,
                                    margin: 0,
                                    color: '#455A64',
                                    fontWeight: '500',
                                    fontSize: '16px'
                                }}
                            >
                                
                                {
                                    this.props.data && 
                                    `${this.M.dayOfWeek(this.M.sqlDateToJSDate(this.props.data).getDay())} 
                                    ${this.M.sqlDateToJSDate(this.props.data).getDate()}
                                    ${this.M.monthName(this.M.sqlDateToJSDate(this.props.data).getMonth())},
                                    
                                    Ora ${this.props.ora.substr(0,5)}`
                                }

                            </p>
                            
                        </h3>

                        <h3       
                            style={{

                                textAlign: 'left',
                                margin: 0,
                                marginRight: 20,
                                marginBottom: 20,
                                
                            }}
                        >
                        
                            <p 
                                style={{
                                        display: 'inline-block', 
                                        position: 'relative', 
                                        top: 4, 
                                        left: 0, 
                                        margin: 0,
                                        color: '#323232',
                                        fontSize: '22px'
                                    }}
                            >
                                <MdAlarm /> 

                            </p>

                            <p 
                                style={{
                                    display: 'inline-block', 
                                    position: 'relative', 
                                    left: 10,
                                    margin: 0,
                                    color: '#455A64',
                                    fontWeight: '500',
                                    fontSize: '16px'
                                }}
                            >
                                
                                {this.props.duration} Minute

                            </p>
                            
                        </h3>

                        {
                            +this.props.price > 0 && (

                                <h3       
                                    style={{

                                        textAlign: 'left',
                                        margin: 0,
                                        marginBottom: 20,
                                        
                                    }}
                                >
                                
                                    <p 
                                        style={{
                                                display: 'inline-block', 
                                                position: 'relative', 
                                                top: 4, 
                                                left: 0, 
                                                margin: 0,
                                                color: +this.props.price === 0 ? '#60D84C' : '#323232',
                                                fontSize: '22px'
                                            }}
                                    >
                                        
                                        <HiCurrencyDollar />
                                    </p>

                                    <p 
                                        style={{
                                            display: 'inline-block', 
                                            position: 'relative', 
                                            left: 10,
                                            margin: 0,
                                            color: +this.props.price === 0 ? '#60D84C' : '#455A64',
                                            fontWeight: '500',
                                            fontSize: '16px'
                                        }}
                                    >
                                        
                                        {+this.props.price === 0 ? 'Fara Tarif' : this.props.price + ' Lei'} 

                                    </p>
                                    
                                </h3>
                            )
                        }

                    </div>

                    <h3       
                        style={{
                            margin: 0,
                        }}
                    >
                    
                        <p 
                            style={{
                                    display: 'inline-block', 
                                    position: 'relative', 
                                    top: 4, 
                                    left: 0, 
                                    margin: 0,
                                    color: '#323232',
                                    fontSize: '22px'
                                }}
                        >
                            {
                                this.props.locatieCurenta.id === 'zoom' && (
                                    <ZoomIcon 
                                        className="loc-icon-darker"
                                        style={{
                                            position: 'relative',
                                            top: '3px'
                                        }}
                                    />
                                ) 
                            }

                            {
                                this.props.locatieCurenta.id === 'skype' && (
                                    <FaSkype />
                                )    
                            }

                            {
                                this.props.locatieCurenta.id === 'office' && (
                                    <LocIcon 
                                        className="loc-icon-darker"
                                        style={{
                                            position: 'relative',
                                            top: '2px',
                                            left: '-2px'
                                        }}
                                    />
                                )
                            }

                            {
                                this.props.locatieCurenta.id === 'gmeet' && (

                                    <GMeetIcon 
                                        className="gmeet-service-info"
                                            style={{
                                                position: 'relative',
                                                top: 3,
                                                width: '24px',
                                                height: '24px'
                                            }}
                                    />
                                )
                            }


                        </p>

                        <p 
                            style={{
                                display: 'inline-block', 
                                position: 'relative', 
                                left: 10,
                                margin: 0,
                                color: '#455A64',
                                fontWeight: '500',
                                fontSize: '16px'
                            }}
                        >
                            
                            {
                                this.props.locatieCurenta.descClient
                            }

                        </p>
                        
                </h3>

                {
                    this.props.member.length > 0 ? (

                        // <div
                        //     style={{
                        //         display: 'flex',
                        //         justifyContent: 'center',
                        //         width: '100%'
                        //     }}

                        // >
                            <div 
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginTop: 8,
                                    width: '100%'
                                }}
                            >
                                
                                <div
                                    style={{
                                        position: 'relative',
                                        left: -5,
                                        width: '32.5px',
                                        height: '32.5px',
                                        borderRadius: '50%',
                                        background: 'lightblue',
                                        overflow: 'hidden'
                                    }}
                                >
                                    <img 
                                        // src={CompanyAvatar} 
                                        alt="Avatar client" 
                                        style={{
                                            width: '100%',
                                            height: '100%'
                                        }}
                                    />
        
                                </div>
                                
                                <div>
        
                                <p
                                    style={{
                                        margin: 0,
                                        fontSize: '16px',
                                        color: '#263238',
                                        textAlign: 'left'
                                    }}
                                >
                                    {this.props.member[0].nume}
                                </p>
        
                                <p
                                    style={{
                                        margin: 0,
                                        fontSize: '9px',
                                        color: '#777777',
                                        textAlign: 'left'
                                    }}
                                >
                                    {this.props.member[0].functie}
                                </p>

                            </div>
        
                        </div>
                    ): null
                }

                

            </BrowserView>
        </>
        )
    }
}