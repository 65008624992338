import React from 'react';

import Ws from '../../providers/Ws';
import Utils from '../../providers/Utils';
import Declarations from '../../providers/Declarations';

import { MdAlarm } from 'react-icons/md';
import { ImLocation2 } from 'react-icons/im';
import { IoVideocam } from 'react-icons/io5';
import { FaSkype } from 'react-icons/fa';
import { HiCurrencyDollar } from 'react-icons/hi';
import { RiCalendar2Fill } from 'react-icons/ri';
import { BrowserView, MobileView } from 'react-device-detect';

import { RiArrowLeftSLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import MClientServiceInfo from './MClientServiceInfo';

import CompanyAvatar from '../../assets/foxStat.jpeg';
import CanaryPlantImage from '../../assets/canary_plant_bg.png';
import LaptopImage from '../../assets/laptop_and_mug.png';
import { ReactComponent  as GMeetIcon} from '../../assets/Google_Meet-Logo.wine.svg';

import Masks from '../../providers/Masks';
import SumarInfoClient from './SumarInfoClient';
import SumarInfo from './SumarInfo';
import ClientValidationFuncs from '../logic/ClientValidationFuncs';
import ErrorMessage from '../../commons/ErrorMessage';
import AvatarAccount from '../../commons/AvatarAccount';

export default class ProgramareFinalizata extends React.Component{

    constructor(props){

        super(props)

        this.state = {

            transactionDetails: {},
            locatieCurenta: {},
            accountName: '',
            accountImgSrc: '',
            member: [],
            errorTx: ''
        }
    }

    WS = new Ws();
    UT = new Utils();
    M = new Masks();
    CVF = new ClientValidationFuncs();

    componentDidMount(){

        this.getServiceData()
        window.scrollTo(0,0);
    }

    getServiceData = async () => {

        const selectTransaction = `select * from trc where token= '${this.props.match.params.tranToken}'    `;

        const _transaction = this.WS.sqlCommand(`select`, selectTransaction);

        const qUsers = `select * from users where account_id =  (select account_tk from trc where token = '${this.props.match.params.tranToken}') `;
        let _users = this.WS.sqlCommand(`select`, qUsers);

        const qAccount = `select * from accounts where token = (select account_tk from trc where token = '${this.props.match.params.tranToken}')`;
        let _account = this.WS.sqlCommand(`select`, qAccount);

        const [transaction, users, account] = await Promise.all([_transaction, _users, _account]);

        if(transaction.success){

            const accountData =  await this.UT.getAccountAvAndName(false, transaction.content[0]['account_tk']);

            const user = users.content.filter( user => user.token === transaction.content[0].user_asignat_token);
            const isPremiumUser = this.props.match.params.memberLink && account.content[0].abonament === 'premium';

            this.setState({

                transactionDetails: transaction.content[0],
                accountName: isPremiumUser ? user[0]['nume'] : accountData['name'],
                accountImgSrc: this.WS.serverAddress + 'staticprovider/getimg?filepath=Storage/Avatars/' + accountData['avatar'],
                member:  isPremiumUser ? [] : user,
            },
                () => {

                    this.setLocatieCurenta(transaction.content[0].location)
                
                }
            )

            const uQ = ` update trc set screen = 'finalizare-succes' where token = '${this.props.match.params.tranToken}'   `
            const updateScreen = await this.WS.sqlCommand(`update`, uQ);

            if(!updateScreen.success){

                // 
            }
        }
        else{

            this.setState({
                errorTx: 'A aparut o eroare de la server.'
            });

            throw new Error("Server response error: " + transaction.message)
        }
    }

    setLocatieCurenta = (location) => {

        let locatieCurenta = new Declarations().listaLocatiiIntalnire.filter(lEl => lEl.id === location)

        this.setState({

            locatieCurenta: locatieCurenta[0]
        })
    }

    onPressBack = async () => {

        const selectAccountLinkQuery = `select web_link from accounts where token = '${this.state.transactionDetails.account_tk}' 
                                       `                             


       const selectAccountLink = await this.WS.sqlCommand(`select`, selectAccountLinkQuery)

        if(selectAccountLink.success){

            this.props.history.push("/" + selectAccountLink.content[0].web_link)
        }
        else{

            this.setState({
                errorTx: 'A aparut o eroare de la server.'
            });
            throw new Error("Server response error: " + selectAccountLink.message )
        }
    }

    render(){

        return(
            <React.Fragment>

            <BrowserView>
            
            <div className="client-center">

                <div className="client-wrapper">

                    <div className="client-card">

                        {
                            this.state.errorTx.length > 0 && (

                                <ErrorMessage 
                                    eroareTx = {this.state.errorTx}
                                />
                            )
                        }

                        {
                            this.state.errorTx.length === 0 && (

                                <>
                                
                                    <div className="card-wrapper">

                                        {/* <div className="company-avatar-outer incard">

                                            <div className="company-avatar-inner">
                                                <img src={this.state.accountImgSrc} alt="Avatar companie" />
                                            </div>

                                        </div> */}

                                        {
                                            this.state.transactionDetails['account_tk'] && (

                                                <AvatarAccount 
                                                    isNotLogged = {false}
                                                    accToken = {this.state.transactionDetails['account_tk']}
                                                    top = '-92px'
                                                    border = '6px solid #fff'
                                                />
                                            )
                                        }

                                        <h2 
                                            style={{
                                                margin: 0,
                                                marginTop: -80,
                                                color: '#455A64',
                                                // fontSize: '18px',
                                            }}
                                        >
                                            {this.state.accountName}
                                        </h2>

                                        <h4 
                                            style={{
                                                margin: 0,
                                                marginTop: 10,
                                                color: '#455A64',
                                                fontSize: '14px',
                                            }}
                                        >
                                            Felicitari! Ai inregistrat o programare pentru
                                        </h4>

                                            

                                        <SumarInfo 
                                            serviceName = {this.state.transactionDetails['service_name']}
                                            data = {this.state.transactionDetails['data_cal']}
                                            ora = {this.state.transactionDetails['ora_cal']}
                                            duration = {this.state.transactionDetails['duration']}
                                            price = {this.state.transactionDetails['price']}
                                            locatieCurenta = {this.state.locatieCurenta}
                                            member = {this.state.member}
                                        />

                                    </div>



                                    <SumarInfoClient 
                                                
                                        nume = {this.state.transactionDetails['clie_nume']}
                                        prenume = {this.state.transactionDetails['clie_prenume']}
                                        email = {this.state.transactionDetails['clie_email']}
                                        telefon = {this.state.transactionDetails['clie_tf']}
                                        detalii = {this.state.transactionDetails['clie_detalii']}
                                    />
                                
                                </>
                            )
                        }

                    </div>

                </div> 

            </div>

            <div
                style={{
                    width: '100%',
                }}
            >

                <img 
                    src={CanaryPlantImage}
                    alt="Canary and plant..."
                    style={{
                        display: 'block',
                        margin: 'auto',
                        width: '400px'
                    }}
                />

            </div>

            </BrowserView>

            <MobileView>

                    <h3  
                        className="m-appoint-back"            
                        style={{

                            textAlign: 'left',
                            margin: 0,
                        }}
                        onClick={this.onPressBack}
                    >   
                        <p 
                            style={{
                                    display: 'inline-block', 
                                    position: 'relative', 
                                    top: 4, 
                                    left: 0, 
                                    margin: 0,
                                    fontSize: '40px'
                                }}
                        >

                            <RiArrowLeftSLine /> 

                        </p>

                        <p 
                            style={{
                                display: 'inline-block', 
                                position: 'relative',
                                top: -10, 
                                left: 0,
                                margin: 0,
                                fontWeight: '500',
                                fontSize: '16px'
                            }}
                        >
                        Inapoi
                        </p>
                        
                    </h3>

                    {

                    }
                    

                    {
                        this.state.errorTx.length === 0 && (

                            <div
                                className="m-client-header"
                                style={{

                                    marginTop: -20
                                }}
                            >

                                {/* <div className="company-avatar-outer mobile">

                                    <div className="company-avatar-inner">
                                        <img src={this.state.accountImgSrc} alt="Avatar companie" />
                                    </div>

                                </div> */}

                                {
                                    this.state.transactionDetails['account_tk'] && (

                                        <AvatarAccount 
                                            isNotLogged = {false}
                                            accToken = {this.state.transactionDetails['account_tk']}
                                            top = '20px'
                                            border = '3px solid #fff'
                                            width = '102px'
                                            height = '102px'
                                        />
                                    )
                                }

                                <h4
                                    className="title"
                                >
                                    {this.state.accountName}
                                </h4>

                            </div> 
                        )
                    } 
                    
                    <div
                        style={{
                            position: 'relative',
                            minHeight: '1500px',
                            background: `url(${LaptopImage}) bottom no-repeat`,
                            backgroundSize: '70%'
                        }}
                    >

                        <div className="m-client-services-card">

                            {
                                this.state.errorTx.length > 0 && (

                                    <ErrorMessage 
                                        eroareTx = {this.state.errorTx}
                                    />
                                )
                            }
                            
                            {
                                this.state.errorTx.length === 0 && (

                                    <>
                                        <h3
                                            style={{
                                                margin: 0,
                                                fontSize: 18,
                                                color: '#31A81D'
                                            }}
                                        >

                                            Felicitari!

                                        </h3>

                                        <br />

                                        <p
                                            style={{
                                                margin: 0,
                                                fontSize: 18,
                                                color: '#455A64'
                                            }}
                                        >
                                            Ai inregistrat cu succes o programare 
                                            noua pentru: 
                                        </p>

                                        <br />


                                        <MClientServiceInfo 
                                        
                                            name={this.state.transactionDetails.service_name}
                                            duration={this.state.transactionDetails.duration}
                                            location={this.state.locatieCurenta}
                                            description={
                                            <p
                                                style={{
                                                    margin: 0, 
                                                    marginTop: -10,
                                                    color: '#A6A6A6',
                                                    fontSize: 14
                                                }}
                                            >
                                                Detaliile de conectare vor fi trimise prin e-mail la 
                                                adresa furnizata
                                                
                                            </p>
                                            }
                                            price={this.state.transactionDetails.price}
                                            date={this.state.transactionDetails['data_cal']}
                                            hour={this.state.transactionDetails['ora_cal']}
                                            member = {this.state.member}
                                            
                                        />
                        

                                        <SumarInfoClient 
                                                
                                            nume = {this.state.transactionDetails['clie_nume']}
                                            prenume = {this.state.transactionDetails['clie_prenume']}
                                            email = {this.state.transactionDetails['clie_email']}
                                            telefon = {this.state.transactionDetails['clie_tf']}
                                            detalii = {this.state.transactionDetails['clie_detalii']}
                                        />


                                    

                                    <div
                                        style={{
                                            marginTop: 30,
                                            marginBottom: 30,
                                            width: '100%',
                                            height: '1px',
                                            background: '#EBEBEB'
                                        }}
                                    >
                                    </div> 

                                    <h3
                                        style={{

                                            margin: 0,
                                            color: '#455A64',
                                            fontSize: 16
                                        }}
                                    >
                                        Ce ai de facut mai departe?
                                    </h3>

                                    <br />

                                    <p
                                        style={{
                                            margin: 0,
                                            textAlign: 'left',
                                            color: '#455A64',
                                            fontSize: 16
                                        }}
                                    >

                                        Verifica mesajele in casuta de e-mail pe 
                                        care ai folosit-o la inregistrare. Vei gasi un 
                                        mesaj de confirmare cu toate datele 
                                        programarii.

                                        <br /> <br />

                                        In cazul in care nu gasesti imediat mesajul 
                                        nostru in Inbox, te rugam verifica si 
                                        directoarele <u>Spam</u>, <u> Promotions</u>, sau 
                                        <u> Updates</u>.

                                        <br /> <br />

                                        Pentru a evita pierderea mesajelor noastre 
                                        email, trage acest email in Inbox sau 
                                        adauga adresa hello@planary.ro in lista ta 
                                        de adrese email favorite. Promitem ca nu 
                                        vom abuza de incredera ta.

                                    </p>

                                    <br />

                                    <h3
                                        style={{

                                            margin: 0,
                                            color: '#455A64',
                                            fontSize: 16
                                        }}
                                    >
                                        Ce ai de facut mai departe?
                                    </h3>

                                    <br />

                                    <p
                                        style={{
                                            margin: 0,
                                            textAlign: 'left',
                                            color: '#455A64',
                                            fontSize: 16
                                        }}
                                    >

                                        {   
                                            this.state.transactionDetails['data_cal'] &&
                                            this.M.dayOfWeek(this.M.sqlDateToJSDate(this.state.transactionDetails['data_cal']).getDay()) + ' ' +
                                            this.M.sqlDateToJSDate(this.state.transactionDetails['data_cal']).getDate() + ' ' +
                                            this.M.monthName(this.M.sqlDateToJSDate(this.state.transactionDetails['data_cal']).getMonth()).substr(0,3) + ', Ora ' +
                                            this.state.transactionDetails['ora_cal'].substr(0,5)
                                        } te vom notifica din 
                                        nou prin mesaj email pentru a-ti aduce 
                                        aminte de programarea ta. Tu doar verifica-ti
                                        emailul.


                                    </p>

                                    
                                    </>
                                )
                            }       
                        
                            
                    </div>

                </div>

                {/* <div
                    style={{
                        marginTop: 20,
                        width: '100%'
                    }}
                >

                    <img 
                        src={LaptopImage}
                        alt="Laptop and mug..."
                        style={{
                            display: 'block',
                            margin: 'auto',
                        }}
                    />

                </div> */}

                <p
                    style={{
                        
                        paddingTop: 10,
                        paddingBottom: 20,
                        fontSize: 14,
                        color: '#455A64'
                    }}
                >
                    Programari prin  <a 
                                        href="#"
                                        style={{
                                            color: '#455A64'
                                        }}
                                    >
                                        Planary.ro
                                    </a>
                        
                </p>

            </MobileView>

            </React.Fragment>
        )
    }
}