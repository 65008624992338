import React from 'react';

import DefaultAvatar from '../assets/canary-default-avatar.svg';

import Utils from '../providers/Utils';
import Ws from '../providers/Ws';

export default class AvatarAccount extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            avatarSrc: ''
        };
    }

    WS = new Ws();
    UT = new Utils();

    componentDidMount(){

        this.getCompanyAvatarSrc();
    }

    getCompanyAvatarSrc = async () => {

        let accountData = await this.UT.getAccountAvAndName(this.props.isNotLogged, this.props.accToken);
        // 
        let verifyAccountData = this.UT.extractCompanyData(accountData['avatar']);
        let avatarSrc = ''

        if(verifyAccountData.err !== null){

            // avatarSrc = '';
            avatarSrc = DefaultAvatar;
        }
        else{

            avatarSrc = this.WS.serverAddress + 'staticprovider/getimg?filepath=Storage/Avatars/' + verifyAccountData.data
        }

        this.setState({

            avatarSrc: avatarSrc
        });
    }

    render(){

        return(

            <div
                className="company-avatar"
                style={{
                    top: this.props.top || '0px',
                    width: this.props.width || '166px',
                    height: this.props.height || '166px',
                    margin: this.props.margin || '0 auto',
                    marginTop: this.props.marginTop || '0px',
                    border: this.props.border || 'none'
                }}
            >
                <img 
                    src = {this.state.avatarSrc}
                    alt = 'Company avatar'
                    style={{
                        width: '100%',
                        height: '100%'
                    }}
                />
            </div>
        )
    }
}