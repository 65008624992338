import React from "react";
import Declarations from "../providers/Declarations";


export default class SelectProgram extends React.Component{

    constructor(props){

        super(props);
    }


    render(){

        const inputClass = 'select-input' + ' ' + this.props.className;

        return(

            <div>
                
                <select 
                    className={inputClass} 
                    style={this.props.style} 
                    onChange={this.props.onChange}
                    value = {this.props.value}
                >

                {   
                    this.props.programe &&
                    this.props.programe.map((pEl, idx) => {

                        return(

                            <option 
                                key={'program-'+idx}
                                value={pEl.token}
                            >

                                {pEl.name}

                            </option>
                        );
                        

                    })

                }

                </select>

                <label className="select-input-label">
                    {this.props.label}
                </label>

            </div>
        );
    }
}