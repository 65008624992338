import React from "react";
import Declarations from "../providers/Declarations";


export default class SelectOthers extends React.Component{

    constructor(props){

        super(props);
    }


    render(){

        const inputClass = 'select-input' + ' ' + this.props.className;

        // 

        return(

            <div>
                
                <select 
                    className={inputClass} 
                    style={this.props.style} 
                    onChange={this.props.onChange}
                    onClick={this.props.onClick}
                    disabled={this.props.disabled}
                    name={this.props.name}
                    value={this.props.tipCurent}
                >
        
                    {   
                        this.props.list &&
                        this.props.list.map((lEl, idx) => {
                            
                            // if(this.props.tipCurent === lEl.val){

                            //     return(

                            //         <option
                            //             key={this.props.keyName+idx}
                            //             value={lEl.val}
                            //             selected={true}    
                            //         >
                            //             {lEl.tx}
                            //         </option>
                            //     )
                            // }
                            // else{
                                
                                return(

                                    <option
                                        key={this.props.keyName+idx}
                                        value={lEl.val}  
                                    >
                                        {lEl.tx}
                                    </option>
                                )
                            // }
                                
                        
               
                        }, this)
                    }
                
                </select>

                <label className="select-input-label">
                    {this.props.label}
                </label>

            </div>
        );
    }
}