import React from 'react';

import { GoVerified } from 'react-icons/go';
import { MdPower, MdSettingsInputComponent } from 'react-icons/md';


import { FaAngleRight } from 'react-icons/fa';

export default class IntegrationsCard extends React.Component{

    constructor(props){

        super(props);

        this.state = {

        };
    }

    componentDidMount(){


    }

    render(){

        const syncedClass = this.props.isSynced || this.props.selected ? 'integrations-card synced' : 'integrations-card';
        const syncedTx = this.props.syncedTx || 'Conecteaza';

        return(

            <div
                className={syncedClass}
                style={this.props.style}
                onClick={this.props.onClick}
            >

                <div className="icon-wrapper">

                    {this.props.icon}

                    <div className="integrationsTx-wrapper">

                        <span className="integrationsTx subtitle">
                            {this.props.type || 'Integrare'}
                        </span>

                        <span className="integrationsTx title">
                            {this.props.middleTx}
                        </span>

                        <span className="integrationsTx subtitle">
                            {this.props.downTx}
                        </span>

                    </div>

                </div>

                <div className="status-wrapper">

                    <div className="statusTx">

                        {
                            this.props.isSynced && (

                                <GoVerified 
                                    className="integrations-icon verified"
                                />
                            )
                        }

                        {
                            !this.props.isSynced && (

                                this.props.connectIcon || 

                                <MdPower 
                                    className="integrations-icon connect"     
                                />
                            )
                        }

                        <span className="integrationsTx status">
                            {this.props.isSynced ? 'Activ' : syncedTx}
                        </span>

                    </div>

                    <FaAngleRight 
                        className="integrations-icon arrow"
                    />

                </div>

            </div>

        );
    }
}