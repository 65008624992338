import React from 'react';

import { FaSkype } from 'react-icons/fa';
import { HiCurrencyDollar } from 'react-icons/hi';
import { ImLocation2 } from 'react-icons/im';
import { MdAlarm } from 'react-icons/md';
import { ReactComponent  as GMeetIcon} from '../../../assets/Google_Meet-Logo.wine.svg';
import Declarations from '../../../providers/Declarations';
import {ReactComponent as ZoomIcon } from '../../../assets/Zoom Icon.svg';
import { ReactComponent as LocIcon } from '../../../assets/pin_drop.svg';
import { IoIosArrowForward } from 'react-icons/io';
import HourInterval from '../../../commons/HourInterval';
import LocationDesc from '../../../commons/LocationDesc';
import IconText from '../../../commons/IconText';

export default class CrAppoShowService extends React.Component{

    constructor(props){

        super(props);
    }

    componentDidMount(){

    }

    onShowCalendar = () => {

        this.props.onShowCalendar(0, this.props.serviceDetails);
    }

    render(){


        return(

            <>
            
                <div
                   
                >

                    <br />

                    <div 
                        style={{
                            
                            display: 'flex'
                        }}
                    >

                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '91.66%'
                            }}
                        >
                            <p
                                style={{
                                    
                                    margin: 0,
                                    fontSize: '14px',
                                    fontWeight: '700',
                                    color: '#000'
                                }}
                            >
                                {this.props.name}
                            </p>
                        </div>

                        <div
                            style={{
                                position: 'relative',
                                width: '8.33%'
                            }}
                        >   

                            <IoIosArrowForward 
                                style={{
                                    position: 'relative',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    right: 0,
                                    margin: 0,
                                    fontSize: 32,
                                    color: '#323232',
                                    cursor: 'pointer'
                                }}
                                
                                onClick={this.onShowCalendar}
                            />

                        </div>

                    </div>

                    <HourInterval 
                        durata = {this.props.duration}
                        justifyContent = 'none'
                        marginTop = '0px'
                        iconSize = '21px'
                        iconCol = '#777777'
                        topIcon = '4px'
                        txSize = '14px'
                        txCol = '#455A64'
                        topTx = '2px'
                        leftTx = '8px'
                    />

                    <LocationDesc 
                        location = {this.props['location'].trim()}
                        locationDesc = { this.props.locationName }
                        // distance = '22px'
                        iconCls = 'loc-icon-dark'
                        marginTop = '5px'
                        zoomW = '20px' zoomH = '10px'
                        officeW = '22px' officeH = '22px'       
                        googleW = '24px' googleH = '24px' 
                        skypeSize = '22px'  
                        leftOfffice = '-1px'               
                        txSize = '14px'
                    />

        
                    <IconText 
                        icon = {

                            <HiCurrencyDollar 
                                style={{
                                    margin: 0,
                                    color: +this.props.price === 0 ? '#60D84C' : '#777777',
                                    fontSize: '22px'
                                }}
                            /> 
                        }
                        
                        tx = {+this.props.price === 0 ? 'Fara Tarif' : this.props.price + ' Lei'}
                        containerTop = '5px'
                        txCol = {+this.props.price === 0 ? '#60D84C' : '#455A64'}
                        txSize = '14px'
                    />

                    <div className="limit-desc-wrapper">

                        <p
                            style={{
                                margin: 0,
                                marginTop: 15,
                                fontSize: '14px',
                                fontWeight: '400',
                                lineHeight: '16px',
                                color: '#777777',
                                whiteSpace: 'pre-wrap'
                            }}
                        >
                            {this.props.description}
                        </p>
                    </div>

                    <br />

                    {/* <div
                        style={{
                            width: '100%',
                            height: '1px',
                            background: '#EBEBEB'
                        }}
                    ></div> */}

                </div>

            </>
        )
    }
}