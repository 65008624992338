import React from 'react';

import ErrorImg from '../../assets/Feeling sorry-cuate 1.png';

export default class IntegrationsErrors extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            error: 'Default error.',
            tx: 'Eroare server.'
        }
    }

    componentDidMount(){

        this.setErrorType()
    }

    setErrorType = () => {

        let type = this.props.match.params.tip

        if(type === 'mnf'){

            this.setState({

                error: 'Inalnirea nu exista.',
                tx: 'Va rugam verificati link-ul intalnirii din nou.'
            })
            return;
        }

        if(type === 'unk'){

            this.setState({

                error: 'Unknown error.',
                tx: 'Eroare unknown.'
            })
            return;
        }

        if(type === 'mns'){

            this.setState({

                error: 'Intalnirea nu a inceput',
                tx: 'Va rugam reveniti la ora la care v-ati programat intalnirea.'
            })
            return;
        }
       
        if(type === 'tyn'){

            this.setState({

                error: 'Tipul de intalnire nu exista sau nu a fost gasit.',
                tx: 'Va rugam verificati platforma pe care se v-a desfasura intalnirea.'
            })
            return;
        }

        if(type === 'cne'){

            this.setState({

                error: 'Contact-ul nu exista.',
                tx: 'Contul persoanei contactate nu exista.'
            })
            return;
        }
    }

    render(){

        return(

            <div
                style={{
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100vh'
                }}
            >

                <div
                    style={{
                        
                        margin: 'auto',
                        padding: 20,
                        width: '600px',
                        background: '#fff',
                        borderRadius: '10px',
                        boxShadow: '0px 4px 25px rgba(0, 0, 0, 0.25)'
                    }}
                >

                    <img
                        src={ErrorImg}
                        alt="Error image..."
                        style={{

                            display: 'block',
                            margin: '0 auto'
                        }}
                    />

                    <h3
                        style={{
                            margin: 0,
                            color: '#455A64'
                        }}
                    >
                        Oops, a aparut o eroare:
                    </h3>
                        
                    <br />

                    <p
                        style={{
                            margin: 0,
                            color: '#FA8989',
                            fontWeight: 500
                        }}
                    >
                        {this.state.error}
                    </p>

                    <br />

                    <p
                        style={{
                            margin: 0,
                            color: '#263238',
                            fontWeight: 500
                        }}
                    >
                        {this.state.tx}
                    </p>
                    
                    <br />
                </div>
                {/* <h1>{this.state.error}</h1> */}

            </div>
        )
    }
}