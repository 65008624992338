import React from 'react';
import Declarations from '../../providers/Declarations';
import Masks from '../../providers/Masks';
import Utils from '../../providers/Utils';
import Ws from '../../providers/Ws';
import ColorPicker from '../ColorPicker';

export default class SvColor extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            listaCulori: [],
            culoareCurenta: this.props.culoare || 'red',
            culoareTmp: this.props.culoare || 'red',
        };
    }

    WS = new Ws();
    M = new Masks();
    UT = new Utils();
    
    componentDidMount(){

        this.setListaCulori();
    }

    componentDidUpdate(prevProps, _){

        if(prevProps.triggerSave !== this.props.triggerSave){

            this.onGenerateQuery();
        }

        if(prevProps['culoare'] !== this.props['culoare']){

            this.setState({

                culoareTmp: this.props.culoare,
            });
        }
    }

    setListaCulori = () =>{

        let culori = new Declarations().listaCulori;

        this.setState({

            listaCulori: culori
        })

    }

    
    onClickCuloare = (color) => {

        let doSave = true;

        if(color === this.state.culoareTmp){

            doSave = false;
        }

        this.setState({

            colorStatus: doSave,
            culoareCurenta: color
        },
        
            () => {

                
            }
        )

        this.props.inputStatus(doSave, 'colorStatus', 'culoare', color);

    }

    onGenerateQuery = () => {

        const result = {

            data: [
                {
                    itemName: 'color',
                    itemData: `'` + this.state.culoareCurenta + `'`
                }
            ],
            err: null
        }

        // this.props.onCheckAndSave(result);
    }

    render(){

        return(

            <div>
                
                {
                    !this.props.hideText && (

                        <p 
                            style={{
                                margin: 0,
                                marginTop: this.props.descMarginTop || '0px',
                                fontSize: this.props.txSize || '12px',
                                textAlign: 'left'
                            }}
                        >
                            Selecteaza o culoare pentru acest serviciu. Te va ajuta
                            sa identifici repede serviciile in calendar.
                        </p>
                    )
                }

                <div
                    style={{
                        marginTop: '20px'
                    }}
                >

                    <ColorPicker 
                        listaCulori = {this.state.listaCulori}
                        culoare = {this.state.culoareCurenta}
                        onClickCuloare = {this.onClickCuloare}
                    />

                </div>

            </div>
        )
    }
}