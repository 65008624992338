import React from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import Declarations from '../../providers/Declarations';
import Masks from '../../providers/Masks';
import Utils from '../../providers/Utils';
import Ws from '../../providers/Ws';
import AuthInput from '../AuthInput';
import ConfigSwitch from '../ConfigSwitch';
import MSwitch from '../MSwitch';
import SelectOthers from '../SelectOthers';
import TextareaDescription from '../TextareaDescription';

export default class SvRecall extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            listaTipZile: [],
            nrZile: this.props.serviceDetails['recall_nr_zile'] || 30,
            hasRecall: this.props.serviceDetails['has_recall'] || 0,

            tipZile: this.props.serviceDetails['recall_tip_zile'] || 'work-days',
            descriere: this.props.serviceDetails['recall_text'] || '',
            descLength: 0,

            hasRecallTmp: this.props.serviceDetails['has_recall'] || 0,
            nrZileTmp: this.props.serviceDetails['recall_nr_zile']  || 30,
            tipZileTmp: this.props.serviceDetails['recall_tip_zile'] || 'work-days',
            descriereTmp: this.props.serviceDetails['recall_text'] || '',
        };
    }

    WS = new Ws();
    M = new Masks();
    UT = new Utils();

    componentDidMount(){

        this.setRecallData();
    }

    componentDidUpdate(prevProps, _){

        if(prevProps.triggerSave !== this.props.triggerSave){

            // this.onCheckDataValidity();
        }

        if(prevProps['serviceDetails'] !== this.props['serviceDetails']){

            this.setState({

                hasRecallTmp: this.props.serviceDetails['has_recall'] || 0,
                nrZileTmp: this.props.serviceDetails['recall_nr_zile']  || 30,
                tipZileTmp: this.props.serviceDetails['recall_tip_zile'] || 'work-days',
                descriereTmp: this.props.serviceDetails['recall_text'] || '',
            });
        }
    }

    setRecallData = () => {

        this.setState({

            descLength: this.setDescriereServiciu(),
            listaTipZile: this.setTipZile()
        })
    }

    setTipZile = () =>{

        let zile = new Declarations().tipZile;

        return zile;
    }

    // setHasRecall = (service) => {

    //     this.setState({

    //         hasRecall: +service['has_recall']
    //     }); 
    // }


    setDescriereServiciu = () => {

        let length = this.state.descriere.replaceAll(/\s/g,'').length;

        return length;
    }


    onChangeSwitch = () => {

        // let hasRecallTiny = this.state.hasRecall === true ? 0 : 1;
        let doSave = true;

        if(+this.state.hasRecallTmp === +!this.state.hasRecall){

            doSave = false;
        }

        this.setState({

            hasRecallStatus: doSave,
            hasRecall: !+this.state.hasRecall,
            // hasRecallTiny: hasRecallTiny,   
            
        },
            () => {

                this.props.inputStatus(doSave, 'hasRecallStatus', 'hasRecall', this.state.hasRecall ? 1 : 0);
                let duration = 500;
                clearTimeout(this.inputTimer);
                this.inputTimer = setTimeout( ()=> {

                    this.onCheckDataValidity();
                    
                }, duration);
            }
        );
    }

    onChangeNrZile = (ev) =>{

        let doSave = true;

        if(+ev.target.value < 90){

            if(+ev.target.value === +this.state.nrZileTmp){

                doSave = false;
            }
    
            this.setState({
    
                nrZileStatus: doSave,
                nrZile: ev.target.value,
            },
            
                () => {
    
                    this.props.inputStatus(doSave, 'recallDaysNoStatus', 'recallNrZile', +ev.target.value);
                }
            )
        }
        else{

            const errorTx = 'Limita de zile recall este de 90';
            this.props.showToastError(errorTx);
        }
    }

    onChangeTipZile = (ev) => {

        let doSave = true;

        if(ev.target.value === this.state.tipZileTmp){

            doSave = false;
        }

        this.setState({

            tipZileStatus: doSave,
            tipZile: ev.target.value
        },
        
            () => {

                this.props.inputStatus(doSave, 'recallDaysTypeStatus', 'recallTipZile', ev.target.value);
            }
        )

    }
    
    onChangeDescriere = (ev) => {

        let count = ev.target.value.replaceAll(/\s/g,'').length;
        let doSave = true;

        if(count <= 500){

            if(ev.target.value === this.state.descriereTmp){

                doSave = false;
            }
       
            this.setState({

                descriereStatus: doSave,
                descriere: ev.target.value,
                descLength: +count
            },
                () => {

                    this.props.inputStatus(doSave, 'recallDescStatus', 'recallDesc', ev.target.value);

                    let duration = 500;
                    clearTimeout(this.inputTimer);
                    this.inputTimer = setTimeout( ()=> {

                        this.onCheckDataValidity();
                        
                    }, duration);
                }
            );
        }
        else{

            const errorTx =  'Ai atins limita de caractere a mesajului de recall!';
            this.props.showToastError(errorTx);
            // alert('Limita caractere atinsa!')
        }

    }

    onCheckDataValidity = () => {

        if(this.state.descriere.length < 1 && this.state.hasRecall){

            const errorTx = 'Trebuie sa existe un mesaj de recall pentru a putea fi activat.';
            this.props.inputStatus(true, 'isRecallOk', 'isRecallOk', false, true);
            this.props.onAddErrorMessage(errorTx);
        }
        else {
            
            this.props.inputStatus(true, 'isRecallOk', 'isRecallOk', true, true);
        }

        // let result = {};

        // if(this.state.descriere.length < 1){

        //     result = {

        //         data: null, 
        //         err: 'Trebuie sa existe un mesaj de recall pentru a putea fi activat.'
        //     }
        // }
        // else {
            
        //     result = this.onGenerateQuery();
        // }

        // this.props.onCheckAndSave(result);
    }

    onGenerateQuery = () => {

        const switchDigit = this.state.hasRecall ? 1 : 0;

        const result = {

            data: [
                {
                    itemName: 'has_recall',
                    itemData: `${switchDigit}`
                },
                
                {
                    itemName: 'recall_nr_zile',
                    itemData: `${this.state.nrZile}`
                },

                {
                    itemName: 'recall_tip_zile',
                    itemData: `'` + this.state.tipZile +`'`
                },

                {
                    itemName: 'recall_text',
                    itemData: `'` + this.state.descriere + `'`
                },
            ],
            err: null
        }

        return result;
    }

    render(){

        return(

            <>
                <BrowserView>
                
                    <div className="form-control">

                        <ConfigSwitch
                            title={this.state.hasRecall ? 'Functionalitate Activa' : 'Functionalitate Inactiva'}
                            id='recall'
                            for='recall'
                            checked={this.state.hasRecall}
                            onChange={this.onChangeSwitch}
                        />

                    </div>

                    <div className="form-control">

                        <p 
                            style={{
                                margin: 0,
                                fontSize: '14px',
                                textAlign: 'left',
                                color: '#000000'
                            }}
                        >
                            La cat timp dupa programare sa trimitem mesajul Recall?
                        </p>

                    </div>

                    <div className="row" style={{marginTop: '25px'}}>

                        <div 
                            className="col-l-2 col-m-2 col-s-2"
                            style={{
                                position: 'relative'
                            }}
                        >

                            <AuthInput 
                                className="days-input"
                                label='Zile'
                                type="text"
                                value={this.state.nrZile}
                                onChange={this.onChangeNrZile}
                                style={{
                                    textAlign: 'center',
                                }}
                                labelStyle={{
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                }}
                            />

                        </div>

                        <div 
                            className="col-l-10 col-m-10 col-s-10"
                            style={{
                                position: 'relative'
                            }}
                        >

                            <SelectOthers
                                label='Alege'
                                keyName='m-recall-tipZile-'
                                tipCurent={this.state.tipZile}
                                value={this.state.tipZile}
                                list={this.state.listaTipZile}
                                onChange={this.onChangeTipZile}
                                style = {{
                                    borderRadius: '0px 5px 5px 0px'
                                }}
                            />

                        </div>

                    </div>

                    <div 
                        className="form-control"
                        style={{
                            marginTop: 20,
                            marginBottom: 20
                        }}
                    >

                        <TextareaDescription 
                        
                            style={{
                                height: 220
                            }}
                            value={this.state.descriere}
                            onChange={this.onChangeDescriere}
                            textLenght={this.state.descLength}
                        />

                    </div>
                
                </BrowserView>

                <MobileView>

                    <MSwitch 
                        title={this.state.hasRecall ? 'Functionaltate Activa' : 'Functionaltate Inactiva'}
                        checked={this.state.hasRecall}
                        onChange={this.onChangeSwitch}
                    />

                    <div 
                        className="m-edit-s-card"
                        style={{
                            marginTop: 20
                        }}
                    >

                        <p 
                            style={{
                                margin: 0,
                                fontSize: '14px',
                                textAlign: 'left'
                            }}
                        >
                            La cat timp dupa programare sa trimitem
                            clientului mesajul tau de Recall?
                        </p>

                        <div 
                            className="row"
                            style={{
                                marginTop: 10
                            }}
                        >

                            <div 
                                className="col-s-3"
                                style={{
                                    position: 'relative'
                                }}
                            >

                                <AuthInput 
                                    className="days-input"
                                    label='Zile'
                                    type="number"
                                    value={this.state.nrZile}
                                    onChange={this.onChangeNrZile}
                                    style={{
                                        textAlign: 'center',
                                    }}
                                    labelStyle={{
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                    }}
                                />

                            </div>

                            <div 
                                className="col-s-9"
                                style={{
                                    position: 'relative'
                                }}
                            >

                                <SelectOthers
                                    label='Alege'
                                    keyName='m-recall-tipZile-'
                                    tipCurent={this.state.tipZile}
                                    value={this.state.tipZile}
                                    list={this.state.listaTipZile}
                                    onChange={this.onChangeTipZile}
                                    style={{
                                        borderRadius: '0px 5px 5px 0px'
                                    }}
                                />

                            </div>

                        </div>

                        <div 
                            className="form-control"
                            style={{
                                marginTop: 30,
                                marginBottom: 20
                            }}
                        >

                            <TextareaDescription 
                            
                                style={{
                                    height: 200
                                }}
                                value={this.state.descriere}
                                onChange={this.onChangeDescriere}
                                textLenght={this.state.descLength}

                            />

                        </div>

                    </div>

                </MobileView>
            
            
            </>
        )
    }
}