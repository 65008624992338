import React from 'react';
import Ws from '../../providers/Ws';
import Masks from '../../providers/Masks';

import { ImPlus } from 'react-icons/im';


export default class IntervalView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataStart: new Date(),
            dataEnd: new Date(),
            calendar: [],

            config: this.props.config || {
                titlu: "Calendar"
            },

            colors: this.props.colors || {
                tdBackGroundColor: "white",
                tdBorderColor: "lightgray",
                dayNumberColor: "#bf4a38",
                thColor: "#636363"
            },

            mainStyle: this.computeMainStyle()
        }
    }

    WS = new Ws()
    M = new Masks()

    localData = {
        dataStart: new Date(),
        dataEnd: new Date(),
        weekenduriLucratoare: [],
        currentDay: new Date()
    }

    // PROPS:
    // elements:[  { id:unique, data:JSDate(),header:'',label:'', backgroundColor:'', color:'' }  ] 

    // dataStart -- optional (daca nu exista se va lua minimul date din elements)
    // dataEnd -- optional (daca nu exista se va lua maximul date din elements)
    // onElementClick = 
    // colors optional
    // width: optional

    componentDidMount() {
        this.generateCalendarView()
    }

    computeMainStyle = () => {
        let reportingWidth = this.props.width || 80

        let width = `${reportingWidth}vw`
        let tdWidth = `${parseInt(reportingWidth / 7)}vw`
        let tdHeight = `${parseInt(reportingWidth / 10)}vw`

        return { width, tdWidth, tdHeight }
    }

    // la pachet pentru a inlocui willReceiveNewProps (depreciated)
    // static getDerivedStateFromProps(nextProps, prevState) {
    //     if ((nextProps.dataStart !== prevState.dataStart) || (nextProps.dataEnd !== prevState.dataEnd)) {
    //         // 
    //         return (
    //             {
    //                 dataStart: nextProps.dataStart,
    //                 dataEnd: nextProps.dataEnd
    //             }
    //             //true
    //         );
    //     }
    //     else {
    //         // 
    //         return null
    //     }
    // }
    // va fi declansata de fuctia de mai sus, daca returneaza true
    componentDidUpdate(prevProps, prevState) {

        /// dooooooh !!!!!
        if ((prevProps.dataStart !== this.props.dataStart) || (prevProps.dataEnd !== this.props.dataEnd)) {
            //
            this.generateCalendarView()
        }
        else {
            // 
        }
    }

    generateCalendarView = () => {

        // 

        this.computeLocalDates()
        let weeks = this.generateWeeksArray()


        this.setState({
            calendar: weeks
        }, () => {
            //
        })

    }


    computeLocalDates = () => {

        let dataStart = null
        let dataEnd = null

        

        if (this.props.dataStart && this.props.dataEnd) {
            dataStart = this.props.dataStart
            dataEnd = this.props.dataEnd

            // 
        }
        else {

            // 
            if ((this.props.elements || []).length > 0) {

                let elementsSortedByDate = this.props.elements.sort((a, b) => {
                    if (a["data"].getTime() < b["data"].getTime()) {
                        return -1
                    }
                    return 1
                })

                dataStart = elementsSortedByDate[0]["data"]
                dataEnd = elementsSortedByDate[elementsSortedByDate.length - 1]["data"]


            }
            else {
                dataStart = this.M.addDaysToDateAndConvertToField(new Date(), -30)
                dataEnd = this.M.curentDateToDateField()
            }
        }

        this.setState({
            dataStart: dataStart,
            dataEnd: dataEnd
        })

        this.localData.dataStart = dataStart
        this.localData.dataEnd = dataEnd

    }

    generateWeeksArray = () => {

        // 
        let zile = this.generateListaZile(this.localData.dataStart, this.localData.dataEnd)

        // just in case
        zile.sort((a, b) => {
            if (a['data'].getTime() < b['data'].getTime()) {
                return -1
            }
            return 1
        })

        
        if ((zile.length % 7 != 0) || zile.length < 7) {
            throw new Error("Array zile incorect generat. Incercati din nou pe un alt interval")

        }

        let weeks = []
        for (let i = 0; i <= zile.length - 1; i = i + 7) {

            let weekDays = []
            for (let k = 0; k < 7; k++) {
                weekDays.push(zile[k + i])
            }
            //

            weeks.push({
                nr: (i / 7),
                days: weekDays
            })
        }

        
        return weeks;
    }

    generateListaZile = (dateStart, dateEnd) => {

        let firstMonday = this.findPrevMondayDate(dateStart)
        let lastSunday = this.findLastSundayDate(dateEnd)

        
        let rez = []
        let iteratingDate = firstMonday

        while (iteratingDate.getTime() <= lastSunday.getTime()) {

            let isOutisideInitialInterval = (iteratingDate.getTime() < dateStart.getTime()) || (iteratingDate.getTime() > dateEnd.getTime())
            let dayNumberInWeek = iteratingDate.getDay() == 0 ? 7 : iteratingDate.getDay() + 1

            rez.push({
                data: iteratingDate,
                isOutisideInitialInterval: isOutisideInitialInterval,
                dayNumberInWeek: dayNumberInWeek
            })

            iteratingDate = this.addDays(iteratingDate, 1)
        }

        
        return rez;
    }

    findPrevMondayDate = (firstDate) => {
 
        let firstDay = firstDate.getDay()

        if (firstDay == 0) {// duminica
            return this.addDays(firstDate, -6)
        }
        return this.addDays(firstDate, -1 * (firstDay - 1))
    }


    findLastSundayDate = (lastDate) => {

        let lastDay = lastDate.getDay()

        if (lastDay == 0) {// duminica
            return lastDate
        }

        return this.addDays(lastDate, 7 - lastDay)
    }


    addDays = (date, days) => {

        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }


    getDayEvents = dayDate => {
        // 
        // 
        const _elements = this.props.elements.filter(elem => elem['data'].getTime() == dayDate.getTime()) //    // elements:[  { id:unique, data:Date(),header:'',label:'', backgroundColor:'', color:'' }  ]

       
        return _elements.map((el, idx) => {

            const teamMember = this.props.isMainAccountUser ? this.props.users.filter( user => user.token === el.user_token) : [];

            if( (el['tip'] || '') == 'pauza' ){

                return (
                    <li
                        key={`deleem_${idx}`}
                        onClick={() => this.onElementClick(el)}
                        style={{
                            backgroundColor: el['backgroundColor'] ? el['backgroundColor'] : 'bluelight',
                            margin: 0,
                            // width: '100%',
                            // height: '40px',
                            color: el['color'] ? el['color'] : 'black',
                            cursor: el['cursor'] ? el['cursor'] : '',
                            listStyleType: 'none',
                            borderRadius: this.props.style ? this.props.style.borderRadius : '',
                            // padding: this.props.style ? this.props.style.padding : '',
                            // padding: '5px',
                            marginBottom: '2px',
                            borderLeft: '6px solid '+ el['color'],
                            padding: !this.props.isListView ? '5px' : '3px',
                            cursor: 'default',
                        }}
                    >

                        {
                            this.props.isListView && (

                                <p
                                    style={{ 
                                        position: 'relative',
                                        margin: 0,
                                        // marginTop: 2,
                                        // top: -2,
                                        fontSize: '10px', 
                                        textAlign: 'left',
                                        color: '#000',

                                    }}
                                >
                                    {
                                        (el['header']).substr(0,5) 
                                    }
                                </p>
                            )
                        }

                        {
                            !this.props.isListView && (

                                <>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center'
                                        }}
                                    >
                                        
                                        {
                                            teamMember.length > 0 ? (

                                                <div
                                                    style={{
                                                        position: 'relative',
                                                        left: -5,
                                                        width: '32.5px',
                                                        height: '32.5px',
                                                        borderRadius: '50%',
                                                        background: 'lightblue',
                                                        overflow: 'hidden'
                                                    }}
                                                >
                                                    <img 
                                                        // src={CompanyAvatar} 
                                                        alt="Avatar client" 
                                                        style={{
                                                            width: '100%',
                                                            height: '100%'
                                                        }}
                                                    />
                        
                                                </div>
                                            ) : null
                                        }

                                       <div
                                        style={{
                                            width: teamMember.length > 0 ? `calc(100% - 32.5px)` : '100%'
                                        }}
                                       >
                                            <p
                                                style={{
                                                    margin: 0,
                                                    color: el['color'],
                                                    borderRadius: this.props.style ? this.props.style.borderRadius : '',
                                                    // fontSize: this.props.style ? this.props.style.fontSizeHeader : '',
                                                    fontSize: this.props.isListView ? '10px' : '14px',
                                                    textAlign: 'left',
                                                    padding: this.props.style ? this.props.style.paddingHeader : '',
                                                    filter: 'brightness(70%)'
                                                }}
                                            >
                                                {
                                                    (el['header'] || '').substr(0,5)
                                                }
                                            </p>

                                            <p
                                                style={{ 
                                                    position: 'relative',
                                                    margin: 0,
                                                    // marginTop: 2,
                                                    // top: -2,
                                                    fontSize: '10px', 
                                                    textAlign: 'left',
                                                    color: '#000',

                                                }}
                                            >
                                                {
                                                    (el['breakDuration'] || '')
                                                }
                                            </p>

                                       </div>

                                    </div>
                                  

                                </>

                            )
                        }
                        
                        
                    </li>
                )
            }
            else{

                return (
                    <li
                        key={`deleem_${idx}`}
                        onClick={() => this.onElementClick(el)}
                        style={{
                            backgroundColor: el['backgroundColor'] ? el['backgroundColor'] : 'bluelight',
                            margin: 0,
                            // width: '100%',
                            // height: '40px',
                            color: el['color'] ? el['color'] : 'black',
                            cursor: el['cursor'] ? el['cursor'] : '',
                            listStyleType: 'none',
                            borderRadius: this.props.style ? this.props.style.borderRadius : '',
                            // padding: this.props.style ? this.props.style.padding : '',
                            // padding: '5px',
                            marginBottom: '2px',
                            borderLeft: '6px solid '+ el['color'],
                            padding: !this.props.isListView ? '5px' : '3px',
                            cursor: 'pointer',
                        }}

                        onClick = {

                            () => {

                                this.onShowClientClick(el.data, el.token)
                            }
                        }
                    >
                        {
                            this.props.isListView && (

                                <p
                                    className='cal-element-tx-wrapper'
                                    style={{ 
                                        position: 'relative',
                                        margin: 0,
                                        // marginTop: 2,
                                        // top: -2,
                                        fontSize: '10px', 
                                        textAlign: 'left',
                                        color: '#000',

                                    }}
                                >
                                    {
                                        (el['header'] || '')
                                    }
                                </p>
                            )
                        }

                        {
                            !this.props.isListView && (
                                <>

                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center'
                                        }}
                                    >
                                        
                                        {
                                            teamMember.length > 0 ? (

                                                <div
                                                    style={{
                                                        position: 'relative',
                                                        left: -5,
                                                        width: '32.5px',
                                                        height: '32.5px',
                                                        borderRadius: '50%',
                                                        background: 'lightblue',
                                                        overflow: 'hidden'
                                                    }}
                                                >
                                                    <img 
                                                        // src={CompanyAvatar} 
                                                        alt="Avatar client" 
                                                        style={{
                                                            width: '100%',
                                                            height: '100%'
                                                        }}
                                                    />
                        
                                                </div>
                                            ) : null
                                        }

                                       <div
                                        style={{
                                            width: teamMember.length > 0 ? `calc(100% - 32.5px)` : '100%'
                                        }}
                                       >
                                              <p
                                                className='cal-element-tx-wrapper'
                                                style={{
                                                    margin: 0,
                                                    color: el['color'],
                                                    borderRadius: this.props.style ? this.props.style.borderRadius : '',
                                                    // fontSize: this.props.style ? this.props.style.fontSizeHeader : '',
                                                    fontSize: '14px',
                                                    textAlign: 'left',
                                                    padding: this.props.style ? this.props.style.paddingHeader : '',
                                                    filter: 'brightness(70%)'
                                                }}
                                            >
                                                {
                                                    el['header']
                                                }
                                            </p>

                                            <p
                                                className='cal-element-tx-wrapper'
                                                style={{ 
                                                    position: 'relative',
                                                    margin: 0,
                                                    // marginTop: 2,
                                                    // top: -2,
                                                    fontSize: '10px', 
                                                    textAlign: 'left',
                                                    color: '#000',

                                                }}
                                            >
                                                {
                                                    (el['label'] || '')
                                                }
                                            </p>

                                       </div>

                                    </div>
                                  
                                </>
                                
                            )   
                        }
                        
                    </li>
                )
            }
                
        })
    }

    getDayAppointsList = (dayDate) => {

        const _elements = this.props.elements.filter(elem => elem['data'].getTime() == dayDate.getTime())

        return _elements;
    }

    showMore = (dayDate) => {

        const _elements = this.props.elements.filter(elem => elem['data'].getTime() == dayDate.getTime());

        return _elements.length > 2;
    }

    onElementClick = id => {
        if (this.props.onElementClick) {
            this.props.onElementClick(id)
        }
        else {
            console.error("onElementClick is not on props")
        }
    }

    onDateClick = (el, hasElement) => {

        if (!hasElement && this.props.onDateClick) {
            this.props.onDateClick(el)
        }
    }

    settingTDBackground = (detaliiZi) => {
        // 
        if (detaliiZi.isOutisideInitialInterval) {

            return "#FAFAFA"

        } else {

            if (detaliiZi.dayNumberInWeek === 7) { // daca este in weekend

                return "#FAFAFA"

            } else {

                if (this.state.colors.tdBackGroundColor) {
                    return this.state.colors.tdBackGroundColor 
    
                } else {
                    return "white"
                }

            }

        }

    }

    onShowMoreClick = (dataCal) => {

        this.props.onShowAppointsPopup(true, dataCal)
    }

    onShowClientClick = (dataCal, token) => {

        this.props.onShowAppointsPopup(true, dataCal, token)
    }

    render() {
        return (
            <div 
            >
                
                <div>
                    <h4> {this.state.titlu} </h4>
                </div>

                <div
                >
                    {
                        this.state.calendar.length > 0 &&

                        <table 
                            style={{ 
                                width: '100%', 
                                // borderCollapse: 'collapse',
                                borderSpacing: '0px',
                                // borderRight: this.props.isListView ? '1px solid #FAFAFA' : 'none',
                            }}
                        >
                            <thead>
                              
                                <tr>
                                    <th style={{ color: '#C7C7C7', fontSize: 16, fontWeight: 400, paddingBottom: 10 }}>Luni</th>
                                    <th style={{ color: '#C7C7C7', fontSize: 16, fontWeight: 400, paddingBottom: 10 }}>Marti</th>
                                    <th style={{ color: '#C7C7C7', fontSize: 16, fontWeight: 400, paddingBottom: 10   }}>Miercuri</th>
                                    <th style={{ color: '#C7C7C7', fontSize: 16, fontWeight: 400, paddingBottom: 10   }}>Joi</th>
                                    <th style={{ color: '#C7C7C7', fontSize: 16, fontWeight: 400, paddingBottom: 10   }}>Vineri</th>
                                    <th style={{ color: '#C7C7C7', fontSize: 16, fontWeight: 400, paddingBottom: 10   }}>Sambata</th>
                                    <th style={{ color: '#C7C7C7', fontSize: 16, fontWeight: 400, paddingBottom: 10   }}>Duminica</th>

                                </tr>

                            </thead>

                            {/* <div style={{marginBottom: 10}}></div>     */}

                            <tbody>
    
                                {
                                    this.state.calendar.map((w, idxw) => {
                                        const _days = w.days
                                        return (
                                            <tr key={idxw}>
                                                {

                                                    _days.map((wd, idxd) => {
                                                        // 

                                                        const _elements = this.getDayEvents(wd.data)
                                                        const _showMore = this.showMore(wd.data)
                                                        const _appointsList = this.getDayAppointsList(wd.data)

                                                        return (
                                                            <td key={`d-${idxw}-${idxd}`}
                                                                style={{
                                                                    backgroundColor: this.settingTDBackground(wd),
                                                                    // background: '#fff',
                                                                    // color: wd.isOutisideInitialInterval ? "#bf4a38" : (this.state.colors.tdBackGroundColor || "gray") ,
                                                                    width: this.props.style ? this.props.style.tdBodyWidth : this.state.mainStyle.tdWidth,
                                                                    // width: '171px',
                                                                    // height: this.props.style ? this.props.style.tdBodyHeight : this.state.mainStyle.tdHeight,
                                                                    height: this.props.isListView ? '150px' : '203px',
                                                                    verticalAlign: 'top',
                                                                    border: this.props.isListView ? '1px solid #FAFAFA' : '1px solid #DBDBDB',
                                                                    borderRight: 'none',
                                                                    borderBottom: wd.data.getFullYear() === this.localData.currentDay.getFullYear()
                                                                                    && wd.data.getMonth() === this.localData.currentDay.getMonth()
                                                                                    && wd.data.getDate() === this.localData.currentDay.getDate()
                                                                                    ? '5px solid #2577C2' : '',
                                                                    // cursor: 'pointer',
                                                                }}

                                                                // onClick={
                                                                //     () => {

                                                                //         this.onShowMoreClick(_appointsList.length > 0 ? _appointsList[0].data : wd.data)
                                                                //     }
                                                                // }
                                                                // onClick={() => this.onDateClick(wd, _elements.length > 0)}

                                                            >

                                                                
                                                                   
                                                                {
                                                                    !wd.isOutisideInitialInterval && (

                                                                        <p 
                                                                            style={{ 
                                                                                color: '#000', 
                                                                                textAlign: 'center', 
                                                                                fontSize: '16px', 
                                                                                fontWeight: 500,
                                                                                cursor: 'pointer'
                                                                            }}

                                                                            onClick={
                                                                                () => {

                                                                                    this.onShowMoreClick(_appointsList.length > 0 ? _appointsList[0].data : wd.data)
                                                                                }
                                                                            }

                                                                        >
                                                                            {wd.data.getDate()} 
                                                                        </p>
                                                                    )
                                                                }

                                                                {
                                                                    wd.isOutisideInitialInterval && (

                                                                        <p 
                                                                            style={{ 
                                                                                color: '#000', 
                                                                                textAlign: 'center', 
                                                                                fontSize: '16px', 
                                                                                fontWeight: 500,
                                                                                // cursor: 'pointer'
                                                                            }}

                                                                            // onClick={
                                                                            //     () => {

                                                                            //         this.onShowMoreClick(_appointsList.length > 0 ? _appointsList[0].data : wd.data)
                                                                            //     }
                                                                            // }

                                                                        >
                                                                            {wd.data.getDate()} 
                                                                        </p>
                                                                    )
                                                                    
                                                                }

                                                                

                                                                {
                                                                    _elements.length > 0 &&
                                                                   
                                                                        <ul
                                                                            style={{
                                                                                
                                                                                padding: 0
                                                                            }}
                                                                        
                                                                        >

                                                                            {
                                                                                _elements.length > 2 ? _elements.slice(0,3) : _elements
     
                                                                            }

                                                                        

                                                                        {
                                                                            _showMore && (
                                                                                <li
                                                                                    style={{
                                                                                        position: 'relative',
                                                                                        // top: this.props.isListView ? 6 : 10,
                                                                                        listStyleType: 'none',
                                                                                        height: '20px',
                                                                                        borderLeft: '6px solid #C7C7C7',
                                                                                        background: 'rgba(199, 199, 199, 0.1)',
                                                                                        fontSize: '10px',
                                                                                        textAlign: 'left',
                                                                                        cursor: 'pointer'
                                                                                    }}

                                                                                    onClick={
                                                                                        () => {

                                                                                            this.onShowMoreClick(_appointsList[0].data)
                                                                                        }
                                                                                    }
                                                                                >
                                                                                    <p
                                                                                        style={{
                                                                                            margin: 0,
                                                                                            position: 'relative',
                                                                                            top: '50%',
                                                                                            transform: 'translateY(-50%)',
                                                                                            left: 4,
                                                                                            fontWeight: 500
                                                                                        }}
                                                                                    >
                                                                                        <ImPlus style={{fontSize: '8px'}} /> Mai mult
                                                                                    </p>
                                                                                </li>
                                                                            )
                                                                            
                                                                        }
                                                                    </ul>
                                                                }


                                                            </td>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>

                    }

                </div>

            </div>
        );
    }
}