import React from 'react';
import Ws from '../../providers/Ws';

export default class AcceptInvite extends React.Component{

    constructor(props){

        super(props);
    }

    WS = new Ws();
    
    componentDidMount(){


    }

    onInviteAccept = async () => {

        let ss = await this.WS.getData('/invite/confirm', {trcToken: this.props.match.params.tranToken});


        if(ss.success){

            alert("Succes confirmare!")
            this.props.history.push('/trc/'+this.props.match.params.tranToken+'/cfp');
        }
        else{

            alert("Confirmare esuata!") //schimba cu state cand face Sergiu design
        }
        
    }

    render(){

        return(

            <div>

                <button
                    type="button"
                    onClick={this.onInviteAccept}
                >
                    Accepta Invitatie
                </button>

            </div>
        )
    }
}