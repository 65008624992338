import React from 'react';

import LocalStorage from '../../providers/LocalStorage';
import Ws from '../../providers/Ws';

import ErrorImg from '../../assets/Feeling sorry-cuate 1.png';
import BusinessImage from '../../assets/Businesswoman-cuate 1.png';

import { BiWindows } from 'react-icons/bi';
import Masks from '../../providers/Masks';
import ClientValidationFuncs from '../logic/ClientValidationFuncs';
import NotificationsFuncs from '../../commons/notifications/NotificationsFuncs';
import Transaction from '../logic/Transaction';
import Loader from '../../commons/Loader';
import ServicesFuncs from '../logic/ServicesFuncs';


export default class PopUpPlata extends React.Component{

    constructor(props){

        super(props)

        this.state = {
            errorTx: '',
            showGmeetIframe: false,
            actionTx: 'Se inregistreaza programarea...',
            currentAppoint: {}
        };
    }

    WS = new Ws();
    M = new Masks();
    CVF = new ClientValidationFuncs();
    NF = new NotificationsFuncs();
    TR = new Transaction();
    SF = new ServicesFuncs();

    componentDidMount() {
        // if (window.addEventListener) {
        //     window.addEventListener("message", this.onMessage, false);        
        // } 

        this.onPaymentNetopia();
    }

    onMessage = (event) => {
        // Check sender origin to be trusted
        if (event.origin !== "https://auth.planary.ro") return;
    
        var data = event.data;

        //if event.data && data.conditie(success true or false) => display alert if false

        if(data && data.success){

            this.onConfirmAppointment(this.state.currentAppoint);
        }
        else{

            
            // alert(`error : ${data.message}`);
        }
    
        // 
        // if (typeof(window[data.func]) == "function") {
        //     window[data.func].call(null, data.message);
            
        // }

        // alert(`gmeet ${data.success} : ${data.message}`)
        
        if(data.email){

            if(data.email.includes("@")){

                this.socialLogin(data.email, data.name, data.picture)
            }
        }   

    }

    createPayment = async () => {

        let result = await this.TR.createPayment(this.props.match.params.tranToken);

        if(result.err !== null){

            this.setState({

                errorTx: result.err
            });
        }
        else{

            if(this.props.match.params.memberLink){

                this.props.history.push(`/trc/${this.props.match.params.memberLink}/${this.props.match.params.tranToken}/finalizare-succes`);
            }
            else{

                this.props.history.push("/trc/" + this.props.match.params.tranToken+
                                "/finalizare-succes"
                                );
            }
        }
    }

    //http://localhost:3000/#/trc/idyM4MiQC9pP78hHMaezZdfzYIsirrfW/cfp
    onPaymentNetopia = async () => {

        const tokenTrc = this.props.match.params.tranToken;

        let selectAppointQuery = `select * from trc where token = '${tokenTrc}'   `;
        let currentAppoint = await this.WS.sqlCommand(`select`, selectAppointQuery);

        let price = 0;
        let isDoctorService = false;

        if(currentAppoint.content.length === 0){

            this.setState({
                errorTx: 'A aparut o eroare la redirectionarea catre plata'
            });

            return;
        }

        if(currentAppoint.success){

            const errorTxOverlap = await this.CVF.checkIfAppointIsOverlaping(currentAppoint.content[0]['data_cal'], currentAppoint.content[0]['ora_cal'], this.props.match.params.tranToken, false);
            const errorTxCommon = this.CVF.clientPageCommonExceptionMessage(currentAppoint.content[0]);
            const errorTxUncomplete = this.CVF.uncompletedDataExceptionMessage(currentAppoint.content[0]);

            if(errorTxOverlap.length > 0){

                this.setState({

                    errorTx: errorTxOverlap
                });

                return;
            }

            if(errorTxCommon.length > 0){

                this.setState({

                    errorTx: errorTxCommon
                });

                return;
            }

            if(errorTxUncomplete.length > 0){

                this.setState({

                    errorTx: errorTxUncomplete
                });

                return;
            }
           
            price = +currentAppoint.content[0].price;

            if(localStorage.getItem("userData")){

                const accountTk = new LocalStorage().GetAccountToken();
                const checkTranAccountTk = currentAppoint.content.filter(tEl => tEl['account_tk'] === accountTk);
                isDoctorService = checkTranAccountTk.length > 0 ? true : false;
                
            }

            let selectTrcPayments = `select * from trc_payments where trc_token = '${(currentAppoint.content[0]['replaned_trc_tk'] || '')}' `;
            let trcPayments = await this.WS.sqlCommand(`select`, selectTrcPayments);

            let successfulPayment = [];

            if(trcPayments.success){

                successfulPayment = trcPayments.content.filter(tEl => +tEl['succes'] === 1);
            }
            else{

                this.setState({

                    errorTx: 'Eroare server.'
                })
            }

            if((price === 0 && isDoctorService === false) || ((currentAppoint.content[0]['replaned_trc_tk'] || '').length > 10 && successfulPayment.length > 0 ) ){
    
                await this.createPayment();
            }
            else if(isDoctorService === true){

                let updateIntoInviteQuery = `update trc set screen = 'popup', src = 'ca' where token = '${this.props.match.params.tranToken}' `;
                let updateInviteQueryRes = await this.WS.sqlCommand(`update`, updateIntoInviteQuery);

                if(updateInviteQueryRes.success){

                    let ss = await this.WS.getData('/invite/create', {trcToken: this.props.match.params.tranToken});

                    if(ss.success){
                        
                        this.props.history.push("/trc/" + this.props.match.params.tranToken+
                        "/finalizare-succes"
                        )
                    }
                    else{

                        this.setState({

                            errorTx: 'Email-ul nu a fost trimis catre client.'
                        })
                    }
                    
                }
                else{

                    this.setState({

                        errorTx: 'Eroare generare programare!'
                    });
                }
                
            }
            else if(price >= 1 && isDoctorService === false){

                let _adr = `${this.WS.serverAddress}pay/netopia/?apikey=${this.WS.appKey}&trToken=${tokenTrc}`;
                let addr = encodeURI(_adr);
        
                // 
                
                let r = await fetch(addr);

                if (r.status === 200) {

                    let ss = await r.json(); // pentru tratarea de exceptii cu mesaje punctuale in fiecare pagina
                    
                    if(ss.success){

                        
                        let data = ss.content.data;
                        let env_key = ss.content.env_key;
                        let addr = "http://sandboxsecure.mobilpay.ro";
        
                        // alert("Redirectionare Formular Plata Netopia");
        
                        this.postToNetopia(data, env_key, addr);
                    
                    }
                    else{
        
                        this.setState({
        
                            errorTx: 'A aparut o eroare la redirectionarea catre plata!'
                        });
                    }
                    // this.onConfirmAppointment()
                }
                else {
        
                    this.setState({
        
                        errorTx: 'A aparut o eroare la redirectionarea catre plata!'
                    });
                    console.error("GET SERVER STATUS ERR", r);
                   
                }

            }
        }
        else{

            this.setState({
                errorTx: 'A aparut o eroare la redirectionarea catre plata'
            });

            throw new Error("Error fetching appointment: " + currentAppoint.message)
        }

    }

    postToNetopia = (data, env_key, addr) =>{

        let _adr = "http://sandboxsecure.mobilpay.ro";
        var form = document.createElement("form");

        form.setAttribute("method", "post");
        form.setAttribute("action", addr);
    
        var hiddenField = document.createElement("input");
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("name", "data");
        hiddenField.setAttribute("value", data);
        form.appendChild(hiddenField);

        var hiddenFieldTwo = document.createElement("input");
        hiddenFieldTwo.setAttribute("type", "hidden");
        hiddenFieldTwo.setAttribute("name", "env_key");
        hiddenFieldTwo.setAttribute("value", env_key);
        form.appendChild(hiddenFieldTwo);

        var hiddenFieldThree = document.createElement("input");
        hiddenFieldThree.setAttribute("type", "hidden");
        hiddenFieldThree.setAttribute("name", "id");
        hiddenFieldThree.setAttribute("value", "123456");
        form.appendChild(hiddenFieldThree);
    
        document.body.appendChild(form);

        // 
        form.submit();
    }

    render(){

        return(

            <div>
                {
                    this.state.errorTx.length === 0 && (

                    <div>
                        {/* <button onClick={this.createPayment}>Genereaza Links Meeting</button>

                        <br />

                        <button onClick={this.onPaymentNetopia}>Simulare plata Netopia</button>

                        
                        {
                            this.state.showGmeetIframe && (

                                <iframe
                                    src={'https://auth.planary.ro/gcal/?trc='+this.props.match.params.tranToken}
                                    style={{
                                        width: '200px',
                                        height: '200px',
                                        backgroundColor: 'blue'
                                    }}
                                >
                                
                                </iframe>
                            )  
                        } */}
{/* 
                        {
                            this.state.showGmeetIframe && (

                                <iframe
                                    src={'https://auth.planary.ro/gcal/?trc='+this.props.match.params.tranToken}
                                    style={{
                                        visibility: 'hidden',
                                        width: '1px',
                                        height: '1px',
                                        backgroundColor: 'blue'
                                    }}
                                >
                                
                                </iframe>
                            )  
                        } */}

                        <div
                            style={{
                                position: 'relative',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                                height: '100vh'
                            }}
                        >
            
                            <div
                                style={{
                                    
                                    margin: 'auto',
                                    padding: 20,
                                    width: '600px',
                                    background: '#fff',
                                    borderRadius: '10px',
                                    boxShadow: '0px 4px 25px rgba(0, 0, 0, 0.25)'
                                }}
                            >

                                <img 
                                    src={BusinessImage}
                                    alt="Business Image"
                                    style={{
                                        display: 'block',
                                        margin: '0 auto'
                                    }}
                                />

                                <Loader 
                                    containerH = '100px'
                                />

                                <p
                                    style={{
                                        margin: 0,
                                        color: '#455A64',
                                        fontSize: '18px',
                                        fontWeight: 500
                                    }}
                                >
                                    {this.state.actionTx}
                                </p>

                            </div>

                        </div>

                    </div>
                    )
                }

                {
                    this.state.errorTx.length > 0 &&

                    <div
                        style={{
                            position: 'relative',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: '100vh'
                        }}
                    >
        
                        <div
                            style={{
                                
                                margin: 'auto',
                                padding: 20,
                                width: '600px',
                                background: '#fff',
                                borderRadius: '10px',
                                boxShadow: '0px 4px 25px rgba(0, 0, 0, 0.25)'
                            }}
                        >
        
                            <img
                                src={ErrorImg}
                                alt="Error image..."
                                style={{
        
                                    display: 'block',
                                    margin: '0 auto'
                                }}
                            />
        
                            <h3
                                style={{
                                    margin: 0,
                                    color: '#455A64'
                                }}
                            >
                                Oooh, a aparut o eroare:
                            </h3>
                                
                            <br />
        
                            <p
                                style={{
                                    margin: 0,
                                    color: '#FA8989',
                                    fontWeight: 500
                                }}
                            >
                                {this.state.errorTx}
                            </p>
    
                            
                            <br />
                        </div>
                        {/* <h1>{this.state.error}</h1> */}
        
                    </div>
                }

               
            </div>
        )
    }
}