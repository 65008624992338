import React from 'react';

import { RiArrowRightSLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import IconText from './IconText';
import LocationDesc from './LocationDesc';

export default class ServiceAccessRow extends React.Component{

    constructor(props){

        super(props);
    }
 

    render(){


        const isLocation = this.props.isLocation || false;

        return(

            <Link
                to={this.props.isNotDashboard ? this.props.link : "/ca/dashboard/cab/msv/"+ this.props.link}
                style={{
                    textDecoration: 'none',
                    color: '#000'
                }}
            >
            <div 
                className="m-details-container"
                style={this.props.containerStyle}
            >

                <div 
                    className="left-content-details"
                    style={{
                        textAlign: 'left',
                    }}
                >

                    {
                        this.props.titleIcon && (

                            this.props.titleIcon
                        )
                    }

                    {
                        this.props.titleTx && (

                            <h5
                                style={{
                                    position: 'relative',
                                    top: this.props.titleTop || -5,
                                    margin: 0,
                                    fontSize: '14px'
                                }}
                            >
                                {this.props.titleTx}
                            </h5>
                        )
                    }

                    {
                        !isLocation && (

                            <IconText 
                                containerTop = {this.props.marginTopSubTx || '0px'}
                                top = {this.props.subTxTop || '5px'}
                                distance = {this.props.distance || '22px'}
                                icon = {this.props.subTxIcon}
                                txTop = {this.props.subTxTopTitle || '0px'}
                                tx = {this.props.subTx}
                                txSize = {this.props.subTxSize || '10px'}
                                txWeight = {this.props.subTxWeight || '400'}
                                txCol = {this.props.subTxCol || '#263238'}
                                side = {this.props.subTxSide}
                                isLimit = {this.props.isLimit}
                            />
                        )
                    }

                    {
                        isLocation && (

                            <LocationDesc 
                                distance = '22px'
                                top = '5px'
                                location = {this.props.location}
                                locationDesc = {this.props.locationDesc}
                                txWeight = '500'
                                zoomW = '18px' zoomH = '9px'
                                officeW = '18px' officeH = '20px'       
                                googleW = '16px' googleH = '16px' 
                                skypeSize = '16px'                 
                                leftTx = '4px'
                                txSize = '12px'
                            />
                        )
                    }

                    {
                        this.props.additionalRow && (

                            this.props.additionalRow
                        )
                    }

                </div>

            
                <div 
                    className="right-content-details"
                    style={{
                        
                        maxHeight: '100%',
                    }}
                >   
                    {/* <Link
                        to={"/ca/msv/denumire-serviciu/"+this.props.match.params.tk}
                    > */}
                        <RiArrowRightSLine 
                            style={{

                                position: 'relative',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                fontSize: '30px',
                                color: '#44A5FF',
                                textAlign: 'right',
                            }} 
                        />
                    {/* </Link> */}
                </div>
                

            </div>
         </Link>
        )
    }
}