import React from 'react';
import { Pie } from 'react-chartjs-2';
import PercentageStat from './PercentageStat';

export default class Visitors extends React.Component{

    constructor(props){

        super(props);

        this.state = {
            
        };
    }

    render(){

        return(

            <div style={this.props.style}>

                <Pie 
                    data={this.props.pieData} 
                    options={this.props.pieOptions}
                />

                <PercentageStat 
                    statColor = '#FFC727'
                    statTitle = 'Website'
                    statRate = {18.61}
                />

                <PercentageStat 
                    statColor = '#52678F'
                    statTitle = 'Facebook'
                    statRate = {12.31}
                />

                <PercentageStat 
                    statColor = '#F7A4A0'
                    statTitle = 'Youtube'
                    statRate = {8.15}
                />

                <PercentageStat 
                    statColor = '#7BDFFF'
                    statTitle = 'Alte Surse'
                    statRate = {8.15}
                />

                <div style={{textAlign: 'left'}}>

                    <p className='statistics-subsolTx' style={{marginTop: '10px'}}>
                        * Vezi grafice mai detaliate petableta sau desktop pe Planary.ro
                    </p>

                </div>

            </div>
        );
    }
}