import React from 'react';

import './Commons.css';

export default class ButtonForm extends React.Component{

    constructor(props){

        super(props)
        
    }

    render(){

        const classes = 'form-button ' + this.props.className;

        return(

            <React.Fragment>

                <button 
                    id = {this.props.id}
                    className={classes} 
                    type={this.props.type} 
                    style={this.props.style} 
                    onClick={this.props.onClick}
                    disabled={this.props.disabled}
                >

                    {this.props.icon} {this.props.tx}

                </button>

            </React.Fragment>
        );
    }
}