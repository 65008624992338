import React from "react";
import Declarations from "../providers/Declarations";


export default class SelectInput extends React.Component{

    constructor(props){

        super(props);
    }

    

    render(){

        const inputClass = 'select-input' + ' ' + this.props.className;

        return(

            <div>
                
                <select 
                    className={inputClass} 
                    style={this.props.style}
                    onChange={this.props.onChange}
                    defaultValue={this.props.durataCurenta}      
                >

                    {   
                        this.props.listaDurataServiciu.map((dsEl, idx) => {

                            if(+dsEl.val === +this.props.durataCurenta){
                                
                                return(

                                    <option 
                                        key={this.props.myKey + idx}
                                        value={+dsEl.val}
                                        selected={true}
                                    >

                                        {dsEl.tx}

                                    </option>

                                );
                            }
                            else{
                                    
                                return(

                                    <option 
                                        key={this.props.myKey + idx}
                                        value={+dsEl.val}
                                    >

                                        {dsEl.tx}

                                    </option>

                                );
                            }

                        }, this)
                    }

                </select>

                <label 
                    className="select-input-label"
                    style={this.props.labelStyle}
                >
                    {this.props.label}
                </label>

            </div>
        );
    }
}