import React from 'react';

import { BrowserView, MobileView } from 'react-device-detect';
import { GoCheck } from 'react-icons/go';
import { MdNotificationsActive, MdSecurity, MdVisibility, MdVisibilityOff } from 'react-icons/md';

import AuthInput from '../../commons/AuthInput';
import ButtonSaveDashboard from '../../commons/ButtonSaveDashboard';
import IconText from '../../commons/IconText';
import MServiceNav from '../../commons/MServiceNav';
import SwitchOption from '../../commons/SwitchOption';
import LaptopAndMugImg from '../../assets/laptop_and_mug.png';
import LocalStorage from '../../providers/LocalStorage';
import Ws from '../../providers/Ws';

export default class Security extends React.Component{

    constructor(props){

        super(props);

        this.state = {
          
            canSave: false,

            showPass: false,
            password: '',
            passErr: false,
            passErrTx: '',
            passVerified: false,

            systemNotificationsSwitch: true,
            newAppointSwitch: true,
            rescheduleSwitch: true,
            cancelSwitch: true
        };
    }

    componentDidMount(){

        this.onSetSwitchButtons();
    }

    onSetSwitchButtons = () => {

        const localStorageSettings = JSON.parse(localStorage.getItem("userSettings"));

        if(localStorageSettings && localStorageSettings !== null){

            this.setState({

                newAppointSwitch: localStorageSettings.newAppointmentNotifications,
                rescheduleSwitch: localStorageSettings.rescheduleNotifications,
                cancelSwitch: localStorageSettings.cancelNotifications
            });
        }
        else{

            const userSettings = {

                newAppointmentNotifications: true,
                rescheduleNotifications: true,
                cancelNotifications: true
            };
    
            localStorage.setItem("userSettings", JSON.stringify(userSettings));
        }
    }

    onShowPassword = () => {

        this.setState({

            showPass: !this.state.showPass
        });
    }

    onEnteredPass = (ev) => {

        this.setState({

            password: ev.target.value,
            canSave: true
        });
    }

    onChangeSwitch = (type) => {

        this.setState({

            [type]: !this.state[type],
            canSave: true
        });
    }

    onSaveSettings = async () => {

        let passwordInput = {

            passErr: false,
            passErrTx: '',
            passVerified: false,
        }
        if(this.state.password.length > 0){

            if(this.state.password.length > 6){

                const accountStorage = new LocalStorage().GetUserData();
                const userToken = accountStorage.userData[0].token;
                let queryUpdatePass = `update users set pwd_c = '${this.state.password}' where token = '${userToken}'`;
                let passwordUpdateRes = await new Ws().sqlCommand(`update`, queryUpdatePass);

                if(passwordUpdateRes.success){

                    passwordInput.passVerified = true;
                }

            }
            else{

                passwordInput.passErr = true;
                passwordInput.passErrTx = 'Parola trebuie sa contina mai mult de 6 caractere.'
            }


        }

        const userSettings = {

            newAppointmentNotifications: this.state.newAppointSwitch,
            rescheduleNotifications: this.state.rescheduleSwitch,
            cancelNotifications: this.state.cancelSwitch 
        };

        localStorage.setItem("userSettings", JSON.stringify(userSettings));

        this.setState({

            canSave: false,
            passErr: passwordInput.passErr,
            passErrTx: passwordInput.passErrTx,
            passVerified: passwordInput.passVerified,
        });
    }

    render(){

        return(
            <>

                <BrowserView>
                
                    <div 
                        className="wrapper-dashboard" 
                        style={{

                            margin:'30px auto',
                        }}
                    >

                        <ButtonSaveDashboard 
                            canSave = {this.state.canSave}
                            onClick={this.onSaveSettings}
                        />

                    </div>

                    <div 
                        className="wrapper-dashboard integrari" 
                        style={{

                            margin:'30px auto',
                            minHeight: '900px',
                            background: `url(${LaptopAndMugImg}) bottom no-repeat`
                        }}
                    >

                        <div className="service-details-card separated-card">

                            <IconText 
                                top = '0px'
                                icon = {
                                    <MdSecurity
                                        style={{
                                            fontSize: '24px',
                                            color: '#FFC727'
                                        }} 
                                    />
                                }
                                tx = 'Schimba Parola'
                                txSize = '14px'
                                txWeight = '500'
                                txCol = '#263238'
                            />

                            <br />

                            <div className='form-control'>

                                {
                                    !this.state.showPass && (

                                        <MdVisibilityOff 
                                            className='showpass'
                                            onClick={this.onShowPassword}
                                        />
                                    )
                                }

                                {
                                    this.state.showPass && (

                                        <MdVisibility
                                            className='showpass'
                                            onClick={this.onShowPassword}
                                        />
                                    )
                                }

                                <AuthInput 
                                    type={this.state.showPass ? 'text' : 'password'}
                                    label='Parola'
                                    labelStyle={{
                                        fontSize: this.props.labelSize || '10px'
                                    }}
                                    iserror={this.state.passErr}
                                    errormsg={this.state.passErrTx}
                                    value={this.state.password}
                                    onChange={this.onEnteredPass}
                                    verified={this.state.passVerified}
                                />

                            </div>

                            <p 
                                className='security-tx px12'
                                style={{
                                    marginTop: '10px'
                                }}
                            >

                                Iti sugeram sa alegi o parola puternica, formata din
                                minim 8 caractere, care sa contina litere mici, litere
                                mari si cel putin un caracter special.

                            </p>

                            <div 
                                className='separator-line'
                                style={{
                                    marginTop: '16px'
                                }}
                            ></div>

                            <br />
{/* 
                            <IconText 
                                top = '0px'
                                icon = {
                                    <MdNotificationsActive
                                        style={{
                                            fontSize: '24px',
                                            color: '#FFC727'
                                        }} 
                                    />
                                }
                                tx = 'Notificari'
                                txSize = '14px'
                                txWeight = '500'
                                txCol = '#263238'
                            />

                            <br />  

                            <SwitchOption 
                                tx = 'Notificari de Sistem'
                                id = 'sys'
                                for = 'sys'
                                checked = {this.state.systemNotificationsSwitch}
                                onChange = {() => {this.onChangeSwitch('systemNotificationsSwitch')}}
                            />

                            <br />

                            <div 
                                className='separator-line'
                            ></div> */}

                            <p 
                                className='security-tx px41'
                                style={{
                                    marginTop: '10px',
                                    color: '#455A64',
                                    fontWeight: '500'
                                }}
                            >
                                Notificari Programari
                            </p>

                            <br /> 
                            <br /> 

                            <SwitchOption 
                                tx = 'Programare Noua'
                                id = 'appoint'
                                for = 'appoint'
                                checked = {this.state.newAppointSwitch}
                                onChange = {() => {this.onChangeSwitch('newAppointSwitch')}}
                            />

                            <br />
                            <br />  

                            <SwitchOption 
                                tx = 'Notificari Reprogramare'
                                id = 'reschedule'
                                for = 'reschedule'
                                checked = {this.state.rescheduleSwitch}
                                onChange = {() => {this.onChangeSwitch('rescheduleSwitch')}}
                            />

                            <br /> 
                            <br /> 

                            <SwitchOption 
                                tx = 'Notificari Anulare'
                                id = 'cancel'
                                for = 'cancel'
                                checked = {this.state.cancelSwitch}
                                onChange = {() => {this.onChangeSwitch('cancelSwitch')}}
                            />

                        </div>
                    </div>
                </BrowserView>

                <MobileView>

                    <div style={{position: 'relative'}}>

                        <MServiceNav 
                            icon={<MdSecurity />}
                            title='Securitate si Notificari'
                            path={'/ca/dashboard/cab'}
                        />

                        {
                            this.state.canSave &&
                            <GoCheck
                                className="m-add-service"
                                // onClick={this.saveToDb}
                            />
                        }

                    </div>

                    <div 
                        style={{
                            padding: '20px'
                        }}
                    >

                        <div 
                            className="m-edit-s-card"
                            style={{
                                textAlign: 'left'
                            }}
                        >

                            <IconText 
                                top = '0px'
                                icon = {
                                    <MdSecurity
                                        style={{
                                            fontSize: '24px',
                                            color: '#FFC727'
                                        }} 
                                    />
                                }
                                tx = 'Schimba Parola'
                                txSize = '14px'
                                txWeight = '500'
                                txCol = '#263238'
                            />

                            <br />

                            <div className='form-control'>

                                {
                                    !this.state.showPass && (

                                        <MdVisibilityOff 
                                            className='showpass'
                                            onClick={this.onShowPassword}
                                        />
                                    )
                                }

                                {
                                    this.state.showPass && (

                                        <MdVisibility
                                            className='showpass'
                                            onClick={this.onShowPassword}
                                        />
                                    )
                                }

                                <AuthInput 
                                    type={this.state.showPass ? 'text' : 'password'}
                                    label='Parola'
                                    labelStyle={{
                                        fontSize: this.props.labelSize || '10px'
                                    }}
                                    iserror={this.state.passErr}
                                    errormsg={this.state.passErrTx}
                                    value={this.state.password}
                                    onChange={this.onEnteredPass}
                                    verified={this.state.passVerified}
                                />

                            </div>

                            <p 
                                className='security-tx px12'
                                style={{
                                    marginTop: '10px'
                                }}
                            >

                                Iti sugeram sa alegi o parola puternica, formata din
                                minim 8 caractere, care sa contina litere mici, litere
                                mari si cel putin un caracter special.

                            </p>

                            <div 
                                className='separator-line'
                                style={{
                                    marginTop: '16px'
                                }}
                            ></div>

                            <br />

                            {/* <IconText 
                                top = '0px'
                                icon = {
                                    <MdNotificationsActive
                                        style={{
                                            fontSize: '24px',
                                            color: '#FFC727'
                                        }} 
                                    />
                                }
                                tx = 'Notificari'
                                txSize = '14px'
                                txWeight = '500'
                                txCol = '#263238'
                            />

                            <br />  

                            <SwitchOption 
                                tx = 'Notificari de Sistem'
                                id = 'sys'
                                for = 'sys'
                                checked = {this.state.systemNotificationsSwitch}
                                onChange = {() => {this.onChangeSwitch('systemNotificationsSwitch')}}
                            />

                            <br /> */}

                            <div 
                                className='separator-line'
                            ></div>

                            <p 
                                className='security-tx px41'
                                style={{
                                    marginTop: '10px',
                                    color: '#455A64',
                                    fontWeight: '500'
                                }}
                            >
                                Notificari Programari
                            </p>

                            <br /> 
                            <br /> 

                            <SwitchOption 
                                tx = 'Programare Noua'
                                id = 'appoint'
                                for = 'appoint'
                                checked = {this.state.newAppointSwitch}
                                onChange = {() => {this.onChangeSwitch('newAppointSwitch')}}
                            />

                            <br />
                            <br />  

                            <SwitchOption 
                                tx = 'Notificari Reprogramare'
                                id = 'reschedule'
                                for = 'reschedule'
                                checked = {this.state.rescheduleSwitch}
                                onChange = {() => {this.onChangeSwitch('rescheduleSwitch')}}
                            />

                            <br /> 
                            <br /> 

                            <SwitchOption 
                                tx = 'Notificari Anulare'
                                id = 'cancel'
                                for = 'cancel'
                                checked = {this.state.cancelSwitch}
                                onChange = {() => {this.onChangeSwitch('cancelSwitch')}}
                            />

                        </div>

                    </div>

                </MobileView>
            </>
        )
    }
}