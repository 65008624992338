import React from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import { FaRegMoneyBillAlt } from 'react-icons/fa';
import { IoSettingsSharp } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import Declarations from '../providers/Declarations';
import Masks from '../providers/Masks';
import Utils from '../providers/Utils';

import Ws from '../providers/Ws';
import HourInterval from './HourInterval';
import IconText from './IconText';
import LocationDesc from './LocationDesc';

export default class ServiceCard extends React.Component{

    constructor(props){

        super(props);
    }

    WS = new Ws();
    M = new Masks();
    UT = new Utils();

    render(){

        return(

            <>
            
            <BrowserView>
            
                <div 
                    className={this.props.isDisabled ? "services-card disabled-sv-card" : "services-card"}
                    style={{
                        height: '196px'
                    }}
                >

                    {
                        this.props.isDisabled && (

                            <p 
                                className="reactivate-btn"
                                onClick={
                                    () => {

                                        this.props.onEnableService(this.props.serviceElem.token)
                                    }
                                }
                            >

                                Reactiveaza Serviciul

                            </p>
                        )
                    }

                    <div className="header">
                        
                        <div 
                            className="left"
                            style={{

                                margin:0,
                                textAlign: 'left',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                            }}
                        >

                        {
                            !this.props.isDisabled && (

                                
                                this.props.listaCulori.map((cEl, idx) => {
    
                                        if(cEl.val === this.props.serviceElem.color && this.props.serviceElem.id === cEl.order){
    
                                            return(
    
                                                <div 
                                                    key={'services-col-'+idx}
                                                    className="color"
                                                    style={{
                                                        background: cEl.col
                                                    }}
                                                >
                                                </div>
                                            );
                                        }
                                })
        
                                
                            )
                        }

                        {
                            this.props.isDisabled && (

                                <div 
                                    className="color"
                                    style={{
                                        background: '#C7C7C7'
                                    }}
                                >
                                </div>
                            )
                        }
                            &nbsp;

                            
                            <span
                                style={{
                                    position: 'relative',
                                    top: '2px',
                                    fontSize: '18px',
                                    color: '#455A64',
                                    fontWeight: '500'
                                }}
                            >
                                {(this.props.serviceElem['name'] || '')}
                            </span>

                            

                        </div>

                        <div className="right" style={{paddingLeft: '4px'}}>

                            <IoSettingsSharp 
                                className="settings-icon"
                                style={{
                                    color: this.props.isDisabled ? '#C7C7C7' : ''
                                }}
                                onClick={
                                    () => {

                                        this.props.onServiciuClick(this.props.serviceElem['token'], this.props.serviceElem['name'] ,this.props.serviceElem['duration'])
                                    }
                                }
                            />

                        </div>

                    </div>

                    <div className="info">

                        <div className="row">
                            
                            <div
                                className="col-l-5 col-m-4 col-s-6"
                            > 

                                <HourInterval 
                                    // ora = {svEl['ora_cal']}
                                    durata = {this.props.serviceElem['duration']}
                                    marginTop = '8px'
                                    iconSize = '20px'
                                    iconCol = {this.props.isDisabled && '#C7C7C7'}
                                    txCol = {this.props.isDisabled && '#C7C7C7'}
                                    txSize = '12px'
                                    txWeight = '500'
                                />

                                <IconText 
                                    icon = {

                                        <FaRegMoneyBillAlt 
                                            style={{
                                                fontSize: '20px',
                                                color: this.props.isDisabled ? '#C7C7C7' : (this.props.serviceElem['price'] === 0 ? '#60D84C' :'#A6A6A6')
                                            }}
                                        />
                                    }
                                    
                                    tx = {this.props.serviceElem['price'] === 0 ? 'Fara tarif' : this.props.serviceElem['price'] + ' Lei'}
                                    containerTop = '6px'
                                    txWeight = '500'
                                    txCol = {this.props.isDisabled ? '#C7C7C7' : (this.props.serviceElem['price'] === 0 ? '#60D84C' :'rgb(69, 90, 100)')}
                                    txSize = '12px'
                                />

                                <LocationDesc 
                                    location = {(this.props.serviceElem['location'] || '').trim()}
                                    locationDesc = {

                                        new Declarations().listaLocatiiIntalnire.filter(llEl => llEl['id'] === ( this.props.serviceElem['location'] || '' ).trim()).length > 0 ?
                                        new Declarations().listaLocatiiIntalnire.filter(llEl => llEl['id'] === ( this.props.serviceElem['location'] || '' ).trim())[0]['descClient'] 
                                        : ''
                                    }
                                    marginTop = '6px'
                                    iconCls = {this.props.isDisabled && 'loc-icon-faded'}
                                    zoomW = '20px' zoomH = '10px'
                                    officeW = '20px' officeH = '20px'       
                                    googleW = '22px' googleH = '20px' 
                                    skypeSize = '20px'  
                                    leftOfffice = '-1px'  
                                    txCol = {this.props.isDisabled && '#C7C7C7'}             
                                    txSize = '12px'
                                    txWeight = '500'
                                />

                            </div>

                            <div className="col-l-6 col-m-6 col-s-6" >

                                <div className="right">

                                    <h6 
                                        className="schedule"
                                        style={{color: this.props.isDisabled && '#C7C7C7'}}
                                    >

                                        {this.props.serviceElem['schedule_descr']}

                                    </h6>

                                </div>

                            </div>

                        </div>

                        <div className="separator-line"></div>

                    </div>

                    <br />
                    
                    {
                        !this.props.isDisabled && (

                            <div 
                                className="footer desktop"
                            >
        
                                <a 
                                    className="link"
                                    onClick={
                                        () => {
                                            this.props.onCopyLinkToClipBoard(this.props.serviceElem['web_link'])
                                        }
                                    }
                                >
                                    Copiaza Link
                                </a>
        
                                <a 
                                    className="link"
                                    onClick={
                                        () => {
                                            this.props.onShowToastSocial(this.props.serviceElem['web_link'])
                                        }
                                    }
                                >
                                    Share Link
                                </a>
        
                                <a 
                                    className="link"
                                    onClick={
                                        () => {
                                            this.props.onClickViewPage(this.props.serviceElem['web_link'])
                                        }
                                    }
                                >
                                    Vezi Pagina
                                </a>                       
        
                            </div>
                        )
                    }

                    {
                        this.props.isDisabled && (

                            <div className="footer">

                                <a className="link" style={{color: '#C7C7C7'}}>
                                    Copiaza Link
                                </a>

                                <a className="link" style={{color: '#C7C7C7'}}>
                                    Share Link
                                </a>

                                <a className="link" style={{color: '#C7C7C7'}}>
                                    Vezi Pagina
                                </a>                       

                            </div>
                        )
                    }
                   

                </div>
            
            </BrowserView>

            <MobileView>

                <div 
                    className={this.props.isDisabled ? "services-card disabled-sv-card" : "services-card"}
                    style={{
                        height: '196px'
                    }}
                >

                    {
                        this.props.isDisabled && (

                            <p 
                                className="reactivate-btn"
                                onClick={
                                    () => {

                                        this.onEnableService(this.props.serviceElem.token)
                                    }
                                }
                                style={{
                                    opacity: '1'
                                }}
                            >

                                Reactiveaza Serviciul

                            </p>
                        )
                    }

                    <div className="header">
                        
                        <div 
                            className="left"
                            style={{

                                margin:0,
                                textAlign: 'left',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                            }}
                        >

                        {
                            !this.props.isDisabled && (

                                
                                this.props.listaCulori.map((cEl, idx) => {
    
                                        if(cEl.val === this.props.serviceElem.color && this.props.serviceElem.id === cEl.order){
    
                                            return(
    
                                                <div 
                                                    key={'services-col-'+idx}
                                                    className="color"
                                                    style={{
                                                        width: '11px',
                                                        height: '11px',
                                                        background: cEl.col
                                                    }}
                                                >
                                                </div>
                                            );
                                        }
                                })
        
                                
                            )
                        }

                        {
                            this.props.isDisabled && (

                                <div 
                                    className="color"
                                    style={{
                                        width: '11px',
                                        height: '11px',
                                        background: '#C7C7C7'
                                    }}
                                >
                                </div>
                            )
                        }
                            &nbsp;

                            
                            <span
                                style={{
                                    position: 'relative',
                                    top: '2px',
                                    fontSize: '14px',
                                    color: '#455A64',
                                    fontWeight: '500'
                                }}
                            >
                                {(this.props.serviceElem['name'] || '')}
                            </span>

                            

                        </div>

                        <div className="right" style={{paddingLeft: '4px'}}>

                            <Link
                                to={'/ca/dashboard/cab/msv/' + this.props.serviceElem.token}
                                style={{
                                    color: '#323232'
                                }}
                            >
                                <IoSettingsSharp 
                                    className="settings-icon"
                                />
                            </Link>

                        </div>

                    </div>

                    <div 
                        className="info"
                        style={{
                            marginTop: 5
                        }}
                    >

                        <div className="row">
                            
                            <div className="col-s-4" style={{position: 'relative', left: 0}}>
                                    
                                <HourInterval 
                                    // ora = {svEl['ora_cal']}
                                    durata = {this.props.serviceElem['duration']}
                                    marginTop = '8px'
                                    iconSize = '20px'
                                    iconCol = {this.props.isDisabled && '#C7C7C7'}
                                    txCol = {this.props.isDisabled && '#C7C7C7'}
                                    txSize = '12px'
                                    txWeight = '500'
                                />

                                <IconText 
                                    icon = {

                                        <FaRegMoneyBillAlt 
                                            style={{
                                                fontSize: '20px',
                                                color: this.props.isDisabled ? '#C7C7C7' : (this.props.serviceElem['price'] === 0 ? '#60D84C' :'#A6A6A6')
                                            }}
                                        />
                                    }
                                    
                                    tx = {this.props.serviceElem['price'] === 0 ? 'Fara tarif' : this.props.serviceElem['price'] + ' Lei'}
                                    containerTop = '6px'
                                    txWeight = '500'
                                    txCol = {this.props.isDisabled ? '#C7C7C7' : (this.props.serviceElem['price'] === 0 ? '#60D84C' :'rgb(69, 90, 100)')}
                                    txSize = '12px'
                                />

                            </div>

                            <div className="col-s-1">

                                <h6 
                                    className="schedule"
                                    style={{
                                        fontSize: '12px',
                                        color: this.props.isDisabled && '#C7C7C7'
                                    }}
                                >

                                    |

                                </h6>

                            </div>

                            <div 
                                className="col-s-5" 
                            >

                                <div className="right">

                                    <h6 
                                        className="schedule"
                                        style={{
                                            fontSize: '12px',
                                            color: this.props.isDisabled && '#C7C7C7'
                                        }}
                                    >

                                        {this.props.serviceElem['schedule_descr']}

                                    </h6>

                                </div>

                            </div>

                        </div>

                        <div className="row">

                            <div className="col-s-12">


                                <LocationDesc 
                                    location = {(this.props.serviceElem['location'] || '').trim()}
                                    locationDesc = {

                                        new Declarations().listaLocatiiIntalnire.filter(llEl => llEl['id'] === ( this.props.serviceElem['location'] || '' ).trim()).length > 0 ?
                                        new Declarations().listaLocatiiIntalnire.filter(llEl => llEl['id'] === ( this.props.serviceElem['location'] || '' ).trim())[0]['descClient'] 
                                        : ''
                                    }
                                    marginTop = '6px'
                                    iconCls = {this.props.isDisabled && 'loc-icon-faded'}
                                    zoomW = '20px' zoomH = '10px'
                                    officeW = '20px' officeH = '20px'       
                                    googleW = '22px' googleH = '20px' 
                                    skypeSize = '20px'  
                                    leftOfffice = '-1px'  
                                    txCol = {this.props.isDisabled && '#C7C7C7'}             
                                    txSize = '12px'
                                    txWeight = '500'
                                />

                            </div>

                        </div>

                    </div>

                    <div className="separator-line"></div>
                    
                    {
                        !this.props.isDisabled && (

                            <div 
                                className="footer mobile"
                                style={{
                                    fontSize: '12px'
                                }}
                            >
        
                                <a 
                                    className="link"
                                    onClick={
                                        () => {
                                            this.props.onCopyLinkToClipBoard(this.props.serviceElem['web_link'])
                                        }
                                    }
                                >
                                    Copiaza Link
                                </a>
        
                                <a 
                                    className="link"
                                    onClick={
                                        () => {
                                            this.props.onShowToastSocial(this.props.serviceElem['web_link'])
                                        }
                                    }
                                >
                                    Share Link
                                </a>
        
                                <a 
                                    className="link"
                                    onClick={
                                        () => {
                                            this.props.onClickViewPage(this.props.serviceElem['web_link'])
                                        }
                                    }
                                >
                                    Vezi Pagina
                                </a>                       
        
                            </div>
                        )
                    }

                    {
                        this.props.isDisabled && (

                            <div 
                                className="footer mobile"
                                style={{
                                    fontSize: '12px'
                                }}
                            >

                                <a className="link" style={{color: '#C7C7C7'}}>
                                    Copiaza Link
                                </a>

                                <a className="link" style={{color: '#C7C7C7'}}>
                                    Share Link
                                </a>

                                <a className="link" style={{color: '#C7C7C7'}}>
                                    Vezi Pagina
                                </a>                       

                            </div>
                        )
                    }
                   

                </div>

            </MobileView>

            </>
        )
    }
}