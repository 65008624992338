import LocalStorage from '../../providers/LocalStorage';
import Masks from '../../providers/Masks';
import Utils from '../../providers/Utils';
import Ws from '../../providers/Ws';

export default class NotificationsFuncs{

    WS = new Ws();
    M = new Masks();
    UT = new Utils();

    TIP_PROGRAMARE_NOUA_ZOOM = 'programare_noua_zoom';
    TIP_PROGRAMARE_NOUA_SKYPE = 'programare_noua_skype';
    TIP_PROGRAMARE_NOUA_OFFICE = 'programare_noua_office';
    TIP_PROGRAMARE_NOUA_GMEET = 'programare_noua_gmeet';

    TIP_REPROGRAMARE_ZOOM = 'reprogramare_zoom';
    TIP_REPROGRAMARE_SKYPE = 'reprogramare_skype';
    TIP_REPROGRAMARE_OFFICE = 'reprogramare_office';
    TIP_REPROGRAMARE_GMEET = 'reprogramare_gmeet';

    TIP_ANULARE_PROGRAMARE_ZOOM = 'programare_anulata_zoom';
    TIP_ANULARE_PROGRAMARE_SKYPE = 'programare_anulata_skype';
    TIP_ANULARE_PROGRAMARE_OFFICE = 'programare_anulata_office';
    TIP_ANULARE_PROGRAMARE_GMEET = 'programare_anulata_gmeet';

    addNewNotification = async (type, message, accountToken, userToken = null) => {

        let insertNotificationQuery;

        if(userToken === null){

            insertNotificationQuery = `insert into notificari(token_cont, token_user, cand, tip, mesaj) 
                                        select '${accountToken}', ${userToken}, Now(), '${type}', '${message}'
                                       `;
        }
        else{

            insertNotificationQuery = `insert into notificari(token_cont, token_user, cand, tip, mesaj) 
                                        select '${accountToken}', '${userToken}', Now(), '${type}', '${message}'
                                       `;
        }

        let insertNewNotification = await this.WS.sqlCommand(`insert`, insertNotificationQuery);

        if(insertNewNotification.success){

            // 
        }
        else{

            // 
        }
    }

    selectAccountNotifications = async () => {

        const ACCOUNT_TK = new LocalStorage().GetAccountToken();
        //Cand vom baga multiple users pe account, atunci vom verifica si dupa user token notificarile, adica
        // daca are tipul de abonament ce corespunde unui cont cu useri multiplii facem un query separat care
        // selecteaza notificarile dupa user token, daca nu le luam direct de pe user account
        let selectNotificationsQuery = `select * from notificari where token_cont = '${ACCOUNT_TK}' `;
        let accNotifications = await this.WS.sqlCommand(`select`, selectNotificationsQuery);

        if(accNotifications.success){

            return{

                data: accNotifications.content,
                err: null
            };
        }
        else{

            return{

                data: null,
                err: 'A aparut o problema la preluarea notificarilor de catre server.'
            };
        }
    }

    markNotificationsAsSeen = async (abonament, tokenUser = null) => {

        const ACCOUNT_TK = new LocalStorage().GetAccountToken();

        let setToSeenQuery;

        if((tokenUser || '').length > 0 && abonament === 'premium'){
            
            setToSeenQuery = `update notificari set seen = ${1} where token_cont = '${ACCOUNT_TK}' and token_user = '${tokenUser}' `;
        }
        else{

            setToSeenQuery = `update notificari set seen = ${1} where token_cont = '${ACCOUNT_TK}' `;
        }

        let setNotificationsAsSeen = await this.WS.sqlCommand(`update`, setToSeenQuery);

        if(setNotificationsAsSeen.success){

            return{

                data: 'succes!',
                err: null
            }
        }
        else{

            return{

                data: null,
                err: 'A aparut o eroare la marcarea notificarilor ca vazute!'
            }
        }
    }

    getNotificationType = (type) => {

        let result = {};

        switch (type) {

            case this.TIP_PROGRAMARE_NOUA_ZOOM:
                result = {
                    type: 'programare',
                    iconType: 'zoom',
                    title: 'Programare Noua!'
                };
                break;

            case this.TIP_PROGRAMARE_NOUA_SKYPE:
                result = {
                    type: 'programare',
                    iconType: 'skype',
                    title: 'Programare Noua!'
                };
                break;
            
            case this.TIP_PROGRAMARE_NOUA_OFFICE:
                result = {
                    type: 'programare',
                    iconType: 'office',
                    title: 'Programare Noua!'
                };
                break;

            case this.TIP_PROGRAMARE_NOUA_GMEET:
                result = {
                    type: 'programare',
                    iconType: 'gmeet',
                    title: 'Programare Noua!'
                };
                break;

            case this.TIP_REPROGRAMARE_ZOOM:
                result = {
                    type: 'reprogramare',
                    iconType: 'zoom',
                    title: 'Reprogramare!'
                };
                break;

            case this.TIP_REPROGRAMARE_SKYPE:
                result = {
                    type: 'reprogramare',
                    iconType: 'skype',
                    title: 'Reprogramare!'
                };
                break;
            
            case this.TIP_REPROGRAMARE_OFFICE:
                result = {
                    type: 'reprogramare',
                    iconType: 'office',
                    title: 'Reprogramare!'
                };
                break;

            case this.TIP_REPROGRAMARE_GMEET:
                result = {
                    type: 'reprogramare',
                    iconType: 'gmeet',
                    title: 'Reprogramare!'
                };
                break;

            case this.TIP_ANULARE_PROGRAMARE_ZOOM: 
                result = {
                    type: 'anulare',
                    iconType: 'zoom',
                    title: 'Programare anulata!'
                };
                break;

            case this.TIP_ANULARE_PROGRAMARE_SKYPE: 
                result = {
                    type: 'anulare',
                    iconType: 'skype',
                    title: 'Programare anulata!'
                };
                break;

            case this.TIP_ANULARE_PROGRAMARE_OFFIC: 
                result = {
                    type: 'anulare',
                    iconType: 'office',
                    title: 'Programare anulata!'
                };
                break;

            case this.TIP_ANULARE_PROGRAMARE_GMEET: 
                result = {
                    type: 'anulare',
                    iconType: 'gmeet',
                    title: 'Programare anulata!'
                };
                break;
        }

        return result;
    }

    getNotificationTime = (notificationDate) => {

        const todayDate = new Date();

        let diffMs = (todayDate - notificationDate); // milliseconds between now & notificationDate
        
        let diffDays = Math.floor(diffMs / 86400000); // days
        let diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
        let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
        
        if(diffDays > 0){

            const formatTextZile = diffDays <= 1 ? 'zi' : 'zile';
            return `Acum ${diffDays <= 1 ? 'o' : diffDays} ${formatTextZile}`;
        }
        else if(diffHrs > 0){

            const formatTexTOre = diffHrs <= 1 ? 'ora' : 'ore';
            return `Acum ${diffHrs} ${formatTexTOre}`;
        }
        else if(diffMins > -1){

            const formatTextMinute = diffMins === 1 ? 'minut' : 'minute'; 
            return `Acum ${diffMins} ${formatTextMinute}`;
        }
    }

    generateNotificationTypeForLocation = (location) => {

        switch (location) {

            //Pentru programari =>

            case 'zoom':
                return this.TIP_PROGRAMARE_NOUA_ZOOM;

            case 'skype': 
                return this.TIP_PROGRAMARE_NOUA_SKYPE;

            case 'office':
                return this.TIP_PROGRAMARE_NOUA_OFFICE;

            case 'gmeet':
                return this.TIP_PROGRAMARE_NOUA_GMEET;

            //Pentru reprogramari =>

            case 'reprogramare-zoom':
                return this.TIP_REPROGRAMARE_ZOOM;

            case 'reprogramare-skype':
                return this.TIP_REPROGRAMARE_SKYPE;

            case 'reprogramare-office':
                return this.TIP_REPROGRAMARE_OFFICE;

            case 'reprogramare-gmeet':
                return this.TIP_REPROGRAMARE_GMEET;

             //Pentru programari anulate =>

             case 'anulare-zoom':
                return this.TIP_ANULARE_PROGRAMARE_ZOOM;

            case 'anulare-skype':
                return this.TIP_ANULARE_PROGRAMARE_SKYPE;

            case 'anulare-office':
                return this.TIP_ANULARE_PROGRAMARE_OFFICE;

            case 'anulare-gmeet':
                return this.TIP_ANULARE_PROGRAMARE_GMEET;
        }
    }

    generateMessageForNotification = (type, clientDetails) => {

        const date = clientDetails['data_cal'];
        const appointmentDate = this.M.dayOfWeek(this.M.sqlDateToJSDate(date).getDay()) + ', ' + this.M.sqlDateToJSDate(date).getDate() + ' ' +
        this.M.monthName(this.M.sqlDateToJSDate(date).getMonth()) + ' ' + this.M.sqlDateToJSDate(date).getFullYear();

        switch (type) {

            case 'programare-noua':
                return clientDetails['clie_nume'] + ' ' + clientDetails['clie_prenume'] + 
                ' a inregistrat o programare la ' + clientDetails['service_name'] + ' pentru ' + appointmentDate + ' ora ' + 
                clientDetails['ora_cal'].substr(0,5) + '.';

            case 'reprogramare':
                return clientDetails['clie_nume'] + ' ' + clientDetails['clie_prenume'] + 
                ' a replanificat programarea la ' + clientDetails['service_name'] + ' pentru ' + appointmentDate + ' ora ' + 
                clientDetails['ora_cal'].substr(0,5) + '.';

            case 'anulare-programare':
                return clientDetails['clie_nume'] + ' ' + clientDetails['clie_prenume'] + 
                ' a anulat programarea la ' + clientDetails['service_name'] + ' pentru ' + appointmentDate + ' ora ' + 
                clientDetails['ora_cal'].substr(0,5) + '.';
        }  
    }

    convertSqlDateWithTimeToJSDate = sqlDate => {
    
        if (!sqlDate) {
            console.warn("Err: sql date is null ??")
            return null;
        }

        if (sqlDate.indexOf('-') == -1) {
            console.error("Err: sql date without - separator ??")
            return null;
        }

        // daca e datetime
        if (sqlDate.indexOf('T') == -1) {
            
            let ds = sqlDate.split('-')

            let asDate = new Date(ds[0], ds[1] - 1, ds[2])
            return asDate
        }
        else {

            let sp = sqlDate.split('T')
            let ds = sp[0].split('-')
            let tm = sp[1].split(':');

            let asDate = new Date(ds[0], ds[1] - 1, ds[2], tm[0], tm[1], tm[2])
            return asDate
        }

    }
}