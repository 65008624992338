import React from 'react';
import { isMobile, MobileView } from 'react-device-detect';

import Masks from '../../../providers/Masks';
import Utils from '../../../providers/Utils';
import Ws from '../../../providers/Ws';
import ServicesFuncs from '../../logic/ServicesFuncs';
import RegisterHeader from '../RegisterHeader';

import Image from '../../../assets/Businesswoman-cuate 1.png';
import ButtonForm from '../../../commons/ButtonForm';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import Declarations from '../../../providers/Declarations';
import UserDataInputs from '../../../commons/profile/UserDataInputs';
import CompanyDataInputs from '../../../commons/profile/CompanyDataInputs';
import AddressDataInputs from '../../../commons/profile/AddressDataInputs';
import ButtonsNavRegister from '../../../commons/ButtonsNavRegister';


import MBirdAndPlantImage from '../../../assets/bird_and_plant_mobile.png';
import LocalStorage from '../../../providers/LocalStorage';
import Loader from '../../../commons/Loader';

export default class MAccountData extends React.Component{

    constructor(props){

        super(props);
        
        this.state = {

            dateProfil: {

                display_name: '',
                web_link: '',
                email: '', 
                telefon: '',
                cui: '',
                reg_com: '',
                nume: '', 
                oras: '',
                adresa: ''
            },
            emailProfil: '',

            triggerSave: false,
            isLoading: true
        };
    }

    componentsData = new Declarations().destructureDataObject(new Declarations().profileData);

    componentsStatus = {
        
        isUserDataOk: false,
        isCompanyDataOk: false,
        isAddressDataOk: false
    };

    WS = new Ws();
    UT = new Utils();
    M = new Masks();
    SF = new ServicesFuncs();
    

    componentDidMount(){

        this.onGetDateCompanie();
    }

    extractContactData = (userContactsArray, contactType) =>{

        let _contactRows = userContactsArray.filter(ucEl => ucEl['tip'] == contactType);

        if(_contactRows.length < 1){

            return { err: 'Contact type not found', data: null };
        }

        return { err: null, data: _contactRows[0]['value'] };
    }

    onGetDateCompanie = async () => {

        const TOKEN_CONT = new LocalStorage().GetAccountToken();

        const WRK_TOKEN =  await this.WS.getUserToken();

        let q = `select * from accounts where token = '${TOKEN_CONT}'  `;
        // 

        let _accountData = this.WS.sqlCommand(`select`, q);
        // 

        // let q2 = `select * from users `;
        let q2 = `select * from user_contacts where token_user = ( select ifnull(token,'xxx') from users where wrk_token = '${WRK_TOKEN}' ) `;
        let _userData = this.WS.sqlCommand(`select`, q2);

        let [accountData, userData] = await Promise.all( [_accountData, _userData] );

        if(accountData.success && userData.success){

            let contactEmail = this.extractContactData(userData.content, 'email');

            if(contactEmail.err !== null){

                contactEmail = '';
            }
            else{

                contactEmail = contactEmail.data;
            }

            const profileData = {

                ...accountData.content[0],
                email: contactEmail
            };

            this.componentsData = new Declarations().updateComponentsData(this.componentsData, profileData, 'profile');

            if(accountData.content[0]['display_name'] !== null && accountData.content[0]['display_name'] !== undefined &&
                accountData.content[0]['telefon'] !== null && accountData.content[0]['telefon'] !== undefined
            )
            {
                if(
                    accountData.content[0]['display_name'].length > 0 &&
                     accountData.content[0]['telefon'].length > 0
                ){

                    this.componentsStatus.isUserDataOk = true;
                }
            }

            if(accountData.content[0]['nume'] !== null && accountData.content[0]['display_name'] !== undefined &&
                accountData.content[0]['cui'] !== null && accountData.content[0]['cui'] !== undefined &&
                accountData.content[0]['reg_com'] !== null && accountData.content[0]['reg_com'] !== undefined 
            )
            {
                if(
                    accountData.content[0]['nume'].length > 0 &&
                     accountData.content[0]['cui'].length > 0 &&
                     accountData.content[0]['reg_com'].length > 0
                ){

                    this.componentsStatus.isCompanyDataOk = true;
                }
            }

            if(accountData.content[0]['adresa'] !== null && accountData.content[0]['adresa'] !== undefined &&
                accountData.content[0]['oras'] !== null && accountData.content[0]['oras'] !== undefined
            )
            {
                if(
                    accountData.content[0]['adresa'].length > 0 &&
                     accountData.content[0]['oras'].length > 0
                ){

                    this.componentsStatus.isAddressDataOk = true;
                }
            }

            this.setState({

                dateProfil: {

                    display_name: accountData.content[0]['display_name'] || '',
                    web_link: accountData.content[0]['web_link'] || '',
                    email: accountData.content[0]['email'] || '', 
                    telefon: accountData.content[0]['telefon'] || '',
                    cui: accountData.content[0]['cui'] || '',
                    reg_com: accountData.content[0]['reg_com'] || '',
                    nume: accountData.content[0]['nume'] || '', 
                    oras: accountData.content[0]['oras'] || '', 
                    adresa: accountData.content[0]['adresa'] || '', 
                },
                emailProfil: contactEmail,
                isLoading: false
            });
        }
    }

    inputStatus = (status, statusName, inputName, data, isVerify = false) => {

        if(isVerify){

            this.componentsStatus[statusName] = data;
        }
        else{

            this.componentsData[inputName] = data;

            
    
            this.setState({
                
                [statusName]: status
            },
                () => {
    
                    // this.checkSaveButtonStatus();
                }
            );
        }
    }

    
    onCheckAndSave = async () => {

        const result = Object.values(this.componentsStatus).every(item => item === true);;
        const TOKEN_CONT = new LocalStorage().GetAccountToken();

        
        
        if(result){

            // const queryObject = new Declarations().generateQuerySet('profile', this.componentsData);
            // const queryArr = new Declarations().onGenerateQueryArray(queryObject);
            let queryUpdateAccountData = `update accounts set
                display_name = '${this.componentsData.display_name}', telefon = '${this.componentsData.telefon}',
                nume = '${this.componentsData.nume}', reg_com = '${this.componentsData.reg_com}', 
                cui = '${this.componentsData.cui}', oras = '${this.componentsData.oras}', 
                adresa = '${this.componentsData.adresa}'
                where token = '${TOKEN_CONT}'
            `;

            let updateAccountData = await this.WS.sqlCommand(`update`, queryUpdateAccountData);

            if(updateAccountData.success){

                // this.props.history.push('');
                if(isMobile){

                    this.props.history.push("/inregistrare/date/avatar");
                }
                else{

                    this.props.history.push("/inregistrare/date/identificator");

                }
            }
        }
        else{

            this.setState({
                triggerSave: false
            })
        }
    }

    onTriggerSave = () => {

        this.setState({

            triggerSave: true
        },
        
            () => {

                this.onCheckAndSave();
            }
        )
    }

    render(){

        if(this.state.isLoading){

            return(

                <Loader fullPage = {true} />
            )
        }

        return(

            <MobileView>
                
                <div 
                    style={{
                        padding: '20px',
                        minHeight: '1650px',
                        background: `url(${MBirdAndPlantImage}) bottom no-repeat`
                    }}
                >
                
                    <RegisterHeader />

                    <div className="m-edit-s-card" style={{marginTop: '20px', textAlign: 'left'}}>

                        <p className='register-tx sm register-title bold'>
                            Datele companiei tale!
                        </p>

                        <p className='register-tx sm' style={{marginTop: '16px'}}>
                            Completeaza datele companiei, asa cum vor aparea ele pe facturile catre clientii tai.
                        </p>

                    </div>
                    
                    <div className="m-edit-s-card" style={{marginTop: '20px', textAlign: 'left'}}>
                        
                        <p className='register-tx sm reigster-title bold'>
                            Date Utilizator
                        </p>

                        <UserDataInputs 
                            data = {this.state.dateProfil}
                            email = {this.state.emailProfil}
                            triggerSave = {this.state.triggerSave}
                            inputStatus = {this.inputStatus}
                            disableLink = {true}
                            disableEmail = {true}
                            style={{
                                marginTop: '16px'
                            }}
                        />

                        <p className='register-tx sm reigster-title bold' style={{marginTop: '16px'}}>
                            Date Companie
                        </p>

                        <CompanyDataInputs 
                            data = {this.state.dateProfil}
                            triggerSave = {this.state.triggerSave}
                            inputStatus = {this.inputStatus}
                            style={{
                                marginTop: '16px'
                            }}
                        />

                        <div 
                            className='separator-line'
                            style={{
                                marginTop: '16px'
                            }} 
                        ></div>

                        <AddressDataInputs 
                            data = {this.state.dateProfil}
                            triggerSave = {this.state.triggerSave}
                            inputStatus = {this.inputStatus}
                            
                            style={{
                                marginTop: '16px'
                            }} 
                        />

                       <ButtonsNavRegister 
                            tx = 'Continua'
                            onPressBtn = {this.onTriggerSave}
                            onBack = {this.props.history.goBack}
                       />

                    </div>

                </div>

            </MobileView>
        );
    }
}