import React from 'react';
import Masks from '../../providers/Masks';
import Utils from '../../providers/Utils';
import Ws from '../../providers/Ws';
import TextareaDescription from '../TextareaDescription';

export default class SvDescription extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            descriere: this.props.descriere || '',
            descriereTmp: this.props.descriere || '',
            descriereLength: 0,
        };
    }

    WS = new Ws();
    M = new Masks();
    UT = new Utils();

    componentDidMount(){
        
        this.setDescriereServiciu();
    }   
    
    componentDidUpdate(prevPros, _){

        if(prevPros.triggerSave !== this.props.triggerSave){

            this.onGenerateQuery();
        }

        if(prevPros['descriere'] !== this.props['descriere']){

            this.setState({

                descriereTmp: this.props.descriere
            });
        }
    }

    setDescriereServiciu = () => {

        let length = this.state.descriere.replaceAll(/\s/g,'').length;

        this.setState({

            descriereLength: length
        })

    }

    
    onChangeDescriere = (ev) => {

        let count = ev.target.value.replaceAll(/\s/g,'').length;
        let doSave = true;

        if(ev.target.value === this.state.descriereTmp){

            doSave = false;
        }

        if(count <= 500){
       
            this.setState({
                
                descStatus: doSave,
                descriere: ev.target.value,
                descriereLength: +count
            },
            
                () => {

                    this.props.inputStatus(doSave, 'descStatus', 'descriereSv', ev.target.value);
                }
            )
        }
        else{

            const errorTx = 'Limita de caractere a descrierii serviciului a fost atinsa.';
            this.props.showToastError(errorTx);
            // alert('Limita caractere atinsa!')
        }
    }

    onGenerateQuery = () => {

        const result = {

            data: [
                {
                    itemName: 'description',
                    itemData: `'` + this.state.descriere + `'`
                }
            ],
            err: null
        }
    }

    render(){

        return(

            <div 
                className="form-control"
                style={{
                    ...this.props.style,
                    marginBottom: 20
                }}
            >

                <TextareaDescription
                    style={{

                        height: this.props.textAreaHeight || '300px'
                    }}
                    value={this.state.descriere}
                    onChange={this.onChangeDescriere}
                    textLenght={this.state.descriereLength}
                />

            </div>
        )
    }
}