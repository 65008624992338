import React from 'react';

import { IoIosArrowForward } from 'react-icons/io';
import ButtonForm from '../../../commons/ButtonForm';
import IconText from '../../../commons/IconText';
import { ReactComponent as PinDrop } from '../../../assets/pin_drop.svg';

import Masks from '../../../providers/Masks';
import Utils from '../../../providers/Utils';
import Ws from '../../../providers/Ws';
import ServicesFuncs from '../../logic/ServicesFuncs';
import RegisterHeader from '../RegisterHeader';
import SvLocation from '../../../commons/service/SvLocation';
import Declarations from '../../../providers/Declarations';
import { RiFolderReduceFill } from 'react-icons/ri';
import SvNameAndLink from '../../../commons/service/SvNameAndLink';
import SvDescription from '../../../commons/service/SvDescription';
import ErrorToast from '../../../commons/ErrorToast';
import { FaCalendarCheck } from 'react-icons/fa';
import SvDisponibility from '../../../commons/service/SvDisponibility';
import { MdPalette } from 'react-icons/md';
import SvColor from '../../../commons/service/SvColor';
import { BrowserView, MobileView } from 'react-device-detect';
import ButtonsNavRegister from '../../../commons/ButtonsNavRegister';

import CanaryPlantImage from '../../../assets/canary_plant_bg.png';
import MBirdAndPlantImage from '../../../assets/bird_and_plant_mobile.png';
import LocalStorage from '../../../providers/LocalStorage';
import Loader from '../../../commons/Loader';

export default class ColorConfig extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            
            showToastPopupError: false,
            actionTx: '',
            eroareTx: '',
            culoare: 'red',
            serviceTk: '',

            triggerSave: false,
            isLoading: true
        };
    }

    componentData = new Declarations().serviceData['color'];
    componentStatus = {

        isColorOk: true
    }

    errorMessages = '';

    WS = new Ws();
    M = new Masks();
    UT = new Utils();
    SF = new ServicesFuncs();

    componentDidMount(){

        this.getServiceData();
    }

    getServiceData = async () => {

        const TOKEN_CONT = new LocalStorage().GetAccountToken();

        let querySelectService = `select * from services where account_tk = '${TOKEN_CONT}' `;
        let service = await this.WS.sqlCommand(`select`, querySelectService);

        if(service.success){

            this.componentData = new Declarations().updateComponentsData(this.componentData, service.content[0]);
            

            this.setState({

                culoare: service.content[0]['color'],
                serviceTk: service.content[0]['token'],
            })
            
        }

        this.setState({

            isLoading: false
        })
    }

    inputStatus = (status, statusName, inputName, data, isVerify = false) => {

        

        if(isVerify){

            this.componentStatus[statusName] = data;
        }
        else{

            this.componentData[inputName] = data;    
    
            this.setState({
                
                [statusName]: status
            },
                () => {
    
                    // this.checkSaveButtonStatus();
                }
            );
        }
    }

    onCheckAndSave = async () => {

        if(this.componentStatus['isColorOk']){

            const queryArr = [

                {
                    itemName: 'color',
                    itemData: `'` + this.componentData['culoare'] + `'`
                },
            ];
    
            let uq = this.SF.onSaveServiceData(queryArr, false, this.state.serviceTk);
             
            

            let updateServiciu = await this.WS.sqlCommand(`update`, uq.data.substring(1, uq.data.length - 2));

            if(updateServiciu.success){

                this.props.history.push('/inregistrare/serviciu/finalizare-serviciu');
            }
        }
        else{

            this.setState({

                triggerSave: false
            });
        }
        
    }

    onTriggerSave = () => {

        this.setState({

            triggerSave: true
        },
             () => {

                this.onCheckAndSave();
            }
        );
    }

    
    render(){

        if(this.state.isLoading){

            return(

                <Loader 
                    fullPage = {true}
                />
            );
        }

        return(
            <>
                <BrowserView>

                <div className='register-container-desktop'>

                    <RegisterHeader isServiceStep = {true}/>

                    <div className='service-details-card' style={{marginTop: '20px'}}>

                        <div className='register-center-tx-wrapper' style={{width: '400px'}}>

                            <IconText 
                                icon = {
                                    <MdPalette 
                                        style={{
                                            fontSize: '22px',
                                            color: '#FFC727'
                                        }}
                                    />
                                }
                                tx = 'Culoare in calendar'
                                txSize = '16px'
                                txWeight = '500'
                                txCol = '#455A64'
                                side = 'center'
                            /> 

                            <p className='register-tx' style={{marginTop: '16px'}}>
                                Selecteaza o culoare pentru acest serviciu. 
                                Te va ajuta sa identifici repede serviciile in calendar.
                            </p>

                            <SvColor 
                                culoare = {this.state.culoare}
                                triggerSave = {this.state.triggerSave}
                                inputStatus = {this.inputStatus}
                                hideText = {true}
                            />


                            <ButtonForm 
                                type='button'
                                tx={
                                    <>
                                        <p style={{display: 'inline-block', position: 'relative', top: '-2px', margin: 0}}>
                                            Continua &nbsp;
                                        </p> 
                                        <IoIosArrowForward className='register-next-arrow'/>
                                    </>
                                }
                                
                                style={{
                                    marginTop: '20px',
                                    width: 'auto',
                                    height: '56px',
                                    background: '#F8BC05',
                                    fontSize: '16px',
                                    padding: '0px 35px 0px 35px',
                                }}
                                onClick={
                                    this.onTriggerSave
                                }
                            />

                        </div>
                            
                    </div>

                    <img 
                        className='bottom-pg-img'
                        src={CanaryPlantImage}
                        alt = 'Planary...'
                        style={{
                            marginTop: '20px'
                        }}
                    />

                </div>

                </BrowserView>

                <MobileView>

                <div 
                    style={{
                        padding: '20px',
                        minHeight: '100vh',
                        background: `url(${MBirdAndPlantImage}) bottom no-repeat`
                    }}
                >
                    
                    <RegisterHeader isServiceStep = {true} />
                    
                    <div className="m-edit-s-card" style={{marginTop: '20px', textAlign: 'left'}}>

                        <IconText 
                            icon = {
                                <MdPalette 
                                    style={{
                                        fontSize: '22px',
                                        color: '#FFC727'
                                    }}
                                />
                            }
                            tx = 'Culoare in calendar'
                            txSize = '16px'
                            txWeight = '500'
                            txCol = '#455A64'
                        /> 
                        
                        <br />

                        <SvColor 
                            culoare = {this.state.culoare}
                            triggerSave = {this.state.triggerSave}
                            inputStatus = {this.inputStatus}
                        />
                        
                        <ButtonsNavRegister 
                            tx = 'Continua'
                            onPressBtn = {this.onTriggerSave}
                            onBack = {this.props.history.goBack}
                        />

                    </div>
                    
                </div>


            </MobileView>

            </>
        );
    }
}