import React from 'react';

import LocalStorage from '../../providers/LocalStorage';
import Ws from '../../providers/Ws';
import Utils from '../../providers/Utils';
import Declarations from '../../providers/Declarations';

import { RiFolderReduceFill } from 'react-icons/ri';
import { ImLocation2 } from 'react-icons/im';
import { MdDone, MdShortText } from 'react-icons/md';
import { MdAlarm } from 'react-icons/md';
import { FaRegMoneyBillAlt } from 'react-icons/fa';
import { FaCalendarCheck } from 'react-icons/fa';
import { RiCupFill } from 'react-icons/ri';
import { IoMdColorPalette } from 'react-icons/io';
import { GoCheck } from 'react-icons/go';
import { BiNotificationOff, BiPlus } from 'react-icons/bi';
import { IoSettingsSharp } from 'react-icons/io5';
import { MdDeleteForever } from 'react-icons/md';
import { RiExchangeFundsLine } from 'react-icons/ri';
import { RiMailCheckFill } from 'react-icons/ri';
import { ReactComponent  as ArticleIcon} from '../../assets/articleIcon.svg';
import { ReactComponent as NotesIcon } from '../../assets/notes.svg';
import { ReactComponent as PinDrop } from '../../assets/pin_drop.svg';

import './Dashboard.css';
import AuthInput from '../../commons/AuthInput';
import LinkPreview from '../../commons/LinkPreview';
import Location from '../../commons/Location';
import TextareaDescription from '../../commons/TextareaDescription';
import SelectInput from '../../commons/SelectInput';
import SelectBreak from '../../commons/SelectBreak';
import SelectOthers from '../../commons/SelectOthers';
import SelectProgram from '../../commons/SelectProgram';
import ConfigSwitch from '../../commons/ConfigSwitch';
import ChairImage from '../../assets/comfortable_chair.svg';
import CopiedLinkPopUp from '../../commons/CopiedLinkPopUp';
import Masks from '../../providers/Masks';
import ButtonSaveDashboard from '../../commons/ButtonSaveDashboard';
import SuccessSaveToast from '../../commons/SuccessSaveToast';
import ErrorToast from '../../commons/ErrorToast';
import SharedSocialToast from '../../commons/SharedSocialToast';
import DisplaySchedule from '../../commons/DisplaySchedule';
import ColorPicker from '../../commons/ColorPicker';
import InputValidation from '../logic/InputValidation';
import ServicesFuncs from '../logic/ServicesFuncs';
import SvNameAndLink from '../../commons/service/SvNameAndLink';
import SvLocation from '../../commons/service/SvLocation';
import SvDescription from '../../commons/service/SvDescription';
import SvDuration from '../../commons/service/SvDuration';
import SvPrice from '../../commons/service/SvPrice';
import SvDisponibility from '../../commons/service/SvDisponibility';
import SvBreaks from '../../commons/service/SvBreaks';
import SvSchedule from '../../commons/service/SvSchedule';
import SvColor from '../../commons/service/SvColor';
import Loader from '../../commons/Loader';
import SvRecall from '../../commons/service/SvRecall';
import SvEmailReview from '../../commons/service/SvEmailReview';
import SvTermsAndConditions from '../../commons/service/SvTermsAndConditions';

export default class Serviciu extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            /////////////////////////////////
            //                             //         
            //     State pentru stocare    //
            //                             //
            /////////////////////////////////
            detaliiServiciu: {},

            accountLink: '',
            abonament: 'gratuit',
  
            programe: [],
            queryArr: [],
            integrations: [],

            showToastPopup: false,
            showToastPopupError: false,
            actionTx: '',
            showToastSocial: false,

            nameStatus: false,
            linkStatus: false,
            locatieStatus: false,
            descStatus: false,
            durataStatus: false,
            tarifStatus: false,
            radioStatusTarif: false,
            nrZileStatus: false,
            tipZileStatus: false,
            pauzaInainteStatus: false,
            pauzaDupaStatus: false,
            programStatus: false,
            colorStatus: false,
            hasRecallStatus: false,
            hasReviewStatus: false,
            hasCancelStatus: false,
            recallDaysNoStatus: false,
            recallDaysTypeStatus: false,
            recallDescStatus: false,
            reviewDescStatus: false,
            cancelDescStatus: false,
            radioStatusDispo: false,
            dataStartStatus: false,
            dataEndStatus: false,

            closeService: false,
            triggerSave: false,
            isLoading: true
        }
    }

    WS = new Ws();
    UT = new Utils();
    M = new Masks();
    IV = new InputValidation();
    SF = new ServicesFuncs();

    componentsData = new Declarations().destructureDataObject(new Declarations().serviceData);

    componentsStatus = {
        
        isNameAndLinkOk: true,
        isLocationOk: true,
        isDescrOk: true,
        isDurationOk: true,
        isPriceOk: true,
        isDispoOk: true,
        isBreakOk: true,
        isScheduleOk: true,
        isColorOk: true,
        isRecallOk: true,
        isReviewOk: true,
        isCancelOk: true
    };

    errorMessages = '';

    componentDidMount(){

        if(this.props.tokenServiciuSelectat.length > 10){

            // this.getDateServiciu()
            // this.getDate()
            this.getDate();
        }

        if(this.props.tokenServiciuSelectat.length < 10 || 
            this.props.tokenServiciuSelectat === null
            ){

            
                this.getDatePorgram();
        }

    }

    componentDidUpdate = (prevProps) => {

        if(prevProps.tokenServiciuSelectat !== this.props.tokenServiciuSelectat){

            if(this.props.tokenServiciuSelectat.length > 10){

                // this.getDateServiciu()
                
                this.setState({

                    canSave: false,
                    closeService: false,
                    isLoading: true
                },
                
                    () => {

                       this.getDate();
                    }
                );
            }
    
            if(this.props.tokenServiciuSelectat.length < 10 || 
                this.props.tokenServiciuSelectat === null
                ){
                    

                this.setState({

                    canSave: false,
                    closeService: false,
                    isLoading: true
                },
                
                    () => {

                        this.resetToDefaultState();
                    }
                );
            }
        }
        
    }

    getDate = async () => {

        const TOKEN_CONT = new LocalStorage().GetAccountToken();

        let q1 = `select * from programs where account_tk = '${TOKEN_CONT}' and ifnull(sters,0)!=1`;
        let q2 = `select * from services where account_tk = '${TOKEN_CONT}' and token = '${this.props.tokenServiciuSelectat}' `;

        let _programs = this.WS.sqlCommand(`select`, q1);
        let _dateServiciu = this.WS.sqlCommand(`select`, q2);

        let [programs, dateServiciu] = await Promise.all([_programs, _dateServiciu])
        

        if(programs.success && dateServiciu.success){

            this.componentsData = new Declarations().updateComponentsData(this.componentsData, dateServiciu.content[0]);

            const integrationsData = await this.SF.getIntegrationsData();

            // alert('Ayaya!')
            this.setState({
                programe: programs.content,
                detaliiServiciu: dateServiciu.content[0],
                integration: integrationsData,
                isLoading: false,
                showConfig: false
            },
            
                () => {

                    this.setAccountLink();
                }
            )

            
            
        }
        else{

            this.setState({
                showToastPopupError: true,
                actionTx: 'Eroare preluare date serviciu.'
            });
            throw new Error('eroare server response:  ' + dateServiciu.message + '\n' + programs.message)
        }
        
    }

    getDatePorgram = async () => {

        const TOKEN_CONT = new LocalStorage().GetAccountToken();

        let q1 = `select * from programs where account_tk = '${TOKEN_CONT}' and ifnull(sters,0)!=1`;

        let _programs = this.WS.sqlCommand(`select`, q1);
        
        let [programs] = await Promise.all([_programs]);

        if(programs.success){

            this.componentsData['programTk'] = programs.content[0]['token'];
            const integrationsData = await this.SF.getIntegrationsData();

            this.setState({

                programe: programs.content,
                programTk: programs.content[0].token,
                integrations: integrationsData,
                isLoading: false
            },

                () => {

                    this.setAccountLink();
                }
            )

            
        }
        else{

            this.setState({
                showToastPopupError: true,
                actionTx: 'Eroare preluare programe.'
            });
            throw new Error('eroare server response:  ' + programs.message)
        }
    }

    resetToDefaultState = () => {

        this.componentsData = new Declarations().destructureDataObject(new Declarations().serviceData);

        this.setState({

            detaliiServiciu: {},
    
            showToastPopup: false,
            showToastPopupError: false,
            actionTx: '',
            showToastSocial: false,

            /////////////////////////////////
            //                             //         
            //   State input errors text   //
            //                             //
            /////////////////////////////////

            nameStatus: false,
            linkStatus: false,
            locatieStatus: false,
            descStatus: false,
            durataStatus: false,
            tarifStatus: false,
            radioStatusTarif: false,
            nrZileStatus: false,
            tipZileStatus: false,
            pauzaInainteStatus: false,
            pauzaDupaStatus: false,
            programStatus: false,
            colorStatus: false,
            hasRecallStatus: false,
            hasReviewStatus: false,
            hasCancelStatus: false,
            recallDaysNoStatus: false,
            recallDaysTypeStatus: false,
            recallDescStatus: false,
            reviewDescStatus: false,
            cancelDescStatus: false,
            radioStatusDispo: false,
            dataStartStatus: false,
            dataEndStatus: false,
            showConfig: false,

            closeService: false,
            isLoading: true

        },
        
            async () => {

                await this.getDatePorgram();
            }
        );
    }

    setAccountLink = async () => {

        const accLink = await this.UT.getAccountLink();

        const accountToken = new LocalStorage().GetAccountToken();
        const qAccount = `select * from accounts where token = '${accountToken}' `;
        let account = await this.WS.sqlCommand(`select`, qAccount);

        

        this.setState({

            accountLink: accLink || '',
            abonament: account.content[0].abonament
        });
    }

    saveToDb = async (result) => {

        const token = this.UT.generateToken(); //used only when creating a service
        const TOKEN_CONT = new LocalStorage().GetAccountToken();
        // let userData = await this.WS.getUserData()
        const draft = 0;

        if(this.props.tokenServiciuSelectat.length < 10){

            let cq = this.SF.onSaveServiceData(result, true, token);

            

            let createService = await this.WS.sqlCommand(`update`, cq.data.substr(1, cq.data.length - 2));

            

            if(createService.success){

                this.props.onUpdateServiceList(true);
                // alert('Serviciu creat cu succes!')
                this.setState({

                    canSave: false,
                    closeService: true,
                    showToastPopup: true,
                    actionTx: 'a fost creat cu succes!',

                    nameStatus: false,
                    linkStatus: false,
                    locatieStatus: false,
                    descStatus: false,
                    durataStatus: false,
                    tarifStatus: false,
                    radioStatusTarif: false,
                    nrZileStatus: false,
                    tipZileStatus: false,
                    pauzaInainteStatus: false,
                    pauzaDupaStatus: false,
                    programStatus: false,
                    colorStatus: false,
                    hasRecallStatus: false,
                    hasReviewStatus: false,
                    hasCancelStatus: false,
                    recallDaysNoStatus: false,
                    recallDaysTypeStatus: false,
                    recallDescStatus: false,
                    reviewDescStatus: false,
                    cancelDescStatus: false,
                    radioStatusDispo: false,
                    dataStartStatus: false,
                    dataEndStatus: false,
                })
            }
            else {

                this.setState({
                    showToastPopupError: true,
                    actionTx: 'Eroare creare serviciu.'
                });
                throw new Error('Eroare Server Response:  ' + createService.message)
            }
        }

         if (this.props.tokenServiciuSelectat.length >= 10){

            // alert("Modific!")
            let uq = this.SF.onSaveServiceData(result, false, this.props.tokenServiciuSelectat);
             
            

            let updateServiciu = await this.WS.sqlCommand(`update`, uq.data.substr(1, uq.data.length - 2));

            if(updateServiciu.success){

                this.props.onUpdateServiceList(true);
                await this.getDate();
                
                // alert("Serviciu Modificat cu Succes!");
                this.setState({
                    canSave: false,
                    
                    showToastPopup: true,
                    actionTx: 'a fost salvat cu succes!',
                    triggerSave: false,

                    nameStatus: false,
                    linkStatus: false,
                    locatieStatus: false,
                    descStatus: false,
                    durataStatus: false,
                    tarifStatus: false,
                    radioStatusTarif: false,
                    nrZileStatus: false,
                    tipZileStatus: false,
                    pauzaInainteStatus: false,
                    pauzaDupaStatus: false,
                    programStatus: false,
                    colorStatus: false,
                    hasRecallStatus: false,
                    hasReviewStatus: false,
                    hasCancelStatus: false,
                    recallDaysNoStatus: false,
                    recallDaysTypeStatus: false,
                    recallDescStatus: false,
                    reviewDescStatus: false,
                    cancelDescStatus: false,
                    radioStatusDispo: false,
                    dataStartStatus: false,
                    dataEndStatus: false,
                })
            }
            else{

                this.setState({
                    showToastPopupError: true,
                    actionTx: 'Eroare actualizare serviciu.'
                });
                throw new Error('Eroare Server Response:  ' + updateServiciu.message)
            }
        }
        
    }

    disableService = async () =>{

        let dq = `update services set deleted = 1
                    where token = '${this.props.tokenServiciuSelectat}'
        `

        
        

        let disablerviciu = await this.WS.sqlCommand(`update`, dq);

        if(disablerviciu.success){

            this.setState({

                showToastPopup: true,
                actionTx: 'a fost sters cu succes!',
                closeService: true
            });
            // this.props.onShowServicii();
            this.props.onUpdateServiceList(true);
            // alert("Serviciu Dezactivat cu Succes!");
        }
        else{

            this.setState({
                showToastPopupError: true,
                actionTx: 'Eroare actualizare serviciu.'
            });
            
            throw new Error('Eroare Server Response:  ' + disablerviciu.message)
        }
    }

    changeSaveButtonStatus = () => {

        let status = null;

        if(
            !this.state.nameStatus && !this.state.linkStatus &&
            !this.state.locatieStatus && !this.state.descStatus &&
            !this.state.durataStatus && !this.state.tarifStatus && 
            !this.state.radioStatusDispo &&
            !this.state.dataStartStatus && !this.state.dataEndStatus &&
            !this.state.radioStatusTarif && !this.state.nrZileStatus &&
            !this.state.tipZileStatus && !this.state.pauzaInainteStatus &&
            !this.state.pauzaDupaStatus && !this.state.programStatus &&
            !this.state.colorStatus && !this.state.hasRecallStatus &&
            !this.state.hasReviewStatus && !this.state.hasCancelStatus &&
            !this.state.recallDaysNoStatus && !this.state.recallDaysTypeStatus &&
            !this.state.reviewDescStatus && !this.state.cancelDescStatus
        ){

            status = false;
        }
        else{

            status = true;
        }

        this.setState({

            canSave: status
        })
    }

    inputStatus = (status, statusName, inputName, data, isVerify = false) => {

        if(isVerify){

            this.componentsStatus[statusName] = data;
        }
        else{

            this.componentsData[inputName] = data;

            
    
            this.setState({
                
                [statusName]: status
            },
                () => {
    
                    this.changeSaveButtonStatus();
                }
            );
        }
    }

    onAddErrorMessage = (errorTx) => {

        this.errorMessages = errorTx;
    }

    onChangeShowConfig = () => {

        this.setState({

            showConfig: !this.state.showConfig
        })
    }

     
    onCheckAndSave = async () => {

        const result = Object.values(this.componentsStatus).every(item => item === true)
        
        if(result){

            const queryObject = new Declarations().generateQuerySet('service', this.componentsData);
            const queryArr = new Declarations().onGenerateQueryArray(queryObject);
            
            await this.saveToDb(queryArr);
        }
        else{

            

            let showError = false;
            let errorTx = '';

            if(this.errorMessages.length > 0){
                
                showError = true;

                // for(let i = 0; i < this.errorMessages.length; i++){

                //     errorTx += this.errorMessages[i] + '\n';
                // }
            }

            this.setState({

                showToastPopupError: showError,
                actionTx: this.errorMessages,
                triggerSave: false
            });
        }
    }

    onCopyLinkToClipBoard = async (result) => {
        
        // const linkRes = await this.UT.onCopyLinkToClipBoard(this.state.link);

        if(result.err !== null){

            this.setState({
                showToastPopupError: true,
                actionTx: result.err
            });
        }
        else{

            this.setState({
                copiedLink: result.data,
                isLinkCardShown: true
            }); 
        }
    }


    onLinkCardClose = (close) => {

        this.setState({

            isLinkCardShown: close
        })
    }


    onShowEditSchedule = () => {

        this.props.onShowEditSchedule(this.state.programTk);
    }
    
    onCloseToastPopup = (type) => {

        this.setState({

            [type]: false
        },
        
            () => {

                if(this.state.closeService){

                    this.props.onShowServicii();
                }
            }
        );
    }

    onShowToastSocial = () => {

        this.setState({

            showToastSocial: true
        });
    }

    showToastError = (errorTx) => {

        this.setState({

            showToastPopupError: true,
            actionTx: errorTx
        });
    }

    onTriggerSave = () => {

        this.setState({

            triggerSave: true
        },
            () => {

                this.onCheckAndSave();
            }
        );
    }

    render(){
        
        return(
            <div 
                className="rootable-page"
            >

                {
                    this.state.showToastSocial && (

                        <SharedSocialToast 
                            onClosePopup = {this.onCloseToastPopup}
                            company = {this.state.accountLink}
                            webLink = {this.state.detaliiServiciu['web_link']}
                        />
                    )
                }

                {
                    this.state.showToastPopupError && (

                        <ErrorToast 
                            action={this.state.actionTx}
                            onClosePopup = {this.onCloseToastPopup}
                        />
                    )
                }

                {
                    this.state.showToastPopup && (

                        <SuccessSaveToast 
                            onClosePopup = {this.onCloseToastPopup}
                            saveName = 'Serviciul'
                            action = {this.state.actionTx}
                        />
                    )
                }

                {
                    this.state.isLinkCardShown && (

                        <CopiedLinkPopUp 
                            link={this.state.copiedLink}
                            onClosePopup = {this.onLinkCardClose}
                        />
                    )
                    
                }

                <div 
                    className="wrapper-dashboard" 
                    style={{
                        position: 'relative',
                        margin:'30px auto'
                    }}
                >

                    <ButtonSaveDashboard 
                        canSave = {this.state.canSave}
                        onClick={this.onTriggerSave}
                    />

                        {
                            this.state.isLoading && (

                                <Loader 
                                    fullPage = {true}
                                />
                            )
                        }

                        {
                            !this.state.isLoading && (

                            <div className="service-details-card">

                                <div className="row">

                                    {/* <Loader /> */}

                                    {
                                        //"col-l-3 col-m-3 col-s-12"
                                    }

                                    <div
                                        className="col-l-8 col-m-8 col-s-8"
                                        style={{
                                            position: 'relative',
                                            // paddingRight: 20,
                                            // borderRight: '1px solid rgb(224, 224, 224)'
                                        }}
                                    >
                                                
                                        <h3 
                                            className="title"
                                            style={{textAlign: 'left'}}
                                        >
                                                
                                                <p 
                                                    style={{
                                                            display: 'inline-block', 
                                                            position: 'relative', 
                                                            top: 3, 
                                                            left: 0, 
                                                            margin: 0,
                                                            color: 'rgb(255, 199, 39)',
                                                            fontSize: '22px'
                                                        }}
                                                >
                                                    <RiFolderReduceFill /> 

                                                </p>

                                                <p 
                                                    style={{
                                                        display: 'inline-block', 
                                                        position: 'relative', 
                                                        left: 10,
                                                        margin: 0,
                                                        color: 'rgb(116, 131, 139)',
                                                        fontWeight: '500'
                                                    }}
                                                >
                                                    Denumire Serviciu si Link
                                                </p>
                                                
                                            </h3>

                                            <SvNameAndLink 
                                                name = {this.state.detaliiServiciu['name'] || ''}
                                                link = {this.state.detaliiServiciu['web_link'] || ''}
                                                token = {this.state.detaliiServiciu['token']}
                                                accountLink = {this.state.accountLink}
                                                inputStatus = {this.inputStatus}
                                                showToastError = {this.showToastError}
                                                onCopyLinkToClipBoard = {this.onCopyLinkToClipBoard}
                                                onShowToastSocial = {this.onShowToastSocial}
                                                
                                                triggerSave = {this.state.triggerSave}

                                                fontSize = '16px'
                                                fontSizeCommands = '18px'
                                            />
                                            
                                            <h3 
                                            className="title"
                                            style={{
                                                margin: 0,
                                                marginBottom: 15,
                                                textAlign: 'left'
                                            }}
                                            >
                                                
                                                <p 
                                                    style={{
                                                            display: 'inline-block', 
                                                            position: 'relative', 
                                                            top: 5, 
                                                            left: 0, 
                                                            margin: 0,
                                                            color: 'rgb(255, 199, 39)',
                                                            fontSize: '22px'
                                                        }}
                                                >
                                                    <PinDrop /> 

                                                </p>

                                                <p 
                                                    style={{
                                                        display: 'inline-block', 
                                                        position: 'relative', 
                                                        left: 10,
                                                        margin: 0,
                                                        color: 'rgb(116, 131, 139)',
                                                        fontWeight: '500'
                                                    }}
                                                >
                                                    Unde se va desfasura programarea?
                                                </p>
                                                
                                            </h3>

                                            <SvLocation 
                                            
                                                locatieCurenta = {this.state.detaliiServiciu['location']}
                                                triggerSave = {this.state.triggerSave}
                                                inputStatus = {this.inputStatus}
                                                integrations = {this.state.integrations}
                                            />

                                            <h3 
                                            className="title"
                                            style={{textAlign: 'left'}}
                                            >
                                                
                                                <p 
                                                    style={{
                                                            display: 'inline-block', 
                                                            position: 'relative', 
                                                            top: 0, 
                                                            left: 0, 
                                                            margin: 0,
                                                            color: 'rgb(255, 199, 39)',
                                                            fontSize: '22px'
                                                        }}
                                                >
                                                    <NotesIcon /> 

                                                </p>

                                                <p 
                                                    style={{
                                                        display: 'inline-block', 
                                                        position: 'relative', 
                                                        left: 10,
                                                        margin: 0,
                                                        color: 'rgb(116, 131, 139)',
                                                        fontWeight: '500'
                                                    }}
                                                >
                                                    Descrierea Serviciului
                                                </p>
                                                
                                            </h3>

                                            <SvDescription 
                                                descriere = {this.state.detaliiServiciu['description'] || ''}
                                                triggerSave = {this.state.triggerSave}
                                                showToastError = {this.showToastError}
                                                
                                                inputStatus = {this.inputStatus}
                                                textAreaHeight = '180px'
                                            />
                                    
                                
                                            <h3 
                                                className="title"
                                                style={{textAlign: 'left'}}
                                            >
                                                
                                                <p 
                                                    style={{
                                                            display: 'inline-block', 
                                                            position: 'relative', 
                                                            top: 5, 
                                                            left: 0, 
                                                            margin: 0,
                                                            color: 'rgb(255, 199, 39)',
                                                            fontSize: '22px'
                                                        }}
                                                >
                                                    <MdAlarm /> 

                                                </p>

                                                <p 
                                                    style={{
                                                        display: 'inline-block', 
                                                        position: 'relative', 
                                                        left: 10,
                                                        margin: 0,
                                                        color: 'rgb(116, 131, 139)',
                                                        fontWeight: '500'
                                                    }}
                                                >
                                                    Cat va dura serviciul?
                                                </p>
                                                
                                            </h3>

                                            

                                            <SvDuration 
                                        
                                                duration = {this.state.detaliiServiciu['duration']}
                                                triggerSave = {this.state.triggerSave}
                                                inputStatus = {this.inputStatus}
                                                
                                            />
                                                
                                            

                                            <h3 
                                                className="title"
                                                style={{textAlign: 'left'}}
                                            >
                                                
                                                <p 
                                                    style={{
                                                            display: 'inline-block', 
                                                            position: 'relative', 
                                                            top: 5, 
                                                            left: 0, 
                                                            margin: 0,
                                                            color: 'rgb(255, 199, 39)',
                                                            fontSize: '22px'
                                                        }}
                                                >
                                                    <FaRegMoneyBillAlt /> 

                                                </p>

                                                <p 
                                                    style={{
                                                        display: 'inline-block', 
                                                        position: 'relative', 
                                                        left: 10,
                                                        margin: 0,
                                                        color: 'rgb(116, 131, 139)',
                                                        fontWeight: '500'
                                                    }}
                                                >
                                                    Tarif Serviciu
                                                </p>
                                                
                                            </h3>
                                            
                                    
                                        <SvPrice 
                                                tarif = {+this.state.detaliiServiciu['price'] || 0}
                                                triggerSave = {this.state.triggerSave}
                                                inputStatus = {this.inputStatus}
                                                
                                        />
                                        

                                            <h3 
                                                className="title"
                                                style={{textAlign: 'left'}}
                                            >
                                                
                                                <p 
                                                    style={{
                                                            display: 'inline-block', 
                                                            position: 'relative', 
                                                            top: 4, 
                                                            left: 0, 
                                                            margin: 0,
                                                            color: 'rgb(255, 199, 39)',
                                                            fontSize: '22px'
                                                        }}
                                                >
                                                    <FaCalendarCheck /> 

                                                </p>

                                                <p 
                                                    style={{
                                                        display: 'inline-block', 
                                                        position: 'relative', 
                                                        left: 10,
                                                        margin: 0,
                                                        color: 'rgb(116, 131, 139)',
                                                        fontWeight: '500'
                                                    }}
                                                >
                                                    Disponibilitate
                                                </p>
                                                
                                            </h3>

                                            <SvDisponibility 
                                                serviceDetails = {this.state.detaliiServiciu}
                                                triggerSave = {this.state.triggerSave}
                                                inputStatus = {this.inputStatus}
                                                showToastError = {this.showToastError}
                                                onAddErrorMessage = {this.onAddErrorMessage}
                                            />
                                            
                                            <h3 
                                                className="title"
                                                style={{textAlign: 'left'}}
                                            >
                                                
                                                <p 
                                                    style={{
                                                            display: 'inline-block', 
                                                            position: 'relative', 
                                                            top: 4, 
                                                            left: 0, 
                                                            margin: 0,
                                                            color: 'rgb(255, 199, 39)',
                                                            fontSize: '22px'
                                                        }}
                                                >
                                                    <RiCupFill /> 

                                                </p>

                                                <p 
                                                    style={{
                                                        display: 'inline-block', 
                                                        position: 'relative', 
                                                        left: 10,
                                                        margin: 0,
                                                        color: 'rgb(116, 131, 139)',
                                                        fontWeight: '500'
                                                    }}
                                                >
                                                    Acorda-ti pauze
                                                </p>
                                                
                                            </h3>

                                            <SvBreaks 
                                                triggerSave = {this.state.triggerSave}
                                                pauzaInainteSesiune = {this.state.detaliiServiciu['break_before'] === undefined ? 15 : this.state.detaliiServiciu['break_before']}
                                                pauzaDupaSesiune = {this.state.detaliiServiciu['break_after'] === undefined ? 15 : this.state.detaliiServiciu['break_after']}
                                                inputStatus = {this.inputStatus}
                                                
                                                mobileSelector = {false}
                                            />



                                            <h3 
                                                className="title"
                                                style={{textAlign: 'left'}}
                                            >
                                                
                                                <p 
                                                    style={{
                                                            display: 'inline-block', 
                                                            position: 'relative', 
                                                            top: 4, 
                                                            left: 0, 
                                                            margin: 0,
                                                            color: 'rgb(255, 199, 39)',
                                                            fontSize: '22px'
                                                        }}
                                                >
                                                    <FaCalendarCheck /> 

                                                </p>

                                                <p 
                                                    style={{
                                                        display: 'inline-block', 
                                                        position: 'relative', 
                                                        left: 10,
                                                        margin: 0,
                                                        color: 'rgb(116, 131, 139)',
                                                        fontWeight: '500',
                                                    }}
                                                >
                                                    Program
                                                </p>
                                                
                                            </h3>

                                            <div 
                                                className="form-control"
                                                style={{
                                                    textAlign: 'left',
                                                    // width: '70%'
                                                }}
                                            >

                                            

                                                <SvSchedule
                                                    triggerSave = {this.state.triggerSave}
                                                    programe = {this.state.programe}
                                                    programTk = {this.state.detaliiServiciu['program_tk'] || this.state.programe[0]['token']}
                                                    
                                                    inputStatus = {this.inputStatus}
                                                    onShowEditSchedule = {this.onShowEditSchedule}
                                                    txSize = '14px'
                                                    schBoxMargin = '20px'
                                                />
                                                    

                                            </div>

                                            
                                            <h3 
                                                className="title"
                                                style={{
                                                    textAlign: 'left',
                                                    margin: 0,
                                                    marginTop: 10
                                                }}
                                            >
                                                
                                                <p 
                                                    style={{
                                                            display: 'inline-block', 
                                                            position: 'relative', 
                                                            top: 4, 
                                                            left: 0, 
                                                            margin: 0,
                                                            color: 'rgb(255, 199, 39)',
                                                            fontSize: '24px'
                                                        }}
                                                >
                                                    <IoMdColorPalette /> 

                                                </p>

                                                <p 
                                                    style={{
                                                        display: 'inline-block', 
                                                        position: 'relative', 
                                                        left: 10,
                                                        margin: 0,
                                                        color: 'rgb(116, 131, 139)',
                                                        fontWeight: '500',
                                                    }}
                                                >
                                                    Culoare in calendar
                                                </p>
                                                
                                            </h3>


                                            <div 
                                                className="form-control"
                                                style={{
                                                    textAlign: 'left'
                                                }}
                                            >
                                                <SvColor 
                                                    culoare = {this.state.detaliiServiciu['color'] || 'red'}
                                                    triggerSave = {this.state.triggerSave}
                                                    
                                                    inputStatus = {this.inputStatus}
                                                    txSize = '14px'
                                                    descMarginTop = '16px'
                                                />

                                            </div>


                                           {
                                                this.state.abonament !== 'gratuit' && this.state.abonament !== null ? (

                                                <>
                                                     <h3 
                                                        className="title"
                                                        style={{
                                                            textAlign: 'left',
                                                            marginTop: 20
                                                        }}
                                                    >
                                                        
                                                        <p 
                                                            style={{
                                                                    display: 'inline-block', 
                                                                    position: 'relative', 
                                                                    top: 4, 
                                                                    left: 0, 
                                                                    margin: 0,
                                                                    color: 'rgb(255, 199, 39)',
                                                                    fontSize: '24px'
                                                                }}
                                                        >
                                                            <IoSettingsSharp /> 

                                                        </p>

                                                        <p 
                                                            style={{
                                                                display: 'inline-block', 
                                                                position: 'relative', 
                                                                left: 10,
                                                                margin: 0,
                                                                color: 'rgb(116, 131, 139)',
                                                                fontWeight: '500',
                                                            }}
                                                        >
                                                            Configurari Avansate
                                                        </p>
                                                        
                                                    </h3>

                                                    <div className="form-control">

                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                color: '#263238',
                                                                fontSize: '14px',
                                                                textAlign: 'left'
                                                            }}
                                                        >
                                                            Activeaza Mesage Post Serviciu si Politica de Anulare
                                                        </p>

                                                    </div>
                                                    
                                                    <div className="form-control">

                                                        <ConfigSwitch 
                                                            title='Configurari Avansate'
                                                            id='config'
                                                            for='config'
                                                            checked={this.state.showConfig}
                                                            onChange={this.onChangeShowConfig}
                                                        />

                                                    </div>
                                                </>

                                                ) : null
                                           }

                                    </div>

                                </div>

                            </div>
                            )
                        }

                    

                    {/* 

                        config
                        Configurari avansate 
                    
                    */}

                    {
                        this.state.showConfig && 
                        !this.state.isLoading &&

                    <div 
                        className="service-details-card"
                        style={{
                            marginTop: 5
                        }}
                    >

                        <div className="row">

                            <div className="col-l-8 col-m-8 col-s-8">

                                <h3 
                                    className="title"
                                    style={{
                                        textAlign: 'left',
                                        margin: 0
                                    }}
                                >
                                    
                                    <p 
                                        style={{
                                                display: 'inline-block', 
                                                position: 'relative', 
                                                top: 4, 
                                                left: 0, 
                                                margin: 0,
                                                color: 'rgb(255, 199, 39)',
                                                fontSize: '24px'
                                            }}
                                    >
                                        <RiExchangeFundsLine /> 

                                    </p>

                                    <p 
                                        style={{
                                            display: 'inline-block', 
                                            position: 'relative', 
                                            left: 10,
                                            margin: 0,
                                            color: 'rgb(116, 131, 139)',
                                            fontWeight: '500',
                                        }}
                                    >
                                        Recall
                                    </p>
                                    
                                </h3>

                                <SvRecall 
                                    triggerSave = {this.state.triggerSave}
                                    serviceDetails = {this.state.detaliiServiciu}
                                    inputStatus = {this.inputStatus}
                                    showToastError = {this.showToastError}
                                    onAddErrorMessage = {this.onAddErrorMessage}
                                />

                                <h3 
                                    className="title"
                                    style={{
                                        textAlign: 'left',
                                        margin: 0,
                                        marginTop: '20px'
                                    }}
                                >
                                    
                                    <p 
                                        style={{
                                                display: 'inline-block', 
                                                position: 'relative', 
                                                top: 4, 
                                                left: 0, 
                                                margin: 0,
                                                color: 'rgb(255, 199, 39)',
                                                fontSize: '24px'
                                            }}
                                    >
                                        <RiMailCheckFill /> 

                                    </p>

                                    <p 
                                        style={{
                                            display: 'inline-block', 
                                            position: 'relative', 
                                            left: 10,
                                            margin: 0,
                                            color: 'rgb(116, 131, 139)',
                                            fontWeight: '500',
                                        }}
                                    >
                                        Cerere Review
                                    </p>
                                    
                                </h3>

                                <SvEmailReview 
                                    triggerSave = {this.state.triggerSave}
                                    serviceDetails = {this.state.detaliiServiciu}
                                    inputStatus = {this.inputStatus}
                                    showToastError = {this.showToastError}
                                    onAddErrorMessage = {this.onAddErrorMessage}
                                />

                                <h3 
                                    className="title"
                                    style={{
                                        textAlign: 'left',
                                        margin: 0,
                                        marginTop: '20px'
                                    }}
                                >
                                    
                                    <p 
                                        style={{
                                                display: 'inline-block', 
                                                position: 'relative', 
                                                top: 2, 
                                                left: 0, 
                                                margin: 0,
                                                color: 'rgb(255, 199, 39)',
                                                fontSize: '24px'
                                            }}
                                    >
                                        <ArticleIcon className="terms-icon" /> 

                                    </p>

                                    <p 
                                        style={{
                                            display: 'inline-block', 
                                            position: 'relative', 
                                            left: 10,
                                            margin: 0,
                                            color: 'rgb(116, 131, 139)',
                                            fontWeight: '500',
                                        }}
                                    >
                                        Termeni si Conditii
                                    </p>
                                    
                                </h3>

                                <SvTermsAndConditions 
                                    triggerSave = {this.state.triggerSave}
                                    serviceDetails = {this.state.detaliiServiciu}
                                    inputStatus = {this.inputStatus}
                                    showToastError = {this.showToastError}
                                    onAddErrorMessage = {this.onAddErrorMessage}
                                />

                            </div>

                        </div>

                    </div>

                    }

                    {/* <button 
                        type="button"
                        style={{
                            marginTop: 40
                        }}
                        onClick={this.onCheckAndSave}
                    >    
                        Salveaza
                    </button> */}

                    {
                        this.props.tokenServiciuSelectat.length > 10 &&
                        !this.state.isLoading &&
                        <h6
                            className="delete-service"
                            onClick={this.disableService}
                        >
                            <p 
                                style={
                                    {
                                        display: 'inline-block', 
                                        position: 'relative', 
                                        top: 6, 
                                        left: 0, 
                                        margin: 0,
                                        fontSize: '24px',
                                    }
                                }
                            >
                                <MdDeleteForever />
                            </p>    

                            <p 
                                style={
                                    {
                                        display: 'inline-block', 
                                        position: 'relative', 
                                        left: 10,
                                        margin: 0,
                                        fontSize: '14px',
                                        fontWeight: '500',
                                    }
                                }
                            >
                                Sterge Serviciu
                            </p>
                            
                        </h6>
                    }

                    {
                        !this.state.isLoading && (

                            <div
                                style={{
                                    width: '100%'
                                }}
                            >

                                <img 
                                    src={ChairImage} 
                                    alt="Chair image..." 
                                    style={{
                                        float: 'left'
                                    }}
                                />

                            </div>
                        )
                    }

                </div>

            </div>
        );
    }
}