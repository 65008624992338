import Declarations from "../../providers/Declarations";
import LocalStorage from "../../providers/LocalStorage";
import Masks from "../../providers/Masks";
import Utils from "../../providers/Utils";
import Ws from "../../providers/Ws";


export default class ScheduleManagerFuncs{

    WS = new Ws();
    M = new Masks();
    UT = new Utils();

    onGeneratePlaceholderName = (allSchedules) => {

        // const ACC_TOKEN = new LocalStorage().GetAccountToken();

        // let selectSchedules = `select * from programs where account_tk = '${ACC_TOKEN}' `;
        // let placeholderNameSchedules = await this.WS.sqlCommand(`select`, selectSchedules);

        let placeholderNameSchedules = allSchedules;
        
        if(placeholderNameSchedules !== null && placeholderNameSchedules !== undefined){

            placeholderNameSchedules = placeholderNameSchedules.filter(pnEl => pnEl['name'].includes('Program nou'));

            const length = placeholderNameSchedules.length;

            if(length > 0){

                return 'Program nou ' + (length + 1);
            }
            else{

                return 'Program nou';
            }
        }
        else{

            return 'Program nou';
        }
    }


    generateScheduleStateArray = (schedule) => {

        const scheduleArray = [

            {
                ziStart: schedule.luStart,
                ziEnd: schedule.luEnd
            },

            {
                ziStart: schedule.maStart,
                ziEnd: schedule.maEnd
            },

            {
                ziStart: schedule.miStart,
                ziEnd: schedule.miEnd
            },

            {
                ziStart: schedule.joStart,
                ziEnd: schedule.joEnd
            },

            {
                ziStart: schedule.viStart,
                ziEnd: schedule.viEnd
            },

            {
                ziStart: schedule.saStart,
                ziEnd: schedule.saEnd
            },

            {
                ziStart: schedule.duStart,
                ziEnd: schedule.duEnd
            }
        ]

        return scheduleArray;
    }


    onSaveScheduleToDb = async (isCreate, scheduleInfo) => {

        const scheduleToken = this.UT.generateToken();
        const ACC_TOKEN = new LocalStorage().GetAccountToken(); 

        let scheduleArray = this.generateScheduleStateArray(scheduleInfo);

        for(let i = 0; i < scheduleArray.length; i++){

            const ziStart = scheduleArray[i].ziStart;
            const ziEnd = scheduleArray[i].ziEnd;

            // if( ( ziStart === 'Indisp' || ziEnd === 'Indisp' ) && ( ziStart === null || ziEnd === null ) )
            if( ( ziStart === 'Indisp' || ziEnd === 'Indisp' ) && ( ziStart === null || ziEnd === null ) || ( ( ziStart === null && ziEnd === null ) ) ){

                scheduleArray[i].ziStart = null;
                scheduleArray[i].ziEnd = null;
            }
            else{

                scheduleArray[i].ziStart = `'${scheduleArray[i].ziStart}'`;
                scheduleArray[i].ziEnd = `'${scheduleArray[i].ziEnd}'`;
            }
        }

        

        if(isCreate){

            const scheduleName = (scheduleInfo['scheduleName'] || '').length > 0 && (scheduleInfo['selectedScheduleTk'] || '').length < 10 ? scheduleInfo['scheduleName'] : this.onGeneratePlaceholderName(scheduleInfo['allSchedules']);
            // await this.onTriggerResetSchedule();
            

            // const isNameExisting = scheduleInfo['allSchedules'].filter(scEl => (scEl.name || '').toLowerCase() === scheduleName.toLowerCase());

            // if(isNameExisting.length > 0){

            //     return{

            //         data: null,
            //         err: 'Numele programului exista deja!'
            //     };
            // }
            
            let createScheduleQuery = `insert into programs(account_tk, token, name, sters,  
                                        lu_start, lu_end, ma_start, ma_end, 
                                        mi_start, mi_end, jo_start, jo_end, 
                                        vi_start, vi_end, sa_start, sa_end,
                                        du_start, du_end, user_tk
                                        )    
                                        
                                        select '${ACC_TOKEN}', '${scheduleToken}', '${scheduleName}', 0,
                                        ${scheduleArray[0].ziStart}, ${scheduleArray[0].ziEnd}, ${scheduleArray[1].ziStart}, ${scheduleArray[1].ziEnd},
                                        ${scheduleArray[2].ziStart}, ${scheduleArray[2].ziEnd}, ${scheduleArray[3].ziStart}, ${scheduleArray[3].ziEnd},
                                        ${scheduleArray[4].ziStart}, ${scheduleArray[4].ziEnd}, ${scheduleArray[5].ziStart}, ${scheduleArray[5].ziEnd},
                                        ${scheduleArray[6].ziStart}, ${scheduleArray[6].ziEnd}, ${null}
                                        `;

            

            let createSchedule = await this.WS.sqlCommand(`insert`, createScheduleQuery);

            

            if(createSchedule.success){

                return{
                    err: null,
                    data: {

                        scheduleArray: scheduleArray,
                        scheduleToken: scheduleToken,
                    }
                };
            }
            else{

                return {err: 'Eroare creare program.', data: null};
                // this.setState({

                //     showToastPopupError: true,
                //     actionTx: 'Eroare creare program.'
                // });
                // alert('Crearea programului a esuat!');
                // throw new Error("Error creating schedule " + createSchedule.message);
            }
        }

        if(!isCreate){

            let updateScheduleQuery = `update programs set name = '${scheduleInfo['scheduleName']}', lu_start = ${scheduleArray[0].ziStart}, lu_end = ${scheduleArray[0].ziEnd},  
                                                            ma_start = ${scheduleArray[1].ziStart}, ma_end = ${scheduleArray[1].ziEnd},
                                                            mi_start = ${scheduleArray[2].ziStart}, mi_end = ${scheduleArray[2].ziEnd},
                                                            jo_start = ${scheduleArray[3].ziStart}, jo_end = ${scheduleArray[3].ziEnd},
                                                            vi_start = ${scheduleArray[4].ziStart}, vi_end = ${scheduleArray[4].ziEnd},
                                                            sa_start = ${scheduleArray[5].ziStart}, sa_end = ${scheduleArray[5].ziEnd},
                                                            du_start = ${scheduleArray[6].ziStart}, du_end = ${scheduleArray[6].ziEnd}

                                                            where token = '${scheduleInfo['selectedScheduleTk']}'
                                                            `;

            

            let updateSchedule = await this.WS.sqlCommand(`update`, updateScheduleQuery);

            

            if(updateSchedule.success){

                return{
                    err: null,
                    data: {

                        scheduleArray: scheduleArray,
                        scheduleToken: '',
                        scheduleName: scheduleInfo['scheduleName'] || ''
                    }
                };
            }
            else{

                // this.setState({

                //     showToastPopupError: true,
                //     actionTx: 'Eroare actualizare program.'
                // });

                return {err: 'Eroare actualizare program.', data: null};
                // alert('Actualizarea programului a esuat!');
                // throw new Error("Error updating schedule " + updateSchedule.message);
            }
        }
    }

    setIntervalOrar = () => {

        const intervalOrar = new Declarations().listaIntervalOrarFix.map(liEl => {

            if(liEl === 'Indisp'){

                return(

                    {
                        val: null,
                        tx: liEl
                    }
                )
            }
            else{

                return(

                    {
                        val: liEl + ':00',
                        tx: liEl
                    }
                )
            }
        });

        

        return intervalOrar;
    }

    setScheduleWeekDays = () => {

        const weekDaysList = new Declarations().listaZileSaptamana;
        
        return weekDaysList;
    }

    changeScheduleFormat = (schedule) => {

        const newSchedule = [

            {
                ziStart: schedule['lu_start'],
                ziEnd: schedule['lu_end'],
                idx: 1,
            },

            {
                ziStart: schedule['ma_start'],
                ziEnd: schedule['ma_end'],
                idx: 2,
            },

            {
                ziStart: schedule['mi_start'],
                ziEnd: schedule['mi_end'],
                idx: 3,
            },

            {
                ziStart: schedule['jo_start'],
                ziEnd: schedule['jo_end'],
                idx: 4,
            },

            {
                ziStart: schedule['vi_start'],
                ziEnd: schedule['vi_end'],
                idx: 5,
            },

            {
                ziStart: schedule['sa_start'],
                ziEnd: schedule['sa_end'],
                idx: 6,
            },

            {
                ziStart: schedule['du_start'],
                ziEnd: schedule['du_end'],
                idx: 0,
            }
        ];

        return newSchedule;
    }

    checkIfConsecutive = (consecutiveIndexes) => {

        const differenceAry = consecutiveIndexes.slice(1).map(function(n, i) { return n - consecutiveIndexes[i]; });
        const isDifference= differenceAry.every(value => value == 1);

        return isDifference;
    }

    setScheduleInterval = (scEl) => {

        const scheduleOnly = this.changeScheduleFormat(scEl);

        let scheduleFormat = [];

        const indexes = [1, 2 ,3 ,4 ,5, 6, 0];

        let consecutiveIndexes = [];
        let nullIndexes = [];

        //Separating schedule days
        for(let i = 0; i < scheduleOnly.length; i++){

           if( ( (scheduleOnly[i].ziStart !== null) || ((scheduleOnly[i].ziStart || '').length > 2) ) &&
                ( (scheduleOnly[i].ziEnd !== null) || ((scheduleOnly[i].ziEnd || '').length > 2) )
           ){
                consecutiveIndexes.push(i)
           }
           else if( ( (scheduleOnly[i].ziStart === null) || ((scheduleOnly[i].ziStart || '').length < 2) ) ||
                    ( (scheduleOnly[i].ziEnd === null) || ((scheduleOnly[i].ziEnd || '').length < 2) )
           ){

                nullIndexes.push(i) 
           }
        }

        //Check if consecutive and if the schedule hours are equal

        let scheduleArrayFormat = [];

        if(this.checkIfConsecutive(consecutiveIndexes)){

            

            let lastIndexMatching = 0;
            let firstIndexMatching = 0;

            let startAux = scheduleOnly[consecutiveIndexes[0]].ziStart;
            let endAux = scheduleOnly[consecutiveIndexes[0]].ziEnd;

            let outcastArray = [];
            let nonOutcastArray = [];

            for(let i = consecutiveIndexes[1]; i <= consecutiveIndexes[consecutiveIndexes.length - 1]; i++){

                if(i === consecutiveIndexes[1]){

                    if( (scheduleOnly[i].ziStart === startAux) && (scheduleOnly[i].ziEnd === endAux) ){

                        firstIndexMatching = i - 1;
                        lastIndexMatching = i;
                    }
                    else{

                        firstIndexMatching = i;
                        startAux = scheduleOnly[consecutiveIndexes[i]].ziStart;
                        endAux = scheduleOnly[consecutiveIndexes[i]].ziEnd;
                    }

                }else{

                    if( (scheduleOnly[i].ziStart === startAux) && (scheduleOnly[i].ziEnd === endAux) ){

                        lastIndexMatching = i;
                    }
                    else if(i === consecutiveIndexes[consecutiveIndexes.length - 1]){

                        startAux = scheduleOnly[consecutiveIndexes[i]].ziStart;
                        endAux = scheduleOnly[consecutiveIndexes[i]].ziEnd;
                    }
                    else{

                        firstIndexMatching = i;
                        startAux = scheduleOnly[consecutiveIndexes[i]].ziStart;
                        endAux = scheduleOnly[consecutiveIndexes[i]].ziEnd;
                    }
                }
            }

            

            let result = [];

            for(let i = consecutiveIndexes[0]; i <= consecutiveIndexes[consecutiveIndexes.length - 1]; i++){

                if(i >= +firstIndexMatching && i <= +lastIndexMatching){ //i >= firstIndexMatching && i <= lastIndexMatching

                    

                    nonOutcastArray.push(

                        {
                            day: this.M.dayOfWeek(scheduleOnly[i].idx).substr(0,1),
                            ziStart: scheduleOnly[i].ziStart.substr(0,5),
                            ziEnd: scheduleOnly[i].ziEnd.substr(0,5),
                            idx: i,
                            outcast: false,
                            indisp: false 
                        }
                    )
                }
                else{

                    outcastArray.push(

                        {
                            day: this.M.dayOfWeek(scheduleOnly[i].idx).substr(0,1),
                            ziStart: scheduleOnly[i].ziStart.substr(0,5),
                            ziEnd: scheduleOnly[i].ziEnd.substr(0,5),
                            idx: i,
                            outcast: true,
                            indisp: false 
                        }
                    )
                }
            }

            
            

            if(nonOutcastArray.length > 0){

                scheduleArrayFormat.push(

                    {
                        ziStart: nonOutcastArray[0].day,
                        ziEnd: nonOutcastArray[nonOutcastArray.length - 1].day,
                        hourStart: nonOutcastArray[nonOutcastArray.length - 1].ziStart,
                        hourEnd: nonOutcastArray[nonOutcastArray.length - 1].ziEnd,
                        idx: nonOutcastArray[nonOutcastArray.length - 1].idx,
                        outcast: false,
                        indisp: false 
                    }
                )
            }

            // 

           if(outcastArray.length > 0){

                for(let i = 0; i < outcastArray.length; i++){

                    scheduleArrayFormat.push(

                        {
                            ziStart: outcastArray[i].day,
                            ziEnd: outcastArray[i].day,
                            hourStart: outcastArray[i].ziStart,
                            hourEnd: outcastArray[i].ziEnd,
                            idx: outcastArray[i].idx,
                            outcast: true,
                            indisp: false 
                        }
                    )
                }
           }
        }
        else{

            for(let i = 0; i < nullIndexes.length; i++){

                scheduleArrayFormat.push(

                    {
                        ziStart: this.M.dayOfWeek(scheduleOnly[nullIndexes[i]].idx).substr(0,1),
                        ziEnd: this.M.dayOfWeek(scheduleOnly[nullIndexes[i]].idx).substr(0,1),
                        hourStart: (scheduleOnly[nullIndexes[i]].ziStart || '').substr(0,5),
                        hourEnd: (scheduleOnly[nullIndexes[i]].ziEnd || '').substr(0,5),
                        idx: nullIndexes[i],
                        outcast: false,
                        indisp: true 
                    }
                )
            }

            for(let i = 0; i < consecutiveIndexes.length; i++){

                scheduleArrayFormat.push(

                    {
                        ziStart: this.M.dayOfWeek(scheduleOnly[consecutiveIndexes[i]].idx).substr(0,1),
                        ziEnd: this.M.dayOfWeek(scheduleOnly[consecutiveIndexes[i]].idx).substr(0,1),
                        hourStart: (scheduleOnly[consecutiveIndexes[i]].ziStart || '').substr(0,5),
                        hourEnd: (scheduleOnly[consecutiveIndexes[i]].ziEnd || '').substr(0,5),
                        idx: consecutiveIndexes[i],
                        outcast: true,
                        indisp: false 
                    }
                )
            }

        }

        

        scheduleArrayFormat.sort( (prevIdx, nextIdx ) => {

            if(prevIdx.idx < nextIdx.idx){

                return -1;
            }
            else{

                return 1;
            }
        })

        return scheduleArrayFormat;

    }  

    convertScheduleObjectKeysToDbFormatObjectKeys = (object) => {

        return {

            name: object['scheduleName'],
            lu_start: object['luStart'],
            lu_end: object['luEnd'],
            ma_start: object['maStart'],
            ma_end: object['maEnd'],
            mi_start: object['miStart'],
            mi_end: object['miEnd'],
            jo_start: object['joStart'],
            jo_end: object['joEnd'],
            vi_start: object['viStart'],
            vi_end: object['viEnd'],
            sa_start: object['saStart'],
            sa_end: object['saEnd'],
            du_start: object['duStart'],
            du_end: object['duEnd'],
        };
    }
}