import React from 'react';

import { FaRegCalendarAlt } from 'react-icons/fa';
import { FaStore } from 'react-icons/fa';
import { IoIosArrowBack } from 'react-icons/io';
import { IoMdAdd } from 'react-icons/io';

import {
    BrowserView,
    MobileView,

} from "react-device-detect";

import './Commons.css';

export default class DashboardNav extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            showBackBtn: false
        };
    }
    

    componentDidMount(){

        // 
        // if(this.props.onShowDetaliiServiciu || this.props.isProfileShown){

            
        //     this.setState({

        //         showBackBtn: true
        //     })
        // }
    }

    onShowCalendar = () =>{

        this.props.onShowCalendar();

    }

    onShowServicii = () =>{

        this.props.onShowServicii();

    }

    onAddServiciu = () =>{

        this.props.goAddServiciu('-1', '')
    }

    onGoBackToPrevScreen = () => {

        this.props.onGoBackToPrevScreen();
    }

    render(){

        return(

            <nav className="dashboard-nav">

                <div className="wrapper-dashboard">

                    <BrowserView>
                        <div className="row">

                            <div className="col-l-9 col-m-9 col-s-9">

                                <a 
                                    onClick={this.onShowCalendar}
                                    className={this.props.isCalendarActive || this.props.showCalendarAndOpen ? "nav-item selected" : "nav-item"}
                                >
                            
                                    <p 
                                        style={
                                            {
                                                display: 'inline-block', 
                                                position: 'relative', 
                                                top: 5, 
                                                left: 0, 
                                                margin: 0,
                                                fontSize: '22px',
                                        
                                            }
                                        }
                                    >
                                        <FaRegCalendarAlt />
                                    </p>

                                    <p 
                                        style={
                                            {
                                                display: 'inline-block', 
                                                position: 'relative', 
                                                left: 10,
                                                margin: 0,
                                                fontWeight: '500'
                                            }
                                        }
                                    >
                                        Calendar
                                    </p>
                                </a>

                                
                                <a 
                                    onClick={this.onShowServicii}
                                    className={this.props.isServiciiActive || this.props.onShowDetaliiServiciu ? "nav-item selected" : "nav-item"}
                                >
                            
                                    <p 
                                        style={
                                            {
                                                display: 'inline-block', 
                                                position: 'relative', 
                                                top: 5, 
                                                left: 0, 
                                                margin: 0,
                                                fontSize: '22px',
                                        
                                            }
                                        }
                                    >
                                        <FaStore />
                                    </p>

                                    <p 
                                        style={
                                            {
                                                display: 'inline-block', 
                                                position: 'relative', 
                                                left: 10,
                                                margin: 0,
                                                fontWeight: '500'
                                            }
                                        }
                                    >
                                        Servicii
                                    </p>
                                    
                                </a>

                                {
                                    this.props.onShowDetaliiServiciu &&

                                    <a className="nav-item path-service">

                                        / {this.props.pathServiciu.length > 60 ? this.props.pathServiciu.substr(0,60) + '...' : this.props.pathServiciu} - {this.props.pathDurata}

                                    </a>

                                }

                            </div>

                            <div 
                                className="col-l-3 col-m-3 col-s-3"
                            >
                                        
                                {
                                    (this.props.onShowDetaliiServiciu || this.props.isProfileShown || this.props.showEditSchedule ||
                                     this.props.showIntegrari || this.props.showEchipa || this.props.showAbonamente ||
                                     this.props.showSecuritate || this.props.showIstoricPlati || this.props.showIstoricProgramari ||
                                     this.props.showRapoarte
                                    ) && (

                                        <a 
                                            onClick={this.onGoBackToPrevScreen}
                                            className="nav-item back"
                                            style={{
                                                right: this.props.showEditSchedule || this.props.showIntegrari || 
                                                this.props.showAbonamente || this.props.showIstoricPlati ||
                                                this.props.showIstoricProgramari ||  this.props.showRapoarte
                                                ? '0px' : '110px'
                                            }}
                                        >
                        
                                            <p 
                                                style={
                                                    {
                                                        display: 'inline-block', 
                                                        position: 'relative', 
                                                        top: 6, 
                                                        right: 10,
                                                        margin: 0,
                                                        fontSize: '22px',
                                                
                                                    }
                                                }
                                            >
                                                <IoIosArrowBack />
                                            </p>

                                            <p 
                                                style={
                                                    {
                                                        display: 'inline-block', 
                                                        position: 'relative', 
                                                        left: 0,
                                                        margin: 0,
                                                        fontWeight: '500'
                                                    }
                                                }
                                            >
                                                Inapoi
                                            </p>
                                            
                                        </a>
                                    )
                                }

                                                
                                {
                                    !this.props.onShowDetaliiServiciu && 
                                    this.props.isServiciiActive && (

                                        <a 
                                            onClick={       

                                                this.onAddServiciu 
                                            }

                                            className="nav-item add"
                                        >
                        
                                            <p 
                                                style={
                                                    {
                                                        display: 'inline-block', 
                                                        position: 'relative', 
                                                        top: 5, 
                                                        right: 6,
                                                        margin: 0,
                                                        fontSize: '22px',
                                                
                                                    }
                                                }
                                            >
                                                <IoMdAdd />
                                            </p>

                                            <p 
                                                style={
                                                    {
                                                        display: 'inline-block', 
                                                        position: 'relative', 
                                                        left: 0,
                                                        margin: 0,
                                                        fontWeight: '500'
                                                    }
                                                }
                                            >
                                                Adauga Serviciu
                                            </p>
                                            
                                        </a>
                                    )
                                       
                                }
                            
                            </div>

                        </div>

                    </BrowserView>

                    <MobileView>
                        
                        <div className="m-dsbnav-wrapper">

                            <a 
                                onClick={this.onShowCalendar}
                                className={this.props.isCalendarActive ? "m-nav-item selected" : "m-nav-item"}
                            >
                        
                                <p 
                                    style={
                                        {
                                            display: 'inline-block', 
                                            position: 'relative', 
                                            top: 5, 
                                            left: 0, 
                                            margin: 0,
                                            fontSize: '26px',
                                    
                                        }
                                    }
                                >
                                    <FaRegCalendarAlt />
                                </p>

                                <p 
                                    style={
                                        {
                                            display: 'inline-block', 
                                            position: 'relative', 
                                            left: 10,
                                            top: -2,
                                            margin: 0,
                                            fontSize: '12px',
                                            fontWeight: '500'
                                        }
                                    }
                                >
                                    Calendar
                                </p>
                            </a>
                            
                            <a 
                                onClick={this.onShowServicii}
                                className={this.props.isServiciiActive || this.props.onShowDetaliiServiciu ? "m-nav-item selected" : "m-nav-item"}
                            >
                        
                                <p 
                                    style={
                                        {
                                            display: 'inline-block', 
                                            position: 'relative', 
                                            top: 5, 
                                            left: 0, 
                                            margin: 0,
                                            fontSize: '26px',
                                    
                                        }
                                    }
                                >
                                    <FaStore />
                                </p>

                                <p 
                                    style={
                                        {
                                            display: 'inline-block', 
                                            position: 'relative', 
                                            left: 10,
                                            top: -2,
                                            margin: 0,
                                            fontSize: '12px',
                                            fontWeight: '500'
                                        }
                                    }
                                >
                                    Servicii
                                </p>
                                
                            </a>

                        </div>

                    </MobileView>

                </div>

            </nav>
        );
    }
}