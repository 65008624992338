import React from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import Declarations from '../../providers/Declarations';
import Masks from '../../providers/Masks';
import Utils from '../../providers/Utils';
import Ws from '../../providers/Ws';
import SelectBreak from '../SelectBreak';

export default class SvBreaks extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            listaDurataPauze: [],
            pauzaInainteSesiune: this.props.pauzaInainteSesiune ,
            pauzaInainteSesiuneTmp: this.props.pauzaInainteSesiune,
            pauzaDupaSesiune: this.props.pauzaDupaSesiune,
            pauzaDupaSesiuneTmp: this.props.pauzaDupaSesiune,
        };
    }

    WS = new Ws();
    UT = new Utils();
    M = new Masks();

    componentDidMount(){

        this.setDurataPauze();
    }

    componentDidUpdate(prevProps, _){

        if(prevProps.triggerSave !== this.props.triggerSave){

            this.onGenerateQuery();
        }

        if( (prevProps['pauzaInainteSesiune'] !== this.props['pauzaInainteSesiune'] ) || 
            (prevProps['pauzaDupaSesiune'] !== this.props['pauzaDupaSesiune'])
        ){

            this.setState({

                pauzaInainteSesiuneTmp: this.props.pauzaInainteSesiune,
                pauzaDupaSesiuneTmp: this.props.pauzaDupaSesiune,
            });
        }
    }

    setDurataPauze = () => {

        const listaDurata = new Declarations().listaDurata;

        this.setState({

            listaDurataPauze: listaDurata
        })
    }

    onChangePauzaInainteSesiune = (ev) => {

        let doSave = true;

        if(+ev.target.value === +this.state.pauzaInainteSesiuneTmp){
            
            doSave = false;
        }

        this.setState({

            pauzaInainteStatus: doSave,
            pauzaInainteSesiune: +ev.target.value
        },
            () => {

                this.props.inputStatus(doSave, 'pauzaInainteStatus', 'pauzaInainte', +ev.target.value);
            }
        )
    }

    onChangePauzaDupaSesiune = (ev) => {

        let doSave = true;

        if(+ev.target.value === +this.state.pauzaDupaSesiuneTmp){
            
            doSave = false;
        }

        this.setState({

            pauzaDupaStatus: doSave,
            pauzaDupaSesiune: +ev.target.value
        },
            () => {

                this.props.inputStatus(doSave, 'pauzaDupaStatus', 'pauzaDupa', +ev.target.value);
            }
        )
        
    }

    onGenerateQuery = () => {

        const result = {

            data: [
                {
                    itemName: 'break_before',
                    itemData: `${this.state.pauzaInainteSesiune}`
                },

                {
                    itemName: 'break_after',
                    itemData: `${this.state.pauzaDupaSesiune}`
                }
            ],
            err: null
        }

        // this.props.onCheckAndSave(result);
    }

    render(){

        return(

            <div>

                <BrowserView>
                
                <div 
                        className="row"
                        style={{

                            marginTop: -10
                        }}
                    >

                        <div 
                            className="col-l-6 col-m-6 col-s-6"
                            style={{
                                position: 'relative',
                                textAlign: 'left',
                                paddingRight: 10
                            }}
                        >

                            <div className="form-control">

                                <p
                                    className="break-paragraph"
                                >
                                    Inainte de Sesiune
                                </p>

                            </div>    

                            <div className="form-control">  
                                
                                <SelectBreak
                                    label='Durata Pauza'
                                    myKey='start-pauza-'
                                    duration={this.state.listaDurataPauze}
                                    break={this.state.pauzaInainteSesiune}
                                    onChange={this.onChangePauzaInainteSesiune}
                                    value={+this.state.pauzaInainteSesiune}
                                />

                            </div>

                        </div>


                        <div 
                            className="col-l-6 col-m-6 col-s-6"
                            style={{
                                position: 'relative',
                                textAlign: 'left'
                            }}
                        >

                            <div className="form-control">

                                <p
                                    className="break-paragraph"
                                >
                                    Dupa Sesiune
                                </p>
                                
                            </div>    

                            <div className="form-control">  
                                
                                <SelectBreak
                                    label='Durata Pauza'
                                    myKey='end-pauza-'
                                    duration={this.state.listaDurataPauze}
                                    break={this.state.pauzaDupaSesiune}
                                    onChange={this.onChangePauzaDupaSesiune}
                                    value={+this.state.pauzaDupaSesiune}
                                />

                            </div>

                        </div>

                    </div>

                </BrowserView>

                <MobileView>
                    
                    <div>

                        <p
                            style={{
                                color: '#263238',
                                fontSize: '14px',
                                fontWeight: 400,
                                textAlign: 'left'
                            }}
                        >
                            Inainte de Sesiune
                        </p>

                        <div className="form-control">

                            <SelectBreak 
                                label='Durata Pauza'
                                myKey='m-break-before-'
                                duration={this.state.listaDurataPauze}
                                break={this.state.pauzaInainteSesiune}
                                onChange={this.onChangePauzaInainteSesiune}
                                value={+this.state.pauzaInainteSesiune}
                            />

                        </div>

                    </div>

                    <div>

                        <p
                            style={{
                                color: '#263238',
                                fontSize: '14px',
                                fontWeight: 400,
                                textAlign: 'left',
                                borderTop: '1px solid #EBEBEB',
                                paddingTop: 10
                            }}
                        >
                            Dupa Sesiune
                        </p>

                        <div className="form-control">

                            <SelectBreak 
                                label='Durata Pauza'
                                myKey='m-break-after-'
                                duration={this.state.listaDurataPauze}
                                break={this.state.pauzaDupaSesiune}
                                onChange={this.onChangePauzaDupaSesiune}
                                value={+this.state.pauzaDupaSesiune}
                            />

                        </div>

                    </div>

                </MobileView>

            </div>
        );
    }
}