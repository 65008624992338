import React from 'react';

import Masks from '../../providers/Masks';
import Utils from '../../providers/Utils';
import Ws from '../../providers/Ws';

import BusinessImage from '../../assets/Businesswoman-cuate 1.png';
import ErrorImg from '../../assets/Feeling sorry-cuate 1.png';
import NotificationsFuncs from '../../commons/notifications/NotificationsFuncs';

export default class CancelAppoint extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            cancelTx: 'Se anuleaza programarea...',
            isError: false
        }
    }

    WS = new Ws();
    UT = new Utils();
    M = new Masks();
    NF = new NotificationsFuncs();

    componentDidMount(){

        this.onCancelMeeting();
    }

    onRedirectReschedule = async () => {

        // const tranToken = this.props.match.params.tranToken;
        // const newTranToken = this.UT.generateToken();

        // let cancelAppointQuery = `update trc set anulat = 1, cand_anulat = now() where token = '${tranToken}'  `;
        // let replanAppointQuery = ` insert into trc(token, account_tk, service_tk, service_name, service_description, 
        //     duration, location, price, data_cal, ora_cal, clie_nume, clie_prenume, clie_tf, 
        //     clie_detalii, cand_creat, src, tip, is_from_replan, replaned_trc_token, draft)

        //     select '${newTranToken}', account_tk, service_tk, service_name, service_description, 
        //     duration, location, price, data_cal, ora_cal, clie_nume, clie_prenume, clie_tf, 
        //     clie_detalii, cand_creat, src, tip, 1, '${tranToken}', 1  

        //     from trc where token='${tranToken}`;
    }

    
    onCancelMeeting = async () => {

        // let ss = await this.WS.getData('pay/cancelmeeting', {trToken: this.props.match.params.tranToken});
        // 

        // if(ss.success){

        //     this.setState({

        //         cancelTx: 'Programarea a fost anulata!'
        //     })
        // }
        // else{

        //     this.setState({

        //         cancelTx: 'A aparut o eroare la anularea programarii',
        //         isError: true
        //     })
        // }

        let selectCanceledAppointment = `select * from trc where token = '${this.props.match.params.tranToken}' `;
        let canceledAppointment = await this.WS.sqlCommand(`select`, selectCanceledAppointment);

        const qAccount = `select * from accounts where token = ( select account_tk from trc where token = '${this.props.match.params.tranToken}' )`;
        let account = await this.WS.sqlCommand(`select`, qAccount);

        let notificationMessageCancelAppointment = '';
        let locationType = '';
        let accountToken = '';
        let userToken = '';

        if(canceledAppointment.success){

            notificationMessageCancelAppointment = this.NF.generateMessageForNotification('anulare-programare', canceledAppointment.content[0]);

            const locationParam = 'anulare-'+canceledAppointment.content[0].location;
            locationType = this.NF.generateNotificationTypeForLocation(locationParam);
            accountToken = canceledAppointment.content[0]['account_tk'];    
            userToken = canceledAppointment.content[0]['user_asignat_token'];   

            if( (+canceledAppointment.content[0]['anulat'] || 0 ) === 1){

                this.setState({

                    cancelTx: 'Programarea a fost deja anulata!',
                    isError: true
                });

                return;
            }
        }
        else{

            this.setState({

                cancelTx: 'Eroare server.',
                isError: true
            });

            throw new Error("Error getting canceled appointment: " + canceledAppointment.message);
        }

        let _adr = `${this.WS.serverAddress}pay/cancelmeeting?apikey=${this.WS.appKey}&trToken=${this.props.match.params.tranToken}`;
        let addr = encodeURI(_adr);

        let r = await fetch(addr)

        if (r.status === 200) {

            let ss = await r.json(); // pentru tratarea de exceptii cu mesaje punctuale in fiecare pagina

            if(ss.success){

                const userToken = account.content[0].abonament === 'premium' && canceledAppointment.content[0].user_asignat_token ?
                                    canceledAppointment.content[0].user_asignat_token : null


                await this.NF.addNewNotification(locationType, notificationMessageCancelAppointment, accountToken,
                    userToken
                );
                
                this.setState({

                    cancelTx: 'Programarea a fost anulata!'
                })
            }
            else{

                this.setState({

                    cancelTx: 'A aparut o eroare la anularea programarii',
                    isError: true
                })
            }
        }
        else {

            this.setState({

                cancelTx: 'A aparut o eroare la anularea programarii',
                isError: true
            })
            
            console.error("GET SERVER STATUS ERR", r)
        }
    }

    render(){

        return(

            <div>

                 <div
                    style={{
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '100vh'
                    }}
                >
    
                    <div
                        style={{
                            
                            margin: 'auto',
                            padding: 20,
                            width: '600px',
                            background: '#fff',
                            borderRadius: '10px',
                            boxShadow: '0px 4px 25px rgba(0, 0, 0, 0.25)'
                        }}
                    >

                        {
                            !this.state.isError && (

                                <img 
                                    src={BusinessImage}
                                    alt="Business Image"
                                    style={{
                                        display: 'block',
                                        margin: '0 auto'
                                    }}
                                />
                            )
                        }

                        {
                            this.state.isError && (
                                <img 
                                    src={ErrorImg}
                                    alt="Business Image"
                                    style={{
                                        display: 'block',
                                        margin: '0 auto'
                                    }}
                                />

                            )
                        }

                        <p
                            style={{
                                margin: 0,
                                color: '#455A64',
                                fontSize: '18px',
                                fontWeight: 500
                            }}
                        >
                            {this.state.cancelTx}
                        </p>

                    </div>

                </div>

            </div>
        )
    }
}