import React from 'react';
import CrAppoIntervalView from '../../module/dashboard/create_appointment_popup/CrAppoIntervalView';
import CalendarClientFuncs from '../../module/logic/CalendarClientFuncs';
import Declarations from '../../providers/Declarations';
import LocalStorage from '../../providers/LocalStorage';
import Masks from '../../providers/Masks';
import Utils from '../../providers/Utils';
import Ws from '../../providers/Ws';
import AppointmentHourSelect from '../AppointmentHourSelect';
import DateChanger from '../DateChanger';
import ErrorMessage from '../ErrorMessage';
import Loader from '../Loader';
import IntervalViewClient from '../../module/client/IntervalViewClient';
import { BrowserView, isMobile, MobileView } from 'react-device-detect';

export default class CalendarAndHour extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            duration: 0,
            serviceDetails: {},
            programe: [],
            showSchedule: false,
            currentSchedule: [],
            confirmHour: '',
            dateSelected: this.props.dayCal || '',
            daySelectedSchedule: [],
            // dataStart: new Date(2021, 6 , 1),
            // dataEnd: new Date(2021, 6 , 30),
            dataStart: null,
            dataEnd: null,
            monthAppointments: [],
            isBreak: false,
            errorTx: '',
            isLoading: true
        };
    }

    WS = new Ws();
    UT = new Utils();
    M = new Masks();
    CLF = new CalendarClientFuncs();

    fecthParamteres = this.props.fetchParamteres;
    initDateRes = this.props.initDate();

    componentDidMount(){

        if(this.props.calendarEntryType){

            if(!isNaN(this.props.calendarEntryType)){

                this.setCurrentScheduleForBreak();
            }
            else{
    
                this.setServiceDataWithToken();
            }
        }

        // if(this.props.breakDuration){

        //     if(+this.props.breakDuration > 0){

        //         this.setCurrentScheduleForBreak();
        //     }
        // }

        if(this.props.serviceDetails){

            // 

            if(Object.keys(this.props.serviceDetails).length > 0){

                // 

                this.setServiceDataFromProps();
            }
        }
    }

    componentDidUpdate(prevProps, _){

        if(this.props.member !== prevProps.member){

            if(this.props.calendarEntryType){

                if(!isNaN(this.props.calendarEntryType)){
    
                    this.setCurrentScheduleForBreak();
                }
                else{
        
                    this.setServiceDataWithToken();
                }
            }

            
            if(this.props.serviceDetails){

                // 

                if(Object.keys(this.props.serviceDetails).length > 0){

                    // 

                    this.setServiceDataFromProps();
                }
            }
        }
    }

    setCurrentScheduleForBreak = () => {

        const breakDuration = this.props.calendarEntryType;

        let currentScheduleForBreak = [

            {
                du_start: '06:00:00',
                du_end: '20:00:00',
                lu_start: '06:00:00',
                lu_end: '20:00:00',
                ma_start: '06:00:00',
                ma_end: '20:00:00',
                mi_start: '06:00:00',
                mi_end: '20:00:00',
                jo_start: '06:00:00',
                jo_end: '20:00:00',
                vi_start: '06:00:00',
                vi_end: '20:00:00',
                sa_start: '06:00:00',
                sa_end: '20:00:00'
            }
        ]

        let serviceDetailsPlaceholder = {

            duration: +breakDuration,
            break_before: 0,
            break_after: 0,
        }

        let checkDurationValidity = new Declarations().listaDurata.filter(dEl => +dEl.val === +breakDuration);
        // 

        if(checkDurationValidity.length > 0){

            this.setState({

                currentSchedule: currentScheduleForBreak,
                serviceDetails: serviceDetailsPlaceholder,
                isBreak: true
            },
                () => {

                    this.initDate();
                }
            )
        }
        else{

            this.setState({

                errorTx: 'Durata pauzei nu este valida!'
            });
        }
        
    }

    setServiceDataWithToken = async () =>{

        const TOKEN_CONT = new LocalStorage().GetAccountToken();
        const serviceLink = this.props.calendarEntryType;

        let selectProgram = `select * from programs where account_tk = '${TOKEN_CONT}' and ifnull(sters,0)!=1   `;
        let selectService = `select * from services where account_tk = '${TOKEN_CONT}' and web_link = '${serviceLink}'  `;

        let _programeAcc = this.WS.sqlCommand(`select`, selectProgram);
        let _service = this.WS.sqlCommand(`select`, selectService);

        let [programeAcc, service] = await Promise.all( [_programeAcc, _service] );

        const intervalDate = JSON.parse(this.props.intervalDate);

        // 

        if(programeAcc.success && service.success){

            if(service.content.length === 0){

                this.setState({
    
                    errorTx: 'Link-ul serviciului introdus este gresit.'
                });
    
                return;
            }
            else if(programeAcc.content.length === 0){

                this.setState({
    
                    errorTx: 'Calendarul nu are niciun program valid!'
                });
    
                return;
            }
            else if(intervalDate['dataStart'] === undefined || 
                    intervalDate['dataEnd'] === undefined
            ){

                this.setState({
    
                    errorTx: 'Calendarul nu are o data valida!'
                });
    
                return;
            }

            const scheduleToken = this.props.member && this.props.member !== null ? this.props.member.program_tk : service.content[0]['program_tk'];
            const currentScheduleService = programeAcc.content.filter(scEl => scEl.token === scheduleToken);

            this.setState({

                serviceDetails: service.content[0],
                currentSchedule: currentScheduleService,
 
            },
                () => {

                    this.initDate();
                }
            );
        }
        else{   

            this.setState({

                errorTx: 'Eroare preluare date programari.'
            });
            throw new Error("Error fetching data: " + programeAcc.message + "\n" + service.message);
        }
    }

    setServiceDataFromProps = async () =>{

        if(this.initDateRes.err !== null){

            this.setState({

                errorTx: this.initDateRes.err['errorTx']
            },
                () => {

                    if(this.initDateRes.err['errorFunc'] !== null &&  
                        this.initDateRes.err['errorFunc'] !== undefined 
                    ){
                        
                        this.initDateRes.err.errorFunc();
                    }
                }
            
            );

            return;
        }
        else{

            let selectProgram = `select * from programs where ${this.initDateRes.data['accountTk']} and ifnull(sters,0)!=1   `;
            let programeAcc = await this.WS.sqlCommand(`select`, selectProgram);

            // 

            if(programeAcc.success){
                

                const scheduleToken = this.props.member && this.props.member !== null ? this.props.member.program_tk : this.props.serviceDetails['program_tk'];
                const currentScheduleService = programeAcc.content.filter(scEl => scEl.token === scheduleToken);
                // const currentScheduleService = programeAcc.content.filter(scEl => scEl.token === this.props.serviceDetails['program_tk']);
                // 

                this.setState({

                    serviceDetails: this.props.serviceDetails,
                    currentSchedule: currentScheduleService,
                },
                    () => {

                        if(this.props.dayCal){

                            this.jumpToDate();
                            this.onClickDate(this.M.specificDateToDateField(this.props.dayCal));
                        }
                        else{

                            this.initDate(); 
                        }
                    }
                );
            }
            else{

                throw new Error("Error fetching account schedule: " + programeAcc.message);
            }
        }     
    }

    jumpToDate = async () => {

        let dataProgramarii = this.M.specificDateToDateField(this.props.dayCal);
        let dataProgramariiAsJs = this.M.sqlDateToJSDate(dataProgramarii);

        let dataStart = new Date(dataProgramariiAsJs.getFullYear(), dataProgramariiAsJs.getMonth(), 1);
        
        let backTrick = this.M.addMonths(new Date(dataStart), 1);
        backTrick = this.M.addDaysToDate(backTrick, -1);

        let dataEnd = backTrick;

        // const TOKEN_CONT = new LocalStorage().GetAccountToken();

        const appointmentDays = 
            await this.CLF.setAvailableAppointmentDays(dataStart, dataEnd, this.state.currentSchedule[0], 
                this.state.serviceDetails, this.fecthParamteres['isToken'], this.fecthParamteres['token'], this.fecthParamteres['isPublic']);

        if(appointmentDays.length === 0){

            this.setState({

                errorTx: 'Datele programarilor nu pot fi prelucrate!'
            });
        }

        this.setState({

            dataStart: dataStart,
            dataEnd: dataEnd,
            monthAppointments: appointmentDays,
            isLoading: false
        },
        
            () => {

                // this.setAvailableAppointmentDays()
            }
        );     
    }

    initDate = async () => {
        
        if(this.initDateRes.err !== null){

            this.setState({

                errorTx: this.initDateRes.err['errorTx']
            },
                () => {

                    if(this.initDateRes.err['errorFunc'] !== null &&  
                        this.initDateRes.err['errorFunc'] !== undefined 
                    ){
                        
                        this.initDateRes.err.errorFunc();
                    }
                }
            
            );

            return;
        }
        else{
            
            const nextMonthStart = this.initDateRes['data']['dataStart'];
            const nextMonthEnd = this.initDateRes['data']['dataEnd'];
            const accountTk = this.initDateRes['data']['accountTk'];

            let selectAppoints;

            if(this.props.member){

                selectAppoints = `select * from trc where month(data_cal) = ${nextMonthStart.getMonth() + 1} and year(data_cal) = ${nextMonthStart.getFullYear()} 
                                                                                                            and ${accountTk} 
                                                                                                            and user_asignat_token = '${this.props.member.token}'
                                                                                                            and ifnull(draft,0)!=1  `;
            }
            else{

                selectAppoints = `select * from trc where month(data_cal) = ${nextMonthStart.getMonth() + 1} and year(data_cal) = ${nextMonthStart.getFullYear()} 
                                                                                                            and ${accountTk} and ifnull(draft,0)!=1  `;
            }

            let fetchAppoint = await this.WS.sqlCommand(`select`, selectAppoints)

            const appointmentDays = await this.CLF.setAvailableAppointmentDays(nextMonthStart, nextMonthEnd, this.state.currentSchedule[0], 
                this.state.serviceDetails, this.fecthParamteres['isToken'], this.fecthParamteres['token'], this.fecthParamteres['isPublic']);

            
            
            if(fetchAppoint.success){
                
        
                this.setState({

                    dataStart: nextMonthStart,
                    dataEnd: nextMonthEnd,
                    monthAppointments: appointmentDays,
                    isLoading: false
                });

            }
            else{

                throw new Error("Server response error: " + fetchAppoint.message)
            }
        }
    }

    nextMonth = async (back) => {

        let coef = back ? - 1 : 1;

        let nextMonthStart = this.M.addMonths(new Date(this.state.dataStart), coef)

        let nextMonthEnd = this.M.firstOrLastDayOfMonth( nextMonthStart.getMonth(), nextMonthStart.getFullYear(), true)

        // const TOKEN_CONT = new LocalStorage().GetAccountToken();

        let selectAppoints;

        if(this.props.member){

            selectAppoints =  `select * from trc where month(data_cal) = ${nextMonthStart.getMonth() + 1} and year(data_cal) = ${nextMonthStart.getFullYear()} 
                                                                                                        and ${this.initDateRes['data']['accountTk']} 
                                                                                                        and user_asignat_token = '${this.props.member.token}'
                                                                                                        and ifnull(draft,0)!=1  `;
        }
        else{

            selectAppoints =  `select * from trc where month(data_cal) = ${nextMonthStart.getMonth() + 1} and year(data_cal) = ${nextMonthStart.getFullYear()} 
                                                                                                        and ${this.initDateRes['data']['accountTk']} and ifnull(draft,0)!=1  `;
        }

        let fetchAppoint = await this.WS.sqlCommand(`select`, selectAppoints)

        const appointmentDays = await this.CLF.setAvailableAppointmentDays(nextMonthStart, nextMonthEnd, this.state.currentSchedule[0],
             this.state.serviceDetails, this.fecthParamteres['isToken'], this.fecthParamteres['token'], this.fecthParamteres['isPublic']);
        
        if(fetchAppoint.success){
        
            this.setState(
 
                (prevState, _) => {

                    const auxStartDate = prevState.dataStart;
                    const startDate = this.M.addMonths(new Date(auxStartDate), coef);
                    const endDate = this.M.firstOrLastDayOfMonth(startDate.getMonth(), startDate.getFullYear(), true)

                    // alert("3.startDate:" + startDate);
                    // alert("3.endDate: " + endDate);

                    return {
                        ...this.state,
                        dataStart: startDate,
                        dataEnd: endDate,
                        monthAppointments: appointmentDays,
                        isLoading: false
                    }
                },

                () => {

                   
                  
                }
                
            );

        }
        else{

            throw new Error("Server response error: " + fetchAppoint.message)
        }
    }

    createBreak = async () =>{

        const TOKEN_CONT = new LocalStorage().GetAccountToken();

        const tranToken = this.UT.generateToken();

        let insertQueryTran;

        if(this.props.member){

            insertQueryTran = ` insert into trc (token, cand_creat, account_tk, 
                service_tk, service_name, service_description,
                duration, location, price, 
                data_cal, ora_cal, draft, clie_nume, clie_prenume,
                clie_email, clie_tf, src,
                screen, tip, user_asignat_token
           ) 

           select '${tranToken}', NOW(), '${TOKEN_CONT}',
                   ${null}, ${null}, ${null},
                   ${this.state.serviceDetails.duration}, '${null}', 0,
                   '${this.state.dateSelected}', '${this.state.confirmHour}', 0, 
                   'Pauza', 'Pauza', ${null},
                   ${null}, 'ca',
                   'popup', 'pauza', '${this.props.member.token}'
               
               `;
        }
        else{

            insertQueryTran = ` insert into trc (token, cand_creat, account_tk, 
                service_tk, service_name, service_description,
                duration, location, price, 
                data_cal, ora_cal, draft, clie_nume, clie_prenume,
                clie_email, clie_tf, src,
                screen, tip
           ) 

           select '${tranToken}', NOW(), '${TOKEN_CONT}',
                   ${null}, ${null}, ${null},
                   ${this.state.serviceDetails.duration}, '${null}', 0,
                   '${this.state.dateSelected}', '${this.state.confirmHour}', 0, 
                   'Pauza', 'Pauza', ${null},
                   ${null}, 'ca',
                   'popup', 'pauza'
               
               `;
        }

        const createTran = await this.WS.sqlCommand(`insert`, insertQueryTran)
        
        if(createTran.success){

            const result = {

                date: this.state.dateSelected,
                hour: this.state.confirmHour,
                tranToken: tranToken
            }
            this.props.onShowBreakAddSuccess(result);
        }
        else{

            throw new Error("Server response error: " + createTran.message)
        }
    }

    createClientAppointment = async () => {

        // const TOKEN_CONT = new LocalStorage().GetAccountToken();

        const tranToken = this.UT.generateToken();
        let insertQueryTran;

        if(this.props.member){

            insertQueryTran = `insert into trc (token, cand_creat, account_tk, 
                service_tk, service_name, service_description,
                duration, location, price, 
                data_cal, ora_cal, draft, 
                screen, src, tip, user_asignat_token
           ) 

           select '${tranToken}', NOW(), '${this.state.serviceDetails.account_tk}',
                   '${this.state.serviceDetails.token}', '${this.state.serviceDetails.name}', '${this.state.serviceDetails.description}',
                   '${this.state.serviceDetails.duration}', '${this.state.serviceDetails.location}', '${this.state.serviceDetails.price}',
                   '${this.state.dateSelected}', '${this.state.confirmHour}', 1, 
                   '${this.fecthParamteres['appointScreen']}',  '${this.fecthParamteres['appointSrc']}', '${this.fecthParamteres['appointType']}',
                   '${this.props.member.token}' 
            `;

        }
        else{

            insertQueryTran = `insert into trc (token, cand_creat, account_tk, 
                service_tk, service_name, service_description,
                duration, location, price, 
                data_cal, ora_cal, draft, 
                screen, src, tip
           ) 

           select '${tranToken}', NOW(), '${this.state.serviceDetails.account_tk}',
                   '${this.state.serviceDetails.token}', '${this.state.serviceDetails.name}', '${this.state.serviceDetails.description}',
                   '${this.state.serviceDetails.duration}', '${this.state.serviceDetails.location}', '${this.state.serviceDetails.price}',
                   '${this.state.dateSelected}', '${this.state.confirmHour}', 1, 
                   '${this.fecthParamteres['appointScreen']}',  '${this.fecthParamteres['appointSrc']}', '${this.fecthParamteres['appointType']}' 
            `;
        }

        let createTran = await this.WS.sqlCommand(`insert`, insertQueryTran)

        if(createTran.success){

            // alert("Redirectionare completare date tranzactie.")
            // // this.props.history.push("/trc/" + tranToken)
            // this.props.history.push("/trc/" + tranToken + "/datele-mele")
            this.props.onShowClientForm(tranToken);
    
        }
        else{

            throw new Error("Server response error: " + createTran.message)
        }

        // this.props.onShowClientForm(tranToken);
    }

    onSelectHour = (hour) => {

        this.setState({

            confirmHour: hour
        })

    }

    onConfirmHour = async () => {

        if(this.state.isBreak){

            await this.createBreak();
        }
        else{

           await this.createClientAppointment();
        }
        

        // this.props.onShowClientDataReg()      
    }

    onClickDate = async (data) =>{

        // const TOKEN_CONT = new LocalStorage().GetAccountToken();
        
        // await this.onShowCalendarSchedule(data)
        // document.getElementById('select-hours').scrollIntoView({behavior: 'smooth'})
        let dataJs = this.M.sqlDateToJSDate(data);

        let program = this.CLF.createScheduleArray(this.state.currentSchedule[0]);
        let programNotNull = program.filter(pEl => {

            if(pEl.zi_start !== null && pEl.zi_end !== null){

                return pEl;
            }
        });

        let getProgramOnDay = programNotNull.filter(pEl => +pEl.day === +dataJs.getDay());

        let comparationDate = new Date();
        comparationDate.setHours(0,0,0,0);

        let diffHours = 0;

        if(new Date().getHours() >= 12){

            let dataJsModified = new Date(dataJs);
            dataJsModified.setHours(12, 0, 0, 0);
            diffHours = ( new Date().getTime() - dataJsModified.getTime() ) / 1000;
        }
        else{

            diffHours = ( new Date().getTime() - dataJs.getTime() ) / 1000;
        }

        diffHours /= (60 * 60);
        diffHours = Math.abs(Math.round(diffHours));

        if( ( getProgramOnDay.length === 0 ) || ( dataJs.getTime() < comparationDate.getTime() || diffHours <= 12)
        ){

            this.setState({

                errorTx: 'Ziua selectata nu este disponibila pentru acest serviciu.'
            });

            // 
            // this.fecthParamteres['errorFunc'] !== undefined )

            if(this.fecthParamteres['errorFunc'] !== null && 
                this.fecthParamteres['errorFunc'] !== undefined 
            ){
                
                this.fecthParamteres.errorFunc();
            }

            return;
        }
        else if( ( +this.state.serviceDetails['dispo_nr_zile'] > 0 ) && 
            ( (this.state.serviceDetails['dispo_data_end'] || '').length < 2 )
        ){
            
            const dataStartZile = this.M.sqlDateToJSDate(this.state.serviceDetails['dispo_data_start']);
            let dataEndZile = this.M.addDaysToDate(this.M.sqlDateToJSDate(this.state.serviceDetails['dispo_data_start']), +this.state.serviceDetails['dispo_nr_zile']);

            if(this.state.serviceDetails['dispo_tip_zile'] === 'work-days'){

                dataEndZile = this.UT.addWorkDays(new Date(this.M.sqlDateToJSDate(this.state.serviceDetails['dispo_data_start'])),  +this.state.serviceDetails['dispo_nr_zile'])

            }

            if(( dataJs.getTime() < dataStartZile.getTime() ) || ( dataJs.getTime() >= dataEndZile.getTime() ) ){

                this.setState({

                    errorTx: 'Ziua selectata nu este disponibila pentru acest serviciu.'
                });

                if(this.fecthParamteres['errorFunc'] !== null &&  
                        this.fecthParamteres['errorFunc'] !== undefined 
                    ){
                        
                        this.fecthParamteres.errorFunc();
                    }
    
                return;
            }
        }

        else  if( ( +this.state.serviceDetails['dispo_nr_zile'] === 0 ) && ( (this.state.serviceDetails['dispo_data_start'] || '').length > 2 ) &&
        ( (this.state.serviceDetails['dispo_data_end'] || '').length > 2 )
        ){


            const dataStartZile = this.M.sqlDateToJSDate(this.state.serviceDetails['dispo_data_start']);
            const dataEndZile = this.M.sqlDateToJSDate(this.state.serviceDetails['dispo_data_end']);

            if(( dataJs.getTime() < dataStartZile.getTime() ) || ( dataJs.getTime() > dataEndZile.getTime() ) ){

                this.setState({

                    errorTx: 'Ziua selectata nu este disponibila pentru acest serviciu.'
                });

                if(this.fecthParamteres['errorFunc'] !== null &&  
                        this.fecthParamteres['errorFunc'] !== undefined 
                    ){
                        
                        this.fecthParamteres.errorFunc();
                    }
    
                return;
            }
            
        }
        

        const selectedDaySchedule = 
            await this.CLF.onShowCalendarSchedule(data, this.fecthParamteres['isToken'], this.fecthParamteres['token'], 
                this.state.currentSchedule[0], +this.state.serviceDetails['duration'], this.fecthParamteres['isPublic']);


        this.setState({
            showSchedule: true,
            dateSelected: data,
            daySelectedSchedule: selectedDaySchedule,
            confirmHour: '',
            // isLoading: false
        },
            () => {

                const selectHours = document.getElementById('select-hours');

                if(selectHours !== null){
                    
                    selectHours.scrollIntoView({behavior: 'smooth'});
                }

                if(this.props.onSetContainerExpansion !== null &&
                    this.props.onSetContainerExpansion !== undefined
                ){

                    this.props.onSetContainerExpansion();
                }
            }
        ) 
    }


    // onCloseCreateAppointPopup = () => {

    //     this.props.onCloseCreateAppointPopup();
    // }

    onChangeDate = (back) => {

        this.setState({

            isLoading: true
        },
            () => {

                // setTimeout( () => this.nextMonth(back), 500);
                this.nextMonth(back)
            }
        );
    }

    render(){

        const columnRight = this.state.showSchedule ? 'col-l-5 col-m-5 col-s-12' : 'col-l-6 col-m-6 col-s-12';

        return(

            <>
            
   

                {
                    this.props.renderPublicCalendar && (

                    <div>

                        <BrowserView>

                        <div 
                            className={columnRight}
                            style={{
                                paddingLeft: 10,
                                // borderLeft: '1px solid #EBEBEB'
                            }}
                        >

                            <h3
                                className="client-cal-select-title"
                                style={{
                                    margin: 0,
                                    marginTop: 60,
                                    color: '#455A64',
                                    fontSize: '18px',
                                    textAlign: 'left'
                                }}
                            >
                                Selecteaza Data si Ora
                            </h3>
                            
                            <br />

                            <div
                                className="client-cal-select-month"
                                style={{
                                    textAlign: 'left'
                                }}
                            >
                                <DateChanger
                                    nextMonth = {this.onChangeDate}
                                    date = {
                                        this.state.isLoading ? '...' : 
                                        `${this.M.monthName(this.state.dataStart.getMonth())} ${this.state.dataStart.getFullYear()}`
                                    }
                                    arrowSize = '28px'
                                    arrowTop = '0px'
                                    dateFont = '16px'
                                    dateTop = '-7px'
                                    arrowColor = '#455A64'
                                    dateColor = '#455A64'
                                    showBackArrow = {this.state.dataStart && (this.state.dataStart.getMonth() !== new Date().getMonth() )}
                                />

                            
                            </div>

                            {
                                this.state.isLoading && (

                                    <Loader 
                                        containerH = '300px'
                                    />
                                )
                            }

                            {
                                !this.state.isLoading && (
                                    
                                    <div className="appoint-calendar">

                                        <IntervalViewClient 
                                            dataStart={this.state.dataStart}
                                            dataEnd={this.state.dataEnd}
                                            onClickDate={this.onClickDate}
                                            elements={this.state.monthAppointments}
                                            dateSelected = {this.state.dateSelected}
                                        />

                                    </div>

                                )
                            }
                            

                        </div>

                        {
                            this.state.showSchedule &&

                            <div 
                                
                                className="col-l-3 col-m-3 col-s-12"
                                style={{

                                    paddingLeft: 20
                                }}
                            >

                                <h3
                                    style={{
                                        margin: 0,
                                        color: '#455A64',
                                        fontSize: '16px',
                                        marginTop: 80
                                    }}
                                >
                                    {
                                        this.state.dateSelected &&
                                        this.M.dayOfWeek(this.M.sqlDateToJSDate(this.state.dateSelected).getDay()) + ' ' +
                                        this.M.sqlDateToJSDate(this.state.dateSelected).getDate() + ' ' +
                                        this.M.monthName(this.M.sqlDateToJSDate(this.state.dateSelected).getMonth() )
                                    }
                                </h3>

                                <div
                                    style={{
                                        height: '400px',
                                        overflowY: 'auto'
                                    }}
                                >

                                    <AppointmentHourSelect 
                                        daySelectedSchedule = {this.state.daySelectedSchedule}
                                        dateSelected = {this.state.dateSelected}
                                        serviceDetails = {this.state.serviceDetails}
                                        confirmHour = {this.state.confirmHour}
                                        onSelectHour = {this.onSelectHour}
                                        onConfirmHour = {this.onConfirmHour}
                                    />
                                
                                </div>

                            </div>

                        }

                        </BrowserView>

                        <MobileView>

                            <h3
                                style={{
                                    margin: 0,
                                    fontSize: 18,
                                    color: '#455A64'
                                }}
                            >

                                {
                                    this.state.dateSelected && (

                                        this.M.dayOfWeek(this.M.sqlDateToJSDate(this.state.dateSelected).getDay()) + ' ' +
                                        this.M.sqlDateToJSDate(this.state.dateSelected).getDate() + ' ' +
                                        this.M.monthName(this.M.sqlDateToJSDate(this.state.dateSelected).getMonth() ) 
                                    
                                    ) || 'Selecteaza o data libera'
                                }

                            </h3>

                            <br />

                            <div

                            >

                                <DateChanger
                                    nextMonth = {this.nextMonth}
                                    date = {
                                        this.state.isLoading ? '...' : 
                                        `${this.M.monthName(this.state.dataStart.getMonth())} ${this.state.dataStart.getFullYear()}`
                                    }
                                    arrowSize = '28px'
                                    arrowTop = '0px'
                                    dateFont = '16px'
                                    dateTop = '-7px'
                                    arrowColor = '#455A64'
                                    dateColor = '#455A64'
                                    showBackArrow = {this.state.dataStart && (this.state.dataStart.getMonth() !== new Date().getMonth() )}
                                />
                                
                            </div>

                            {
                                this.state.isLoading && (

                                    <Loader 
                                        containerH = '300px'
                                    />
                                )
                            }

                            {
                                !this.state.isLoading && (

                                    <div className="m-calendar">

                                        <IntervalViewClient 
                                            dataStart={this.state.dataStart}
                                            dataEnd={this.state.dataEnd}
                                            elements={this.state.monthAppointments}
                                            onClickDate={this.onClickDate}
                                        />

                                    </div>
                                )
                            }  

                            {
                                this.state.showSchedule && (

                                    <div id='select-hours' className="m-select-hour-container">

                                        <AppointmentHourSelect 
                                            daySelectedSchedule = {this.state.daySelectedSchedule}
                                            dateSelected = {this.state.dateSelected}
                                            serviceDetails = {this.state.serviceDetails}
                                            confirmHour = {this.state.confirmHour}
                                            onSelectHour = {this.onSelectHour}
                                            onConfirmHour = {this.onConfirmHour}
                                        />

                                    </div>
                                )
                     
                            }

                        </MobileView>

                    </div>

                    
                )
            }

                

                {

                    this.props.renderDashboardCalendar && (

                        
                        <>
                            {
                                this.state.errorTx.length > 0 && (

                                    <ErrorMessage 
                                        eroareTx = {this.state.errorTx}
                                    />
                                )
                            }

                            {

                                this.state.errorTx.length === 0 && (

                                    <>
                                        {
                                            this.props.header && (

                                                <>
                                                
                                                    {this.props.header}
                                                    {/* <br /> */}
                                                
                                                </>
                                            )
                                        }
                                        <div
                                            style={{
                                                marginTop: '20px',
                                                width: '100%',
                                                height: '1px',
                                                background: '#EBEBEB'
                                            }}
                                        ></div>

                                        <div
                                            style={{
                                                marginTop: '10px',
                                                textAlign: 'center'
                                            }}
                                        >
                                            <DateChanger
                                                nextMonth = {this.onChangeDate}
                                                date = {
                                                    this.state.isLoading ? '...' : 
                                                    `${this.M.monthName(this.state.dataStart.getMonth())} ${this.state.dataStart.getFullYear()}`
                                                }
                                                arrowSize = '28px'
                                                dateFont = '16px'
                                                arrowTop = '8px'
                                                arrowColor = '#455A64'
                                                dateColor = '#455A64'
                                                showBackArrow = {this.state.dataStart && (this.state.dataStart.getMonth() !== new Date().getMonth() )}
                                            />

                                        </div>

                                        <div
                                            style={{
                                                width: isMobile ? '100%' : '300px',
                                                margin: '20px auto',
                                                textAlign: 'center'
                                            }}
                                        >

                                    
                                            {
                                                this.state.isLoading && (

                                                    <Loader 
                                                        containerH = '300px'
                                                    />
                                                )
                                            }

                                            {
                                            !this.state.isLoading && (

                                                <CrAppoIntervalView 
                                            
                                                        dataStart={this.state.dataStart}
                                                        dataEnd={this.state.dataEnd}
                                                        onClickDate={this.onClickDate}
                                                        elements={this.state.monthAppointments}
                                                        // dateSelected={this.props.dayCal}
                                                        dateSelected={this.state.dateSelected}
                                                    />
                                                )
                                            }

                                        {
                                            this.state.showSchedule && (

                                                <div 
                                                    style={{
                                                        width: '300px',
                                                    }}
                                                >

                                                    <br />
                                                
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            height: '1px',
                                                            background: '#EBEBEB'
                                                        }}
                                                    ></div>

                                                    <br />

                                                    <h3
                                                        id='select-hours'
                                                        style={{
                                                            margin: 0,
                                                            color: '#455A64',
                                                            fontSize: '16px',
                                                        }}
                                                    >
                                                        {
                                                            this.state.dateSelected &&
                                                            this.M.dayOfWeek(this.M.sqlDateToJSDate(this.state.dateSelected).getDay()) + ' ' +
                                                            this.M.sqlDateToJSDate(this.state.dateSelected).getDate() + ' ' +
                                                            this.M.monthName(this.M.sqlDateToJSDate(this.state.dateSelected).getMonth() )
                                                        }
                                                    </h3>

                                                    <br />

                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            fontWeight: '400',
                                                            fontSize: '14px',
                                                            color: '#455A64'
                                                        }}
                                                    >
                                                        Selecteaza o ora libera
                                                    </p>

                                                    <div
                                                        style={{
                                                            marginTop: 20
                                                        }}
                                                    >

                                                        <AppointmentHourSelect 
                                                            daySelectedSchedule = {this.state.daySelectedSchedule}
                                                            dateSelected = {this.state.dateSelected}
                                                            serviceDetails = {this.state.serviceDetails}
                                                            confirmHour = {this.state.confirmHour}
                                                            onSelectHour = {this.onSelectHour}
                                                            onConfirmHour = {this.onConfirmHour}
                                                        />

                                                    </div>
                                                    
                                                </div>
                                            )

                                        }
                                        </div>
                                    </>
                                )
                            }
                        
                        </>

                       
                        
                    )
                }
            </>
        );
    }
}