import React from 'react';
import { GoCheck } from 'react-icons/go';

export default class ColorPicker extends React.Component{

    constructor(props){

        super(props);
    }

    render(){

        return(

            <div className="colors-container">
                                        
                {   
                    this.props.listaCulori &&
                    this.props.listaCulori.map((lcEl, idx) => {

                        if(lcEl.val === this.props.culoare){        
                            
                            return(

                                <div 
                                    key={"service-color-"+idx}
                                    className="color-circle selected-color"
                                    style={{
                                        background: lcEl.col
                                    }}
                                >
                                    <GoCheck className="color-checked" />
                                </div> 
                            );
                        }
                        else{

                            return(

                                <div 
                                    key={"service-color-"+idx}
                                    className="color-circle"
                                    style={{
                                        background: lcEl.col
                                    }}
                                    onClick={ () => {

                                        this.props.onClickCuloare(lcEl.val)
                                    }}
                                >
                                    
                                </div> 
                            );
                        }
                    }, this)

                }

                {/* <div 
                    className="color-circle"
                    style={{
                        background: '#C7C7C7'
                    }}
                >

                    <BiPlus className="add-color" />
                </div> */}

            </div>
        );
    }
}