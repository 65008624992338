import React from 'react';

import { MobileView } from 'react-device-detect';

import { RiCalendar2Fill } from 'react-icons/ri';
import ErrorImage from '../../../../assets/Feeling sorry-cuate 1.png';
import LaptopImage from '../../../../assets/laptop_and_mug.png';
import ErrorMessage from '../../../../commons/ErrorMessage';

import MServiceNav from '../../../../commons/MServiceNav';

import Masks from '../../../../providers/Masks';
import Utils from '../../../../providers/Utils';
import Ws from '../../../../providers/Ws';

export default class MCrappoAddBreakSuccess extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            breakDetails: {}, //h2ysM7GihB7RUpSfmDo3rWX0nPNWanyj
            errorTx: ''
        }
    }

    WS = new Ws();
    UT = new Utils();
    M = new Masks();

    componentDidMount(){

        this.getBreakDetails();
    }

    getBreakDetails = async () => {

        let selectBreak = `select * from trc where token = '${this.props.match.params.tranToken}' and ifnull(draft,0)!=1 `;
        let caBreak = await this.WS.sqlCommand(`select`, selectBreak); 

        if(caBreak.success){

            if(caBreak.content.length === 0){

                this.setState({

                    errorTx: 'A aparut o eroare. Token pauza gresit.'
                });

                return;
            }

            this.setState({

                breakDetails: caBreak.content[0]
            });
        }
        else{


            this.setState({

                errorTx: 'Server-ul nu raspunde cererii.'
            });
            throw new Error("Error getting break data: " + caBreak.success);
        }
    }

    render(){

        return(

            <MobileView>

                <MServiceNav
                    icon={<RiCalendar2Fill />}
                    title='Adauga in calendar'
                    path={'/ca/dashboard/cab/creare-programare'}
                />

                <div
                    style={{
                        padding: '20px'
                    }}
                >

                    <div
                        className="m-create-appointment-card"
                        style={{
                            padding: '20px',
                            borderRadius: '10px',
                            textAlign: 'center'
                        }}
                    >

                        {
                            this.state.errorTx.length > 0 && (

                               <ErrorMessage 
                                    eroareTx = {this.state.errorTx}
                               />
                            )
                        }

                        {
                            this.state.errorTx.length === 0 && (


                                <div>

                                    <p
                                        style={{
                                            margin: 0,
                                            fontSize: '18px',
                                            fontWeight: '700',
                                            color: '#60D84C'
                                        }}
                                    >
                                        Felicitari!
                                    </p>

                                    <br />

                                    <p
                                        style={{
                                            margin: 0,
                                            fontSize: '14px',
                                            fontWeight: '400',
                                            color: '#455A64'
                                        }}
                                    >
                                        Ai inregistrat cu succes <br />
                                        pauza de <b>{this.state.breakDetails.duration} Minute </b> pentru:
                                    </p>

                                    <br />

                                    <div>

                                        <RiCalendar2Fill 
                                            style={{
                                                display: 'inline-block',
                                                position: 'relative',
                                                top: 4,
                                                margin: 0,
                                                fontSize: '18px',
                                                color: '#455A64'
                                            }}
                                        />

                                        <p
                                            style={{
                                                display: 'inline-block',
                                                position: 'relative',
                                                margin: 0,
                                                fontSize: '14px',
                                                fontWeight: '400',
                                                color: '#455A64'
                                            }}
                                        >
                                            &nbsp; {    
                                                        this.state.breakDetails['data_cal'] && (

                                                            this.M.dayOfWeek(this.M.sqlDateToJSDate(this.state.breakDetails['data_cal']).getDay()) + ' ' +
                                                            this.M.sqlDateToJSDate(this.state.breakDetails['data_cal']).getDate() + ' ' +
                                                            this.M.monthName(this.M.sqlDateToJSDate(this.state.breakDetails['data_cal']).getMonth()) + ', Ora ' +
                                                            this.state.breakDetails['ora_cal'].substr(0,5)
                                                        )
                                                    }
                                        </p>

                                    </div>

                                </div>
                            )
                        }

                    </div>

                    <img 
                        src={LaptopImage}
                        alt="Calendar Image"
                        style={{
                            display: 'block',
                            margin: '30px auto',
                            width: '200px',
                        }}
                    />

                </div>

            </MobileView>
        )
    }
}