import React from 'react';

import Ws from '../../providers/Ws';
import Utils from '../../providers/Utils';
import Declarations from '../../providers/Declarations';

import { MdAlarm } from 'react-icons/md';
import { ImLocation2 } from 'react-icons/im';
import { IoVideocam } from 'react-icons/io5';
import { FaSkype } from 'react-icons/fa';
import { HiCurrencyDollar } from 'react-icons/hi';
import { RiCalendar2Fill } from 'react-icons/ri';
import { RiArrowLeftSLine } from 'react-icons/ri';

import ButtonForm from '../../commons/ButtonForm';
import MClientServiceInfo from './MClientServiceInfo';
import CompanyAvatar from '../../assets/foxStat.jpeg';
import CanaryPlantImage from '../../assets/canary_plant_bg.png';
import TownImage from '../../assets/town_and_clouds.png';
import { ReactComponent  as GMeetIcon} from '../../assets/Google_Meet-Logo.wine.svg';
import ErrorImg from '../../assets/Feeling sorry-cuate 1.png';

import { BrowserView, MobileView } from 'react-device-detect';
import { Link } from 'react-router-dom';
import Masks from '../../providers/Masks';
import LocalStorage from '../../providers/LocalStorage';
import SumarInfo from './SumarInfo';
import SumarInfoClient from './SumarInfoClient';
import ErrorMessage from '../../commons/ErrorMessage';
import ClientValidationFuncs from '../logic/ClientValidationFuncs';
import AvatarAccount from '../../commons/AvatarAccount';
import Loader from '../../commons/Loader';


export default class SumarProgramare extends React.Component{

    constructor(props){

        super(props)

        this.state = {

            transactionDetails: {},
            locatieCurenta: {},
            eroareTx: '',
            isDoctorService: false,
            accountName: '',
            accountImgSrc: '',
            member: [],
            isInvite: false,
            isLoading: true
        }
    }

    WS = new Ws();
    UT = new Utils();
    M = new Masks();
    CVF = new ClientValidationFuncs();

    componentDidMount(){

        this.getServiceData();
        window.scrollTo(0,0);
    }

    getServiceData = async () => {

        const selectTransaction = `select * from trc where token= '${this.props.match.params.tranToken}'    `;

        const _transaction = this.WS.sqlCommand(`select`, selectTransaction);

        const qUsers = `select * from users where account_id =  (select account_tk from trc where token = '${this.props.match.params.tranToken}') `;
        let _users = this.WS.sqlCommand(`select`, qUsers);

        const qAccount = `select * from accounts where token = (select account_tk from trc where token = '${this.props.match.params.tranToken}')`;
        let _account = this.WS.sqlCommand(`select`, qAccount);

        const [transaction, users, account] = await Promise.all([_transaction, _users, _account]);

        if(transaction.content.length === 0){

            this.setState({

                eroareTx: 'A aparut o eroare. Verifica daca link-ul tranzactiei este corect.'
            });
        }

        if(transaction.success){
            
            const errorTxOverlap = await this.CVF.checkIfAppointIsOverlaping(transaction.content[0]['data_cal'], transaction.content[0]['ora_cal'], this.props.match.params.tranToken, false);
            const errorTxCommon = this.CVF.clientPageCommonExceptionMessage(transaction.content[0]);
            const errorTxUncomplete = this.CVF.uncompletedDataExceptionMessage(transaction.content[0]);

            if(errorTxOverlap.length > 0){

                this.setState({

                    eroareTx: errorTxOverlap,
                    isLoading: false
                });
            }

            if(errorTxCommon.length > 0){

                this.setState({

                    eroareTx: errorTxCommon,
                    isLoading: false
                });
            }

            if(errorTxUncomplete.length > 0){

                this.setState({

                    eroareTx: errorTxUncomplete,
                    isLoading: false
                });
            }
            
            if(transaction.content.length > 0){

                let isDoctorServiceTrue = false;
                
                if(localStorage.getItem("userData")){

                    const accountTk = new LocalStorage().GetAccountToken();
                    const checkTranAccountTk = transaction.content.filter(tEl => tEl['account_tk'] === accountTk);
                    isDoctorServiceTrue = checkTranAccountTk.length > 0 ? true : false;
                }

                const accountData =  await this.UT.getAccountAvAndName(false, transaction.content[0]['account_tk']);

                let isInviteType = false;

                if(( transaction.content[0].src || '' ) === 'ca' && (transaction.content[0]['replaned_trc_tk'] || '').length < 10){

                    isInviteType = true;
                }

                const user = users.content.filter( user => user.token === transaction.content[0].user_asignat_token);
                const isPremiumUser = this.props.match.params.memberLink && account.content[0].abonament === 'premium';

                this.setState({

                    transactionDetails: transaction.content[0],
                    isDoctorService: isDoctorServiceTrue,
                    accountName: isPremiumUser ? user[0]['nume'] : accountData['name'],
                    accountImgSrc: this.WS.serverAddress + 'staticprovider/getimg?filepath=Storage/Avatars/' + accountData['avatar'],
                    member:  isPremiumUser ? [] : user,
                    isInvite: isInviteType,
                    isLoading: false
                },
                    () => {
    
                        this.setLocatieCurenta(transaction.content[0].location)
                    
                    }
                )
    
            }

           

            const uQ = ` update trc set screen = 'finalizare' where token = '${this.props.match.params.tranToken}'   `
            const updateScreen = await this.WS.sqlCommand(`update`, uQ);

            if(!updateScreen.success){

                // 
            }
        }
        else{

            this.setState({

                eroareTx: 'Eroare server. Va rugam reimprospatati pagina.'
            })
            throw new Error("Server response error: " + transaction.message)
        }
    }

    setLocatieCurenta = (location) => {

        let locatieCurenta = new Declarations().listaLocatiiIntalnire.filter(lEl => lEl.id === location)

        this.setState({

            locatieCurenta: locatieCurenta[0]
        })
    }

    onConfirmAppointment = async () => {

        const updateTrcQuery = `update trc set 
                                            draft = 0
                                            
                                where token = '${this.props.match.params.tranToken}'
        
        `

        const updateTransaction = await this.WS.sqlCommand(`update`, updateTrcQuery);
        
        if(updateTransaction.success){

            // alert("Tranzactie finalizata cu succes!")
            if(this.props.match.params.memberLink){

                this.props.history.push(`/trc/${this.props.match.params.memberLink}/${this.props.match.params.tranToken}/finalizare-succes`);
            }
            else{

                this.props.history.push("/trc/" + this.props.match.params.tranToken 
                                    + "/finalizare-succes");
            }

        }
        else{

            throw new Error("Server respnse error: " + updateTransaction.message)
        }
        
    }

    onRedirectionarePlata = () => {

        // window.open("/#/trc/" + this.props.match.params.tranToken + "/cfp")
        if(this.props.match.params.memberLink){

            this.props.history.push(`/trc/${this.props.match.params.memberLink}/${this.props.match.params.tranToken}/cfp`);
        }
        else{

            this.props.history.push("/trc/" + this.props.match.params.tranToken 
                                + "/finalizare-succes");
        }
    }

    onInviteAccept = async () => {

        let _adr = `${this.WS.serverAddress}invite/confirm?apikey=${this.WS.appKey}&trcToken=${this.props.match.params.tranToken}`;
        let addr = encodeURI(_adr);

        let r = await fetch(addr)

        if (r.status === 200) {
            
            let ss = await r.json(); // pentru tratarea de exceptii cu mesaje punctuale in fiecare pagina

            if(ss.success){

                this.props.history.push('/trc/'+this.props.match.params.tranToken+'/cfp');
            }
            else{

                this.setState({

                    eroareTx: 'Confirmarea programarii a esuat!',
                })
            }

            // alert("Succes confirmare!")
            // this.props.history.push('/trc/'+this.props.match.params.tranToken+'/cfp');
            
           
        }
        else {
            this.setState({

                eroareTx: 'Confirmarea programarii a esuat!',
            })
            console.error("GET SERVER STATUS ERR", r)
        }
    }


    onRefuseInvite = () => {

        this.props.history.push('/cancel/appointment/' + this.props.match.params.tranToken);
    }

    render(){

        let btnText = '';

        if(this.state.isInvite){

            btnText = 'Accepta Invitatia';
        }
        else if(+this.state.transactionDetails.price === 0 || this.state.isDoctorService === true || (this.state.transactionDetails['replaned_trc_tk'] || '').length > 10){

            btnText = 'Finalizeaza'
        }
        else{

            btnText = 'Mergi la plata'
        }

        return(
            <React.Fragment>

            <BrowserView>
            
            {
                this.state.isLoading && (

                    <Loader 
                        fullPage = {true}
                    />
                )
            }

            {
                !this.state.isLoading && (

                    <>

                         <div className="client-center">

                            <div className="client-wrapper">

                                <div className="client-card">

                                    {
                                        this.state.eroareTx.length === 0 && 
                                        (
                                            <div>

                                                <div className="card-wrapper">

                                                    {/* <div className="company-avatar-outer incard">

                                                        <div className="company-avatar-inner">
                                                            <img src={this.state.accountImgSrc} alt="Avatar companie" />
                                                        </div>

                                                    </div> */}

                                                    {
                                                        this.state.transactionDetails['account_tk'] && (

                                                            <AvatarAccount 
                                                                isNotLogged = {false}
                                                                accToken = {this.state.transactionDetails['account_tk']}
                                                                top = '-92px'
                                                                border = '6px solid #fff'
                                                            />
                                                        )
                                                    }

                                                    <h2 
                                                        style={{
                                                            margin: 0,
                                                            marginTop: -80,
                                                            color: '#455A64',
                                                            // fontSize: '18px',
                                                        }}
                                                    >
                                                    {this.state.accountName}
                                                    </h2>

                                                    <h4 
                                                        style={{
                                                            margin: 0,
                                                            marginTop: 10,
                                                            color: '#455A64',
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        Sumar Programare
                                                    </h4>

                                                    <SumarInfo 
                                                        serviceName = {this.state.transactionDetails['service_name']}
                                                        data = {this.state.transactionDetails['data_cal']}
                                                        ora = {this.state.transactionDetails['ora_cal']}
                                                        duration = {this.state.transactionDetails['duration']}
                                                        price = {this.state.transactionDetails['price']}
                                                        locatieCurenta = {this.state.locatieCurenta}
                                                        member = {this.state.member}
                                                        
                                                    />

                                                </div>

                                                <SumarInfoClient 
                                                
                                                    nume = {this.state.transactionDetails['clie_nume']}
                                                    prenume = {this.state.transactionDetails['clie_prenume']}
                                                    email = {this.state.transactionDetails['clie_email']}
                                                    telefon = {this.state.transactionDetails['clie_tf']}
                                                    detalii = {this.state.transactionDetails['clie_detalii']}
                                                />

                                                {
                                                    +this.state.transactionDetails.price > 0 && (

                                                        <p 
                                                            style={{
                                                                margin:0,
                                                                marginTop: 30,
                                                                fontSize: '16px'
                                                            }}
                                                        >
                                                            Plateste pentru a finaliza inregistrarea programarii tale
                                                        </p>
                                                    )
                                                }

                                                {
                                                    +this.state.transactionDetails.price === 0 && (

                                                        <p 
                                                            style={{
                                                                margin:0,
                                                                marginTop: 30,
                                                                fontSize: '16px'
                                                            }}
                                                        >
                                                            Apasa butonul de mai jos pentru a finaliza programarea ta
                                                        </p>
                                                    )
                                                }

                                                {
                                                    this.state.isInvite && (

                                                        <ButtonForm 
                                                            className="client-schedule-btn"
                                                            // tx={+this.state.transactionDetails.price === 0 || this.state.isDoctorService === true ? 'Finalizeaza' : 'Plateste'}
                                                            tx={btnText}
                                                            style={{
                                                                marginTop: '20px',
                                                                marginRight: '10px',
                                                                width: '180px',
                                                            }}
                                                            // onClick={this.onConfirmAppointment}
                                                            onClick={this.onInviteAccept}
                                                        />

                                                    )
                                                }

                                                {
                                                    !this.state.isInvite && (

                                                        <ButtonForm 
                                                            className="client-schedule-btn"
                                                            // tx={+this.state.transactionDetails.price === 0 || this.state.isDoctorService === true ? 'Finalizeaza' : 'Plateste'}
                                                            tx={btnText}
                                                            style={{
                                                                marginTop: 10,
                                                                width: '20%'
                                                            }}
                                                            // onClick={this.onConfirmAppointment}
                                                            onClick={this.onRedirectionarePlata}
                                                        />

                                                    )
                                                }

                                                {
                                                    this.state.isInvite && (

                                                        <ButtonForm 
                                                            className="client-schedule-btn"
                                                            // tx={+this.state.transactionDetails.price === 0 || this.state.isDoctorService === true ? 'Finalizeaza' : 'Plateste'}
                                                            tx='Refuza'
                                                            style={{
                                                                width: '180px',
                                                                background: '#DD6A57'
                                                            }}
                                                            // onClick={this.onConfirmAppointment}
                                                            onClick={this.onRefuseInvite}
                                                        />
                                                    )
                                                }


                                            </div>
                                        )
                                    }

                                    {
                                        this.state.eroareTx.length > 0 && (

                                            <ErrorMessage 
                                                eroareTx = {this.state.eroareTx}
                                            />
                                        )  
                                    }


                                </div>

                            </div> 

                        </div>

                        <div
                            style={{
                                    marginTop: '-60px',
                                    width: '100%',
                                }}
                        >

                            <img 
                                src={CanaryPlantImage}
                                alt="Canary and plant..."
                                style={{
                                    display: 'block',
                                    margin: 'auto',
                                    width: '400px'
                                }}
                            />

                        </div>

                        <p
                            style={{
                                
                                paddingTop: '5px',
                                paddingBottom: '10px',
                                fontSize: 14,
                                color: '#455A64'
                            }}
                        >
                            Programari prin  <a 
                                                href="https://planary.ro"
                                                style={{
                                                    color: '#455A64'
                                                }}
                                            >
                                                Planary.ro
                                            </a>
                                
                        </p>

                    </>
                )
            }

           

            </BrowserView>
            
            <MobileView>

                {
                    this.state.isLoading && (

                        <Loader 
                            fullPage = {true}
                        />
                    )
                }

                {
                    !this.state.isLoading && (

                        <>
                        
                             <Link to={'/trc/'+ this.props.match.params.tranToken+'/datele-mele'}>
                            <h3  
                                className="m-appoint-back"            
                                style={{

                                    textAlign: 'left',
                                    margin: 0,
                                }}
                            >   
                                <p 
                                    style={{
                                            display: 'inline-block', 
                                            position: 'relative', 
                                            top: 4, 
                                            left: 0, 
                                            margin: 0,
                                            fontSize: '40px'
                                        }}
                                >

                                    <RiArrowLeftSLine /> 

                                </p>

                                <p 
                                    style={{
                                        display: 'inline-block', 
                                        position: 'relative',
                                        top: -10, 
                                        left: 0,
                                        margin: 0,
                                        fontWeight: '500',
                                        fontSize: '16px'
                                    }}
                                >
                                Inapoi
                                </p>
                                
                            </h3>
                            </Link> 

                            {
                                this.state.eroareTx.length === 0 && (

                                    <div
                                        className="m-client-header"
                                        style={{

                                            marginTop: -20
                                        }}
                                    >

                                        {/* <div className="company-avatar-outer mobile">

                                            <div className="company-avatar-inner">
                                                <img src={this.state.accountImgSrc} alt="Avatar companie" />
                                            </div>

                                        </div> */}

                                        {
                                            this.state.transactionDetails['account_tk'] && (

                                                <AvatarAccount 
                                                    isNotLogged = {false}
                                                    accToken = {this.state.transactionDetails['account_tk']}
                                                    top = '20px'
                                                    border = '3px solid #fff'
                                                    width = '102px'
                                                    height = '102px'
                                                />
                                            )
                                        }

                                        <h4
                                            className="title"
                                        >
                                            {this.state.accountName}
                                        </h4>

                                    </div>
                                )
                            }

                            <div
                                style={{
                                    position: 'relative',
                                    minHeight: '940px',
                                    background: `url(${TownImage}) bottom no-repeat`
                                }}
                            >

                                <div className="m-client-services-card">


                                {
                                    this.state.eroareTx.length === 0 && 
                                    (
                                        <div>

                                            <MClientServiceInfo 
                                    
                                                name={this.state.transactionDetails.service_name}
                                                duration={this.state.transactionDetails.duration}
                                                location={this.state.locatieCurenta}
                                                description={
                                                <p
                                                    style={{
                                                        margin: 0, 
                                                        marginTop: -10,
                                                        color: '#A6A6A6',
                                                        fontSize: 14
                                                    }}
                                                >
                                                    Detaliile de conectare vor fi trimise prin e-mail la 
                                                    adresa furnizata
                                                    
                                                </p>
                                                }
                                                price={this.state.transactionDetails.price}
                                                date={this.state.transactionDetails['data_cal']}
                                                hour={this.state.transactionDetails['ora_cal']}
                                                member = {this.state.member}
                                                
                                            />
                            
                                            <SumarInfoClient 
                                                
                                                nume = {this.state.transactionDetails['clie_nume']}
                                                prenume = {this.state.transactionDetails['clie_prenume']}
                                                email = {this.state.transactionDetails['clie_email']}
                                                telefon = {this.state.transactionDetails['clie_tf']}
                                                detalii = {this.state.transactionDetails['clie_detalii']}
                                            />
                                            

                                        <div
                                            style={{
                                                marginTop: 30,
                                                marginBottom: 30,
                                                width: '100%',
                                                height: '1px',
                                                background: '#EBEBEB'
                                            }}
                                        >
                                        </div>

                                        {
                                            +this.state.transactionDetails.price > 0 && (

                                                <p 
                                                    style={{
                                                        margin:0,
                                                        marginTop: 30,
                                                        fontSize: '14px'
                                                    }}
                                                >
                                                    Plateste pentru a finaliza inregistrarea programarii tale
                                                </p>
                                            ) 
                                        }

                                        {
                                            +this.state.transactionDetails.price === 0 && (

                                                <p 
                                                    style={{
                                                        margin:0,
                                                        marginTop: 30,
                                                        fontSize: '14px'
                                                    }}
                                                >
                                                    Apasa butonul de mai jos pentru a finaliza programarea ta
                                                </p>
                                            )
                                        }

                                        {
                                            this.state.isInvite && (

                                                <ButtonForm 
                                                    className="client-schedule-btn"
                                                    // tx={+this.state.transactionDetails.price === 0 || this.state.isDoctorService === true? 'Finalizeaza' : 'Mergi la plata'}
                                                    tx = 'Refuza'
                                                    style={{
                                                        marginTop: 10,
                                                        width: '100%',
                                                        background: '#DD6A57'
                                                    }}
                                                    // onClick={this.onConfirmAppointment}
                                                    onClick={this.onRefuseInvite}
                                                />
                                            )
                                        }

                                        {
                                            this.state.isInvite && (

                                                <ButtonForm 
                                                    className="client-schedule-btn"
                                                    // tx={+this.state.transactionDetails.price === 0 || this.state.isDoctorService === true? 'Finalizeaza' : 'Mergi la plata'}
                                                    tx = {btnText}
                                                    style={{
                                                        marginTop: 20,
                                                        width: '100%'
                                                    }}
                                                    // onClick={this.onConfirmAppointment}
                                                    onClick={this.onInviteAccept}
                                        />
                                            )
                                        }
                                        

                                        {
                                            !this.state.isInvite && (

                                                <ButtonForm 
                                                    className="client-schedule-btn"
                                                    // tx={+this.state.transactionDetails.price === 0 || this.state.isDoctorService === true? 'Finalizeaza' : 'Mergi la plata'}
                                                    tx = {btnText}
                                                    style={{
                                                        marginTop: 20,
                                                        width: '100%'
                                                    }}
                                                    // onClick={this.onConfirmAppointment}
                                                    onClick={this.onRedirectionarePlata}
                                        />
                                            )
                                        }

                                        </div>
                                    )
                                }    

                                {
                                    this.state.eroareTx.length > 0 && (

                                        <ErrorMessage 
                                            eroareTx = {this.state.eroareTx}
                                        />
                                    )  
                                }
                        
                            </div>

                        </div>

                        {/* <div
                            style={{
                                width: '100%',
                                marginTop: 20
                            }}
                        >

                            <img 
                                src={TownImage}
                                alt="Town and clouds..."
                                style={{
                                    display: 'block',
                                    margin: 'auto',
                                }}
                            />

                        </div> */}

                        <p
                            style={{
                                
                                paddingTop: 10,
                                paddingBottom: 20,
                                fontSize: 14,
                                color: '#455A64'
                            }}
                        >
                            Programari prin  <a 
                                                href="#"
                                                style={{
                                                    color: '#455A64'
                                                }}
                                            >
                                                Planary.ro
                                            </a>
                                
                        </p>
                        
                        </>
                    )
                }

            </MobileView>

            </React.Fragment>
        )
    }
}