import React from 'react';

import ApiCalendar from 'react-google-calendar-api';
import LocalStorage from '../../providers/LocalStorage';
import Ws from '../../providers/Ws';

export default class GCalTest extends React.Component{

    constructor(props){

        super(props);
    }

    WS = new Ws();

    onCreateGCalEvent = async () => {       
        
        let _adr = `https://gcal.planary.ro/index.php?trcToken=2CQoeRWxvZUpuN1m1og70UGjbe6Svxd2`;

        let addr = encodeURI(_adr);
        let r = await fetch(addr, {
            headers : { 
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        

        if (r.status === 200) {
            let ss = await r.json(); // pentru tratarea de exceptii cu mesaje punctuale in fiecare pagina
            

            ss = JSON.parse(ss);
            // 

            if(ss.success){

                alert("Php a returnat succes!");
            }
            else{

                alert("Php a returnat fail!");
            }
        }
        
    }

    onTestMailTemplate = async () => {

        let postData = await this.WS.postData('notificari/emailindividual', {

            to: 'stoianlucian28@gmail.com',
            subject: 'Test subject',
            body: body
        });

        
    }
    

    getUserData = async () => {

        const ACC_TOKEN = new LocalStorage().GetAccountToken();
        const userToken = await this.WS.getUserToken();
        // let q = `select * from user_contacts where tip = 'google' and token_user = ( select token from users where account_id = '${ACC_TOKEN}' LIMIT 1 )`;
        let q = `select * from users`;
        let u = await this.WS.sqlCommand(`select`, q);

        
    }

    getUsers = async () => {

        // let q = `select * from users `;
        // let u = await this.WS.sqlCommand(`select`, q);

        let q = `update users set email = "valicristizaharia1@gmail.com" where wrk_token = "DLKlAy3LL7fWsVnndcjteC64HOdlaxZ7" and id = 7`;
        let u = await this.WS.sqlCommand(`update`, q);

        
    }


    render(){

        return(

            <div>

                <button 
                    type='button'
                    onClick={this.getUsers}
                >
                    Trigger Cal Event
                </button>

            </div>
        )
    }
}

const body = 
`
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Planary - Programare Noua</title>

    <style>

        html,body{

            margin: 0;
            padding: 0;
            box-sizing: border-box;
            height: 100%;
        }


        *, *:before, *:after {
            box-sizing: border-box;

        }
        @media screen and (max-width: 600px){

            .main{

                width: 100% !important;
                padding: 0 !important;
            }

            .card{
                padding: 30px !important;
            }

            .meeting-links-container{

                border-left: none !important;
            }
            .meeting-links{

                margin-left: 0 !important;
                
            }
        }

    </style>
</head>

<body
    style="
        background: #F6F6F6;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    "
>

<div 
    class="main"
    style="position: relative; 
        width: 620px; 
        margin: 20px auto;
    "
>

    <div
        
        style="
            width: 100%;
            display: flex;
        "
    >

        <img 
            src="https://i.ibb.co/FDndkP4/planary-logo-badge-1.png"

        />

        <p
            style="
                margin: 0;
                margin-top: 14px;
                margin-left: 8px;
                font-size: 18px;
                font-weight: 500;
                color: #000;
            "
        >
            Planary
        </p>
            

    </div>

    <div
        class="card"
        style="
            margin-top: 20px;
            padding: 50px;
            width: 100%;
            height: auto;
            background: #fff;
            border-radius: 10px;
            text-align: left;
        "
    >

        <h1
            style="
                margin: 0;
                padding:0;
                font-size: 36px;
                color: #E6B323;
                font-weight: 500;
            "
        >
            Confirmare Plata
        </h1>

        <br>

        <p
            style="
                margin: 0;
                font-size: 16px;
                font-weight: 400px;
            "
        >
            Salutare {{numeClient}}!
        </p>

        <br>

        <p
            style="
                margin: 0;
                font-size: 16px;
                font-weight: 400px;
            "
        >
            Ai inregistrat o programare platita in data <b>{{dataCurenta}}</b> cu <b> {{numeComerciant}} </b>! In continuare iti trimitem detaliile platii tale.
        </p>

        <br>

        <div
            style="
                width: 100%;
                height: 1px;
                background: #EBEBEB;
            "
        >
        </div>

        <br>

        <h3
            style="
                margin: 0;
                font-size: 18px;
                color: #455A64;
            "    
        >
            {{numeComerciant}}
        </h3>

        <p
            style="
                margin: 0;
                margin-top: 8px;
                font-size: 16px;
                font-weight: 400px;
            "
        >
            C.I.F : {{cuiComerciant}}
        </p>  

        <p
            style="
                margin: 0;
                font-size: 16px;
                font-weight: 400px;
            "
        >
            Reg. Com: {{jComerciant}}
        </p> 
        
        <p
            style="
                margin: 0;
                margin-top: 8px;
                font-size: 16px;
                font-weight: 400px;
            "
        >
            Adresa : {{adresaComerciant}}
        </p>  

        <p
            style="
                margin: 0;
                font-size: 16px;
                font-weight: 400px;
            "
        >
            Tel: {{telComerciant}}
        </p> 

        <p
            style="
                margin: 0;
                font-size: 16px;
                font-weight: 400px;
            "
        >
            eMail: {{emailComerciant}}
        </p> 

        <br>

        <div
            style="
                width: 100%;
                height: 1px;
                background: #EBEBEB;
            "
        >
        </div>

        <br>

          <div
            style="
                display: flex;
                justify-content: space-around;
            "
        >
            <div
                style="
                    width: 66.66%;
                "
            >
                <h3
                    style="
                        margin: 0;
                        font-size: 18px;
                        color: #455A64;
                    "    
                >
                    Serviciu
                </h3>

                <p
                    style="
                        margin: 0;
                        margin-top: 8px;
                        font-size: 16px;
                        font-weight: 400px;
                    "
                >
                    {{numeServiciu}}
                </p>

            </div>

            <div
                style="
                    width: 16.66%;
                "
            >
                
                <h3
                    style="
                        margin: 0;
                        font-size: 18px;
                        color: #455A64;
                    "    
                >
                    Pret
                </h3>

                <p
                    style="
                        margin: 0;
                        margin-top: 8px;
                        font-size: 16px;
                        font-weight: 400px;
                    "
                >
                    {{pret}} Lei
                </p>
                

            </div>

            <div
                style="
                    width: 16.66%;
                "
            >
                <h3
                    style="
                        margin: 0;
                        font-size: 18px;
                        color: #455A64;
                    "    
                >
                    Durata
                </h3>

                <p
                    style="
                        margin: 0;
                        margin-top: 8px;
                        font-size: 16px;
                        font-weight: 400px;
                    "
                >
                    {{durata}} Minute
                </p>

            </div>
            
        </div>

        <br>

        <h3
            style="
                margin: 0;
                font-size: 18px;
                color: #455A64;
            "    
        >
            Metoda de Plata
        </h3>

        <p
            style="
                margin: 0;
                margin-top: 8px;
                font-size: 16px;
                font-weight: 400px;
            "
        >
            Plata cu card bancar prin Netopia
        </p>

        <br>

        <div
            style="
                width: 100%;
                height: 1px;
                background: #EBEBEB;
            "
        >
        </div>

        <br>

        <p
            style="
                margin: 0;
                margin-top: 8px;
                font-size: 16px;
                font-weight: 400px;
            "
        >
            Acesta nu este un document fiscal ci reprezinta confirmarea platii tale catre furnizorul de servicii prin platforma Planary.
        </p>

        <br>

        <div
            style="
                width: 100%;
                height: 1px;
                background: #EBEBEB;
            "
        >
        </div>

        <br>

        <p
            style="
                margin: 0;
                font-size: 16px;
                font-weight: 400px;
            "
        >
            Pana data viitoare, 
            <br>
            Echipa Planary.
        </p>

    </div> 

    <br>
    
    <div style="margin: 0 auto; width: 90%">
    
        <p
            style="
                margin: 0;
                font-size: 14px;
                font-weight: 400;
                color: #000;
            "
        >

            Pentru a te asigura ca nu ratezi mesajele Planary, adauga adresa <b>info@planary.ro</b> in 
            lista ta de adrese mail, contacte sau lista de adrese sigure.
        </p>

        <br>

        <p
            style="
                margin: 0;
                font-size: 14px;
                font-weight: 400;
                color: rgb(69,90,100);
            "
        >

            Ai primit acest meaj email pentru ca ai creat un cont nou pe Planary.ro. Daca 
            doresti sa nu mai primesti mesaje de la Planary apasa <a href="#">aici</a>
        </p>

        <br>

        <p
            style="
                margin: 0;
                font-size: 14px;
                font-weight: 400;
                color: rgb(69,90,100);
            "
        >

            C 2021 Planary.ro. Toate drepturile rezervate. Logo-ul Planary este marca 
            inregistrata a Planary S.R.L pe teritoriul Europei si statelor membre.
        </p>
    
    </div>
    

</div>


</body>

</html>
`;