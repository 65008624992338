import React from 'react';

import { MdAlarmAdd, MdDeleteForever, MdKeyboardArrowRight } from 'react-icons/md';
import { RiCalendar2Fill } from 'react-icons/ri';
import ScheduleImage from '../../assets/Businesswoman-cuate 1.png';
import LaptopImg from '../../assets/laptop_and_mug.png';

import AuthInput from '../../commons/AuthInput';
import SelectInput from '../../commons/SelectInput';
import SelectOthers from '../../commons/SelectOthers';
import Declarations from '../../providers/Declarations';

import Ws from '../../providers/Ws';
import Utils from '../../providers/Utils';
import Masks from '../../providers/Masks';
import LocalStorage from '../../providers/LocalStorage';
import ButtonSaveDashboard from '../../commons/ButtonSaveDashboard';
import PopWarningDeleteSchedule from './PopWarningDeleteSchedule';
import ButtonForm from '../../commons/ButtonForm';
import SuccessSaveToast from '../../commons/SuccessSaveToast';
import ErrorToast from '../../commons/ErrorToast';
import ScheduleInterval from '../../commons/ScheduleInterval';
import ScheduleManagerFuncs from '../logic/ScheduleManagerFuncs';
import ScheduleConfig from '../../commons/schedule/ScheduleConfig';
import ScheduleName from '../../commons/schedule/ScheduleName';

export default class ServiceSchedule extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            schedules: [],
            allSchedules: [],

            selectedSchedule: [],
            canCreate: true,
            canSave: false,

            scheduleName: '',

            selectedScheduleTk: '',
            showWarningPopup: false,
            showToastPopup: false,
            showToastPopupError: false,
            warningServiceList: [],
            actionTx: '',
            
            triggerSave: false,
            isScheduleDeleted: false,

            nameStatus: false,
            luStStatus: false,
            luEndStatus: false,
            maStStatus: false,
            maEndStatus: false,
            miStStatus: false,
            miEndStatus: false,
            joStStatus: false,
            joEndStatus: false,
            viStStatus: false,
            viEndStatus: false,
            saStStatus: false,
            saEndStatus: false,
            duStStatus: false,
            duEndStatus: false,
        };
    }

    WS = new Ws();
    M = new Masks();
    UT = new Utils();
    SMF = new ScheduleManagerFuncs();

    componentsData = new Declarations().scheduleData;

    componentsStatus = {

        isNameOk: true,
        isScheudleOk: true
    }

    componentDidMount(){

        this.getSchedules();

        window.scrollTo(0,0);
    }

    componentDidUpdate(prevProps){

        if(this.props.scheduleTk !== prevProps.scheduleTk){

            this.getSchedules();
        }
    }

    getSchedules = async (usePropsToken = true) => {

        const ACC_TOKEN = new LocalStorage().GetAccountToken();

        // let selectSchedulesQuery = `select * from programs where account_tk = '${ACC_TOKEN}' and ifnull(sters,0)!= 1 `;
        let selectSchedulesQuery = `select * from programs where account_tk = '${ACC_TOKEN}' `;
        let selectServicesQuery = `select * from services where account_tk = '${ACC_TOKEN}' and ifnull(deleted,0)!=1 and ifnull(draft,0)!=1`;

        let _schedules = this.WS.sqlCommand(`select`, selectSchedulesQuery);
        let _services = this.WS.sqlCommand(`select`, selectServicesQuery);

        let [schedules, services] = await Promise.all( [_schedules, _services] );
        
        
        

        let availableSchedules = schedules.content.filter(sEl => (+sEl['sters'] || 0) === 0);

        

        if(schedules.success && services.success){

            this.setState({ 

                schedules: availableSchedules,
                services: services.content,
                allSchedules: schedules.content,

            },
                () => {

                    // this.setScheduleInterval(schedules.content);
                    if(this.props.scheduleTk && usePropsToken){

                        if(this.props.scheduleTk.length > 10){
            
                            this.onDisplayClickedSchedule(this.props.scheduleTk)
                        }
                    }
                    else{

                        
                    }
                }
            )
        }
        else{

            this.setState({

                showToastPopupError: true,
                actionTx: 'Eroare preluare date din server.'
            });
            throw new Error("Error fetching schedules or services: " + schedules.message + " " + services.message);
        }
    }

    changeSaveButtonStatus = () => {

        let status = null;

        if(!this.state.nameStatus && 
            !this.state.luStStatus && !this.state.luEndStatus && 
            !this.state.maStStatus && !this.state.maEndStatus && 
            !this.state.miStStatus && !this.state.miEndStatus &&
            !this.state.joStStatus && !this.state.joEndStatus &&
            !this.state.viStStatus && !this.state.viEndStatus &&
            !this.state.saStStatus && !this.state.saEndStatus &&
            !this.state.duStStatus && !this.state.duEndStatus 
        ){
            status = false;
        }
        else{

            status = true;
        }

        this.setState({

            canSave: status
        });
    }

    inputStatus = (status, statusName, inputName, data, isVerify = false) => {

        if(isVerify){

            this.componentsStatus[statusName] = data;
        }
        else{

            this.componentsData[inputName] = data;

            
    
            this.setState({
                
                [statusName]: status
            },
                () => {
    
                    this.changeSaveButtonStatus();
                }
            );
        }
    }

    onSaveToDb = async (isCreate) => {

        const componentsDataWithScheduleTk = {
            ...this.componentsData, 
            selectedScheduleTk: this.state.selectedScheduleTk,
            allSchedules: this.state.allSchedules
        };

        const savingRez = await this.SMF.onSaveScheduleToDb(isCreate, componentsDataWithScheduleTk);

        if(savingRez.err !== null){

            this.setState({

                showToastPopupError: true,
                actionTx: savingRez.err
            });
        }
        else{

            if(savingRez['data']['scheduleToken'].length < 1){

                this.setState({

                        canSave: false,
                        showToastPopup: true,
                        actionTx: 'a fost actualizat cu succes!',
                        triggerSave: false,

                    });

                    await this.getSchedules(false);
                    this.onDisplayClickedSchedule(this.state.selectedScheduleTk);
            }
            else{

                this.setState({

                    showToastPopup: true,
                    actionTx: 'a fost creat cu succes!',
                    triggerSave: false,

                });

                await this.getSchedules(false);
                this.onDisplayClickedSchedule(savingRez['data']['scheduleToken']);
            }
            
        }
    }

    onDeleteSchedule = async (scheduleTk) => {

        let servicesWithSelectedSchedule = this.state.services.filter(sEl => sEl['program_tk'] === scheduleTk);

        
        

        if(servicesWithSelectedSchedule.length > 0){

            this.setState({

                showWarningPopup: true,
                warningServiceList: servicesWithSelectedSchedule.map(sEl => {return sEl.name})
            })
        }

        if(servicesWithSelectedSchedule.length === 0){    

            let deleteScheduleQuery = `update programs set sters = 1 where token = '${scheduleTk}' `;

            let deleteSchedule = await this.WS.sqlCommand(`update`, deleteScheduleQuery);

            if(deleteSchedule.success){

                if(this.state.selectedScheduleTk === scheduleTk){

                    this.componentsData = new Declarations().scheduleData;
                    let defaultSchedule = [];
                    defaultSchedule.push(this.SMF.convertScheduleObjectKeysToDbFormatObjectKeys(new Declarations().scheduleData));

                    this.setState({

                        showToastPopup: true,
                        actionTx: 'a fost sters cu succes!',
                        selectedSchedule: defaultSchedule,

                        selectedScheduleTk: '',
                        scheduleName: '',
                        triggerSave: false,
                        canCreate: true,
                        canSave: false,
                    },
                        () => {

                            this.resetStatus();
                        }
                    
                    );
                }
                else{

                    this.setState({

                        showToastPopup: true,
                        actionTx: 'a fost sters cu succes!',
                    });
                }

                 
                await this.getSchedules();
            }
            else{

                this.setState({

                    showToastPopupError: true,
                    actionTx: 'Eroare stergere program.'
                });
                // alert("A aparut o eroare la stergerea programului!");
                throw new Error("Error deleting schedule: " + deleteSchedule.message);
            }
        }
    }   

    onDisplayClickedSchedule = (token) => {

        let schedule = this.state.schedules.filter(scEl => scEl.token === token);

        for(let key in schedule[0]){

            if( ( (schedule[0][key] || '').length < 2 ) || (schedule[0][key] === null ) ||  ( (schedule[0][key] || '') === '00:00:00') ){

                schedule[0][key] = null;
            }
        }

        this.componentsData = new Declarations().updateComponentsData(this.componentsData, schedule[0], 'schedule');

        

        this.setState({

            scheduleName: schedule[0]['name'],
            selectedSchedule: schedule,
            canCreate: false,
            canSave: false,
            selectedScheduleTk: token,
            
        },
            () => {

                this.resetStatus();
            }
        );
    }

    resetStatus = () => {

        this.setState({

            nameStatus: false,
            luStStatus: false,
            luEndStatus: false,
            maStStatus: false,
            maEndStatus: false,
            miStStatus: false,
            miEndStatus: false,
            joStStatus: false,
            joEndStatus: false,
            viStStatus: false,
            viEndStatus: false,
            saStStatus: false,
            saEndStatus: false,
            duStStatus: false,
            duEndStatus: false,
        },
            () => {

                this.changeSaveButtonStatus();
            }
        );
    }

    onTriggerCreateSchedule = async () => { //async

        // let defaultSchedule = [];
        if((this.state.selectedScheduleTk || '').length > 0){

            this.componentsData = new Declarations().scheduleData;
        }

        await this.onSaveToDb(true);  
  
    }

    onCheckAndSave = async (isCreate) => {

        const result = Object.values(this.componentsStatus).every(item => item === true)
        
        if(isCreate){
            
            // 
            this.onTriggerCreateSchedule(); //await
        }
        else if(result){
            
            await this.onSaveToDb(false);
        }
        else{

            this.setState({

                triggerSave: false
            });
        }

    }

    onTriggerSave = (status) => {

        this.setState({

            triggerSave: true
        },
            async () => {

                await this.onCheckAndSave(status);
            }
        );
    }

    showToastError = (errorTx) => {

        this.setState({

            showToastPopupError: true,
            actionTx: errorTx
        });
    }

    onClosePopup = (popupType) => {

        this.setState({

            [popupType]: false
        })
    }

    render(){

        return(

            <div>

                {
                    this.state.showToastPopupError && (

                        <ErrorToast 
                            action={this.state.actionTx}
                            onClosePopup = {this.onClosePopup}
                        />
                    )
                }

                {
                    this.state.showToastPopup && (

                        <SuccessSaveToast 
                            saveName = 'Programul'
                            action = {this.state.actionTx}
                            onClosePopup = {this.onClosePopup}
                        />
                    )
                }

                <div 
                    className="wrapper-dashboard" 
                    style={{
                        position: 'relative',
                        margin:'30px auto'
                    }}
                >

                    {
                        this.state.showWarningPopup && (

                            <PopWarningDeleteSchedule 
                            
                                servicesList = {this.state.warningServiceList}
                                onClosePopup = {this.onClosePopup}
                            />
                        )
                    }

                    <div className="service-details-card">

                        <div className="row">

                            <div className="col-l-4 col-m-4 col-s-12" style={{textAlign: 'left'}}>

                                <h3 
                                    className="title"
                                    style={{
                                        margin: 0
                                    }}
                                >
                                    
                                    <p 
                                        style={{
                                                display: 'inline-block', 
                                                position: 'relative', 
                                                top: 4, 
                                                left: 0, 
                                                margin: 0,
                                                color: 'rgb(255, 199, 39)',
                                                fontSize: '22px'
                                            }}
                                    >
                                            <RiCalendar2Fill /> 

                                    </p>

                                    <p 
                                        style={{
                                            display: 'inline-block', 
                                            position: 'relative', 
                                            left: 10,
                                            margin: 0,
                                            color: 'rgb(116, 131, 139)',
                                            fontWeight: '500'
                                        }}
                                    >
                                        Programe Personalizate
                                    </p>
                                    
                                </h3>

                                <br />

                                <div
                                    className="schedule-manager-list-container"
                                    style={{
                                        maxHeight: '650px',
                                        overflowY: 'auto'
                                    }}
                                >

                                {
                                    this.state.schedules && 
                                    this.state.schedules.map( (scEl, idx) => {

                                        const schedule = this.SMF.setScheduleInterval(scEl);

                                        return(
                                            <div key={`schedule-${idx}`}>

                                                <div
                                                    className="schedule-select-card"
                                                >
                                                    <div
                                                        style={{width: '91.66%'}}
                                                    >
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                fontSize: '16px',
                                                                fontWeight: '400',
                                                                color: '#263238'
                                                            }}
                                                        >
                                                            {scEl.name}
                                                        </p>
                                                        
                                                        {
                                                            schedule && schedule.length > 0 &&
                                                            schedule.map( (schEl, idx) => {

                                                                if(schEl.outcast === false && schEl.indisp === false){

                                                                    return(

                                                                        <p
                                                                            key={`schedule-day-${idx}`}
                                                                            style={{
                                                                                margin: 0,
                                                                                marginTop: '2px',
                                                                                fontSize: '16px',
                                                                                fontWeight: '400',
                                                                                color: '#263238'
                                                                            }}
                                                                        >
                                                                            {
                                                                                `${schEl.ziStart} - ${schEl.ziEnd} : ${schEl.hourStart} - ${schEl.hourEnd}` 
                                                                            }
                                                                        </p>
                                                                    )
                                                                }
                                                                else if(schEl.outcast === true && schEl.indisp === false){

                                                                    return(

                                                                        <p
                                                                            key={`schedule-day-${idx}`}
                                                                            style={{
                                                                                margin: 0,
                                                                                marginTop: '2px',
                                                                                fontSize: '16px',
                                                                                fontWeight: '400',
                                                                                color: '#263238'
                                                                            }}
                                                                        >
                                                                            {
                                                                                `${schEl.ziStart} : ${schEl.hourStart} - ${schEl.hourEnd}` 
                                                                            }
                                                                        </p>
                                                                    )
                                                                }
                                                                else if(schEl.outcast === false && schEl.indisp === true && ( ( schEl.ziStart !== 'S' ) || ( schEl.ziStart !== 'D' ) ) ){

                                                                    return(

                                                                        <p
                                                                            key={`schedule-day-${idx}`}
                                                                            style={{
                                                                                margin: 0,
                                                                                marginTop: '2px',
                                                                                fontSize: '16px',
                                                                                fontWeight: '400',
                                                                                color: '#263238'
                                                                            }}
                                                                        >
                                                                            {
                                                                                `${schEl.ziStart} : ` 
                                                                            }
                                                                            <span style={{color: '#DD6A57'}}>Indisponibil</span>
                                                                        </p>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                        
                                                        <h6
                                                            className="delete-service"
                                                            style={{
                                                                textAlign: 'center'
                                                            }}
                                                            onClick = {

                                                                () => {

                                                                    this.onDeleteSchedule(scEl.token);
                                                                }
                                                            }
                                                        >
                                                            <p 
                                                                style={
                                                                    {
                                                                        display: 'inline-block', 
                                                                        position: 'relative', 
                                                                        top: 6, 
                                                                        left: 0, 
                                                                        margin: 0,
                                                                        fontSize: '24px',
                                                                    }
                                                                }
                                                            >
                                                                <MdDeleteForever />
                                                            </p>    

                                                            <p 
                                                                style={
                                                                    {
                                                                        display: 'inline-block', 
                                                                        position: 'relative', 
                                                                        left: 6,
                                                                        margin: 0,
                                                                        fontSize: '14px',
                                                                        fontWeight: '500',
                                                                    }
                                                                }
                                                            >
                                                                Sterge 
                                                            </p>
                                                            
                                                        </h6>

                                                    </div>

                                                    <div
                                                        style={{width: '8.33%', textAlign: 'right'}}
                                                    >
                                                        <MdKeyboardArrowRight   
                                                            style={{
                                                                fontSize: '40px',
                                                                color: '#44A5FF',
                                                                cursor: 'pointer'
                                                            }}
                                                            onClick={

                                                                () => {

                                                                    this.onDisplayClickedSchedule(scEl.token)
                                                                }
                                                            }
                                                        />
                                                    </div>

                                                </div>

                                                <br />

                                            </div>
                                        )
                                    })
                                }
                                </div>
                              

                                {/* <div 
                                    className="create-schedule-button"
                                    onClick={this.onCheckAndSave}
                                >
                                    <MdAlarmAdd />
                                </div> */}

                                <ButtonForm 
                                    type='button'
                                    tx='Adauga Program Nou'
                                    style={{
                                        width: 'auto',
                                        background: '#F8BC05',
                                        fontSize: '16px',
                                        padding: '0px 15px 0px 15px'
                                    }}
                                    onClick={
                                        () => {

                                            this.onCheckAndSave(true)
                                        }
                                    }
                                />
                                
                            </div>

                            <div className="col-l-4 col-m-4 col-s-12" style={{textAlign: 'left'}}>

                                <h3 
                                    className="title"
                                    style={{
                                        margin: 0
                                    }}
                                >
                                    
                                    <p 
                                        style={{
                                                display: 'inline-block', 
                                                position: 'relative', 
                                                top: 4, 
                                                left: 0, 
                                                margin: 0,
                                                color: 'rgb(255, 199, 39)',
                                                fontSize: '22px'
                                            }}
                                    >
                                            <RiCalendar2Fill /> 

                                    </p>

                                    <p 
                                        style={{
                                            display: 'inline-block', 
                                            position: 'relative', 
                                            left: 10,
                                            margin: 0,
                                            color: 'rgb(116, 131, 139)',
                                            fontWeight: '500'
                                        }}
                                    >
                                        Personalizeaza Program
                                    </p>
                                    
                                </h3>

                                <br />
                                
                                <ScheduleName 
                                    triggerSave = {this.state.triggerSave}
                                    inputStatus = {this.inputStatus}
                                    scheduleName = {this.state.scheduleName}
                                    schedules = {this.state.schedules}
                                    selectedScheduleTk = {this.state.selectedScheduleTk}
                                />

                                <ScheduleConfig 
                                    selectedSchedule = {this.state.selectedSchedule}
                                    triggerSave = {this.state.triggerSave}
                                    showSave = {!this.state.canCreate && this.state.canSave ? true : false}
                                    inputStatus = {this.inputStatus}
                                    resetStatus = {this.resetStatus}
                                    showToastError = {this.showToastError}
                                    onCheckAndSave = {this.onTriggerSave}
                                />
                              

                            </div>

                            <div className="col-l-4 col-m-4 col-s-12" style={{textAlign: 'left'}}>

                                <br />
                                <br />

                                <p
                                    style={{
                                        margin: 0,
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        color: '#455A64'
                                    }}
                                >
                                    Programul personalizat este primul nivel de disponibilitate pe care il ofera Planary. 
                                    <br />
                                    <br />
                                    Personalizeaza si mai mult disponibilitatea ta in setarile de Servicii si poti adauga chiar perioade de Vacanta.
                                </p>

                                <br />

                                <img 
                                    src={ScheduleImage}
                                    alt="Schedule Image"
                                    style={{
                                        display: 'block',
                                        margin: '0 auto'
                                    }}
                                />

                                {/* <br />

                                <p
                                    style={{
                                        margin: 0,
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        color: '#455A64',
                                    }}
                                >
                                    Something Something Dark Side!
                                </p> */}
                                
                            </div>

                        </div>

                    </div>

                </div>
                
                <img
                    src={LaptopImg}
                    alt="Laptop Image"
                    style={{
                        display: 'block',
                        margin: '20px auto',
                        width: '280px'
                    }}
                />

                <br />

            </div>
        )
    }
}