import React from 'react';
import HourInterval from './HourInterval';

export default class DayCalendarBreak extends React.Component{

    constructor(props){

        super(props);
    }

    render(){

        return(

            <ul 
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    // alignItems: 'center',
                    flexDirection: 'column',
                    width: this.props.width || '100%',
                    position: 'absolute',
                    height: this.props.height, // * duration from current appointment
                    // marginTop: 0,
                    top: this.props.top,
                    left: this.props.left || '0',
                    listStyle: 'none',
                    margin: 0,
                    padding: 0,
                    paddingLeft: '10px',
                    // background: ptdEl.backgroundColor.replace('0.1', '0.5'),
                    background: this.props.breakElem.backgroundColor,
                    borderLeft: '3px solid ' + this.props.breakElem.color,
                    overflow: 'hidden',
                    color: '#000',
                    cursor: 'default',
                    textAlign: 'left',
                    zIndex: this.props.zIndex
                }}

                // onClick={
                //     () => {
                //         this.onShowClientClick(this.state.weeklyAppointsList, ptdEl.token, ptdEl['data_cal'])
                //     }
                // }
            >
                <p
                    style={{
                        margin: 0,
                        fontSize: '14px',
                        color: this.props.breakElem.color,
                        filter: 'brightness(70%)'
                    }}
                >
                    {/* {ptdEl['clie_nume'] + ' ' + ptdEl['clie_prenume']} */}
                    {this.props.breakElem['clie_nume']}
                </p>

                <p
                    style={{
                        position: 'relative',
                        margin: 0,
                        fontSize: '10px',
                        // color: ptdEl.color
                    }}
                >
                    {this.props.breakElem['service_name']}
                </p>  

                <HourInterval 
                    ora = {this.props.breakElem['ora_cal']}
                    durata = {this.props.breakElem['duration']}
                    justifyContent = 'none'
                    marginTop = '4px'
                    iconWidth = '22px'
                    iconSize = '16px'
                    txSize = '12px'
                />

                {
                    this.props.member.length > 0 ? (

                        <ul
                            className="row"
                            style={{
                                position: 'absolute',
                                right: 20,
                                display: 'flex',
                                marginTop: 0,
                                listStyle: 'none',
                                margin: 0,
                                padding: 0,
                            }}
                            // style={{
                            //     display: 'flex',
                            //     width: '100%',
                            //     marginTop: 8   
                            // }}
                        >
                            <li
                                // className="col-l-1 col-m-1 col-s-1"
                            >
        
                                <li
                                    style={{
                                        position: 'relative',
                                        left: -5,
                                        width: '30.5px',
                                        height: '30.5px',
                                        borderRadius: '100%',
                                        background: 'lightblue',
                                        overflow: 'hidden'
                                    }}
                                >
                                    <img 
                                        // src={CompanyAvatar} 
                                        alt="Avatar client" 
                                        style={{
                                            width: '100%',
                                            height: '100%'
                                        }}
                                    />
        
                                </li>
        
                            </li>
        
                            <li
                                // style={{
                                //     width: '90%'
                                // }}
                                // className="col-l-11 col-m-11 col-s-11"
                            >
        
                                <p
                                    style={{
                                        margin: 0,
                                        fontSize: '16px',
                                        color: '#263238',
                                        textAlign: 'left'
                                    }}
                                >
                                    {this.props.member[0].nume}
                                </p>
        
                                <p
                                    style={{
                                        margin: 0,
                                        fontSize: '9px',
                                        color: '#777777',
                                        textAlign: 'left'
                                    }}
                                >
                                    {this.props.member[0].functie}
                                </p>
        
                            </li>
        
                        </ul>
                    ) : null
               }
                
            </ul>
        )
    }
}