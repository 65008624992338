import React from 'react';

import Masks from '../../providers/Masks';
import Utils from '../../providers/Utils';
import Welcome from '../login/Welcome';
import Declarations from '../../providers/Declarations';
import LocalStorage from '../../providers/LocalStorage';
import Ws from '../../providers/Ws';

import { ImPlus } from 'react-icons/im';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { MdAlarm } from 'react-icons/md';
import { IoVideocam } from 'react-icons/io5';
import { FaSkype } from 'react-icons/fa';
import { ImLocation2 } from 'react-icons/im';
import { ReactComponent  as GMeetIcon} from '../../assets/Google_Meet-Logo.wine.svg';
import { ReactComponent as ZoomIcon } from '../../assets/zoom_icon.svg';
import { ReactComponent as LocIcon } from '../../assets/pin_drop.svg';
import ErrorToast from '../../commons/ErrorToast';
import DayCalendarElem from '../../commons/DayCalendarElem';
import DayCalendarBreak from '../../commons/DayCalendarBreak';
import Loader from '../../commons/Loader';

export default class CalendarSaptamanal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataStart: null,
            dataEnd: null,
            listaZileInterval: [],
            dateToShowOnInterval: [],
            intervalProgramari: [],
            mainStyle: this.computeMainStyle(),
            weeklyAppointsList: [],
            highestDurationAppoint: {},
            currentWeek: 0,
            currentYear: '',
            luna: '',
            showToastPopup: false,
            showToastPopupError: false,
            actionTx: '',
            isLoading: true 
        }
    }
    

    M = new Masks()
    U = new Utils()
    WS = new Ws()
 
    localData = {
  
        dateToShowOnInterval: [],
        minutePixelFactor: 3, //it was 2 before
        tdImplicitMinutes: 30, 
        locatiiIntalnire: new Declarations().listaLocatiiIntalnire
    }

    componentDidMount() {

        // console.clear();
        // 
        this.onLoad();
    }


    computeMainStyle = () => {
        let reportingWidth = this.props.width || 80

        // let width = `${reportingWidth}px`
        let tdWidth = `${parseInt(reportingWidth / 7)}vw`
        // let tdHeight = `${parseInt(reportingWidth / 10)}px`
        // let minuteFactor = parseInt(reportingWidth / 30)


        // return { width, tdWidth, tdHeight, minuteFactor }

        let tdHeight = `${+this.localData.minutePixelFactor * +this.localData.tdImplicitMinutes}px`
        // let tdWidth = '100px'


        return { tdHeight, tdWidth }
    }

    // getCurrentWeekDays = () => {

    //     let _ziCurenta = new Date();
    //     let ziCurenta = _ziCurenta.setHours(2, 0, 0, 0);
    //     // 
    //     let day = new Date(ziCurenta).getDay()

    //     let ultimaZiDinSaptamana = null;
    //     let primaZiDinSaptamana = null;

    //     if (day > 0) {
    //         primaZiDinSaptamana = this.M.addDaysToDate(ziCurenta, -(day - 1))
    //         ultimaZiDinSaptamana = this.M.addDaysToDate(primaZiDinSaptamana, 6)

    //     }
    //     else {
    //         primaZiDinSaptamana = this.M.addDaysToDate(ziCurenta, -6)
    //         ultimaZiDinSaptamana = ziCurenta
    //     }

    //     return ({
    //         dataStart: this.M.specificDateToDateField(primaZiDinSaptamana),
    //         dataEnd: this.M.specificDateToDateField(ultimaZiDinSaptamana),
    //         listaZileInterval: this.U.generateListaZile(new Date(primaZiDinSaptamana), new Date(ultimaZiDinSaptamana))
    //     })

    // }

    getCurrentWeekDays = () => {

        let _ziCurenta = new Date();
        let ziCurenta = _ziCurenta.setHours(2, 0, 0, 0);
        // 
        let day = new Date(ziCurenta).getDay()

        let ultimaZiDinSaptamana = null;
        let primaZiDinSaptamana = null;

        if (day > 0) {
            primaZiDinSaptamana = this.M.addDaysToDate(ziCurenta, 0)
            ultimaZiDinSaptamana = this.M.addDaysToDate(primaZiDinSaptamana, 0)

        }
        else {
            primaZiDinSaptamana = this.M.addDaysToDate(ziCurenta, 0)
            ultimaZiDinSaptamana = ziCurenta
        }

        return ({
            dataStart: this.M.specificDateToDateField(primaZiDinSaptamana),
            dataEnd: this.M.specificDateToDateField(ultimaZiDinSaptamana),
            listaZileInterval: this.U.generateListaZile(new Date(primaZiDinSaptamana), new Date(ultimaZiDinSaptamana))
        })
    }

    onChangeInterval = (nrZile) => {

        let _dataStart = this.M.specificDateToDateField(this.M.addDaysToDate(new Date(this.state.dataStart), nrZile))
        let _dataEnd = this.M.specificDateToDateField(this.M.addDaysToDate(new Date(this.state.dataEnd), nrZile))

        // this.setState({
        //     dataStart: _dataStart,
        //     dataEnd: _dataEnd,
        //     listaZileInterval: this.U.generateListaZile(new Date(_dataStart), new Date(_dataEnd))
        // }, () => {

        //     // this.composeDateForCalendar()
        //     this.getSetProgramariSiInterval(_dataStart, _dataEnd)
        // })

        this.getSetProgramariSiInterval(_dataStart, _dataEnd, this.U.generateListaZile(new Date(_dataStart), new Date(_dataEnd)))
    }

    onLoad = () => {

        let zileInSaptamana = this.getCurrentWeekDays()
        let dataWeekStart = this.M.specificDateToDateField(zileInSaptamana['dataStart'])
        // let dataWeekEnd = this.M.specificDateToDateField(zileInSaptamana['dataEnd'])
        let dataWeekEnd = this.M.specificDateToDateField(zileInSaptamana['dataStart'])

        this.getSetProgramariSiInterval(dataWeekStart, dataWeekEnd, zileInSaptamana['listaZileInterval'])
        
    }

    getSetProgramariSiInterval = async (dataWeekStart, dataWeekEnd, listaZileIntervalOrar) => {

        // let zileInSaptamana = this.getCurrentWeekDays()
        // let dataWeekStart = null
        // let dataWeekEnd = null

        // if(this.state.dataStart === null || this.state.dataEnd === null){

        //     dataWeekStart = this.M.specificDateToDateField(zileInSaptamana['dataStart'])
        //     dataWeekEnd = this.M.specificDateToDateField(zileInSaptamana['dataEnd'])
        // }
        // else{

        //     dataWeekStart = this.state.dataStart
        //     dataWeekEnd = this.state.dataEnd
        // }

        // 

        // 
        // 

        const TOKEN_CONT = new LocalStorage().GetAccountToken();
        const tokenUser = new LocalStorage().GetUserData().userData[0]['token'];

        let selectWeeklyAppoints = `select * from trc where account_tk = '${TOKEN_CONT}' and data_cal between '${dataWeekStart}' 
                                                                                          and '${dataWeekEnd}' and ifnull(draft,0)!=1  and ifnull(anulat,0)!=1  `

        let selectServices = `select * from services where account_tk = '${TOKEN_CONT}'  `

        let selectUnconfirmedAppoints =  `select * from trc where account_tk = '${TOKEN_CONT}' and data_cal between '${dataWeekStart}'and '${dataWeekEnd}' and tip = 'programare' 
                                                                                                and src = 'ca' and ifnull(draft,0)=1 and ifnull(anulat,0)!=1 `

        // 
        
        let _weeklyAppoints = this.WS.sqlCommand(`select`, selectWeeklyAppoints)
        let _services = this.WS.sqlCommand(`select`, selectServices)
        let _unconfirmedAppoints = this.WS.sqlCommand(`select`, selectUnconfirmedAppoints)
        const qUsers = `select * from users where account_id = '${TOKEN_CONT}' `;
        let _users = await this.WS.sqlCommand(`select`, qUsers);

        let [weeklyAppoints, services, unconfirmedAppoints, users] = await Promise.all([_weeklyAppoints, _services, _unconfirmedAppoints, _users]);

        let serviceColorList = this.U.getServicesColors(services.content);

        // 
        // 

        // let currentDayOfWeek = Math.ceil((this.M.sqlDateToJSDate(dataWeekStart).getDay() + this.M.sqlDateToJSDate(dataWeekEnd).getDate()) / 7)

        let elms = []
        
        if(weeklyAppoints.success && services.success && unconfirmedAppoints.success){

            const unconfirmedAppointsCalendar = unconfirmedAppoints.content.filter(uaEl => ( uaEl['clie_nume'] || '').length > 0);
            // const unconfirmedAppointsCalendar = unconfirmedAppoints.content.filter(uaEl => {

            //     if( (( uaEl['clie_nume'] || '').length > 0) && (new Date().getTime() <= this.M.sqlDateToJSDate(uaEl['data_cal']).getTime()) ){
    
            //         return uaEl;
            //     }
            // });
            const appointsFromClients = weeklyAppoints.content;

            let mergedAppoints = appointsFromClients.concat(unconfirmedAppointsCalendar);

            mergedAppoints.sort( (prev, next) => {

                if(this.M.sqlDateToJSDate(prev['data_cal']).getDate() < this.M.sqlDateToJSDate(next['data_cal']).getDate()){

                    return -1;
                }
                else if(this.M.sqlDateToJSDate(prev['data_cal']).getDate() > this.M.sqlDateToJSDate(next['data_cal']).getDate()){

                    return 1;
                }
                else if(this.U.convertToTime(prev['ora_cal']).getTime() < this.U.convertToTime(next['ora_cal']).getTime()){

                    return -1;
                }
                else if(this.U.convertToTime(prev['ora_cal']).getTime() < this.U.convertToTime(next['ora_cal']).getTime()){

                    return 1;
                }
            })

            if(tokenUser !== users.content[0]['token']){

                mergedAppoints = mergedAppoints.filter( appoint => appoint.user_asignat_token === tokenUser);
            }


            elms = mergedAppoints.map(wEl => {

                return (
                    {
                        ...wEl,
                        backgroundColor: this.U.filterColors(true, serviceColorList, wEl.service_tk, wEl.tip, wEl.draft),
                        color: this.U.filterColors(false, serviceColorList, wEl.service_tk, wEl.tip, wEl.draft),
                    }
                )
            });

            let highestDuratiponAppoint = null;

            if(mergedAppoints.length > 0){

                highestDuratiponAppoint = mergedAppoints.sort( (prev, next) => {

                    if(+prev['duration'] > +next['duration']){
    
                        return 1;
                    }
                    else{
    
                        return -1;
                    }
                })[mergedAppoints.length - 1];
            }

            // 

            this.props.onOpenCreateAppointFromDayIntervalView(this.M.sqlDateToJSDate(dataWeekStart));

            this.setState({
                dataStart: dataWeekStart,
                dataEnd: dataWeekEnd,
                listaZileInterval: listaZileIntervalOrar,
                weeklyAppointsList: elms,
                // currentWeek: currentDayOfWeek,
                highestDurationAppoint: highestDuratiponAppoint,
                currentYear: dataWeekStart.substr(0,4),
                isLoading: false
            }, () => {
    
                    this.composeDateForCalendar();
                    this.setReadableMonth(); 
                    this.setIntervalOrar()
                    // this.props.onCheckIfDayCalendarIsLoaded();
                }
            )    
        }
        else{

            this.setState({

                showToastPopupError: true,
                actionTx: 'Eroare preluare date calendar din server.'
            });

            throw new Error("Server response error: " + weeklyAppoints.message + "\n" + services.message + "\n" + unconfirmedAppoints.message)
        }
        
    }

    setReadableMonth = () => {

        const luna = this.U.setHumanReadableMonth(this.M.sqlDateToJSDate(this.state.dataEnd).getMonth())

        this.setState({

            luna: luna
        })
    }

    setIntervalOrar = () => {

        const ore = new Declarations().listaIntervalOrar

        this.setState({

            intervalProgramari: ore
        })
    }

    composeDateForCalendar =  () => { //async

        // let rez = [

        //     {
        //         ziua: new Date(),
        //         infoZi: [
        //             {
        //                 descriere: 'xxx',
        //             }
        //         ]
        //     },

        //     {
        //         ziua: new Date(),
        //         infoZi: [
        //             {
        //                 descriere: 'yyy',
        //             }
        //         ]
        //     }
        // ];

        // 

        let rezZile = [];
        let rezAppoints = [];

        for (let i = 0; i < this.state.listaZileInterval.length; i++) {

            let _curentDay = this.state.listaZileInterval[i]

            let z = {
                ziua: _curentDay, 
            }

            rezZile.push(z);
        }

        this.localData.dateToShowOnInterval = [...rezZile];

        this.setState({
             
            dateToShowOnInterval: rezZile,
        })

    }

    convertToTime = (t) => {

        t = t.split(":");
        const time = new Date();
        time.setHours(t[0], t[1], 0);

        return time;
    }

    addMinutes = (date, minutes) => {

        return  new Date(date.getTime() + minutes*60000);
    }

    onShowMoreClick = (dataCal) => {

        this.props.onShowAppointsPopup(true, dataCal)
    }

    onShowClientClick = (list, token, dataCal) => {

        this.props.onShowClientWeekly(list, token, dataCal)
        
    }

    onCloseToastPopup = (type) => {

        this.setState({

            [type]: false
        });
    }

    render() {
        return (
            <div
                style={{
                    position: 'relative',
                }}
            >

                {
                    this.state.showToastPopupError && (

                        <ErrorToast 
                            action={this.state.actionTx}
                            onClosePopup = {this.onCloseToastPopup}
                        />
                    )
                }

                <div
                  style={{
                    position: 'absolute',
                        top: this.state.isLoading ? -50 : -90,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        paddingLeft: 20
                    }}
                    >

                        <IoIosArrowBack 
                            style={{
                                display: 'inline-block',
                                position: 'relative',
                                margin: 0,
                                top: 5,
                                fontSize: '22px',
                                color: '#A6A6A6',
                                cursor: 'pointer'
                            }}

                            onClick={

                                () => {

                                    this.setState({
                                        isLoading: true
                                    },
                                    
                                        () => {

                                            setTimeout( () => this.onChangeInterval(-1), 500)
                                        }
                                    );
                                }
                            }
                        
                        />

                        {
                            !this.state.isLoading && (

                                <h3
                                    style={{
                                        display: 'inline-block',
                                        position: 'relative',
                                        margin: 0,
                                        fontSize: '18px',
                                        padding: '0px 10px 0 10px'
                                    }}
                                
                                >
                                    {this.state.dateToShowOnInterval.length > 0 && this.M.dataFriendlyFromSql(this.M.specificDateToDateField(this.state.dateToShowOnInterval[0].ziua)) + ' '} 
                                    {/* {this.state.dataStart && this.M.sqlDateToJSDate(this.state.dataStart).getDate() + ' '}  */}
                                    {this.state.currentYear}

                                </h3>

                            )
                        }

                        {
                            this.state.isLoading && (

                                <h3
                                    style={{
                                        display: 'inline-block',
                                        position: 'relative',
                                        margin: 0,
                                        fontSize: '18px',
                                        padding: '0px 10px 0 10px'
                                    }}
                                
                                >
                                    ...

                                </h3>

                            )
                        }

                        <IoIosArrowForward 
                            style={{
                                display: 'inline-block',
                                position: 'relative',
                                margin: 0,
                                top: 5,
                                fontSize: '22px',
                                color: '#A6A6A6',
                                cursor: 'pointer'
                            }}

                            onClick={

                                () => {

                                    this.setState({
                                        isLoading: true
                                    },
                                    
                                        () => {

                                            setTimeout(() => this.onChangeInterval(1), 500)
                                        }
                                    );
                                }
                            }
                        
                        />

                    </div>

                    
                    {
                        this.state.isLoading && (

                            <Loader />
                        )
                    }

                    {
                        !this.state.isLoading && (

                            <div
                            style={{
                                marginTop: 40,
                                
                            }}
                        >
      
    
                            <div
                                style={{
                                    position: 'relative',
                                    top: -15,
                                    paddingLeft: 30,
                                    paddingRight: 30,
                                }}
                            >
    
                                <div
                                    style={{
                                        width: '100%',
                                        height: '1px',
                                        // paddingLeft: 30,
                                        // margin: '0 auto',
                                        background: '#000'
                                    }}
                                >
    
                                </div>
    
                            </div>
    
                            <br />
                            <br />
    
                            <table 
                                style={{
                                    width: '100%',
                                    borderSpacing: '0px',
                                    paddingRight: 30
                                }}
                            >
    
                                <thead>
    
                                    <tr>    
                                        <th></th>
                                        {/* {
                                            this.state.dateToShowOnInterval.map((diEl, idx) => {
    
                                                return(
                                                    <th 
                                                        key={'week-days-'+idx}
                                                        style={{ color: '#C7C7C7', fontSize: 16, fontWeight: 400 }}
                                                    >
                                                        {this.M.dataFriendlyFromSql(this.M.specificDateToDateField(diEl.ziua))}
                                                    </th>
                                                )
                                            })
                                        } */}
                                        
                                    </tr>
    
                                </thead>
    
                                
    
                                <tbody>
                                    {
    
                                        this.state.intervalProgramari.map((ipEl, idx) => {
                                            return(
                                                <tr key={'intervalProgramari-'+idx}>
    
                                                    <td
                                                        key={'intervalProgramari-'+idx} 
                                                        style={{
                                                            position: 'relative',
                                                            verticalAlign: 'top',
                                                            // width: this.state.mainStyle.tdWidth,
                                                            width: '7%',
                                                            height: this.state.mainStyle.tdHeight,
                                                            background: '#fff',
                                                            // border: '1px solid #DBDBDB' 
                                                        }}
                                                    >
                                                        
                                                        <p
                                                            style={{
                                                                position: 'relative',
                                                                top: -12,
                                                                margin: 0
                                                            }}
                                                        >
                                                            {ipEl}
                                                        </p>
                                                    </td>
    
                                                    {
                                                        this.state.dateToShowOnInterval && 
                                                        
                                                        this.state.dateToShowOnInterval.map((dEl, idx) => {


                                                            let setZIndex = 44000
                                                            let programariTd = this.state.weeklyAppointsList.filter((ptEl) => {
    
                                                                if((this.M.sqlDateToJSDate(ptEl['data_cal']).getTime() === this.M.sqlDateToJSDate(this.M.specificDateToDateField(dEl.ziua)).getTime()) &&
                                                                    (this.convertToTime(ptEl['ora_cal']).getTime() >= this.convertToTime(ipEl).getTime()) &&
                                                                    (this.convertToTime(ptEl['ora_cal']).getTime() < this.addMinutes(this.convertToTime(ipEl), 30).getTime())
    
                                                                ){
    
                                                                    return ptEl
                                                                }
    
                                                            });
                                                            
                                                            
                                                            let isOverlaped = false;
                                                            let elementsNum = 0;
    
                                                            if(programariTd.length > 1 ){
    
                                                                isOverlaped = true
                                                                elementsNum = programariTd.length
                                                            }
    
                                                            // 
                                                            let overlappedIntervalAppoint = [];
                                                            
                                                            if(this.state.highestDurationAppoint !== null){
    
                                                                overlappedIntervalAppoint = this.state.weeklyAppointsList.filter(wEl => {
    
                                                                    if( ( this.M.sqlDateToJSDate(wEl['data_cal']).getTime() ===  this.M.sqlDateToJSDate(this.M.specificDateToDateField(dEl.ziua)).getTime() ) && 
                                                                         (this.convertToTime(wEl['ora_cal']).getTime() >= this.convertToTime(this.state.highestDurationAppoint['ora_cal']) ) && 
                                                                          (this.convertToTime(wEl['ora_cal']).getTime() < this.addMinutes(this.convertToTime(this.state.highestDurationAppoint['ora_cal']), +this.state.highestDurationAppoint['duration']).getTime() ) &&
                                                                          ( wEl['token'] !== this.state.highestDurationAppoint['token'] )
                                                                        
                                                                    ){
        
                                                                        return wEl;
                                                                    }
                                                                });
                                                            }
    
                                                            return(
    
                                                                <td
                                                                    key={'intervalProgramari-'+idx} 
                                                                    style={{
                                                                        verticalAlign: 'top',
                                                                        // position: 'relative',
                                                                        // width: this.state.mainStyle.tdWidth,
                                                                        width: '93%',
                                                                        height: this.state.mainStyle.tdHeight,
                                                                        background: '#fff',
                                                                        
                                                                        // border: '1px solid #DBDBDB'
                                                                        
                                                                        borderTop: '1px solid #EBEBEB'
                                                                    }}
                                                                >
                                                                    <ul style={{position: 'relative', listStyle: 'none', margin: 0, padding: 0}}>                                                            
                                                                       
                                                                    {   
                                                                
                                                                        programariTd.map((ptdEl, idx) => {
                                                                           
                                                                            let calcHeight = ptdEl.duration * this.localData.minutePixelFactor;
                                                                            const teamMember = 
                                                                            new LocalStorage().GetUserData().userData[0]['token'] === this.props.users[0]?.token ?
                                                                            this.props.users.filter( user => user.token === ptdEl.user_asignat_token) : [];
    
                                                                            let pleft = 0;
                                                                            // let prevEL = programariTd[0]
    
                                                                            if(idx > 0){
    
                                                                                pleft = `${(100/elementsNum)*idx}%`
                                                                                // prevEL = programariTd[idx-1] 
                                                                                // 
                                                                            }
                                                                   
                                                                            setZIndex++
    
                                                                            let diff = this.convertToTime(ptdEl['ora_cal']) - this.convertToTime(ipEl)
    
                                                                            diff = diff / 1000 / 60
    
                                                                            if(isOverlaped){
    
                                                                                if((ptdEl.tip || '') === 'pauza'){
                                                                                    return(
                                                                                        
                                                                                        <DayCalendarBreak 
                                                                                            key={'programare-calendar-saptamanala-'+idx}
                                                                                            width = {`${100/elementsNum}%`}
                                                                                            height = {`${calcHeight}px`}
                                                                                            top = {diff}
                                                                                            left = {pleft}
                                                                                            zIndex = {setZIndex}
                                                                                            breakElem = {ptdEl}
                                                                                            member = {teamMember}
                                                                                        />
                                                                                    )
                                                                                }
                                                                                else{
    
                                                                                    return(
    
                                                                                        <DayCalendarElem 
                                                                                            key={'programare-calendar-saptamanala-'+idx}
                                                                                            width = {`${100/elementsNum}%`}
                                                                                            top = {diff}
                                                                                            left = {pleft}
                                                                                            zIndex = {setZIndex}
                                                                                            height = {`${calcHeight}px`}
                                                                                            weeklyAppointsList = {this.state.weeklyAppointsList}
                                                                                            elem = {ptdEl}
                                                                                            onShowClientClick = {this.onShowClientClick}
                                                                                            member = {teamMember}
                                                                                        />
                                                                            
                                                                                    )
                                                                                }
    
                                                                                        
                                                                                                                                                                                                                                     
                                                                            }
                                                                            else if (
    
                                                                                overlappedIntervalAppoint.length > 0
                                                                            ){
    
                                                                                if(
                                                                                    ( this.M.sqlDateToJSDate(ptdEl['data_cal']).getTime() ===  this.M.sqlDateToJSDate(this.M.specificDateToDateField(dEl.ziua)).getTime() ) && 
                                                                                    (this.convertToTime(ptdEl['ora_cal']).getTime() >= this.convertToTime(this.state.highestDurationAppoint['ora_cal']) ) && 
                                                                                    (this.convertToTime(ptdEl['ora_cal']).getTime() < this.addMinutes(this.convertToTime(this.state.highestDurationAppoint['ora_cal']), +this.state.highestDurationAppoint['duration']).getTime() )
                                                                                   
                                                                                ){
                                                                                    
                                                                                    if( (ptdEl['tip'] || '') === 'pauza' ){
    
                                                                                        return (
    
                                                                                            <DayCalendarBreak 
                                                                                                key={'programare-calendar-saptamanala-'+idx}
                                                                                                width = {`${100/(overlappedIntervalAppoint.length + 1)}%`}
                                                                                                height = {`${calcHeight}px`}
                                                                                                top = {diff}
                                                                                                left = {ptdEl['token'] !== this.state.highestDurationAppoint['token'] ? `${(100/(overlappedIntervalAppoint.length + 1))}%` : '0%'}
                                                                                                zIndex = {setZIndex}
                                                                                                breakElem = {ptdEl}
                                                                                                member = {teamMember}
                                                                                            />
                                                                                        )
                                                                                    }
                                                                                    else{
    
                                                                                        return(
    
                                                                                            <DayCalendarElem 
                                                                                                key={'programare-calendar-saptamanala-'+idx}
                                                                                                width = {`${100/(overlappedIntervalAppoint.length + 1)}%`}
                                                                                                top = {diff}
                                                                                                left = {ptdEl['token'] !== this.state.highestDurationAppoint['token'] ? `${(100/(overlappedIntervalAppoint.length + 1))}%` : '0%'}
                                                                                                zIndex = {setZIndex}
                                                                                                height = {`${calcHeight}px`}
                                                                                                weeklyAppointsList = {this.state.weeklyAppointsList}
                                                                                                elem = {ptdEl}
                                                                                                onShowClientClick = {this.onShowClientClick}
                                                                                                member = {teamMember}
                                                                                            />
                                                                                        )
                                                                                    }      
    
                                                                                }
                                                                                
                                                                            }
                                                                            else{
    
                                                                                if( (ptdEl['tip'] || '') === 'pauza' ){
    
                                                                                    return (
    
                                                                                        <DayCalendarBreak 
                                                                                            key={'programare-calendar-saptamanala-'+idx}
                                                                                            height = {`${calcHeight}px`}
                                                                                            top = {diff}
                                                                                            zIndex = {setZIndex}
                                                                                            breakElem = {ptdEl}
                                                                                            member = {teamMember}
                                                                                        />
                                                                                    )
                                                                                }
                                                                                else{
    
                                                                                    return(
                                                                                
                                                                                        <DayCalendarElem 
                                                                                            key={'programare-calendar-saptamanala-'+idx}
                                                                                            top = {diff}
                                                                                            height = {`${calcHeight}px`}
                                                                                            zIndex = {setZIndex}
                                                                                            weeklyAppointsList = {this.state.weeklyAppointsList}
                                                                                            elem = {ptdEl}
                                                                                            onShowClientClick = {this.onShowClientClick}
                                                                                            member = {teamMember}
                                                                                        />
                                                                                    )  
                                                                                }
                                                                                 
                                                                            }
    
                                                                        })
                                                                    }
                                                                    </ul>
                                                    
                                                                </td> 
                                                            )
    
                                                            
                                                        })
                                                    }
                                                    
                                                </tr>
                                            )
                                        })
                                    }
                                   
                                    
    
                                </tbody>
    
                            </table>
    
                            {/* <div>
    
                            {
                                this.state.dateToShowOnInterval &&
    
                                this.state.dateToShowOnInterval.map((dateZiInterval, idx) => {
    
                                    return (
    
                                        <div key={`calzi${idx}`} style={{ display: 'inline-block', verticalAlign: 'top', width: '14%' }}>
    
                                            <CalendarZilnicTure
                                                ziSelectata={dateZiInterval.ziua}
                                                toShow={dateZiInterval.infoZi}
    
                                                
                                            />
                                        </div>
                                    )
    
                                })
    
                            }
                            </div> */}
    
                        </div>
    
                        )
                    }


            </div>
        );
    }


}  // end class
