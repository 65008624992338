import React from 'react';
import { MobileView } from 'react-device-detect';

import { RiCalendar2Fill } from 'react-icons/ri';
import ErrorImage from '../../../../assets/Feeling sorry-cuate 1.png';
import LaptopImage from '../../../../assets/laptop_and_mug.png';

import MServiceNav from '../../../../commons/MServiceNav';

import Masks from '../../../../providers/Masks';
import Utils from '../../../../providers/Utils';
import Ws from '../../../../providers/Ws';
import Declarations from '../../../../providers/Declarations';
import CrAppoInfo from '../../create_appointment_popup/CrAppoInfo';
import ErrorMessage from '../../../../commons/ErrorMessage';

export default class MCrAppoCreateSuccess extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            appointDetails: {},
            eroareTx: '',
            locationName: '',
        };
    }

    WS = new Ws();
    M = new Masks();
    UT = new Utils();

    componentDidMount(){

        this.getAppointmentDetails();
    }

    getAppointmentDetails = async () => {

        let selectAppointment = `select * from trc where token = '${this.props.match.params.tranToken}'  `;

        let _appointment =  this.WS.sqlCommand(`select`, selectAppointment);

        let screenQuery = `update trc set screen = 'client-formular-ca' where token = '${this.props.match.params.tranToken}' `;
        let _updateScreen = this.WS.sqlCommand(`update`, screenQuery);

        let [appointment, updateScreen] = await Promise.all( [_appointment, _updateScreen] );

        

        if(appointment.success && updateScreen.success){

            if(appointment.content.length === 0){

                this.setState({

                    eroareTx: 'Inregistrarea programarii a esuat. Programare invalida.'
                });

                return;
            }
            else if( (appointment.content[0]['clie_nume'] || '').length === 0 ){

                this.setState({

                    eroareTx: 'Date programare incomplete, programarea nu a avut succes!'
                });

            }

            this.setState({

                appointDetails: appointment.content[0]
            },
                () => {

                    this.setLocationName(appointment.content[0])
                }
            );
        }
        else{

            this.setState({

                eroareTx: 'Datele programarii nu au putut fi preluate de catre server.'
            });
            
            throw new Error("Error getting appointment data: " + appointment.message);
        }
    }

    setLocationName = (appointment) => {

        const locationNameAppoint = new Declarations().listaLocatiiIntalnire.filter(lEl => lEl.id === appointment.location);

        this.setState({

            locationName: locationNameAppoint[0].descClient
        });
    }

    render(){

        return(

            <MobileView>

            <MServiceNav
                icon={<RiCalendar2Fill />}
                title='Adauga in calendar'
                path={'/ca/dashboard/cab'}
            />

            <div
                style={{
                    padding: '20px'
                }}
            >

                <div
                    className="m-create-appointment-card"
                    style={{
                        padding: '20px',
                        borderRadius: '10px',
                        textAlign: 'left'
                    }}
                >

                    {
                        this.state.eroareTx.length > 0 && (

                            <ErrorMessage 
                                eroareTx = {this.state.eroareTx}
                            />
                        )
                    }

                    {
                        this.state.eroareTx.length === 0 && (

                            <>
                            
                                <p
                                    style={{
                                        margin: 0,
                                        fontSize: '18px',
                                        fontWeight: '700',
                                        color: '#60D84C',
                                        textAlign: 'center'
                                    }}
                                >
                                    Felicitari!
                                </p>

                                <br />

                                <p
                                    style={{
                                        margin: 0,
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        color: '#455A64',
                                        textAlign: 'center'
                                    }}
                                >
                                    Ai inregistrat cu succes o invitatie pentru:
                                </p>

                                <br />
    
                                <CrAppoInfo
                                    serviceName = {this.state.appointDetails['service_name']}
                                    duration = {this.state.appointDetails.duration}
                                    price = {this.state.appointDetails.price}
                                    location = {this.state.appointDetails.location}
                                    locationName = {this.state.locationName}
                                    date = {this.state.appointDetails['data_cal']}
                                    hour = { this.state.appointDetails['ora_cal'] }
                                    titleStyle = {{
                                        margin: 0,
                                        fontSize: '18px',
                                        fontWeight: '700',
                                        textAlign: 'center'
                                    }}
                                />

                                <p
                                    style={{
                                        margin: 0,
                                        marginTop: 10,
                                        fontSize: '14px',
                                        color: '#455A64'
                                    }}
                                >
                                    Detaliile de conectare ti-au fost trimise prin e-mail la adresa furnizata
                                </p>

                                <br />

                                <div
                                    style={{
                                        width: '100%',
                                        height: '1px',
                                        background: '#EBEBEB'
                                    }}
                                ></div>

                                <br />

                                <p
                                    style={{
                                        margin: 0,
                                        textAlign: 'center',
                                        fontWeight: '700',
                                        fontSize: '16px',
                                        color: '#455A64'
                                    }}
                                >
                                    Datele Clientului
                                </p>

                                <br />

                                <div className="row">

                                    <div className="col-l-2 col-m-2 col-s-3">

                                        <p
                                            style={{
                                                margin: 0,
                                                fontSize: '14px'
                                            }}
                                        >
                                            Nume: 
                                        </p>

                                    </div>

                                    <div className="col-l-10 col-m-10 col-s-9">

                                        <p
                                            style={{
                                                margin: 0,
                                                fontSize: '14px',
                                                fontWeight: '500'
                                            }}
                                        >
                                            {this.state.appointDetails['clie_nume'] + ' ' + this.state.appointDetails['clie_prenume']}
                                        </p>

                                    </div>

                                </div>

                                <div className="row" style={{marginTop: 8}}>

                                    <div className="col-l-2 col-m-2 col-s-3">

                                        <p
                                            style={{
                                                margin: 0,
                                                fontSize: '14px'
                                            }}
                                        >
                                            Email: 
                                        </p>

                                    </div>

                                    <div className="col-l-10 col-m-10 col-s-9">

                                        <p
                                            style={{
                                                margin: 0,
                                                fontSize: '14px',
                                                fontWeight: '500'
                                            }}
                                        >
                                            {this.state.appointDetails['clie_email']}
                                        </p>

                                    </div>

                                </div>

                                <div className="row" style={{marginTop: 8}}>

                                    <div className="col-l-2 col-m-2 col-s-3">

                                        <p
                                            style={{
                                                margin: 0,
                                                fontSize: '14px'
                                            }}
                                        >
                                            Telefon: 
                                        </p>

                                    </div>

                                    <div className="col-l-10 col-m-10 col-s-9">

                                        <p
                                            style={{
                                                margin: 0,
                                                fontSize: '14px',
                                                fontWeight: '500'
                                            }}
                                        >
                                            {this.state.appointDetails['clie_tf']}
                                        </p>

                                    </div>

                                </div>

                                <div className="row" style={{marginTop: 8}}>

                                    <div className="col-l-2 col-m-2 col-s-3">

                                        <p
                                            style={{
                                                margin: 0,
                                                fontSize: '14px'
                                            }}
                                        >
                                            Detalii: 
                                        </p>

                                    </div>

                                    <div className="col-l-10 col-m-10 col-s-9">

                                        <p
                                            style={{
                                                margin: 0,
                                                fontSize: '14px',
                                                fontWeight: '500'
                                            }}
                                        >
                                            {this.state.appointDetails['clie_detalii'] || 'n/a'}
                                        </p>

                                    </div>

                                </div>

                                <br />

                                <div
                                    style={{
                                        width: '100%',
                                        height: '1px',
                                        background: '#EBEBEB'
                                    }}
                                ></div>

                                <br />

                                <p
                                    style={{
                                        margin: 0,
                                        textAlign: 'center',
                                        fontWeight: '700',
                                        fontSize: '16px',
                                        color: '#455A64'
                                    }}
                                >
                                    Ce urmeaza?
                                </p>

                                <br />

                                <p
                                    style={{
                                        margin: 0,
                                        marginTop: 10,
                                        fontSize: '12px',
                                        fontWeight: '400',
                                        color: '#455A64'
                                    }}
                                >
                                    Clientul tau va primi o invitatie sub forma de mesaj email pentru aceasta programare.
                                    <br /> <br />
                                    Clientul poate schimba data  si ora programarii, o poate refuza, sau o poate ignora.
                                    <br /> <br />
                                    Clientul trebuie sa o accepte, sa o finalizeze si in cazul in care programarea este platita, sa o si plateasca pentru ca aceasta sa devina activa in calendarul tau.
                                    <br /> <br />
                                    In momentul in care clientul confirma invitatia vei primi notificare prin email si prin sistemul intern de notificari.
                                </p>
                            
                            </>
                        )
                    }


                </div>

                <img 
                    src={LaptopImage}
                    alt="Calendar Image"
                    style={{
                        display: 'block',
                        margin: '30px auto',
                        width: '200px',
                    }}
                />

            </div>

            </MobileView>
        )
    }
}