import React from 'react';

export default class Home extends React.Component{

    constructor(props){

        super(props);
    }

    onGoSvs = () => {

        this.props.history.push("/svs")
    }

    render(){
        
        return(

            <div>

                <h1>Home</h1>
                <button type="button" onClick={this.onGoSvs}>Svs</button>

            </div>
        );
    }
}