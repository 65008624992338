import React from 'react';

import Ws from '../../../providers/Ws';
import Masks from '../../../providers/Masks';
import Utils from '../../../providers/Utils';
import Declarations from '../../../providers/Declarations';
import CrAppoInfo from './CrAppoInfo';
import ErrorMessage from '../../../commons/ErrorMessage';

export default class CrAppoCreateSuccessful extends React.Component{

    constructor(props){

        super(props);
        this.state = {

            appointmentDetails: {},
            locationName: '',
            eroareTx: ''
        }
    }

    WS = new Ws();
    UT = new Utils();
    M = new Masks();

    componentDidMount(){

        this.getData();
    }

    getData = async () => {

        let selectTrcQuery = `select * from trc where token = '${this.props.tranToken}'   `;
        

        let _clientAppointmentInfo =  this.WS.sqlCommand(`select`, selectTrcQuery);

        let screenQuery = `update trc set screen = 'client-succes-ca' where token = '${this.props.tranToken}' `;
        let _updateScreen = this.WS.sqlCommand(`update`, screenQuery);

        let [clientAppointmentInfo, updateScreen] = await Promise.all( [_clientAppointmentInfo, _updateScreen ] );

        if(clientAppointmentInfo.success && updateScreen.success){

            

            this.setState({

                appointmentDetails: clientAppointmentInfo.content[0]
            },
                () => {

                    this.setLocationName(clientAppointmentInfo.content[0])
                }
            )
        }
        else{

            this.setState({

                eroareTx: 'Eroare server.'
            });

            throw new Error("Error fetching service data: " + clientAppointmentInfo.message);
        }
    }

    setLocationName = (appointment) => {

        const locationNameAppoint = new Declarations().listaLocatiiIntalnire.filter(lEl => lEl.id === appointment.location);

        this.setState({

            locationName: locationNameAppoint[0].descClient
        });
    }

    onCloseCreateAppointPopup = () => {

        this.props.onCloseCreateAppointPopup();
    }

    render(){

        return(

            <div
            >

                {
                    this.state.eroareTx.length > 0 && (

                        <ErrorMessage 
                        
                            eroareTx = {this.state.eroareTx}
                        />  
                    )
                }

                {
                    this.state.eroareTx.length === 0 && (

                        <>
                       
                            <div 
                                style={{
                                    textAlign: 'center'
                                }}  
                            >
                                <p
                                    style={{
                                        margin: 0,
                                        fontWeight: '700',
                                        fontSize: '18px',
                                        color: '#60D84C'
                                    }}
                                >
                                    Felicitari!
                                </p>

                                <br />

                                <p
                                    style={{
                                        margin: 0,
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        color: '#263238'
                                    }}
                                >
                                    Ai inregistrat cu succes o invitatie pentru:
                                </p>

                            </div>

                            <br />

                            <CrAppoInfo 
                                serviceName = {this.state.appointmentDetails['service_name']}
                                duration = {this.state.appointmentDetails.duration}
                                price = {this.state.appointmentDetails.price}
                                location = {this.state.appointmentDetails.location}
                                locationName = {this.state.locationName}
                                date = {this.state.appointmentDetails['data_cal']}
                                hour = { this.state.appointmentDetails['ora_cal'] }
                                titleStyle = {{
                                    margin: 0,
                                    fontSize: '20px',
                                    fontWeight: '700',
                                    textAlign: 'center'
                                }}
                            />

                            <p
                                style={{
                                    margin: 0,
                                    marginTop: 5,
                                    fontSize: '14px',
                                    color: '#455A64'
                                }}
                            >
                                Detaliile de conectare ti-au fost trimise prin e-mail la adresa furnizata
                            </p>

                            <br />

                            <div
                                style={{
                                    width: '100%',
                                    height: '1px',
                                    background: '#EBEBEB'
                                }}
                            ></div>

                            <br />

                            <p
                                style={{
                                    margin: 0,
                                    textAlign: 'center',
                                    fontWeight: '700',
                                    fontSize: '16px',
                                    color: '#455A64'
                                }}
                            >
                                Datele Clientului
                            </p>

                            <br />

                            <div className="row">

                                <div className="col-l-2 col-m-2 col-s-2">

                                    <p
                                        style={{
                                            margin: 0,
                                            fontSize: '14px'
                                        }}
                                    >
                                        Nume: 
                                    </p>

                                </div>

                                <div className="col-l-10 col-m-10 col-s-10">

                                    <p
                                        style={{
                                            margin: 0,
                                            fontSize: '14px',
                                            fontWeight: '500'
                                        }}
                                    >
                                        {this.state.appointmentDetails['clie_nume'] + ' ' + this.state.appointmentDetails['clie_prenume']}
                                    </p>

                                </div>

                            </div>

                            <div className="row" style={{marginTop: 8}}>

                                <div className="col-l-2 col-m-2 col-s-2">

                                    <p
                                        style={{
                                            margin: 0,
                                            fontSize: '14px'
                                        }}
                                    >
                                        Email: 
                                    </p>

                                </div>

                                <div className="col-l-10 col-m-10 col-s-10">

                                    <p
                                        style={{
                                            margin: 0,
                                            fontSize: '14px',
                                            fontWeight: '500'
                                        }}
                                    >
                                        {this.state.appointmentDetails['clie_email']}
                                    </p>

                                </div>

                            </div>

                            <div className="row" style={{marginTop: 8}}>

                                <div className="col-l-2 col-m-2 col-s-2">

                                    <p
                                        style={{
                                            margin: 0,
                                            fontSize: '14px'
                                        }}
                                    >
                                        Telefon: 
                                    </p>

                                </div>

                                <div className="col-l-10 col-m-10 col-s-10">

                                    <p
                                        style={{
                                            margin: 0,
                                            fontSize: '14px',
                                            fontWeight: '500'
                                        }}
                                    >
                                        {this.state.appointmentDetails['clie_tf']}
                                    </p>

                                </div>

                            </div>

                            <div className="row" style={{marginTop: 8}}>

                                <div className="col-l-2 col-m-2 col-s-2">

                                    <p
                                        style={{
                                            margin: 0,
                                            fontSize: '14px'
                                        }}
                                    >
                                        Detalii: 
                                    </p>

                                </div>

                                <div className="col-l-10 col-m-10 col-s-10">

                                    <p
                                        style={{
                                            margin: 0,
                                            fontSize: '14px',
                                            fontWeight: '500'
                                        }}
                                    >
                                        {this.state.appointmentDetails['clie_detalii'] || 'n/a'}
                                    </p>

                                </div>

                            </div>

                            <br />

                            <div
                                style={{
                                    width: '100%',
                                    height: '1px',
                                    background: '#EBEBEB'
                                }}
                            ></div>

                            <br />

                            <p
                                style={{
                                    margin: 0,
                                    textAlign: 'center',
                                    fontWeight: '700',
                                    fontSize: '16px',
                                    color: '#455A64'
                                }}
                            >
                                Ce urmeaza?
                            </p>

                            <br />

                            <p
                                style={{
                                    margin: 0,
                                    marginTop: 10,
                                    fontSize: '12px',
                                    fontWeight: '400',
                                    color: '#455A64'
                                }}
                            >
                                Clientul tau va primi o invitatie sub forma de mesaj email pentru aceasta programare.
                                <br /> <br />
                                Clientul poate schimba data  si ora programarii, o poate refuza, sau o poate ignora.
                                <br /> <br />
                                Clientul trebuie sa o accepte, sa o finalizeze si in cazul in care programarea este platita, sa o si plateasca pentru ca aceasta sa devina activa in calendarul tau.
                                <br /> <br />
                                In momentul in care clientul confirma invitatia vei primi notificare prin email si prin sistemul intern de notificari.
                            </p>
                        
                        </>
                    )
                }

            </div>
        )
    }
}