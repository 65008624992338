import React from 'react';
import { BrowserView, MobileView } from 'react-device-detect';

import { FaAngleRight, FaBook } from 'react-icons/fa';
import { GoCheck } from 'react-icons/go';
import { MdPersonAdd, MdSupervisorAccount, MdPublic, MdDeleteForever } from 'react-icons/md';
import {ReactComponent as PublicOff} from '../../../assets/publicoff.svg';
import AvatarImg from '../../../assets/foxStat.jpeg';
import AuthInput from '../../../commons/AuthInput';

import Avatar from '../../../commons/Avatar';
import DisplaySchedule from '../../../commons/DisplaySchedule';
import LinkPreview from '../../../commons/LinkPreview';
import MServiceNav from '../../../commons/MServiceNav';
import SelectProgram from '../../../commons/SelectProgram';
import TeamMemberCard from '../../../commons/TeamMemberCard';
import SvSchedule from '../../../commons/service/SvSchedule';

import Masks from '../../../providers/Masks';
import Utils from '../../../providers/Utils';
import Ws from '../../../providers/Ws';
import LocalStorage from '../../../providers/LocalStorage';
import SelectOthers from '../../../commons/SelectOthers';
import ButtonSaveDashboard from '../../../commons/ButtonSaveDashboard';
import InputValidation from '../../logic/InputValidation';
import ErrorToast from '../../../commons/ErrorToast';
import SuccessSaveToast from '../../../commons/SuccessSaveToast';
import Loader from '../../../commons/Loader';
import EmailTemplatesDeclarations from '../../../providers/EmailTemplatesDeclarations';
import SharedSocialToast from '../../../commons/SharedSocialToast';
import CopiedLinkPopUp from '../../../commons/CopiedLinkPopUp';

export default class MembruEchipa extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            canSave: false,
            tipAbonament: this.props.tipAbonament || '',
            avatarSrc: '',
            nume: '',
            functie: '',
            email: '',
            telefon: '',
            link: '',
            program: '',
            userWebLink: '',
            copiedLink: '',
            showToastSocial: false,
            isLinkCardShown: false,

            numeErr: false,
            functieErr: false,
            emailErr: false,
            telefonErr: false,
            linkErr: false,
            programErr: false,

            numeErrTx: null,
            functieErrTx: null,
            emailErrTx: null,
            telefonErrTx: null,
            linkErrTx: null,
            programErrTx: null,

            numeValid: false,
            functieValid: false,
            emailValid: false,
            telefonValid: false,
            linkValid: false,
            programValid: false,

            showToastPopup: false,
            showToastPopupError: false,
            actionTx: '',
            programe: [],
            programAfisat: [],
            accountData: {},
            canSave: false,
        };
    }

    scheduleTmp = [

        {
            zi_start: '08:00',
            zi_end: '20:00'
        },

        {
            zi_start: '08:00',
            zi_end: '20:00'
        },

        {
            zi_start: '08:00',
            zi_end: '20:00'
        },

        {
            zi_start: '08:00',
            zi_end: '20:00'
        },

        {
            zi_start: '08:00',
            zi_end: '20:00'
        },

        {
            zi_start: null,
            zi_end: null
        },

        {
            zi_start: null,
            zi_end: null
        },
    ]

    WS = new Ws();
    UT = new Utils();
    M = new Masks();
    IV = new InputValidation();

    componentDidMount(){

        if(this.props.userWrkToken || this.props?.match?.params?.userWrkToken){

            this.onGetData();
        }
        else{

            this.onSetProgram();
            this.onResetFields();
        }
    }

    componentDidUpdate(prevProps, _){

        if(this.props.userWrkToken !== prevProps.userWrkToken){
           
            if(this.props.userWrkToken){

                this.setState({

                    isLoading: true
                },
                    async () => {

                        await this.onGetData();
                    }
                );
            }
            else{

                this.setState({

                    isLoading: true
                },
                    async () => {

                        this.onSetProgram();
                        this.onResetFields();   
                    }
                );
            }
        }
    }

    onResetInputsStates = () => {

        this.setState({

            numeErr: false,
            functieErr: false,
            emailErr: false,
            telefonErr: false,
            linkErr: false,
            programErr: false,

            numeErrTx: null,
            functieErrTx: null,
            emailErrTx: null,
            telefonErrTx: null,
            linkErrTx: null,
            programErrTx: null,

            numeValid: false,
            functieValid: false,
            emailValid: false,
            telefonValid: false,
            linkValid: false,
            programValid: false,
        });
    }

    onResetFields = () => {

        this.setState({

            canSave: false,
            avatarSrc: '',
            nume: '',
            functie: '',
            email: '',
            telefon: '',
            link: '',
            program: '',

            numeErr: false,
            functieErr: false,
            emailErr: false,
            telefonErr: false,
            linkErr: false,
            programErr: false,

            numeErrTx: null,
            functieErrTx: null,
            emailErrTx: null,
            telefonErrTx: null,
            linkErrTx: null,
            programErrTx: null,

            numeValid: false,
            functieValid: false,
            emailValid: false,
            telefonValid: false,
            linkValid: false,
            programValid: false,

            showModal: false,
            modalMessage: '',
            canSave: false,

            isLoading: true
        });
    }

    onGetData = async () => {

        const wrkToken = this.props.isDesktop ? this.props.userWrkToken : this.props.match.params.userWrkToken;
        const accountToken = new LocalStorage().GetAccountToken();

        // const qAccount = `select * from accounts where token = '${accountToken}' `;
        const qUser = `select * from users where wrk_token = '${wrkToken}' `;

        // let _account = this.WS.sqlCommand(`select`, qAccount);
        let _user = this.WS.sqlCommand(`select`, qUser);
        

        let [ user ] = await Promise.all([ _user ]);

        this.setState({
            
            nume: user.content[0].nume,
            email: user.content[0].email,
            functie:  user.content[0].functie || '',
            telefon: user.content[0].telefon || '',
            link:  user.content[0].web_link || '',
            program:  user.content[0].program_tk,
            avatarSrc: (user.content[0].avatar_src || '').length > 0 ? this.WS.serverAddress + 'staticprovider/getimg?filepath=Storage/Avatars/' + user.content[0].avatar_src : null,
            user: user.content[0],
            userWebLink: user.content[0].web_link
            // tipAbonament: account.content[0]['abonament'],
        },
            async () => {

                await this.onSetProgram();
                this.onResetInputsStates();
            }
        );
    }

    onSetProgram = async () => {

        const accountToken = new LocalStorage().GetAccountToken();

        const qPrograms = `select * from programs where account_tk = '${accountToken}' and ifnull(sters,0)!=1`;
        let _programs = this.WS.sqlCommand(`select`, qPrograms);

        const qAccount = `select * from accounts where token = '${accountToken}' `;
        let _account = this.WS.sqlCommand(`select`, qAccount);

        const qUsers = `select * from users where account_id = '${accountToken}' `;
        let _users = this.WS.sqlCommand(`select`, qUsers);

        let [ programs, account, users ] = await Promise.all([ _programs,  _account, _users]);

        this.setState({

            programe: programs.content, 
            tipAbonament: account.content[0]['abonament'],
            accountData: account.content[0],
            users: users.content,
            isLoading: false
        }, () => {

            this.setListaProgram()
        });
    }

    createScheduleList = (schedule) => {

        let zile = [
            {
                zi_start: schedule.lu_start === null ? null : schedule.lu_start.substring(0,5),
                zi_end: schedule.lu_end === null ? null : schedule.lu_end.substring(0,5)
            },

            {
                zi_start: schedule.ma_start === null ? null : schedule.ma_start.substring(0,5),
                zi_end: schedule.ma_end === null ? null : schedule.ma_end.substring(0,5)
            },

            {
                zi_start: schedule.mi_start === null ? null : schedule.mi_start.substring(0,5),
                zi_end: schedule.mi_end === null ? null : schedule.mi_end.substring(0,5)
            },

            {
                zi_start: schedule.jo_start === null ? null : schedule.jo_start.substring(0,5),
                zi_end: schedule.jo_end === null ? null : schedule.jo_end.substring(0,5)
            },

            {
                zi_start: schedule.vi_start === null ? null : schedule.vi_start.substring(0,5),
                zi_end: schedule.vi_end === null ? null : schedule.vi_end.substring(0,5)
            },

            {
                zi_start: schedule.sa_start === null ? null : schedule.sa_start.substring(0,5),
                zi_end: schedule.sa_end === null ? null : schedule.sa_end.substring(0,5)
            },

            {
                zi_start: schedule.du_start === null ? null : schedule.du_start.substring(0,5),
                zi_end: schedule.du_end === null ? null : schedule.du_end.substring(0,5)
            }
        ]

        return zile;
    }

    setListaProgram = () => {

        const programTk = (this.state.program || '').length > 0 ? this.state.program : this.state.programe[0]['token'];
        let programCurent = this.state.programe.filter(pEl => pEl.token === programTk)

        let zile = this.createScheduleList(programCurent[0]);

        this.setState({

            program: programTk,
            programAfisat: zile,
        });
    }

    onCheckValidation = async () => {

        const accountToken = new LocalStorage().GetAccountToken();
        const wrkToken = this.props.isDesktop ? this.props.userWrkToken : this.props.match.params.userWrkToken;

        let isEmailValid = await this.IV.onCheckEmailTeamMebmer(this.state.email, wrkToken);
        let isNumeValid = this.IV.onCheckName(this.state.nume);
        let isFunctieValid = this.IV.onCheckName(this.state.nume);
        let isTelefonValid = this.IV.onCheckPhone(this.state.telefon);
        let isWebLinkValid = await this.IV.onCheckUserWebLink(accountToken, this.state.link);  

        if(this.state.tipAbonament === 'anteprenor'){

            return {isEmailValid, isNumeValid, isFunctieValid, isTelefonValid};
        }
        else{

            isWebLinkValid = isWebLinkValid.data;
            return {isEmailValid, isNumeValid, isFunctieValid, isTelefonValid, isWebLinkValid};
        }
    }

    onAddMember = async () => {
        
        let q = ``;
        let type = '';
        let user = {};
        let res;

        const inputsResult = await this.onCheckValidation();
        const inputsStatus = Object.values(inputsResult).every(item => item.isOk === true);

        let isUsersLimitReached = false;

        const accountToken = new LocalStorage().GetAccountToken();
        let selectUsersContactsQuery = `select * from user_contacts where token_user = ( select token from users where account_id = '${accountToken}' LIMIT 1 ) `;
        let usersContacts = await this.WS.sqlCommand(`select`, selectUsersContactsQuery);

        if(this.state.tipAbonament === 'anteprenor' && (this.props.userWrkToken === undefined || this.props?.match?.params?.userWrkToken === undefined)){

            isUsersLimitReached = this.state.users.length === 4; //Primul user nu se pune, deoarece este main account user.
        }

        

        const skype = usersContacts.content.filter(contact => contact.tip === 'skype');
        const google = usersContacts.content.filter(contact => contact.tip === 'google');

        if(inputsStatus && !isUsersLimitReached){

            if(this.props.userWrkToken || this.props?.match?.params?.userWrkToken){

                const wrkToken = this.props.isDesktop ? this.props.userWrkToken : this.props.match.params.userWrkToken;
                type = `update`;
                
                if(this.state.tipAbonament === 'anteprenor'){
    
                    q = `update users set nume = '${this.state.nume}', email = '${this.state.email}',
                            functie = '${this.state.functie}', program_tk = '${this.state.program}',
                            avatar_src = '${this.state.avatarSrc}', telefon = '${this.state.telefon}'
                            where wrk_token = '${wrkToken}'
                            `;
                }
                else if(this.state.tipAbonament === 'premium'){
    
                    
                    q = `update users set nume = '${this.state.nume}', email = '${this.state.email}',
                            functie = '${this.state.functie}', web_link = '${this.state.link}', program_tk = '${this.state.program}',
                            avatar_src = '${this.state.avatarSrc}',  telefon = '${this.state.telefon}',
                            web_link = '${this.state.link}'
                            where wrk_token = '${wrkToken}'
                            `;
                }
    
                res = await this.WS.sqlCommand(`update`, q);
    
            }
            else{
    
                const wrkToken = this.UT.generateToken();
                const userToken =  this.UT.generateToken();
                const accountToken = new LocalStorage().GetAccountToken();
    
                type = `insert`;
                
                if(this.state.tipAbonament === 'anteprenor'){
    
                    q = `insert into users (nume, email, functie, program_tk, avatar_src, telefon, wrk_token, token, account_id)
                        select
                        '${this.state.nume}', '${this.state.email}', '${this.state.functie}', '${this.state.program}', '${this.state.avatarSrc}',
                        '${this.state.telefon}',
                        '${wrkToken}', '${userToken}', '${accountToken}'
                    `;

                }
                else if(this.state.tipAbonament === 'premium'){
    
                    const pass = this.UT.generateToken();
    
                    q = `insert into users (nume, email, functie, program_tk, pwd_c, avatar_src, telefon, web_link, wrk_token, token, account_id)
                            select
                            '${this.state.nume}', '${this.state.email}', '${this.state.functie}', '${this.state.program}',
                            '${pass}', '${this.state.avatarSrc}', '${this.state.telefon}', '${this.state.link}', '${wrkToken}', '${userToken}', '${accountToken}'  
                        `;
                    
                    let ud = new LocalStorage().GetUserData();
    
                    // Implementeaza functia de send mail cu parola catre user
                    const emailBody = new EmailTemplatesDeclarations().onGenerateMembruCreatEmail(this.state.email, pass, ud.accountData[0]['display_name']);

                    let emailRes = await this.UT.onGenerateMailTemplateAndSendToEmail(
                        emailBody, this.state.email, `Planary - Membru nou`,
                    );
                }
    
                user = {wrk_token: wrkToken};
    
                res = await this.WS.sqlCommand(`insert`, q);
                // const querySkype = `insert into user_contacts(tip, token_user, value) select
                //     'skype', '${userToken}', '${skype[0]['value']}'
                // `
                // let skypeData = await this.WS.sqlCommand(`insert`, querySkype);

                // const queryGmeet = `insert into user_contacts(tip, token_user, value) select
                //     'google', '${userToken}', '${skype[0]['value']}'
                // `
                // let gmeetData = await this.WS.sqlCommand(`insert`, querySkype);

                let queryUserContacts = `insert into user_contacts(token_user, tip, value)
                                     select
                                    '${userToken}', 'email', '${this.state.email}'
                                `;

                let queryUserRights = `insert into user_rights(token_account, token_user, is_admin, can_change_settings)
                                        select '${accountToken}', '${userToken}', 0, 0
                                    `;

                let _userContacts = this.WS.sqlCommand(`insert`, queryUserContacts);
                let _userRights = this.WS.sqlCommand(`insert`, queryUserRights);

                let [userContacts, userRights] = await Promise.all( [_userContacts, _userRights] );
            }

            
            
            if(res.success){
    
                if(this.props.onSetData){
    
                    this.props.onSetData();
                }
    
                if(type === `insert` && this.props.onSelectUser){
    
                    this.props.onSelectUser(user);
                }
            }
            else{
    
                
            }
        }

        

        this.setState({

            numeErr: inputsResult.isNumeValid.error,
            functieErr: inputsResult.isFunctieValid.error,
            emailErr: inputsResult.isEmailValid.error,
            telefonErr: inputsResult.isTelefonValid.error,
            linkErr: inputsResult.isWebLinkValid ? inputsResult.isWebLinkValid.error : false,

            numeErrTx: inputsResult.isNumeValid.errorTx,
            functieErrTx: inputsResult.isFunctieValid.errorTx,
            emailErrTx: inputsResult.isEmailValid.errorTx,
            telefonErrTx: inputsResult.isTelefonValid.errorTx,
            linkErrTx: inputsResult.isWebLinkValid ? inputsResult.isWebLinkValid.errorTx : null,

            numeValid: inputsResult.isNumeValid.verified,
            functieValid: inputsResult.isFunctieValid.verified,
            emailValid: inputsResult.isEmailValid.verified,
            telefonValid: inputsResult.isTelefonValid.verified,
            linkValid: inputsResult.isWebLinkValid ? inputsResult.isWebLinkValid.verified : false,
        });
    }

    onChangeInput = (ev) => {

        this.setState({

            [ev.target.name]: ev.target.value,
            canSave: true
        }, () => {


        })
    }

    onChangeAvatar = async (fileName) => {

        let q = `update users set avatar_src = '${fileName}' `;
        let res = await this.WS.sqlCommand(`update`, q);

        if(res.success){

            this.setState({

                avatarSrc: fileName
            });
        }
    }

    onChangeProgram = (ev) => {

        let programCurent = this.state.programe.filter(pEl => pEl.token === ev.target.value)
        let zile = this.createScheduleList(programCurent[0]);

        this.setState({

            program: ev.target.value,
            programAfisat: zile
        });
    }

    onCloseToastPopup = (type) => {

        this.setState({

            [type]: false
        });
    }

    onCopyLinkToClipBoard = async () => {
        
        const linkRes = await this.UT.onCopyLinkToClipBoard(null, this.state.userWebLink);

        if(linkRes.err !== null){

            this.setState({
                showToastPopupError: true,
                actionTx: linkRes.err
            });
        }
        else{

            this.setState({
                copiedLink: linkRes.data,
                isLinkCardShown: true
            }); 
        }
    }

    onLinkCardClose = (close) => {

        this.setState({

            isLinkCardShown: close
        })
    }

    onClickViewPage = async () => {

        const viewPageRes = await this.UT.onClickViewPage(null, this.state.userWebLink);  

        if(viewPageRes.err !== null){

            this.setState({
                showToastPopupError: true,
                actionTx: viewPageRes.err
            });
            
            
        }
        else{

            window.open(viewPageRes.data);
        }
    }

    onShowToastSocial = (webLink) => {

        this.setState({

            serviceLink: webLink,
            showToastSocial: true
        });
    }
    
    

    render(){

        if(this.state.isLoading){

            return <Loader containerH = '300px' />;
        }

        return(

            <>

                {
                    this.state.showToastSocial && (

                        <SharedSocialToast 
                            onClosePopup = {this.onCloseToastPopup}
                            company = {this.state.accountData.web_link}
                            webLink = {this.state.userWebLink}
                        />
                    )
                }


                {
                    this.state.isLinkCardShown &&

                    <CopiedLinkPopUp 
                        link={this.state.copiedLink}
                        onClosePopup = {this.onLinkCardClose}
                    />
                }

                {
                    this.state.showToastPopupError && (

                        <ErrorToast 
                            action={this.state.actionTx}
                            onClosePopup = {this.onCloseToastPopup}
                        />
                    )
                }

                {
                    this.state.showToastPopup && (

                        <SuccessSaveToast 
                            onClosePopup = {this.onCloseToastPopup}
                            saveName = 'Membrul'
                            action = {this.state.actionTx}
                        />
                    )
                }

                <BrowserView
                    style={{
                        display: this.props.isDesktop ? 'block' : 'none'
                    }}
                >
                    <ButtonSaveDashboard 
                        canSave = {this.state.canSave}
                        onClick={this.onAddMember}
                    />

                    
                    <div className='row'>

                        {
                            this.props.userWrkToken || this.props?.match?.params?.userWrkToken ? (

                                <Avatar 
                                    avatarSrc = {this.state.avatarSrc}
                                    showSuccesUpload = {this.showSuccesUpload}
                                    showErrorUpload = {this.showErrorUpload}
                                    saveAvatar = {this.onChangeAvatar}
                                    accToken = {new LocalStorage().GetAccountToken()}
                                    wrkToken = {this.props.isDesktop ? this.props.userWrkToken : this.props.match.params.userWrkToken}
                                    // margin = '0px'
                                />
                            ) : (

                                <br />
                            )
                        }

                    </div>


                    <div className="form-control">

                        <AuthInput     
                            label = 'Nume si Prenume'
                            type = "text"
                            value = {this.state.nume}
                            name = "nume"
                            onChange = {this.onChangeInput}
                            iserror={this.state.numeErr}
                            errormsg={this.state.numeErrTx}
                            verified={this.state.numeValid}
                            onFocus = {
                                () => {

                                    this.setState({
                                        numeErrTx: null
                                    })
                                }
                            }
                        />

                    </div>


                    <div 
                        className="form-control"
                        style={{
                            marginTop: '20px'
                        }}
                    >

                        <AuthInput 
                            label = 'Functie'
                            type = "text"
                            value = {this.state.functie}
                            name = "functie"
                            onChange = {this.onChangeInput}
                            iserror={this.state.functieErr}
                            errormsg={this.state.functieErrTx}
                            verified={this.state.functieValid}
                            onFocus = {
                                () => {

                                    this.setState({
                                        functieErrTx: null
                                    })
                                }
                            }
                        />

                    </div>

                    <div 
                        className="form-control"
                        style={{
                            marginTop: '20px'
                        }}
                    >

                        <AuthInput 
                            label = 'Adresa Email'
                            type = "email"
                            value = {this.state.email}
                            name = "email"
                            onChange = {this.onChangeInput}
                            iserror={this.state.emailErr}
                            errormsg={this.state.emailErrTx}
                            verified={this.state.emailValid}
                            onFocus = {
                                () => {

                                    this.setState({
                                        emailErrTx: null
                                    })
                                }
                            }
                        />

                    </div>

                    <div 
                        className="form-control"
                        style={{
                            marginTop: '20px'
                        }}
                    >

                        <AuthInput 
                            label = 'Numar de telefon'
                            type = "text"
                            value = {this.state.telefon}
                            name = "telefon"
                            onChange = {this.onChangeInput}
                            iserror={this.state.telefonErr}
                            errormsg={this.state.telefonErrTx}
                            verified={this.state.telefonValid}
                            onFocus = {
                                () => {

                                    this.setState({
                                        telefonErrTx: null
                                    })
                                }
                            }
                        />

                    </div>

                    {
                        this.state.tipAbonament === 'premium' ? (
                            <>
                                <div 
                                    className="form-control"
                                    style={{
                                        marginTop: '20px'
                                    }}
                                >

                                    <AuthInput 
                                        label = 'Link'
                                        type = "text"
                                        value = {this.state.link}
                                        name = "link"
                                        onChange = {this.onChangeInput}
                                        iserror={this.state.linkErr}
                                        errormsg={this.state.linkErrTx}
                                        verified={this.state.linkValid}
                                        onFocus = {
                                            () => {
            
                                                this.setState({
                                                    linkErrTx: null
                                                })
                                            }
                                        }
                                    />

                                </div>

                                <LinkPreview 
                                    accountLink = {this.state.accountData['web_link']}
                                    link = {this.state.link}
                                    enableLinks = {!this.state.canSave && this.props.userWrkToken}
                                    fontSize = '16px'
                                    fontSizeCommands = '16px'
                                    onCopyLinkToClipBoard = {this.onCopyLinkToClipBoard}
                                    onClickViewPage = {this.onClickViewPage}
                                    onShowToastSocial = {this.onShowToastSocial}
                                />
                            </>
                        ) : null
                    }

                    <div 
                        className="form-control"
                        style={{
                            marginTop: 20
                        }}
                    >

                         <SelectOthers
                            keyName = 'interval-program-'
                            label='Program'
                            list={this.state.programe.map(pEl => {

                                return {
                                    val: pEl['token'],
                                    tx: pEl['name']
                                }
                            })
                            
                            }
                            // programCurent={this.state.programTk}
                            onChange={this.onChangeProgram}
                            tipCurent={this.state.program || this.state.programe[0]?.token}
                        />

                    </div>

                    <div 
                        className='form-control'
                        style={{
                            marginTop: '20px'
                        }}
                    >

                        <DisplaySchedule 
                            programAfisat = {this.state.programAfisat}
                        />

                    </div>

                    <br />

                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >

                        <div 
                            style={{
                                width: '50%',
                                textAlign: 'left',
                                cursor: 'pointer'
                            }}
                        >

                            <PublicOff 
                                style={{
                                    position: 'relative',
                                    display: 'inline-block',
                                }}
                            />

                            <p
                                style={{
                                    position: 'relative',
                                    display: 'inline-block',
                                    color: '#263238',
                                    top: '-4px',
                                    left: '8px',
                                    margin: 0,
                                    fontSize: '14px',
                                    fontWeight: '400'
                                }}
                            >
                                Blocheaza
                            </p>

                        </div>

                        <div 
                            style={{
                                width: '50%',
                                textAlign: 'right',
                                paddingRight: '16px',
                                cursor: 'pointer'
                            }}
                        >

                            <MdDeleteForever 
                                style={{
                                    position: 'relative',
                                    display: 'inline-block',
                                    color: '#DD6A57',
                                    fontSize: '20px'
                                }}
                            />

                            <p
                                style={{
                                    position: 'relative',
                                    display: 'inline-block',
                                    color: '#DD6A57',
                                    top: '-4px',
                                    left: '8px',
                                    margin: 0,
                                    fontSize: '14px',
                                    fontWeight: '400'
                                }}
                            >
                                Sterge
                            </p>

                        </div>

                    </div>

                </BrowserView>
            

                <MobileView
                    style={{
                        display: !this.props.isDesktop ? 'block' : 'none'
                    }}
                >

                    <div
                        style={{
                            position: 'relative'
                        }} 
                    >

                        <MServiceNav 
                            icon={<MdSupervisorAccount />}
                            title={this.state.nume.length > 0 ? this.state.nume : 'Membru nou'}
                            path={'/ca/dashboard/cab/echipa'}
                        />

                        {
                            this.state.canSave &&
                            <GoCheck
                                className="m-add-service"
                                onClick={this.onAddMember}
                            />
                        }

                    </div>

                    <div 
                        style={{
                            padding: 20
                        }}
                    >

                        <Avatar 
                            avatarOuterStyle={{
                                width: '120px',
                                height: '120px',
                                border: '8px solid #fff'
                            }}

                            uploaderStyle={{

                                top: 75,
                                right: 40,
                                width: '36px',
                                height: '36px',
                                background: '#fff'
                            }}

                            cameraStyle={{

                                fontSize: '22px'
                            }}

                            hideSize = {true}

                            avatarSrc = {AvatarImg}
                        />

                        <br />

                        <div className="m-edit-s-card">

                            
                            <div className="form-control">

                                <AuthInput     
                                    label = 'Nume si Prenume'
                                    type = "text"
                                    value = {this.state.nume}
                                    name = "nume"
                                    onChange = {this.onChangeInput}
                                    iserror={this.state.numeErr}
                                    errormsg={this.state.numeErrTx}
                                    verified={this.state.numeValid}
                                    onFocus = {
                                        () => {

                                            this.setState({
                                                numeErrTx: null
                                            })
                                        }
                                    }
                                />

                            </div>


                            <div 
                                className="form-control"
                                style={{
                                    marginTop: '20px'
                                }}
                            >

                                <AuthInput 
                                    label = 'Functie'
                                    type = "text"
                                    value = {this.state.functie}
                                    name = "functie"
                                    onChange = {this.onChangeInput}
                                    iserror={this.state.functieErr}
                                    errormsg={this.state.functieErrTx}
                                    verified={this.state.functieValid}
                                    onFocus = {
                                        () => {

                                            this.setState({
                                                functieErrTx: null
                                            })
                                        }
                                    }
                                />

                            </div>

                            <div 
                                className="form-control"
                                style={{
                                    marginTop: '20px'
                                }}
                            >

                                <AuthInput 
                                    label = 'Adresa Email'
                                    type = "email"
                                    value = {this.state.email}
                                    name = "email"
                                    onChange = {this.onChangeInput}
                                    iserror={this.state.emailErr}
                                    errormsg={this.state.emailErrTx}
                                    verified={this.state.emailValid}
                                    onFocus = {
                                        () => {

                                            this.setState({
                                                emailErrTx: null
                                            })
                                        }
                                    }
                                />

                            </div>

                            <div 
                                className="form-control"
                                style={{
                                    marginTop: '20px'
                                }}
                            >

                                <AuthInput 
                                    label = 'Numar de telefon'
                                    type = "text"
                                    value = {this.state.telefon}
                                    name = "telefon"
                                    onChange = {this.onChangeInput}
                                    iserror={this.state.telefonErr}
                                    errormsg={this.state.telefonErrTx}
                                    verified={this.state.telefonValid}
                                    onFocus = {
                                        () => {

                                            this.setState({
                                                telefonErrTx: null
                                            })
                                        }
                                    }
                                />

                            </div>

                            {
                                this.state.tipAbonament === 'premium' ? (
                                    <>
                                        <div 
                                            className="form-control"
                                            style={{
                                                marginTop: '20px'
                                            }}
                                        >

                                            <AuthInput 
                                                label = 'Link'
                                                type = "text"
                                                value = {this.state.link}
                                                name = "link"
                                                onChange = {this.onChangeInput}
                                                iserror={this.state.linkErr}
                                                errormsg={this.state.linkErrTx}
                                                verified={this.state.linkValid}
                                                onFocus = {
                                                    () => {
                    
                                                        this.setState({
                                                            linkErrTx: null
                                                        })
                                                    }
                                                }
                                            />

                                        </div>

                                        <LinkPreview 
                                            accountLink = 'foxstat'
                                            link = {this.state.link}
                                            enableLinks = {!this.state.canSave && this.props?.match?.params?.userWrkToken}
                                            fontSize = '16px'
                                            fontSizeCommands = '16px'
                                            onCopyLinkToClipBoard = {this.onCopyLinkToClipBoard}
                                            onClickViewPage = {this.onClickViewPage}
                                            onShowToastSocial = {this.onShowToastSocial}
                                        />
                                    </>
                                ) : null
                            }

                            <br />

                            <div
                                style={{
                                    width: '100%',
                                    height: '1px',
                                    background: '#EBEBEB', 
                                }}
                            ></div>

                            <br />

                            <div 
                                style={{
                                    textAlign: 'left'
                                }}
                            >

                                <FaBook 
                                    style={{
                                        position: 'relative',
                                        display: 'inline-block',
                                        color: '#FFC727',
                                        fontSize: '20px'
                                    }}
                                />

                                <p
                                    style={{
                                        position: 'relative',
                                        display: 'inline-block',
                                        top: '-4px',
                                        left: '8px',
                                        margin: 0,
                                        fontSize: '14px',
                                        fontWeight: '500'
                                    }}
                                >
                                    Program
                                </p>

                            </div>

                            <br />

                            <div 
                                className="form-control"
                                style={{
                                    marginTop: 10
                                }}
                            >

                                <SelectOthers
                                    keyName = 'interval-program-'
                                    label='Program'
                                    list={this.state.programe.map(pEl => {

                                        return {
                                            val: pEl['token'],
                                            tx: pEl['name']
                                        }
                                    })
                                    
                                    }
                                    // programCurent={this.state.programTk}
                                    onChange={this.onChangeProgram}
                                    tipCurent={this.state.program || this.state.programe[0]?.token}
                                />

                                <DisplaySchedule 
                                    programAfisat = {this.state.programAfisat}
                                />

                            </div>

                            <br />

                            <div
                                style={{
                                    width: '100%',
                                    height: '1px',
                                    background: '#EBEBEB', 
                                }}
                            ></div>

                            <br />

                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >

                                <div 
                                    style={{
                                        width: '50%',
                                        textAlign: 'left'
                                    }}
                                >

                                    <PublicOff 
                                        style={{
                                            position: 'relative',
                                            display: 'inline-block',
                                        }}
                                    />

                                    <p
                                        style={{
                                            position: 'relative',
                                            display: 'inline-block',
                                            color: '#263238',
                                            top: '-4px',
                                            left: '8px',
                                            margin: 0,
                                            fontSize: '14px',
                                            fontWeight: '400'
                                        }}
                                    >
                                        Blocheaza
                                    </p>

                                </div>

                                <div 
                                    style={{
                                        width: '50%',
                                        textAlign: 'right',
                                        paddingRight: '16px'
                                    }}
                                >

                                    <MdDeleteForever 
                                        style={{
                                            position: 'relative',
                                            display: 'inline-block',
                                            color: '#DD6A57',
                                            fontSize: '20px'
                                        }}
                                    />

                                    <p
                                        style={{
                                            position: 'relative',
                                            display: 'inline-block',
                                            color: '#DD6A57',
                                            top: '-4px',
                                            left: '8px',
                                            margin: 0,
                                            fontSize: '14px',
                                            fontWeight: '400'
                                        }}
                                    >
                                        Sterge
                                    </p>

                                </div>

                            </div>

                        </div>

                    </div>

                </MobileView>

            </>
        );
    }
}