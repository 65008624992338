import React from 'react';

export default class ConfigSwitch extends React.Component{

    constructor(props){

        super(props)
    }

    render(){

        return(

            <div className="row">

                <div className="col-l-6 col-m-6 col-s-6">

                    <p
                        style={{
                            fontSize: '14px',
                            color: '#455A64',
                            textAlign: 'left',
                        }}
                    >
                        {this.props.title}
                    </p>

                </div>

                <div className="col-l-6 col-m-6 col-s-6">

                    <div 
                        className="form-control"
                        style={{
                            float: 'right'
                        }}
                    >

                        <div className="dsk-switch">
                            <input 
                                type="checkbox" 
                                id={this.props.id} 
                                checked={this.props.checked}
                                onChange={this.props.onChange}
                            />
                            <label for={this.props.for}></label>
                        </div>

                    </div>

                </div>

            </div>
        )
    }
}