import React from 'react';

import Masks from '../../providers/Masks';
import Utils from '../../providers/Utils';
import Ws from '../../providers/Ws';

import BusinessImage from '../../assets/Businesswoman-cuate 1.png';
import ErrorImg from '../../assets/Feeling sorry-cuate 1.png';

export default class ReplanAppointments extends React.Component{

    constructor(props){

        super(props);

        //if5w87XJw1801ox1mlwHsD9FgBCqLHfm

        this.state = {

            redirectTx: 'Redirectionare reprogramare...',
            isError: false
        }
    }

    WS = new Ws();
    UT = new Utils();
    M = new Masks();

    componentDidMount(){

        this.onRedirectReschedule();
    }

    onRedirectReschedule = async () => {

        const tranToken = this.props.match.params.tranToken;
        const newTranToken = this.UT.generateToken();

        let selectAppointQuery = `select * from trc where token = '${tranToken}'   `;
        let currentAppoint = await this.WS.sqlCommand(`select`, selectAppointQuery);

        if(currentAppoint.success){

            if( ( +currentAppoint.content[0]['anulat'] || 0) === 1){

                this.setState({

                    redirectTx: 'Intalnirea a fost deja reprogamata.',
                    isError: true
                });

                return;
            }

            if( ( currentAppoint.content[0]['clie_nume'] || '').length < 1){

                this.setState({

                    redirectTx: 'Datele dvs. sunt necompletate',
                    isError: true
                });

                return;
            }
        }
        else{

            this.setState({

                redirectTx: 'A aparut o eroare in timpul redirectionarii catre reprogramare!',
                isError: true
            })
            throw new Error("Error replanning appointment: " + currentAppoint.message );   
        }


        let cancelAppointQuery = `update trc set anulat = 1, cand_anulat = NOW() where token = '${tranToken}'  `;
        
        let replanAppointQuery = ` insert into trc(token, account_tk, service_tk, service_name, service_description, 
            duration, location, price, data_cal, ora_cal, clie_nume, clie_prenume, clie_email, clie_tf, 
            clie_detalii, cand_creat, src, tip, is_from_replan, replaned_trc_tk, user_asignat_token, draft)

            select '${newTranToken}', account_tk, service_tk, service_name, service_description, 
            duration, location, price, data_cal, ora_cal, clie_nume, clie_prenume, clie_email, clie_tf, 
            clie_detalii, cand_creat, src, tip, 1, '${tranToken}', user_asignat_token, 1  

            from trc where token='${tranToken}' `;


        let _cancelAppoint = this.WS.sqlCommand(`update`, cancelAppointQuery);
        let _replanAppoint = this.WS.sqlCommand(`insert`, replanAppointQuery);

        let [replanAppoint, cancelAppoint] = await Promise.all( [_replanAppoint, _cancelAppoint] );
        
        if(cancelAppoint.success && replanAppoint.success){

            if(currentAppoint.content.length === 0){

                this.setState({

                    redirectTx: 'Eroare server.',
                    isError: true
                });

                return;
            }

            this.props.history.push('/calendar/edit/' + newTranToken);
            
        }
        else{

            this.setState({

                redirectTx: 'A aparut o eroare in timpul redirectionarii catre reprogramare!',
                isError: true
            })
            throw new Error("Error replanning appointment: " + cancelAppoint.message + "\n" + replanAppoint.message);   
        }
    }

    render(){

        return(

            <div>

                <div
                    style={{
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '100vh'
                    }}
                >
    
                    <div
                        style={{
                            
                            margin: 'auto',
                            padding: 20,
                            width: '600px',
                            background: '#fff',
                            borderRadius: '10px',
                            boxShadow: '0px 4px 25px rgba(0, 0, 0, 0.25)'
                        }}
                    >

                        {
                            !this.state.isError && (

                                <img 
                                    src={BusinessImage}
                                    alt="Business Image"
                                    style={{
                                        display: 'block',
                                        margin: '0 auto'
                                    }}
                                />
                            )
                        }

                        {
                            this.state.isError && (
                                <img 
                                    src={ErrorImg}
                                    alt="Business Image"
                                    style={{
                                        display: 'block',
                                        margin: '0 auto'
                                    }}
                                />

                            )
                        }

                        <p
                            style={{
                                margin: 0,
                                color: '#455A64',
                                fontSize: '18px',
                                fontWeight: 500
                            }}
                        >
                           {this.state.redirectTx}
                        </p>
                        
                        <br />
                        
                    </div>

                </div>

            </div>
        )
    }
}