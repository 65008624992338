import React from "react";

import { Switch, Route, HashRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history'
import LocalStorage from '../providers/LocalStorage';


import Home from "../module/Home";
import Login from "../module/login/Login";
import Svs from "../module/dashboard/Svs";
import Dashboard from "../module/dashboard/Dashboard";
import Serviciu from "../module/dashboard/Serviciu";
import TestRes from "../module/dashboard/TestRes";
import Welcome from "../module/login/Welcome";
import MDetaliiServiciu from "../module/dashboard/mobile/MDetaliiServiciu";
import MDenumServiciu from "../module/dashboard/mobile/MDenumServiciu";
import MEditLocatie from "../module/dashboard/mobile/MEditLocatie";
import MDurata from "../module/dashboard/mobile/MDurata";
import MTarif from "../module/dashboard/mobile/MTarif";
import MDescriere from "../module/dashboard/mobile/MDescriere";
import MCuloare from "../module/dashboard/mobile/MCuloare";
import MDisponibilitate from "../module/dashboard/mobile/MDisponibilitate";
import MProgram from "../module/dashboard/mobile/MProgram";
import MPauze from "../module/dashboard/mobile/MPauze";
import MRecall from "../module/dashboard/mobile/MRecall";
import MReviewMail from "../module/dashboard/mobile/MReviewMail";
import MPolitica from "../module/dashboard/mobile/MPolitica";
import SelectServices from "../module/client/SelectServices";
import ClientAppointment from "../module/client/ClientAppointment";
import CalendarClient from "../module/client/CalendarClient";
import ClientDataReg from "../module/client/ClientDataReg";
import SumarProgramare from "../module/client/SumarProgramare";
import ProgramareFinalizata from "../module/client/ProgramareFinalizata";
import ServiciuIndisponibil from "../module/client/ServiciuIndisponibil";
import RutaIncompleta from "../module/client/RutaIncompleta";
import MClientServiceInfo from "../module/client/MClientServiceInfo";
import PopUpPlata from "../module/client/PopUpPlata";
import CheckTransactionPayment from "../module/client/CheckTransactionPayment";
import IntegrationsErrors from "../module/client/IntegrationsErrors";
import RedirectToDashboard from "../module/dashboard/RedirectToDashboard";
import MUserMenu from "../commons/MUserMenu";
import MNotifications from "../commons/notifications/MNotifications";
import RedirectToSuccesAppoint from "../module/client/RedirectToSuccesAppoint";
import SecretContact from "../module/dashboard/SecretContact";
import MProfil from "../module/dashboard/mobile/MProfil";
import AcceptInvite from "../module/client/AcceptInvite";
import TestInv from "../module/client/TestInv";
import TestPauza from "../module/dashboard/TestPauza";
import MCrAppoMain from "../module/dashboard/mobile/create_appointment/MCrAppoMain";
import MCrAppoAddBreak from "../module/dashboard/mobile/create_appointment/MCrAppoAddBreak";
import MCrAppoCalendar from "../module/dashboard/mobile/create_appointment/MCrAppoCalendar";
import MCrappoAddBreakSuccess from "../module/dashboard/mobile/create_appointment/MCrappoAddBreakSuccess";
import MCrAppoCreate from "../module/dashboard/mobile/create_appointment/MCrAppoCreate";
import MCrAppoCreateSuccess from "../module/dashboard/mobile/create_appointment/MCrAppoCreateSuccess";
import CalendarClientEdit from "../module/client/CalendarClientEdit";
import Calendar from "../module/dashboard/Calendar";
import ReplanAppointments from "../module/client/ReplanAppointments";
import CancelAppoint from "../module/client/CancelAppoint";
import MServiceSchedule from "../module/dashboard/mobile/schedule_manager/MServiceSchedule";
import MServiceScheduleCreate from "../module/dashboard/mobile/schedule_manager/MServiceScheduleCreate";
import AppointmentsHistory from "../module/dashboard/AppointmentsHistory";
import TermsAndConditions from "../module/client/TermsAndConditions";
import Integrari from "../module/dashboard/Integrari";
import Echipa from "../module/dashboard/team/Echipa";
import MembruEchipa from "../module/dashboard/team/MembruEchipa";
import Subscriptions from "../module/dashboard/subscriptions/Subscriptions";
import AccSubscription from "../module/dashboard/subscriptions/AccSubscription";
import PaymentHistory from "../module/dashboard/PaymentHistory";
import Security from "../module/dashboard/Security";
import ZoomAuthentication from "../module/dashboard/ZoomAuthentication";
import GCalTest from "../module/dashboard/GCalTest";
import Account from "../module/register/desktop/Account";
import Company from "../module/register/desktop/Company";
import EmailValidation from "../module/register/EmailValidation";
import AccountLink from "../module/register/desktop/AccountLink";
import EmailSuccessValidation from "../module/register/EmailSuccessValidation";
import CompanyAddress from "../module/register/desktop/CompanyAddress";
import AccAvatar from "../module/register/desktop/AccAvatar";
import FirstSchedule from "../module/register/desktop/FirstSchedule";
import AccIntegrLoc from "../module/register/desktop/AccIntegrLoc";
import AccIntegrPay from "../module/register/desktop/AccIntegrPay";
import AccountDataCompleted from "../module/register/AccountDataCompleted";
import LocConfig from "../module/register/desktop/LocConfig";
import SvInfoConfig from "../module/register/desktop/SvInfoConfig";
import DispoConfig from "../module/register/desktop/DispoConfig";
import ColorConfig from "../module/register/desktop/ColorConfig";
import SvFinalConfig from "../module/register/desktop/SvFinalConfig";
import AuthFinal from "../module/register/AuthFinal";
import MStartWizard from "../module/register/mobile/MStartWizard";
import MAccountData from "../module/register/mobile/MAccountData";
import Statistics from "../module/dashboard/statistics/Statistics";
import StatisticsMember from "../module/dashboard/statistics/StatisticsMember";
import history from "./history";
import Ws from "../providers/Ws";
import Utils from "../providers/Utils";
import DurationConfig from "../module/register/desktop/DurationConfig";
import MServiceNameConfig from "../module/register/mobile/MServiceNameConfig";
import Masks from "../providers/Masks";
import ErrorMessage from "../commons/ErrorMessage";
import ButtonForm from "../commons/ButtonForm";
import ZoomIntegrationDev from "../module/dashboard/ZoomIntegrationDev";
import TestAbonamente from "../module/dashboard/TestAbonamente";

// const history = createBrowserHistory();

export default class Routes extends React.Component{

    constructor(props){

        super(props);
        this.state = { 
            esteLogat: false,
            isWizard: false,
            emailValidat: false,
            expirat: false,
            mesajExpirare: '',
        };
    }

    WS = new Ws();
    U = new Utils();
    M = new Masks();

    componentDidMount() {

        let ud = new LocalStorage().GetUserData()
        

        if (ud){
            
            this.setState({ 
                esteLogat: true 
            },
                () => {

                    this.onGetEmailValidatResponse();
                    this.onGetIfAccountIsCompleted();
                    this.onCheckAbonamentTypeAndLimit();
                    this.onSetUserSettings();
                    
                }
            );
            
            // 
        }     
        else{

            this.setState({ esteLogat: false })
        }
    }

    onSetUserSettings = () => {

        const localStorageSettings = JSON.parse(localStorage.getItem("userSettings"));

        if(!localStorageSettings || localStorageSettings === null){

            const userSettings = {

                newAppointmentNotifications: true,
                rescheduleNotifications: true,
                cancelNotifications: true
            };
    
            localStorage.setItem("userSettings", JSON.stringify(userSettings));
        }
    }

    onCheckAbonamentTypeAndLimit = async () => {

        const accountToken = new LocalStorage().GetAccountToken();
        const qUd = `select * from accounts where token = '${accountToken}' `;
        let _ud = this.WS.sqlCommand(`select`, qUd);

        const qUsers = `select * from users where account_id = '${accountToken}' `;
        let _users = this.WS.sqlCommand(`select`, qUsers);

        let [ ud, users ] = await Promise.all( [_ud, _users] );

        

        const dataCreare = this.M.sqlDateToJSDate(ud['content'][0]['cand_creat']);
        let dataCurenta = new Date();
        dataCurenta.setHours(0, 0, 0, 0);

        let dataExpirare = this.M.addDaysToDate(dataCreare, 30);

        const abonament = ud['content'][0]['abonament'];
        const userToken = new LocalStorage().GetUserData().userData[0]['token'];

        if(abonament === null || abonament === 'gratuit'){

            if(dataExpirare.getTime() < dataCurenta.getTime() ){ //este semnul "<"

                this.setState({

                    expirat: true,
                    mesajExpirare: 'Abonamentul gratuit a expirat! Pentru a continua upgradati contul gratuit la unul dintre cele 3 abonamente Planary.'
                });
            }
        }
        
        if(abonament === 'anteprenor' && userToken !== users.content[0].token){

            this.setState({

                expirat: true,
                mesajExpirare: 'Nu va puteti loga pe acest cont de utilizator cu abonamentul Antreprenor. Pentru mai multe detalii vedeti beneficiile abonamentului Premium.'
            });
        }

        // this.setState({

        //     isLoading: false
        // });
    }
    
    onGetEmailValidatResponse = async () => {

        let isEmailValidat = await this.U.onCheckIfUserHasWrkToken();

        if(isEmailValidat.success){

            this.setState({ emailValidat: true })
        }
        else{

            this.setState({ emailValidat: false })
        }
    }

    onGetIfAccountIsCompleted = async () => {

        let isAccountComplete = await this.U.onCheckIfUserHasCompleteAccountData();
        let isFirstService = await this.U.onCheckIfHasFirstService();

        if(isAccountComplete.success && isFirstService.success){

            this.setState({ isWizard: false })
        }
        else{

            this.setState({ isWizard: true })
        }

        // 
    }

    onLogoutUser = () => {

        localStorage.clear();
        window.location.href = '/';    
    }


    render(){


        return(

            <div>
                {
                    this.state.esteLogat && this.state.expirat ? (

                            <div className="wrapper-flex">
                                <ErrorMessage 
                                    eroareTx = {this.state.mesajExpirare}
                                />

                                <ButtonForm 
                                    className="client-schedule-btn"
                                    tx = "Actualizeaza abonament"
                                    style={{
                                        marginTop: 20,
                                        width: '240px'
                                    }}
                                    onClick = {() => {

                                        window.location.href = "https://planary.ro/abonamente/"
                                    }}
                                />

                                <ButtonForm 
                                    className="client-schedule-btn"
                                    tx = "Iesire cont"
                                    style={{
                                        marginTop: 10,
                                        width: '240px',
                                        background: '#DD6A57'
                                    }}
                                    onClick = {this.onLogoutUser}
                                />
                            </div>
                    ) : (

                        <HashRouter>
                    
                        <Switch>
    
                            {/*
                            //////////////////////////////////////////
                            
                                        Dashboard Routes
    
                            ////////////////////////////////////////
                            */}
    
                            { 
                                !this.state.esteLogat && 
                                <Route path="/" exact component={Welcome} />
                            }
    
                            { 
                                !this.state.esteLogat && 
                                <Route path="/login" component={Login} />
                            }
    
                            { 
                                this.state.esteLogat && 
                                <Route path="/" exact component={RedirectToDashboard}/>
                            }    
    
                            {
                                //ca
                                //cab/:flag/ca
                                //cab/dashboard/ca
                                this.state.esteLogat && !this.state.isWizard && this.state.emailValidat &&
                                <Route exact path="/ca/dashboard/cab" component={Dashboard} history={history} />
                            }  
    
                            {
                                this.state.esteLogat && !this.state.isWizard && this.state.emailValidat &&
                                <Route exact path="/ca/:trc/open" component={Dashboard} history={history} />
                            }   
    
                            {
    
                                this.state.esteLogat && !this.state.isWizard && this.state.emailValidat &&
                                <Route exact path="/secret-contact" component={SecretContact} history={history} />
                            }   
    
                            {
    
                                this.state.esteLogat && !this.state.isWizard && this.state.emailValidat &&
                                <Route exact path="/svs/test/test" component={Svs} history={history} />
                            }
    
    
                            {
                                //Original paths for mobile => ca/:flag
                            }

                            {   
                                this.state.esteLogat && !this.state.isWizard && this.state.emailValidat &&
                                <Route exact path="/ca/dashboard/cab/test-abonamente" component={TestAbonamente} history={history} />
                            }

                            {   
                                this.state.esteLogat && !this.state.isWizard && this.state.emailValidat &&
                                <Route exact path="/ca/dashboard/cab/meniu" component={MUserMenu} history={history} />
                            }
    
                            {
    
                                this.state.esteLogat && !this.state.isWizard && this.state.emailValidat &&
                                <Route exact path="/ca/dashboard/cab/profil" component={MProfil} history={history} />
                            }
    
                            {
    
                                this.state.esteLogat && !this.state.isWizard && this.state.emailValidat &&
                                <Route exact path="/ca/dashboard/cab/notificari" component={MNotifications} history={history} />
                            }
    
                            {
    
                                this.state.esteLogat && !this.state.isWizard && this.state.emailValidat &&
                                <Route exact path="/ca/dashboard/cab/sv/:tk" component={Serviciu} history={history} />
                            }
    
                            {
                                this.state.esteLogat && !this.state.isWizard && this.state.emailValidat &&
                                <Route exact path="/ca/dashboard/cab/msv/:tk" component={MDetaliiServiciu} history={history}/>
                            }
    
                            {
                                this.state.esteLogat && !this.state.isWizard && this.state.emailValidat &&
                                <Route exact path="/ca/dashboard/cab/msv/denumire-serviciu/:tk" component={MDenumServiciu} history={history}/>
                            }
    
                            {   
                                this.state.esteLogat && !this.state.isWizard && this.state.emailValidat &&
                                <Route exact path="/ca/dashboard/cab/msv/locatie-serviciu/:tk" component={MEditLocatie} history={history}/>
                            }
    
                            {   
                                this.state.esteLogat && !this.state.isWizard && this.state.emailValidat &&
                                <Route exact path="/ca/dashboard/cab/msv/durata-serviciu/:tk" component={MDurata} history={history}/>
                            }
    
                            {   
                                this.state.esteLogat && !this.state.isWizard && this.state.emailValidat &&
                                <Route exact path="/ca/dashboard/cab/msv/tarif-serviciu/:tk" component={MTarif} history={history}/>
                            }
    
                            {   
                                this.state.esteLogat && !this.state.isWizard && this.state.emailValidat &&
                                <Route exact path="/ca/dashboard/cab/msv/descriere-serviciu/:tk" component={MDescriere} history={history}/>
                            }
    
                            {   
                                this.state.esteLogat && !this.state.isWizard && this.state.emailValidat &&
                                <Route exact path="/ca/dashboard/cab/msv/culoare-calendar/:tk" component={MCuloare} history={history}/>
                            }
    
                            {
                                this.state.esteLogat && !this.state.isWizard && this.state.emailValidat &&
                                <Route exact path="/ca/dashboard/cab/msv/disponibilitate-serviciu/:tk" component={MDisponibilitate} history={history}/>
                            }
    
                            {
                                this.state.esteLogat && !this.state.isWizard && this.state.emailValidat &&
                                <Route exact path="/ca/dashboard/cab/msv/program-serviciu/:tk" component={MProgram} history={history}/>
                            }
    
                            {
                                this.state.esteLogat && !this.state.isWizard && this.state.emailValidat &&
                                <Route exact path="/ca/dashboard/cab/msv/pauze-serviciu/:tk" component={MPauze} history={history}/>
                            }
    
                            {
                                this.state.esteLogat && !this.state.isWizard && this.state.emailValidat &&
                                <Route exact path="/ca/dashboard/cab/msv/recall/:tk" component={MRecall} history={history}/>
                            }
    
                            {
                                this.state.esteLogat && !this.state.isWizard && this.state.emailValidat &&
                                <Route exact path="/ca/dashboard/cab/msv/cerere-review/:tk" component={MReviewMail} history={history}/>
                            }
    
                            {
                                this.state.esteLogat && !this.state.isWizard && this.state.emailValidat &&
                                <Route exact path="/ca/dashboard/cab/msv/politica-anulare/:tk" component={MPolitica} history={history}/>
                            }
    
                            {
                                this.state.esteLogat && !this.state.isWizard && this.state.emailValidat &&
                                <Route exact path="/ca/dashboard/cab/creare-programare/:intervalDate" component={MCrAppoMain} history={history}/>
                            }
    
                            {
                                this.state.esteLogat && !this.state.isWizard && this.state.emailValidat &&
                                <Route exact path="/ca/dashboard/cab/adauga-pauza/:intervalDate" component={MCrAppoAddBreak} history={history}/>
                            }
    
                            {
                                this.state.esteLogat && !this.state.isWizard && this.state.emailValidat &&
                                <Route exact path="/ca/dashboard/cab/adauga-in-calendar/:svLink/:intervalDate" component={MCrAppoCalendar} history={history}/>
                            }
                            
                            {
                                this.state.esteLogat && !this.state.isWizard && this.state.emailValidat &&
                                <Route exact path="/ca/dashboard/cab/adauga-pauza/succes/:tranToken" component={MCrappoAddBreakSuccess} history={history}/>
                            }
    
                            {
                                this.state.esteLogat && !this.state.isWizard && this.state.emailValidat &&
                                <Route exact path="/ca/dashboard/cab/adauga-programare/:tranToken" component={MCrAppoCreate} history={history}/>
                            }
    
                            {
                                this.state.esteLogat && !this.state.isWizard && this.state.emailValidat &&
                                <Route exact path="/ca/dashboard/cab/adauga-programare/succes/:tranToken" component={MCrAppoCreateSuccess} history={history}/>
                            }
    
                            {
                                this.state.esteLogat && !this.state.isWizard && this.state.emailValidat &&
                                <Route exact path="/ca/dashboard/cab/programe" component={MServiceSchedule} history={history}/>
                            }
    
                            {
                                this.state.esteLogat && !this.state.isWizard && this.state.emailValidat &&
                                <Route exact path="/ca/dashboard/cab/program/:scheduleTk" component={MServiceScheduleCreate} history={history}/>
                            }
    
                            {
                                this.state.esteLogat && !this.state.isWizard && this.state.emailValidat &&
                                <Route exact path="/ca/dashboard/cab/istoric-programari" component={AppointmentsHistory} history={history}/>
                            }
    
                            {
                                this.state.esteLogat && !this.state.isWizard && this.state.emailValidat &&
                                <Route exact path="/ca/dashboard/cab/integrari" component={Integrari} history={history}/>
                            }
    
                            {
                                this.state.esteLogat && !this.state.isWizard && this.state.emailValidat &&
                                <Route exact path="/ca/dashboard/cab/autentificare-zoom" component={ZoomAuthentication} history={history}/>
                            }
    
                            {
                                this.state.esteLogat && !this.state.isWizard && this.state.emailValidat &&
                                <Route exact path="/ca/dashboard/cab/echipa" component={Echipa} history={history}/>
                            }
    
                            {
                                this.state.esteLogat && !this.state.isWizard && this.state.emailValidat &&
                                <Route exact path="/ca/dashboard/cab/echipa/membru/:userWrkToken" component={MembruEchipa} history={history}/>
                            }
    
                            {
                                this.state.esteLogat && !this.state.isWizard && this.state.emailValidat &&
                                <Route exact path="/ca/dashboard/cab/abonamente" component={Subscriptions} history={history}/>
                            }
    
                            {
                                this.state.esteLogat && !this.state.isWizard && this.state.emailValidat &&
                                <Route exact path="/ca/dashboard/cab/abonamentul-meu/:type" component={AccSubscription} history={history}/>
                            }
    
                            {
                                this.state.esteLogat && !this.state.isWizard && this.state.emailValidat &&
                                <Route exact path="/ca/dashboard/cab/istoric-plati" component={PaymentHistory} history={history}/>
                            }
    
                            {
                                this.state.esteLogat && !this.state.isWizard && this.state.emailValidat &&
                                <Route exact path="/ca/dashboard/cab/securitate" component={Security} history={history}/>
                            }
    
                            { 
                                this.state.esteLogat && !this.state.isWizard && this.state.emailValidat &&
                                <Route exact path="/ca/dashboard/cab/rapoarte-si-analiza" component={Statistics} history={history}/>
                            }
    
                            {
                                this.state.esteLogat && !this.state.isWizard && this.state.emailValidat &&
                                <Route exact path="/ca/dashboard/cab/rapoarte-si-analiza/:member" component={StatisticsMember} history={history}/>
                            }

                            {
                                this.state.esteLogat && !this.state.isWizard && this.state.emailValidat &&
                                <Route exact path="/ca/dashboard/cab/zoom-integration-dev" component={ZoomIntegrationDev} history={history}/>
                            }

{
                                this.state.esteLogat && !this.state.isWizard && this.state.emailValidat &&
                                <Route exact path="/ca/dashboard/cab/zoom-integration" component={ZoomIntegrationDev} history={history}/>
                            }
                            
                            {/*
                            //////////////////////////////////////////
                            
                                        Client Routes
    
                            ////////////////////////////////////////
                            */}
    
                            {
                                this.state.esteLogat && !this.state.isWizard && this.state.emailValidat &&
                                <Route exact path="/test-ore" component={ClientAppointment} history={history}/>
                            }
                          
                            <Route exact path="/:company_web_link" component={SelectServices} history={history}/>

                            <Route exact path="/:company_web_link/:memberLink" component={SelectServices} history={history}/>
                            
                            <Route exact path="/:company_web_link/:service_web_link" component={RutaIncompleta} history={history}/>
                            
                            <Route exact path="/:company_web_link/:service_web_link/calendar" component={CalendarClient} history={history}/>

                            <Route exact path="/:company_web_link/:memberLink/:service_web_link/calendar" component={CalendarClient} history={history}/>
                         
                            {/* <Route exact path="/trc/:tranToken/" component={ClientDataReg} history={history}/> */}
                            <Route exact path="/trc/:tranToken/datele-mele" component={ClientDataReg} history={history}/>
                         
                            <Route exact path="/trc/:tranToken/finalizare" component={SumarProgramare} history={history}/>
                            
                            <Route exact path="/trc/:tranToken/cfp" component={PopUpPlata} history={history}/>
                            
                            <Route exact path="/trc/:tranToken/cptr" component={CheckTransactionPayment} history={history}/>
    
                            <Route exact path="/trc/:tranToken/gmeet-succes" component={RedirectToSuccesAppoint} history={history}/>
                            
                            <Route exact path="/trc/:tranToken/finalizare-succes" component={ProgramareFinalizata} history={history}/>

                            <Route exact path="/trc/:memberLink/:tranToken/datele-mele" component={ClientDataReg} history={history}/>
                         
                            <Route exact path="/trc/:memberLink/:tranToken/finalizare" component={SumarProgramare} history={history}/>
                            
                            <Route exact path="/trc/:memberLink/:tranToken/cfp" component={PopUpPlata} history={history}/>
                            
                            <Route exact path="/trc/:memberLink/:tranToken/cptr" component={CheckTransactionPayment} history={history}/>
    
                            <Route exact path="/trc/:memberLink/:tranToken/gmeet-succes" component={RedirectToSuccesAppoint} history={history}/>
                            
                            <Route exact path="/trc/:memberLink/:tranToken/finalizare-succes" component={ProgramareFinalizata} history={history}/>
                            
                            <Route exact path="/:company_web_link/:service_web_link/indisponibil" component={ServiciuIndisponibil} history={history}/>
    
                            <Route exact path="/rer/:tip/:tranToken" component={IntegrationsErrors} history={history}/>
    
                            {/* <Route exact path="/invite/acc/:tranToken" component={AcceptInvite} history={history}/> */}
    
                            <Route exact path="/invite/acc/:tranToken" component={ClientDataReg} history={history}/>
    
                            <Route exact path="/calendar/edit/:tranToken" component={CalendarClientEdit} history={history}/>
    
                            <Route exact path="/replan/appointment/:tranToken" component={ReplanAppointments} history={history}/>
    
                            <Route exact path="/cancel/appointment/:tranToken" component={CancelAppoint} history={history}/>
    
                            <Route exact path="/:companyWebLink/:serviceWebLink/termeni-si-conditii" component={TermsAndConditions} history={history}/>
    
                            <Route exact path="/test-inv/acc/ca" component={TestInv} history={history}/> 
                                  
                            <Route exact path="/test-pauza/acc/ca" component={TestPauza} history={history}/>   
    
                            <Route exact path="/test/more/gcal-test/" component={GCalTest} history={history}/>  
    
                             {/* <Route exact path="/vd/zoom" component={ZoomJoin} history={history}/> */}
    
                            {/*
                            //////////////////////////////////////////
                            
                                        Register Routes
    
                            ////////////////////////////////////////
                            */}
    
    
                            {
                                !this.state.esteLogat && (
    
                                    <>
    
                                        <Route exact path="/inregistrare/date/cont" component={Account} history={history}/>
    
                                        <Route exact path="/inregistrare/cont/succes-validare-email/:userToken" component={EmailSuccessValidation} history={history}/>
                                        
                                    </>
                                )
                            }
    
                            {/* 
                            {
                                this.state.esteLogat && !this.state.emailValidat && !this.state.isWizard &&  (
    
                                    <Route exact path="/inregistrare/cont/validare-email/:userToken" component={EmailValidation} history={history}/>
                                )
                            } */}
    
    
                            {
                                this.state.esteLogat && this.state.isWizard && this.state.emailValidat && (
    
                                    <>
    
                                         {/* /// Desktop ////   */}
                                
                                        <Route exact path="/inregistrare/date/companie" component={Company} history={history}/>  
    
                                        <Route exact path="/inregistrare/date/companie-adresa" component={CompanyAddress} history={history}/>
    
                                        {/* /// End Desktop ////   */}  
    
                                        <Route exact path="/inregistrare/cont/succes-creare-cont" component={AccountDataCompleted} history={history}/>  
                                        
                                        <Route exact path="/inregistrare/date/identificator" component={AccountLink} history={history}/>  
    
                                        <Route exact path="/inregistrare/date/avatar" component={AccAvatar} history={history}/>
    
                                        <Route exact path="/inregistrare/serviciu/integrari-aplicatii" component={AccIntegrLoc} history={history}/>
    
                                        <Route exact path="/inregistrare/serviciu/integrari-plati" component={AccIntegrPay} history={history}/>
    
                                        <Route exact path="/inregistrare/serviciu/program" component={FirstSchedule} history={history}/>
                                        
                                        <Route exact path="/inregistrare/serviciu/configurare-serviciu" component={SvInfoConfig} history={history}/>
    
                                        <Route exact path="/inregistrare/serviciu/locatie-serviciu" component={LocConfig} history={history}/>
    
                                        <Route exact path="/inregistrare/serviciu/disponibilitate-serviciu" component={DispoConfig} history={history}/>
    
                                        <Route exact path="/inregistrare/serviciu/culoare-serviciu" component={ColorConfig} history={history}/>
    
                                        <Route exact path="/inregistrare/serviciu/durata-serviciu" component={DurationConfig} history={history}/>
    
                                        <Route exact path="/inregistrare/serviciu/finalizare-serviciu" component={SvFinalConfig} history={history}/>
    
                                        <Route exact path="/inregistrare/serviciu/serviciu-creat" component={AuthFinal} history={history}/>
    
                                        {/* /// Mobile ////   */}
    
                                        <Route exact path="/inregistrare/date/incepe" component={MStartWizard} history={history}/>
    
                                        <Route exact path="/inregistrare/date/datele-companiei" component={MAccountData} history={history}/>
    
                                        <Route exact path="/inregistrare/serviciu/denumire-serviciu/:tk" component={MServiceNameConfig} history={history}/>
    
                                        <Route exact path="/inregistrare/serviciu/locatie-serviciu/:tk" component={MEditLocatie} history={history}/>
    
                                        <Route exact path="/inregistrare/serviciu/durata-serviciu/:tk" component={MDurata} history={history}/>
    
                                        <Route exact path="/inregistrare/serviciu/descriere-serviciu/:tk" component={MDescriere} history={history}/>
    
                                        <Route exact path="/inregistrare/serviciu/culoare-serviciu/:tk" component={MCuloare} history={history}/>
    
                                        <Route exact path="/inregistrare/serviciu/disponibilitate-serviciu/:tk" component={MDisponibilitate} history={history}/>
    
                                        <Route exact path="/inregistrare/serviciu/pauze-serviciu/:tk" component={MPauze} history={history}/>
    
                                        {/* /// End Mobile ////   */}
                                    
                                    </>
                                )
                            }
    
                        </Switch>
    
                    </HashRouter>
    
                    )
                }
               
            </div>
        );
    }
}