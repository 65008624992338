import React from "react";
import EmailTemplatesDeclarations from "../../providers/EmailTemplatesDeclarations";
import LocalStorage from "../../providers/LocalStorage";
import Utils from "../../providers/Utils";
import Ws from "../../providers/Ws";



export default class TestAbonamente extends React.Component{

    constructor(props){

        super(props);
        this.state = {

        };
    }

    WS = new Ws();
    UT = new Utils();

    onSetAbonamentAntrepenor = async () => {

        let accountToken = new LocalStorage().GetAccountToken();
        let q = `update accounts set abonament = 'anteprenor' where token = '${accountToken}' `;
        let res = await this.WS.sqlCommand(`update`, q);

        if(res.success){

            alert("Abonamentul Antrepenor a fost activat!");
            window.location.href = '/';
        }
    }

    onSetAbonamentPremium= async () => {

        let accountToken = new LocalStorage().GetAccountToken();
        let q = `update accounts set abonament = 'premium' where token = '${accountToken}' `;
        let res = await this.WS.sqlCommand(`update`, q);

        const qUsers = `select * from users where account_id = '${accountToken}' `;
        let users = await this.WS.sqlCommand(`select`, qUsers);

        if(res.success){

            if(users.content.length > 1){

                //Abordare naiva, dar sunt putini users => Limita la antreprenori e de 3 per cont.. (excluzand user-ul principal (admin-ul) )
                //Pot face aceasta mini romaneasca cu un for loop si fetch la server :))
                for(let i = 1; i < users.content.length; i++){

                    if((users.content[i]['pwd_c'] || '').length < 3){

                        const pass = this.UT.generateToken();
                        const ud = new LocalStorage().GetUserData();

                        const qUpdateUser = `update users set pwd_c = '${pass}' where token = '${users.content[i].token}' `;
                        let updateUser = await this.WS.sqlCommand(`update`, qUpdateUser);

                        const emailBody = new EmailTemplatesDeclarations().onGenerateMembruCreatEmail(users.content[i].email, pass, ud.accountData[0]['display_name']);

                        let emailRes = await this.UT.onGenerateMailTemplateAndSendToEmail(
                            emailBody, users.content[i].email, `Planary - Membru nou`,
                        );
                    }
                }
            }

            alert("Abonamentul Premium a fost activat!");
            window.location.href = '/';
        }
    }

    getUsers = async () => {

        const q = `select * from users`;
        let res = await this.WS.sqlCommand(`select`, q);
    }

    render(){

        return(

            <div>
                <button
                    onClick={this.onSetAbonamentAntrepenor}
                >
                    Abonament Antrepenor
                </button>

                &nbsp;
                &nbsp;
                &nbsp;

                <button
                    onClick={this.onSetAbonamentPremium}
                >
                    Abonament Premium
                </button>

            </div>
        );
    }
}