import React from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import MServiceNav from '../../../commons/MServiceNav';
import Masks from '../../../providers/Masks';
import Utils from '../../../providers/Utils';
import Ws from '../../../providers/Ws';

import {ReactComponent as AnalyticsIcon} from '../../../assets/analytics.svg';
import DateChanger from '../../../commons/DateChanger';
import IconText from '../../../commons/IconText';
import { MdOpenInNew, MdPayment, MdPermIdentity, MdReportProblem, MdSupervisorAccount, MdTrendingDown, MdTrendingUp, MdVisibility } from 'react-icons/md';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import { Line, Pie } from 'react-chartjs-2';
import TeamMemberCard from '../../../commons/TeamMemberCard';
import { FaAngleRight } from 'react-icons/fa';
import StatMemberCard from './StatMemberCard';
import Bounce from './Bounce';
import Conversion from './Conversion';
import ClientsPay from './ClientsPay';
import Visitors from './Visitors';
import GraphStats from './GraphStats';

ChartJS.register(
        
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    // Title,
    // Tooltip,
    // Legend
);

export const options = {
    responsive: true,
    plugins: {

        datalabels: {

            display: false
        },

        legend: {
            // position: 'top' as const,
            display: false
        },

        title: {
            display: false,
            text: 'Chart.js Line Chart',
        },
      
    },

    
    scales: {
        x: {
          grid: {display:false},
          ticks: {
            display: false //this will remove only the label
          }
        },
        y: {
          grid:{display:false},
          ticks: {
            display: false //this will remove only the label
          }
        }    
    },

};

const labels = ['1 Februarie', '28 Februarie'];

export const data = {

  labels,
  datasets: [
    {
      data: [1200, 300, 600, 322, 3400],
      borderColor: '#44A5FF',
      fill: true,
      backgroundColor: 'rgb(68, 165, 255, 0.2)',
    },

  ],
};

export const pieOptions = {

    responsive: true,
    plugins: {

        datalabels: {

            display: false
        },

        legend: {
            // position: 'top' as const,
            display: false
        },

        title: {
            display: false,
            text: 'Chart.js Line Chart',
        },
      
    },

}

export const pieData = {

    labels: ['Website', 'Facebook', 'Youtube', 'Alte Surse'],

    datasets: [
        {
          label: '# of Votes',
          data: [12, 19, 3, 5],
          backgroundColor: [
            '#FFC727',
            '#52678F',
            '#F7A4A0',
            '#7BDFFF',
          ],
          borderColor: [
            '#ffffff',
            '#ffffff',
            '#ffffff',
            '#ffffff',
          ],
          borderWidth: 1,
        },
      ],
}

export default class Statistics extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            teamMembers: [

                {
                    id: '1',
                    avatar: '',
                    wrkToken: '',
                    userToken: '',
                    nume: 'Marcela Popescu',
                    totalProgramari: 4,
                    totalIncasari: 400,
                    procenteClienti: '-3.12%', 
                    selected: true
                },

                {
                    id: '2',
                    avatar: '',
                    wrkToken: '',
                    userToken: '',
                    nume: 'Cristian Ionescu',
                    totalProgramari: 6,
                    totalIncasari: 600,
                    procenteClienti: '6.12%', 
                    selected: false
                },
            ]
        };
    
    }

    WS = new Ws();
    M = new Masks();
    UT = new Utils();

    onSelectMemberDesktop = (member) => {
        
        let teamMembers = this.state.teamMembers;
        let selectedTeamMemberIdx = teamMembers.findIndex((el) => el['id'] === member['id'] );

        teamMembers[selectedTeamMemberIdx]['selected'] = true;
        
        for(let i = 0; i < teamMembers.length; i++){

            if(i !== selectedTeamMemberIdx){

                teamMembers[i]['selected'] = false;
            }
        }

        this.setState({

            teamMembers: teamMembers
        });
    }

    render(){

        return(
            <>

            <BrowserView>

            <div 
                className="wrapper-dashboard" 
                style={{
                    position: 'relative',
                    margin:'30px auto'
                }}
            >
                <DateChanger
                    nextMonth = {this.nextMonth}
                    date = {`Martie 2022`}
                    showBackArrow = {true}
                    arrowSize = '26px'
                    arrowTop = '7px'
                />
                
                <br />

                <div className='row'>
                    
                    <div className='col-l-6 col-m-6 col-s-12'>

                        <div className="service-details-card statistics-desktop-card">

                            <div className='statistics-graph-wrapper-desktop'>

                                <IconText 
                                    icon = {
                                        <MdVisibility
                                            style={{
                                                color: 'rgb(255, 199, 39)',
                                                fontSize: '20px'
                                            }}
                                        />
                                    }
                                    tx = 'Vizualizari pe Linkurile tale'
                                    txSize = '16px'
                                    txWeight = '500'
                                    txCol = '#455A64'
                                    side = 'center'
                                />

                                <GraphStats 
                                    title = 'VIZUALIZARI'
                                    rate = {64}
                                    trendingRate = {14.26}
                                    options = {options}
                                    data = {data}
                                />

                                <div style={{textAlign: 'left'}}>

                                    <p className='statistics-subsolTx'>
                                        * Numarul de vizualizari reprezinta vizualizarile 
                                        paginilor tale Planary si nu include vizualizarile de pe website-ul tau.
                                    </p>

                                    <p className='statistics-subsolTx' style={{marginTop: '10px'}}>
                                        * Vezi grafice mai detaliate pe tableta sau desktop pe Planary.ro
                                    </p>

                                </div>

                            </div>

                        </div>

                    </div>

                    <div className='col-l-6 col-m-6 col-s-12'>
                        
                        <div className="service-details-card statistics-desktop-card">

                            <div className='statistics-graph-wrapper-desktop'>

                                <IconText 
                                    icon = {
                                        <MdPermIdentity
                                            style={{
                                                color: 'rgb(255, 199, 39)',
                                                fontSize: '20px'
                                            }}
                                        />
                                    }
                                    tx = 'Vizitatori Unici'
                                    txSize = '16px'
                                    txWeight = '500'
                                    txCol = '#455A64'
                                    side = 'center'
                                />

                                <GraphStats 
                                    title = 'VIZITATORI UNICI'
                                    rate = {23}
                                    trendingRate = {10.61}
                                    options = {options}
                                    data = {data}
                                />

                                <div style={{textAlign: 'left'}}>

                                    <p className='statistics-subsolTx'>
                                        * Numarul de vizitatori reprezinta vizitatori unici care au 
                                        accesat paginile tale Planary si nu include vizitatorii de pe website-ul tau.
                                    </p>

                                    <p className='statistics-subsolTx' style={{marginTop: '10px'}}>
                                        * Vezi grafice mai detaliate petableta sau desktop pe Planary.ro
                                    </p>

                                </div>

                            </div>

                        </div>

                    </div>

                    {/* <div 
                        className='col-l-4 col-m-6 col-s-12'
                    >
                        
                        <div className="service-details-card statistics-desktop-card">

                             
                            
                        </div>

                    </div> */}

                </div>

                <div className="service-details-card separated-card">

                   
                    <div className='row'>

                        <div className='col-l-4 col-m-4 col-s-12'>
                                
                            <IconText 
                                icon = {
                                    <MdSupervisorAccount
                                        style={{
                                            color: 'rgb(255, 199, 39)',
                                            fontSize: '20px'
                                        }}
                                    />
                                }
                                tx = 'Performante Echipa'
                                txSize = '16px'
                                txWeight = '500'
                                txCol = '#455A64'
                                side = 'center'
                            />

                            {
                                this.state.teamMembers.map( (member, idx) => {

                                    return(

                                        <>
                                        
                                            <StatMemberCard 
                                                name = {member['nume']}
                                                total = {member['totalIncasari']}
                                                appoints = {member['totalProgamari']}
                                                clientPercentage = {member['procenteClienti']}

                                                onClick = {() => {this.onSelectMemberDesktop(member)}}

                                                style={{
                                                    marginTop: '16px',
                                                    backgroundColor: member['selected'] ? '#e6e6e6': 'transparent',
                                                    padding: member['selected'] ? '12px' : '0',
                                                    borderRadius: '12px'
                                                }}
                                            />

                                            {
                                                idx < this.state.teamMembers.length && (

                                                    <div 
                                                        className='separator-line'
                                                        style={{
                                                            marginTop: '16px'
                                                        }}
                                                    ></div>
                                                )
                                            }
                                        
                                        </>
                                    )
                                })
                            }
                            
                        </div>

                        <div className='col-l-5 col-m-5 col-s-12'>

                            <IconText 
                                icon = {
                                    <MdPermIdentity
                                        style={{
                                            color: 'rgb(255, 199, 39)',
                                            fontSize: '20px'
                                        }}
                                    />
                                }
                                tx = 'Sursele Vizitatorilor'
                                txSize = '16px'
                                txWeight = '500'
                                txCol = '#455A64'
                                side = 'center'
                            />

                            <br />
                            
                            <Visitors 
                                    pieData = {pieData}
                                    pieOptions = {pieOptions}
                            />

                            <br />
                            <br />

                            <IconText 
                                icon = {
                                    <MdPayment
                                        style={{
                                            color: 'rgb(255, 199, 39)',
                                            fontSize: '20px'
                                        }}
                                    />
                                }
                                tx = 'Clienti'
                                txSize = '16px'
                                txWeight = '500'
                                txCol = '#455A64'
                                side = 'center'
                            />

                            <ClientsPay 
                                clients = {11}
                                trendingRate = {-2.33}
                            />

                            <br />
                            <br />

                            <IconText 
                                icon = {
                                    <MdPayment
                                        style={{
                                            color: 'rgb(255, 199, 39)',
                                            fontSize: '20px'
                                        }}
                                    />
                                }
                                tx = 'Rata de Conversie'
                                txSize = '16px'
                                txWeight = '500'
                                txCol = '#455A64'
                                side = 'center'
                            />

                            <Conversion 
                                conversionRate = {16.41}
                                trendingRate = {1.31}
                            />

                            <br />
                            <br />

                            <IconText 
                                icon = {
                                    <MdOpenInNew
                                        style={{
                                            color: 'rgb(255, 199, 39)',
                                            fontSize: '20px'
                                        }}
                                    />
                                }
                                tx = 'Bounce'
                                txSize = '16px'
                                txWeight = '500'
                                txCol = '#455A64'
                                side = 'center'
                            />

                            <Bounce 
                                rate = {21}
                                trendingRate = {-3.5}
                            />

                        </div>

                    </div>
                        
                </div>

            </div>
            
            </BrowserView>

            <MobileView>
                <div 
                    className="rootable-page"
                >

                    <div
                        style={{
                            position: 'relative'
                        }} 
                    >

                        <MServiceNav
                            icon={<AnalyticsIcon />}
                            title='Rapoarte si Analiza'
                            // path={'/ca/dashboard/cab/msv/'+this.props.match.params.tk}
                        />
                </div>

                <br />

                <DateChanger
                    nextMonth = {this.nextMonth}
                    date = {`Martie 2022`}
                    showBackArrow = {true}
                    arrowSize = '26px'
                    arrowTop = '7px'
                />

                <br />

                <div 
                    style={{
                        padding: 20
                    }}
                >

                    <div className="m-edit-s-card">

                        <IconText 
                            icon = {
                                <MdVisibility
                                    style={{
                                        color: 'rgb(255, 199, 39)',
                                        fontSize: '20px'
                                    }}
                                />
                            }
                            tx = 'Vizualizari pe Linkurile tale'
                            txSize = '16px'
                            txWeight = '500'
                            txCol = '#455A64'
                            side = 'center'
                        />

                        <GraphStats 
                            title = 'VIZUALIZARI'
                            rate = {64}
                            trendingRate = {14.26}
                            options = {options}
                            data = {data}
                        />

                        <div style={{textAlign: 'left'}}>

                            <p className='statistics-subsolTx'>
                                * Numarul de vizualizari reprezinta vizualizarile 
                                paginilor tale Planary si nu include vizualizarile de pe website-ul tau.
                            </p>

                            <p className='statistics-subsolTx' style={{marginTop: '10px'}}>
                                * Vezi grafice mai detaliate pe tableta sau desktop pe Planary.ro
                            </p>

                        </div>

                    </div>

                    <div className="m-edit-s-card" style={{marginTop: '20px'}}>

                        <IconText 
                            icon = {
                                <MdPermIdentity
                                    style={{
                                        color: 'rgb(255, 199, 39)',
                                        fontSize: '20px'
                                    }}
                                />
                            }
                            tx = 'Vizitatori Unici'
                            txSize = '16px'
                            txWeight = '500'
                            txCol = '#455A64'
                            side = 'center'
                        />

                        <GraphStats 
                            title = 'VIZITATORI UNICI'
                            rate = {23}
                            trendingRate = {10.61}
                            options = {options}
                            data = {data}
                        />

                        <div style={{textAlign: 'left'}}>

                            <p className='statistics-subsolTx'>
                                * Numarul de vizitatori reprezinta vizitatori unici care au 
                                accesat paginile tale Planary si nu include vizitatorii de pe website-ul tau.
                            </p>

                            <p className='statistics-subsolTx' style={{marginTop: '10px'}}>
                                * Vezi grafice mai detaliate petableta sau desktop pe Planary.ro
                            </p>

                        </div>

                    </div>

                    <div className="m-edit-s-card" style={{marginTop: '20px'}}>

                        <IconText 
                            icon = {
                                <MdPermIdentity
                                    style={{
                                        color: 'rgb(255, 199, 39)',
                                        fontSize: '20px'
                                    }}
                                />
                            }
                            tx = 'Sursele Vizitatorilor'
                            txSize = '16px'
                            txWeight = '500'
                            txCol = '#455A64'
                            side = 'center'
                        />

                        <br />
                        
                        <Visitors 
                                pieData = {pieData}
                                pieOptions = {pieOptions}
                        />

                    </div>

                    <div className="m-edit-s-card" style={{marginTop: '20px'}}>

                        <IconText 
                            icon = {
                                <MdPayment
                                    style={{
                                        color: 'rgb(255, 199, 39)',
                                        fontSize: '20px'
                                    }}
                                />
                            }
                            tx = 'Clienti'
                            txSize = '16px'
                            txWeight = '500'
                            txCol = '#455A64'
                            side = 'center'
                        />

                        <ClientsPay 
                            clients = {11}
                            trendingRate = {-2.33}
                        />

                    </div>

                    <div className="m-edit-s-card" style={{marginTop: '20px'}}>

                        <IconText 
                            icon = {
                                <MdPayment
                                    style={{
                                        color: 'rgb(255, 199, 39)',
                                        fontSize: '20px'
                                    }}
                                />
                            }
                            tx = 'Rata de Conversie'
                            txSize = '16px'
                            txWeight = '500'
                            txCol = '#455A64'
                            side = 'center'
                        />

                        <Conversion 
                            conversionRate = {16.41}
                            trendingRate = {1.31}
                        />
                        

                    </div>

                    <div className="m-edit-s-card" style={{marginTop: '20px'}}>

                        <IconText 
                            icon = {
                                <MdOpenInNew
                                    style={{
                                        color: 'rgb(255, 199, 39)',
                                        fontSize: '20px'
                                    }}
                                />
                            }
                            tx = 'Bounce'
                            txSize = '16px'
                            txWeight = '500'
                            txCol = '#455A64'
                            side = 'center'
                        />

                        <Bounce 
                            rate = {21}
                            trendingRate = {-3.5}
                        />

                    </div>

                    <div className="m-edit-s-card" style={{marginTop: '20px'}}>

                        <IconText 
                            icon = {
                                <MdSupervisorAccount
                                    style={{
                                        color: 'rgb(255, 199, 39)',
                                        fontSize: '20px'
                                    }}
                                />
                            }
                            tx = 'Performante Echipa'
                            txSize = '16px'
                            txWeight = '500'
                            txCol = '#455A64'
                            side = 'center'
                        />

                        <StatMemberCard 
                            name = 'Marcela Popescu'
                            total = {480}
                            appoints = {4}

                            style={{
                                marginTop: '16px'
                            }}
                        />

                        <div 
                            className='separator-line'
                            style={{
                                marginTop: '16px'
                            }}
                        ></div>

                        <StatMemberCard 
                            name = 'Cristian Ionescu'
                            total = {600}
                            appoints = {6}

                            style={{
                                marginTop: '16px'
                            }}
                        />

                    </div>

                </div>

            </div>

            </MobileView>

            </>
        );
    }
}