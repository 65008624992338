import Masks from "./Masks"
import Utils from "./Utils";



export default class Declarations{

    M = new Masks();
    UT = new Utils();

    listaDurata = [

        {
            val: 0,
            tx: '0 Minute'
        },

        {
            val: 15,
            tx: '15 Minute'
        },

        
        {
            val: 30,
            tx: '30 Minute'
        },

        {
            val: 45,
            tx: '45 Minute'
        },

        
        {
            val: 60,
            tx: '60 Minute'
        },

        {
            val: 90,
            tx: '90 Minute'
        },

        
        {
            val: 120,
            tx: '120 Minute'
        }
    ]

    listaLocatiiIntalnire = [

        {
            id: 'zoom',
            shortTx: 'Zoom.us',
            tx: 'Videoconferinta',
            descClient: 'Zoom'
        },

        {
            id: 'skype',
            shortTx: 'Skype',
            tx: 'Videoconferinta',
            descClient: 'Skype'
        },

        {
            id: 'gmeet',
            shortTx: 'Google Meet',
            tx: 'Videoconferinta',
            descClient: 'Meet'
        },

        {
            id: 'office',
            shortTx: 'Sediu',
            tx: 'Intalnire sediu',
            descClient: 'Sediu'
        }
    ]

    listaCulori = [

        {
            val: 'red',
            col: '#D84C4C'
        },

        {
            val: 'yellow',
            col: '#FFC727'
        },

        {
            val: 'green',
            col: '#60D84C'
        },

        {
            val: 'blue',
            col: '#4CD8D8'
        },

        {
            val: 'dark-blue',
            col: '#44A5FF'
        },

        {
            val: 'violet',
            col: '#9A4CD8'
        },

        {
            val: 'pink',
            col: '#D84CA0'
        }
    ]

    tipZile = [

        {
            val: 'work-days',
            tx: 'Zile lucratoare'
        },

        {
            val: 'calendar-days',
            tx: 'Zile calendaristice'
        }
    ]

    listaLuni = [

        {
            luna: 'Ianuarie',
            idx: 0
        },

        {
            luna: 'Februarie',
            idx: 1
        },

        {
            luna: 'Martie',
            idx: 2
        },

        {
            luna: 'Aprilie',
            idx: 3
        },

        {
            luna: 'Mai',
            idx: 4
        },

        {
            luna: 'Iunie',
            idx: 5
        },

        {
            luna: 'Iulie',
            idx: 6
        },

        {
            luna: 'August',
            idx: 7
        },

        {
            luna: 'Septembrie',
            idx: 8
        },

        {
            luna: 'Octombrie',
            idx: 9
        },

        {
            luna: 'Noiembrie',
            idx: 10
        },

        {
            luna: 'Decembrie',
            idx: 11
        },
    ]

    listaZileSaptamana = [

        {
            idx: 1,
            ziua: 'Luni'
        },

        {
            idx: 2,
            ziua: 'Marti'
        },

        {
            idx: 3,
            ziua: 'Miercuri'
        },

        {
            idx: 4,
            ziua: 'Joi'
        },

        {
            idx: 5,
            ziua: 'Vineri'
        },

        {
            idx: 6,
            ziua: 'Sambata'
        },

        {
            idx: 7,
            ziua: 'Duminica'
        }
    ]

    listaIntervalOrar = [

        '08:00',
        '08:30',
        '09:00',
        '09:30',
        '10:00',
        '10:30',
        '11:00',
        '11:30',
        '12:00',
        '12:30',
        '13:00',
        '13:30',
        '14:00',
        '14:30',
        '15:00',
        '15:30',
        '16:00',
        '16:30',
        '17:00',
        '17:30',
        '18:00',
        '18:30',
        '19:00',
        '19:30',
        '20:00'
    ]

    listaIntervalOrarSchedule = [

        '08:00',
        '08:30',
        '09:00',
        '09:30',
        '10:00',
        '10:30',
        '11:00',
        '11:30',
        '12:00',
        '12:30',
        '13:00',
        '13:30',
        '14:00',
        '14:30',
        '15:00',
        '15:30',
        '16:00',
        '16:30',
        '17:00',
        '17:30',
        '18:00',
        '18:30',
        '19:00',
        '19:30',
        '20:00',
        'Indisp'
    ]

    listaIntervalOrarFix = [

        '08:00',,
        '09:00',
        '10:00',
        '11:00',
        '12:00',,
        '13:00',
        '14:00',
        '15:00',
        '16:00',,
        '17:00',
        '18:00',
        '19:00',
        '20:00',
        'Indisp'
    ]

    generateSmartBillInvoiceObject = (smartBillData, appointmentData) => {

        const dataAzi = this.M.specificDateToDateField(new Date());

        let dateFactuare = {
            companyVatCode: smartBillData['smart_bill_cui'],
            client: {
              name: appointmentData['clie_nume'] + ' ' + appointmentData['clie_prenume'],
              vatCode: appointmentData['clie_cui'],
              isTaxPayer: true,
              address: appointmentData['clie_adresa'],
              city: appointmentData['clie_oras'],
              country: "Romania",
              email: appointmentData['clie_email'],
              saveToDb: false
            },
            issueDate: dataAzi,
            seriesName: smartBillData['smart_bill_serie'],
            isDraft: false,
            dueDate: appointmentData['data_cal'],
            deliveryDate: dataAzi,
            products: [
              {
                name: appointmentData['service_name'],
                isDiscount: false,
                measuringUnitName: "buc",
                currency: "RON",
                quantity: 1,
                price: +appointmentData['price'],
                isTaxIncluded: true,
                taxName: "Redusa",
                taxPercentage: 9,
                saveToDb: false,
                isService: false
              }
            ]
        }

        return dateFactuare;
    }

    serviceData = {

        svNameAndLink: {

            denumire: '',
            link: '',
        },

        location : {

            locatie: 'zoom',
        },

        description: {

            descriereSv: '',
        },

        duration: {

            durata: 30
        },

        price: {

            tarif: 0,
        },

        disponibility: {

            dispoNrZile: 30,
            dispoTipZile: 'work-days',
            dispoDataStart: `'${this.M.specificDateToDateField(new Date().setHours(0, 0, 0, 0))}'`,
            dispoDataEnd: null,
        },

        breaks: {

            pauzaInainte: 15,
            pauzaDupa: 15,
        },

        schedule: {

            programTk: '',
            scheduleDescr: ''
        },

        color: {

            culoare: 'red',
        },

        recall: {

            hasRecall: 0,
            recallNrZile: 30,
            recallTipZile: 'work-days',
            recallDesc: '',
        },

        review: {

            hasReview: 0,
            reviewDesc: '',
        },

        terms: {

            hasCancel: 0,
            cancelDesc: '',
        }
    };

    profileData = {

        utilizator: {

            display_name: '',
            web_link: '',
            email: '',
            telefon: ''
        },

        companie: {

            nume: '',
            reg_com: '',
            cui: ''
        },

        adresa: {

            adresa: '',
            oras: ''
        }
    }

    scheduleData = {

        scheduleName: '',

        luStart: '08:00:00',
        luEnd: '20:00:00',

        maStart: '08:00:00',
        maEnd: '20:00:00',

        miStart: '08:00:00',
        miEnd: '20:00:00',

        joStart: '08:00:00',
        joEnd: '20:00:00',

        viStart: '08:00:00',
        viEnd: '20:00:00',

        saStart: null,
        saEnd: null,

        duStart: null,
        duEnd: null,
    }

    clientData = {

        clientInfo: {

            clie_nume: '',
            clie_prenume: '',
            clie_email: '',
            clie_tf: '',
            clie_detalii: ''
        },

        smartBillInfo: {

            clie_cui: '',
            clie_adresa: '',
            clie_oras: ''
        }
    }

    generateQuerySet = (type, object) => {

        const serviceQuerySet = {

            svNameAndLink: [
    
                {
                    itemName: 'name',
                    itemData: `'` + object['denumire'] + `'`
                },
                
                {
                    itemName: 'web_link',
                    itemData: `'` + (object['link'] || '').toLowerCase() + `'`
                },
    
                {
                    itemName: 'draft',
                    itemData: `${0}`
                },
            ],
    
            location: [
    
                {
                    itemName: 'location',
                    itemData: `'` + object['locatie'] + `'`
                },
            ],

            description: [

                {
                    itemName: 'description',
                    itemData: `'` + object['descriereSv'] + `'`
                }
            ],

            duration: [

                {
                    itemName: 'duration',
                    itemData: `${+object['durata']}`
                },
            ],

            price: [

                {

                    itemName: 'price',
                    itemData: `${+object['tarif']}`
                },
            ],

            disponibility: [

                {
                    itemName: 'dispo_nr_zile',
                    itemData: `${+object['dispoNrZile']}`
                },
    
                {
                    itemName: 'dispo_tip_zile',
                    itemData: `'` + object['dispoTipZile'] + `'`
                },
    
                {
                    itemName: 'dispo_data_start',
                    itemData: `${object['dispoDataStart']}`
                    // itemData: `${object['dispoDataStart']}`
                },
    
                {
                    itemName: 'dispo_data_end',
                    itemData: `${object['dispoDataEnd']}`,
                },
            ],

            breaks: [

                {
                    itemName: 'break_before',
                    itemData: `${+object['pauzaInainte']}`
                },
    
                {
                    itemName: 'break_after',
                    itemData: `${+object['pauzaDupa']}`
                },
            ],

            schedule: [

                {
                    itemName: 'program_tk',
                    itemData: `'` + object['programTk'] + `'`
                },

                {
                    itemName: 'schedule_descr',
                    itemData: `'` + object['scheduleDescr'] + `'`
                }
            ],

            color: [

                {
                    itemName: 'color',
                    itemData: `'` + object['culoare'] + `'`
                },
            ],

            recall: [

                {
                    itemName: 'has_recall',
                    itemData: `${+object['hasRecall']}`
                },
    
                {
                    itemName: 'recall_nr_zile',
                    itemData: `${+object['recallNrZile']}`
                },
    
                {
                    itemName: 'recall_tip_zile',
                    itemData: `'` + object['recallTipZile'] + `'`
                },
    
                {
                    itemName: 'recall_text',
                    itemData: `'` + object['recallDesc'] + `'`
                },
            ],

            review: [

                {
                    itemName: 'review_cere',
                    itemData: `${+object['hasReview']}`
                },
    
                {
                    itemName: 'review_text',
                    itemData: `'` + object['reviewDesc'] + `'`
                },
            ],

            terms: [

                {
                    itemName: 'has_cancel_policy',
                    itemData: `${+object['hasCancel']}`
                },
    
                {
                    itemName: 'cancel_policy_text',
                    itemData: `'` + object['cancelDesc'] + `'`
                },
            ],
            
        };

        const profileQuerySet = {

            utilizator: [

                {
                    itemName: 'display_name',
                    itemData: `'` + object['display_name'] + `'`
                },

                {
                    itemName: 'web_link',
                    itemData: `'` + object['web_link'] + `'`
                },

                {
                    itemName: 'telefon',
                    itemData: `'` + object['telefon'] + `'`
                }
            ],

            companie: [

                {
                    itemName: 'nume',
                    itemData: `'` + object['nume'] + `'`
                },

                {
                    itemName: 'reg_com',
                    itemData: `'` + object['reg_com'] + `'`
                },

                {
                    itemName: 'cui',
                    itemData: `'` + object['cui'] + `'`
                },
            ],

            adresa: [

                {
                    itemName: 'adresa',
                    itemData: `'` + object['adresa'] + `'`
                },

                {
                    itemName: 'oras',
                    itemData: `'` + object['oras'] + `'`
                },
            ]
        };

        const scheduleQuerySet = {
            
            program: [

                {
                    itemName: 'name',
                    itemData: `${object['scheduleName']}`
                },
                
                {
                    itemName: 'lu_start',
                    itemData: `${object['luStart']}`
                },

                {
                    itemName: 'lu_start',
                    itemData: `${object['luEnd']}`
                },

                {
                    itemName: 'ma_start',
                    itemData: `${object['maStart']}`
                },

                {
                    itemName: 'ma_end',
                    itemData: `${object['maEnd']}`
                },

                {
                    itemName: 'mi_start',
                    itemData: `${object['miStart']}`
                },

                {
                    itemName: 'mi_end',
                    itemData: `${object['miEnd']}`
                },

                {
                    itemName: 'jo_start',
                    itemData: `${object['joStart']}`
                },

                {
                    itemName: 'jo_end',
                    itemData: `${object['joEnd']}`
                },

                {
                    itemName: 'vi_start',
                    itemData: `${object['viStart']}`
                },

                {
                    itemName: 'vi_end',
                    itemData: `${object['viEnd']}`
                },

                {
                    itemName: 'sa_start',
                    itemData: `${object['saStart']}`
                },

                {
                    itemName: 'sa_end',
                    itemData: `${object['saEnd']}`
                },

                {
                    itemName: 'du_start',
                    itemData: `${object['duStart']}`
                },

                {
                    itemName: 'du_end',
                    itemData: `${object['duEnd']}`
                },
            ]
        }

        const clientDataQuerySet = {

            clientInfo: [

                {
                    itemName: 'clie_nume',
                    itemData: `'` + object['clie_nume'] + `'`
                },

                {
                    itemName: 'clie_prenume',
                    itemData: `'` + object['clie_prenume'] + `'`
                },

                {
                    itemName: 'clie_email',
                    itemData: `'` + object['clie_email'] + `'`
                },

                {
                    itemName: 'clie_tf',
                    itemData: `'` + object['clie_tf'] + `'`
                },

                {
                    itemName: 'clie_detalii',
                    itemData: `'` + (object['clie_detalii'] || '') + `'`
                },
            ],

            smartBillInfo: [

                {
                    itemName: 'clie_cui',
                    itemData: object['clie_cui'] === null ? `${object['clie_cui']}` :`'` + object['clie_cui'] + `'`
                },

                {
                    itemName: 'clie_oras',
                    itemData: object['clie_oras'] === null ? `${object['clie_oras']}` :`'` + object['clie_oras'] + `'`
                },

                {
                    itemName: 'clie_adresa',
                    itemData: object['clie_adresa'] === null ? `${object['clie_adresa']}` :`'` + object['clie_adresa'] + `'`
                },
            ]
        }

        switch (type){

            case 'service':
                return serviceQuerySet;

            case 'profile':
                return profileQuerySet;

            case 'schedule':
                return scheduleQuerySet;
            
            case 'client':
                return clientDataQuerySet;
        }   
    }

    serviceKeySet = {

        denumire: 'name',
        link: 'web_link',
        locatie: 'location',
        descriereSv: 'description',
        durata: 'duration',
        tarif: 'price',
        dispoNrZile: 'dispo_nr_zile',
        dispoTipZile: 'dispo_tip_zile',
        dispoDataStart: 'dispo_data_start',
        dispoDataEnd: 'dispo_data_end',
        pauzaInainte: 'break_before',
        pauzaDupa: 'break_after',
        programTk: 'program_tk',
        scheduleDescr: 'schedule_descr',
        culoare: 'color',
        hasRecall: 'has_recall',
        recallNrZile: 'recall_nr_zile',
        recallTipZile: 'recall_tip_zile',
        recallDesc: 'recall_text',
        hasReview: 'review_cere',
        reviewDesc: 'review_text',
        hasCancel: 'has_cancel_policy',
        cancelDesc: 'cancel_policy_text'
    };

    scheduleKeySet = {

        scheduleName: 'name',
        luStart: 'lu_start',
        luEnd: 'lu_end',
        maStart: 'ma_start',
        maEnd: 'ma_end',
        miStart: 'mi_start',
        miEnd: 'mi_end',
        joStart: 'jo_start',
        joEnd: 'jo_end',
        viStart: 'vi_start',
        viEnd: 'vi_end',
        saStart: 'sa_start',
        saEnd: 'sa_end',
        duStart: 'du_start',
        duEnd: 'du_end',
    }

    updateComponentsData = (component, data, type = 'service') => {

        let result = component;

        

        if(type === 'service'){

            for(let [key, value] of Object.entries(component) ){;

                if(key === 'dispoDataStart'){

                    result[key] = `'` + data[this.serviceKeySet[key]] + `'`;
                    // result[key] = null;
                    
                }
                else if(key === 'dispoDataEnd'){

                    if((data[this.serviceKeySet[key]] || '').length > 4){

                        result[key] = `'` + data[this.serviceKeySet[key]] + `'`;
                    }
                    else{

                        result[key] = data[this.serviceKeySet[key]];
                    }
                }
                else{

                    result[key] = data[this.serviceKeySet[key]];
                    
                }

            }
        }
        else if(type === 'schedule'){

            for(let [key, value] of Object.entries(component) ){

                if( (data[key] || '').length < 3 || data[key] === null ){

                    result[key] = data[this.scheduleKeySet[key]];
                }
                else{

                    result[key] = `'` + data[this.scheduleKeySet[key]] + `'`;
                }
            }
        }
        else {
        
            for(let [key, value] of Object.entries(component) ){

                result[key] = data[key];                
            }
        }

        return result;
    }

    destructureDataObject = (object) => {

        let objectCopy = object;
        let newObject = {};

        for (let [key, value] of Object.entries(objectCopy)) {
            
            for( let [valueKey, finalValue] of Object.entries(objectCopy[key]) ){

                newObject[valueKey] = finalValue;
            }
        }

        return newObject;
    }

    onGenerateQueryArray = (queryObject) => {

        let nestedQueryArr = [];
        let queryArr = [];

        for(let [key, value] of Object.entries(queryObject)){

            nestedQueryArr.push(queryObject[key]);
        }
        
        for(let i = 0; i < nestedQueryArr.length; i++){

            for(let j = 0; j < nestedQueryArr[i].length; j++){

                queryArr.push(nestedQueryArr[i][j]);
            }
        }
        
        return queryArr;
    }  
}