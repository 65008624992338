import LocalStorage from "../../providers/LocalStorage";
import Utils from "../../providers/Utils";
import Ws from "../../providers/Ws";


export default class InputValidation{

    WS = new Ws();
    UT = new Utils();

    regexSpaces = /\s/g;
    regexSpecialCharSetOne = /[`!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?~]/;
    regexSpecialCharSetTwo = /[`!@#$%^&*()_+\=\[\]{};:"\\|,.<>\/?~]/;
    regexSpecialCharSetThree = /[a-zA-Z]/g;
    regexSpecialCharSetFour = /[`!@#$%^&*()_+\=\[\]{};:"\\|<>\/?~]/;

    generateResultObject = (error, verified, errorTx, isOk) => {

        return {

            error: error,
            verified: verified,
            errorTx: errorTx,
            isOk: isOk
        }
    }

    onCheckName = (name) => {

        let res = {};

        if(name.length < 3 || this.regexSpecialCharSetFour.test(name) ){

            res = this.generateResultObject(true, false, 'Nume scurt sau contine caractere speciale.', false);

            return res;
        }
        else{

            res = this.generateResultObject(false, true, '', true);

            return res;
        }
    }

    onCheckEmail = async (email, isAccountEmail = false) => {

        let res = {};

        if(email.length < 6 || !email.includes('@') || 
            !email.includes('.')
        ){
            
            res = this.generateResultObject(true, false, 'Email scurt sau format gresit.', false);
            return res;
        }
        else if(isAccountEmail){

            let queryUsers = `select email from users`;
            let usersEmails = await this.WS.sqlCommand(`select`, queryUsers);

            if(usersEmails.success){

                let emailExists = usersEmails.content.findIndex(user => user.email === email);

                if(emailExists > -1){

                    res = this.generateResultObject(true, false, 'Email-ul exista deja!', false);
                    return res;
                }
                else{

                    res = this.generateResultObject(false, true, '', true);
                    return res;
                }
            }
        }
        else{

            res = this.generateResultObject(false, true, '', true);
            return res;
        }
    }

    onCheckEmailTeamMebmer = async (email, wrkToken) => {

        let res = {};
        
        let queryUsers = `select * from users`;
        let usersEmails = await this.WS.sqlCommand(`select`, queryUsers);
        let emailExists = usersEmails.content.findIndex(user => user.email === email && user.wrk_token !== wrkToken);

        if(email.length < 6 || !email.includes('@') || 
            !email.includes('.')
        ){
            
            res = this.generateResultObject(true, false, 'Email scurt sau format gresit.', false);
            return res;
        }
        else if(emailExists > -1){

            res = this.generateResultObject(true, false, 'Email-ul exista deja!', false);
            return res;
        }
        else{

            res = this.generateResultObject(false, true, '', true);
            return res;
        }
    }

    onCheckPhone = (phone) => {

        let res = {};

        if(phone.length < 10 || this.regexSpecialCharSetTwo.test(phone) || 
            this.regexSpecialCharSetThree.test(phone)
        ){

            res = this.generateResultObject(true, false, 'Nr. telefon scurt sau contine caractere speciale.', false);
            return res;
        }
        else{

            res = this.generateResultObject(false, true, '', true);
            return res;
        }
    }

    onCheckCui = (cui) => {

        let res = {};

        // if(!this.UT.validateCui(cui) || this.regexSpecialCharSetOne(cui)){

        //     res = this.generateResultObject(true, false, 'C.I.F invalid.', false);
        //     return res;
        // }
        // else{

            res = this.generateResultObject(false, true, '', true);
            return res;
        // }
    }

    onCheckNrRegCom = (nrRegCom) => {

        let res = {};

        if((nrRegCom || '').length < 6){

            res = this.generateResultObject(true, false, 'Nr. Reg. Com scurt.', false);
            return res;
        }
        else{

            res = this.generateResultObject(false, true, '', true);
            return res;
        }
    }

    onCheckNrCnp = (cnp) => {

        let res = {};

        if((cnp || '').length < 6){

            res = this.generateResultObject(true, false, 'Cnp-ul este prea scurt.', false);
            return res;
        }
        else{

            res = this.generateResultObject(false, true, '', true);
            return res;
        }
    }

    onCheckCityOrAddress = (string, message) => {

        let res = {};

        if((string || '').length < 3 || this.regexSpecialCharSetFour.test(string) ){

            res = this.generateResultObject(true, false, message, false);
            return res;
        }
        else{

            res = this.generateResultObject(false, true, '', true);
            return res;
        }
    }

    onCheckLegalName = (legalName) => {

        let res = {};

        if(legalName.length < 3 || this.regexSpecialCharSetFour.test(legalName) ){

            res = this.generateResultObject(true, false, 'Nume legal scurt sau contine caractere speciale.', false);
            return res;
        }
        else{

            res = this.generateResultObject(false, true, '', true);
            return res;
        }
    }

    onCheckAccountLink = async (accLink) => {
        
        let selectAccLinks = `select * from accounts `;
        let accLinks = await this.WS.sqlCommand(`select`, selectAccLinks);

        if(accLinks.success){

            let res = {}; 
            const accToken = new LocalStorage().GetAccountToken();
            const linkAlreadyExists = accLinks.content.filter(alEl => (alEl['web_link'] || '').toLowerCase() === (accLink || '').toLowerCase() && alEl['token'] !== accToken );

            if(accLink.length < 3 || this.regexSpecialCharSetOne.test(accLink)){

                res = this.generateResultObject(true, false, 'Link scurt sau contine caractere speciale.', false);
                return {data: res, err: null};
            }
            else if(linkAlreadyExists.length > 0){

                res = this.generateResultObject(true, false, 'Link cont existent.', false);
                return {data: res, err: null};;
            }
            else{

                res = this.generateResultObject(false, true, '', true);
                return {data: res, err: null};
            }   
        }
        else{

            return {data: null, err: 'Eroare server.'};
        }
    }

    onCheckServiceLinkAndName = async (accToken, serviceLink, serviceName, serviceToken) => {

        let selectServiceDetails = `select name, web_link, token from services where account_tk = '${accToken}' `;
        let serviceDetails = await this.WS.sqlCommand(`select`, selectServiceDetails);

        if(serviceDetails.success){

            let res = {

                svName: {},
                svLink: {}
            };

            const isNameExisting = serviceDetails.content.filter(sEl => {

                if( ((sEl['name'] || '').toLowerCase() === (serviceName || '').toLowerCase() ) && 
                    (sEl['token'] !== serviceToken)
                ){
                    return sEl;
                }
            });

            const isLinkExisting = serviceDetails.content.filter(sEl => {

                if( ( (sEl['web_link'] || '').toLowerCase() === (serviceLink || '').toLowerCase() ) && 
                    (sEl['token'] !== serviceToken)
                ){
                   
                    return sEl;
                }
            });

            if(serviceName.length < 3){

                res['svName'] = this.generateResultObject(true, false, 'Nume serviciu scurt.', false);
            }
            else if(isNameExisting.length > 0){

                res['svName'] = this.generateResultObject(true, false, 'Exista un serviciu cu acelasi nume.', false);
            }
            else{

                res['svName'] = this.generateResultObject(false, true, '', true);
            }

            if(this.regexSpecialCharSetOne.test(serviceLink) || 
                this.regexSpaces.test(serviceLink)
            ){

                res['svLink'] = this.generateResultObject(true, false, 'Link-ul nu poate contine caractere speciale.', false);
            }
            else if(serviceLink.length < 3){

                res['svLink'] = this.generateResultObject(true, false, 'Link-ul este scurt.', false);
            }
            else if(isLinkExisting.length > 0){

                res['svLink'] = this.generateResultObject(true, false, 'Link-ul exista deja.', false);
            }
            else{

                res['svLink'] = this.generateResultObject(false, true, '', true);
            }

            return {data: res, err: null};
            
        }
        else{

            return {err: 'Eroare server', data: null}
        }
    }

    onCheckUserWebLink = async (accountToken, webLink) => {

        let res = {};
        const qWebLink = `select web_link from users where account_id = '${accountToken}' `;
        let userWebLink = await this.WS.sqlCommand(`select`, qWebLink);

        const isLinkExisting = userWebLink.content.findIndex(weblink => (weblink['web_link'] || '').toLowerCase() === (webLink || '').toLowerCase() );

        if(isLinkExisting > -1){

            res = this.generateResultObject(true, false, 'Un utilizator al acestui cont are deja acest link!', false);
            return {data: res, err: null};  
        }
        else if((webLink || '').length < 3 || this.regexSpecialCharSetOne.test(webLink)){

            res = this.generateResultObject(true, false, 'Link scurt sau contine caractere speciale.', false);
            return {data: res, err: null};
        }
        else{

            res = this.generateResultObject(false, true, '', true);
            return {data: res, err: null};
        }    
    }

}