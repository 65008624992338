import NotificationsFuncs from "../../commons/notifications/NotificationsFuncs";
import Masks from "../../providers/Masks";
import Utils from "../../providers/Utils";
import Ws from "../../providers/Ws";
import ClientValidationFuncs from "./ClientValidationFuncs";
import ServicesFuncs from "./ServicesFuncs";


export default class Transaction{

    WS = new Ws();
    M = new Masks();
    UT = new Utils();
    CVF = new ClientValidationFuncs();
    NF = new NotificationsFuncs();;
    SF = new ServicesFuncs();

    //user_asignat_token
    onCreateGCalEvent = async (trcToken, isGmeet = false) => {       
        
        let _adr = `https://gcal.planary.ro/index.php?trcToken=${trcToken}`;

        let addr = encodeURI(_adr);
        let r = await fetch(addr, {
            headers : { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json'
            }
        });

        

        if (r.status === 200) {
            let ss = await r.json(); // pentru tratarea de exceptii cu mesaje punctuale in fiecare pagina
            

            // ss = JSON.parse(ss);
            

            if(ss.success){

                // alert("Php a returnat succes!");
                if(isGmeet){

                    const gmeetLink = ss['hangoutLink'];
                    const addr = `https://plannarybk.auths.online/api/pay/pay/?apikey=8dhsa90jcdu9r03hfjdhfu09ehvjdsruefnhh90h&trToken=${trcToken}&alreadyGeneratedGmetLink=${gmeetLink}`

                    let ssRq = await fetch(addr)
                    let ssRes = await ssRq.json()

                    

                    if(ssRes.success){

                        return {success: true};
                    }
                    else{

                        return {success: false};
                    }
                }
                else{

                    return {success: ss.success};
                }  
            }
            else{

                // alert("Php a returnat fail!");
                
                return {success: false};
            }
        } 
    }

    onConfirmAppointment = async (currentAppoint, tokenTrc) => {

        const updateTrcQuery = `update trc set 
                                            draft = 0
                                            
                                where token = '${tokenTrc}'
        
        `
        const updateTransaction = await this.WS.sqlCommand(`update`, updateTrcQuery);

        const qAccount = `select * from accounts where token = ( select account_tk from trc where token = '${tokenTrc}' )`;
        let account = await this.WS.sqlCommand(`select`, qAccount);

        const notificationMessageNewAppointment = this.NF.generateMessageForNotification('programare-noua', currentAppoint.content[0]);

        const notificationMessageReplannedAppointment = this.NF.generateMessageForNotification('reprogramare', currentAppoint.content[0]);

        const locationParam = (currentAppoint.content[0]['replaned_trc_tk'] || '').length > 10 ? 'reprogramare-' + currentAppoint.content[0].location : currentAppoint.content[0].location;

        const locationType = this.NF.generateNotificationTypeForLocation(locationParam);
        const accountToken = currentAppoint.content[0]['account_tk'];     
        const userToken = account.content[0].abonament === 'premium' && currentAppoint.content[0].user_asignat_token ?
        currentAppoint.content[0].user_asignat_token : null  
        
        if(updateTransaction.success){
            
            const messageToSend = (currentAppoint.content[0]['replaned_trc_tk'] || '').length > 10 ? notificationMessageReplannedAppointment : notificationMessageNewAppointment;
            await this.NF.addNewNotification(locationType, messageToSend, accountToken, userToken); 
            // alert("Tranzactie finalizata cu succes!")
            // this.props.history.push("/trc/" + this.props.match.params.tranToken+
            //                     "/finalizare-succes"
            //                     )

            return { data: "success", err: null };

        }
        else{

            return { data: null, err: 'Confirmarea programarii a esuat!' };
        }
        
    }

    createPayment = async (tokenTrc) => {

        let selectAppointQuery = `select * from trc where token = '${tokenTrc}'   `;
        let currentAppoint = await this.WS.sqlCommand(`select`, selectAppointQuery);

        if(currentAppoint.success){

            const errorTxOverlap = await this.CVF.checkIfAppointIsOverlaping(currentAppoint.content[0]['data_cal'], currentAppoint.content[0]['ora_cal'], tokenTrc, false);
            const errorTxCommon = this.CVF.clientPageCommonExceptionMessage(currentAppoint.content[0]);

            

            if(errorTxOverlap.length > 0){

                return { err: errorTxOverlap, data: null };
            }

            if(errorTxCommon.length > 0){

                return { err: errorTxCommon, data: null };
            }

            if(currentAppoint.content[0].location === 'zoom' || currentAppoint.content[0].location === 'skype' || currentAppoint.content[0].location === 'office'){

                let _adr = `${this.WS.serverAddress}pay/pay/?apikey=${this.WS.appKey}&trToken=${tokenTrc}`;
                let addr = encodeURI(_adr);

                

                let r = await fetch(addr)

                if (r.status === 200) {
                    let ss = await r.json(); // pentru tratarea de exceptii cu mesaje punctuale in fiecare pagina
                    

                    const isUserAsignat = currentAppoint.content[0]['user_asignat_token'] ? true : false;
                    let integrationsList = await this.SF.getIntegrationsData(true, currentAppoint.content[0]['account_tk'], tokenTrc, isUserAsignat);
                    let isGoogleIntegrated = this.SF.onSelectIntegration(integrationsList, 'gmeet');

                    if(isGoogleIntegrated){

                        if(ss.success){

                            // await this.NF.addNewNotification(locationType, notificationMessage, accountToken);
                            let gmeetRes = await this.onCreateGCalEvent(tokenTrc);

                            
    
                            if(gmeetRes.success){
    
                                let res = await this.onConfirmAppointment(currentAppoint, tokenTrc); 
                                return { err: null, data: res };
                            }
                            else{
    
                                return { err: 'Intalnirea nu a putut fi adauagata in Google Calendar. Va rugam reimprospatati pagina!', data: null };  
                            }
                                
                        }
                        else{
                            
                            return { err: 'Generarea link-urilor de meeting a esuat!', data: null };  
                        }
                    }
                    else{

                        let res = await this.onConfirmAppointment(currentAppoint, tokenTrc);

                        if(res.err !== null){

                            return {data: null, err: res.err};
                        }
                        else{

                            return {data: res.data, err: null};
                        }
                        
                    }

                    
                }
                else {

                    return { err:  r.message, data: null };   
                }
                
            }
            else if(currentAppoint.content[0].location === 'gmeet'){

                // alert("Arat gmeet")
    
                // this.setState({

                //     showGmeetIframe: true,
                //     currentAppoint: currentAppoint
                // });

                let gmeetRes = await this.onCreateGCalEvent(tokenTrc, true);
                

                if(gmeetRes.success){

                    let res = await this.onConfirmAppointment(currentAppoint, tokenTrc);
                    
                    if(res.err !== null){

                        return {data: null, err: res.err};
                    }
                    else{

                        return {data: res.data, err: null};
                    }
                }
                else{

                    return {err: 'Eroare comunicare Google API', data: null};
                }
            }
        }
        else{

            return{err: 'Server-ul nu a putut obtine datele programarii!', data: null}
            
        }   
    }
}