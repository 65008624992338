import React from 'react';
import { BrowserView, MobileView } from 'react-device-detect';

import { MdClear } from 'react-icons/md';
import AlertImage from '../../assets/Feeling sorry-cuate 1.png';
import ButtonForm from '../../commons/ButtonForm';
import LocalStorage from '../../providers/LocalStorage';
import Ws from '../../providers/Ws';
import AuthInput from '../AuthInput';

export default class ModalSkype extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            skypeErr: false,
            skypeErrTx: '',
            skypeVerified: false,
            skype: '',

        };
    }

    WS = new Ws();

    componentDidMount(){

        this.onGetSkypeId();
    }

    onClosePopup = (ev) => {

        if( ev.target.id === 'modal-popup' ||  ev.target.id === 'close-modal-btn'){

            this.props.onClosePopup("showSkype");
        }
  
    }

    onGetSkypeId = () => {

        let skypeData = this.props.callIntegrations.filter(el => el['tip'] === 'skype');
        let skypeId = '';

        if(skypeData.length > 0){

            skypeId = skypeData[0]['value'];
        }

        this.setState({

            skype: skypeId
        })
    }

    onSaveSkypeData = async () => {

        if(this.state.skype.length < 4 || !this.state.skype.includes('live:')){

            this.setState({

                skypeErr: true,
                skypeVerified: false,
                skypeErrTx: 'Format Id Skype invalid.'
                
            });
        }
        else{

            let isFirstIntegration = this.props.callIntegrations.findIndex(el => el['tip'] === 'skype') <= -1;
            // let isFirstIntegration = true;

            const userData = new LocalStorage().GetUserData();
            const userToken = userData.userData[0]['token'];
            const accountToken = new LocalStorage().GetAccountToken();

            const qUsers = `select * from users where account_id = '${accountToken}' `;
            let _usersRes = this.WS.sqlCommand(`select`, qUsers);

            const qAccount = `select * from accounts where token = '${accountToken}' `;
            let _accountRes = this.WS.sqlCommand(`select`, qAccount);
            
            let [ usersRes, accountRes ] = await Promise.all( [_usersRes, _accountRes] );

            let querySkype = '';
            let skypeData = [];

            if(isFirstIntegration){

                querySkype = `insert into user_contacts(tip, token_user, value) select
                    'skype', '${userToken}', '${this.state.skype}'
                `
                // if(accountRes.content[0]['abonament'] === 'anteprenor'){

                //     for(let i = 0; i < usersRes.content.length; i++){

                //         querySkype = `insert into user_contacts(tip, token_user, value) select
                //             'skype', '${usersRes.content[i]['token']}', '${this.state.skype}'
                //         `
                //         skypeData = await this.WS.sqlCommand(`insert`, querySkype);
                //     }
                // }
                // else{

                //     skypeData = await this.WS.sqlCommand(`insert`, querySkype);
                // }

                skypeData = await this.WS.sqlCommand(`insert`, querySkype);
            }
            else{

                querySkype = `update user_contacts set value = '${this.state.skype}' where token_user = '${userToken}' and tip = 'skype' `;
                
                // if(accountRes.content[0]['abonament'] === 'anteprenor'){

                //     for(let i = 0; i < usersRes.content.length; i++){

                //         querySkype = `update user_contacts set value = '${this.state.skype}' where token_user = '${usersRes.content[i]['token']}' and tip = 'skype' `;
                //         skypeData = await this.WS.sqlCommand(`update`, querySkype);
                //     }
                // }
                // else{

                //     skypeData = await this.WS.sqlCommand(`update`, querySkype);
                // }

                skypeData = await this.WS.sqlCommand(`update`, querySkype);
            }

            if(skypeData.success){

                this.setState({

                    skypeErr: false,
                    skypeVerified: true,
                    skypeErrTx: ''
                    
                },
                    async () => {

                        await this.props.onRefreshData();
                        this.props.onClosePopup("showSkype");
                    }
                );

                
            }
        }
    }
    
    render(){

        return(
      
            <div
                id="modal-popup"
                className="calendar-modal-container"
                onClick={(ev) => {this.onClosePopup(ev)}}
            >

                <div 
                    className="calendar-popup-card"
                    style={{
                        padding: '30px',
                        textAlign: 'left'
                    }}
                >

                    <MdClear
                        id="close-modal-btn"
                        style={{
                            position: 'absolute',
                            top: 10,
                            right: 10,
                            fontSize: '24px',
                            color: '#323232',
                            cursor: 'pointer'
                        }}

                        onClick={(ev) => {this.onClosePopup(ev)}}
                    />

                    <p
                        style={{
                            margin: 0,
                            fontSize: '16px',
                            fontWeight: '400',
                            color: '#455A64'
                        }}
                    >
                        Pentru a integra contul de Skype trebuie sa intrati in sectiunea de Settings, apoi
                        copiati campul Skype Name si introduceti-l in campul de mai jos 
                        <br />
                        (ex: live:cid2233zxx ):
                    </p>

                    <br />

                    <div className='form-control'>

                        <AuthInput 
                            type="text"
                            label='Id Skype'
                            labelStyle={{
                                fontSize: '10px'
                            }}
                            iserror={this.state.skypeErr}
                            errormsg={this.state.skypeErrTx}
                            value={this.state.skype}
                             onChange={(ev) => this.setState({skype: ev.target.value})}
                            verified={this.state.skypeVerified}
                            onFocus = {
                                () => {
    
                                    this.setState({
                                        // denumireError: false,
                                        skypeErrTx: null
                                    })
                                }
                            }
                        />
                    </div>

                    <br />

                    <div style={{textAlign: 'center'}}>

                        <ButtonForm
                            // id="close-modal-btn"
                            tx='Salveaza'
                            type='button'
                            onClick={this.onSaveSkypeData}
                            style={{
                                width: '150px',
                                background: '#FFC727'
                            }}
                        />
                        
                    </div>

                </div>

                

            </div>

        )
    }
}