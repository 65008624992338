import React from 'react';
import { Line } from 'react-chartjs-2';
import IconText from '../../../commons/IconText';
import StatisticsLogic from './StatisticsLogic';

export default class GraphStats extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            trendingRate: new StatisticsLogic().setTrendingIcon(this.props.trendingRate)
        };
    }

    render(){

        return(

            <div style={this.props.style}>

                <p className='statistics-tx'>{this.props.title}</p>

                <p className='statistics-tx stat'>{this.props.rate}</p>

                <IconText 
                    icon = {
                        this.state.trendingRate['icon']
                    }
                    containerTop = '10px'
                    containerW = 'auto'
                    txW = '80px'
                    tx = {this.state.trendingRate['tx']}
                    txSize = '16px'
                    txWeight = '500'
                    txCol = {this.state.trendingRate['txColor']}
                    side = 'center'
                    direction = 'row-reverse'
                />

                <Line 
                    options={this.props.options} 
                    data={this.props.data} 
                />

            </div>
        )
    }
}