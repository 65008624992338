import LocalStorage from "../../providers/LocalStorage";
import Masks from "../../providers/Masks";
import Ws from "../../providers/Ws"
import InputValidation from "./InputValidation";


export default class ClientValidationFuncs{


    WS = new Ws();
    M = new Masks();
    IV = new InputValidation();

    clientPageCommonExceptionMessage = (transaction) => {

    
        if( (+transaction['anulat'] || 0) === 1){

            return 'Programarea a fost deja anulata!';
        }
        
        if( +transaction['draft'] === 0 ){ // || transaction['src'] === 'ca'

            return 'Programarea este deja confirmata';
        }

        return '';
    }

    uncompletedDataExceptionMessage = (transaction) => {

        if( (transaction['clie_nume'] || '' ).length < 1){

            return 'Date insuficiente.';
        }

        return '';
    } 

    checkIfAppointIsOverlaping = async (data, ora, token, isFromAcc, accToken) => {

        let selectHourAppoints = ``;

        if(isFromAcc){

            const ACC_TOKEN = new LocalStorage().GetAccountToken();
            selectHourAppoints = `select * from trc where account_tk = '${token}' and ora_cal = '${ora}' and data_cal = '${data}' and account_tk = '${ACC_TOKEN}' `;
        }
        else{

            selectHourAppoints = `select * from trc where account_tk = ( select account_tk from trc where token = '${token}' ) 
                                    and ora_cal = '${ora}' and data_cal = '${data}' 
                                    and account_tk = '${accToken}'
                                    `;
        }

        let hourAppoints = await this.WS.sqlCommand(`select`, selectHourAppoints);

        if(hourAppoints.success){

            const hourAppointsFiltered = hourAppoints.content.filter(haEl => {

                if( ( haEl['token'] !== token) && (haEl['account_tk'] ) &&
                    ( (haEl['clie_nume'] || '').length > 2 && ( (haEl['anulat'] === null) || (+haEl['anulat'] === 0) ) ) &&
                    ( ( +haEl['draft'] === 0) || haEl['src'] === 'ca')
                ){

                    return haEl;
                } 
            });

            if( hourAppointsFiltered.length > 0 ){

                return `O programare a fost inregistrata deja pe data: ${this.M.dataFriendlyFromSql(data)}, ora ${ora.substr(0,5)} `;
            }
        }
        else{

            return 'Eroare server';
            // throw new Error("Error fetching hour appoints", + hourAppoints.message);
        }

        return '';
    }

    onCheckClientDataInputs = async (data, isTransactionData = false) => {

        let result = {

            name: this.IV.onCheckName(data.name),
            email: await this.IV.onCheckEmail(data.email, false),
            phone: this.IV.onCheckPhone(data.phone)
        };

        if(isTransactionData){

            result = {

                ...result,
                cui: this.IV.onCheckNrCnp(data.cui),
                address: this.IV.onCheckCityOrAddress(data.address, 'Adresa este prea scurta sau contine caractere speciale.'),
                city: this.IV.onCheckCityOrAddress(data.city, 'Orasul este prea scurta sau contine caractere speciale.'),
            }
        }

        return result;
    }
}