import React from 'react';
import Masks from '../../../providers/Masks';
import Utils from '../../../providers/Utils';
import Ws from '../../../providers/Ws';
import ServicesFuncs from '../../logic/ServicesFuncs';

import Image from '../../../assets/Businesswoman-cuate 1.png';
import '../Register.css'

import Declarations from '../../../providers/Declarations';
import UserDataInputs from '../../../commons/profile/UserDataInputs';
import AuthInput from '../../../commons/AuthInput';
import ButtonForm from '../../../commons/ButtonForm';
import { IoIosArrowForward } from 'react-icons/io';
import RegisterHeader from '../RegisterHeader';
import LinkPreview from '../../../commons/LinkPreview';
import { BrowserView, isMobile, MobileView } from 'react-device-detect';

import CanaryPlantImage from '../../../assets/canary_plant_bg.png';
import MBirdAndPlantImage from '../../../assets/bird_and_plant_mobile.png';
import LocalStorage from '../../../providers/LocalStorage';
import Loader from '../../../commons/Loader';


export default class AccountLink extends React.Component{

    constructor(props){

        super(props);
        this.state = {

            data: {

                web_link: '',
            },

            web_linkStatus: false, 
   
            triggerSave: false,
            isLoading: true
        };
    }

    WS = new Ws();
    UT = new Utils();
    M = new Masks();
    SF = new ServicesFuncs();

    componentsData = {

        web_link: new Declarations().profileData['utilizator']['web_link']
    }

    componentsStatus = {
        
        isUserDataOk: false,
    };

    componentDidMount(){

        this.onGetLinkCompanie();
    }

    onGetLinkCompanie = async () => {

        const TOKEN_CONT = new LocalStorage().GetAccountToken();

        let queryDateCompanie = `select * from accounts where token = '${TOKEN_CONT}' `;
        let dateCompanie = await this.WS.sqlCommand(`select`, queryDateCompanie);

        if(dateCompanie.success){

            

            this.componentsData.web_link = dateCompanie.content[0]['web_link'] || '';

            if(dateCompanie.content[0]['web_link'] !== null && dateCompanie.content[0]['web_link'] !== undefined ){

                if( (dateCompanie.content[0]['web_link'] || '').length > 0){

                    this.componentsStatus.isUserDataOk = true;
                }
            }

            this.setState({

                data: {

                    web_link: dateCompanie.content[0]['web_link'] || '',
                },
                isLoading: false
            });
        }
    }


    inputStatus = (status, statusName, inputName, data, isVerify = false) => {

        if(isVerify){

            this.componentsStatus[statusName] = data;
        }
        else{

            this.componentsData[inputName] = data;

            
    
            this.setState({
                
                [statusName]: status
            },
                () => {
    
                    // this.checkSaveButtonStatus();
                }
            );
        }
    }

    onCheckAndSave = async () => {

        const result = Object.values(this.componentsStatus).every(item => item === true);;
        const TOKEN_CONT = new LocalStorage().GetAccountToken();

        
        
        if(result){

            // const queryObject = new Declarations().generateQuerySet('profile', this.componentsData);
            // const queryArr = new Declarations().onGenerateQueryArray(queryObject);

            let queryUpdateAccountData = `update accounts set web_link = '${this.componentsData.web_link}'
                                where token = '${TOKEN_CONT}'
            `;

            let updateAccountData = await this.WS.sqlCommand(`update`, queryUpdateAccountData);

            if(updateAccountData.success){

                // this.props.history.push('');
                if(isMobile){

                    this.props.history.push("/inregistrare/date/datele-companiei");
                }
                else{

                    this.props.history.push("/inregistrare/date/companie-adresa");
                }
            }
        }
        else{

            
        }
    }

    onTriggerSave = () => {

        this.setState({

            triggerSave: true
        },
        
            () => {

                this.onCheckAndSave();
            }
        )
    }


    render(){

        if(this.state.isLoading){

            return(

                <Loader 
                    fullPage = {true}
                />
            )
        }

        return(
            <>
            <BrowserView>
                <div className='register-container-desktop'>

                    <RegisterHeader />

                    <div className='service-details-card' style={{marginTop: '20px', textAlign: 'left'}}>

                        <div className='row'>

                            <div className='col-l-6 col-m-6 col-s-6'>

                                <p className='register-tx register-title'>
                                    Ce este un identificator?
                                </p>

                                <p className='register-tx' style={{marginTop: '16px'}}>
                                    Planary functioneaza construind likuri catre serviciile tale, linkuri pe care tu le 
                                    distribui catre clientii tai prin email social media, chat etc.
                                </p>

                                <img
                                    className='register-image'
                                    src={Image}
                                    alt="Planary"
                                />

                            </div>

                            <div className='col-l-6 col-m-6 col-s-6' style={{paddingLeft: '20px'}}>

                                <p className='register-tx register-title'>
                                    Identificatorul tau
                                </p>

                                <p className='register-tx' style={{marginTop: '16px'}}>
                                    Completeaza identificatorul tau asa cum va aparea in adresa generata de Planary, pe care o vei distribui catre clientii tai
                                </p>

                                <UserDataInputs 
                                    hideUserData = {true}
                                    data = {this.state.data}
                                    triggerSave = {this.state.triggerSave}
                                    inputStatus = {this.inputStatus}
                                    style={{
                                        marginTop: '30px'
                                    }}
                                />

                                <LinkPreview 
                                    enableLinks = {false}
                                    hideOptions = {true}
                                    isAccLink = {true}
                                    accountLink = {this.componentsData.web_link || this.state.data.web_link}
                                    fontSize = '14px'
                                    // fontSizeCommands = '18px'

                                />

                                <ButtonForm 
                                    type='button'
                                    tx={
                                        <>
                                            <p style={{display: 'inline-block', position: 'relative', top: '-2px', margin: 0}}>
                                                Continua &nbsp;
                                            </p> 
                                            <IoIosArrowForward className='register-next-arrow'/>
                                        </>
                                    }
                                    
                                    style={{
                                        marginTop: '20px',
                                        width: 'auto',
                                        height: '56px',
                                        background: '#F8BC05',
                                        fontSize: '16px',
                                        padding: '0px 35px 0px 35px'
                                    }}
                                    onClick={
                                        () => {

                                            this.onTriggerSave()
                                        }
                                    }
                                />
                                
                            </div>


                        </div>

                    </div>

                    <img 
                        className='bottom-pg-img'
                        src={CanaryPlantImage}
                        alt = 'Planary...'
                        style={{
                            marginTop: '20px'
                        }}
                    />

                </div>

            </BrowserView>

            <MobileView>

                <div 
                    style={{
                        padding: '20px',
                        minHeight: '1050px',
                        background: `url(${MBirdAndPlantImage}) bottom no-repeat`
                    }}
                >
                    
                    <RegisterHeader />
                    
                    <div className="m-edit-s-card" style={{marginTop: '20px', textAlign: 'left'}}>

                        <p className='register-tx register-title'>
                            Identificatorul tau
                        </p>

                        <p className='register-tx' style={{marginTop: '16px'}}>
                            Completeaza identificatorul tau asa cum va aparea in adresa generata de Planary, pe care o vei distribui catre clientii tai
                        </p>
                        
                        <p className='register-tx register-title' style={{marginTop: '16px'}}>
                            Ce este un identificator?
                        </p>

                        <p className='register-tx' style={{marginTop: '16px'}}>
                            Planary functioneaza construind likuri catre serviciile tale, linkuri pe care tu le 
                            distribui catre clientii tai prin email social media, chat etc.
                        </p>

                        <UserDataInputs 
                            hideUserData = {true}
                            data = {this.state.data}
                            triggerSave = {this.state.triggerSave}
                            inputStatus = {this.inputStatus}
                            style={{
                                marginTop: '30px'
                            }}
                        />

                        <LinkPreview 
                            enableLinks = {false}
                            hideOptions = {true}
                            isAccLink = {true}
                            accountLink = {this.componentsData.web_link || this.state.data.web_link}
                            // fontSize = '14px'
                            // fontSizeCommands = '18px'
                        />


                        <img
                            className='register-image'
                            src={Image}
                            alt="Planary"
                        />

                        

                        <div 
                            style={{
                                textAlign: 'center'
                            }}
                        >
                            <ButtonForm
                                type='button'
                                tx={
                                    <>
                                        <p style={{display: 'inline-block', position: 'relative', top: '-2px', margin: 0}}>
                                            Continua &nbsp;
                                        </p> 
                                        <IoIosArrowForward className='register-next-arrow'/>
                                    </>
                                }
                                
                                style={{
                                    marginTop: '20px',
                                    width: '100%',
                                    height: '56px',
                                    background: '#F8BC05',
                                    fontSize: '16px',
                                    padding: '0px 35px 0px 35px'
                                }}
                                onClick={
                                    () => {

                                        this.onTriggerSave()
                                    }
                                }
                            />
                        </div>

                    </div>

                </div>

            </MobileView>
            </>
        )
    }
}