import React from 'react';


import { MobileView } from 'react-device-detect';
import { RiCalendar2Fill } from 'react-icons/ri';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import ErrorImage from '../../../../assets/Feeling sorry-cuate 1.png';
import CalendarImage from '../../../../assets/calendar_bg.png';

import CrAppoIntervalView from '../../create_appointment_popup/CrAppoIntervalView';
import MServiceNav from '../../../../commons/MServiceNav';

import Masks from '../../../../providers/Masks';
import Utils from '../../../../providers/Utils';
import Ws from '../../../../providers/Ws';
import LocalStorage from '../../../../providers/LocalStorage';
import Declarations from '../../../../providers/Declarations';
import CalendarClientFuncs from '../../../logic/CalendarClientFuncs';
import ErrorMessage from '../../../../commons/ErrorMessage';
import ErrorToast from '../../../../commons/ErrorToast';
import AppointmentHourSelect from '../../../../commons/AppointmentHourSelect';
import DateChanger from '../../../../commons/DateChanger';
import CalendarAndHour from '../../../../commons/client_appointments/CalendarAndHour';
import TeamMemberCard from '../../../../commons/TeamMemberCard';

export default class MCrAppoCalendar extends React.Component{

    constructor(props){

        super(props);

        this.state ={

            isError: false,
            member: null, 
            members: [],
            abonament: '',
            avatar: '',
            serviceDetails: {}
        };
    }

    WS = new Ws();
    UT = new Utils();
    M = new Masks();
    CLF = new CalendarClientFuncs();

    componentDidMount(){

        this.onSetAccountData();
    }

    fetchParamteres = {

        isToken: false,
        token: new LocalStorage().GetAccountToken(),
        isPublic: false,
        appointScreen: 'popup',
        appointSrc: 'ca',
        appointType: 'programare',
        serviceDetails: {}
    }

    onSetAccountData = async () => {

        const accountToken = new LocalStorage().GetAccountToken();

        const qAccount = `select * from accounts where token = '${accountToken}' `;
        let _account = this.WS.sqlCommand(`select`, qAccount);

        const qUsers = `select * from users where account_id = '${accountToken}' `;
        let _users = this.WS.sqlCommand(`select`, qUsers);

        const qService = `select * from services where token = '${this.props.match.params.svLink}' `;
        let _service = this.WS.sqlCommand(`select`, qService);

        let [ account, users, service ] = await Promise.all( [ _account, _users, _service] );

        const userToken =  new LocalStorage().GetUserData().userData[0].token;
        const isMainAccount = users.content[0].token === userToken;
        const memberObj = isMainAccount ? null : users.content.filter(user => user.token === userToken);

        this.setState({

            abonament: account.content[0].abonament,
            members: users.content,
            avatar: this.WS.serverAddress + 'staticprovider/getimg?filepath=Storage/Avatars/' + account.content[0]['avatar'],
            serviceDetails: service.content[0],
            member: memberObj[0] 
        });
    }


    //this.props.history.push('/ca/dashboard/cab/adauga-pauza/succes/'+tranToken);
    //this.props.history.push('/ca/dashboard/cab/adauga-programare/'+tranToken);
    //this.props.match.params.svLink
    initDate = () => {
        
        const intervalDate = JSON.parse(this.props.match.params.intervalDate);
        const validateDataStart = this.M.sqlDateToJSDate(intervalDate['dataStart']);
        const validateDataEnd = this.M.sqlDateToJSDate(intervalDate['dataEnd']);
        const correctDataEnd = this.M.firstOrLastDayOfMonth( validateDataStart.getMonth(), validateDataStart.getFullYear(), true);

        let todayDate = new Date();
        todayDate.setHours(0, 0, 0, 0);

        if(validateDataStart.getDate() > 1){

            return {

                err: {

                    errorTx: 'Data de inceput este invalida!'
                },

                data: null
            };
        }
        else if(validateDataEnd.getTime() !== correctDataEnd.getTime()){

            return {

                err: {

                    errorTx: 'Data sfarsit este invalida!'
                },

                data: null
            };
        }
        else if( (validateDataStart.getMonth() !== validateDataEnd.getMonth()) && 
                    (validateDataStart.getFullYear() !== validateDataEnd.getFullYear())
        ){

            return {

                err: {

                    errorTx: 'Interval date invalid!'
                },

                data: null
            };
        }
        else if( ( ( validateDataStart.getMonth() < todayDate.getMonth() ) || 
                 ( validateDataStart.getFullYear() < todayDate.getFullYear() ) ) || 
                 (
                    ( validateDataEnd.getMonth() < todayDate.getMonth() ) ||
                    ( validateDataEnd.getFullYear() < todayDate.getFullYear() )
                 )
        ){

            return {

                err: {

                    errorTx: 'Intervalul de date este vechi.'
                },

                data: null
            };
        }
        else{

            const TOKEN_CONT = new LocalStorage().GetAccountToken();
            let nextMonthStart = this.M.sqlDateToJSDate(intervalDate['dataStart']);
            let nextMonthEnd = this.M.sqlDateToJSDate(intervalDate['dataEnd']);

            return{

                err: null,
                data: {

                    dataStart: nextMonthStart,
                    dataEnd: nextMonthEnd,
                    accountTk: `account_tk = '${TOKEN_CONT}' and ifnull(draft,0)!=1`
                }
            }
        }
    }

    onShowBreakAddSuccess = (result) => {

        this.props.history.push('/ca/dashboard/cab/adauga-pauza/succes/'+ result['tranToken']);
    }

    onShowClientForm = (tranToken) => {

        this.props.history.push('/ca/dashboard/cab/adauga-programare/' + tranToken);
    }

    onSelectUser = (user) => {

        this.setState({

            member: user
        });
    }

    render(){

        // 

        return(

            <MobileView>

            {/* {
                this.state.showToastPopupError && (

                    <ErrorToast
                        action={this.state.actionTx}
                        onClosePopup = {this.onClosePopup}
                    />
                )
            } */}

            <MServiceNav
                icon={<RiCalendar2Fill />}
                title='Adauga in calendar'
                path={this.state.isBreak ? '/ca/dashboard/cab/adauga-pauza/'+this.props.match.params.intervalDate : '/ca/dashboard/cab/creare-programare/'+this.props.match.params.intervalDate}
            />

                <div 
                    style={{
                        padding: '20px'
                    }}
                >

                    <div
                        className="m-create-appointment-card"
                        style={{
                            padding: '20px',
                            borderRadius: '10px',
                            textAlign: 'left'
                        }}
                    >

                    {
                        this.state.abonament === 'anteprenor' ? (

                                <div
                                    style={{
                                        width: '100%',
                                        // height: '300px',
                                        // overflowY: 'auto'
                                    }}
                                >
                                    <p
                                        style={{
                                            fontSize: 14,
                                            color: '#455A64',
                                            fontWeight: '500'
                                        }}
                                    >
                                        Selectati un membru
                                    </p>

                                    <TeamMemberCard 
                                        name = {new LocalStorage().GetUserData().accountData[0]['display_name']}
                                        desc = {`Programare direct pe serviciul ${this.state.serviceDetails?.name}`}
                                        selected = {this.state.member === null}
                                        avatarSrc = {this.state.avatar}
                                        forSelect = {true}
                                        onClick = {() => this.onSelectUser(null)}
                                        style={{
                                            marginTop: '10px'
                                        }}
                                    />

                                    {
                                        this.state.members.map( (member, index) => {

                                            if(index > 0){

                                                return(

                                                    <TeamMemberCard 
                                                        key={`membru-${index}`}
                                                        name = {member.nume}
                                                        desc = {member.functie}
                                                        selected = {this.state.member?.wrk_token === member.wrk_token}
                                                        forSelect = {true}
                                                        onClick = {() => this.onSelectUser(member)}
                                                        style={{
                                                            marginTop: '10px'
                                                        }}
                                                    />
                                                );
                                            }
                                        })
                                    }

                                </div>

                            ) : null
                        }

                        <CalendarAndHour 
                            initDate = {this.initDate}
                            fetchParamteres = {this.fetchParamteres}
                            calendarEntryType = {this.props.match.params.svLink}
                            renderDashboardCalendar = {true}
                            onShowBreakAddSuccess = {this.onShowBreakAddSuccess}
                            onShowClientForm = {this.onShowClientForm}
                            intervalDate = {this.props.match.params.intervalDate}
                            member = {this.state.member}
                            header = {<>
                                        <RiCalendar2Fill
                            
                                            style={{

                                                display: 'inline-block',
                                                position: 'relative',
                                                top: 4,
                                                margin: 0,
                                                fontSize: '20px',
                                                color: '#FFC727'
                                            }}
                                        />

                                        <p
                                            style={{
                                                display: 'inline-block',
                                                position: 'relative',
                                                margin: 0,
                                                fontSize: '14px',
                                                fontWeight: '500',
                                                color: '#455A64'
                                            }}
                                        >
                                            &nbsp; Selecteaza Data si Ora
                                        </p>
                                    
                                    </>}
                        />

                    </div>

                    <br />


                    <img 
                        src={CalendarImage}
                        alt="Calendar Image"
                        style={{
                            display: 'block',
                            margin: '30px auto',
                            width: '280px',
                        }}
                    />

                </div>


            </MobileView>
        )
    }
}