import React from 'react';

import { BrowserView, MobileView } from 'react-device-detect';

import ErrorImage from '../assets/Feeling sorry-cuate 1.png';

export default class ErrorMessage extends React.Component{

    constructor(props){

        super(props);
    }

    componentDidMount(){


    }

    render(){

        return(

            <div>

                <BrowserView>
                
                    <p
                        style={{
                            margin: 0,
                            fontSize: '14px',
                            fontWeight: '500',
                            color: '#DD6A57',
                            textAlign: 'center'
                        }}
                    >
                        Oooh... A aparut o eroare!
                    </p>

                    <br />

                    <img
                        src = {ErrorImage}
                        alt = "Error Image..."
                        style = {{
                            display: 'block',
                            margin: '0 auto'
                        }}
                    />

                    <p
                        style={{
                            margin: 0,
                            fontSize: '14px',
                            fontWeight: '500',
                            color: '#263238',
                            textAlign: 'center'
                        }}
                    >
                        {this.props.eroareTx}
                    </p>

                </BrowserView>

                <MobileView>

                    <p
                        style={{
                            margin: 0,
                            textAlign: 'center',
                            fontSize: '14px',
                            fontWeight: '700',
                            color: '#EB9481'
                        }}
                    >
                        Oooh... A aparut o eroare!
                    </p>

                    <br />

                    <img 
                        src={ErrorImage}
                        alt="Error image."
                        style={{
                            display: 'block',
                            margin: '0 auto'
                        }}
                    />

                    <br />

                    <p
                        style={{
                            margin: 0,
                            textAlign: 'center',
                            fontSize: '14px',
                            fontWeight: '500',
                            color: '#455A64' //#EB9481
                        }}
                    >
                        {this.props.eroareTx}
                    </p>

                </MobileView>

            </div>
        )
    }
}