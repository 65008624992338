import React from 'react';

import { MobileView } from 'react-device-detect';

import { GoCheck, GoVerified } from 'react-icons/go';
import { ImLocation2 } from 'react-icons/im';
import { IoVideocam } from 'react-icons/io5';
import { FaSkype } from 'react-icons/fa';
import { ReactComponent as ZoomIcon } from '../../../assets/zoom_icon.svg';
import { ReactComponent as LocIcon } from '../../../assets/pin_drop.svg';


import MServiceNav from '../../../commons/MServiceNav';
import Ws from '../../../providers/Ws';
import Utils from '../../../providers/Utils';
import Declarations from '../../../providers/Declarations';
import LocalStorage from '../../../providers/LocalStorage';

import MPlantImage from '../../../assets/mobile-plant.png';
import GoogleMeetIcon from '../../../assets/Google_Meet-Logo.wine.svg';
import { ReactComponent  as GMeetIcon} from '../../../assets/Google_Meet-Logo.wine.svg';
import SuccessSaveToast from '../../../commons/SuccessSaveToast';
import ErrorToast from '../../../commons/ErrorToast';
import ErrorMessage from '../../../commons/ErrorMessage';
import SvLocation from '../../../commons/service/SvLocation';
import ServicesFuncs from '../../logic/ServicesFuncs';
import Loader from '../../../commons/Loader';

export default class MEditLocatie extends React.Component{

    constructor(props){

        super(props)

        this.state = {

            detaliiServiciu: {},
 
            canSave: false,
            showToastPopup: false,
            showToastPopupError: false,
            actionTx: '',
            eroareTx: '',
            integrations: [],

            locatieStatus: false,
            triggerSave: false,
            isLoading: true
        }
    }

    WS = new Ws();
    UT = new Utils();
    SVF = new ServicesFuncs();

    componentData = new Declarations().serviceData['location'];

    componentStatus = {

        isLocationOk: true
    };

    componentDidMount(){
        
        if(this.props.match.params.tk.length > 10){

            this.getData();
        }

        window.scrollTo(0,0);
    }

    getData = async () =>{

        const TOKEN_CONT = new LocalStorage().GetAccountToken();

        let q1 = `select * from services where account_tk = '${TOKEN_CONT}' and token = '${this.props.match.params.tk}' `;

        let _dateServiciu = this.WS.sqlCommand(`select`, q1);

        let [dateServiciu] = await Promise.all([_dateServiciu])

        if(dateServiciu.success){

            // alert('Ayaya!')

            if(dateServiciu.content.length === 0){

                this.setState({
                    
                    isLoading: false,
                    eroareTx: 'Serviciu inexistent.'
                });

                return;
            }

            if(+dateServiciu.content[0]['deleted'] === 1){

                this.setState({

                    isLoading: false,
                    eroareTx: 'Serviciul este dezactivat.'
                });

                return;
            }
            
            this.componentData = new Declarations().updateComponentsData(this.componentData, dateServiciu.content[0]);

            const integrations = await this.SVF.getIntegrationsData();

            this.setState({
                detaliiServiciu: dateServiciu.content[0],
                integrations: integrations,
                isLoading: false,
                // locatieCurenta: dateServiciu.content[0].location,
                // locatieCurentaTmp: dateServiciu.content[0].location, 
            },
                () => {
                   
                }
            )

            
        }
        else{

            this.setState({

                showToastPopupError: true,
                actionTx: 'Eroare preluare date locatie serviciu.'
            });

            throw new Error('eroare server response:  ' + dateServiciu.message)
        }
    }

    checkSaveButtonStatus = () => {

        let status = null;

        if(!this.state.locatieStatus){

            status = false;
        }
        else{

            status = true;
        }

        this.setState({

            canSave: status
        });
    }

    inputStatus = (status, statusName, inputName, data, isVerify = false) => {

        if(isVerify){

            this.componentStatus[statusName] = data;
        }
        else{

            this.componentData[inputName] = data;

            
    
            this.setState({
                
                [statusName]: status
            },
                () => {
    
                    this.checkSaveButtonStatus();
                }
            );
        }
    }

    saveToDb = async (result) => {

        const token = this.UT.generateToken(); //used only when creating a service
        const TOKEN_CONT = new LocalStorage().GetAccountToken();
        // let userData = await this.WS.getUserData()

         if (this.props.match.params.tk.length >= 10){

            // alert("Modific!")
            // let uq = `update services set  location = '${this.state.locatieCurenta}'
            //                                 where token = '${this.props.match.params.tk}'
            //                                 ` 


            

            let uq = this.SVF.onSaveServiceData(result, false, this.props.match.params.tk);

            

            let updateServiciu = await this.WS.sqlCommand(`update`, uq.data.substr(1, uq.data.length - 2));

            if(updateServiciu.success){

                // alert("Serviciu Modificat cu Succes!");
                this.setState({

                    canSave: false,
                    showToastPopup: true,
                    actionTx: 'a fost actualizata cu succes!',

                    locatieStatus: false,
                    triggerSave: false
                },
                    async () => {

                        await this.getData();
                    }
                )
            }
            else{

                this.setState({

                    showToastPopupError: true,
                    actionTx: 'Eroare actualizare locatie serviciu.'
                });

                throw new Error('Eroare Server Response:  ' + updateServiciu.message)
            }
        }
    }

    onCheckAndSave = async () => {

        if(this.componentStatus['isLocationOk']){

            const queryArr = new Declarations().generateQuerySet('service', this.componentData)['location'];

            await this.saveToDb(queryArr);
        }
        else{

            this.setState({

                triggerSave: false,
            });
        }
    }

    onClosePopup = (type) => {

        this.setState({

            [type]: false
        })
    }

    onTriggerSave = () => {

        this.setState({

            triggerSave: true 
        },
        
            () => {

                this.onCheckAndSave();
            }
        );
    }

    render(){

        return(
            <MobileView>

            {
                this.state.showToastPopupError && (

                    <ErrorToast
                        action={this.state.actionTx}
                        onClosePopup = {this.onClosePopup}
                    />
                )
            }


            {
                this.state.showToastPopup && (

                    <SuccessSaveToast 
                        onClosePopup = {this.onClosePopup}
                        saveName = 'Locatia'
                        action = {this.state.actionTx}
                    />
                )
            }

            <div 
                className="rootable-page"
                style={{
                    position: 'relative',
                    height: '100vh',
                    background: `url(${MPlantImage}) bottom no-repeat`
                }}
            >

                <div
                    style={{
                        position: 'relative'
                    }} 
                >

                    <MServiceNav 
                        icon={
                            <LocIcon 
                                style={{
                                    width: '22px',
                                    height: '22px'
                                }}
                            />
                        }
                        title='Unde se va desfasura programarea?'
                        path={'/ca/dashboard/cab/msv/'+this.props.match.params.tk}
                        titleStyle={{
                            position: 'relative',
                            left: -6
                        }}
                        fontSize='12px'
                    />

                    {
                        this.state.canSave &&
                        <GoCheck
                            className="m-add-service"
                            onClick={this.onTriggerSave}
                        />
                    }

                </div>

                {
                    this.state.isLoading && (

                        <Loader 
                            fullPage = {true}
                        />
                    )
                }

                {
                    !this.state.isLoading && (

                        <div style={{
                            padding: 20
                        }}>
        
                            <div className="m-edit-s-card">
        
                                {
                                    this.state.eroareTx.length > 0 && (
        
                                        <ErrorMessage 
                                        
                                            eroareTx = {this.state.eroareTx}
                                        />
                                    )
                                }

                                {
                                    this.state.eroareTx.length === 0 && (
        
                                        <SvLocation 
                                            showAlt = {true}
                                            locatieCurenta = {this.state.detaliiServiciu['location']}
                                            integrations = {this.state.integrations}
                                            triggerSave = {this.state.triggerSave}
                                            inputStatus = {this.inputStatus}
                                        />
                                    )
                                }

                            </div>
        
                        </div>
                    )
                }


                {/* <div
                    style={{
                        width: '100%',
                        position: 'fixed',
                        left: 0,
                        bottom: 0
                    }}
                >

                    <img 
                        src={MPlantImage}
                        alt="Bird and plant..."
                        style={{
                            display: 'block',
                            margin: 'auto'
                        }}
                    />

                </div> */}

            </div>

            </MobileView>
        )
    }
}