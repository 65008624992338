import React from "react";
import { MobileView } from "react-device-detect";
import { RiFolderReduceFill } from "react-icons/ri";
import Declarations from "../../../providers/Declarations";
import Utils from "../../../providers/Utils";
import Ws from "../../../providers/Ws";
import ServicesFuncs from "../../logic/ServicesFuncs";
import RegisterHeader from "../RegisterHeader";
import MBirdAndPlantImage from '../../../assets/bird_and_plant_mobile.png';
import IconText from "../../../commons/IconText";
import { MdAlarm } from "react-icons/md";
import LocalStorage from "../../../providers/LocalStorage";
import SvDuration from "../../../commons/service/SvDuration";
import Loader from "../../../commons/Loader";
import ButtonsNavRegister from "../../../commons/ButtonsNavRegister";
import ErrorToast from "../../../commons/ErrorToast";


export default class DurationConfig extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            serviceTk: '',
            svDuration: '',
            showToastPopupError: false,
            actionTx: '',

            triggerSave: false,
            isLoading: true
        }
    }

    WS = new Ws();
    UT = new Utils();
    SVF = new ServicesFuncs();
    
    componentData = new Declarations().serviceData['duration'];

    componentStatus = {

        isDurationOk: true
    };

    componentDidMount(){

        this.onGetService();
    }

    onGetService = async () => {

        const TOKEN_CONT = new LocalStorage().GetAccountToken();

        let querySelectService = `select * from services where account_tk = '${TOKEN_CONT}' `;
        let service = await this.WS.sqlCommand(`select`, querySelectService);

        if(service.success){

            


            if(service.content[0]['name'].length > 0 && service.content[0]['web_link'].length > 0 ){

                this.componentStatus.isNameAndLinkOk = true;
                this.componentData = new Declarations().updateComponentsData(this.componentData, service.content[0]);
                // this.componentData.denumire = service.content[0]['name'];
                // this.componentData.link = service.content[0]['web_link'];
                // this.componentData.denumire = service.content[0]['description'];
            }
                

            this.setState({

                serviceTk: service.content[0]['token'],
                svDuration: service.content[0]['duration'],
            })
        }

        this.setState({

            isLoading: false
        })
    }

    inputStatus = (status, statusName, inputName, data, isVerify = false) => {

        if(isVerify){

            this.componentStatus[statusName] = data;
        }
        else{

            this.componentData[inputName] = data;

            
    
            this.setState({
                
                [statusName]: status
            },
                () => {
    
                    this.checkSaveButtonStatus();
                }
            );
        }
    }

    onCheckAndSave = async () => {

        if(this.componentStatus['isDurationOk']){

            const queryArr = new Declarations().generateQuerySet('service', this.componentData)['duration'];
            
            let uq = this.SVF.onSaveServiceData(queryArr, false, this.state.serviceTk);

            let updateServiciu = await this.WS.sqlCommand(`update`, uq.data.substring(1, uq.data.length - 2));

            if(updateServiciu.success){

                // alert("Serviciu Modificat cu Succes!");
                this.props.history.push('');
            }
            else{

                this.setState({

                    showToastPopupError: true,
                    actionTx: 'Eroare actualizare durata serviciu.'
                });

                throw new Error('Eroare Server Response:  ' + updateServiciu.message)
            }
        }
        else{

            this.setState({

                triggerSave: false
            });
        }
    }

    onTriggerSave = () => {

        this.setState({

            triggerSave: true
        },
        
            async () => {

                await this.onCheckAndSave();
            }
        );
    }

    onClosePopup = (type) => {

        this.setState({

            [type]: false
        })
    }

    render(){

        if(this.state.isLoading){

            return(

                <Loader fullPage = {true} />
            );
        }

        return(

            <React.Fragment>

                {
                this.state.showToastPopupError && (

                    <ErrorToast
                        action={this.state.actionTx}
                        onClosePopup = {this.onClosePopup}
                    />
                )
            }

                <MobileView>

                <div 
                    style={{
                        padding: '20px',
                        minHeight: '100vh',
                        background: `url(${MBirdAndPlantImage}) bottom no-repeat`
                    }}
                >
                    
                    <RegisterHeader isServiceStep = {true} />
                    
                    <div className="m-edit-s-card" style={{marginTop: '20px', textAlign: 'left'}}>

                        <IconText 
                            icon = {
                                <MdAlarm 
                                    style={{
                                        fontSize: '22px',
                                        color: '#FFC727'
                                    }}
                                />
                            }
                            tx = 'Durata'
                            txSize = '16px'
                            txWeight = '500'
                            txCol = '#455A64'
                        />         

                        <p 
                            className='register-tx sm'
                            style={{marginTop: '16px'}}
                        >

                            Durata unei sesiuni video sau la birou a serviciului tau.
                            
                        </p>

                        <SvDuration 
                                            
                            duration = {this.state.svDuration}
                            triggerSave = {this.state.triggerSave}
                            inputStatus = {this.inputStatus}
                        />

                        <ButtonsNavRegister 
                            tx = 'Continua'
                            onPressBtn = {this.onTriggerSave}
                            onBack = {this.props.history.goBack}
                        />

                        </div>

                    </div>

                </MobileView>

            </React.Fragment>
        )
    }
}