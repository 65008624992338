import React from 'react';

import Ws from '../../providers/Ws';

import BusinessImage from '../../assets/Businesswoman-cuate 1.png';
import ErrorImg from '../../assets/Feeling sorry-cuate 1.png';
import NotificationsFuncs from '../../commons/notifications/NotificationsFuncs';
import Masks from '../../providers/Masks';
import Declarations from '../../providers/Declarations';
import Loader from '../../commons/Loader';
import Transaction from '../logic/Transaction';
import ServicesFuncs from '../logic/ServicesFuncs';


export default class CheckTransactionPayment extends React.Component{

    constructor(props){

        super(props)

        this.state = {

            transactionMsg: '',
            errorTx: '',
            showGmeetIframe: false,
            currentAppoint: {},
            isLoading: true
        }
    }

    componentDidMount(){

        // if (window.addEventListener) {
        //     window.addEventListener("message", this.onMessage, false);        
        // }
        
        this.checkTransaction();  
    }

    componentWillUnmount(){
        // if (window.addEventListener) {
        //     window.removeEventListener("message", this.onMessage, false);        
        // } 

    }

    WS = new Ws();
    M = new Masks();
    NF = new NotificationsFuncs();
    TR = new Transaction();
    SF = new ServicesFuncs();

    onMessage = (event) => {
        // Check sender origin to be trusted
        if (event.origin !== "https://auth.planary.ro") return;
    
        var data = event.data;

        //if event.data && data.conditie(success true or false) => display alert if false

        if(data && data.success){

            this.onConfirmAppointment(this.state.currentAppoint);
        }
        else{

            // alert(`error : ${data.message}`);
        }
    
        // 
        // if (typeof(window[data.func]) == "function") {
        //     window[data.func].call(null, data.message);
            
        // }
        // alert(`gmeet ${data.success} : ${data.message}`)
        
        if(data.email){

            if(data.email.includes("@")){

                this.socialLogin(data.email, data.name, data.picture)
            }
        }   

    }

    createPayment = async () => {

        let result = await this.TR.createPayment(this.props.match.params.tranToken);

        if(result.err !== null){

            this.setState({

                errorTx: result.err
            });
        }
        else{

            
            this.props.history.push("/trc/" + this.props.match.params.tranToken+
                                "/finalizare-succes"
                                );
        }
    }


    generateMeetingLinks = async () => {

        const tokenTrc = this.props.match.params.tranToken;

        // let ss = await this.WS.getData('pay/SendEmailConfirmation', {trToken: 'JojbZldPxKlFRsH9oLECBiMh3qhyGZNK'});
        // 

        let _adr = `${this.WS.serverAddress}pay/SendEmailConfirmation/?apikey=${this.WS.appKey}&trToken=${tokenTrc}`;
        let addr = encodeURI(_adr);

        let r = await fetch(addr)

        if (r.status === 200) {
            let ss = await r.json(); // pentru tratarea de exceptii cu mesaje punctuale in fiecare pagina

            if(ss.success){

                await this.createPayment();
            }
            else{

                this.setState({

                    errorTx: 'Procesul de trimitere a mail-ului de confirmare plata a esuat!'
                });
                throw new Error("Error sendPayEmailConfirmation: " + ss.message)
            }
        }
        else {
            console.error("GET SERVER STATUS ERR sendPayEmailConfirmation", r)
           
        }
    }

    //http://localhost:3000/#/trc/TYOI38oOHVdGKMUGnzrvKnHVHxrFAJD4/cptr
    checkTransaction = async () => {

        const tokenTrc = this.props.match.params.tranToken;

        let selectPaymentsQuery = `select * from trc_payments where trc_token = '${tokenTrc}' and succes = 1    `;

        let selectAppointment = `select * from trc where token = '${tokenTrc}' `;

        // let selectSmartBillIntegrations = `select count(*) as rez from accounts_integrations where account_token = 
        // (select account_tk from trc where token = '${tokenTrc}') 
        // and ifnull(activ,0)=1 `;

        let selectSmartBillIntegrations = `select * from accounts_integrations where account_token = 
        (select account_tk from trc where token = '${tokenTrc}') 
        and ifnull(activ,0)=1 `;

        let _ssPayments = this.WS.sqlCommand(`select`, selectPaymentsQuery);
        let _appointment = this.WS.sqlCommand(`select`, selectAppointment);
        let _smartBillRez = this.WS.sqlCommand(`select`, selectSmartBillIntegrations);

        let [ssPayments, appointment, smartBillRez] = await Promise.all( [_ssPayments, _appointment, _smartBillRez] );

        if(ssPayments.success && appointment.success && smartBillRez.success){

            if( +appointment.content[0]['draft'] === 0){

                this.setState({
                    errorTx: 'Programarea este deja confirmata!'
                })

                return;
            }

            if(ssPayments.content.length > 0){
                
                this.setState({

                    transactionMsg: 'Tranzactie incheiata cu succes! Vei fi redirectionat la pagina de finalizare.',
                });

                // alert("Tranzactie reusita!");
                
                if(smartBillRez.content.length > 0){

   
                    await this.smartBillIntegration(smartBillRez.content[0], appointment.content[0]);
                }

                // await this.sendPayEmailConfirmation();
                await this.generateMeetingLinks();
            }
            else{

                this.setState({

                    errorTx: 'Tranzactia a esuat! Vei fi redirectionat la pagina de plata.',
                    isLoading: false
                });

                // alert("A aparut o problema in timpul tranzactiei!");

                setTimeout( () => this.props.history.push("/trc/" + this.props.match.params.tranToken +
                "/finalizare"
                ), 500);
            }
        }
        else{

            throw new Error("Error fetching payment: " + ssPayments.message);
        }
    }

    encodeToBaseSixtyFour = (user, token) => {

        const userAndToken = user + token;
        const encodedCredentials = Buffer.from(userAndToken).toString('base64')

        return encodedCredentials;
    }

    smartBillIntegration = async (smartBillData, appointmentData) => {

        // alert("SmartBill Integration!");

        let addr = 'https://ws.smartbill.ro/SBORO/api/invoice';
        const encodedToken = this.encodeToBaseSixtyFour(smartBillData['smart_bill_email'], smartBillData['smart_bill_api_key'] );

        const dateFactuare = new Declarations().generateSmartBillInvoiceObject(smartBillData, appointmentData);

        let r = await fetch(addr, {

            method: 'POST',
            headers: {
                'Accept': 'application/json',
                // 'Accept' : 'application/octet-stream',
                'Content-Type': 'application/json',
                "authorization": "Basic " + encodedToken
                //  'Content-Type': 'text/plain'
            },
            
            body: JSON.stringify(dateFactuare)
        });

        if (r.status === 200) {

            let rj = await r.json(); 
            
            // return rj;     
            
            if(rj['errorText'].length < 1){

            }
            else{

                this.setState({

                    errorTx: 'Eroare generare factura!',
                    isLoading: false
                });
            }
            
        }
        else {
            console.error("POST SERVER STATUS ERR", r)
            // return null;
            this.setState({

                errorTx: 'Eroare generare factura!',
                isLoading: false
            });

            return;
        }
    }

    render(){

        return(

            <div>

            <div
                style={{
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100vh'
                }}
            >
                
                <div
                    style={{
                        
                        margin: 'auto',
                        padding: 20,
                        width: '600px',
                        background: '#fff',
                        borderRadius: '10px',
                        boxShadow: '0px 4px 25px rgba(0, 0, 0, 0.25)'
                    }}
                >

                    {
                        this.state.showGmeetIframe && (

                            <iframe
                                src={'https://auth.planary.ro/gcal/?trc='+this.props.match.params.tranToken}
                                style={{
                                    visibility: 'hidden',
                                    width: '1px',
                                    height: '1px',
                                    backgroundColor: 'blue'
                                }}
                            >
                            
                            </iframe>
                        )  
                    }

                    {
                        this.state.errorTx.length === 0 && (

                            <div>
                                
                                <img 
                                    src={BusinessImage}
                                    alt="Business Image"
                                    style={{
                                        display: 'block',
                                        margin: '0 auto'
                                    }}
                                />

                                {
                                    this.state.isLoading && (

                                        <Loader 
                                            containerH = '100px'
                                        />
                                    )
                                }

                                <p
                                    style={{
                                        margin: 0,
                                        color: '#455A64',
                                        fontSize: '18px',
                                        fontWeight: 500
                                    }}
                                >
                                    {this.state.transactionMsg}
                                </p>

                            </div>
                        )
                    }

                    {
                        this.state.errorTx.length > 1 && (
                            <div>

                                <img 
                                    src={ErrorImg}
                                    alt="Business Image"
                                    style={{
                                        display: 'block',
                                        margin: '0 auto'
                                    }}
                                />

                                <p
                                    style={{
                                        margin: 0,
                                        color: '#455A64',
                                        fontSize: '18px',
                                        fontWeight: 500
                                    }}
                                >
                                    {this.state.errorTx}
                                </p>

                            </div>

                        )
                    }

                    

                </div>

            </div>

        </div>
        )
    }
}