import React from 'react';

import { MdSecurity } from 'react-icons/md';

import IconText from '../IconText';
import Declarations from '../../providers/Declarations';
import LocationDesc from '../LocationDesc';

export default class NotificationContainer extends React.Component{

    constructor(props){

        super(props);
    }

    render(){

        let notificationIcon = null;

        if(this.props.type === 'programare' || this.props.type === 'reprogramare' || 
            this.props.type === 'anulare'
        ){

            notificationIcon = (

                <LocationDesc 
                    location = {this.props.iconType}
                    locationDesc = {

                        new Declarations().listaLocatiiIntalnire.filter(llEl => llEl['id'] === ( this.props.iconType || '' ).trim()).length > 0 ?
                        new Declarations().listaLocatiiIntalnire.filter(llEl => llEl['id'] === ( this.props.iconType || '' ).trim())[0]['descClient'] 
                        : ''
                    }
                    marginTop = '0px'
                    side = 'flex-end'
                    // iconCls = {this.props.isDisabled && 'loc-icon-faded'}
                    zoomW = '20px' zoomH = '10px'
                    officeW = '20px' officeH = '20px'       
                    googleW = '22px' googleH = '20px' 
                    skypeSize = '20px'  
                    leftOfffice = '-1px'  
                    txCol = '#777777'             
                    txSize = '14px'
                    txWeight = '400'
                />
            )
        }

        return(

            <>

            <p
                style={{
                    margin: 0,
                    textAlign: 'left',
                    fontSize: '16px',
                    fontWeight: 500,
                    color: '#455A64'
                }}
            >
                {this.props.title}
            </p>

            <p
                style={{
                    margin: 0,
                    marginTop: 10,
                    textAlign: 'left',
                    fontSize: '16px',
                    fontWeight: 400,
                    color: '#263238'
                }}
            >
                {this.props.message}
            </p>

            <div className="row" style={{marginTop: 8}}>

                <div 
                    className="col-l-5 col-m-5 col-s-5"
                >

                    <p
                        style={{
                            margin: 0,
                            textAlign: 'left',
                            fontSize: '14px',
                            fontWeight: 400,
                            color: '#E6B323'
                        }}
                    >
                        {this.props.time}
                    </p>

                </div>

                <div 
                    className="col-l-7 col-m-7 col-s-7"
                    // style={{
                    //     textAlign: 'right'
                    // }}
                >
       
                    {notificationIcon}

                </div>

            </div>

            
            </>
        )
    }
}