import React from 'react';
import { FaAngleRight } from 'react-icons/fa';
import { MdTrendingDown, MdTrendingUp } from 'react-icons/md';
import IconText from '../../../commons/IconText';

export default class StatMemberCard extends React.Component{

    constructor(props){

        super(props);
    }

    render(){

        return(

            <div 
                className='stat-member-wrapper'
                onClick = {this.props.onClick}
                style={this.props.style}
            >

                <div className='member-stats'>

                    <div className='avatar-wrapper'>

                        <div className='avatar'></div>

                    </div>

                    <div className='member-info'>

                        <div className='member-tx-info'>

                            <p className='tx name'>{this.props.name}</p>

                            <div className='member-tx-stats'>

                                <div className='tx-stats'>

                                    <p className='tx appoints'>{this.props.appoints} Programari</p>

                                    <p className='tx total'>Total: {this.props.total} Lei</p>
                                </div>
                                
                                <IconText 
                                    icon = {
                                        parseFloat(this.props.clientPercentage) < 0 ? (

                                            <MdTrendingDown
                                                style={{
                                                    color: '#DD6A57',
                                                    fontSize: '20px'
                                                }}
                                            />
                                        ) : (

                                            <MdTrendingUp
                                                style={{
                                                    color: '#31A81D',
                                                    fontSize: '20px'
                                                }}
                                            />
                                        )
                                    }
                                    // containerTop = '10px'
                                    containerW = 'auto'
                                    txW = '60px'
                                    tx = {this.props.clientPercentage}
                                    txSize = '16px'
                                    txWeight = '500'
                                    txCol = {parseFloat(this.props.clientPercentage ) > 0 ? '#31A81D' : '#DD6A57'}
                                    side = 'center'
                                    direction = 'row-reverse'
                                />

                                
                            </div>

                        </div>

                        

                    </div>

                </div>

                <div className='access-wrapper'>

                    <FaAngleRight 
                        className="enter"
                    />
                    
                </div>

            </div>
        )
    }
}