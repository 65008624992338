import React from 'react';
import Masks from '../../../providers/Masks';
import Utils from '../../../providers/Utils';
import Ws from '../../../providers/Ws';
import ServicesFuncs from '../../logic/ServicesFuncs';

import Image from '../../../assets/Businesswoman-cuate 1.png';
import '../Register.css'
import Declarations from '../../../providers/Declarations';
import UserDataInputs from '../../../commons/profile/UserDataInputs';
import AuthInput from '../../../commons/AuthInput';
import ButtonForm from '../../../commons/ButtonForm';
import { IoIosArrowForward } from 'react-icons/io';
import RegisterHeader from '../RegisterHeader';
import CompanyDataInputs from '../../../commons/profile/CompanyDataInputs';

import CanaryPlantImage from '../../../assets/canary_plant_bg.png';
import MBirdAndPlantImage from '../../../assets/bird_and_plant_mobile.png';
import LocalStorage from '../../../providers/LocalStorage';
import Loader from '../../../commons/Loader';

export default class Company extends React.Component{

    constructor(props){

        super(props);
        this.state = {

            data: {

                cui: '',
                reg_com: '',
                nume: '', 

            },

            cuiStatus: false,
            reg_comStatus: false,
            numeStatus: false, 
            isLoading: true,

            triggerSave: false
        };
    }

    WS = new Ws();
    UT = new Utils();
    M = new Masks();
    SF = new ServicesFuncs();

    componentsData = new Declarations().profileData['companie'];

    componentsStatus = {
        
        isCompanyDataOk: false,
    };

    componentDidMount(){

        this.onGetDateCompanie();
    }


    //In caz ca nu completeaza wizard-ul
    onGetDateCompanie = async () => {

        const TOKEN_CONT = new LocalStorage().GetAccountToken();

        let queryDateCompanie = `select * from accounts where token = '${TOKEN_CONT}' `;
        let dateCompanie = await this.WS.sqlCommand(`select`, queryDateCompanie);

        if(dateCompanie.success){

            

            this.componentsData.cui = dateCompanie.content[0]['cui'] || '';
            this.componentsData.reg_com = dateCompanie.content[0]['reg_com'] || '';
            this.componentsData.nume = dateCompanie.content[0]['nume'] || '';

            this.setState({

                data: {

                    cui: dateCompanie.content[0]['cui'] || '',
                    reg_com: dateCompanie.content[0]['reg_com'] || '',
                    nume: dateCompanie.content[0]['nume'] || '', 
    
                },
                isLoading: false
            });
        }
    }


    inputStatus = (status, statusName, inputName, data, isVerify = false) => {

        if(isVerify){

            this.componentsStatus[statusName] = data;
        }
        else{

            this.componentsData[inputName] = data;

            
    
            this.setState({
                
                [statusName]: status
            },
                () => {
    
                    // this.checkSaveButtonStatus();
                }
            );
        }
    }

    onCheckAndSave = async () => {

        const result = Object.values(this.componentsStatus).every(item => item === true);;
        const TOKEN_CONT = new LocalStorage().GetAccountToken();

        
        
        if(result){

            // const queryObject = new Declarations().generateQuerySet('profile', this.componentsData);
            // const queryArr = new Declarations().onGenerateQueryArray(queryObject);

            let queryUpdateAccountData = `update accounts set 
                nume = '${this.componentsData.nume}', reg_com = '${this.componentsData.reg_com}', 
                cui = '${this.componentsData.cui}' where token = '${TOKEN_CONT}'
            `;

            let updateAccountData = await this.WS.sqlCommand(`update`, queryUpdateAccountData);

            if(updateAccountData.success){

                // this.props.history.push('');
                this.props.history.push("/inregistrare/date/identificator");
            }
        }
        else{

            
        }
    }

    onTriggerSave = () => {

        this.setState({

            triggerSave: true
        },
        
            () => {

                this.onCheckAndSave();
            }
        )
    }

    render(){

        if(this.state.isLoading){

            return(

                <Loader 
                    fullPage = {true}
                />
            )
        }

        return(

            <div className='register-container-desktop'>

                <RegisterHeader />

                <div className='service-details-card' style={{marginTop: '20px', textAlign: 'left'}}>

                    <div className='row'>

                        <div className='col-l-6 col-m-6 col-s-6'>

                            <p className='register-tx register-title'>
                                Salut!
                            </p>

                            <p className='register-tx' style={{marginTop: '16px'}}>
                                Bine ai venit la Planary! Hai sa completam impreuna datele necesare activarii si sa cream primul tau serviciu. 
                                <br /> <br />
                                Acest proces poate dura pana la 10 minute.
                            </p>

                            <img
                                className='register-image'
                                src={Image}
                                alt="Planary"
                            />

                        </div>

                        <div className='col-l-6 col-m-6 col-s-6' style={{paddingLeft: '20px'}}>

                            <p className='register-tx register-title'>
                                Datele companiei tale!
                            </p>

                            <p className='register-tx' style={{marginTop: '16px'}}>
                                Completeaza datele companiei, asa cum vor aparea ele pe facturile catre clientii tai.
                            </p>

                            {
                                Object.keys(this.state.data).length > 0 ? (

                                    <CompanyDataInputs 

                                        data = {this.state.data}
                                        triggerSave = {this.state.triggerSave}
                                        inputStatus = {this.inputStatus}
                                        style={{
                                            marginTop: '30px'
                                        }}
                                    />
                                ) : null
                            }

                            <ButtonForm 
                                type='button'
                                tx={
                                    <>
                                        <p style={{display: 'inline-block', position: 'relative', top: '-2px', margin: 0}}>
                                            Continua &nbsp;
                                        </p> 
                                        <IoIosArrowForward className='register-next-arrow'/>
                                    </>
                                }
                                
                                style={{
                                    marginTop: '20px',
                                    width: 'auto',
                                    height: '56px',
                                    background: '#F8BC05',
                                    fontSize: '16px',
                                    padding: '0px 35px 0px 35px'
                                }}
                                onClick={
                                    () => {

                                        this.onTriggerSave(true)
                                    }
                                }
                            />
                            
                        </div>


                    </div>

                </div>

                <img 
                    className='bottom-pg-img'
                    src={CanaryPlantImage}
                    alt = 'Planary...'
                    style={{
                        marginTop: '20px'
                    }}
                />

            </div>
        )
    }
}