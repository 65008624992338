import React from 'react';

import { AiOutlineMail } from 'react-icons/ai';
import { FaRegMoneyBillAlt } from 'react-icons/fa';
import { MdPayment, MdStayPrimaryPortrait, MdThumbDown, MdThumbUp } from 'react-icons/md';

import Masks from '../providers/Masks';
import Utils from '../providers/Utils';
import HourInterval from './HourInterval';

import IconText from './IconText';
import LocationDesc from './LocationDesc';
import { ReactComponent  as ArticleIcon} from '../assets/articleIcon.svg';

export default class PopupAppointDetails extends React.Component{

    constructor(props){

        super(props);
    }

    M = new Masks();
    UT = new Utils();

    render(){

        return(

            <>
            
                
            <h3
                style={{
                    margin: 0,
                    fontSize: '18px',
                    color: '#455A64',
                    textAlign: 'left'
                }}
            >
                {this.props.currentAppoint['clie_nume'] + ' ' + this.props.currentAppoint['clie_prenume']}
            </h3>

            <div
                // className="row"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: 8
                }}
            >

                <div 
                    // className="col-l-1 col-m-1 col-s-1"
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '22px'
                    }}
                >

                    <div 
                                                                    
                        style={{
                            position: 'relative',
                            // left: '4px',
                            width: '14px',
                            height: '14px',
                            background: +this.props.currentAppoint['draft'] === 1 && this.props.currentAppoint['src'] === 'ca' ? '#455A64' : this.props.currentServiceColor,
                            borderRadius: '50%'
                        }}
                    >
                        
                    </div>

                </div>

                <div 
                    // className="col-l-11 col-m-11 col-s-11"
                >

                    <p
                        style={{
                            position: 'relative',
                            margin: 0,
                            // top: -4,
                            // left: -4,
                            left: '10px',
                            textAlign: 'left',
                            fontSize: '16px'
                        }} 
                    >
                        {this.props.currentAppoint['service_name']}
                    </p>

                </div>

            </div>

            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',   
                }}
            >
        
                <HourInterval 
                    ora = {this.props.currentAppoint['ora_cal']}
                    durata = {this.props.currentAppoint['duration']}
                    containerWidth = '50%'
                    justifyContent = 'none'
                    // marginTop = '0px'
                    iconSize = '22px'
                    txSize = '16px'
                    // topTx = '-1px'
                    // leftTx = '10px'
                />

                {   
                    this.props.appointDateEnd &&
                    this.props.appointDateEnd.getTime() !== new Date(2021, 0, 1).getTime() ||
                    this.props.appointDateEnd.getTime() > new Date().getTime() && (

                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                width: '50%',
                            }}
                        >
                            <p
                                style={{
                                    position: 'relative',
                                    left: '-34px',
                                    margin: 0,
                                    fontSize: '16px',
                                    fontWeight: '400',
                                    color: '#D3766A',
                                    
                                }}
                            >
                                Depasit
                            </p>

                        </div>
                    )
                }

                {/* <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        width: '50%',
                    }}
                >
                    <p
                        style={{
                            position: 'relative',
                            left: '-34px',
                            margin: 0,
                            fontSize: '16px',
                            fontWeight: '400',
                            color: '#D3766A',
                            
                        }}
                    >
                        Depasit
                    </p>

                </div> */}

            </div>

            <div 
                //className="row"
                style={{
                    display: 'flex',
                    alignItems: 'center'
                }}
            >

                <IconText 
                    icon = {

                        <FaRegMoneyBillAlt 
                            style={{
                                margin: 0,
                                color: +this.props.currentAppoint.price > 0 ? '#A6A6A6' : '#31A81D',
                                fontSize: '22px'
                            }}
                        /> 
                    }
                    
                    tx = {+this.props.currentAppoint.price > 0 ? this.props.currentAppoint.price + " Lei" : "Fara Tarif"}
                    containerTop = '5px'
                    width = '50%'
                    txCol = {+this.props.currentAppoint.price > 0 ? '#455A64' : '#31A81D'}
                    txSize = '16px'
                />

            
                <div 
                    // className="col-l-6 col-m-6 col-s-6"
                    style={{
                        width: '50%'
                    }}
                >

                    <h3 
                        className="title"
                        style={{
                            paddingRight: '30px',
                            textAlign: 'right',
                            margin: 0
                        }}
                    >
                        
                        <p 
                            style={{
                                    display: 'inline-block', 
                                    position: 'relative', 
                                    top: +this.props.currentAppoint.draft === 0 ? 3 : 5, 
                                    left: 0, 
                                    margin: 0,
                                    color: +this.props.currentAppoint.draft === 0 ? '#31A81D' : '#D3766A',
                                    fontSize: '22px'
                                }}
                        >
                            {
                                +this.props.currentAppoint.draft === 0 && +this.props.currentAppoint.paymentSuccess === 0 && (

                                    <MdThumbUp />
                                )
                            }

                            {
                                +this.props.currentAppoint.draft === 0 && +this.props.currentAppoint.paymentSuccess === 1 && (

                                    <MdPayment />
                                )
                            }

                            {
                                +this.props.currentAppoint.draft === 1 && (

                                    <MdThumbDown />
                                )
                            }

                        </p>

                        <p 
                            style={{
                                display: 'inline-block', 
                                position: 'relative', 
                                top: -2,
                                left: 10,
                                margin: 0,
                                color: +this.props.currentAppoint.draft === 0 ? '#31A81D' : '#D3766A',
                                fontWeight: '400',
                                fontSize: '16px'
                            }}
                        >
                            {   
                                (+this.props.currentAppoint.draft === 0 && +this.props.currentAppoint.paymentSuccess === 0) ? "Confirmat" : (+this.props.currentAppoint.draft === 0 && +this.props.currentAppoint.paymentSuccess === 1 ) ? 'Platit' : "Neconfirmat"
                            } 
                        </p>
                        
                    </h3>

                </div>

            </div>

         

            <LocationDesc 
                location = {this.props.currentAppoint['location'].trim()}
                locationDesc = { this.props.appointLocation }
                // distance = '22px'
                marginTop = '5px'
                zoomW = '22px' zoomH = '11px'
                officeW = '22px' officeH = '22px'       
                googleW = '24px' googleH = '24px' 
                skypeSize = '22px'  
                leftOfffice = '-1px'               
                txSize = '16px'
            />

                {/* <p
                    style={{
                        position: 'relative',
                        margin: 0,
                        marginTop: 8,
                        
                        textAlign: 'left',
                        color: '#2577C2',
                        fontSize: '12px',
                        cursor: 'pointer'
                    }}
                >
                    <u>https://zoom.us/j/98656439028?pwd=blBlV0x5OHJ4VjU0Q01uazlkV3hDQT09</u>
                </p> */}

            <div
                style={{
                    // position: 'relative',
                    marginLeft: '30px',
                }}
            >

                <a
                    href={this.props.currentAppoint['host_meeting_link']}
                    style={{
                        display: 'block',
                        // position: 'relative',
                        left: '-2px',
                        margin: 0, 
                        marginTop: 10,
                        textDecoration: 'none',
                        textAlign: 'left',
                        fontSize: '12px',
                        color: '#2577C2',
                        wordWrap: 'break-word'
                    }}
                >
                    {this.props.currentAppoint['host_meeting_link']}
                </a>
                                                
                {
                    this.props.currentAppoint.location === 'zoom' && 
                    (this.props.currentAppoint['host_meeting_link'] || '').length > 6 &&
                    (

                        <React.Fragment>
                            <p
                                style={{
                                    position: 'relative',
                                    margin: 0,
                                    marginTop: 8,
                                    textAlign: 'left',
                                    color: '#2577C2',
                                    fontSize: '12px',
                                    cursor: 'pointer'
                                }}
                            >
                                <u>
                                    Meeting ID: 
                                    {   
                                        " " + this.props.meetingId
                                    }
                                </u>
                            </p>

                            <p
                                style={{
                                    position: 'relative',
                                    margin: 0,
                                    textAlign: 'left',
                                    color: '#2577C2',
                                    fontSize: '12px',
                                    cursor: 'pointer'
                                }}
                            >
                                <u>
                                    Passcode: 
                                    {
                                        " " + this.props.meetingPass 
                                    }
                                </u>
                            </p>
                            
                        </React.Fragment>
                    )
                }
                
            </div>
                                                                                                


            {
                this.props.member ? (

                    this.props.member.length > 0 ? (

                        <div
                            className="row"
                            style={{
                                marginTop: 8
                            }}
                            // style={{
                            //     display: 'flex',
                            //     width: '100%',
                            //     marginTop: 8   
                            // }}
                        >
                            <div
                                className="col-l-1 col-m-1 col-s-1"
                            >

                                <div
                                    style={{
                                        position: 'relative',
                                        left: -5,
                                        width: '32.5px',
                                        height: '32.5px',
                                        borderRadius: '50%',
                                        background: 'lightblue',
                                        overflow: 'hidden'
                                    }}
                                >
                                    <img 
                                        // src={CompanyAvatar} 
                                        alt="Avatar client" 
                                        style={{
                                            width: '100%',
                                            height: '100%'
                                        }}
                                    />

                                </div>

                            </div>

                            <div
                                // style={{
                                //     width: '90%'
                                // }}
                                className="col-l-11 col-m-11 col-s-11"
                            >

                                <p
                                    style={{
                                        margin: 0,
                                        fontSize: '16px',
                                        color: '#263238',
                                        textAlign: 'left'
                                    }}
                                >
                                    {this.props.member[0]['nume']}
                                </p>

                                <p
                                    style={{
                                        margin: 0,
                                        fontSize: '9px',
                                        color: '#777777',
                                        textAlign: 'left'
                                    }}
                                >
                                {this.props.member[0]['functie']}
                                </p>

                            </div>

                        </div>
                    ) : null
                    
                ) : null
            }


            <div
                style={{
                    marginTop: 20,
                    marginBottom: 10,
                    width: '96%',
                    height: '1px',
                    background: '#EBEBEB'
                }}
            >
            </div>

            <div 
                //className="row" 
                style={{
                    display: 'flex',
                    // alignItems: 'center'
                }}
            >
                
                <div 
                    //className="col-l-1 col-m-1 col-s-1"
                    style={{
                        display: 'flex',
                        // alignItems: 'center',
                        justifyContent: 'center',
                        width: '22px'
                    }}
                >

                    <p 
                        style={{
                                position: 'relative', 
                                top: 2, 
                                left: 0, 
                                margin: 0,
                                color: '#A6A6A6',
                                fontSize: '20px'
                            }}
                    >
                        <ArticleIcon 
                            className="article-icon"
                        />  

                    </p>

                </div>

                <div 
                    //className="col-l-11 col-m-11 col-s-11"
                    style={{
                        
                        
                        textAlign: 'left'
                    }}
                >

                    <p 
                        style={{
                            position: 'relative', 
                            top: 4,
                            left: '10px',
                            margin: 0,
                            paddingBottom: '10px',
                            color: '#455A64',
                            fontWeight: '400',
                            fontSize: '14px'
                        }}
                    >
                        {this.props.currentAppoint['clie_detalii'] || 'n/a'}
                    </p>

                </div>
                
            </div>

            <div
                style={{
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '22px'
                    }}
                >

                    <p 
                        style={{
                            // display: 'inline-block', 
                            position: 'relative', 
                            top: 3, 
                            // left: -2, 
                            margin: 0,
                            color: '#A6A6A6',
                            fontSize: '22px'
                        }}
                    >
                        <AiOutlineMail />

                    </p>

                </div>

                <div>

                    <p 
                        style={{
                            // display: 'inline-block', 
                            position: 'relative', 
                            // top: -2,
                            left: '10px',
                            margin: 0,
                            color: '#455A64',
                            fontWeight: '400',
                            fontSize: '16px'
                        }}
                    >
                        {this.props.currentAppoint['clie_email']}
                    </p>

                </div>
                
            </div>

            <div 
                style={{
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '22px'
                    }}
                >

                    <p 
                        style={{
                            position: 'relative', 
                            top: 2, 
                            margin: 0,
                            color: '#A6A6A6',
                            fontSize: '22px'
                        }}
                    >
                        <MdStayPrimaryPortrait />

                    </p>

                </div>

                <div>

                    <p 
                        style={{
                            // display: 'inline-block', 
                            position: 'relative', 
                            // top: -2,
                            left: '10px',
                            margin: 0,
                            color: '#455A64',
                            fontWeight: '400',
                            fontSize: '16px'
                        }}
                    >
                        {this.props.currentAppoint['clie_tf']}
                    </p>

                </div>
                
            </div>

            {/* <div className="row">

                <div className="col-l-6 col-m-6 col-s-6">

                    <h3 
                        className="title"
                        style={{
                            textAlign: 'left',
                            margin: 0
                        }}
                    >
                        
                        <p 
                            style={{
                                    display: 'inline-block', 
                                    position: 'relative', 
                                    top: 4, 
                                    left: 0, 
                                    margin: 0,
                                    color: +this.state.currentAppoint.price > 0 ? '#A6A6A6' : '#31A81D',
                                    fontSize: '22px'
                                }}
                        >
                            <FaRegMoneyBillAlt />

                        </p>

                        <p 
                            style={{
                                display: 'inline-block', 
                                position: 'relative', 
                                top: -2,
                                left: 10,
                                margin: 0,
                                color: +this.state.currentAppoint.price > 0 ? '#455A64' : '#31A81D',
                                fontWeight: '400',
                                fontSize: '16px'
                            }}
                        >
                            {+this.state.currentAppoint.price > 0 ? this.state.currentAppoint.price + " Lei" : "Fara Tarif"} 
                        </p>
                        
                    </h3>

                </div>

                <div className="col-l-6 col-m-6 col-s-6">

                    <h3 
                        className="title"
                        style={{
                            position: 'relative',
                            right: 40,
                            textAlign: 'right',
                            margin: 0
                        }}
                    >
                        
                        <p 
                            style={{
                                    display: 'inline-block', 
                                    position: 'relative', 
                                    top: 4, 
                                    left: 0, 
                                    margin: 0,
                                    color: '#31A81D',
                                    fontSize: '22px'
                                }}
                        >
                            <MdPayment />

                        </p>

                        <p 
                            style={{
                                display: 'inline-block', 
                                position: 'relative', 
                                top: -2,
                                left: 10,
                                margin: 0,
                                color: '#31A81D',
                                fontWeight: '400',
                                fontSize: '16px'
                            }}
                        >
                            Platit
                        </p>
                        
                    </h3>

                </div>

            </div> */}

                               


            </>
        )
    }
}