import LocalStorage from './LocalStorage';
import Ws from './Ws';
import Masks from './Masks';
import Declarations from './Declarations';

//import aes from 'crypto-js/aes'
//import * as aesjs from '../libs/aes'

export default class Utils{

    WS = new Ws()
    M = new Masks();

     encKey = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16 ];
     encCounter = 5

    encryptText=(tx, activeLog = null)=>{

        let aesjs = require('../libs/aes')

        // Convert text to bytes
        var textBytes = aesjs.utils.utf8.toBytes(tx);

        // The counter is optional, and if omitted will begin at 1
        var aesCtr = new aesjs.ModeOfOperation.ctr(this.encKey, new aesjs.Counter(this.encCounter));
        var encryptedBytes = aesCtr.encrypt(textBytes);

        // To print or store the binary data, you may convert it to hex
        var encryptedHex = aesjs.utils.hex.fromBytes(encryptedBytes);

        if(activeLog){
            // 
        }
        
        return encryptedHex
    }

    decryptText = (encryptedHex,activeLog)=>{

        let aesjs = require('../libs/aes')
        var encryptedBytes = aesjs.utils.hex.toBytes(encryptedHex);

        var aesCtr = new aesjs.ModeOfOperation.ctr(this.encKey, new aesjs.Counter(this.encCounter));
        var decryptedBytes = aesCtr.decrypt(encryptedBytes);

        // Convert our bytes back into text
        var decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes);

        if(activeLog){

            // 
        }

        return decryptedText
    }


    renameKeysInObject = (obj, newKeys) =>{
        const keyValues = Object.keys(obj).map(key => {
          const newKey = newKeys[key] || key;
          return { [newKey]: obj[key] };
        });

        return Object.assign({}, ...keyValues);
    }

    renameKeysInArray = (arr, newKeys) => {
        
        for(let el in arr){
            // 
            arr[el] = this.renameKeysInObject(arr[el],newKeys)
            // 
        }

        return arr
    }

    translateHeaderData = (listaDeTradus,dictionar) => {
        //let dictionar = this.dictionary.licitatii.capLista

        let newKeys={}
        for(let k in dictionar){
            newKeys[k] = dictionar[k]
        }

        let newObject = this.renameKeysInArray(listaDeTradus,newKeys)
        return newObject
    }

    translateArray = (arr, dict) => {
        //  ATENTIE: VA MANIPULA PRIN REFERINTA ARRAYUL PRIMIT !!!
        
        for(let el in arr){
            if(dict[arr[el]]){
                arr[el] = dict[arr[el]]
            }
        }
        return arr
    }

    checkModuleAvailable = (modul, activeLog = null)=>{
        let LS = new LocalStorage()
        let setari = LS.GetSetariComerciant()

        if(setari){
            // tabela setari include codurile 
            let allowAccess = false
            switch(modul){
                case 'Licitatii':
                    allowAccess = setari.l1 === 'Da'  ;
                    break;               
            }

            if(activeLog){
                // 
                // 
            }
            return allowAccess
        }
        else
            return null;
    }

    checkUserRights = (modul,functie, activeLog = null)=>{

        let LS = new LocalStorage()
        let userRights = LS.GetUserRights()

        if(userRights.IsAdmin){
            if(activeLog){
                // 
            }
            return true
        }

        let allowAccess = false

        if(modul === 'Licitatii'){

            switch(functie){
                case 'Lista':
                    allowAccess =  userRights.AccessLicitatii || false
                    break; 
                case 'Add':
                    allowAccess =  userRights.CreazaLicitatii || false
                    break;
            }
        }
        // end modul licitatii

        if(activeLog){
            // 
            // 
        }

        return allowAccess
    }

    checkAccessToModule = (modul, functie,dictionar,activeLog = null)=>{

        let _allowModule = this.checkModuleAvailable(modul,activeLog)
        
        if(_allowModule){

            let _allowUser = this.checkUserRights(modul, functie, activeLog)

            if(_allowUser){
                return {
                    hasAccess:true,
                    headMessage:'',
                    textMessage:''
                }
            }
            else{
                return {
                    hasAccess:false,
                    headMessage:dictionar.comune.accesBlocatHead,
                    textMessage:dictionar.comune.accesBlocatText
                }
            }

        }
        else{
            return {
                hasAccess:false,
                headMessage:dictionar.comune.modulIndisponibilHead,
                textMessage:dictionar.comune.modulIndisponibilText
            }
        }
    }

    checkUserRightFromDb = async (slqColumnToCheck) => {

        let s = await new Ws().getData('User/GetAccesRights')
        if (s.Succes) {

            if (s.Content[0][slqColumnToCheck]) {

                if (s.Content[0][slqColumnToCheck] === 1) {
                    return true
                }
                else {
                    return false
                }
            }
            else {
                // 
            }

        }
        else {
            // 
            return null
        }
    }


    getAllUserRightsFromDb = async (slqColumnToCheck) => {

        let s = await new Ws().getData('User/GetAccesRights')
        if (s.Succes)
            return s.Content[0]
        else {
            // 
            return null
        }
    }


    ParseDataFromSql = (d)=>String(d).substring(0,10)


    ParseDateTimeFromSql = (s)=>{
        
        // '2019-09-02    16:44.900878'

        let sp = s.split('T')
        // 
        return ( sp[0] || '' ) + ' ' + ( sp[1] || '      ' ).substring(0, 5)
    }

    reduceLongTextFragment = (text, nrFirstWords, nrLastWords) => {
        // dintr-un text lung va afisa primele nrFirstWords si ultimele nrLastWords

        let doubleSpacesChecked = false
        let maxCheckNumbers = 1000

        let counter = 0;
        let prelText = text

        while ( doubleSpacesChecked === false  && counter < maxCheckNumbers){
            counter++
            prelText = prelText.replace('  ', ' ')
            
            // daca nu au mai ramas spatii duble ne putem opri
            if (prelText.indexOf(' ') === -1) {
                doubleSpacesChecked = true
            }
        }

        // 

        let textAsArr = prelText.split(' ')
        let rez = textAsArr.slice(0, nrFirstWords).join(' ')
        if ( textAsArr.length > 2 )
            rez+= ' ... ' + textAsArr.slice(Math.max(textAsArr.length - nrLastWords, 1)).join(' ')
        
        // 

        return rez
    }

    removeMultiCharFromString = (tx, ch) => {

        let doubleSpacesChecked = false
        const maxCheckNumbers = 1000

        let counter = 0;
        let prelText = tx

        while (doubleSpacesChecked === false && counter < maxCheckNumbers) {
            counter++
            prelText = prelText.replace(ch+ch, ch)

            // daca nu au mai ramas spatii duble ne putem opri
            if (prelText.indexOf(ch+ch) === -1) {
                doubleSpacesChecked = true
            }
        }

        return prelText
    }

    compareStringsAsDates = (dataUnu, dataDoi) => {
        // daca data1 > data2 retureaza -1
        // daca data1 == data2 returneaza 0
        // daca data2 > data1 returneaza 1

        //let sp1 = dataUnu.spl
    }


    randomData = (nr) => {
        let text = "";
        let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for (var i = 0; i < nr; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
    }

    generateToken = () => {
        return this.randomData(32)
    }


    getNumberOrNullFromString = (km)=>{
        if(!km)
            return null;

        if(isNaN(km))
            return null;
        
        return  Number(km);
    }
    

    addDaysToDate = (dt, nrZile) => {

        var result = new Date(dt);
        result.setDate(result.getDate() + nrZile);
        return result;

    }

        /*
    //////////////////////////////////

            Functii calendar 

    //////////////////////////////////
    */

    generateListaZile = (dateStart, dateEnd) => {

        // 

        if (dateStart.getTime() > dateEnd.getTime()) {
            console.error("Err genrrate lista zile: data start trebuie sa fie mai mica decat dataEnd")
            return null;
        }

        let M = new Masks()

        let rez = []
        let iteratingDate = dateStart

        let maxCounter = 0;
        const comparingDate = M.addDaysToDate(dateEnd, 1)
        while (M.compareDates(iteratingDate, comparingDate) === false && maxCounter < 1000) {

            rez.push(iteratingDate)

            iteratingDate = this.M.addDaysToDate(iteratingDate, 1)
            maxCounter++;
        }

        return rez;
    }

    setDisponibility = (serviceDetails) => {

        if( ( +serviceDetails['dispo_nr_zile'] > 0 ) && 
            ( (serviceDetails['dispo_data_end'] || '').length < 2 )
        ){
            
            const oneDay = 24 * 60 * 60 * 1000;
            const dataStartZile = this.M.sqlDateToJSDate(serviceDetails['dispo_data_start']);
            const dataEndZile = this.M.addDaysToDate(this.M.sqlDateToJSDate(serviceDetails['dispo_data_start']), +serviceDetails['dispo_nr_zile']);
            let todayDate = new Date();
            todayDate.setHours(0,0,0,0);

            let zileRamase = Math.round( Math.abs( (dataEndZile - todayDate) / oneDay ) );

            if(serviceDetails['dispo_tip_zile'] === 'work-days'){

                const dataWorkDaysEnd = this.addWorkDays(dataStartZile,  +serviceDetails['dispo_nr_zile'])

                const weekNumber = this.getWeekNumber(dataStartZile, dataWorkDaysEnd, this.diffWeeks(dataWorkDaysEnd, dataStartZile) );

                

                return dataWorkDaysEnd;
            }
            else{

                return dataEndZile;
            }   
        }

        else if( ( +serviceDetails['dispo_nr_zile'] === 0 ) && 
        ( (serviceDetails['dispo_data_end'] || '').length < 2) ){

            return new Date(2021, 0, 1);
        }

        else  if( ( +serviceDetails['dispo_nr_zile'] === 0 ) && ( (serviceDetails['dispo_data_start'] || '').length > 2 ) &&
        ( (serviceDetails['dispo_data_end'] || '').length > 2 )
        ){
            
            const dataEnd = this.M.sqlDateToJSDate(serviceDetails['dispo_data_end'])
            return dataEnd;
            
        }
    }

    hexToRgb = (hex) =>{

        let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        
        result = result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
            o: 0.1
            } : null;

        let color = 'rgb(' + result.r + ',' + result.g + ',' + result.b + ',' + result.o + ')';

        return color;
    }

    makeColorTransparent = (color) => {

        color = this.hexToRgb(color)

        // 

        return color;
    }

    getServicesColors = (serviceList) =>{
    
        const colorList = new Declarations().listaCulori;
        let serviceColorList = [];

        for(let i = 0; i < serviceList.length; i++){

            for(let j = 0; j < colorList.length; j++){

                if(colorList[j].val === serviceList[i].color){

                    serviceColorList.push({
                        
                        svToken: serviceList[i].token,
                        bgColor: this.makeColorTransparent(colorList[j].col),
                        txColor: colorList[j].col
                    })
                }
            }
        }

        return serviceColorList;
    }

    filterColors = (isBackground, serviceList, token, tip, draft) => {

        let color = '';

        if( (tip || '') == 'pauza' || (+draft || '') == 1){

            if(isBackground){

                return 'rgba(199, 199, 199, 0.3)';
            }
            
            return '#455A64';
        }
        

        if(isBackground){

            color = serviceList.filter(sEl => sEl.svToken === token)

            if(color.length > 0){

                return color[0].bgColor;
            }
            else{

                return '#FAFAFA';
            }
            
        }
        
        color = serviceList.filter(sEl => sEl.svToken === token)

        // 
        
        if(color.length > 0){

            return color[0].txColor;
        }
        else{

            return '#FAFAFA';
        }


        
    }

    setHumanReadableMonth = (luna) => {

        const listaLuni = new Declarations().listaLuni;

        let lunaCurenta = listaLuni.filter(mEl => mEl.idx === luna)

        return lunaCurenta[0].luna
    }

    addMinutes = (date, minutes) => {

        return  new Date(date.getTime() + minutes*60000);
    }

    convertToTime = (t) => {

        t = t.split(":");
        const time = new Date();
        time.setHours(t[0], t[1], 0);

        return time;
    }

       /*
    //////////////////////////////////

        Functii globale account

    //////////////////////////////////
    */
    

    getAccountAvAndName = async (isPublicPage, token) => {

        let selectAccNameQuery = ``;

        if(isPublicPage){

           selectAccNameQuery = `select display_name, avatar, web_link from accounts where token = ( select token from accounts where web_link = '${token}' ) `; 
        }
        else{

            selectAccNameQuery = `select display_name, avatar, web_link from accounts where token = '${token}' `
        }

        let accName = await this.WS.sqlCommand(`select`, selectAccNameQuery);

        if(accName.success){

            let accNameRez = '';
            let accAvatarRez = '';
            let accWebLinkRez = '';

            if(accName.content.length > 0){

                accNameRez = accName.content[0]['display_name'];
                accAvatarRez = accName.content[0]['avatar'];
                accWebLinkRez = accName.content[0]['web_link']
            }

            return {name: accNameRez, avatar: accAvatarRez, webLink: accWebLinkRez};
        }
        else{

            throw new Error("Error getting account name: " + accName.message);
        }
    }

    extractCompanyData = (companyData) => {

        if(companyData !== undefined && companyData !== null){

            if(Object.keys(companyData).length < 1){

                return { err: 'Eroare incarcare nume sau avatar.', data: null };
            }
            else{
    
                return {err: null, data: companyData}
            }
        }
        else{

            return { err: 'Eroare incarcare nume sau avatar.', data: null };
        }
    }

    getAccountLink = async () => {

        const TOKEN_CONT = new LocalStorage().GetAccountToken();

        let selectAccLinkQuery = `select web_link from accounts where token = '${TOKEN_CONT}' `; 
        let accLink = await this.WS.sqlCommand(`select`, selectAccLinkQuery);

        if(accLink.success){

            const accLinkRez = accLink.content[0]['web_link'];

            return accLinkRez;
        }
        else{

            throw new Error("Error getting account link: " + accLink.message);
        }
    }


      /*
    //////////////////////////////////

            Functii validare

    //////////////////////////////////
    */

    validateCui = (cui) => {

        if( (cui || '').length > 0){

            if(cui.includes("RO")){

                cui = cui.trim();
                cui = cui.substr(2, cui.length - 1);
            }
            cui = parseInt(cui);

            

            if(cui.toString().length > 10 || cui.toString().length < 2){

                
                return false;
            } 

            

            let controlNum = 753217532;

            let c1 = cui % 10;
            cui = parseInt(cui / 10);

            
            let t = 0;

            while(cui > 0){

                t += (cui % 10) * (controlNum % 10);
                cui = parseInt(cui / 10);
                controlNum = parseInt(controlNum / 10);
            }

            let c2 = t * 10 % 11;

            if(parseInt(c2) === 10){

                c2 = 0;
            }

            

            return parseInt(c1) === parseInt(c2);
        }
        else{

            return false;
        }
    }

    
      /*
    //////////////////////////////////

         Functii calcul calendare

    //////////////////////////////////
    */

    countWeekendDays = ( d0, d1 ) => {

        let ndays = 1 + Math.round((d1.getTime()-d0.getTime())/(24*3600*1000));
        let nsaturdays = Math.floor( (d0.getDay()+ndays) / 7 );
        return 2*nsaturdays + (d0.getDay()==0) - (d1.getDay()==6);
    }

    addWorkDays = (startDate, days) => {
        if(isNaN(days)) {
            
            return
        }
        if(!(startDate instanceof Date)) {
            
            return
        }
        // Get the day of the week as a number (0 = Sunday, 1 = Monday, .... 6 = Saturday)
        var dow = startDate.getDay();
        var daysToAdd = parseInt(days);
        // If the current day is Sunday add one day
        if (dow == 0)
            daysToAdd++;
        // If the start date plus the additional days falls on or after the closest Saturday calculate weekends
        if (dow + daysToAdd >= 6) {
            //Subtract days in current working week from work days
            var remainingWorkDays = daysToAdd - (5 - dow);
            //Add current working week's weekend
            daysToAdd += 2;
            if (remainingWorkDays > 5) {
                //Add two days for each working week by calculating how many weeks are included
                daysToAdd += 2 * Math.floor(remainingWorkDays / 5);
                //Exclude final weekend if remainingWorkDays resolves to an exact number of weeks
                if (remainingWorkDays % 5 == 0)
                    daysToAdd -= 2;
            }
        }
        startDate.setDate(startDate.getDate() + daysToAdd);
        return startDate;
    }

    diffWeeks = (dt2, dt1) => {

        var diff =(dt2.getTime() - dt1.getTime()) / 1000;
        diff /= (60 * 60 * 24 * 7);
        return Math.abs(Math.round(diff));
  
    }

    getWeekNumber = (dateStart, dateEnd, weeksNumber) => {

        // var numberOfDays =  Math.floor((dateEnd - dateStart) / (24 * 60 * 60 * 1000));
        
        // 

        // return Math.ceil(( dateStart.getDay() + 1 + numberOfDays) / 7) - weeksNumber;  
        
        // The number of milliseconds in one week
        const ONE_WEEK = 1000 * 60 * 60 * 24 * 7;
        // Convert both dates to milliseconds
        const date1_ms = dateEnd.getTime();
        const date2_ms = dateStart.getTime();
        // Calculate the difference in milliseconds
        const difference_ms = Math.abs(date1_ms - date2_ms);
        // Convert back to weeks and return hole weeks
        return weeksNumber - Math.floor(difference_ms / ONE_WEEK);
    }

     /*
    //////////////////////////////////

        Functii link-uri servicii

    //////////////////////////////////
    */

    onCopyLinkToClipBoard = async (serviceLink, userLink = null) => {
        
        const TOKEN_CONT = new LocalStorage().GetAccountToken();

        let selectWebLinkQuery = `select web_link from accounts where token = '${TOKEN_CONT}'    `;
        

        let webLink = await this.WS.sqlCommand(`select`, selectWebLinkQuery);
        

        if(webLink.success){

            let link;

            if(userLink !== null){

                link = "https://app.planary.ro/#/"+webLink.content[0]['web_link']+"/"+userLink;
            }
            else{

                link = "https://app.planary.ro/#/"+webLink.content[0]['web_link']+"/"+serviceLink+"/calendar";
            }

            navigator.clipboard.writeText(link);
            
            return {data: link, err: null};
        }
        else{

            return {data: null, err: 'Eroare copiere link serviciu'};   
        }
    }

    onClickViewPage = async (serviceLink, userLink = null) => {

        const TOKEN_CONT = new LocalStorage().GetAccountToken();

        let selectWebLinkQuery = `select web_link from accounts where token = '${TOKEN_CONT}'    `;
        

        let webLink = await this.WS.sqlCommand(`select`, selectWebLinkQuery);
        

        if(webLink.success){

            // const link = "https://app.planary.ro/#/"+webLink.content[0]['web_link']+"/"+serviceLink+"/calendar";

            let link;

            if(userLink !== null){

                link = "https://app.planary.ro/#/"+webLink.content[0]['web_link']+"/"+userLink;
            }
            else{

                link = "https://app.planary.ro/#/"+webLink.content[0]['web_link']+"/"+serviceLink+"/calendar";
            }

            return {data: link, err: null};
        }
        else{

            return {err: 'A aparut o eroare la redirectionarea catre pagina de programare.', data: null};
        }
    }

    // Verificam daca are wrk_token, daca nu are atunci nu si-a validat email-ul.
    onCheckIfUserHasWrkToken = async () => {

        let hasToken = await this.WS.getUserToken();

        

        if(hasToken.length > 0){

            return {success: true};
        } 
        else{

            return {success: false};
        }
    }


    //Verificam daca utilizatorul si-a completat campurile obligatorii:
    // CIF, Nr registru comert, Nume companie, link pg programari, adresa si oras,
    // daca sunt completate si acestea putem trece la pasul urmator: Crearea primului serviciu.
    onCheckIfUserHasCompleteAccountData = async () => {

        const TOKEN_CONT = new LocalStorage().GetAccountToken();

        if(TOKEN_CONT !== null && TOKEN_CONT !== undefined){

            let queryUserData = `select * from accounts where token = '${TOKEN_CONT}'  `;
            // 

            let accountData = await this.WS.sqlCommand(`select`, queryUserData);
            // 

            if(accountData.success){

                const data = accountData.content[0];

                if( (data['adresa'] || '').length > 0 && (data['cui'] || '').length > 0 && 
                    (data['display_name'] || '').length > 0 && (data['nume'] || '').length > 0 &&
                    (data['oras'] || '').length > 0 && (data['reg_com'] || '').length > 0 && 
                    (data['telefon'] || '').length > 0 && (data['web_link'] || '').length > 0
                ){

                    return {success: true};   
                }
                else{

                    return {success: false};
                }
            }
            else{

                return {success: false};
            }
        }
        else{

            return {success: false};
        }
    }   

    onGenerateMailTemplateAndSendToEmail = async (body, email, subject) => {

        if(email && body && subject){

            let postData = await this.WS.postData('notificari/emailindividual', {

                to: email,
                subject: subject,
                body: body
            });

            if(postData.success){

                return{success: true};
            }
            else{

                return{success: false}
            }

            
        }
        else{

            console.error("Incomplete data ")
            return{success: false}
        }
    }

    
    onCheckIfHasFirstService = async () => {

        let TOKEN_CONT = new LocalStorage().GetAccountToken();
        let querySelectService = `select * from services where account_tk = '${TOKEN_CONT}' and ifnull(draft,0)!=1 `;
        let services = await this.WS.sqlCommand(`select`, querySelectService);

        if(services.success){

            if(services.content.length > 0){

                return {success: true};
            }
            else{

                return {success: false};
            }
        }
        else{

            return {success: false};
        }
    }

}


