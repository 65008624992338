import React from 'react';
import ServicesFuncs from '../../module/logic/ServicesFuncs';
import LocalStorage from '../../providers/LocalStorage';

import Masks from '../../providers/Masks';
import Utils from '../../providers/Utils';
import Ws from '../../providers/Ws';

import AuthInput from '../AuthInput';

export default class SvPrice extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            tarif: this.props.tarif || 0,
            tarifTmp: this.props.tarif || 0,
            isNetopia: false
        };
    }

    WS = new Ws();
    M = new Masks();
    UT = new Utils();
    SF = new ServicesFuncs();

    componentDidMount(){

        this.setTarifRadio();
    }

    componentDidUpdate(prevPros, _){

        if(prevPros.triggerSave !== this.props.triggerSave){

            this.onGenerateQuery();
        }

        if(prevPros['tarif'] !== this.props['tarif']){

            this.setState({

                tarifTmp: this.props.tarif
            });
        }
    }
    
    onGenerateQuery = () => {

        const result = {

            data: [
                {
                    itemName: 'price',
                    itemData: `${this.state.tarif}`
                }
            ],
            err: null
        }

        // this.props.onCheckAndSave(result);
    }

    setTarifRadio = () => {

        this.setState({

            faraTarifRadio: +this.props.tarif === 0 ? true : false,
            faraTarifRadioTmp: +this.props.tarif === 0 ? true : false,
        },
            async () => {

                await this.onCheckIfIsNetopiaPayment();
            }
        );
    }

    onCheckIfIsNetopiaPayment = async () => {

        const accountTk = new LocalStorage().GetAccountToken();
        let result = await this.SF.getIntegrationsData(false, accountTk);

        if(result.err === null){

            let isNetopia = 
                result.data.accountPayments.findIndex(el => +el['is_netopia'] === 1) > -1;

            this.setState({

                isNetopia: isNetopia
            });
        }
    }

    onChangeTarif= (ev) => {

        let doSave = true;

        if(+ev.target.value === +this.state.tarifTmp){

            doSave = false;
        }

        this.setState({

            tarifStatus: doSave,
            tarif: +ev.target.value || 0
        },
            () => {

                // this.checkSaveButtonStatus()
                this.props.inputStatus(doSave, 'tarifStatus', 'tarif', +ev.target.value);
            }
        )
    }

    onChangeFaraTarif = () => {



        let doSave = true;
        let tarif = 0;

        if(!this.state.faraTarifRadio === this.state.faraTarifRadioTmp){

            doSave = false;
            tarif = this.state.tarifTmp;
        }

        this.setState({

            radioStatus: doSave,
            faraTarifRadio: !this.state.faraTarifRadio,
            tarif: tarif
        },
            () => {

                // this.checkSaveButtonStatus()
                this.props.inputStatus(doSave, 'radioStatusTarif', 'tarif', +tarif);
            }
        )

        // 
    }

    render(){

        return(

            <div 
                style={{
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                {
                    this.state.isNetopia ? (

                        <div 
                            style={{
                                // display: 'flex',
                                // alignItems: 'center',
                                width: '58.33%'
                            }}
                        >

                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >

                                <div 
                                    style={{
                                        width: '16.66%'
                                    }}
                                >

                                    <input 
                                        className="input-radio" type="radio" 
                                        checked={!this.state.faraTarifRadio}
                                        onChange={this.onChangeFaraTarif}
                                    />
                                    
                                </div>

                                <div
                                    style={{
                                        width: '83.33%'
                                    }}
                                >

                                    <div className="form-control">

                                        <AuthInput 
                                            label='Tarif Serviciu'
                                            type='number'
                                            value={this.state.tarif}
                                            onChange={this.onChangeTarif}
                                            disabled={this.state.faraTarifRadio}
                                            placeHolder="0"
                                        />
                                        
                                        <p 
                                            className="money-type"
                                            style={{
                                                top: '32px',
                                                marginLeft: 18,
                                                left: 10 * this.state.tarif.toString().length 
                                            }}
                                        >
                                            Lei
                                        </p>

                                    </div>
                                    
                                </div>

                            </div>

                        </div>
                    ) : null
                }

                <div
                    style={{
                        // display: 'flex',
                        // alignItems: 'center',
                        width: '41.66%'
                    }}
                >

                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}    
                    >

                        <div 
                            style={{
                                width: '33.33%'
                            }}
                        >

                            <div className="form-control">

                                <input 
                                    className="input-radio" type="radio" 
                                    checked={this.state.faraTarifRadio}
                                    onChange={this.onChangeFaraTarif}
                                />

                            </div>

                        </div>

                        <div 
                            style={{

                                width: '66.66%'
                            }}
                        >

                            <p
                                style={{
                                    position: 'relative',
                                    top: '-1px',
                                    textAlign: 'left',
                                    margin: 0
                                }}
                            >
                                Fara Tarif
                            </p>

                        </div>

                    </div>

                </div>

            </div>
        )
    }
}