import React from 'react';

import { HiCurrencyDollar } from 'react-icons/hi';
import { ImLocation2 } from 'react-icons/im';
import { MdAlarm } from 'react-icons/md';
import { RiCalendar2Fill } from 'react-icons/ri';
import { ReactComponent  as GMeetIcon} from '../../../assets/Google_Meet-Logo.wine.svg';
import Declarations from '../../../providers/Declarations';
import {ReactComponent as ZoomIcon } from '../../../assets/Zoom Icon.svg';
import { FaSkype } from 'react-icons/fa';

import Masks from '../../../providers/Masks';
import Utils from '../../../providers/Utils';
import Ws from '../../../providers/Ws';
import AuthInput from '../../../commons/AuthInput';
import { AiFillWarning } from 'react-icons/ai';
import ButtonForm from '../../../commons/ButtonForm';
import CrAppoInfo from './CrAppoInfo';
import ErrorImage from '../../../assets/Feeling sorry-cuate 1.png';
import ErrorMessage from '../../../commons/ErrorMessage';
import LocalStorage from '../../../providers/LocalStorage';
import ClientValidationFuncs from '../../logic/ClientValidationFuncs';
import CalendarClientFuncs from '../../logic/CalendarClientFuncs';
import ClientDataForm from '../../../commons/client_appointments/ClientDataForm';

export default class CrAppoClientForm extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            appointmentDetails: {},
            locationName: '',
            
            eroareTx: ''
        };
    }

    WS = new Ws();
    UT = new Utils();
    M = new Masks();
    CVF = new ClientValidationFuncs();
    CCF = new CalendarClientFuncs(); 

    componentDidMount(){

        this.getData();
    }

    componentsData = new Declarations().destructureDataObject(new Declarations().clientData);

    componentsStatus = {
        
        isClientDataOk: true
    };

    getData = async () => {

        let selectTrcQuery = `select * from trc where token = '${this.props.tranToken}'   `;
        

        let _clientAppointmentInfo = this.WS.sqlCommand(`select`, selectTrcQuery);
        
        let screenQuery = `update trc set screen = 'client-formular-ca' where token = '${this.props.tranToken}' `;
        let _updateScreen = this.WS.sqlCommand(`update`, screenQuery);

        let [clientAppointmentInfo, updateScreen] = await Promise.all( [_clientAppointmentInfo, _updateScreen ] );

        

        if(clientAppointmentInfo.success && updateScreen.success){

            this.componentsData = new Declarations().updateComponentsData(this.componentsData, clientAppointmentInfo.content[0], 'client');

            this.setState({

                appointmentDetails: clientAppointmentInfo.content[0]
            },
                () => {

                    this.setLocationName(clientAppointmentInfo.content[0])
                }
            )
        }
        else{

            this.setState({

                eroareTx: 'A aparut o eroare sever.'
            });
            
            throw new Error("Error fetching service data: " + clientAppointmentInfo.message);
        }
    }

    setLocationName = (appointment) => {

        const locationNameAppoint = new Declarations().listaLocatiiIntalnire.filter(lEl => lEl.id === appointment.location);

        this.setState({

            locationName: locationNameAppoint[0].descClient
        });
    }

    // onConfirmClientData = async () => {

    //     const ACCTOKEN = new LocalStorage().GetAccountToken();

    //     const errorTxOverlap = await this.CVF.checkIfAppointIsOverlaping(this.state.appointmentDetails['data_cal'], this.state.appointmentDetails['ora_cal'], ACCTOKEN, true);

    //     if(errorTxOverlap.length > 0){

    //         this.setState({

    //             eroareTx: errorTxOverlap
    //         });

    //         return;
    //     }

    //     const clientNameArr = this.state.name.split(" ");
    //     const firstName = clientNameArr[0];
    //     let lastName = '';

    //     for(let i = 1; i < clientNameArr.length; i++){

    //         lastName += clientNameArr[i] + ' ';
    //     }

    //     lastName = lastName.substr(0, lastName.length - 1) 

    //     

    //     const updateTrcQuery = `update trc set 
    //                             clie_nume = '${firstName}',
    //                             clie_prenume = '${lastName}',
    //                             clie_email = '${this.state.email}',
    //                             clie_tf = '${this.state.phone}',
    //                             clie_detalii = '${this.state.details}'

    //                             where token = '${this.props.tranToken}'
    //     `

    //     const updateTransactions = await this.WS.sqlCommand(`update`, updateTrcQuery);

    //     if(updateTransactions.success){

    //         let ss = await this.WS.getData('/invite/create', {trcToken: this.props.tranToken});
    //         
    //         if(ss.success){

    //             this.props.onShowClientAppointSuccess(this.props.tranToken);
    //             this.props.onUpdateCal();
    //         }
    //         else{

    //             this.setState({

    //                 eroareTx: 'Email-ul nu a fost trimis catre client.'
    //             })
    //         }
    //         // this.createPayment();
            
    //     }
    //     else{

    //         this.setState({

    //             eroareTx: 'Datele clientului nu au fost trimise catre server.'
    //         });
            
    //         throw new Error("Server error response: ", updateTransactions.message)
    //     }    
    // }

    inputStatus = (status, statusName, inputName, data, isVerify = false) => {

        if(isVerify){

            this.componentsStatus[statusName] = data;
        }
        else{

            this.componentsData[inputName] = data;
        }
    }

    onConfirmClientData = async (clientData) => {

        const ACCTOKEN = new LocalStorage().GetAccountToken();

        const errorTxOverlap = await this.CVF.checkIfAppointIsOverlaping(this.state.appointmentDetails['data_cal'], this.state.appointmentDetails['ora_cal'], ACCTOKEN, true);

        if(errorTxOverlap.length > 0){

            this.setState({

                eroareTx: errorTxOverlap
            });

            return;
        }
        const formResponse = await this.CCF.onSaveClientFormData(this.props.tranToken, clientData);

        if(formResponse.success){

            this.props.onShowClientAppointSuccess(this.props.tranToken);
            this.props.onUpdateCal();
        }
        else{

            this.setState({

                eroareTx: formResponse.err
            });
        }
    }
  
 

    onCheckDataAndSave = async () => {

       const result = Object.values(this.componentsStatus).every(item => item === true);

        if(result){

            const queryArr = new Declarations().generateQuerySet('client', this.componentsData)['clientInfo'];

            

            await this.onConfirmClientData(queryArr);
        }
        else{

            this.setState({

                triggerSave: false
            });
        }
    }

    onTriggerSave = () => {

        this.setState({

            triggerSave: true
        },
            () => {

                this.onCheckDataAndSave();
            }
        
        );
    }

    onCloseCreateAppointPopup = () => {

        this.props.onCloseCreateAppointPopup();
    }

    render(){

        return(

            <div
            >

                <div
                    style={{
                        width: '100%',
                        height: '1px',
                        background: '#EBEBEB'
                    }}
                ></div>

                <br />

                {
                    this.state.eroareTx.length > 0 && (

                        <ErrorMessage 
                        
                            eroareTx = {this.state.eroareTx}
                        />  
                    )
                }
                
                {
                    this.state.eroareTx.length === 0 && (

                        <div>

                        <CrAppoInfo 
                            serviceName = {this.state.appointmentDetails['service_name']}
                            duration = {this.state.appointmentDetails.duration}
                            price = {this.state.appointmentDetails.price}
                            location = {this.state.appointmentDetails.location}
                            locationName = {this.state.locationName}
                            date = {this.state.appointmentDetails['data_cal']}
                            hour = { this.state.appointmentDetails['ora_cal'] }
                            titleStyle = {{
                                margin: 0,
                                fontSize: '14px',
                                fontWeight: '700',
                                textAlign: 'center'
                            }}
                        />

                        <br />

                        <div
                            style={{
                                width: '100%',
                                height: '1px',
                                background: '#EBEBEB'
                            }}
                        ></div>

                        <br />

                        <p
                            style={{
                                margin: 0,
                                textAlign: 'center',
                                fontWeight: '700',
                                fontSize: '16px',
                                color: '#455A64'
                            }}
                        >
                            Datele Clientului
                        </p>

                        <br />

                       
                        <ClientDataForm 
                            tranData = {this.state.appointmentDetails}
                            triggerSave = {this.state.triggerSave}
                            inputStatus = {this.inputStatus}
                        />


                        <br />

                        <div
                            
                        >
                            <AiFillWarning 
                                style={{
                                    display: 'inline-block',
                                    position: 'relative',
                                    top: 4,
                                    margin: 0,
                                    fontSize: '20px',
                                    color: '#EB9481'
                                }}
                            />

                            <p
                                style={{
                                    display: 'inline-block',
                                    position: 'relative',
                                    margin: 0,
                                    fontSize: '14px',
                                    fontWeight: '500',
                                    color: '#455A64'
                                }}
                            >
                                &nbsp; Aceasta este o invitatie.
                            </p>

                        </div>

                        <p
                            style={{
                                margin: 0,
                                marginTop: 10,
                                fontSize: '12px',
                                fontWeight: '500',
                                color: '#455A64'
                            }}
                        >
                            Clientul tau va primi o <u>invitatie</u> la aceasta programare, pe care trebuie sa o accepte si sa o finalizeze. 
                            Clientul poate schimba data  si ora programarii, o poate refuza, sau o poate ignora.
                        </p>

                        <br />

                        <ButtonForm 
                            tx='Programeaza'
                            style={{
                                width: '157px',
                                background: '#F8BC05'
                            }}
                            onClick={this.onCheckDataAndSave}
                        />

                        </div>
                    )
                }

            </div>
        )
    }
}