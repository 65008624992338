import React from 'react';

import { IoReceiptOutline } from 'react-icons/io5';
import { IoVideocam } from 'react-icons/io5';
import { FaSkype } from 'react-icons/fa';
import { ImLocation2 } from 'react-icons/im';
import { ReactComponent  as GMeetIcon} from '../assets/Google_Meet-Logo.wine.svg';
import { MdAlarm } from 'react-icons/md';
import Declarations from '../providers/Declarations';
import Masks from '../providers/Masks';
import Utils from '../providers/Utils';

export default class AppointmentsHistoryElement extends React.Component{

    constructor(props){

        super(props);
    }

    M = new Masks();
    UT = new Utils();

    localData = {

        colors: new Declarations().listaCulori,
        locations: new Declarations().listaLocatiiIntalnire,
        todayDate: new Date()
    };

    render(){

        return(

            <div>
                                
                {
                    this.props.groupdByDays && 
                    this.props.groupdByDays.length > 0 &&
                    this.props.groupdByDays.map( (gdEl, idx) => {

                        let dayAppoints = this.props.appointments.filter(daEl => {

                            if(this.M.sqlDateToJSDate(daEl['data_cal']).getTime() === this.M.sqlDateToJSDate(gdEl['data_cal']).getTime()){

                                return daEl
                            }
                        })
                        
                        return(
                            <div key={`appoint-date-${idx}`} >
                            <div 
                                
                                className="row"
                            >

                                <div 
                                    className="col-l-2 col-m-2 col-s-2"
                                    style={{
                                        textAlign: 'left'
                                    }}
                                >

                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            width: '22px'
                                        }}
                                    >

                                        <p
                                            style={{
                                                margin: 0,
                                                color: '#A6A6A6',
                                                fontSize: '12px'
                                            }}
                                        >
                                            {this.M.monthName(this.M.sqlDateToJSDate(gdEl['data_cal']).getMonth()).substr(0,3).toUpperCase()}
                                        </p>

                                        <h3
                                            style={{
                                                position: 'relative',
                                                top: -2,
                                                margin: 0,
                                                color: '#A6A6A6',
                                                fontSize: '18px',
                                                textAlign: 'center'
                                            }}
                                        >
                                            {this.M.sqlDateToJSDate(gdEl['data_cal']).getDate()}
                                        </h3>

                                    </div>

                                </div>

                                <div
                                    className="col-l-10 col-m-10 col-s-10"
                                    style={{
                                        textAlign: 'left'
                                    }}
                                >

                                {   
                                    dayAppoints.length > 0 &&
                                    dayAppoints.map( (daEl, idx) => {

                                    const service = this.props.services.filter(sEl => sEl['token'] === daEl['service_tk']);
                                    const colorSv = this.localData.colors.filter(cEl => cEl['val'] === service[0]['color']);
                                    const locationSv = this.localData.locations.filter(lEl => lEl['id'] === daEl['location']);
                                    

                                    return(

                                            <div key={`day-appoint-no-${idx}`}>

                                                <div
                                                    className="row"
                                                >

                                                    <div
                                                        className="col-l-8 col-m-8 col-s-8"
                                                    >
                                                        <h3
                                                            style={{
                                                                position: 'relative',
                                                                top: 0,
                                                                margin: 0,
                                                                color: '#455A64',
                                                                fontSize: '14px'
                                                            }} 
                                                        >
                                                            {daEl['clie_nume'] + ' ' + daEl['clie_prenume']}

                                                        </h3>

                                                    </div>

                                                    <div 
                                                        className="col-l-4 col-m-4 col-s-4"
                                                        style={{
                                                            textAlign: 'right'
                                                        }}
                                                    >

                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'flex-end',
                                                            }}
                                                        >
                                                            <p
                                                                style={{
                                                                    margin: 0,
                                                                    paddingRight: '10px',
                                                                    fontWeight: '400',
                                                                    fontSize: '14px',
                                                                    color: '#263238'
                                                                }}
                                                            >

                                                                {
                                                                    +daEl['price'] > 0 ? `${daEl['price']} Lei` : 'Gratuit'
                                                                } 
                                                            </p>

                                                            <IoReceiptOutline
                                                                style={{
                                                                    fontSize: '12px',
                                                                    color: '#323232'
                                                                }}
                                                            />

                                                        </div>
                                                        
                                                    </div>

                                                </div>

                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        // justifyContent: 'flex-start',
                                                        marginTop: '8px'
                                                    }}
                                                >
                                                    <div
                                                        style={{

                                                            width: '18px'
                                                        }}
                                                    >

                                                        <div
                                                            style={{
                                                                width: '10px',
                                                                height: '10px',
                                                                borderRadius: '50%',
                                                                background: colorSv[0]['col']
                                                            }}
                                                        >

                                                        </div>

                                                    </div>
                                                    
                                                    <span
                                                        className='ah-servicename'
                                                        style={{
                                                            width: 'calc(100% - 20px)',
                                                            position: 'relative',
                                                            // top: '-2px',
                                                            margin: 0,
                                                            fontWeight: '400',
                                                            // fontSize: '10px',
                                                        }}
                                                    >
                                                        {daEl['service_name']}
                                                    </span>

                                                </div>

                                                

                                                <div 
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        marginTop: '8px'
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            width: '20px'
                                                        }}
                                                    >

                                                        <MdAlarm 
                                                            style={{
                                                                margin: 0,
                                                                fontSize: '16px',
                                                                color: '#A6A6A6'
                                                            }}
                                                        />

                                                    </div>

                                                    <span
                                                        style={{
                                                            position: 'relative',
                                                            top: '-2px',
                                                            margin: 0,
                                                            fontWeight: '400',
                                                            fontSize: '12px',
                                                            color: '#455A64'
                                                        }}
                                                    >
                                                        {daEl['ora_cal'].substr(0,5)} 
                                                        
                                                        {' '} - {' '}

                                                        {this.UT.addMinutes(this.UT.convertToTime(daEl['ora_cal'].substr(0,5)), daEl.duration).toTimeString().substr(0,5)}

                                                    </span>

                                                </div>
  

                                                <div 
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        marginTop: '8px'
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            width: '20px'
                                                        }}
                                                    >

                                                        {
                                                            daEl.location === 'zoom' && (
                                                                <IoVideocam 
                                                                    style={{
                                                                        margin: 0,
                                                                        fontSize: '16px',
                                                                        color: '#A6A6A6'
                                                                    }}
                                                                />
                                                            )
                                                        }

                                                        {
                                                            daEl.location === 'skype' && (
                                                                <FaSkype 
                                                                    style={{
                                                                        margin: 0,
                                                                        fontSize: '16px',
                                                                        color: '#A6A6A6'
                                                                    }}
                                                                />
                                                            )
                                                        }

                                                        {
                                                            daEl.location === 'office' && (
                                                                <ImLocation2 
                                                                    style={{
                                                                        margin: 0,
                                                                        fontSize: '16px',
                                                                        color: '#A6A6A6'
                                                                    }}
                                                                />
                                                            )
                                                        }

                                                        {
                                                            daEl.location === 'gmeet' && (

                                                                <GMeetIcon 
                                                                    className="gmeet-services"
                                                                        style={{
                                                                            position: 'relative',
                                                                            top: 1,
                                                                            width: '16px',
                                                                            height: '16px'
                                                                        }}
                                                                />
                                                            )
                                                        }

                                                    </div>

                                                    <span
                                                        style={{
                                                            position: 'relative',
                                                            top: '-2px',
                                                            margin: 0,
                                                            fontWeight: '400',
                                                            fontSize: '12px',
                                                            color: '#455A64'
                                                        }}
                                                    >
                                                        
                                                        {locationSv[0]['descClient']}

                                                    </span>

                                                </div>

                                                    
                                                    
                                                

                                                {
                                                    dayAppoints.length > 0 &&
                                                    dayAppoints.length - 1 > idx && (

                                                        <div
                                                            style={{
                                                                marginTop: 20,
                                                                marginBottom: 20,
                                                                width: '100%',
                                                                height: '1px',
                                                                background: '#EBEBEB'
                                                            }}
                                                        ></div> 
                                                    )
                                                }    

                                            </div>
                                            
                                    )

                                    })
                                    
                                }
                                    
                                
                                </div>

                            </div>

                            {
                                this.props.groupdByDays.length - 1 > idx && (

                                    <div
                                        style={{
                                            marginTop: 20,
                                            marginBottom: 20,
                                            width: '100%',
                                            height: '1px',
                                            background: '#EBEBEB'
                                        }}  
                                    ></div> 
                                )
                            }
                        </div>
                        )

                    })
                }

            </div>
        )
    }
}