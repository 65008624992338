import React from 'react';

import { MdError } from 'react-icons/md';
import { GoVerified } from 'react-icons/go';
import { IoCaretUp } from 'react-icons/io5';

import {
    BrowserView,
    MobileView,
    
} from "react-device-detect";

import './Commons.css';
import { BiCaretUp } from 'react-icons/bi';

export default class AuthInput extends React.Component{

    constructor(props){

        super(props)
    }

    // onChangeContent = () =>{

    //     this.props.onChangeContent()
    // }

    render(){
        
        let inputClass = 'form-input' + ' ' + this.props.className;
        // const mInputClass = 'm-form-input' + ' ' + this.props.className;
        
        if(this.props.verified || this.props.error){

            inputClass = 'form-input marker' + ' ' + this.props.className;
        }

        return(

            <div>
                
                {/* {
                    this.props.isMobile &&

                    <input 
                    className={mInputClass} 
                    type={this.props.type}
                    value={this.props.value}
                    placeholder={this.props.mPlaceholder}
                    iserror={this.props.error}
                    errormsg={this.props.errormsg}
                    // onChange={this.props.onChange}
                    onChange={this.props.onChange}
                    // onKeyDown ={this.props.onKeyDown}
                    verified={this.props.verified}
                    style={this.props.style} 
                    />

                } */}
     
                <input 
                    className={inputClass} 
                    type={this.props.type}
                    value={this.props.value}
                    placeholder={this.props.placeholder}
                    iserror={this.props.error}
                    errormsg={this.props.errormsg}
                    verified={this.props.verified || false}
                    style={this.props.style}
                    disabled={this.props.disabled || false} 
                    name={this.props.name}
                    onChange={this.props.onChange}
                    onFocus={this.props.onFocus}
                    onKeyDown={this.props.onKeyDown}
                />

               

                <label 
                    className="input-label"
                    style={this.props.labelStyle}
                >
                        {this.props.label}
                </label>    

                {this.props.verified && <GoVerified className="status-icon verified-icon"/>}

                {this.props.iserror && <MdError className="status-icon error-icon"/>}

                {
                    this.props.iserror && this.props.errormsg &&(

                        <div className="error-background">

                            <BiCaretUp
                                style={{
                                    position: 'absolute',
                                    color: '#EB9481',
                                    top: '-21.5px',
                                    right: 0,
                                    fontSize: '35px'
                                }}
                            
                            />

                            <p className="input-error">
                                
                                {this.props.errormsg}
                            </p>

                        </div>
                    )
                 
                }



            </div>

        );
    }
}