import React from 'react';

import ButtonForm from '../../../commons/ButtonForm';
import SelectInput from '../../../commons/SelectInput';

import Declarations from '../../../providers/Declarations';

export default class CrAppoAddBreak extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            durataCurenta: 30,
            errorTx: ''
        };
    }

    localData = {

        listaDurate: new Declarations().listaDurata
    }

    componentDidMount(){


    }

    onChangeDurata = (ev) => {

        this.setState({

            durataCurenta: ev.target.value
        });
    }

    onShowCalendar = () => {
        
        this.props.onShowCalendar(this.state.durataCurenta);
    }

    onCloseCreateAppointPopup = () => {

        this.props.onCloseCreateAppointPopup();
    }

    render(){

        return(

            <div
            >

                 <div
                    style={{
                        width: '100%',
                        height: '1px',
                        background: '#EBEBEB'
                    }}
                ></div>

                <br />

                <div className="form-control">

                    <SelectInput 
                        myKey='durata-pauza-no-'
                        label={'Durata Pauzei'}
                        listaDurataServiciu={this.localData.listaDurate}
                        durataCurenta={this.state.durataCurenta}   
                        onChange={this.onChangeDurata}

                        labelStyle={{
                            top: 6,
                            fontSize: '10px'
                        }}
                    />

                </div>
                
                <br />

                <ButtonForm 
                    tx='Selecteaza Data'
                    style={{
                        width: '177px',
                        fontSize: '16px',
                        background: '#F8BC05'
                    }}

                    onClick={this.onShowCalendar}
                />

            </div>
        )
    }
}