import React from 'react';

export default class IconText extends React.Component{

    constructor(props){

        super(props);
    }

    render(){

        return(

            <div
                style={{
                    ...this.props.style,
                    display: 'flex',
                    justifyContent: this.props.side || '',
                    alignItems: 'center',
                    top: this.props.top || '0px',
                    marginTop: this.props.containerTop || '0px',
                    width: this.props.containerW || '100%',
                    flexDirection: this.props.direction || 'row'
                }}
            >

                <div
                    style={{
                        display: 'flex',
                        // justifyContent: 'center',
                        alignItems: 'center',
                        width: this.props.distance || '32px'
                    }}
                >

                    {this.props.icon}

                </div>

                <div
                    className={this.props.isLimit ? 'm-description-wrapper' : ''}
                >
                    <p
                        style={{
                            position: 'relative',
                            top: this.props.txTop || '0px',
                            margin: 0,
                            width: this.props.txW || 'auto',
                            fontWeight: this.props.txWeight || '400',
                            fontSize: this.props.txSize || '16px',
                            color: this.props.txCol || '#455A64',
                        }}
                    >
                        {this.props.tx}
                    </p>

                </div>

            </div>
        );
    }
}