import Masks from "../../providers/Masks";
import Ws from "../../providers/Ws";
import Utils from '../../providers/Utils';
import ServicesFuncs from "./ServicesFuncs";

export default class CalendarClientFuncs{

    WS = new Ws();
    M = new Masks();
    UT = new Utils();
    SF = new ServicesFuncs();

    createScheduleArray = (schedule) =>{

        

        let days = [


            {
                zi_start: schedule.du_start === null ? null : schedule.du_start.substring(0,5),
                zi_end: schedule.du_end === null ? null : schedule.du_end.substring(0,5),
                day: 0
            },

            {
                zi_start: schedule.lu_start === null ? null : schedule.lu_start.substring(0,5),
                zi_end: schedule.lu_end === null ? null : schedule.lu_end.substring(0,5),
                day: 1
            },

            {
                zi_start: schedule.ma_start === null ? null : schedule.ma_start.substring(0,5),
                zi_end: schedule.ma_end === null ? null : schedule.ma_end.substring(0,5),
                day: 2
            },

            {
                zi_start: schedule.mi_start === null ? null : schedule.mi_start.substring(0,5),
                zi_end: schedule.mi_end === null ? null : schedule.mi_end.substring(0,5),
                day: 3
            },

            {
                zi_start: schedule.jo_start === null ? null : schedule.jo_start.substring(0,5),
                zi_end: schedule.jo_end === null ? null : schedule.jo_end.substring(0,5),
                day: 4
            },

            {
                zi_start: schedule.vi_start === null ? null : schedule.vi_start.substring(0,5),
                zi_end: schedule.vi_end === null ? null : schedule.vi_end.substring(0,5),
                day: 5
            },

            {
                zi_start: schedule.sa_start === null ? null : schedule.sa_start.substring(0,5),
                zi_end: schedule.sa_end === null ? null : schedule.sa_end.substring(0,5),
                day: 6
            }
        ];

        return days;
    }

    convertToTime = (t) => {

        const time = new Date();
        time.setHours(t[0], t[1], 0);

        return time;
    }

    getDuration = (t1, t2) =>{

       const duration = Math.abs(t2 - t1) / 1000 / 60;

       return Math.round(duration);
    }

    modifyDisponibility = (n, arr, avgDur, idx, brBefore, brAfter, svDuration) => {

        let duration = avgDur;
        let copyArr = arr;
        
        for(let i = idx; i < n; i++){

            if(arr[i].disponibilitate !== 0) {

                copyArr[i].disponibilitate = duration;
                copyArr[i].breakBefore = brBefore;
                copyArr[i].breakAfter = brAfter;
                copyArr[i].duration = svDuration;
            }  
             
            duration -= (60 + brBefore) //duration -= 30
        }

        return copyArr;
    }

    addMinutes = (date, minutes) => {

        return  new Date(date.getTime() + minutes*60000);
    }

    setIndispHours = (start, end, arr) => {

        let copyArr = arr

        

        for(let i = start; i < end; i++){

            copyArr[i].disponibilitate = 0;
        }

        return copyArr
    }

    setScheduleDisponibility = (hoursList, appointmentList, serviceDuration) => {

        hoursList[hoursList.length-1].disponibilitate = 0;
        let oraProgramareIdx = 0;
        let isOraProgramata = false;
        

        for(let i = 0; i < hoursList.length; i++){

            let duration = 0;
            let indispHours = [];

            if(isOraProgramata){

                isOraProgramata = false;
            }

            let startTime = hoursList[i].ora.split(":");
            let startH = this.convertToTime(startTime);

            let endTime = hoursList[hoursList.length-1].ora.split(":");
            let endH = this.convertToTime(endTime);

            if(!isOraProgramata){

                duration = this.getDuration(startH, endH)
                hoursList = this.modifyDisponibility(hoursList.length-1, hoursList, duration, i, 0, 0, +serviceDuration)
            }

            for(let j = 0; j < appointmentList.length; j++){

                if(hoursList[i].ora === appointmentList[j]['ora_cal'].substr(0,5)){

                    startTime = hoursList[oraProgramareIdx].ora.split(":");
                    startH = this.convertToTime(startTime);

                    endTime = hoursList[i].ora.split(":");
                    endH = this.convertToTime(endTime);
                    

                    
                    

                    duration = this.getDuration(
                        startH, endH
                    );    

                    hoursList[i].disponibilitate = 0;
                
                    let idx = Math.ceil( (parseInt(appointmentList[j].duration) + 
                                          parseInt(appointmentList[j].breakBefore) +
                                          parseInt(appointmentList[j].breakAfter) ) / 60);  

                    /*
                    
                        let idx = Math.ceil( (parseInt(appointmentList[j].duration) + 
                                          parseInt(appointmentList[j].breakBefore) +
                                          parseInt(appointmentList[j].breakAfter) ) / 30);

                    */
                    
                    // 

                    indispHours = this.setIndispHours(i, idx+i, hoursList);
                    // 
                
                    hoursList = this.modifyDisponibility(i, hoursList, duration, oraProgramareIdx, appointmentList[j].breakBefore, appointmentList[j].breakAfter, 30)

                    

                    oraProgramareIdx = i
                    isOraProgramata = true

                }
            }

        }

        return hoursList
        
    }

    /*
        Programare de la 14:00 - 14:30 => Serviciul are 30 de min cu pauza de 15 min inainte si 30 min dupa.

        Programare inainte de 14:00 (ora 13:00) => 

        Serviciu cu durata 30 de min si pauza inainte 15 min, pauza dupa 15 min
        
    */

    setOreProgram = (index, currentSchedule, duration, dayAppointments) => {

        let program = this.createScheduleArray(currentSchedule);
        
        let start = program[index].zi_start === null ? 0 : +program[index].zi_start.substring(0,2);
        let end = program[index].zi_end === null ? 0 : +program[index].zi_end.substring(0,2);

        let ore = []
        
        for(let i = start; i <= end; i++){

            if(i < 10){

                ore.push(
                    {
                        ora: '0'+i+':00',
                        disponibilitate: 1
                    }
                )    
            }
            else{
    
                ore.push(
                    {
                        ora: i+':00',
                        disponibilitate: 1
                    }
                )
            }
        }

        ore = this.setScheduleDisponibility(ore.map(oel => {

            return(
                {
                    ...oel,
                    duration: 0,
                    breakBefore: 0,
                    breakAfter: 0,
                }
            )
        }), dayAppointments, duration)

        // 

        return ore;
    }

    getServiceBreaksDuration = (serviceList, serviceTk ,isBreakBefore, tip) => {

        let breakDuration = 0;
        let getService = serviceList.filter(sEl => sEl.token === serviceTk);

        if( (tip || '') === 'pauza'){

            if(isBreakBefore){

                return 0;
            }
    
            return 0;
        }

        if(isBreakBefore){

            breakDuration = getService[0]['break_before'];
            return breakDuration;
        }

        breakDuration = getService[0]['break_after'];
        return breakDuration;
    } 

    onShowCalendarSchedule = async (date, isToken, token, currentSchedule, duration, isPublic) =>{

        // const index = this.M.sqlDateToJSDate(date.target.value).getDay()

        // 

        // let selectDayAppointments = `select * from trc where data_cal = '${date}' and account_tk = ( select token from accounts where web_link = '${this.props.match.params.company_web_link}' ) 
        //                                                                                     and service_tk = ( select token from services where web_link = '${this.props.match.params.service_web_link}' )
        //                                                                                     and draft = 0    `;

        let data = this.M.sqlDateToJSDate(date);

        let program = this.createScheduleArray(currentSchedule);
        let programNotNull = program.filter(pEl => {

            if(pEl.zi_start !== null && pEl.zi_end !== null){

                return pEl;
            }
        });
        let getProgramOnDay = programNotNull.filter(pEl => +pEl.day === +data.getDay());

        let startProgram = getProgramOnDay[0].zi_start.substr(0,5);
        let endProgram = getProgramOnDay[0].zi_end.substr(0,5);

        let selectDayAppointments = ``;
        let selectUnconfirmedAppointments = ``;
        let selectServices = ``;

        if(isPublic){

            if(isToken){

                selectDayAppointments = `select * from trc where data_cal = '${date}' and account_tk = ( select account_tk from trc where token = '${token}' ) 
                                                                                                    and ifnull(draft,0)!=1 and ifnull(anulat,0)!=1   `;
    
                selectUnconfirmedAppointments = ` select * from trc where account_tk = ( select account_tk from trc where token = '${token}' ) 
                and data_cal = '${date}' and tip = 'programare' and src = 'ca' and ifnull(draft,0)=1 and ifnull(anulat,0)!=1  `;
    
                selectServices = `select * from services where account_tk = ( select account_tk from trc where token = '${token}' ) and ifnull(draft,0)!=1 `;
            }
            else{
    
                selectDayAppointments = `select * from trc where data_cal = '${date}' and account_tk = ( select token from accounts where web_link = '${token}' ) 
                                                                                                    and ifnull(draft,0)!=1 and ifnull(anulat,0)!=1   `;
    
                selectUnconfirmedAppointments = ` select * from trc where account_tk = ( select token from accounts where web_link = '${token}' ) 
                and data_cal = '${date}' and tip = 'programare' and src = 'ca' and ifnull(draft,0)=1 and ifnull(anulat,0)!=1 `;
    
                // let selectServices = `select * from services where account_tk = ( select token from accounts where web_link = '${this.props.match.params.company_web_link}' ) and ifnull(draft,0)!=1 and ifnull(deleted,0)!=1 `;
                selectServices = `select * from services where account_tk = ( select token from accounts where web_link = '${token}' ) and ifnull(draft,0)!=1 `;
            }
        }
        else{

            selectDayAppointments = `select * from trc where data_cal = '${date}' and account_tk = '${token}'
                                                                                                and ifnull(draft,0)!=1 and ifnull(anulat,0)!=1   `;

        
            selectUnconfirmedAppointments = ` select * from trc where account_tk = '${token}' and data_cal = '${date}'
                                                    and tip = 'programare' and src = 'ca' and ifnull(draft,0)=1 and ifnull(anulat,0)!=1 `;

            selectServices = `select * from services where account_tk = '${token}' and ifnull(draft,0)!=1 `;
        }
                                                        
                                                
        let _dayAppointments = this.WS.sqlCommand(`select`, selectDayAppointments);
        let _unconfirmedAppoints = this.WS.sqlCommand(`select`, selectUnconfirmedAppointments);
        let _services = this.WS.sqlCommand(`select`, selectServices)

        let [dayAppointments, unconfirmedAppoints, services] = await Promise.all([_dayAppointments, _unconfirmedAppoints, _services]);

        const unconfirmedAppointsCalendar = unconfirmedAppoints.content.filter(uaEl => {

            if( (( uaEl['clie_nume'] || '').length > 0) && (new Date().getTime() < this.M.sqlDateToJSDate(uaEl['data_cal']).getTime()) ){

                return uaEl;
            }
        });

        const appointsFromClients = dayAppointments.content;
        let mergedAppoints = appointsFromClients.concat(unconfirmedAppointsCalendar);
                                                
        

        // let dayAppointments = await this.WS.sqlCommand(`select`, selectDayAppointments);
        

        

        

        if(dayAppointments.success && unconfirmedAppoints.success && services.success){

            mergedAppoints = mergedAppoints.map(maEl => {

                return(

                    {
                        ...maEl,
                        breakBefore: this.getServiceBreaksDuration(services.content, maEl['service_tk'], true, maEl.tip),
                        breakAfter: this.getServiceBreaksDuration(services.content, maEl['service_tk'], false, maEl.tip)
                    }
                )
            })
            

            const selectedDaySchedule = this.setOreProgram(+data.getDay(), currentSchedule, duration, mergedAppoints.filter(dEl => {

                    if(
                        ( this.UT.convertToTime(dEl['ora_cal'].substr(0,5)).getTime() >= this.UT.convertToTime(startProgram).getTime() ) && 
                        ( this.UT.convertToTime(dEl['ora_cal'].substr(0,5)).getTime() < this.UT.convertToTime(endProgram).getTime() )
                    ){

                        return dEl;
                    }
            }))



            // this.setState({
            //     showSchedule: true,
            //     dateSelected: date,
            //     daySelectedSchedule: selectedDaySchedule,
            //     confirmHour: ''
            // })


            return selectedDaySchedule;

        }
        else{

            throw new Error("Server response error: " + dayAppointments.message)
        }
        
    }

    onGetAppointmentsNumberFromCurrentMonth = (dayAppointments, subscription, month) => {

        let appointments = [];
        let isOverdue = false;

        if(dayAppointments.length > 0){

            appointments = dayAppointments.filter( (appointment) => this.M.sqlDateToJSDate(appointment.cand_creat).getMonth() === new Date().getMonth());
            
            if(subscription === 'basic' || subscription === 'gratuit'){

                isOverdue = appointments.length > 100 ? true : false;
            }
            else if(subscription === 'anteprenor'){

                isOverdue = appointments.length > 700 ? true : false;
            }
        }

        
        
        return isOverdue;
    }

    setAvailableAppointmentDays = async (dataStart, dataEnd, currentSchedule, serviceDetails, isToken, token, isPublic) => {

        const start = dataStart.getDate()
        const end = dataEnd.getDate()

        // const program = this.createScheduleArray(this.state.currentSchedule[0]);
        
        // 
        // 

        let appointmentDays = [];
        let it = 0;

        let selectDayAppointments = ``;
        let selectUnconfirmedAppointments = ``;
        let selectServices = ``;
        let querySubscription = ``;

        if(isPublic){

            if(!isToken){

                selectDayAppointments = `select * from trc where account_tk = ( select token from accounts where web_link = '${token}' )
                and ifnull(draft,0)!=1 and ifnull(anulat,0)!=1  `;
    
                selectUnconfirmedAppointments = ` select * from trc where account_tk = ( select token from accounts where web_link = '${token}' ) 
                                                        and tip = 'programare' and src = 'ca' and ifnull(draft,0)=1 and ifnull(anulat,0)!=1 `;
    
    
                selectServices = `select * from services where account_tk = ( select token from accounts where web_link = '${token}' ) and ifnull(draft,0)!=1 `;

                querySubscription = `select abonament from accounts where token = ( select token from accounts where web_link = '${token}' )`;
            }
            else{
    
                selectDayAppointments = `select * from trc where account_tk = ( select account_tk from trc where token = '${token}' )
                and ifnull(draft,0)!=1 and ifnull(anulat,0)!=1   `;
    
                selectUnconfirmedAppointments = ` select * from trc where account_tk = ( select account_tk from trc where token = '${token}' ) 
                    and tip = 'programare' and src = 'ca' and ifnull(draft,0)=1 and ifnull(anulat,0)!=1 `;
    
    
                selectServices = `select * from services where account_tk = ( select account_tk from trc where token = '${token}' ) and ifnull(draft,0)!=1 `;

                querySubscription = `select abonament from accounts where token = ( select account_tk from trc where token = '${token}' )`;
            }
        }
        else{

            selectDayAppointments = `select * from trc where account_tk = '${token}'
            and ifnull(draft,0)!=1 and ifnull(anulat,0)!=1   `;

            selectUnconfirmedAppointments = ` select * from trc where account_tk = '${token}' 
                                                    and tip = 'programare' and src = 'ca' and ifnull(draft,0)=1 and ifnull(anulat,0)!=1 `;

            
            selectServices = `select * from services where account_tk = '${token}' and ifnull(draft,0)!=1 `;

            querySubscription = `select abonament from accounts where token = '${token}' `;

        }
        

        let _dayAppointments = this.WS.sqlCommand(`select`, selectDayAppointments);
        let _unconfirmedAppoints = this.WS.sqlCommand(`select`, selectUnconfirmedAppointments);
        let _services = this.WS.sqlCommand(`select`, selectServices)
        let _subscription = this.WS.sqlCommand(`select`, querySubscription);

        let [dayAppointments, unconfirmedAppoints, services, subscription] = await Promise.all([_dayAppointments, _unconfirmedAppoints, _services, _subscription]);

        const unconfirmedAppointsCalendar = unconfirmedAppoints.content.filter(uaEl => {

            if( (( uaEl['clie_nume'] || '').length > 0) && (new Date().getTime() < this.M.sqlDateToJSDate(uaEl['data_cal']).getTime()) ){

                return uaEl;
            }
        });

        const appointsFromClients = dayAppointments.content;
        let mergedAppoints = appointsFromClients.concat(unconfirmedAppointsCalendar);
        let isAppointmentLimit = this.onGetAppointmentsNumberFromCurrentMonth(mergedAppoints, subscription.content[0]['abonament'], dataStart);

        let program = this.createScheduleArray(currentSchedule);
        let programNotNull = program.filter(pEl => {

            if(pEl.zi_start !== null && pEl.zi_end !== null){

                return pEl;
            }
        })

        let startProgram= '06:00';
        let endProgram = '00:00';
        
        // let start_program = program[1].zi_start === null ? 0 : program[1].zi_start.substring(0,5);
        // let end_program = program[1].zi_end === null ? 0 : program[1].zi_end.substring(0,5);
        
        
        let unavailableScheduleDays = program.filter(pEl => {

            if((pEl.zi_start === null || pEl.zi_end === null) ||
            (pEl.zi_start.length <= 2 || pEl.zi_end.length <= 2)){

                return pEl;
            }
        })

        mergedAppoints = mergedAppoints.map(maEl => {

            return(

                {
                    ...maEl,
                    breakBefore: this.getServiceBreaksDuration(services.content, maEl['service_tk'], true, maEl.tip),
                    breakAfter: this.getServiceBreaksDuration(services.content, maEl['service_tk'], false, maEl.tip)
                }
            )
        })

        // 

        const oneDay = 24 * 60 * 60 * 1000;
        const dataStartZile = this.M.sqlDateToJSDate(serviceDetails['dispo_data_start']);
        const dataEndZile = this.M.addDaysToDate(this.M.sqlDateToJSDate(serviceDetails['dispo_data_start']), +serviceDetails['dispo_nr_zile']);
        let todayDate = new Date();
        todayDate.setHours(0,0,0,0);

        const zileRamase = Math.round(Math.abs((dataEndZile - todayDate) / oneDay));

        // const weekendDiff = this.UT.countWeekendDays(dataStartZile, dataEndZile);
        // const endDateWorkDays = this.M.addDaysToDate(dataEndZile, weekendDiff);
        const endDateWorkDays = this.UT.addWorkDays(new Date(dataStartZile), +serviceDetails['dispo_nr_zile'])

        // 

        for(let i = start; i <= end; i++){

            let isInDisponibility = false;
     
            let data = this.M.addDaysToDate(dataStart, it)

            let isDayAvailalble = unavailableScheduleDays.filter(uEl => +uEl.day === +data.getDay())

            let diffHours = ( new Date().getTime() - data.getTime() ) / 1000;

            diffHours /= (60 * 60);
            diffHours = Math.abs(Math.round(diffHours));

            
            
            // let selectDayAppointments = `select * from trc where data_cal = '${this.M.specificDateToDateField(data)}' and account_tk = ( select token from accounts where web_link = '${this.props.match.params.company_web_link}' ) 
            //                                                                             and service_tk = ( select token from services where web_link = '${this.props.match.params.service_web_link}' )
            //                                                                             and draft = 0    `;

            if(dayAppointments.success && unconfirmedAppoints.success){

                let largest = 0;

                if(isDayAvailalble.length === 0){

                    let getProgramOnDay = programNotNull.filter(pEl => +pEl.day === +data.getDay());
                    startProgram = getProgramOnDay[0].zi_start.substr(0,5);
                    endProgram = getProgramOnDay[0].zi_end.substr(0,5);
                }
                
                let disponibilityArr = this.setOreProgram(+data.getDay(), currentSchedule, serviceDetails['duration'], mergedAppoints.filter(daEl => {

                        if((daEl['data_cal'].substr(0,10) === this.M.specificDateToDateField(data)) &&
                            ((this.UT.convertToTime(daEl['ora_cal'].substr(0,5)).getTime() >= this.UT.convertToTime(startProgram).getTime() )&& 
                            (this.UT.convertToTime(daEl['ora_cal'].substr(0,5)).getTime() < this.UT.convertToTime(endProgram).getTime()) )
                        ){
                            return daEl;
                        }
                    })
                )

                 if( ( +serviceDetails['dispo_nr_zile'] > 0 ) && 
                        ( (serviceDetails['dispo_data_end'] || '').length < 2 )
                    ){
                        
                        if(dataStartZile !== null){

                            if(serviceDetails['dispo_tip_zile'] === 'work-days'){

                                if( ( data.getTime() >= dataStartZile.getTime() ) && ( data.getTime() < endDateWorkDays.getTime() ) && ( (data.getDay() > 0) && (data.getDay() < 6) ) ){

                                    isInDisponibility= true;
                                }
                                
                            }
                            else if(serviceDetails['dispo_tip_zile'] === 'calendar-days'){

                                if( ( data.getTime() >= dataStartZile.getTime() ) && ( data.getTime() <= dataEndZile.getTime() ) ){

                                    isInDisponibility= true;
                                }
                            }

                            // if( ( data.getTime() >= dataStartZile.getTime() ) && ( data.getTime() <= dataEndZile.getTime() ) ){

                            //     isInDisponibility= true;
                            // }
                        }
                        else{

                            isInDisponibility= true;
                        }
                    }

                    else if( ( +serviceDetails['dispo_nr_zile'] === 0 ) && 
                    ( (serviceDetails['dispo_data_end'] || '').length < 2) ){

                        isInDisponibility = true;
                    }

                    else  if( ( +serviceDetails['dispo_nr_zile'] === 0 ) && ( (serviceDetails['dispo_data_start'] || '').length > 2 ) &&
                    ( (serviceDetails['dispo_data_end'] || '').length > 2 )
                    ){
  
                        const dataStartZile = this.M.sqlDateToJSDate(serviceDetails['dispo_data_start']);
                        const dataEndZile = this.M.sqlDateToJSDate(serviceDetails['dispo_data_end']);

                        if(( data.getTime() >= dataStartZile.getTime() ) && ( data.getTime() <= dataEndZile.getTime() ) ){

                            isInDisponibility= true;
                        }
                      
                    }
                    else{

                        isInDisponibility = true;
                    }

                // 
             
                for(let j = 0; j < disponibilityArr.length; j++){

                    if(disponibilityArr[j].disponibilitate > largest){

                        largest = disponibilityArr[j].disponibilitate
                    }                
                }

                let isAvailable = false

                if((largest >= (parseInt(serviceDetails.duration) + 
                parseInt(serviceDetails['break_before']) +
                parseInt(serviceDetails['break_after']) )) &&  
                ( isDayAvailalble.length === 0 ) && (isInDisponibility === true)
                && diffHours > 12 && !isAppointmentLimit
                // && (todayDate.getTime() !== data.getTime())
                
                ){

                    isAvailable = true;
                }

                appointmentDays.push({
                    date: data,
                    disponibil: isAvailable,
                    largest: largest
                })

            }
            else{
                throw new Error("Server error: days availability in calendar " + dayAppointments.message + '\n' + unconfirmedAppoints) 
            }

            it = it + 1
        }

        // this.setState({

        //     monthAppointments: appointmentDays
        // })

        return appointmentDays;

    }

    onSaveClientFormData = async (trcToken, clientData) => {

        // 

        let updateTrcQuery = this.SF.onSaveServiceData(clientData, false, trcToken, 'trc');
             
        // 

        let updateTransactions = await this.WS.sqlCommand(`update`, updateTrcQuery.data.substr(1, updateTrcQuery.data.length - 2));

        if(updateTransactions.success){

            let ss = await this.WS.getData('/invite/create', {trcToken: trcToken});
            
            if(ss.success){

                return {

                    err: null,
                    success: true
                }
            }
            else{

                return {

                    err: 'Email-ul nu a fost trimis catre client.',
                    success: false
                }
            }
        }
        else{

            return {

                err: 'Datele clientului nu au fost trimise catre server.',
                success: false
            }
        }
    }
}

