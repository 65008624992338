import React from 'react';

import InputValidation from '../../module/logic/InputValidation';

import ProfileFuncs from '../../module/logic/ProfileFuncs';
import Masks from '../../providers/Masks';
import Utils from '../../providers/Utils';
import Ws from '../../providers/Ws';

import AuthInput from '../AuthInput';

export default class UserDataInputs extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            display_name: this.props.data['display_name'] || '',
            web_link: this.props.data['web_link'] || '',
            email: this.props['email'] || '', 
            telefon: this.props.data['telefon'] || '',

            display_nameTmp: this.props.data['display_name'] || '',
            web_linkTmp: this.props.data['web_link'] || '',
            emailTmp: this.props['email'] || '',
            telefonTmp: this.props.data['telefon'] || '',
             
            isNumeValid: false,
            isLinkValid: false,
            isEmailValid: false,
            isTelefonValid: false,

            numeError: false,
            linkError: false,
            emailError: false,
            telefonError: false,
            
            numeErrorTx: '',
            linkErrorTx: '',
            emailErrorTx: '',
            telefonErrorTx: '',
        }
    }

    WS = new Ws();
    M = new Masks();
    UT = new Utils();
    PF = new ProfileFuncs();
    IV = new InputValidation();

    componentDidMount(){


    }

    componentDidUpdate(prevProps, _){
        
        if(prevProps.triggerSave !== this.props.triggerSave){

            this.onCheckInputs();
        }

        if(prevProps['data'] !== this.props['data']){

            this.setState({

                display_nameTmp: this.props.data['display_name'],
                web_linkTmp: this.props.data['web_link'],
                emailTmp: this.props['email'],
                telefonTmp: this.props.data['telefon'],
            });
        }
    }

    onSendInputStatus = async () => {

        let isAccountEmail = this.props.isAccountEmail && this.props.isAccountEmail !== null ? this.props.isAccountEmail : false

        const result = {

            nume: this.IV.onCheckName(this.state.display_name),
            email: await this.IV.onCheckEmail(this.state.email, isAccountEmail),
            link: await this.IV.onCheckAccountLink(this.state.web_link),
            telefon: this.IV.onCheckPhone(this.state.telefon)
        }

        const linkRes = this.props.hideLink ? true : result['link']['data']['isOk'];

        if(this.props.hideLink){

            if(result['nume']['isOk'] && result['email']['isOk'] && linkRes && result['telefon']['isOk']){

                this.props.inputStatus(true, 'isUserDataOk', 'isUserDataOk', true, true);
            }
            else{
    
                this.props.inputStatus(true, 'isUserDataOk', 'isUserDataOk', false, true);
            }
        }
        else if(this.props.hideUserData){

            if(linkRes){

                this.props.inputStatus(true, 'isUserDataOk', 'isUserDataOk', true, true);
            }
            else{
    
                this.props.inputStatus(true, 'isUserDataOk', 'isUserDataOk', false, true);
            }
        }
        
    }

    onCheckInputs = async () => {

        let isAccountEmail = this.props.isAccountEmail && this.props.isAccountEmail !== null ? this.props.isAccountEmail : false

        const checkRes = {

            nume: this.IV.onCheckName(this.state.display_name),
            email: await this.IV.onCheckEmail(this.state.email, isAccountEmail),
            link: await this.IV.onCheckAccountLink(this.state.web_link),
            telefon: this.IV.onCheckPhone(this.state.telefon)
        }

        this.setState({

            isEmailValid: checkRes['email']['verified'],
            emailError: checkRes['email']['error'],
            emailErrorTx: checkRes['email']['errorTx'],

            isNumeValid: checkRes['nume']['verified'],
            numeError: checkRes['nume']['error'],
            numeErrorTx: checkRes['nume']['errorTx'],

            isTelefonValid: checkRes['telefon']['verified'],
            telefonError: checkRes['telefon']['error'],
            telefonErrorTx: checkRes['telefon']['errorTx'],
            
            isLinkValid: checkRes['link']['data']['verified'],
            linkError: checkRes['link']['data']['error'],
            linkErrorTx: checkRes['link']['data']['errorTx'],
        });
        
    }

    onModifyInput = (ev) => {

        let doSave = true;

        if(ev.target.value === this.state[ev.target.name+'Tmp']){

            doSave = false;
        }

        this.setState({

            [ev.target.name] : ev.target.value,
        },
        
            () => {

                this.props.inputStatus(doSave, ev.target.name+'Status', ev.target.name , ev.target.value);

                let duration = 500;
                clearTimeout(this.inputTimer);
                    this.inputTimer = setTimeout( async ()=>{
                        await this.onSendInputStatus();
                }, duration);
            }
        );

        // 
        
    }

    render(){

        return(

            <div style={this.props.style}>

                {
                    !this.props.hideUserData && (

                        <div 
                            className="form-control"
                            style={{
                                marginTop: 5
                            }}
                        >

                            <AuthInput 
                                label='Nume pe pagina de programari'
                                labelStyle={{
                                    fontSize: '10px',
                                    top: 6
                                }}
                                value={this.state.display_name}
                                name='display_name'
                                onChange={this.onModifyInput}
                                iserror={this.state.numeError}
                                errormsg={this.state.numeErrorTx}
                                verified={this.state.isNumeValid}
                                onFocus = {
                                    () => {

                                        this.setState({
                                            numeErrorTx: false
                                        })
                                    }
                                }
                            />

                        </div>
                    )
                }

                {
                    !this.props.hideLink && (

                        <div 
                            className="form-control"
                            style={{
                                marginTop: 10
                            }}
                        >

                            <AuthInput 
                                label='Link'
                                labelStyle={{
                                    fontSize: '10px',
                                    top: 6
                                }}
                                value={this.state.web_link}
                                name='web_link'
                                onChange={this.onModifyInput}
                                iserror={this.state.linkError}
                                errormsg={this.state.linkErrorTx}
                                verified={this.state.isLinkValid}
                                onFocus = {
                                    () => {

                                        this.setState({
                                            linkErrorTx: null
                                        })
                                    }
                                }
                                disabled={this.props.disableLink || false}
                            />

                        </div>
                    )
                }

                {
                    !this.props.hideUserData && (

                        <>

                            <div 
                                className="form-control"
                                style={{
                                    marginTop: 10
                                }}
                            >

                                <AuthInput 
                                    label='Adresa Email'
                                    labelStyle={{
                                        fontSize: '10px',
                                        top: 6
                                    }}

                                    value={this.state.email}
                                    name='email'
                                    onChange={this.onModifyInput}
                                    iserror={this.state.emailError}
                                    errormsg={this.state.emailErrorTx}
                                    verified={this.state.isEmailValid}
                                    onFocus = {
                                        () => {

                                            this.setState({
                                                emailErrorTx: null
                                            })
                                        }
                                    }
                                    disabled={this.props.disableEmail || false}
                                />

                            </div>

                            <div 
                                className="form-control"
                                style={{
                                    marginTop: 10
                                }}
                            >

                                <AuthInput 
                                    label='Numar de Telefon'
                                    labelStyle={{
                                        fontSize: '10px',
                                        top: 6
                                    }}
                                    value={this.state.telefon}
                                    name='telefon'
                                    onChange={this.onModifyInput}
                                    iserror={this.state.telefonError}
                                    errormsg={this.state.telefonErrorTx}
                                    verified={this.state.isTelefonValid}
                                    onFocus = {
                                        () => {

                                            this.setState({
                                                telefonErrorTx: null
                                            })
                                        }
                                    }
                                />

                            </div>
                        
                        </>
                    )
                }

            </div>
        )
    }
}