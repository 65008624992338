import React from 'react';

import { IoIosArrowForward } from 'react-icons/io';
import ButtonForm from '../../../commons/ButtonForm';
import IconText from '../../../commons/IconText';
import { ReactComponent as PinDrop } from '../../../assets/pin_drop.svg';

import Masks from '../../../providers/Masks';
import Utils from '../../../providers/Utils';
import Ws from '../../../providers/Ws';
import ServicesFuncs from '../../logic/ServicesFuncs';
import RegisterHeader from '../RegisterHeader';
import SvLocation from '../../../commons/service/SvLocation';
import Declarations from '../../../providers/Declarations';
import { BrowserView, MobileView } from 'react-device-detect';
import ButtonsNavRegister from '../../../commons/ButtonsNavRegister';

import CanaryPlantImage from '../../../assets/canary_plant_bg.png';
import MBirdAndPlantImage from '../../../assets/bird_and_plant_mobile.png';
import LocalStorage from '../../../providers/LocalStorage';
import Loader from '../../../commons/Loader';

export default class LocConfig extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            locatieCurenta: '',
            integrations: {},
            triggerSave: false,
            isLoading: true,
            serviceTk: ''
        };
    }

    componentData = new Declarations().serviceData['location'];

    componentStatus = {

        isLocationOk: true
    };

    WS = new Ws();
    M = new Masks();
    UT = new Utils();
    SF = new ServicesFuncs();

    componentDidMount(){

        this.getLocationData();
        this.getServiceData();
    }
    

    getServiceData = async () => {

        const TOKEN_CONT = new LocalStorage().GetAccountToken();

        let querySelectService = `select * from services where account_tk = '${TOKEN_CONT}' `;
        let service = await this.WS.sqlCommand(`select`, querySelectService);

        if(service.success){

             this.componentData.locatie = service.content[0]['location'];

            this.setState({

                locatieCurenta: service.content[0]['location'],
                serviceTk: service.content[0]['token'],
            })
        }

        this.setState({

            isLoading: false
        })
    }

    getLocationData = async () => {

        const integrations = await this.SF.getIntegrationsData();

        if(integrations.err !== null){


        }
        else{
            this.setState({

                integrations: integrations
            });
        }   
    }

    inputStatus = (status, statusName, inputName, data, isVerify = false) => {

        if(isVerify){

            this.componentStatus[statusName] = data;
        }
        else{

            this.componentData[inputName] = data;

            
    
            this.setState({
                
                [statusName]: status
            },
                () => {
    
                    // this.checkSaveButtonStatus();
                }
            );
        }
    }

    onCheckAndSave = async () => {

        const result = Object.values(this.componentStatus).every(item => item === true);;
        const TOKEN_CONT = new LocalStorage().GetAccountToken();

        
        
        if(result){

            // const queryObject = new Declarations().generateQuerySet('profile', this.componentsData);
            // const queryArr = new Declarations().onGenerateQueryArray(queryObject);

            let queryUpdateService = `update services set 
                    location = '${this.componentData.locatie}' where token = '${this.state.serviceTk}'
                `;

            let updateService = await this.WS.sqlCommand(`update`, queryUpdateService);

            if(updateService.success){

                // this.props.history.push('');
                this.props.history.push("/inregistrare/serviciu/disponibilitate-serviciu");
            }
        }
        else{

            
        }
    }

    onTriggerSave = () => {

        this.setState({

            triggerSave: true
        },
        
            () => {

                this.onCheckAndSave();
            }
        )
    }


    render(){

        if(this.state.isLoading){

            return(

                <Loader 
                    fullPage = {true}
                />
            )
        }

        return(
            <>

            <BrowserView>

                <div className='register-container-desktop'>

                    <RegisterHeader isServiceStep = {true}/>

                    <div className='service-details-card' style={{marginTop: '20px', textAlign: 'left'}}>

                        <IconText 
                            icon = {
                                <PinDrop />
                            }
                            tx = 'Unde se va desfasura Programarea?'
                            txSize = '16px'
                            txWeight = '500'
                            txCol = '#455A64'
                        />

                        <div className='row' style={{marginTop: '16px'}}>

                            <div className='col-l-6 col-m-6 col-s-6'>         

                                <p className='register-tx'>

                                    Planary este conceput sa faciliteze programarea unei intalniri. In ziua de astazi Intalnirea video devine din ce in ce mai comuna. 
                                    <br />
                                    <br />
                                    Locatia programarii sau serviciului tau, asa cum o va vedea clientul tau in panoul de Programari poate fi locatia 
                                    fizica a sediului tau, dar si o camera virtuala de intalnire prin servicii ca Zoom sau Skype.

                                    
                                </p>

                            </div>

                            <div className='col-l-6 col-m-6 col-s-6' style={{paddingLeft: '20px'}}>

                                <p className='register-tx'>Alege una din locatiile disponibile.</p>

                                {
                                    this.state.integrations['data'] && 
                                    (

                                        <SvLocation 
                                            showAlt = {true}
                                            locatieCurenta = {this.state.locatieCurenta}
                                            integrations = {this.state.integrations}
                                            triggerSave = {this.state.triggerSave}
                                            inputStatus = {this.inputStatus}
                                        />
                                    )
                                }

                                <ButtonForm 
                                    type='button'
                                    tx={
                                        <>
                                            <p style={{display: 'inline-block', position: 'relative', top: '-2px', margin: 0}}>
                                                Continua &nbsp;
                                            </p> 
                                            <IoIosArrowForward className='register-next-arrow'/>
                                        </>
                                    }
                                    
                                    style={{
                                        marginTop: '20px',
                                        width: 'auto',
                                        height: '56px',
                                        background: '#F8BC05',
                                        fontSize: '16px',
                                        padding: '0px 35px 0px 35px',
                                    }}
                                    onClick={
                                        this.onTriggerSave
                                    }
                                />
                                
                            </div>


                        </div>

                    </div>

                    <img 
                        className='bottom-pg-img'
                        src={CanaryPlantImage}
                        alt = 'Planary...'
                        style={{
                            marginTop: '20px'
                        }}
                    />

                </div>

            </BrowserView>

            <MobileView>

            <div 
                style={{
                    padding: '20px',
                    minHeight: '850px',
                    background: `url(${MBirdAndPlantImage}) bottom no-repeat`
                }}
            >
                    
                    <RegisterHeader isServiceStep = {true} />
                    
                    <div className="m-edit-s-card" style={{marginTop: '20px', textAlign: 'left'}}>

                        <IconText 
                            icon = {
                                <PinDrop />
                            }
                            tx = 'Unde se va desfasura Programarea?'
                            txSize = '16px'
                            txWeight = '500'
                            txCol = '#455A64'
                        />
                        <p 
                            className='register-tx sm'
                            style={{marginTop: '16px'}}
                        >

                            Locatia programarii sau serviciului tau, asa cum va vedea clientul tau in panoul de Programari.
  
                        </p>

                        {
                            this.state.integrations['data'] && 
                            (

                                <SvLocation 
                                    showAlt = {true}
                                    locatieCurenta = {this.state.locatieCurenta}
                                    integrations = {this.state.integrations}
                                    triggerSave = {this.state.triggerSave}
                                    inputStatus = {this.inputStatus}
                                />
                            )
                        }
                        
                        <ButtonsNavRegister 
                            tx = 'Continua'
                            onPressBtn = {this.onTriggerSave}
                            onBack = {this.props.history.goBack}
                        />

                    </div>
                    
                </div>

            </MobileView>
            </>
        );
    }
}