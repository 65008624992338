import React from 'react';
import ServicesFuncs from '../../module/logic/ServicesFuncs';
import Declarations from '../../providers/Declarations';
import Masks from '../../providers/Masks';
import Utils from '../../providers/Utils';
import Ws from '../../providers/Ws';
import AuthInput from '../AuthInput';
import SelectOthers from '../SelectOthers';

export default class SvDisponibility extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            listaTipZile: [],
            nrZile: +this.props.serviceDetails['dispo_nr_zile'] || 30,
            nrZileTmp: +this.props.serviceDetails['dispo_nr_zile'] || 30,
            tipZile: this.props.serviceDetails['dispo_tip_zile'] || 'work-days',
            tipZileTmp: this.props.serviceDetails['dispo_tip_zile'] || 'work-days',

            zileInput: true, 
            dateInput: false,
            nelimInput: false,

            zileInputTmp: true, 
            dateInputTmp: false,
            nelimInputTmp: false,

            showDateSelector: false,
            startCalRange: (this.props.serviceDetails['dispo_data_start'] || '').substr(0,10),
            endCalRange: (this.props.serviceDetails['dispo_data_end'] || '').substr(0,10),
            startCalRangeTmp: (this.props.serviceDetails['dispo_data_start'] || '').substr(0,10),
            endCalRangeTmp: (this.props.serviceDetails['dispo_data_end'] || '').substr(0,10),
            dateSelectorOption: [
                {
                    val: 'Neselectat',
                    tx: 'Data Inceput / Data Sfarsit'
                }
            ],
            dateSelectorOptionTmp: [
                {
                    val: 'Neselectat',
                    tx: 'Data Inceput / Data Sfarsit'
                }
            ],
            isEndRangeSelectorDisabled: true,
        };
    }

    WS = new Ws();
    M = new Masks();
    UT = new Utils();
    SF = new ServicesFuncs();

    componentDidMount(){

        this.setDisponibilityData();
    }

    componentDidUpdate(prevProps, _){

        if(prevProps.triggerSave !== this.props.triggerSave){

            this.verifyDatesValues();
        }

        if(prevProps.serviceDetails !== this.props.serviceDetails){


            this.setState({

                tipZile: this.props.serviceDetails['dispo_tip_zile'],
                tipZileTmp: this.props.serviceDetails['dispo_tip_zile'],
                startCalRangeTmp: (this.props.serviceDetails['dispo_data_start'] || '').substr(0,10),
                endCalRangeTmp: (this.props.serviceDetails['dispo_data_end'] || '').substr(0,10),
            },
                () => {

                    this.setDisponibilityData();
                }
            );
        }
    }

    setDisponibilityData = () => {

        let dateForSelect = [

            {
                val: 'Neselectat',
                tx: 'Data Inceput / Data Sfarsit'
            }
        ]

        let disponibilityDays = this.state.nrZile;

        if( (this.props.serviceDetails['dispo_data_start'] || '').length > 2 &&
            (this.props.serviceDetails['dispo_data_end'] || '').length > 2
        ){

            dateForSelect = [

                {
                    val: 'Selectat',
                    tx: this.M.dataFriendlyFromSql(this.props.serviceDetails['dispo_data_start']) + ' ' + this.M.sqlDateToJSDate(this.props.serviceDetails['dispo_data_start']).getFullYear() + ' / ' + this.M.dataFriendlyFromSql(this.props.serviceDetails['dispo_data_end']) +
                    ' ' + this.M.sqlDateToJSDate(this.props.serviceDetails['dispo_data_end']).getFullYear()
                }
            ]  
        }
        else if( ( +this.props.serviceDetails['dispo_nr_zile'] > 0 ) && 
        ( (this.props.serviceDetails['dispo_data_end'] || '').length < 2)
        ){

            const oneDay = 24 * 60 * 60 * 1000;
            const dataStartZile = this.M.sqlDateToJSDate(this.props.serviceDetails['dispo_data_start']);
            const dataEndZile = this.M.addDaysToDate(this.M.sqlDateToJSDate(this.props.serviceDetails['dispo_data_start']), +this.props.serviceDetails['dispo_nr_zile']);
            let todayDate = new Date();
            todayDate.setHours(0,0,0,0);

            disponibilityDays = Math.round(Math.abs((dataEndZile - todayDate) / oneDay));

            if(this.props.serviceDetails['dispo_tip_zile'] === 'work-days'){

                disponibilityDays = this.SF.calculateWorkingDays(this.props.serviceDetails, dataStartZile, todayDate, oneDay);
            }

        }

        this.setState({

            nrZile: disponibilityDays,
            nrZileTmp: disponibilityDays,
            dateSelectorOption: dateForSelect,
            dateSelectorOptionTmp: dateForSelect,
        },
        
            () => {

                this.setRadioByServiceDisponibility();
                this.setListaZile();
            }
        );
    }

    setRadioByServiceDisponibility = () => {

        let radioInputs = this.SF.setCurrentDisponibilityRadio(this.props.serviceDetails);

        this.setState({

            zileInput: radioInputs.daysInput, 
            dateInput: radioInputs.calendarInput,
            nelimInput: radioInputs.unlimInput,

            zileInputTmp: radioInputs.daysInput, 
            dateInputTmp: radioInputs.calendarInput,
            nelimInputTmp: radioInputs.unlimInput,
        },
        
            () => {

            }
        );
    }

    setListaZile = () => {

        let tipZile = new Declarations().tipZile;

        this.setState({

            listaTipZile: tipZile
        });
    }

    radioValidation = (value, radio, stateName) =>{

        let isChecked = radio;
        let doSave = true;
        let dataStart = '';

        if(isChecked){

            if(isNaN(value)){

                if(value === this.state[stateName+'Tmp']){

                    doSave = false;
                }
            }
            else{

                if(+value === +this.state[stateName+'Tmp']){

                    doSave = false;
                }
            }

            if(stateName === 'nrZile' || stateName === 'tipZile'){

                dataStart = this.M.specificDateToDateField(new Date());

                // if(this.state[stateName] === this.state[stateName+'Tmp']){

                //     doSave = false;
                // }
            } 

            this.setState({

                [stateName]: value,
                startCalRange: dataStart,
                endCalRange: ''
            },
                () => {

                    this.props.inputStatus(doSave, 'radioStatusDispo', 'dispoDataStart', `'` + dataStart + `'`);
                    this.props.inputStatus(doSave, 'radioStatusDispo', 'dispoDataEnd', null);

                    if(stateName === 'nrZile'){

                        this.props.inputStatus(doSave, `${stateName}Status`, 'dispoNrZile', value);
                    }
                    else if(stateName === 'tipZile'){

                        this.props.inputStatus(doSave, `${stateName}Status`, 'dispoTipZile', value);
                    }
                    
                }
            );
        }
    }

    onChangeRadio = (ev) => {
        
        let doSave = true;
        let nrZileDispo = this.state.nrZile; 
        let dataStart = this.M.specificDateToDateField(new Date());
        let dataEnd = null;
        let inputs = ['zileInput', 'dateInput', 'nelimInput'];
        let notSelectedRadios = inputs.filter(iEl => iEl !== ev.target.name);

        let todayDate = new Date();
        todayDate.setHours(0, 0, 0, 0);

        if(this.state[`${ev.target.name}Tmp`] === true){

            doSave = false;
        }

        if(ev.target.name === 'nelimInput'){

            nrZileDispo = 0;
        }
        else if(ev.target.name === 'zileInput'){

            if(this.state.nrZileTmp > 0){

                nrZileDispo = this.state.nrZileTmp;
            }
            else{

                nrZileDispo = 30;
            }
        }
        else if(ev.target.name === 'dateInput'){

            if(this.M.sqlDateToJSDate(this.state.startCalRangeTmp).getTime() < todayDate.getTime()){

                dataStart = this.M.specificDateToDateField(todayDate);
            }
            else{

                dataStart = this.state.startCalRangeTmp;
            }
            
            dataEnd = this.state.endCalRangeTmp;

            nrZileDispo = 0;
        }

        this.setState({

            [ev.target.name] : true,
            [notSelectedRadios[0]]: false,
            [notSelectedRadios[1]]: false,
            nrZile: nrZileDispo,
            startCalRange: dataStart,
            endCalRange: dataEnd,
            showDateSelector: false,  
        },
            () => {

                this.props.inputStatus(doSave, 'radioStatusDispo', 'dispoDataStart', `'` + dataStart + `'` );
                this.props.inputStatus(doSave, 'radioStatusDispo', 'dispoDataEnd', (dataEnd || '').length > 4 ? `'` + dataEnd + `'` : dataEnd);
                this.props.inputStatus(doSave, 'radioStatusDispo', 'dispoNrZile', nrZileDispo);
                this.props.inputStatus(true, 'isDispoOk', 'isDispoOk', true, true);
            }
        );
    }

    onChangeNrZile = (ev) =>{

        if(+ev.target.value <= 365){

            this.radioValidation(ev.target.value, this.state.zileInput, 'nrZile')
        }
        else{

            const errorTx =  'Numarul de zile nu poate depasi valoarea de 365.';
            this.props.showToastError(errorTx);
        }
    }

    onChangeTipZile = (ev) => {

        this.radioValidation(ev.target.value, this.state.zileInput, 'tipZile')

        // 
    }

    onShowDateSelector = () => {

        this.setState({

            showDateSelector: true
        })
    }

    onChangeStartRange = (ev) => {

        if((ev.target.value || '').length < 1){

            return;
        }

        let currentDate = new Date();
        currentDate.setHours(0,0,0,0);
        let dataStart = this.M.sqlDateToJSDate(ev.target.value);
        let dataEnd = this.M.sqlDateToJSDate(this.state.endCalRange);
        // let saveState = false;
        let saveState = true;

        // if(dataEnd){

        //     if(dataStart.getTime() >= dataEnd.getTime()){

        //         // alert("Wrong date interval!")

        //         const errorTx =  'Interval orar gresit: Data sfarsit trebuie sa fie mai mare ca data inceput.';
        //         this.props.showToastError(errorTx);

        //         return;
        //     }
        //     else{

        //         saveState = true;
        //     }
        // }

        // if( dataStart.getTime() < currentDate.getTime() ){

        //     // alert("Date out of range!")

        //     // this.props.inputStatus(false, 'dataStartStatus');
        //     const errorTx = 'Interval orar gresit: Data inceput trebuie sa fie mai mare sau egala cu data de astazi.';
        //     this.props.showToastError(errorTx);

        //     return;
        // }  
        
        this.setState({

            startCalRange: ev.target.value,
            startCalRangeTmp: ev.target.value,
            isEndRangeSelectorDisabled: false,
            // canSave: saveState
        },
            () => {

                this.props.inputStatus(saveState, 'dataStartStatus', 'dispoDataStart', `'` + ev.target.value + `'`);

                if(dataEnd){

                    let dateRange = 
                    this.M.dataFriendlyFromSql(ev.target.value) + ' ' + this.M.sqlDateToJSDate(ev.target.value).getFullYear() + ' / ' + this.M.dataFriendlyFromSql(this.state.endCalRange) +
                    ' ' + this.M.sqlDateToJSDate(this.state.endCalRange).getFullYear();

                    let dateSelectorSelect = [

                        {
                            val: 'date',
                            tx: dateRange
                        }
                    ];

                    this.setState({

                        dateSelectorOption: dateSelectorSelect,
                    });
                }

                let duration = 500;
                clearTimeout(this.inputTimer);
                    this.inputTimer = setTimeout( ()=>{
                        this.verifyDatesValues();
                }, duration);
            }
        );
    }

    onChangeEndRange = (ev) => {

        if((ev.target.value || '').length < 1){

            return;
        }
        
        let dateRange = 
        this.M.dataFriendlyFromSql(this.state.startCalRange) + ' ' + this.M.sqlDateToJSDate(this.state.startCalRange).getFullYear() + ' / ' + this.M.dataFriendlyFromSql(ev.target.value) +
        ' ' + this.M.sqlDateToJSDate(ev.target.value).getFullYear()

        let dataStart = this.M.sqlDateToJSDate(this.state.startCalRange);
        let dataEnd = this.M.sqlDateToJSDate(ev.target.value);
        let currentDate = new Date();
        currentDate.setHours(0,0,0,0);

        let dateSelectorSelect = [

            {
                val: 'date',
                tx: dateRange
            }
        ]

        // if( ( dataStart.getTime() >= dataEnd.getTime() ) || 
        //     ( dataStart.getTime() < currentDate.getTime() ) ||
        //     (  dataEnd.getTime() < currentDate.getTime()  )
        // ){


        //     let saveState = false;
        //     if( (this.state.endCalRange || '').length > 0 ){

        //         saveState = true;
        //     }

        //     // alert("Wrong date interval!");
        //     // this.setState({

        //     //     showToastPopupError: true,
        //     //     actionTx: 'Interval orar gresit: Data sfarsit trebuie sa fie mai mare ca data inceput.',
        //     //     canSave: saveState
        //     // });
        //     this.props.inputStatus(saveState, 'dataEndStatus')

        //     const errorTx = 'Interval orar gresit: Data sfarsit trebuie sa fie mai mare ca data inceput.';
        //     this.props.showToastError(errorTx);

        //     return;
        // }

        this.setState({

            endCalRange: ev.target.value,
            endCalRangeTmp: ev.target.value,
            dateSelectorOption: dateSelectorSelect,
            // showDateSelector: false,
            nrZile: 0,
        },
            () => {

                this.props.inputStatus(true, 'dataEndStatus', 'dispoDataEnd', `'` + ev.target.value + `'`);
                this.props.inputStatus(true, 'isDispoOk', 'isDispoOk', true, true);

                let duration = 500;
                clearTimeout(this.inputTimer);
                    this.inputTimer = setTimeout( ()=>{
                        this.verifyDatesValues();
                }, duration);
            }
        )
    }

    onBlurHideCalendars = () => {

        this.setState({

            showDateSelector: false
        })
    }

    verifyDatesValues = () => {

         let isOkDataStart = true;
        let isOkDataEnd = true;

        let showToastPopupError = false;
        let actionTxStart = '';
        let actionTxEnd = '';


        if(this.state.dateInput){

           if(this.state.showDateSelector === true){

                let currentDate = new Date();
                currentDate.setHours(0, 0, 0, 0);

                if(this.state.showDateSelector === false && this.state.dateSelectorOption.val === 'Neselectat'){

                    this.props.inputStatus(true, 'isDispoOk', 'isDispoOk', false, true);
                    this.props.onAddErrorMessage('Datele nu sunt selectate!');
                    return;
                }

                if(this.state.startCalRange.length > 6){

                    isOkDataStart = true;

                    if((this.state.endCalRange || '').length > 6){

                        if(this.M.sqlDateToJSDate(this.state.startCalRange).getTime() >= this.M.sqlDateToJSDate(this.state.endCalRange).getTime()){

                            isOkDataStart = false;
                            showToastPopupError = true;
                            actionTxStart = 'Interval orar gresit: Data sfarsit trebuie sa fie mai mare ca data inceput. \n'
                        }
                        else{

                            isOkDataStart = true;
                        }
                    }

                    if(this.M.sqlDateToJSDate(this.state.startCalRange).getTime() < currentDate.getTime()){

                        isOkDataStart = false;
                        showToastPopupError =  true;
                        actionTxStart =  'Interval orar gresit: Data inceput trebuie sa fie mai mare sau egala cu data de astazi. \n';
                    }
                }
                else{
        
                    isOkDataStart = false;
                    showToastPopupError =  true;
                    actionTxStart = 'Data inceput trebuie sa fie aleasa. \n';
                }
        
                if(this.state.endCalRange.length > 6){

                    isOkDataEnd = true;

                    if(this.M.sqlDateToJSDate(this.state.endCalRange).getTime() < currentDate.getTime()){

                        isOkDataEnd = false;
                        showToastPopupError =  true;
                        actionTxEnd = 'Interval orar gresit: Data sfarsit trebuie sa mai mare ca data de astazi. \n';
                    }
                }
                else{
        
                    isOkDataEnd = false;
                    showToastPopupError =  true;
                    actionTxEnd = 'Data sfarsit trebuie sa fie aleasa. \n';
                }
           }
        }

        if(isOkDataStart && isOkDataEnd){

            this.props.inputStatus(true, 'isDispoOk', 'isDispoOk', true, true);
        }
        else{

            this.props.inputStatus(true, 'isDispoOk', 'isDispoOk', false, true);
            this.props.onAddErrorMessage(actionTxStart + actionTxEnd);
            // this.props.showToastError(actionTx);
        }
    }

    render(){

        return(

            <div style={this.props.style}>

                {
                    !this.props.hideTx && (

                        <p
                            style={{
                                margin: 0,
                                fontSize: this.props.txSize || '14px',
                                textAlign: 'left'
                            }}
                        >
                            Clientii tai se pot programa...
                        </p>
                    )
                }

                <div 
                    style={{
                        display: 'flex',
                        marginTop: this.props.containerTop || '20px'
                    }}
                >

                    <div 
                        className="m-disp-1"
                        style={{
                            position: 'relative',
                            textAlign: 'left'
                        }}
                    >

                        <input 
                            className="input-radio" type="radio"
                            style={{
                                position:'relative',
                                top: '50%',
                                transform: 'translateY(-50%)'
                            }}
                            name='zileInput' 
                            checked={this.state.zileInput}
                            onChange={this.onChangeRadio}
                        />

                    </div>

                    <div 
                        className={this.props.mobileSelector ? "m-disp-2" : "disp-2"}
                        style={{
                            position: 'relative',
                        }}
                    >

                        <AuthInput 
                            className="days-input"
                            label='Zile'
                            type="text"
                            value={this.state.nrZile}
                            onChange={this.onChangeNrZile}
                            style={{
                                textAlign: 'center',
                                borderRadius: '5px 0px 0px 5px'
                            }}
                            labelStyle={{
                                left: '50%',
                                transform: 'translateX(-50%)',
                            }}
                            disabled={!this.state.zileInput}
                        />

                    </div>

                    <div 
                        className={this.props.mobileSelector ? "m-disp-3" : "disp-3"}
                        style={{
                            position: 'relative',
                        }}
                    >

                        <SelectOthers
                            label='Alege'
                            keyName='m-tipZile-'
                            tipCurent={this.state.tipZile}
                            value={this.state.tipZile}
                            list={this.state.listaTipZile}
                            onChange={this.onChangeTipZile}
                            style={{
                                borderRadius: '0px 5px 5px 0px'
                            }}
                            disabled={!this.state.zileInput}
                        />

                    </div>

                </div>



                <div 
                    style={{
                        display: 'flex',
                        marginTop: 10
                    }}
                >

                    <div 
                        className="m-disp-1"
                        style={{
                            position: 'relative',
                            textAlign: 'left'
                        }}
                    >

                        <input 
                            className="input-radio" type="radio"
                            style={{
                                position:'relative',
                                top: '50%',
                                transform: 'translateY(-50%)'
                            }} 
                            name='dateInput'
                            checked={this.state.dateInput}
                            onChange={this.onChangeRadio}
                        />

                    </div>


                    <div 
                        className="m-disp-lg"
                        style={{
                            position: 'relative',
                        }}
                    >

                        {
                            !this.state.showDateSelector && (

                                <SelectOthers
                                    label='Selecteaza Date'
                                    onClick={this.onShowDateSelector}
                                    keyName='m-date-selector-'
                                    list = {this.state.dateSelectorOption}
                                    disabled={!this.state.dateInput}
                                />
                            )
                        }

                        {
                            this.state.showDateSelector && 
                            this.props.mobileSelector &&
                            (

                                <>
                                    <div className="form-control">

                                        <input 
                                            className="m-period-selector"
                                            type="date" 
                                            value={this.state.startCalRange}
                                            onChange={this.onChangeStartRange}
                                            style={{
                                                width: '100%'
                                            }}
                                        />
                                        
                                        <label 
                                            className="input-label"
                                            style={{
                                                top: 8,
                                                fontSize: '10px'
                                            }}
                                        >
                                            Data Inceput
                                        </label>    
                                        
                                    </div>

                                    <div className="form-control">

                                        <input 
                                            className="m-period-selector"
                                            type="date" 
                                            value={this.state.endCalRange}
                                            onChange={this.onChangeEndRange}
                                            // disabled={this.state.isEndRangeSelectorDisabled}
                                            style={{
                                                width: '100%'
                                            }}
                                            onBlur={this.onBlurHideCalendars}
                                        />
                                        
                                        <label 
                                            className="input-label"
                                            style={{
                                                top: 8,
                                                fontSize: '10px'
                                            }}
                                        >
                                            Data Sfarsit
                                        </label>    
                                        
                                    </div> 
                                
                                </>
                            )
                        }

                        {
                            this.state.showDateSelector && 
                            !this.props.mobileSelector && (

                                <div className="row">

                                    <div className="col-l-6 col-m-6 col-s-6">

                                        <div className="form-control">

                                            <input 
                                                className="period-selector"
                                                type="date" 
                                                value={this.state.startCalRange}
                                                onChange={this.onChangeStartRange}
                                                style={{
                                                    borderRadius: '5px 0 0 5px'
                                                }}
                                            />

                                        </div>

                                        <label 
                                            className="input-label"
                                            style={{
                                                top: 6
                                            }}  
                                        >
                                            Data Inceput
                                        </label>   

                                    </div>

                                    <div className="col-l-6 col-m-6 col-s-6">

                                        <div className="form-control">

                                            <input 
                                                className="period-selector"
                                                type="date" 
                                                value={this.state.endCalRange}
                                                onChange={this.onChangeEndRange}
                                                onBlur={this.onBlurHideCalendars}
                                                // disabled={this.state.isEndRangeSelectorDisabled}
                                                style={{
                                                    borderRadius: '0 5px 5px 0'
                                                }}
                                            />

                                            <label 
                                                className="input-label"
                                                style={{
                                                    top: 6
                                                }}
                                            >
                                                Data Sfarsit
                                            </label>   
                                    
                                        </div>

                                    </div>

                                </div>
                            )

                        }

                    </div>

                </div>



                <div 
                    style={{
                        display: 'flex',
                        marginTop: 10
                    }}
                >

                    <div 
                        className="m-disp-1"
                        style={{
                            position: 'relative',
                            textAlign: 'left'
                        }}
                    >

                        <input 
                            className="input-radio" type="radio"
                            style={{
                                position:'relative',
                                top: '50%',
                                transform: 'translateY(-50%)'
                            }} 
                            name='nelimInput'
                            checked={this.state.nelimInput}
                            onChange={this.onChangeRadio}
                        />

                    </div>


                    <div 
                        className="m-disp-lg"
                        style={{
                            position: 'relative',
                        }}
                    >

                        <p
                            style={{
                                position: 'relative',
                                top: 3,
                                fontSize: this.props.mobileSelector ? '14px' : '16px',
                                color: '#455A64',
                                textAlign: 'left'
                            }}
                        >
                            Perioada nelimitata
                        </p>

                    </div>

                </div>

            </div>
        )
    }
}