import React from 'react';
import Declarations from '../../providers/Declarations';
import Masks from '../../providers/Masks';
import Utils from '../../providers/Utils';
import Ws from '../../providers/Ws';
import SelectInput from '../SelectInput';

export default class SvDuration extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            listaDurata: [],
            durataCurenta: this.props.duration || 30,
            durataTmp: this.props.duration || 30,
        };
    }

    WS = new Ws();
    M = new Masks();
    UT = new Utils();

    componentDidMount(){

        this.setListaDurata();
    }
    
    componentDidUpdate(prevPros, _){

        if(prevPros.triggerSave !== this.props.triggerSave){

            this.onGenerateQuery();
        }

        if(prevPros['duration'] !== this.props['duration']){

            this.setState({

                durataTmp: this.props.duration
            });
        }
    }

    setListaDurata = () => {

        let timp = new Declarations().listaDurata;

        this.setState({

            listaDurata: timp
        })
    }

    onChangeDurata = (ev) => {

        let doSave = true;

        if(+ev.target.value === +this.state.durataTmp){

            doSave = false;
        }

        this.setState({

            durataStatus: doSave,
            durataCurenta: +ev.target.value
        },
        
            () => {

                this.props.inputStatus(doSave, 'durataStatus', 'durata', +ev.target.value);
            }
        )

        // 
    }

    onGenerateQuery = () => {

        const result = {

            data: [
                {
                    itemName: 'duration',
                    itemData: `${this.state.durataCurenta}`
                }
            ],
            err: null
        }

        // this.props.onCheckAndSave(result);
    }

    render(){

        return(

            <div className="form-control" style={this.props.style}>

                <SelectInput 
                    myKey='m-durata-serviciu-'
                    label={'Durata Serviciu'}
                    listaDurataServiciu={this.state.listaDurata}
                    durataCurenta={this.state.durataCurenta}   
                    onChange={this.onChangeDurata}     
                />

            </div>
        );
    }
}