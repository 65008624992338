import React from 'react';
import Masks from '../../providers/Masks';
import Utils from '../../providers/Utils';
import Ws from '../../providers/Ws';

import RegisterHeader from './RegisterHeader';

import Image from '../../assets/Followers-cuate 1.png';
import { IoIosArrowForward } from 'react-icons/io';
import ButtonForm from '../../commons/ButtonForm';
import { BrowserView, MobileView } from 'react-device-detect';

import CanaryPlantImage from '../../assets/canary_plant_bg.png';
import MBirdAndPlantImage from '../../assets/bird_and_plant_mobile.png';
import ErrorMessage from '../../commons/ErrorMessage';

export default class EmailSuccessValidation extends React.Component{

    constructor(props){

        super(props);
        this.state = {
            isEmailValidated: true,
            errorTx: ''
        };
    }

    WS = new Ws();
    M = new Masks();
    UT = new Utils();

    localData = {

        isEmailValidat: false
    }

    componentDidMount(){

        this.onGetDataFromDb()

        // this.onValidateEmail();
        
    }

    onGetDataFromDb = async () => {

        await this.onCheckIfUserIsAlreadyValidated();

        

        if(this.localData.isEmailValidat === true){

            window.location.href = "/";
        }
        else{

            await this.onValidateEmail();
        }
    }

    onCheckIfUserIsAlreadyValidated = async () =>{

        const userToken = this.props.match.params.userToken;

        let queryUserSelectData = `select * from users where token = '${userToken}' `;
        let userDataSelected = await this.WS.sqlCommand(`select`, queryUserSelectData);

        if(userDataSelected.success){

            if((userDataSelected.content[0]['wrk_token'] || '').length > 10){

                

                this.localData.isEmailValidat = true;
            }
        }
        else{

            this.localData.isEmailValidat = false;
        }

        
    }

    onValidateEmail = async () => {

        const userToken = this.props.match.params.userToken;

        const wrkToken = this.UT.generateToken();
        let queryUserData = `update users set wrk_token = '${wrkToken}' where token = '${userToken}' `;
        let userData = await this.WS.sqlCommand(`update`, queryUserData);

        if(userData.success){

            let queryUserSelectData = `select * from users where token = '${userToken}' `;
            let userDataSelected = await this.WS.sqlCommand(`select`, queryUserSelectData);

            if(userDataSelected.success){

                let queryUserContacts = `insert into user_contacts(token_user, tip, value)
                                     select
                                    '${userToken}', 'email', '${userDataSelected.content[0]['email']}'
                                `;

                let queryUserRights = `insert into user_rights(token_account, token_user, is_admin, can_change_settings)
                                        select '${userDataSelected.content[0]['account_id']}', '${userToken}', 1, 1
                                    `;

                let _userContacts = this.WS.sqlCommand(`insert`, queryUserContacts);
                let _userRights = this.WS.sqlCommand(`insert`, queryUserRights);

                let [userContacts, userRights] = await Promise.all( [_userContacts, _userRights] );

                if(userContacts.success && userRights.success){

                    this.setState({

                        isEmailValidated: true
                    });
                }
            }
            else{

                
            }

        }
        else{

            
        }

    }

    render(){

        return(

            this.state.isEmailValidated ? (

                <>

            <BrowserView>

                <div className='register-container-desktop'>

                    <RegisterHeader />

                    <div className='service-details-card' style={{marginTop: '20px'}}>
                        

                        <div className='register-center-tx-wrapper'>

                            <img
                                className='register-image'
                                src={Image}
                                alt="Planary"
                            />

                            <p 
                                className='register-tx register-title'
                                style={{
                                    fontSize: '18px'
                                }}
                            >
                                Adresa e-mail verificata cu succes!
                            </p>

                            <p 
                                className='register-tx'
                                style={{
                                    marginTop: '20px'
                                }}
                            >
                                In continuare vom parcurge impreuna pasii necesari personalizarii si 
                                activarii profilului tau cat si crearea primului tau serviciu.

                            </p>

                            <ButtonForm
                                type='button'
                                tx={
                                    <>
                                        <p style={{display: 'inline-block', position: 'relative', top: '-2px', margin: 0}}>
                                            Personalizeaza Profilul &nbsp;
                                        </p> 
                                        <IoIosArrowForward className='register-next-arrow'/>
                                    </>
                                }
                                
                                style={{
                                    marginTop: '20px',
                                    width: 'auto',
                                    height: '56px',
                                    background: '#F8BC05',
                                    fontSize: '16px',
                                    padding: '0px 35px 0px 35px'
                                }}
                                onClick={
                                    () => {

                                        // this.onCheckAndSave(true)
                                        window.location.href = "/#/login"
                                    }
                                }
                            />

                            

                        </div>



                    </div>

                    <img 
                        className='bottom-pg-img'
                        src={CanaryPlantImage}
                        alt = 'Planary...'
                        style={{
                            marginTop: '20px'
                        }}
                    />


                </div>

            </BrowserView>

            <MobileView>

                <div 
                    style={{
                        padding: '20px',
                        minHeight: '850px',
                        background: `url(${MBirdAndPlantImage}) bottom no-repeat`
                    }}
                >
                    
                    <RegisterHeader />
                    
                    <div className="m-edit-s-card" style={{marginTop: '20px'}}>
                        
                        <img
                            className='register-image'
                            src={Image}
                            alt="Planary"
                        />

                        <p 
                            className='register-tx register-title'
                            style={{
                                fontSize: '18px'
                            }}
                        >
                            Adresa e-mail verificata cu succes!
                        </p>

                        <p 
                            className='register-tx'
                            style={{
                                marginTop: '20px'
                            }}
                        >
                            In continuare vom parcurge impreuna pasii necesari personalizarii si 
                            activarii profilului tau cat si crearea primului tau serviciu.

                        </p>

                        <div 
                            style={{
                                textAlign: 'center'
                            }}
                        >
                            <ButtonForm
                                type='button'
                                tx={
                                    <>
                                        <p style={{display: 'inline-block', position: 'relative', top: '-2px', margin: 0}}>
                                            Personalizeaza Profilul &nbsp;
                                        </p> 
                                        <IoIosArrowForward className='register-next-arrow'/>
                                    </>
                                }
                                
                                style={{
                                    marginTop: '20px',
                                    width: '100%',
                                    height: '56px',
                                    background: '#F8BC05',
                                    fontSize: '16px',
                                    padding: '0px 35px 0px 35px'
                                }}
                                onClick={
                                    () => {

                                        // this.onCheckAndSave(true)
                                    }
                                }
                            />
                        </div>

                    </div>

                </div>

            </MobileView>

            </>
            ) : (

                <ErrorMessage
                    eroareTx = "Validarea email-ului a esuat. Va rugam dati refresh la pagina!"
                />
            )
        )
    }
}