import React from 'react';

import { BrowserView, MobileView } from 'react-device-detect';

import { FaTrophy } from 'react-icons/fa';
import { GoCheck } from 'react-icons/go';
import { IoMdThumbsUp } from 'react-icons/io';
import { MdBusinessCenter, MdSchool, MdSettingsInputComponent, MdWork } from 'react-icons/md';
import { RiLeafFill } from 'react-icons/ri';

import IntegrationsCard from '../../../commons/IntegrationsCard';
import MServiceNav from '../../../commons/MServiceNav';
import MBirdAndPlantImage from '../../../assets/bird_and_plant_mobile.png';
import LaptopAndMugImg from '../../../assets/laptop_and_mug.png';
import IconText from '../../../commons/IconText';
import AccSubscription from './AccSubscription';

export default class Subscriptions extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            // currentSubscription: {},
            canSave: false,
            selectedSub: {
                
                free: false,
                basic: false,
                antrepenor: false,
                premium: false
            },
            type: 'free'
        };
    }


    componentDidMount(){

       
    }

    onSetType = () => {

        //the type will be set directly from database
    }

    onSelectType = (type) => {

        this.setState({

            type: type
        },
            () => {
                
                let selectedSubs = this.state.selectedSub;

                for(let [key, value] of Object.entries(selectedSubs) ){

                    if(key === type){

                        selectedSubs[key] = true;
                    }
                    else{

                        selectedSubs[key] = false
                    }
                }

                this.setState({

                    // currentSubscription: this.subscriptionDetails[type],
                    selectedSub: selectedSubs
                })
            }
        );
    }

    onGoToSubscriptionPageForMobile = (type) => {

        this.props.history.push('abonamentul-meu/' + type);
    } 

    render(){

        return(

            <>
                <BrowserView>
                
                    <div 
                        className="wrapper-dashboard" 
                        style={{

                            margin:'30px auto',
                            minHeight: '1200px',
                            background: `url(${LaptopAndMugImg}) bottom no-repeat`
                        }}
                    >

                        <div className="service-details-card separated-card">

                            <div className='row'>

                                <div className='col-l-4 col-m-4 col-s-12'>

                                    <IconText 
                                        icon = {
                                            <MdWork 
                                                style={{
                                                    color: 'rgb(255, 199, 39)',
                                                    fontSize: '20px'
                                                }}
                                            />
                                        }
                                        tx = 'Abonamente'
                                        txSize = '18px'
                                        txWeight = '500'
                                        txCol = '#74838B'
                                    />

                                    <br />

                                    <IntegrationsCard 

                                        type = 'Abonament'
                                        icon = {
                                            <MdSchool
                                                className={"integrations-icon sub"}
                                            />
                                        }
                                        middleTx = 'Incercare Gratuita'
                                        downTx = 'Expira: 23 Martie 2021'
                                        isSynced = {true}
                                        onClick = {() => this.onSelectType('free')}

                                        // style={{

                                        //     marginTop: idx > 0 ? '8px' : '0px'
                                        // }}
                                    />

                                    <IntegrationsCard 

                                        type = 'Abonament'
                                        icon = {

                                            <RiLeafFill
                                                className={"integrations-icon sub"}
                                            />
                                        }

                                        connectIcon = {

                                            <IoMdThumbsUp 
                                                className='integrations-icon connect'
                                            />
                                        }

                                        middleTx = 'Basic'
                                        downTx = '50 Lei / Luna'
                                        isSynced = {false}
                                        // selected = {this.state.selectedSub['basic']}
                                        syncedTx = 'Alege'

                                        onClick = {() => this.onSelectType('basic')}
                                        style={{

                                            marginTop: '8px'
                                        }}
                                    />

                                    <IntegrationsCard 

                                        type = 'Abonament'
                                        icon = {

                                            <MdBusinessCenter
                                                className={"integrations-icon sub"}
                                            />
                                        }

                                        connectIcon = {

                                            <IoMdThumbsUp 
                                                className='integrations-icon connect'
                                            />
                                        }

                                        middleTx = 'Anteprenor'
                                        downTx = '70 Lei / Luna'
                                        isSynced = {false}
                                        // selected = {this.state.selectedSub['antrepenor']}
                                        syncedTx = 'Alege'
                                        onClick = {() => this.onSelectType('antrepenor')}
                                        style={{

                                            marginTop: '8px'
                                        }}
                                    />

                                    <IntegrationsCard 

                                        type = 'Abonament'
                                        icon = {

                                            <FaTrophy
                                                className={"integrations-icon sub"}
                                            />
                                        }

                                        connectIcon = {

                                            <IoMdThumbsUp 
                                                className='integrations-icon connect'
                                            />
                                        }

                                        middleTx = 'Premium'
                                        downTx = 'Cere oferta'
                                        isSynced = {false}
                                        // selected = {this.state.selectedSub['premium']}
                                        syncedTx = 'Alege'
                                        onClick = {() => this.onSelectType('premium')}
                                        style={{

                                            marginTop: '8px'
                                        }}
                                    />

                                </div>

                                <div className='col-l-5 col-m-5 col-s-12'>

                                    <IconText 
                                        icon = {
                                            <MdWork 
                                                style={{
                                                    color: 'rgb(255, 199, 39)',
                                                    fontSize: '20px'
                                                }}
                                            />
                                        }
                                        tx = 'Abonamentul Meu'
                                        txSize = '18px'
                                        txWeight = '500'
                                        txCol = '#74838B'
                                    />

                                    <br />

                                    {
                                        this.state.type &&
                                        (

                                            <AccSubscription 
                                                type = {this.state.type}
                                                currentSubscription = {this.state.currentSubscription}
                                                isMobile={false}
                                            />
                                        )
                                    }

                                </div>

                            </div>

                        </div>
                        
                    </div>
                
                </BrowserView>

                <MobileView>

                    <div style={{position: 'relative'}}>

                        <MServiceNav 
                            icon={<MdWork />}
                            title='Abonamente'
                            path={'/ca/dashboard/cab'}
                        />

                        {
                            this.state.canSave &&
                            <GoCheck
                                className="m-add-service"
                                // onClick={this.saveToDb}
                            />
                        }

                    </div>

                    <div 
                        style={{
                            padding: '20px'
                        }}
                    >


                        <div className="m-edit-s-card">

                            <IntegrationsCard 

                                type = 'Abonament'
                                icon = {
                                    <MdSchool
                                        className={"integrations-icon sub"}
                                    />
                                }
                                middleTx = 'Incercare Gratuita'
                                downTx = 'Expira: 23 Martie 2021'
                                isSynced = {true}
                                onClick = {() => this.onGoToSubscriptionPageForMobile('free')}

                                // style={{

                                //     marginTop: idx > 0 ? '8px' : '0px'
                                // }}
                            />

                            <IntegrationsCard 

                                type = 'Abonament'
                                icon = {

                                    <RiLeafFill
                                        className={"integrations-icon sub"}
                                    />
                                }

                                connectIcon = {

                                    <IoMdThumbsUp 
                                        className='integrations-icon connect'
                                    />
                                }

                                middleTx = 'Basic'
                                downTx = '50 Lei / Luna'
                                isSynced = {false}
                                syncedTx = 'Alege'
                                onClick = {() => this.onGoToSubscriptionPageForMobile('basic')}
                                style={{

                                    marginTop: '8px'
                                }}
                            />

                            <IntegrationsCard 

                                type = 'Abonament'
                                icon = {

                                    <MdBusinessCenter
                                        className={"integrations-icon sub"}
                                    />
                                }

                                connectIcon = {

                                    <IoMdThumbsUp 
                                        className='integrations-icon connect'
                                    />
                                }

                                middleTx = 'Anteprenor'
                                downTx = '70 Lei / Luna'
                                isSynced = {false}
                                syncedTx = 'Alege'
                                onClick = {() => this.onGoToSubscriptionPageForMobile('antrepenor')}
                                style={{

                                    marginTop: '8px'
                                }}
                            />

                            <IntegrationsCard 

                                type = 'Abonament'
                                icon = {

                                    <FaTrophy
                                        className={"integrations-icon sub"}
                                    />
                                }

                                connectIcon = {

                                    <IoMdThumbsUp 
                                        className='integrations-icon connect'
                                    />
                                }

                                middleTx = 'Premium'
                                downTx = 'Cere oferta'
                                isSynced = {false}
                                syncedTx = 'Alege'
                                onClick = {() => this.onGoToSubscriptionPageForMobile('premium')}
                                style={{

                                    marginTop: '8px'
                                }}
                            />

                        </div>

                    </div>

                    <img 
                        src={MBirdAndPlantImage} 
                        style={{
                            position: 'absolute',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            bottom: '0px',
                            width: '200px'
                        }}
                    />
                    

                </MobileView>
            
            </>
        )
    }
}