import React from 'react';
import { BrowserView, MobileView } from 'react-device-detect';

import ContentCopyIcon from '../assets/content_copy.png';
import ConnectedPeopleImg from '../assets/Connected-cuate 1.png';
import ButtonForm from './ButtonForm';

export default class CopiedLinkPopUp extends React.Component{

    constructor(props){

        super(props);
    }

    onClosePopup = (ev) => {

        if( ev.target.id === 'modal-popup' ||  ev.target.id === 'close-modal-btn'){

            this.props.onClosePopup(false);
        }
  
    }

    render(){

        return(
            <>
            <BrowserView>
            <div 
                id="modal-popup"
                className="calendar-modal-container"
                onClick={(ev) => {this.onClosePopup(ev)}}
            >

                <div 
                    className="calendar-popup-card"
                    style={{
                        width: '500px',
                        padding: '30px 20px'
                    }}
                >
                    <h3
                        style={{
                            margin: 0
                        }}
                    >
                        <p
                            style={{
                                display: 'inline-block',
                                position: 'relative',
                                top: 4,
                                margin: 0,
                            }}
                        >
                            <img
                                src={ContentCopyIcon}
                                alt="Copied to clipboard img..."
                            /> &nbsp;
                        </p>

                        <p
                            style={{
                                display: 'inline-block',
                                position: 'relative',
                                margin: 0,
                                fontWeight: 500,
                                color: '#455A64'
                            }}
                        >
                            Link copiat
                        </p>

                    </h3>

                    <br />

                    <a 
                        href={this.props.link}
                        style={{
                            display: 'block',
                            textDecoration: 'none',
                            fontSize: '16px',
                            color: '#44A5FF',
                            cursor: 'pointer'
                        }}
                    >
                        {this.props.link}
                    </a>

                    <br />

                    <p 
                        style={{
                            margin: 0,
                            fontSize: '16px',
                            color: '#455A64'
                        }}
                    >
                        Link-ul tau a fost copiat in clipboard.
                        <br />
                        Poti sa ii dai Paste oriunde doresti.
                    </p>

                    <br />

                    <img 
                        src={ConnectedPeopleImg}
                        alt="Connected people..."
                        style={{
                            display: 'block',
                            margin: '0 auto'
                        }}

                    />

                    <br />

                    <ButtonForm 
                        id="close-modal-btn"
                        style={{
                            width: '160px',
                            height: '49px',
                            borderRadius: '100px',
                            background: '#F8BC05',
                            fontWeight: '500'
                        }}
                        tx="Ok"
                        onClick={(ev) => {this.onClosePopup(ev)}}
                    />

                </div>

            </div>
            </BrowserView>

            <MobileView>

            <div 
                id="modal-popup"
                className="calendar-modal-container"
                onClick={(ev) => {this.onClosePopup(ev)}}
            >

                <div 
                    className="calendar-popup-card"
                    style={{
                        width: '100%',
                        padding: '20px'
                    }}
                >
                    <h3
                        style={{
                            margin: 0
                        }}
                    >
                        <p
                            style={{
                                display: 'inline-block',
                                position: 'relative',
                                top: 4,
                                margin: 0,
                            }}
                        >
                            <img
                                src={ContentCopyIcon}
                                alt="Copied to clipboard img..."
                            /> &nbsp;
                        </p>

                        <p
                            style={{
                                display: 'inline-block',
                                position: 'relative',
                                margin: 0,
                                fontWeight: 500,
                                color: '#455A64'
                            }}
                        >
                            Link copiat
                        </p>

                    </h3>

                    <br />

                    <a 
                        href={this.props.link}
                        style={{
                            display: 'block',
                            textDecoration: 'none',
                            fontSize: '16px',
                            color: '#44A5FF',
                            cursor: 'pointer'
                        }}
                    >
                        {this.props.link}
                    </a>

                    <br />

                    <p 
                        style={{
                            margin: 0,
                            fontSize: '16px',
                            color: '#455A64'
                        }}
                    >
                        Link-ul tau a fost copiat in clipboard.
                        <br />
                        Poti sa ii dai Paste oriunde doresti.
                    </p>

                    <br />

                    <ButtonForm
                        id="close-modal-btn"
                        style={{
                            width: '160px',
                            height: '46px',
                            borderRadius: '100px',
                            background: '#F8BC05',
                            fontWeight: '500'
                        }}
                        tx="Ok"
                        onClick={(ev) => {this.onClosePopup(ev)}}
                    />

                </div>

            </div>

            </MobileView>

            </>
        )
    }
}