import React from 'react';

import { IoIosArrowForward } from 'react-icons/io';
import ButtonForm from '../../../commons/ButtonForm';
import IconText from '../../../commons/IconText';
import { ReactComponent as PinDrop } from '../../../assets/pin_drop.svg';

import Masks from '../../../providers/Masks';
import Utils from '../../../providers/Utils';
import Ws from '../../../providers/Ws';
import ServicesFuncs from '../../logic/ServicesFuncs';
import RegisterHeader from '../RegisterHeader';
import SvLocation from '../../../commons/service/SvLocation';
import Declarations from '../../../providers/Declarations';
import { RiFolderReduceFill } from 'react-icons/ri';
import SvNameAndLink from '../../../commons/service/SvNameAndLink';
import SvDescription from '../../../commons/service/SvDescription';
import ErrorToast from '../../../commons/ErrorToast';
import { FaCalendarCheck } from 'react-icons/fa';
import SvDisponibility from '../../../commons/service/SvDisponibility';
import { BrowserView, MobileView } from 'react-device-detect';
import ButtonsNavRegister from '../../../commons/ButtonsNavRegister';

import CanaryPlantImage from '../../../assets/canary_plant_bg.png';
import MBirdAndPlantImage from '../../../assets/bird_and_plant_mobile.png';
import LocalStorage from '../../../providers/LocalStorage';
import Loader from '../../../commons/Loader';

export default class DispoConfig extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            dispoData: {

                dispo_nr_zile: 30,
                dipo_tip_zile: 'work-days',
                dipo_data_start: '2022-02-23T00:00:00',
                dispo_data_end: null
            },
            serviceTk: '',
            showToastPopupError: false,
            actionTx: '',
            eroareTx: '',

            triggerSave: false,
            isLoading: true
        };
    }

    componentData = new Declarations().serviceData['disponibility'];

    componentStatus = {

        isDispoOk: true
    };

    errorMessages = '';

    WS = new Ws();
    M = new Masks();
    UT = new Utils();
    SF = new ServicesFuncs();

    componentDidMount(){

        this.getServiceData();
    }

    getServiceData = async () => {

        const TOKEN_CONT = new LocalStorage().GetAccountToken();

        let querySelectService = `select * from services where account_tk = '${TOKEN_CONT}' `;
        let service = await this.WS.sqlCommand(`select`, querySelectService);

        if(service.success){

            this.componentData = new Declarations().updateComponentsData(this.componentData, service.content[0]);

            

            this.setState({

                dispoData: {

                    dispo_nr_zile: parseInt(service.content[0]['dispo_nr_zile']),
                    dispo_tip_zile: service.content[0]['dispo_tip_zile'],
                    dispo_data_start: service.content[0]['dispo_data_start'],
                    dispo_data_end: service.content[0]['dispo_data_end'],
                },
                serviceTk: service.content[0]['token'],
            })
            
        }

        this.setState({

            isLoading: false
        })
    }

    inputStatus = (status, statusName, inputName, data, isVerify = false) => {

        if(isVerify){

            this.componentStatus[statusName] = data;
        }
        else{

            this.componentData[inputName] = data;

            
    
            this.setState({
                
                [statusName]: status,
            },
                () => {
    
                    // this.checkSaveButtonStatus();
                }
            );
        }
    }

    onCheckAndSave = async () => {

        const result = Object.values(this.componentStatus).every(item => item === true);;
        const TOKEN_CONT = new LocalStorage().GetAccountToken();

        
        
        if(result){

            // const queryArr = new Declarations().onGenerateQueryArray(queryObject);

            const queryArr = new Declarations().generateQuerySet('service', this.componentData)['disponibility'];
            let uq = this.SF.onSaveServiceData(queryArr, false, this.state.serviceTk);
             
            
            
            let updateService = await this.WS.sqlCommand(`update`, uq.data.substring(1, uq.data.length - 2));

            if(updateService.success){

                // this.props.history.push('');
                this.props.history.push("/inregistrare/serviciu/culoare-serviciu");
            }
        }
        else{

            this.setState({

                triggerSave: false,
                showToastPopupError: true,
                actionTx: this.errorMessages
            })
        }
    }

    onTriggerSave = () => {

        this.setState({

            triggerSave: true
        },
        
            async () => {

                await this.onCheckAndSave();
            }
        )
    }

    onAddErrorMessage = (errorTx) => {

        this.errorMessages = errorTx;
    }

    showToastError = (message) => {

        this.setState({

            showToastPopupError: true,
            actionTx: message
        });
    }

    onClosePopup = (type) => {

        this.setState({

            [type]: false
        })
    }

    
    render(){

        if(this.state.isLoading){

            return(

                <Loader fullPage={true} />
            )
        }

        return(
            <>

            {/* {
                this.state.showToastPopupError && (

                    <ErrorToast 
                        action={this.state.actionTx}
                        onClosePopup = {this.onClosePopup}
                    />
                )
            } */}

            
            <BrowserView>

                <div className='register-container-desktop'>

                    {
                        this.state.showToastPopupError && (

                            <ErrorToast 
                                action={this.state.actionTx}
                                onClosePopup = {this.onClosePopup}
                            />
                        )
                    }

                    <RegisterHeader isServiceStep = {true}/>

                    <div className='service-details-card' style={{marginTop: '20px', textAlign: 'left'}}>

                        <IconText 
                            icon = {
                                <FaCalendarCheck 
                                    style={{
                                        fontSize: '22px',
                                        color: '#FFC727'
                                    }}
                                />
                            }
                            tx = 'Disponibilitate'
                            txSize = '16px'
                            txWeight = '500'
                            txCol = '#455A64'
                        /> 

                        <div className='row' style={{marginTop: '16px'}}>

                            <div className='col-l-6 col-m-6 col-s-6'>                  

                                <p 
                                    className='register-tx'
                                >

                                    Pe ce perioada doresti sa se desfasoare
                                    aceasta oferta de programare?
                                    
                                </p>

                            </div>

                            <div className='col-l-6 col-m-6 col-s-6' style={{paddingLeft: '20px'}}>

                                <p className='register-tx'>
                                    Clientii tai se pot programa...
                                </p>

                                <SvDisponibility 
                                    serviceDetails = {this.state.dispoData}
                                    triggerSave = {this.state.triggerSave}
                                    inputStatus = {this.inputStatus}
                                    showToastError = {this.showToastError}
                                    mobileSelector = {true}
                                    hideTx = {true}
                                    onAddErrorMessage = {this.onAddErrorMessage}
                                />

                                <ButtonForm 
                                    type='button'
                                    tx={
                                        <>
                                            <p style={{display: 'inline-block', position: 'relative', top: '-2px', margin: 0}}>
                                                Continua &nbsp;
                                            </p> 
                                            <IoIosArrowForward className='register-next-arrow'/>
                                        </>
                                    }
                                    
                                    style={{
                                        marginTop: '20px',
                                        width: 'auto',
                                        height: '56px',
                                        background: '#F8BC05',
                                        fontSize: '16px',
                                        padding: '0px 35px 0px 35px',
                                    }}
                                    onClick={this.onTriggerSave}
                                />
                                
                            </div>


                        </div>

                    </div>

                    <img 
                        className='bottom-pg-img'
                        src={CanaryPlantImage}
                        alt = 'Planary...'
                        style={{
                            marginTop: '20px'
                        }}
                    />

                </div>

            </BrowserView>

            <MobileView>

                <div 
                    style={{
                        padding: '20px',
                        minHeight: '850px',
                        background: `url(${MBirdAndPlantImage}) bottom no-repeat`
                    }}
                >
                    
                    <RegisterHeader isServiceStep = {true} />
                    
                    <div className="m-edit-s-card" style={{marginTop: '20px', textAlign: 'left'}}>

                        <IconText 
                            icon = {
                                <FaCalendarCheck 
                                    style={{
                                        fontSize: '22px',
                                        color: '#FFC727'
                                    }}
                                />
                            }
                            tx = 'Disponibilitate'
                            txSize = '16px'
                            txWeight = '500'
                            txCol = '#455A64'
                        /> 

                        <p 
                            className='register-tx sm'
                            style={{marginTop: '16px'}}
                        >

                            Pe ce perioada doresti sa se desfasoare
                            aceasta oferta de programare?
  
                        </p>
                        
                        <br />

                        <SvDisponibility 
                            serviceDetails = {this.state.dispoData}
                            triggerSave = {this.state.triggerSave}
                            inputStatus = {this.inputStatus}
                            showToastError = {this.showToastError}
                            mobileSelector = {true}
                            onAddErrorMessage = {this.onAddErrorMessage}
                        />

                        
                        <ButtonsNavRegister 
                            tx = 'Continua'
                            onPressBtn={this.onTriggerSave}
                            onBack = {this.props.history.goBack}
                        />

                    </div>
                    
                </div>


            </MobileView>

            </>
        );
    }
}