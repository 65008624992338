import React from 'react';
import { isMobile } from 'react-device-detect';
import Utils from '../../providers/Utils';

export default class RedirectToDashboard extends React.Component{

    constructor(props){

        super(props)
    }

    U = new Utils();

    componentDidMount(){

        
        this.onCheckIfIsWizard();
        // this.props.history.push('/ca');
        // this.props.history.push('/ca/dashboard/cab');
    }

    onCheckIfIsWizard = async () => {

        let isAccountComplete = await this.U.onCheckIfUserHasCompleteAccountData();
        let isFirstService = await this.U.onCheckIfHasFirstService();
        
        if(isAccountComplete.success && isFirstService.success){

            this.props.history.push('/ca/dashboard/cab');
        }
        else if(isAccountComplete.success && !isFirstService.success){

            this.props.history.push('/inregistrare/cont/succes-creare-cont');
        }
        else{

            if(isMobile){

                this.props.history.push('/inregistrare/date/incepe');
            }
            else{

                this.props.history.push('/inregistrare/date/companie');
            }
        }
    }

    render(){

        return(

            <div></div>
        )
    }
}