import React from 'react'

import Ws from '../../providers/Ws';
import Utils from '../../providers/Utils';
import Declarations from '../../providers/Declarations';
import LocalStorage from '../../providers/LocalStorage';

// import './Test.css'


export default class ClientAppointment extends React.Component{

    constructor(props){

        super(props)

        this.state = {
            name: 'React',
            data: {
              datasets: [{
                data: [10, 20, 30]
              }],
            
            },
            queue: [],
            iterator: 1,
        }

    }

    WS = new Ws();

    ore = [
        '08:00',
        '08:30',
        '09:00',
        '09:30',
        '10:00',
        '10:30',
        '11:00',
        '11:30',
        '12:00',
        '12:30',
        '13:00',
        '13:30',
        '14:00',
        '14:30',
        '15:00',
        '15:30',
        '16:00',
        '16:30',
        '17:00',
        '17:30',
        '18:00'
    ]

    programari = [

        {
            ora: '08:30',
            durata: 45
        },

        {
            ora: '10:30',
            durata: 45
        },

        {
            ora: '11:00',
            durata: 45
        },

        // {
        //     ora: '12:00',
        //     durata: 30
        // },

        {
            ora: '14:00',
            durata: 45
        },

        // {
        //     ora: '15:00',
        //     durata: 60
        // },

        // {
        //     ora: '16:00',
        //     durata: 30
        // },

           {
            ora: '17:00',
            durata: 45
        },
    ]
    
    // array care seamana cu cel de programari (obiecte) => fiecare ora din arr ore, disponibilitate in minute
    // ora ocupata = 0 sau negativ   

    convertToTime = (t) => {

        const time = new Date();
        time.setHours(t[0], t[1], 0);

        return time;
    }

    getDuration = (t1, t2) =>{

       const duration = Math.abs(t2 - t1) / 1000 / 60;

       return Math.round(duration);
    }

    modifyDisponibility = (n, arr, avgDur, idx) => {

        let duration = avgDur;
        let copyArr = arr;
        
        for(let i = idx; i < n; i++){

            if(arr[i].disponibilitate !== 0) {

                copyArr[i].disponibilitate = duration;
                
            }  
             
            duration -= 30     
        }

        return copyArr;
    }

    // setOccupiedHours = (idxStart, idxEnd, arr) => {

    //     let cloneArr = arr;

    //     for(let i = idxStart; i < idxEnd; i++){

    //         
    //         cloneArr[i].disponibilitate = 0;
    //     }

    //     return cloneArr;
    // }

    addMinutes = (date, minutes) => {

        return  new Date(date.getTime() + minutes*60000);
    }

    setIndispHours = (start, end, arr) => {

        let copyArr = arr

        for(let i = start; i < end; i++){

            copyArr[i].disponibilitate = 0;
        }

        return copyArr
    }

    checkOverlappedSchedule = (previousH, currentH, duration) => {     

        const time = this.getDuration(previousH, currentH);
        const overlappedDuration = time - duration;

        if(overlappedDuration < 0){

            return true;
        }

        return false;
    }


    onOreDisponibile = () => {

        // let disponibilitate = [];

        let disponibilitate = this.ore.map(oEl => {

            return {
                ora: oEl,
                disponibilitate: 1
            }
        });

        disponibilitate[disponibilitate.length-1].disponibilitate = 0;
        let oraProgramareIdx = 0;
        const lengthOre = this.ore.length;
        let isOraProgramata = false;
        let programariClone = [...this.programari]

        // let idxSuprapus = this.programari[0].durata;
        let previousDuration = this.programari[0].durata;
        let previousHour =  this.programari[0].ora;
        // let currentScheduledHour = this.ore[0];
        
        for(let i = 0; i < disponibilitate.length; i++){

            let duration = 0;
            let indispHours = [];

            if(isOraProgramata){

                isOraProgramata = false;
            }

            let startTime = this.ore[i].split(":");
            let startH = this.convertToTime(startTime);

            let endTime = this.ore[lengthOre-1].split(":");
            let endH = this.convertToTime(endTime);

            if(!isOraProgramata){

                duration = this.getDuration(startH, endH)
                disponibilitate = this.modifyDisponibility(this.ore.length-1, disponibilitate, duration, i)
            }

            for(let j = 0; j < this.programari.length; j++){

                if(disponibilitate[i].ora === programariClone[j].ora){

                    
                    startTime = this.ore[oraProgramareIdx].split(":");
                    startH = this.convertToTime(startTime);

                    endTime = this.ore[i].split(":");
                    endH = this.convertToTime(endTime);
                    
                    duration = this.getDuration(
                        startH, endH
                    );

                    disponibilitate[i].disponibilitate = 0;
                
                    let idx = this.programari[j].durata/30;

                    indispHours = this.setIndispHours(i, idx+i, disponibilitate)
                    // 
                
                    disponibilitate = this.modifyDisponibility(i, disponibilitate, duration, oraProgramareIdx)

                    oraProgramareIdx = i
                    isOraProgramata = true

                }
            }

        }
        
    }
    

    onTestQueue = () => {


        let queue = [...this.state.queue];
        let it = this.state.iterator;
        if(this.state.queue.length < 3){
            

            queue.push(it);
            it = it+1;

            this.setState({

                queue: queue,
                iterator: it
            })  
        }
        else{

            queue.shift();
            it = it - 1;

            this.setState({

                queue: queue,
                iterator: it
            }) 
        }
    }

    encodeToBaseSixtyFour = (user, token) => {

        const userAndToken = user + token;
        const encodedCredentials = Buffer.from(userAndToken).toString('base64')

        return encodedCredentials;
    }

    testBase64 = async () => {


        const accToken = new LocalStorage().GetAccountToken();
        let addr = 'https://ws.smartbill.ro/SBORO/api/invoice';

        let q1 = `select * from accounts_integrations where account_token = '${accToken}'
        and ifnull(activ,0)=1 `;
        
        let q2 = `select * from trc where token = 'I9SOQG2l8HG1OpteNhNyhUYqzL95aswS'  `;

        let _smartBill =  this.WS.sqlCommand(`select`, q1);
        let _appointment = this.WS.sqlCommand(`select`, q2);
        let [smartBill, appointment] = await Promise.all( [_smartBill, _appointment] );

        const emailAcc = smartBill.content[0]['smart_bill_email'];
        const tokenAcc= smartBill.content[0]['smart_bill_api_key'];

        const result = this.encodeToBaseSixtyFour(emailAcc, tokenAcc);

        const dateFactuare = new Declarations().generateSmartBillInvoiceObject(smartBill.content[0], appointment.content[0]);

        let r = await fetch(addr, {

            method: 'POST',
            headers: {
                'Accept': 'application/json',
                // 'Accept' : 'application/octet-stream',
                'Content-Type': 'application/json',
                "authorization": "Basic " + result
                //  'Content-Type': 'text/plain'
            },
            
            body: JSON.stringify(dateFactuare)
        });

        if (r.status === 200) {

            let rj = await r.json(); 

            // return rj;     
            
            if(rj['errorText'].length < 1){

            }
            else{

                this.setState({

                    errorTx: 'Eroare generare factura!',
                    isLoading: false
                });
            }
            
        }
        else {
            console.error("POST SERVER STATUS ERR", r)
            // return null;
            this.setState({

                errorTx: 'Eroare generare factura!',
                isLoading: false
            });

            return;
        }
    }

    render(){

        return(

            <div className="circle-container">

                <button 
                    onClick={this.onOreDisponibile}
                >
                    Apasa
                </button>

                <button 
                    onClick={this.onTestQueue}
                >
                    Queue
                </button>

                <button 
                    onClick={this.testBase64}
                >
                    BASE64
                </button>

            </div>
        )
    }
}