import React from 'react';
import IconText from '../../../commons/IconText';

export default class PercentageStat extends React.Component{

    constructor(props){

        super(props);

        this.state = {

        };
    }

    render(){

        return(

            <div className='statistics-pie-wrapper'>

                <div>

                    <IconText 
                        icon = {
                            <span 
                                className='stat-circle'
                                style={{
                                    backgroundColor: this.props.statColor
                                }}
                            ></span>
                        }
                        tx = {this.props.statTitle}
                        txSize = '12px'
                        txWeight = '400'
                        txCol = '#777777'

                    />
                    
                </div>

                <p className='statistics-subsolTx'>{this.props.statRate}%</p>

            </div>
        )
    }
}