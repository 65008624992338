import React from 'react';
import AuthInput from '../AuthInput';

export default class ScheduleName extends React.Component{

    constructor(props){

        super(props);

        this.state = {
            
            scheduleName: this.props.scheduleName,
            scheduleNameTmp: this.props.scheduleName,
            
            isNameError: false,
            isNameValid: false,
            nameErrorTx: '',
        };  
    }

    componentDidMount(){


    }

    componentDidUpdate(prevProps, _){

        if(prevProps.triggerSave !== this.props.triggerSave){

            this.onCheckScheduleName();
        }

        if(prevProps['scheduleName'] !== this.props['scheduleName']){

            if((this.props['scheduleName'] || '').length < 1){

                this.setState({

                    scheduleName: this.props.scheduleName,
                    scheduleNameTmp: this.props.scheduleName,
                    isNameError: false,
                    isNameValid: false,
                    nameErrorTx: '',
                });
            }
            else{

                this.setState({

                    scheduleName: this.props.scheduleName,
                    scheduleNameTmp: this.props.scheduleName,
                    nameErrorTx: '',
                    isNameError: false,
                });
            }

            
        }
    }

    onFilterScheduleName = () => {

        if(this.props.schedules !== undefined && this.props.schedules !== null){

            return this.props.schedules.filter(schEl => {

                if((schEl['name'] || '').toLowerCase() === this.state.scheduleName.toLowerCase() &&
                    schEl['token'] !== this.props.selectedScheduleTk && (+schEl['draft'] || 0) !== 1 &&
                    (+schEl['deleted'] || 0 ) !== 1
                ){
    
                    return schEl;
                }
            });
        }
    }
    
    onVerifyInputStatus = () => {

        const doNameAlreadyExists = this.onFilterScheduleName();

        if(doNameAlreadyExists.length > 0 || this.state.scheduleName.length < 4){

            this.props.inputStatus(true, 'isNameOk', 'isNameOk', false, true);
        }
        else{

            this.props.inputStatus(true, 'isNameOk', 'isNameOk', true, true);
        }
    } 

    onCheckScheduleName = () => {

        let isNameValid = true;
        let isNameError = false;
        let nameErrorTx = '';

        const doNameAlreadyExists = this.onFilterScheduleName();

        if(this.state.scheduleName.length < 4){

            isNameValid = false;
            isNameError = true;
            nameErrorTx = 'Numele este prea scurt.'
        }
        else if(doNameAlreadyExists.length > 0){

            isNameValid = false;
            isNameError = true;
            nameErrorTx = 'Numele exista deja.'
        }
        else{

            isNameValid = true;
            isNameError = false;
            nameErrorTx = ''
        } 

        
        this.setState({

            isNameError: isNameError,
            isNameValid: isNameValid,
            nameErrorTx: nameErrorTx,
        });
    }

    onChangeScheduleName = (ev) => {

        let doSave = true;

        if(ev.target.value === this.state.scheduleNameTmp){

            doSave = false;
        }

        this.setState({

            scheduleName: ev.target.value,
        },
        
            () => {

                this.props.inputStatus(doSave, 'nameStatus', 'scheduleName', ev.target.value);

                let duration = 500;
                clearTimeout(this.inputTimer);
                    this.inputTimer = setTimeout( async ()=>{
                    this.onVerifyInputStatus();
                }, duration);
            }
        );
    }

    render(){

        return(

            <div className="form-control" style={this.props.style}>

                <AuthInput 
                    label="Denumire Program"
                    placeholder="Ex: Program Dimineata"
                    value={this.state.scheduleName}
                    onChange={this.onChangeScheduleName}
                    iserror={this.state.isNameError}
                    errormsg={this.state.nameErrorTx}
                    verified={this.state.isNameValid}
                    onFocus = {
                        () => {

                            this.setState({
                                nameErrorTx: null
                            })
                        }
                    }
                />

            </div>
        );
    }
}