import React from 'react';

import { BrowserView, MobileView } from 'react-device-detect';
import { GoCheck } from 'react-icons/go';
import { MdAccountBalanceWallet } from 'react-icons/md';
import { IoReceiptOutline } from 'react-icons/io5';

import MServiceNav from '../../commons/MServiceNav';
import LaptopAndMugImg from '../../assets/laptop_and_mug.png';
import IconText from '../../commons/IconText';

export default class PaymentHistory extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            canSave: false
        }
    }

    componentDidMount(){


    }

    render(){

        return(

            <> 
                <BrowserView>
                
                    <div 
                        className="wrapper-dashboard integrari" 
                        style={{

                            margin:'30px auto',
                            minHeight: '900px',
                            background: `url(${LaptopAndMugImg}) bottom no-repeat`
                        }}
                    >

                        <div className="service-details-card" style={{textAlign: 'left'}}>
                            
                            <IconText 
                                top = '0px'
                                icon = {
                                    <MdAccountBalanceWallet
                                        style={{
                                            fontSize: '24px',
                                            color: '#FFC727'
                                        }} 
                                    />
                                }
                                tx = 'Istoric Plati'
                                txSize = '18px'
                                txWeight = '500'
                                txCol = '#74838B'
                            />

                            <p 
                                className='payment-history-tx fs16'
                                style={{
                                    marginTop: '20px',
                                    fontWeight: '500'
                                }}
                            >
                                Urmatoarea Plata
                            </p>

                            <p 
                                className='payment-history-tx fs16'
                                style={{
                                    marginTop: '10px',
                                    color: '#777777'
                                }}
                            >
                                50 Lei
                            </p>

                            <p 
                                className='payment-history-tx fs12'
                                style={{
                                    marginTop: '8px',
                                    color: '#777777',
                                }}
                            >
                                Factura se va emite pe 23 Aprilie 2021
                            </p>
                            
                            <div 
                                className='separator-line'
                                style={{
                                    marginTop: '16px'
                                }}
                            ></div>

                            <div 
                                className='row'
                                style={{
                                    marginTop: '12px'
                                }}
                            >

                                <div className='col-l-5 col-m-5 col-s-5'>

                                    <p 
                                        className='payment-history-tx fs16'
                                        style={{
                                            fontWeight: '500'
                                        }}
                                    >
                                        Data
                                    </p>

                                </div>

                                <div className='col-l-5 col-m-5 col-s-5'>
                                    
                                    <p 
                                        className='payment-history-tx fs16'
                                        style={{
                                            
                                            fontWeight: '500'
                                        }}
                                    >
                                        Platit
                                    </p>

                                </div>

                            </div>

                            <div 
                                className='row'
                                style={{
                                    marginTop: '12px'
                                }}
                            >

                                <div className='col-l-5 col-m-5 col-s-5'>

                                    <p 
                                        className='payment-history-tx fs16'  
                                    >
                                        23 Mar 2021
                                    </p>

                                </div>

                                <div className='col-l-5 col-m-5 col-s-5'>
                                    
                                    <p 
                                        className='payment-history-tx fs16'  
                                    >
                                        50 Lei
                                    </p>
                                </div>

                                <div 
                                    className='col-l-2 col-m-2 col-s-2'
                                    style={{
                                        textAlign: 'right'
                                    }}
                                >
                                    <IoReceiptOutline 
                                        style={{
                                            color: '#323232'
                                        }}
                                    />
                                    
                                </div>

                            </div>

                            <div 
                                className='separator-line'
                                style={{
                                    marginTop: '14px'
                                }}
                            ></div>

                            <div 
                                className='row'
                                style={{
                                    marginTop: '12px'
                                }}
                            >

                                <div className='col-l-5 col-m-5 col-s-5'>

                                    <p 
                                        className='payment-history-tx fs16'  
                                    >
                                        23 Feb 2021
                                    </p>

                                </div>

                                <div className='col-l-5 col-m-5 col-s-5'>
                                    
                                    <p 
                                        className='payment-history-tx fs16'  
                                    >
                                        50 Lei
                                    </p>
                                </div>

                                <div 
                                    className='col-l-2 col-m-2 col-s-2'
                                    style={{
                                        textAlign: 'right'
                                    }}
                                >
                                    <IoReceiptOutline 
                                        style={{
                                            color: '#323232'
                                        }}
                                    />
                                    
                                </div>

                            </div>

                        </div>

                    </div>
                
                </BrowserView>

                <MobileView>

                    <div style={{position: 'relative'}}>

                        <MServiceNav 
                            icon={<MdAccountBalanceWallet />}
                            title='Istoric Plati'
                            path={'/ca/dashboard/cab'}
                        />

                        {
                            this.state.canSave &&
                            <GoCheck
                                className="m-add-service"
                                // onClick={this.saveToDb}
                            />
                        }

                    </div>

                    <div 
                        style={{
                            padding: '20px'
                        }}
                    >


                        <div 
                            className="m-edit-s-card"
                            style={{
                                textAlign: 'left'
                            }}
                        >

                            <p 
                                className='payment-history-tx'
                                style={{
                                    fontWeight: '500'
                                }}
                            >
                                Urmatoarea Plata
                            </p>

                            <p 
                                className='payment-history-tx'
                                style={{
                                    marginTop: '10px',
                                    color: '#777777'
                                }}
                            >
                                50 Lei
                            </p>

                            <p 
                                className='payment-history-tx'
                                style={{
                                    marginTop: '8px',
                                    color: '#777777',
                                    fontSize: '10px'
                                }}
                            >
                                Factura se va emite pe 23 Aprilie 2021
                            </p>
                            
                            <div 
                                className='separator-line'
                                style={{
                                    marginTop: '16px'
                                }}
                            ></div>

                            <div 
                                className='row'
                                style={{
                                    marginTop: '12px'
                                }}
                            >

                                <div className='col-l-5 col-m-5 col-s-5'>

                                    <p 
                                        className='payment-history-tx'
                                        style={{
                                            fontWeight: '500'
                                        }}
                                    >
                                        Data
                                    </p>

                                </div>

                                <div className='col-l-5 col-m-5 col-s-5'>
                                    
                                    <p 
                                        className='payment-history-tx'
                                        style={{
                                            fontWeight: '500'
                                        }}
                                    >
                                        Platit
                                    </p>

                                </div>

                            </div>

                            <div 
                                className='row'
                                style={{
                                    marginTop: '12px'
                                }}
                            >

                                <div className='col-l-5 col-m-5 col-s-5'>

                                    <p 
                                        className='payment-history-tx'  
                                    >
                                        23 Mar 2021
                                    </p>

                                </div>

                                <div className='col-l-5 col-m-5 col-s-5'>
                                    
                                    <p 
                                        className='payment-history-tx'  
                                    >
                                        50 Lei
                                    </p>
                                </div>

                                <div 
                                    className='col-l-2 col-m-2 col-s-2'
                                    style={{
                                        textAlign: 'right'
                                    }}
                                >
                                    <IoReceiptOutline 
                                        style={{
                                            color: '#323232'
                                        }}
                                    />
                                    
                                </div>

                            </div>

                            <div 
                                className='separator-line'
                                style={{
                                    marginTop: '14px'
                                }}
                            ></div>

                            <div 
                                className='row'
                                style={{
                                    marginTop: '12px'
                                }}
                            >

                                <div className='col-l-5 col-m-5 col-s-5'>

                                    <p 
                                        className='payment-history-tx'  
                                    >
                                        23 Feb 2021
                                    </p>

                                </div>

                                <div className='col-l-5 col-m-5 col-s-5'>
                                    
                                    <p 
                                        className='payment-history-tx'  
                                    >
                                        50 Lei
                                    </p>
                                </div>

                                <div 
                                    className='col-l-2 col-m-2 col-s-2'
                                    style={{
                                        textAlign: 'right'
                                    }}
                                >
                                    <IoReceiptOutline 
                                        style={{
                                            color: '#323232'
                                        }}
                                    />
                                    
                                </div>

                            </div>

                        </div>

                    </div>

                </MobileView>

            </>
        )
    }
}