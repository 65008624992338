import { MdTrendingDown, MdTrendingUp } from "react-icons/md";


export default class StatisticsLogic{

    setTrendingIcon = (trendingRate) => {

        let trendingIcon = {};

        if(+trendingRate < 0){

            trendingIcon = {

                icon: (

                    <MdTrendingDown
                        style={{
                            color: '#DD6A57',
                            fontSize: '20px'
                        }}
                    />
                ),
                txColor: '#DD6A57',
                tx: `${trendingRate}%`
            }
        }
        else{

            trendingIcon = {

                icon: (

                    <MdTrendingUp
                        style={{
                            color: '#31A81D',
                            fontSize: '20px'
                        }}
                    />
                ),

                txColor: '#31A81D',
                tx: `+${trendingRate}%`
            }
        }

        return trendingIcon;
    }
}