import React from 'react';

import { IoVideocam } from 'react-icons/io5';
import { FaSkype } from 'react-icons/fa';
import { ImLocation2 } from 'react-icons/im';
import { GoVerified } from 'react-icons/go';
import { ReactComponent as GMeetIcon} from '../assets/Google_Meet-Logo.wine.svg';
import { ReactComponent as ZoomIcon } from '../assets/zoom_icon.svg';
import { ReactComponent as LocIcon } from '../assets/pin_drop.svg';

import './Commons.css'
import Declarations from '../providers/Declarations';
import { MdPower } from 'react-icons/md';
import ServicesFuncs from '../module/logic/ServicesFuncs';

export default class Location extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            isLocationShown: false,
        }
    }

    SF = new ServicesFuncs();

    onLocationClick = () => {

        this.setState({

            isLocationShown: !this.state.isLocationShown
        })
    }

    onSelectedLocation = (location) => {

        this.props.onLocationSelect(location);
        this.onCloseLocationList();

        // 
    }

    

    onCloseLocationList = () =>{

        this.setState({

            isLocationShown: false
        })
    }

    render(){

        return(

            <div>
                
                <div 
                    className="location-card"
                    onClick={this.onLocationClick}
                >
                    {   
                        this.props.currentLocation.shortTx !== null &&
                        this.props.currentLocation.tx !== null &&    
                        
                        <div 
                            className="row"
                            style={{
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >

                            <div 
                                className="col-l-6 col-m-6 col-s-6"
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '50%',
                                    textAlign: 'left'
                                }}
                            >
                    
                                {
                                    this.props.id === 'zoom' && (

                                        <ZoomIcon className="zoom-icon-loc" />
                                    )
                                    
                                }

                                {
                                    this.props.id === 'skype' && (

                                        <FaSkype className="location-icon" />
                                    )
                                    
                                }

                                {
                                    this.props.id === 'office' && (

                                        <LocIcon className="office-icon-loc" />
                                    )   
                                    
                                }

                                {
                                    this.props.id === 'gmeet' && (

                                        <GMeetIcon 
                                            className="location-icon gmeet"
                                            style={{
                                                width: '24px',
                                                height: '22px'
                                            }}
                                        />
                                    )
                                }

                                <div 
                                    className="location-info" 
                                    //style={{display: 'inline-block'}}
                                >

                                    <p>
                                        {this.props.currentLocation.tx}
                                        <br />
                                        <span>{this.props.currentLocation.shortTx}</span>
                                    </p>


                                </div>

                            </div>

                            <div 
                                // className="col-l-6 col-m-6 col-s-6"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                    width: '50%',
                                }}
                            >
                                
                                <div 
                                    className="sync-info" 
                                    //style={{display: 'inline-block'}}
                                >

                                    {
                                        this.SF.onSelectIntegration(this.props.integrations, this.props.currentLocation['id']) && (

                                            <p>
                                                <GoVerified className="sync-icon"/>
                                                <br />
                                                <span>Sync Ok</span>
                                            </p>
                                        )
                                    }

                                    {
                                        !this.SF.onSelectIntegration(this.props.integrations, this.props.currentLocation['id']) && (

                                            <p>
                                                <MdPower className="sync-icon connect"/>
                                                <br />
                                                <span>Conecteaza</span>
                                            </p>
                                        )
                                    }


                                </div>
                                

                            </div>

                        </div>
                    }

                    {
                        this.props.currentLocation.shortTx == null &&
                        this.props.currentLocation.tx == null &&
                        
                        <div>
                            <h5
                                style={{
                                    marginTop: 15,
                                    color: '#455A64'
                                }}
                            >
                                Alege unde se va desfasura programarea
                            </h5>
                        </div>    

                    }

                    {
                        this.state.isLocationShown &&

                        <div className="select-location">

                            {
                                this.props.listaLocatii.map((lEl,idx) => {

                                    const isActive = this.SF.onSelectIntegration(this.props.integrations, lEl['id']);
                                    
                                    return(
                                        <div   
                                            key={'select-location-'+idx}
                                            className="select-loc-row"
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                width: '100%',
                                                height: '60px',
                                                cursor: isActive ? 'pointer' : 'no-drop' 
                                            }}
                                            onClick={

                                                () => {

                                                    if(isActive){

                                                        this.onSelectedLocation(lEl);
                                                    }
                                                }
                                                   
                                            }
                                        >

                                        <div 
                                            // className="col-l-6 col-m-6 col-s-6"
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                width: '50%',
                                                textAlign: 'left'
                                            }}
                                        >
                                
                                            {
                                                lEl.shortTx === 'Zoom.us' &&
                                                <ZoomIcon className="zoom-icon-loc" />
                                            }
            
                                            {
                                                lEl.shortTx === 'Skype' &&
                                                <FaSkype className="location-icon" />
                                            }
            
                                            {
                                                lEl.shortTx === 'Sediu' &&
                                                <LocIcon className="office-icon-loc" />
                                            }

                                            {
                                                lEl.shortTx === 'Google Meet' && (

                                                    <GMeetIcon 
                                                        className="location-icon gmeet"
                                                        style={{
                                                            width: '24px',
                                                            height: '22px'
                                                        }}
                                                    />
                                                )
                                            }
            
                                            <div 
                                                className="location-info" 
                                                //style={{display: 'inline-block'}}
                                            >
            
                                                <p>
                                                    {lEl.tx}
                                                    <br />
                                                    <span>{lEl.shortTx}</span>
                                                </p>
            
            
                                            </div>
            
                                        </div>
            
                                        <div 
                                            // className="col-l-6 col-m-6 col-s-6"
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'flex-end',
                                                width: '50%'
                                                // textAlign: 'right'
                                            }}
                                        >
                                            
                                            <div className="sync-info" style={{display: 'inline-block'}}>
            
                                                {
                                                    isActive && (

                                                        <p>
                                                            <GoVerified className="sync-icon"/>
                                                            <br />
                                                            <span>Sync Ok</span>
                                                        </p>
                                                    )
                                                }

                                                {
                                                    !isActive && (

                                                        <p>
                                                            <MdPower className="sync-icon connect"/>
                                                            <br />
                                                            <span>Conecteaza</span>
                                                        </p>
                                                    )
                                                }
            
            
                                            </div>
                                            
            
                                        </div>
            
                                    </div>
                                );

                                })
                            }


                        </div>

                    }

                </div>

            </div>
        );
    }
}