import React from 'react';

import { BrowserView, MobileView } from 'react-device-detect';
import { GoCheck } from 'react-icons/go';
import { MdHistory } from 'react-icons/md';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

import MServiceNav from '../../commons/MServiceNav';
import LaptopAndMugImg from '../../assets/laptop_and_mug.png'

import Masks from '../../providers/Masks';
import Utils from '../../providers/Utils';
import Ws from '../../providers/Ws';
import LocalStorage from '../../providers/LocalStorage';
import Declarations from '../../providers/Declarations';
import AppointmentsHistoryElement from '../../commons/AppointmentHistoryElement';
import DateChanger from '../../commons/DateChanger';
import IconText from '../../commons/IconText';

export default class AppointmentsHistory extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            canSave: false,
            dataStart: new Date(2021, 6 , 1),
            dataEnd: new Date(2021, 6 , 30),
            appointments: [],
            groupdByDays: [],
            totalMoney: 0,
            services: []
        }
    }

    WS = new Ws();
    M = new Masks();
    UT = new Utils();

    localData = {

        colors: new Declarations().listaCulori,
        locations: new Declarations().listaLocatiiIntalnire,
        todayDate: new Date()
    };

    componentDidMount(){

        this.initDate();
    }

    initDate = () =>{

        let dt = new Date()
    
        let backTrick = this.M.addMonths(dt, -1)

        this.setState({

            dataStart: new Date(backTrick.getFullYear(), backTrick.getMonth(), 1),
            dataEnd: new Date(backTrick.getFullYear(), backTrick.getMonth(), 1)
        },
        
            () => {

                this.nextMonth(false)
            }
        ) 
    }

    nextMonth = async (back) => {
 

        let coef = back ? - 1 : 1;

        let nextMonthStart = this.M.addMonths(this.state.dataStart, coef)

        let nextMonthEnd = this.M.firstOrLastDayOfMonth( nextMonthStart.getMonth(), nextMonthStart.getFullYear(), true)

        const TOKEN_CONT = new LocalStorage().GetAccountToken();

        let selectAppoints = `select * from trc where month(data_cal) = ${nextMonthStart.getMonth() + 1} and year(data_cal) = ${nextMonthStart.getFullYear()} and account_tk = '${TOKEN_CONT}' and ifnull(draft,0)!=1 and ifnull(anulat,0)!=1 `
        let _fetchAppoint =  this.WS.sqlCommand(`select`, selectAppoints)


        let selectUnconfirmedAppoints =  `select * from trc where month(data_cal) = ${nextMonthStart.getMonth() + 1} and year(data_cal) = ${nextMonthStart.getFullYear()} and account_tk = '${TOKEN_CONT}' and 
                                            tip = 'programare' and src = 'ca' and ifnull(draft,0)=1 and ifnull(anulat,0)!=1 `
        let _unconfirmedAppoints = this.WS.sqlCommand(`select`, selectUnconfirmedAppoints);

        let selectServices = `select * from services where account_tk = '${TOKEN_CONT}' `
        // 

        let _fecthServices =  this.WS.sqlCommand(`select`, selectServices);
     
        let [fetchAppoint, unconfirmedAppoints, fetchServices] = await Promise.all([_fetchAppoint, _unconfirmedAppoints, _fecthServices]);

        if(fetchAppoint.success && unconfirmedAppoints.success && fetchServices.success){

            let monthlyAppoints = fetchAppoint.content;

            monthlyAppoints.sort( (prev, next) => {

                if(this.M.sqlDateToJSDate(prev['data_cal']).getDate() < this.M.sqlDateToJSDate(next['data_cal']).getDate()){

                    return -1;
                }
                else{

                    return 1;
                }
            })

            monthlyAppoints = monthlyAppoints.filter(maEl => {

                if( ( (maEl['tip'] || '') !== 'pauza' ) && 
                        (maEl['src'] || '') !== 'ca' 
                        // && +maEl['price'] > 0
                ){

                    return maEl;
                }
            });

            let daysToGroup = this.groupAppointemntsByDay(monthlyAppoints);

            // 

            // 
    
            this.setState({
    
                dataStart: nextMonthStart,
                dataEnd: nextMonthEnd,
                appointments: monthlyAppoints,
                totalMoney: this.getTotalMoneyReceived(monthlyAppoints),
                groupdByDays: daysToGroup,
                services: fetchServices.content
            }, 
    
                () => {

                    

                }
            )

        }
        else{

            throw new Error("Server response error: " + fetchAppoint.message + "\n" + "\n" + unconfirmedAppoints.message)
        }  
    }


    groupAppointemntsByDay = (appointList) => {

        let appointListNoDuplicates = []

        if(appointList.length > 1){

            appointListNoDuplicates = appointList.reduce((unique, o) => {
                
                if(!unique.some( obj => this.M.sqlDateToJSDate(obj['data_cal']).getTime() === this.M.sqlDateToJSDate(o['data_cal']).getTime() )) {
                    unique.push(o);
                  }
                  return unique;

            }, [])

            let todayDate = new Date();
            todayDate.setHours(0,0,0,0);

            // appointListNoDuplicates = appointListNoDuplicates.filter(anEl => {

            //     if( this.M.sqlDateToJSDate(anEl['data_cal']).getTime() >= todayDate.getTime() ){

            //         return anEl;
            //     }
            // })

        }
        else if(appointList.length === 1){

            appointListNoDuplicates = appointList
        }

        appointListNoDuplicates = appointListNoDuplicates.filter(alndEl => (alndEl['tip'] || '') !== 'pauza')

        return appointListNoDuplicates;
    }

    getTotalMoneyReceived = (appointments) => {

        let total = 0;

        for(let i = 0; i < appointments.length; i++){

            total += +appointments[i].price;
        }

        return +total;
    }

    render(){

        return(
            <>

                <BrowserView>
                
                    <div 
                        className="wrapper-dashboard integrari" 
                        style={{

                            margin:'30px auto',
                            minHeight: '900px',
                            background: `url(${LaptopAndMugImg}) bottom no-repeat`
                        }}
                    >

                        <div className="service-details-card">

                            <IconText 
                                top = '0px'
                                icon = {
                                    <MdHistory
                                        style={{
                                            fontSize: '24px',
                                            color: '#FFC727'
                                        }} 
                                    />
                                }
                                tx = 'Istoric Programari'
                                txSize = '18px'
                                txWeight = '500'
                                txCol = '#74838B'
                            />

                            <br />

                            <DateChanger
                                nextMonth = {this.nextMonth}
                                date = {`${this.M.monthName(this.state.dataStart.getMonth())} ${this.state.dataStart.getFullYear()}`}
                                showNextArrow = {
                                    this.state.dataStart.getMonth() >= new Date().getMonth() &&  
                                    this.state.dataStart.getFullYear() >= new Date().getFullYear()  
                                    ? false : true
                                }
                                showBackArrow = {true}
                                arrowSize = '26px'
                                arrowTop = '7px'
                            />

                            <p
                                style={{
                                    margin: 0,
                                    marginTop: '10px',
                                    fontSize: '14px',
                                    fontWeight: '400',
                                    color: '#263238',
                                }}
                            >
                                <b>{this.state.appointments.length} Programari</b> - Total Incasari: {this.state.totalMoney} Lei
                            </p>

                            <br />

                            <p
                                style={{

                                    margin: 0,
                                    fontSize: '14px',
                                    fontWeight: '400',
                                    color: '#44A5FF',
                                    cursor: 'pointer'
                                }}
                                onClick = {this.props.onShowRapoarte}
                            >
                                Analiza si Rapoarte
                            </p>

                            <br />

                            {
                                this.state.appointments.length > 0 && (

                                    <AppointmentsHistoryElement
                                        groupdByDays = {this.state.groupdByDays}
                                        appointments = {this.state.appointments}
                                        services = {this.state.services}
                                    />
                                )
                            }

                            {
                                this.state.appointments.length === 0 && (

                                    <p
                                        style={{
                                            margin: 0,
                                            fontWeight: '400',
                                            fontSize: '16px',
                                            color: '#C1C1C1'
                                        }}
                                    >
                                        Nu ai nicio plata pentru luna {this.M.monthName(this.state.dataStart.getMonth())}
                                    </p>
                                )
                            }

                        </div>
                        
                    </div>

                </BrowserView>

                <MobileView>

                    <div
                        style={{
                            position: 'relative'
                        }} 
                    >

                        <MServiceNav 
                            icon={<MdHistory />}
                            title='Istoric Programari'
                            path={'/ca/dashboard/cab'}
                        />
                        
                        {
                            this.state.canSave &&
                            <GoCheck
                                className="m-add-service"
                                onClick={this.onCheckAndSave}
                            />
                        }


                    </div>

                    <div
                        style={{
                            padding: 20
                        }}
                    >
                        <div className="m-edit-s-card">
                            
                            <DateChanger
                                nextMonth = {this.nextMonth}
                                date = {`${this.M.monthName(this.state.dataStart.getMonth())} ${this.state.dataStart.getFullYear()}`}
                                showBackArrow = {true}
                                arrowSize = '26px'
                                arrowTop = '7px'
                            />

                            <p
                                style={{
                                    margin: 0,
                                    marginTop: '10px',
                                    fontSize: '14px',
                                    fontWeight: '400',
                                    color: '#263238',
                                }}
                            >
                                <b>{this.state.appointments.length} Programari</b> - Total Incasari: {this.state.totalMoney} Lei
                            </p>

                            <br />

                            <p
                                style={{

                                    margin: 0,
                                    fontSize: '14px',
                                    fontWeight: '400',
                                    color: '#44A5FF'
                                    
                                }}
                            >
                                Analiza si Rapoarte
                            </p>

                            <br />

                            {
                                this.state.appointments.length > 0 && (

                                    <AppointmentsHistoryElement
                                        groupdByDays = {this.state.groupdByDays}
                                        appointments = {this.state.appointments}
                                        services = {this.state.services}
                                    />
                                )
                            }

                            {
                                this.state.appointments.length === 0 && (

                                    <p
                                        style={{
                                            margin: 0,
                                            fontWeight: '400',
                                            fontSize: '16px',
                                            color: '#C1C1C1'
                                        }}
                                    >
                                        Nu ai nicio plata pentru luna {this.M.monthName(this.state.dataStart.getMonth())}
                                    </p>
                                )
                            }

                        </div>

                    </div>

                </MobileView>

            </>
        )
    }
}   