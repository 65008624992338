import React from 'react';
import { MobileView } from 'react-device-detect';

import Masks from '../../../providers/Masks';
import Utils from '../../../providers/Utils';
import Ws from '../../../providers/Ws';
import ServicesFuncs from '../../logic/ServicesFuncs';
import RegisterHeader from '../RegisterHeader';

import Image from '../../../assets/Businesswoman-cuate 1.png';
import ButtonForm from '../../../commons/ButtonForm';
import { IoIosArrowForward } from 'react-icons/io';

import MBirdAndPlantImage from '../../../assets/bird_and_plant_mobile.png';

export default class MStartWizard extends React.Component{

    constructor(props){

        super(props);
        
        this.state = {

        };
    }

    WS = new Ws();
    UT = new Utils();
    M = new Masks();
    SF = new ServicesFuncs();

    componentDidMount(){


    }

    render(){

        return(

            <MobileView>
                <div 
                    style={{
                        padding: '20px',
                        minHeight: '850px',
                        background: `url(${MBirdAndPlantImage}) bottom no-repeat`
                    }}
                >
                
                    <RegisterHeader />
                    
                    <div className="m-edit-s-card" style={{marginTop: '20px', textAlign: 'left'}}>
                        
                        <p className='register-tx sm register-title bold'>
                            Salut!
                        </p>

                        <p className='register-tx sm register-title' style={{marginTop: '16px'}}>
                            Bine ai venit la Planary! 
                            <br />
                            <br />
                            Hai sa completam impreuna datele necesare activarii si sa 
                            cream primul tau serviciu. Acest proces poate dura pana la 10 minute.
                        </p>

                        <img
                            className='register-image'
                            src={Image}
                            alt="Planary"
                        />

                        <div 
                            style={{
                                textAlign: 'center'
                            }}
                        >
                            <ButtonForm
                                type='button'
                                tx={
                                    <>
                                        <p style={{display: 'inline-block', position: 'relative', top: '-2px', margin: 0}}>
                                            Incepe &nbsp;
                                        </p> 
                                        <IoIosArrowForward className='register-next-arrow'/>
                                    </>
                                }
                                
                                style={{
                                    marginTop: '20px',
                                    width: '100%',
                                    height: '56px',
                                    background: '#F8BC05',
                                    fontSize: '16px',
                                    padding: '0px 35px 0px 35px'
                                }}
                                onClick={
                                    () => {

                                        this.props.history.push('/inregistrare/date/identificator');
                                    }
                                }
                            />
                        </div>

                    </div>

                </div>

            </MobileView>
        );
    }
}