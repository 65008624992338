import React from 'react';
import Ws from '../../providers/Ws';
import Masks from '../../providers/Masks';

import { FiPlus } from 'react-icons/fi';

export default class IntervalViewClient extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataStart: new Date(),
            dataEnd: new Date(),
            calendar: [],

            config: this.props.config || {
                titlu: "Calendar"
            },

            colors: this.props.colors || {
                tdBackGroundColor: "#FFF7E0",
                tdBorderColor: "lightgray",
                dayNumberColor: "#bf4a38",
                thColor: "#636363"
            },

            mainStyle: this.computeMainStyle(),
            selectedDate: '',
            availableDays: []
        }
       
    }

    WS = new Ws()
    M = new Masks()

    localData = {
        dataStart: new Date(),
        dataEnd: new Date(),
        weekenduriLucratoare: [],
    }

    // PROPS:
    // elements:[  { id:unique, data:JSDate(),header:'',label:'', backgroundColor:'', color:'' }  ] 

    // dataStart -- optional (daca nu exista se va lua minimul date din elements)
    // dataEnd -- optional (daca nu exista se va lua maximul date din elements)
    // onElementClick = 
    // colors optional
    // width: optional

    componentDidMount() {
        this.generateCalendarView(this.props.elements)
    }

    computeMainStyle = () => {
        let reportingWidth = this.props.width || 80

        let width = `${reportingWidth}vw`
        let tdWidth = `${parseInt(reportingWidth / 7)}vw`
        let tdHeight = `${parseInt(reportingWidth / 10)}vw`

        return { width, tdWidth, tdHeight }
    }

    // la pachet pentru a inlocui willReceiveNewProps (depreciated)
    static getDerivedStateFromProps(nextProps, prevState) {
        if ((nextProps.dataStart !== prevState.dataStart) || (nextProps.dataEnd !== prevState.dataEnd)) {
            // 
            return (
                {
                    dataStart: nextProps.dataStart,
                    dataEnd: nextProps.dataEnd
                }
                //true
            );
        }
        else {
            // 
            return null
        }
    }
    // va fi declansata de fuctia de mai sus, daca returneaza true
    componentDidUpdate(prevProps, prevState) {

        /// dooooooh !!!!!
        if ((prevProps.dataStart !== this.props.dataStart) || (prevProps.dataEnd !== this.props.dataEnd) || (prevProps.elements !== this.props.elements)) {
            //
            this.generateCalendarView(this.props.elements)
            
        }
        else {
            // 
        }

       
    }

    shouldComponentUpdate(nextProps, nextState){
        return true;
    }

    generateCalendarView = (elements) => {

        // 

        this.computeLocalDates()
        let weeks = this.generateWeeksArray(elements)

        this.setState({
            calendar: weeks,
            selectedDate: this.props.dateSelected
        }, () => {
            //
        })

       

    }

    computeLocalDates = () => {

        let dataStart = null
        let dataEnd = null

        if (this.props.dataStart && this.props.dataEnd && this.props.elements) {
            dataStart = this.props.dataStart
            dataEnd = this.props.dataEnd
        }
        else {
            if ((this.props.elements || []).length > 0) {

                let elementsSortedByDate = this.props.elements.sort((a, b) => {
                    if (a["data"].getTime() < b["data"].getTime()) {
                        return -1
                    }
                    return 1
                })

                dataStart = elementsSortedByDate[0]["data"]
                dataEnd = elementsSortedByDate[elementsSortedByDate.length - 1]["data"]


            }
            else {
                dataStart = this.M.addDaysToDateAndConvertToField(new Date(), -30)
                dataEnd = this.M.curentDateToDateField()
            }
        }

        this.setState({
            dataStart: dataStart,
            dataEnd: dataEnd
        })

        this.localData.dataStart = dataStart
        this.localData.dataEnd = dataEnd

    }

    generateWeeksArray = (elements) => {
        let zile = this.generateListaZile(this.localData.dataStart, this.localData.dataEnd, elements)

        //

        // just in case
        zile.sort((a, b) => {
            if (a['data'].getTime() < b['data'].getTime()) {
                return -1
            }
            return 1
        })

        //
        if ((zile.length % 7 != 0) || zile.length < 7) {
            throw new Error("Array zile incorect generat. Incercati din nou pe un alt interval")

        }

        let weeks = []
        for (let i = 0; i <= zile.length - 1; i = i + 7) {

            let weekDays = []
            for (let k = 0; k < 7; k++) {
                weekDays.push(zile[k + i])
            }
            //

            weeks.push({
                nr: (i / 7),
                days: weekDays
            })
        }

        // 
        return weeks;
    }

    generateListaZile =  (dateStart, dateEnd, elements) => {

        let firstMonday = this.findPrevMondayDate(dateStart)
        let lastSunday = this.findLastSundayDate(dateEnd)

        let rez = []
        let iteratingDate = firstMonday

        // 

        while (iteratingDate.getTime() <= lastSunday.getTime()) {

            let isOutisideInitialInterval = (iteratingDate.getTime() < dateStart.getTime()) || (iteratingDate.getTime() > dateEnd.getTime())
            let dayNumberInWeek = iteratingDate.getDay() == 0 ? 7 : iteratingDate.getDay() + 1
            let isAvailableDay = elements.filter(elm => elm.date.getTime() === iteratingDate.getTime())

            rez.push({
                data: iteratingDate,
                isOutisideInitialInterval: isOutisideInitialInterval,
                dayNumberInWeek: dayNumberInWeek,
                isAvailable: isAvailableDay
            })

            iteratingDate = this.addDays(iteratingDate, 1)
        }

        return rez;
    }

    findPrevMondayDate = (firstDate) => {
 
        let firstDay = firstDate.getDay()

        if (firstDay == 0) {// duminica
            return this.addDays(firstDate, -6)
        }
        return this.addDays(firstDate, -1 * (firstDay - 1))
    }


    findLastSundayDate = (lastDate) => {

        let lastDay = lastDate.getDay()

        if (lastDay == 0) {// duminica
            return lastDate
        }

        return this.addDays(lastDate, 7 - lastDay)
    }


    addDays = (date, days) => {

        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }


    getDayEvents = dayDate => {
        // 
        // 
        const _elements = this.props.elements.filter(elem => elem['data'].getTime() == dayDate.getTime()) //    // elements:[  { id:unique, data:Date(),header:'',label:'', backgroundColor:'', color:'' }  ]

        return _elements.map((el, idx) => {

            return (
               <li
                    key={`mobile-appoint_${idx}`}
                    style={{
                        margin: 0,
                        listStyleType: 'none',
                        width: '5px',
                        height: '5px',
                        borderRadius: '50%',
                        background: el['color'],
                    }}
                >
                    
                </li>
            )
        })
    }

    getDayAppointsList = (dayDate) => {

        const _elements = this.props.elements.filter(elem => elem['data'].getTime() == dayDate.getTime())

        return _elements;
    }

    showMore = (dayDate) => {

        const _elements = this.props.elements.filter(elem => elem['data'].getTime() == dayDate.getTime());

        return _elements.length > 3;
    }

    onElementClick = id => {
        if (this.props.onElementClick) {
            this.props.onElementClick(id)
        }
        else {
            console.error("onElementClick is not on props")
        }
    }

    onDateClick = (el, hasElement) => {

        if (!hasElement && this.props.onDateClick) {
            this.props.onDateClick(el)
        }
    }

    settingTDBackground = (detaliiZi) => {
        // 
        if (detaliiZi.isOutisideInitialInterval) {

            return "#F4F4F4"

        } else {

            if (detaliiZi.dayNumberInWeek === 7) { // daca este in weekend

                return "#F4F4F4"

            } else {

                if (this.state.colors.tdBackGroundColor) {
                    return this.state.colors.tdBackGroundColor 
    
                } else {
                    return "#FFF7E0"
                }

            }

        }

    }

    settingWeekFieldTextColor = (detaliiZi) => {

  
        if (detaliiZi.dayNumberInWeek === 7){

            return '#fff'
        }
        else{

            return '#A6A6A6'
        }

    }

    onClickDate = (date) => {

        this.props.onClickDate(date)
        this.setState({

            selectedDate: date
        })
    } 

    setAvailableDaysForClient = (data) =>{

        const _availableDays = this.props.elements.filter(avEl => avEl.date.getTime() === data.getTime())

        return _availableDays;
    }

    render() {
        return (
            <div 
            >

                <div
                >
                    {
                        this.state.calendar.length > 0 &&

                        <table 
                            style={{ 
                                width: '100%', 
                                // borderCollapse: 'collapse',
                                borderSpacing: '0px',
                                // borderRight: this.props.isListView ? '1px solid #FAFAFA' : 'none',
                            }}
                        >
                            <thead>
                              
                                <tr>
                                    <th style={{ color: '#777777', fontSize: 16, fontWeight: 500, paddingBottom: 30 }}>L</th>
                                    <th style={{ color: '#777777', fontSize: 16, fontWeight: 500, paddingBottom: 30   }}>M</th>
                                    <th style={{ color: '#777777', fontSize: 16, fontWeight: 500, paddingBottom: 30   }}>M</th>
                                    <th style={{ color: '#777777', fontSize: 16, fontWeight: 500, paddingBottom: 30   }}>J</th>
                                    <th style={{ color: '#777777', fontSize: 16, fontWeight: 500, paddingBottom: 30   }}>V</th>
                                    <th style={{ color: '#777777', fontSize: 16, fontWeight: 500, paddingBottom: 30   }}>S</th>
                                    <th style={{ color: '#777777', fontSize: 16, fontWeight: 500, paddingBottom: 30  }}>D</th>

                                </tr>
                            </thead>

                             

                            <tbody>
    
                                {
                                    this.state.calendar.map((w, idxw) => {
                                        const _days = w.days
                                        return (
                                            <tr key={idxw}>
                                                {
                                                    
                                                    _days.map((wd, idxd) => {
                                                        // 

                                                       let todayDateLimit = new Date();
                                                       todayDateLimit.setHours(12, 0, 0, 0);

                                                       const currentDate = new Date();

                                                       let isDateOutOfTimeRange = false;

                                                       let todayDate = new Date();
                                                       todayDate.setHours(0, 0, 0, 0);

                                                       if(wd.data.getTime() === todayDate.getTime()){

                                                            isDateOutOfTimeRange = todayDateLimit.getTime() < currentDate.getTime();
                                                       }
                                                        
                                                        if(this.M.specificDateToDateField(wd.data) === this.state.selectedDate){
                                                            return (
                                                                <td key={`d-${idxw}-${idxd}`}
                                                                    style={{
                                                                        // backgroundColor: this.settingTDBackground(wd),
                                                                        // background: '#fff',
                                                                        
                                                                        // color: wd.isOutisideInitialInterval ? "#bf4a38" : (this.state.colors.tdBackGroundColor || "gray") ,
                                                                        width: this.props.style ? this.props.style.tdBodyWidth : this.state.mainStyle.tdWidth,
                                                                        // width: '171px',
                                                                        // height: this.props.style ? this.props.style.tdBodyHeight : this.state.mainStyle.tdHeight,
                                                                        height: '60px',
                                                                        verticalAlign: 'top',
                                                                    }}
                                                                   
                                                                >
                                                                    <ul
                                                                        style={{
                                                                            listStyle: 'none',
                                                                            margin: 0,
                                                                            padding: 0
                                                                        }}
                                                                    >
    
                                                                        <li 
                                                                            style={{ 
                                                                                position: 'relative',
                                                                                margin: '0 auto',
                                                                                marginBottom: 10,
                                                                                width: '35px',
                                                                                height: '35px',
                                                                                borderRadius: '50%',
                                                                                backgroundColor: '#FFC727',
                                                                                cursor: 'pointer'
                                                                            }}
                                                                            onClick={
                                                                                () => {
                                                                                    this.onClickDate(this.M.specificDateToDateField(wd.data))
                                                                                }
                                                                            }
                                                                        >
    
                                                                            <p 
                                                                                style={{ 
                                                                                    
                                                                                    position: 'absolute',
                                                                                    margin: 0,
                                                                                    top: '50%',
                                                                                    left: '50%',
                                                                                    transform: 'translateY(-50%) translateX(-50%)',
                                                                                    textAlign: 'center', 
                                                                                    fontSize: '16px',
                                                                                    color: '#fff', 
                                                                                    fontWeight: 700 
                                                                                }}
                                                                            >
                                                                                {wd.data.getDate()} 
                                                                            </p>
    
                                                                        </li>

                                                                        {
                                                                            wd.data.getFullYear() === new Date().getFullYear()
                                                                            && wd.data.getMonth() === new Date().getMonth()
                                                                            && wd.data.getDate() === new Date().getDate() &&
                                                                            <li style={{
                                                                                position: 'relative',
                                                                                top: -6,
                                                                                margin: '1px auto',
                                                                                width: '4px',
                                                                                height: '4px',
                                                                                background: '#455A64',
                                                                                borderRadius: '50%'
                                                                            }}
                                                                            >
                                                                            </li>
                                                                        }
    
                                                                    </ul>
    
                                                                </td>
                                                            )

                                                        }
                                                        else if((wd.isAvailable[0] !== undefined) && (wd.isAvailable[0].disponibil === true) && 
                                                        (wd.data.getDate() >= new Date().getDate() || wd.data.getMonth() !== new Date().getMonth()
                                                        
                                                        ) && (!isDateOutOfTimeRange)
                                                        
                                                        ){

                                                            return (
                                                                <td key={`d-${idxw}-${idxd}`}
                                                                    style={{
                                                                        // backgroundColor: this.settingTDBackground(wd),
                                                                        // background: '#fff',
                                                                        
                                                                        // color: wd.isOutisideInitialInterval ? "#bf4a38" : (this.state.colors.tdBackGroundColor || "gray") ,
                                                                        width: this.props.style ? this.props.style.tdBodyWidth : this.state.mainStyle.tdWidth,
                                                                        // width: '171px',
                                                                        // height: this.props.style ? this.props.style.tdBodyHeight : this.state.mainStyle.tdHeight,
                                                                        height: '60px',
                                                                        verticalAlign: 'top',
                                                                    }}
                                                                   
                                                                >

                                                                    <ul
                                                                        style={{
                                                                            listStyle: 'none',
                                                                            margin: 0,
                                                                            padding: 0
                                                                        }}
                                                                    >
                                                                        
                                                                        <li 
                                                                            style={{ 
                                                                                position: 'relative',
                                                                                margin: '0 auto',
                                                                                marginBottom: 10,
                                                                                width: '35px',
                                                                                height: '35px',
                                                                                borderRadius: '50%',
                                                                                backgroundColor: '#FFF7E0',
                                                                                cursor: 'pointer'
                                                                            }}
                                                                            onClick={
                                                                                () => {
                                                                                    this.onClickDate(this.M.specificDateToDateField(wd.data))
                                                                                }
                                                                            }
                                                                        >
    
                                                                            <p 
                                                                                style={{ 
                                                                                    
                                                                                    position: 'absolute',
                                                                                    margin: 0,
                                                                                    top: '50%',
                                                                                    left: '50%',
                                                                                    transform: 'translateY(-50%) translateX(-50%)',
                                                                                    textAlign: 'center', 
                                                                                    fontSize: '16px', 
                                                                                    fontWeight: 700 
                                                                                }}
                                                                            >
                                                                                {wd.data.getDate()} 
                                                                            </p>
    
                                                                        </li>

                                                                        {
                                                                            wd.data.getFullYear() === new Date().getFullYear()
                                                                            && wd.data.getMonth() === new Date().getMonth()
                                                                            && wd.data.getDate() === new Date().getDate() &&
                                                                            <li style={{
                                                                                position: 'relative',
                                                                                top: -6,
                                                                                margin: '0 auto',
                                                                                width: '4px',
                                                                                height: '4px',
                                                                                background: '#455A64',
                                                                                borderRadius: '50%'
                                                                            }}
                                                                            >
                                                                            </li>
                                                                        }
        
                                                                    </ul>


    
                                                                </td>
                                                            )
                                                        }
                                                        else{

                                                            return (
                                                                <td key={`d-${idxw}-${idxd}`}
                                                                    style={{
                                                                        // backgroundColor: this.settingTDBackground(wd),
                                                                        // background: '#fff',
                                                                        
                                                                        // color: wd.isOutisideInitialInterval ? "#bf4a38" : (this.state.colors.tdBackGroundColor || "gray") ,
                                                                        width: this.props.style ? this.props.style.tdBodyWidth : this.state.mainStyle.tdWidth,
                                                                        // width: '171px',
                                                                        // height: this.props.style ? this.props.style.tdBodyHeight : this.state.mainStyle.tdHeight,
                                                                        height: '60px',
                                                                        verticalAlign: 'top',
                                                                    }}
                                                                   
                                                                >

                                                                    <ul
                                                                        style={{
                                                                            listStyle: 'none',
                                                                            margin: 0,
                                                                            padding: 0
                                                                        }}
                                                                    >
                                                                        
                                                                        <li 
                                                                            style={{ 
                                                                                position: 'relative',
                                                                                margin: '0 auto',
                                                                                marginBottom: 10,
                                                                                width: '35px',
                                                                                height: '35px',
                                                                                borderRadius: '50%',
                                                                                backgroundColor: '#F4F4F4',
                                                                                cursor: 'no-drop'
                                                                            }}
                                                                        >
    
                                                                            <p 
                                                                                style={{ 
                                                                                    
                                                                                    position: 'absolute',
                                                                                    margin: 0,
                                                                                    top: '50%',
                                                                                    left: '50%',
                                                                                    transform: 'translateY(-50%) translateX(-50%)',
                                                                                    textAlign: 'center', 
                                                                                    fontSize: '16px', 
                                                                                    fontWeight: 700 
                                                                                }}
                                                                            >
                                                                                {wd.data.getDate()} 
                                                                            </p>
    
                                                                        </li>

                                                                        {
                                                                            wd.data.getFullYear() === new Date().getFullYear()
                                                                            && wd.data.getMonth() === new Date().getMonth()
                                                                            && wd.data.getDate() === new Date().getDate() &&
                                                                            <li style={{
                                                                                position: 'relative',
                                                                                top: -6,
                                                                                margin: '0 auto',
                                                                                width: '4px',
                                                                                height: '4px',
                                                                                background: '#455A64',
                                                                                borderRadius: '50%'
                                                                            }}
                                                                            >
                                                                            </li>
                                                                        }
    
                                                                    </ul>
    
                                                                </td>
                                                            )
                                                            
                                                        }
                                                        
                                                    }, this)
                                                }
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>

                    }

                </div>

            </div>
        );
    }
}