import React from 'react';
import Declarations from '../providers/Declarations';
import HourInterval from './HourInterval';
import LocationDesc from './LocationDesc';

export default class DayCalendarElem extends React.Component{

    constructor(props){

        super(props);
    }

    render(){

        return(

            <ul 
                style={{
                    display: 'flex',
                    justifyContent: this.props.justifyContent || 'center',
                    // alignItems: 'center',
                    flexDirection: 'column',
                    width: this.props.width || '100%',
                    position: 'absolute',
                    height: this.props.height, // * duration from current appointment
                    // marginTop: 0,
                    top: this.props.top,
                    left: this.props.left || 0,
                    listStyle: 'none',
                    margin: 0,
                    padding: 0,
                    paddingLeft: '10px',
                    // background: ptdEl.backgroundColor.replace('0.1', '0.5'),
                    background: this.props.elem.backgroundColor,
                    borderLeft: '3px solid ' + this.props.elem.color,
                    overflow: 'hidden',
                    zIndex: this.props.zIndex,
                    color: '#000',
                    cursor: 'pointer',
                    textAlign: 'left'
                }}

                onClick={
                    () => {
                        this.props.onShowClientClick(this.props.weeklyAppointsList, this.props.elem.token, this.props.elem['data_cal'])
                    }
                }
            >
                <p
                    style={{
                        margin: 0,
                        fontSize: '14px',
                        color: this.props.elem.color,
                        filter: 'brightness(70%)'
                    }}
                >
                    {this.props.elem['clie_nume'] + ' ' + this.props.elem['clie_prenume']}
                </p>

                <p
                    style={{
                        position: 'relative',
                        margin: 0,
                        fontSize: '10px',
                        // color: ptdEl.color
                    }}
                >
                    {this.props.elem['service_name']}
                </p>  

                <HourInterval 
                    ora = {this.props.elem['ora_cal']}
                    durata = {this.props.elem['duration']}
                    justifyContent = 'none'
                    marginTop = '4px'
                    iconWidth = '22px'
                    iconSize = '16px'
                    txSize = '12px'
                />

                <ul 
                    className="row"
                    style={{
                        listStyle: 'none',
                        margin: 0,
                        padding: 0,
                    }}
                >

                    <li className="col-m-6 col-l-6">

                        <LocationDesc 
                            location = {(this.props.elem['location'] || '').trim()}
                            locationDesc = { 
                                new Declarations().listaLocatiiIntalnire.filter(llEl => llEl['id'] === (this.props.elem['location'] || '').trim())[0]['descClient']
                            }
                            distance = '22px'
                            marginTop = '4px'
                            zoomW = '18px' zoomH = '9px'
                            officeW = '18px' officeH = '18px'       
                            googleW = '18px' googleH = '18px' 
                            skypeSize = '16px'  
                            leftOfffice = '-1px'               
                            txSize = '12px'
                            // txWeight = '500'
                        />

                    </li>

                    <li className="col-m-6 col-l-6" style={{textAlign: 'right'}}>

                        {
                            this.props.elem['location'] !== 'office' && (

                                <p
                                    style={{
                                        position: 'relative',
                                        right: 20,
                                        margin: 0,
                                        marginLeft: 10,
                                        fontSize: '12px',
                                        color: '#2577C2',
                                        textAlign: 'right',
                                        zIndex: 999
                                        // color: ptdEl.color
                                    }}
                                    onClick = {() => {

                                        if((this.props.elem['host_meeting_link'] || '').length > 0){

                                            window.open(this.props.elem['host_meeting_link'])
                                        }
                                    }}
                                >
                                    <u>Detalii</u>
                                </p>
                            )
                        }
                        
                    </li>


                </ul>

               {
                    this.props.member.length > 0 ? (

                        <ul
                            className="row"
                            style={{
                                position: 'absolute',
                                right: 20,
                                display: 'flex',
                                marginTop: 0,
                                listStyle: 'none',
                                margin: 0,
                                padding: 0,
                            }}
                            // style={{
                            //     display: 'flex',
                            //     width: '100%',
                            //     marginTop: 8   
                            // }}
                        >
                            <li
                                // className="col-l-1 col-m-1 col-s-1"
                            >
        
                                <li
                                    style={{
                                        position: 'relative',
                                        left: -5,
                                        width: '30.5px',
                                        height: '30.5px',
                                        borderRadius: '100%',
                                        background: 'lightblue',
                                        overflow: 'hidden'
                                    }}
                                >
                                    <img 
                                        // src={CompanyAvatar} 
                                        alt="Avatar client" 
                                        style={{
                                            width: '100%',
                                            height: '100%'
                                        }}
                                    />
        
                                </li>
        
                            </li>
        
                            <li
                                // style={{
                                //     width: '90%'
                                // }}
                                // className="col-l-11 col-m-11 col-s-11"
                            >
        
                                <p
                                    style={{
                                        margin: 0,
                                        fontSize: '16px',
                                        color: '#263238',
                                        textAlign: 'left'
                                    }}
                                >
                                    {this.props.member[0].nume}
                                </p>
        
                                <p
                                    style={{
                                        margin: 0,
                                        fontSize: '9px',
                                        color: '#777777',
                                        textAlign: 'left'
                                    }}
                                >
                                    {this.props.member[0].functie}
                                </p>
        
                            </li>
        
                        </ul>
                    ) : null
               }


                

            </ul>
        )
    }
}