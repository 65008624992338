import React from 'react';

import { ReactComponent as ZoomIcon } from '../assets/zoom_icon.svg';
import { ReactComponent as LocIcon } from '../assets/pin_drop.svg';
import { ReactComponent  as GMeetIcon} from '../assets/Google_Meet-Logo.wine.svg';

import Masks from '../providers/Masks';
import Utils from '../providers/Utils';
import Ws from '../providers/Ws';
import { FaSkype } from 'react-icons/fa';
import IconText from './IconText';

export default class LocationDesc extends React.Component{

    constructor(props){

        super(props);
    }

    WS = new Ws();
    M = new Masks();
    UT = new Utils();

    componentDidMount(){


    }

    render(){

        let locationIcon = null;

        if(this.props.location === 'zoom'){

            locationIcon = ( 
                <ZoomIcon 
                    className={this.props.iconCls || 'loc-icon-light'}
                    style={{
                        position: 'relative',
                        width: this.props.zoomW || '22px',
                        height: this.props.zoomH || '11px'
                    }}
            /> )
        }
        else if(this.props.location === 'skype'){

            locationIcon = (
                <FaSkype 
                    className={this.props.iconCls || 'loc-icon-light'}
                    style={{
                        fontSize: this.props.skypeSize || '20px'
                    }}
                />
            )
        }
        else if(this.props.location === 'office'){

            locationIcon = (

                <LocIcon 
                    className={this.props.iconCls || 'loc-icon-light'}
                    style={{
                        position: 'relative',
                        left: this.props.leftOfffice || '-2px',
                        width: this.props.officeW || '18px',
                        height: this.props.officeH || '18px',
                        
                    }}
                />
            )
        }
        else if(this.props.location === 'gmeet'){

            locationIcon = (

                <GMeetIcon 
                    className={this.props.iconCls || 'loc-icon-light'}
                    style={{
                        position: 'relative',
                        top: '1px',
                        width: this.props.googleW || '24px',
                        height: this.props.googleH || '24px'
                    }}
                />
            )
        }

        return(

            <IconText 
                containerTop = {this.props.marginTop}
                top = {this.props.top || '0px'}
                distance = {this.props.distance}
                icon = {locationIcon}
                tx = {this.props.locationDesc}
                txSize = {this.props.txSize}
                txWeight = {this.props.txWeight}
                txCol = {this.props.txCol}
                side = {this.props.side}
            />

            // <div
            //     style={{
            //         display: 'flex',
            //         alignItems: 'center',
            //         marginTop: this.props.marginTop || '0px'
            //     }}
            // >

            //     <div
            //         style={{
            //             display: 'flex',
            //             justifyContent: 'center'
            //         }}
            //     >     
            //         {
            //             this.props.location === 'zoom' && (
                            
            //                 <ZoomIcon 
            //                     className={this.props.iconCls || 'loc-icon-light'}
            //                     style={{
            //                         position: 'relative',
            //                         width: this.props.zoomW || '22px',
            //                         height: this.props.zoomH || '11px'
            //                     }}
            //                 />
            //             )
            //         }

            //         {
            //             this.props.location === 'skype' && (
            //                 <FaSkype 
            //                     className={this.props.iconCls || 'loc-icon-light'}
            //                     style={{
            //                         fontSize: this.props.skypeSize || '20px'
            //                     }}
            //                 />
            //             )
            //         }

            //         {
            //             this.props.location === 'office' && (
            //                 <LocIcon 
            //                     className={this.props.iconCls || 'loc-icon-light'}
            //                     style={{
            //                         position: 'relative',
            //                         left: this.props.leftOfffice || '-2px',
            //                         width: this.props.officeW || '18px',
            //                         height: this.props.officeH || '18px',
                                    
            //                     }}
            //                 />
            //             )
            //         }

            //         {
            //             this.props.location === 'gmeet' && (
            //                 <GMeetIcon 
            //                     className={this.props.iconCls || 'loc-icon-light'}
            //                     style={{
            //                         position: 'relative',
            //                         top: '1px',
            //                         width: this.props.googleW || '24px',
            //                         height: this.props.googleH || '24px'
            //                     }}
            //                 />
            //             )
            //         }
            //     </div>

            //     <div>
            //         <p 
            //             style={{
            //                 // display: 'inline-block', 
            //                 position: 'relative', 
            //                 // top: -2,
            //                 left: this.props.leftTx || '10px',
            //                 margin: 0,
            //                 color: '#455A64',
            //                 fontWeight: '400',
            //                 fontSize: this.props.txSize || '14px',
            //             }}
            //         >
            //             {this.props.locationDesc}
            //         </p>

            //     </div>
                
            // </div>     

        )
    }
}