import React from 'react';
import IconText from '../../../commons/IconText';
import PercentageStat from './PercentageStat';
import StatisticsLogic from './StatisticsLogic';

export default class Conversion extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            trendingRate: new StatisticsLogic().setTrendingIcon(this.props.trendingRate),
        };
    }

    render(){

        return(

            <div style={this.props.style}>

                <p className='statistics-tx'>Vizite / Clienti</p>

                <p className='statistics-tx stat'>{this.props.conversionRate}%</p>

                <IconText 
                    icon = {this.state.trendingRate['icon']}
                    containerTop = '10px'
                    containerW = 'auto'
                    txW = '80px'
                    tx = {this.state.trendingRate['tx']}
                    txSize = '16px'
                    txWeight = '500'
                    txCol = {this.state.trendingRate['txColor']}
                    side = 'center'
                    direction = 'row-reverse'
                />

                <p 
                    className='statistics-tx stat'
                    style={{
                        marginTop: '20px',
                        fontSize: '14px'
                    }}
                >
                    Rate de Conversie Per Serviciu
                </p>

                <PercentageStat 
                    statColor = '#31A81D'
                    statTitle = 'Consultatie Psihologica Video'
                    statRate = {18.61}
                />

                <PercentageStat 
                    statColor = '#44A5FF'
                    statTitle = 'Consultatie de Cuplu'
                    statRate = {12.31}
                />

                <PercentageStat 
                    statColor = '#FFC727'
                    statTitle = 'Consultatie Psihologica Face to Face'
                    statRate = {8.15}
                />

            </div>
        )
    }
}