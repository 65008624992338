import React from 'react';

import { MdAlarm } from 'react-icons/md';
import { RiArrowRightSLine } from 'react-icons/ri';
import { ImLocation2 } from 'react-icons/im';
import { IoVideocam } from 'react-icons/io5';
import { FaEdit, FaSkype } from 'react-icons/fa';
import { HiCurrencyDollar } from 'react-icons/hi';
import { RiCalendar2Fill } from 'react-icons/ri';
import { ReactComponent  as GMeetIcon} from '../../assets/Google_Meet-Logo.wine.svg';
import {ReactComponent as ZoomIcon } from '../../assets/Zoom Icon.svg';
import { ReactComponent as LocIcon } from '../../assets/pin_drop.svg';

import Masks from '../../providers/Masks';

export default class MClientServiceInfo extends React.Component{

    constructor(props){

        super(props)
    }

    M = new Masks()

    render(){

        return(

            <div>
                 
                <h3
                    style={{
                        margin: 0,
                        fontSize: 18
                    }}
                >

                    {this.props.name}

                </h3>

                {
                    this.props.date &&

                    <h3 
                        
                        style={{

                            textAlign: 'left',
                            margin: 0,
                            marginTop: 20,
                            color: '#31A81D'
                        }}
                    >
                    
                        <p 
                            style={{
                                    display: 'inline-block', 
                                    position: 'relative', 
                                    top: 4, 
                                    left: 0, 
                                    margin: 0,
                                    fontSize: '22px'
                                }}
                        >
                            <RiCalendar2Fill /> &nbsp;

                        </p>

                        <p 
                            style={{
                                display: 'inline-block', 
                                position: 'relative', 
                                margin: 0,
                                fontWeight: '500',
                                fontSize: '16px'
                            }}
                        >
                            {
                                this.M.dayOfWeek(this.M.sqlDateToJSDate(this.props.date).getDay()) + ' ' +
                                this.M.sqlDateToJSDate(this.props.date).getDate() + ' ' +
                                this.M.monthName(this.M.sqlDateToJSDate(this.props.date).getMonth()).substr(0,3) + ', Ora ' +
                                this.props.hour.substr(0,5)
                            }

                        </p>

                        {
                            this.props.editDate && (

                                <FaEdit 
                                    style={{
                                        display: 'inline-block',
                                        position: 'relative',
                                        top: 3,
                                        left: 14,
                                        fontSize: '20px',
                                        color: '#60D84C',
                                        cursor: 'pointer'
                                    }}

                                    onClick = { () => {window.location.href = '/#/calendar/edit/'+this.props.tranToken}}
                                />
                            )
                        
                        }
                        
                    </h3>
                 }

                <h3 
                    className="client-appoint-title"
                    style={{

                        textAlign: 'left',
                        margin: 0,
                        marginTop: 10
                    }}
                >
                    
                    <p 
                        style={{
                                display: 'inline-block', 
                                position: 'relative', 
                                top: 4, 
                                left: 0, 
                                margin: 0,
                                color: '#777777',
                                fontSize: '22px'
                            }}
                    >
                        <MdAlarm /> 

                    </p>

                    <p 
                        style={{
                            display: 'inline-block', 
                            position: 'relative', 
                            left: 10,
                            margin: 0,
                            color: '#455A64',
                            fontWeight: '400',
                            fontSize: '16px'
                        }}
                    >
                        {this.props.duration} Minute
                    </p>
                    
                </h3>

                <h3 
                    className="client-appoint-title"
                    style={{

                        textAlign: 'left',
                        margin: 0,
                        marginTop: 10
                    }}
                >
                    
                    <p 
                        style={{
                                display: 'inline-block', 
                                position: 'relative', 
                                top: 4, 
                                left: 0, 
                                margin: 0,
                                color: '#777777',
                                fontSize: '22px'
                            }}
                    >
                        {
                            this.props.location.id === 'zoom' && (

                                <ZoomIcon 
                                    className="loc-icon-darker"
                                    style={{
                                        position: 'relative',
                                        top: '3px'
                                    }}
                                /> 
                            )
                        }

                        {
                            this.props.location.id === 'skype' && (

                                <FaSkype /> 
                            )
                        }

                        {
                            this.props.location.id === 'office' && (

                                <LocIcon 
                                    className="loc-icon-dark"
                                    style={{
                                        position: 'relative',
                                        top: '2px',
                                        left: '-2px'
                                    }}
                                />
                            )
                        } 

                        {
                            this.props.location.id === 'gmeet' && (

                                <GMeetIcon 
                                    className="gmeet-services"
                                        style={{
                                            position: 'relative',
                                            top: 3,
                                            width: '24px',
                                            height: '24px'
                                        }}
                                />
                            )
                        }

                    </p>
         

                    <p 
                        style={{
                            display: 'inline-block', 
                            position: 'relative', 
                            left: 12,
                            margin: 0,
                            color: '#455A64',
                            fontWeight: '400',
                            fontSize: '16px'
                        }}
                    >
                        {this.props.location.shortTx}
                    </p>     
                    
                </h3>

                {

                    +this.props.price > 0 && (

                        <h3 
                            className="client-appoint-title"
                            style={{

                                textAlign: 'left',
                                margin: 0,
                                marginTop: 10
                            }}
                        >
                            
                            <p 
                                style={{
                                        display: 'inline-block', 
                                        position: 'relative', 
                                        top: 4, 
                                        left: -2, 
                                        margin: 0,
                                        color: '#777777',
                                        fontSize: '26px'
                                    }}
                            >
                                <HiCurrencyDollar /> 

                            </p>

                            <p 
                                style={{
                                    display: 'inline-block', 
                                    position: 'relative', 
                                    top: -4,
                                    left: 8,
                                    margin: 0,
                                    color: '#455A64',
                                    fontWeight: '400',
                                    fontSize: '16px'
                                }}
                            >
                                {this.props.price + ' Lei'} 
                            </p>
                            
                        </h3>
                    )
                }

                <p
                    style={{
                        margin: 0,
                        marginTop: 20,
                        textAlign: 'left',
                        color: '#777777',
                        fontSize: 16,
                        whiteSpace: 'pre-wrap'
                    }}
                >
                        {
                            this.props.description
                        }
                </p>

                {
                    this.props.member.length > 0 ? (

                        <div
                            className="row"
                            style={{
                                marginTop: 8,
                                marginLeft: 6
                            }}
                            // style={{
                            //     display: 'flex',
                            //     width: '100%',
                            //     marginTop: 8   
                            // }}
                        >
                            <div
                                className="col-l-1 col-m-1 col-s-2"
                            >
        
                                <div
                                    style={{
                                        position: 'relative',
                                        left: -5,
                                        width: '32.5px',
                                        height: '32.5px',
                                        borderRadius: '50%',
                                        background: 'lightblue',
                                        overflow: 'hidden'
                                    }}
                                >
                                    <img 
                                        // src={CompanyAvatar} 
                                        alt="Avatar client" 
                                        style={{
                                            width: '100%',
                                            height: '100%'
                                        }}
                                    />
        
                                </div>
        
                            </div>
        
                            <div
                                // style={{
                                //     width: '90%'
                                // }}
                                className="col-l-11 col-m-11 col-s-10"
                            >
        
                                <p
                                    style={{
                                        margin: 0,
                                        fontSize: '16px',
                                        color: '#263238',
                                        textAlign: 'left'
                                    }}
                                >
                                    {this.props.member[0].nume}
                                </p>
        
                                <p
                                    style={{
                                        margin: 0,
                                        fontSize: '9px',
                                        color: '#777777',
                                        textAlign: 'left'
                                    }}
                                >
                                    {this.props.member[0].functie}
                                </p>
        
                            </div>
        
                        </div>
                    ): null
                }

        
                <div
                    style={{
                        marginTop: 30,
                        marginBottom: 30,
                        width: '100%',
                        height: '1px',
                        background: '#EBEBEB'
                    }}
                >
                </div> 
                
            </div>
        )
    }
}