import React from 'react';
import { BrowserView, MobileView } from 'react-device-detect';

import { MdClear } from 'react-icons/md';
import AlertImage from '../../assets/Feeling sorry-cuate 1.png';
import ButtonForm from '../../commons/ButtonForm';

export default class PopWarningDeleteSchedule extends React.Component{

    constructor(props){

        super(props);
    }

    onClosePopup = (ev) => {

        if( ev.target.id === 'modal-popup' ||  ev.target.id === 'close-modal-btn'){

            this.props.onClosePopup("showWarningPopup");
        }
  
    }
    
    render(){

        return(
            <>

            <BrowserView>
            <div
                id="modal-popup"
                className="calendar-modal-container"
                onClick={(ev) => {this.onClosePopup(ev)}}
            >

                <div 
                    className="calendar-popup-card"
                    style={{
                        padding: '30px',
                        textAlign: 'left'
                    }}
                >

                    <MdClear
                        id="close-modal-btn"
                        style={{
                            position: 'absolute',
                            top: 10,
                            right: 10,
                            fontSize: '24px',
                            color: '#323232',
                            cursor: 'pointer'
                        }}

                        onClick={(ev) => {this.onClosePopup(ev)}}
                    />

                    <h2
                        style={{
                            margin: 0,
                            color: '#74838B',
                            textAlign: 'center'
                        }}
                    >
                        Alerta stergere program!
                    </h2>

                    <img 
                        src={AlertImage}
                        alt="Alert Image"
                        style={{
                            display: 'block',
                            margin: '0 auto'
                        }}
                    />

                    <p
                        style={{
                            margin: 0,
                            fontSize: '16px',
                            fontWeight: '400',
                            color: '#455A64'
                        }}
                    >
                        Nu poti sterge programul deoarece este folosit de {this.props.servicesList.length === 1 ? 'urmatorul serviciu' : 'urmatoarele servicii'}: 
                    </p>

                    <br />

                    {
                        this.props.servicesList && 
                        this.props.servicesList.map( (sEl, idx) => {

                            return (

                                <p
                                    key={`serviceName-${idx}`}
                                    style={{
                                        margin: 0,
                                        fontSize: '16px',
                                        fontWeight: '400',
                                        color: '#455A64',
                                    }}
                                >
                                    {idx+1}. {sEl}
                                </p>

                            )
                        })
                    }

                    <br />
                    <p
                        style={{
                            margin: 0,
                            fontSize: '16px',
                            fontWeight: '400',
                            color: '#455A64'
                        }}
                    >
                        Pentru a putea sterge acest program trebuie sa selectezi un program nou
                        pentru {this.props.servicesList.length === 1 ? 'acest serviciu' : 'aceste servicii'}. 
                    </p>

                    <br />

                    <div style={{textAlign: 'center'}}>

                        <ButtonForm
                            id="close-modal-btn"
                            tx='OK'
                            type='button'
                            onClick={(ev) => {this.onClosePopup(ev)}}
                            style={{
                                width: '150px',
                                background: '#FFC727'
                            }}
                        />
                        
                    </div>

                </div>

                

            </div>

            </BrowserView>

            <MobileView>

            <div 
                id="modal-popup" 
                className="calendar-modal-container"
                onClick={(ev) => {this.onClosePopup(ev)}}
            >

                <div 
                    className="calendar-popup-card"
                    style={{
                        width: '100%',
                        padding: '24px',
                        textAlign: 'left'
                    }}
                >

                    <MdClear 
                        id="close-modal-btn"
                        style={{
                            position: 'absolute',
                            top: 10,
                            right: 10,
                            fontSize: '24px',
                            color: '#323232',
                            cursor: 'pointer'
                        }}

                        onClick={(ev) => {this.onClosePopup(ev)}}
                    />
                    

                    <h2
                        style={{
                            margin: 0,
                            color: '#74838B',
                            textAlign: 'center'
                        }}
                    >
                        Alerta stergere program!
                    </h2>

                    <br />

                    <p
                        style={{
                            margin: 0,
                            fontSize: '16px',
                            fontWeight: '400',
                            color: '#455A64'
                        }}
                    >
                        Nu poti sterge programul deoarece este folosit de {this.props.servicesList.length === 1 ? 'urmatorul serviciu' : 'urmatoarele servicii'}: 
                    </p>

                    <br />

                    {
                        this.props.servicesList && 
                        this.props.servicesList.map( (sEl, idx) => {

                            return (

                                <p
                                    key={`serviceName-${idx}`}
                                    style={{
                                        margin: 0,
                                        fontSize: '16px',
                                        fontWeight: '400',
                                        color: '#455A64',
                                    }}
                                >
                                    {idx+1}. {sEl}
                                </p>

                            )
                        })
                    }

                    <br />
                    <p
                        style={{
                            margin: 0,
                            fontSize: '16px',
                            fontWeight: '400',
                            color: '#455A64'
                        }}
                    >
                        Pentru a putea sterge acest program trebuie sa selectezi un program nou
                        pentru {this.props.servicesList.length === 1 ? 'acest serviciu' : 'aceste servicii'}. 
                    </p>

                    <br />

                    <div style={{textAlign: 'center'}}>

                        <ButtonForm 
                            id="close-modal-btn"
                            tx='OK'
                            type='button'
                            onClick={(ev) => {this.onClosePopup(ev)}}
                            style={{
                                width: '150px',
                                background: '#FFC727'
                            }}
                        />
                        
                    </div>

                </div>

                </div>


            </MobileView>

            </>
        )
    }
}