import React from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

export default class DateChanger extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            showBackArrow: true, 
            showNextArrow: true
        }
    }

    localData = {

        showNextArrow: this.props.showNextArrow === undefined || this.props.showNextArrow === null 
                        ? true : this.props.showNextArrow
    }

    render(){

        return(

            <div>

                {
                    this.props.showBackArrow && this.state.showBackArrow && (

                        <IoIosArrowBack 

                            style={{
                                display: 'inline-block',
                                position: 'relative',
                                margin: 0,
                                top: this.props.arrowTop || 5,
                                fontSize: this.props.arrowSize || '22px',
                                color: this.props.arrowColor || '#A6A6A6',
                                cursor: 'pointer'
                            }}

                            onClick={
                                () => {

                                    // setTimeout(() => this.props.nextMonth(true), 2000)
                                    this.setState({showBackArrow: false})
                                    this.props.nextMonth(true)
                                    setTimeout(() => this.setState({showBackArrow: true}), 1000)
                                }
                            }
                        
                        />
                    )
                }

                {
                    this.props.showBackArrow && !this.state.showBackArrow && (

                        <IoIosArrowBack 

                            style={{
                                display: 'inline-block',
                                position: 'relative',
                                margin: 0,
                                top: this.props.arrowTop || 5,
                                fontSize: this.props.arrowSize || '22px',
                                color: this.props.arrowColor || '#A6A6A6',
                                cursor: 'pointer'
                            }}

                            
                        
                        />
                    )
                }

                <h3
                    style={{
                        display: 'inline-block',
                        position: 'relative',
                        top: this.props.dateTop || 0,
                        margin: 0,
                        fontSize: this.props.dateFont || '18px',
                        padding: '0px 10px 0 10px',
                        color: this.props.dateColor || '#000'
                    }}
                
                >
                {this.props.date}
                </h3>

                {
                    this.localData.showNextArrow && (

                        <IoIosArrowForward 
                            style={{
                                display: 'inline-block',
                                position: 'relative',
                                margin: 0,
                                top: this.props.arrowTop || 5,
                                fontSize: this.props.arrowSize || '22px',
                                color: this.props.arrowColor || '#A6A6A6',
                                cursor: 'pointer'
                            }}

                            onClick={
                                () => {

                                    // setTimeout(() => this.nextMonth(false), 1000)
                                    this.setState({showNextArrow: false})
                                    this.props.nextMonth(false)
                                    setTimeout(() => this.setState({showNextArrow: true}), 1000)
                                }
                            }
                        
                        />
                    )
                }

                {/* {
                    !this.props.showNextArrow && (

                        <IoIosArrowForward 
                            style={{
                                display: 'inline-block',
                                position: 'relative',
                                margin: 0,
                                top: this.props.arrowTop || 5,
                                fontSize: this.props.arrowSize || '22px',
                                color: this.props.arrowColor || '#A6A6A6',
                                cursor: 'pointer'
                            }}

                            // onClick={
                            //     () => {

                            //         // setTimeout(() => this.nextMonth(false), 1000)
                            //         this.setState({showNextArrow: false})
                            //         this.props.nextMonth(false)
                            //         setTimeout(() => this.setState({showNextArrow: true}), 1000)
                            //     }
                            // }
                        
                        />
                    )
                } */}

            </div>
        )
    }
}