import React from 'react';

import { MobileView } from 'react-device-detect';

import AppBar from './AppBar';

import { RiArrowUpSFill } from 'react-icons/ri';
import MenuCarret from '../assets/menu_carret.png';
import AccountCircleIcon from '../assets/account_circle.svg';
import AccountSupervisorIcon from '../assets/supervisor_account.svg';
import HistoryIcon from '../assets/history.svg';
import AnalyticsIcon from '../assets/analytics.svg';
import FurnitureImg from '../assets/Dulap Perete Gol.png';
import BusinessCenterIcon from '../assets/business_center.svg';
import SecurityIcon from '../assets/security.svg';
import IntegrationsIcon from '../assets/settings_input_component.svg';
import HelpIcon from '../assets/support.svg';
import LogoutIcon from '../assets/power_settings_new.svg';
import { Link } from 'react-router-dom';
import Ws from '../providers/Ws';
import LocalStorage from '../providers/LocalStorage';

export default class MUserMenu extends React.Component{

    constructor(props){

        super(props);
        this.state = {

            tipAbonament: null
        }
    }

    WS = new Ws();

    componentDidMount(){

        this.onGetTipAbonament();
    }

    onLogoutUser = () => {

        localStorage.clear();
        window.location.href = '/';    
    }

    onGetTipAbonament = async () => {

        let accountToken = new LocalStorage().GetAccountToken();

        let q = `select * from accounts where token = '${accountToken}' `;
        let res = await this.WS.sqlCommand(`select`, q);

        if(res.success){

            this.setState({

                tipAbonament: res.content[0].abonament
            });
        }
    }


    render(){

        return(

            <MobileView>

                <AppBar 
                    isMenuShown = {true}
                    isNotificationsShown = {false}
                    isDashboardPage={true}
                />

                <div
                    style={{
                        padding: '0px 20px 0px 20px'
                    }}
                >
            
                    <div 
                        style={{
                            position: 'relative',
                            width: '100%',
                            background: '#fff',
                            borderRadius: '10px 0px 10px 10px',
                            boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)',
                            padding: '20px'
                        }}
                    >
                        <img
                            src={MenuCarret}
                            style={{
                                position: 'absolute',
                                top: -11,
                                right: 0,
                                fontSize: '40px',
                                color: '#fff',
                            }}
                        />
                        
                        <Link
                            to='/ca/dashboard/cab/profil'
                            stlye={{
                                textDecoration: 'none'
                            }}
                        >
                        <div
                            style={{
                                margin: 0,
                                textAlign: 'left'
                            }}
                        >

                            <p
                                style={{
                                    position: 'relative',
                                    display: 'inline-block',
                                    margin: 0,
                                    top: 0,
                                }}
                            >
                                <img 
                                    src={AccountCircleIcon}
                                    
                                />
                            </p>

                            <p
                                style={{
                                    position: 'relative',
                                    display: 'inline-block',
                                    top: -6,
                                    margin: 0,
                                    fontSize: '14px',
                                    fontWeight: '500',
                                    color: '#263238'
                                }}
                            >
                                &nbsp; Profil
                            </p>

                        </div>
                        </Link>

                        <Link
                            to='/ca/dashboard/cab/programe'
                            stlye={{
                                textDecoration: 'none'
                            }}
                        >
                        <div
                            style={{
                                marginTop: 12,
                                textAlign: 'left'
                            }}
                        >

                            <p
                                style={{
                                    position: 'relative',
                                    display: 'inline-block',
                                    margin: 0,
                                    top: 0,
                                }}
                            >
                                <img 
                                    src={AccountCircleIcon}
                                    style={{
                                        opacity: 0
                                    }}
                                />
                            </p>

                            <p
                                style={{
                                    position: 'relative',
                                    display: 'inline-block',
                                    top: -6,
                                    margin: 0,
                                    fontSize: '14px',
                                    fontWeight: '400',
                                    color: '#263238'
                                }}
                            >
                                &nbsp; Programe Personalizate
                            </p>

                        </div>
                        </Link>
                        
                        {
                            this.state.tipAbonament === 'anteprenor' ||
                            this.state.tipAbonament === 'premium' ? (

                                <Link
                                    to='/ca/dashboard/cab/echipa'
                                    stlye={{
                                        textDecoration: 'none'
                                    }}
                                >
                                <div
                                    style={{
                                        marginTop: 12,
                                        textAlign: 'left'
                                    }}
                                >

                                    <p
                                        style={{
                                            position: 'relative',
                                            display: 'inline-block',
                                            margin: 0,
                                            top: 0,
                                        }}
                                    >
                                        <img 
                                            src={AccountSupervisorIcon}

                                        />
                                    </p>

                                    <p
                                        style={{
                                            position: 'relative',
                                            display: 'inline-block',
                                            top: -6,
                                            margin: 0,
                                            fontSize: '14px',
                                            fontWeight: '500',
                                            color: '#263238'
                                        }}
                                    >
                                        &nbsp; Echipa
                                    </p>

                                </div>
                                </Link>
                                
                            ) : null
                        }

                        <Link
                            to='/ca/dashboard/cab/securitate'
                            stlye={{
                                textDecoration: 'none'
                            }}
                        >
                        <div
                            style={{
                                marginTop: 12,
                                textAlign: 'left'
                            }}
                        >

                            <p
                                style={{
                                    position: 'relative',
                                    display: 'inline-block',
                                    margin: 0,
                                    top: 0,
                                }}
                            >
                                <img 
                                    src={SecurityIcon}

                                />
                            </p>

                            <p
                                style={{
                                    position: 'relative',
                                    display: 'inline-block',
                                    top: -6,
                                    margin: 0,
                                    fontSize: '14px',
                                    fontWeight: '500',
                                    color: '#263238'
                                }}
                            >
                                &nbsp; Securitate si Notificari
                            </p>

                        </div>
                        </Link>
                        
                        <br />

                        <div 
                            style={{
                                width: '100%',
                                height: '1px',
                                background: '#EBEBEB'
                            }}
                        ></div>

                        <br />

                        <Link
                            to='/ca/dashboard/cab/abonamente'
                            stlye={{
                                textDecoration: 'none'
                            }}
                        >
                        <div
                            style={{
                                textAlign: 'left'
                            }}
                        >

                            <p
                                style={{
                                    position: 'relative',
                                    display: 'inline-block',
                                    margin: 0,
                                    top: 0,
                                }}
                            >
                                <img 
                                    src={BusinessCenterIcon}

                                />
                            </p>

                            <p
                                style={{
                                    position: 'relative',
                                    display: 'inline-block',
                                    top: -6,
                                    margin: 0,
                                    fontSize: '14px',
                                    fontWeight: '500',
                                    color: '#263238'
                                }}
                            >
                                &nbsp; Abonament
                            </p>

                        </div>
                        </Link>
                        
                        <Link
                            to='/ca/dashboard/cab/istoric-plati'
                            stlye={{
                                textDecoration: 'none'
                            }}
                        >
                        <div
                            style={{
                                marginTop: 12,
                                textAlign: 'left'
                            }}
                        >

                            <p
                                style={{
                                    position: 'relative',
                                    display: 'inline-block',
                                    margin: 0,
                                    top: 0,
                                }}
                            >
                                <img 
                                    src={SecurityIcon}
                                    style={{
                                        opacity: 0
                                    }}
                                />
                            </p>

                            <p
                                style={{
                                    position: 'relative',
                                    display: 'inline-block',
                                    top: -6,
                                    margin: 0,
                                    fontSize: '14px',
                                    fontWeight: '400',
                                    color: '#263238'
                                }}
                            >
                                &nbsp; Istoric plati
                            </p>

                        </div>
                        </Link>

                        <br />

                        <div 
                            style={{
                                width: '100%',
                                height: '1px',
                                background: '#EBEBEB'
                            }}
                        ></div>

                        <br />
                        
                        <Link
                            to='/ca/dashboard/cab/istoric-programari'
                            stlye={{
                                textDecoration: 'none'
                            }}
                        >
                        <div
                            style={{
                                textAlign: 'left'
                            }}
                        >

                            <p
                                style={{
                                    position: 'relative',
                                    display: 'inline-block',
                                    margin: 0,
                                    top: 0,
                                }}
                            >
                                <img 
                                    src={HistoryIcon}

                                />
                            </p>

                            <p
                                style={{
                                    position: 'relative',
                                    display: 'inline-block',
                                    top: -7,
                                    margin: 0,
                                    fontSize: '14px',
                                    fontWeight: '500',
                                    color: '#263238'
                                }}
                            >
                                &nbsp; Istoric Programari
                            </p>

                        </div>
                        </Link>

                        <div
                            style={{
                                marginTop: 12,
                                textAlign: 'left'
                            }}
                        >

                            <p
                                style={{
                                    position: 'relative',
                                    display: 'inline-block',
                                    margin: 0,
                                    top: 0,
                                }}
                            >
                                <img 
                                    src={AnalyticsIcon}

                                />
                            </p>

                            <p
                                style={{
                                    position: 'relative',
                                    display: 'inline-block',
                                    top: -7,
                                    margin: 0,
                                    fontSize: '14px',
                                    fontWeight: '500',
                                    color: '#263238'
                                }}
                            >
                                &nbsp; Rapoarte si Analiza
                            </p>

                        </div>

                        <br />

                        <div 
                            style={{
                                width: '100%',
                                height: '1px',
                                background: '#EBEBEB'
                            }}
                        ></div>

                        <br />

                        <Link
                            to='/ca/dashboard/cab/integrari'
                            stlye={{
                                textDecoration: 'none'
                            }}
                        >
                        <div
                            style={{
                                textAlign: 'left'
                            }}
                        >

                            <p
                                style={{
                                    position: 'relative',
                                    display: 'inline-block',
                                    margin: 0,
                                    top: 0,
                                }}
                            >
                                <img 
                                    src={IntegrationsIcon}

                                />
                            </p>

                            <p
                                style={{
                                    position: 'relative',
                                    display: 'inline-block',
                                    top: -7,
                                    margin: 0,
                                    fontSize: '14px',
                                    fontWeight: '500',
                                    color: '#263238'
                                }}
                            >
                                &nbsp; Integrari
                            </p>

                        </div>
                        </Link>

                        <br />

                        <div 
                            style={{
                                width: '100%',
                                height: '1px',
                                background: '#EBEBEB'
                            }}
                        ></div>

                        <br />

                        <div
                            style={{
                                textAlign: 'left'
                            }}
                            onClick = {() => window.location.href = 'https://planary.ro/docs'}
                        >

                            <p
                                style={{
                                    position: 'relative',
                                    display: 'inline-block',
                                    margin: 0,
                                    top: 0,
                                }}
                            >
                                <img 
                                    src={HelpIcon}

                                />
                            </p>

                            <p
                                style={{
                                    position: 'relative',
                                    display: 'inline-block',
                                    top: -7,
                                    margin: 0,
                                    fontSize: '14px',
                                    fontWeight: '500',
                                    color: '#263238'
                                }}
                            >
                                &nbsp; Ajutor!
                            </p>

                        </div>

                        <div
                            style={{
                                marginTop: 12,
                                textAlign: 'left'
                            }}
                            onClick={this.onLogoutUser}
                        >

                            <p
                                style={{
                                    position: 'relative',
                                    display: 'inline-block',
                                    margin: 0,
                                    top: 0,
                                }}
                            >
                                <img 
                                    src={LogoutIcon}

                                />
                            </p>

                            <p
                                style={{
                                    position: 'relative',
                                    display: 'inline-block',
                                    top: -7,
                                    margin: 0,
                                    fontSize: '14px',
                                    fontWeight: '500',
                                    color: '#263238'
                                }}
                            >
                                &nbsp; Log Out
                            </p>

                        </div>

                    </div>

                </div>

            </MobileView>
        )
    }
}