import React from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import Masks from '../../providers/Masks';
import Utils from '../../providers/Utils';
import Ws from '../../providers/Ws';
import ConfigSwitch from '../ConfigSwitch';
import MSwitch from '../MSwitch';
import TextareaDescription from '../TextareaDescription';

export default class SvEmailReview extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            reviewCerere: this.props.serviceDetails['review_cere'] || 0,
            descriere: this.props.serviceDetails['review_text'] || '',
            descLength: 0,
            canSave: false,
            reviewCerereTmp: this.props.serviceDetails['review_cere'] || 0,
            descriereTmp: this.props.serviceDetails['review_text'] || '',
        };
    }

    WS = new Ws();
    M = new Masks();
    UT = new Utils();

    componentDidMount(){
        
        this.setReviewEmailData();
    }

    componentDidUpdate(prevProps, _){
        
        if(prevProps.triggerSave !== this.props.triggerSave){

            // this.onCheckDataValidity();
        }

        if(prevProps['serviceDetails'] !== this.props['serviceDetails']){

            this.setState({

                reviewCerereTmp: this.props.serviceDetails['review_cere'] || 0,
                descriereTmp: this.props.serviceDetails['review_text'] || '',
            });
        }
    }

    setReviewEmailData = () => {

        this.setState({

            descLength: this.setDescriereServiciu()
        });
    }

    onChangeSwitch = () => {

        let doSave = true;

        if(+this.state.reviewCerereTmp === +!this.state.reviewCerere){

            doSave = false;
        }

        this.setState({

            reviewCerere: !+this.state.reviewCerere,
        },
            () => {

                this.props.inputStatus(doSave, 'hasReviewStatus', 'hasReview', this.state.reviewCerere ? 1 : 0);
                let duration = 500;
                clearTimeout(this.inputTimer);
                this.inputTimer = setTimeout( ()=> {

                    this.onCheckDataValidity();
                    
                }, duration);
            }
        )
    }

    setDescriereServiciu = () => {

        let length = this.state.descriere.replaceAll(/\s/g,'').length;

        return length;
    }

    onChangeDescriere = (ev) => {

        let count = ev.target.value.replaceAll(/\s/g,'').length;
        let doSave = true;

        if(count <= 500){
            
            if(this.state.descriereTmp === ev.target.value){

                doSave = false;
            }

            this.setState({

                descriere: ev.target.value,
                descLength: +count
            },
                () => {

                    this.props.inputStatus(doSave, 'reviewDescStatus', 'reviewDesc', ev.target.value);

                    let duration = 500;
                    clearTimeout(this.inputTimer);
                    this.inputTimer = setTimeout( ()=> {

                        this.onCheckDataValidity();
                        
                    }, duration);
                }
            );
        }
        else{
            
            const errorTx = 'Ai atins limita de caractere a mesajului de review!';
            this.props.showToastError(errorTx);
            // alert('Limita caractere atinsa!')
        }

        // 
    }

    onCheckDataValidity = () => {

        if(this.state.descriere.length < 1 && this.state.reviewCerere){

            const errorTx = 'Trebuie sa existe un mesaj de review pentru a putea fi activat.';
            this.props.inputStatus(true, 'isReviewOk', 'isReviewOk', false, true);
            this.props.onAddErrorMessage(errorTx);
        }
        else {
            
            this.props.inputStatus(true, 'isReviewOk', 'isReviewOk', true, true);
        }

        // let result = {};

        // if(this.state.descriere.length < 1){

        //     result = {

        //         data: null, 
        //         err: 'Trebuie sa existe un mesaj de review pentru a putea fi activat.'
        //     }
        // }
        // else {
            
        //     result = this.onGenerateQuery();
        // }

        // this.props.onCheckAndSave(result);
    }

    onGenerateQuery = () => {

        const switchDigit = this.state.reviewCerere ? 1 : 0;

        const result = {

            data: [
                {
                    itemName: 'review_cere',
                    itemData: `${switchDigit}`
                },
                
                {
                    itemName: 'review_text',
                    itemData: `'` + this.state.descriere + `'`
                },
            ],
            err: null
        }

        return result;
    }

    render(){

        return(

            <>
                <BrowserView>

                    <div className="form-control">

                        <ConfigSwitch 
                            title={this.state.reviewCerere ? 'Functionalitate Activa' : 'Functionalitate Inactiva'}
                            id='review'
                            for='review'
                            checked={this.state.reviewCerere}
                            onChange={this.onChangeSwitch}
                        />

                    </div>

                    <p
                        style={{
                            margin: 0,
                            textAlign: 'left',
                            fontSize: '14px',
                            fontWeight: '400',
                            color: '#000000'
                        }}
                    >

                        Textul mesajului tau catre client pentru invitatia la Review

                    </p>

                    <div 
                        className="form-control"
                        style={{
                            marginTop: 25
                        }}
                    >

                        <TextareaDescription 
                        
                            style={{
                                height: 300
                            }}
                            value={this.state.descriere}
                            onChange={this.onChangeDescriere}
                            textLenght={this.state.descLength}

                        />

                    </div>
                
                </BrowserView>

                <MobileView>

                    <MSwitch 
                        title={this.state.reviewCerere ? 'Functionalitate Activa' : 'Functionalitate Inactiva'}
                        checked={this.state.reviewCerere}
                        onChange={this.onChangeSwitch}
                    />

                    <div 
                        className="m-edit-s-card"
                        style={{
                            marginTop: 20
                        }}
                    >
                        <p 
                            style={{
                                margin: 0,
                                paddingBottom: '8px',
                                fontSize: '14px',
                                textAlign: 'left'
                            }}
                        >
                            Textul mesajului tau catre client pentru invitatia la Review
                        </p>
                        
                        <div 
                            className="form-control"
                            style={{
                                marginBottom: 30
                            }}
                        >

                            <TextareaDescription 
                            
                                style={{
                                    height: 200
                                }}
                                value={this.state.descriere}
                                onChange={this.onChangeDescriere}
                                textLenght={this.state.descLength}

                            />

                        </div>

                    </div>

                </MobileView>
            
            </>
        );
    }
}