import React from 'react';

import IntervalView from './IntervalView';
import Masks from '../../providers/Masks';
import Ws from '../../providers/Ws';
import LocalStorage from '../../providers/LocalStorage';
import Declarations from '../../providers/Declarations';
import Utils from '../../providers/Utils';

import { RiCalendar2Fill } from 'react-icons/ri';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';
import { ImPlus } from 'react-icons/im';
import { IoReceiptOutline } from 'react-icons/io5';
import { MdAlarm, MdPayment, MdClear } from 'react-icons/md';
import { IoVideocam } from 'react-icons/io5';
import { FaSkype } from 'react-icons/fa';
import { ImLocation2 } from 'react-icons/im';
import { RiArrowRightSLine } from 'react-icons/ri';
import { ReactComponent as ZoomIcon } from '../../assets/zoom_icon.svg';
import { ReactComponent as LocIcon } from '../../assets/pin_drop.svg';
 
import CalendarBg from '../../assets/calendar_bg.png';
import CalendarSaptamanal from './CalendarSaptamanal';
import CalAppointsPopup from './CalAppointsPopup';
import MIntervalView from './mobile/MIntervalView';
import { BrowserView, MobileView } from 'react-device-detect';
import { ReactComponent  as GMeetIcon} from '../../assets/Google_Meet-Logo.wine.svg';
import MAppointmentPopUp from './mobile/MAppointmentPopUp';
import TestInv from '../client/TestInv';
import CreateAppointPopup from './CreateAppointPopup';
import ErrorToast from '../../commons/ErrorToast';

export default class CalendarDirect extends React.Component{

    //PROPS
    /* 
        trc


    */
    constructor(props){

        super(props);

        this.state = {

            dataStart: new Date(2021, 6 , 1),
            dataEnd: new Date(2021, 6 , 30),
            elms: [],
            luna: '',
            appointments: [],
            services: [],
            monthType: true,
            listType: false,
            weekType: false,
            currentDay: new Date(),
            appointLocations: [],
            appointColors: [],
            showAppointsPopup: false,
            popupAppointList: [],
            mobileAppointList: [],
            popupData: null,
            tokenPopupClient: '',
            showAppointsPopupMobile: false,
            showCreateAppointPopup: false,
            payments: [],
            updateCal: false,
            showToastPopup: false,
            showToastPopupError: false,
            actionTx: '',
            renderPageCount: 0
        }
    }


    WS = new Ws();
    M = new Masks();
    UT = new Utils();

    componentDidMount(){

        if(this.props.trc){

            if(this.props.trc.length > 10){

                this.initDate()
            }
        }
    }   

    componentDidUpdate(prevProps){

        if(this.props.trc !== prevProps.trc){

            if(this.props.trc){

                if(this.props.trc.length > 10){
    
                    this.initDate()
                }
            }
        }

        if(this.state.updateCal === true){

            
            this.setState({

                updateCal: false
            },
                () => {

                    this.onUpdateCalendarContent();
                }
            )
        }

        if(this.props.updateServiceList === true){

            this.onUpdateCalendarContent();
            this.props.onUpdateServiceList(false);
        }
    }

    onUpdateCalendarContent = () => {

        let dt = this.state.dataStart
    
        let backTrick = this.M.addMonths(dt, -1)

        this.setState({

            dataStart: new Date(backTrick.getFullYear(), backTrick.getMonth(), 1),
            dataEnd: new Date(backTrick.getFullYear(), backTrick.getMonth(), 1)
        },
        
            () => {

                this.nextMonth(false)
                this.setReadableMonth()
            }
        ) 
    }
    
    initDate = () =>{

        // this.setState({

        //     dataStart: new Date(backTrick.getFullYear(), backTrick.getMonth(), 1),
        //     dataEnd: new Date(backTrick.getFullYear(), backTrick.getMonth(), 1)
        // },
        
        //     () => {

        //         this.nextMonth(false)
        //         this.setReadableMonth()
        //     }
        
        // )   

        this.goToMonth();
    }

    nextMonth = async (back) => {

        let coef = back ? - 1 : 1;

        const dataStartAux = this.state.dataStart

        let nextMonthStart = this.M.addMonths( new Date(dataStartAux) , coef)

        let nextMonthEnd = this.M.firstOrLastDayOfMonth( nextMonthStart.getMonth(), nextMonthStart.getFullYear(), true);

        const TOKEN_CONT = new LocalStorage().GetAccountToken();

        let selectAppoints = `select * from trc where month(data_cal) = ${nextMonthStart.getMonth() + 1} and year(data_cal) = ${nextMonthStart.getFullYear()} and account_tk = '${TOKEN_CONT}' and ifnull(draft,0)!=1 and ifnull(anulat,0)!=1 `
        let _fetchAppoint =  this.WS.sqlCommand(`select`, selectAppoints)


        let selectUnconfirmedAppoints =  `select * from trc where month(data_cal) = ${nextMonthStart.getMonth() + 1} and year(data_cal) = ${nextMonthStart.getFullYear()} and account_tk = '${TOKEN_CONT}' and 
                                            tip = 'programare' and src = 'ca' and ifnull(draft,0)=1 and ifnull(anulat,0)!=1 `
        let _unconfirmedAppoints = this.WS.sqlCommand(`select`, selectUnconfirmedAppoints);

        // let selectServices = `select * from services where account_tk = '${TOKEN_CONT}' and ifnull(deleted,0)!=1 and ifnull(draft,0)!=1  `
        let selectServices = `select * from services where account_tk = '${TOKEN_CONT}' `
        // 

        let _fecthServices =  this.WS.sqlCommand(`select`, selectServices)
        // 

        let selectPaymentsQuery = `select * from trc_payments `;
        let _paymentsInfo = this.WS.sqlCommand(`select`, selectPaymentsQuery);

        let [fetchAppoint, fecthServices, unconfirmedAppoints, paymentsInfo] = await Promise.all([_fetchAppoint, _fecthServices, _unconfirmedAppoints, _paymentsInfo])

        let serviceColorList = this.UT.getServicesColors(fecthServices.content);

        let elms = []
        let currentDayAppoints = []

        if(fetchAppoint.success && fecthServices.success && unconfirmedAppoints.success && paymentsInfo.success){

            const unconfirmedAppointsCalendar = unconfirmedAppoints.content.filter(uaEl => ( uaEl['clie_nume'] || '').length > 0);
            // const unconfirmedAppointsCalendar = unconfirmedAppoints.content.filter(uaEl => {

            //     if( (( uaEl['clie_nume'] || '').length > 0) && (new Date().getTime() <= this.M.sqlDateToJSDate(uaEl['data_cal']).getTime()) ){
    
            //         return uaEl;
            //     }
            // });
            const appointsFromClients = fetchAppoint.content;

            let mergedAppoints = appointsFromClients.concat(unconfirmedAppointsCalendar);

            mergedAppoints.sort( (prev, next) => {

                if(this.M.sqlDateToJSDate(prev['data_cal']).getDate() < this.M.sqlDateToJSDate(next['data_cal']).getDate()){

                    return -1;
                }
                else if(this.M.sqlDateToJSDate(prev['data_cal']).getDate() > this.M.sqlDateToJSDate(next['data_cal']).getDate()){

                    return 1;
                }
                else if(this.UT.convertToTime(prev['ora_cal']).getTime() < this.UT.convertToTime(next['ora_cal']).getTime()){

                    return -1;
                }
                else if(this.UT.convertToTime(prev['ora_cal']).getTime() < this.UT.convertToTime(next['ora_cal']).getTime()){

                    return 1;
                }
            })

            if(this.state.monthType){

                elms = mergedAppoints.map(el => {
    
                    return (
        
                        {   
                            id: el.id, 
                            token: el.token,
                            data: this.M.sqlDateToJSDate(el.data_cal), 
                            header: el.clie_nume + ' ' + el.clie_prenume, 
                            label: el.service_name, 
                            backgroundColor: this.UT.filterColors(true, serviceColorList, el.service_tk, el.tip, el.draft), 
                            color: this.UT.filterColors(false, serviceColorList, el.service_tk, el.tip, el.draft), 
                            imgSrc: '',
                            tip: el.tip,
                            breakDuration: (el['ora_cal'] || '').substr(0,5) + '-' + this.UT.addMinutes(this.UT.convertToTime(el['ora_cal']), el.duration).toTimeString().substr(0,5)
                        }
                    )           
                })
            }
            else if(this.state.listType){
    
                elms = mergedAppoints.map(el => {
    
                    return (
        
                        {   
                            id: el.id, 
                            token: el.token,
                            data: this.M.sqlDateToJSDate(el.data_cal), 
                            header: '', 
                            label: el.clie_nume + ' ' + el.clie_prenume, 
                            backgroundColor: this.UT.filterColors(true, serviceColorList, el.service_tk, el.tip, el.draft), 
                            color: this.UT.filterColors(false, serviceColorList, el.service_tk, el.tip, el.draft), 
                            imgSrc: '',
                            tip: el.tip, 
                        }
                    )           
                })  
            }

            // 

            this.setState(
 
                (prevState, _) => {

                    const auxStartDate = prevState.dataStart;
                    const startDate = this.M.addMonths(new Date(auxStartDate), coef);
                    const endDate = this.M.firstOrLastDayOfMonth(startDate.getMonth(), startDate.getFullYear(), true)

                    // alert("3.startDate:" + startDate);
                    // alert("3.endDate: " + endDate);

                    return {
                        ...this.state,
                        dataStart: startDate,
                        dataEnd: endDate,
                        elms: elms,
                        appointments: mergedAppoints,
                        services: fecthServices.content,
                        payments: paymentsInfo.content
                    }
                },

                () => {

                    // alert("intra in callback")
                    this.setReadableMonth()
                    this.getAppoinLocations(mergedAppoints)
                    this.getAppointColors(mergedAppoints, fecthServices.content)
                    this.getMobileAppointList(elms)
                  
                }
                
            );

        }
        else{

            this.setState({
                showToastPopupError: true,
                actionTx: 'Eroare preluare date calendar din server.'
            });
            throw new Error("Server response error: " + fetchAppoint.message + "\n" + fecthServices.message)
        } 
        
    }

    goToMonth = async () => {

        let ss = await this.WS.sqlCommand(`select`,`select * from trc where token = '${this.props.trc}'`);

        let dataProgramarii = ss.content[0]['data_cal'];
        let dataProgramariiAsJs = this.M.sqlDateToJSDate(dataProgramarii);

        let dataStart = new Date(dataProgramariiAsJs.getFullYear(), dataProgramariiAsJs.getMonth(), 1);
        
        let backTrick = this.M.addMonths(new Date(dataStart), 1)
        backTrick = this.M.addDaysToDate(backTrick, -1)


        let dataEnd = backTrick;

        let an = '2021';
        let luna = '09';

        // let nextMonthStart = this.M.addMonths(this.state.dataStart, coef)

        // let nextMonthEnd = this.M.firstOrLastDayOfMonth( nextMonthStart.getMonth(), nextMonthStart.getFullYear(), true)

        // 
        // 

        const TOKEN_CONT = new LocalStorage().GetAccountToken();

        let selectAppoints = `select * from trc where month(data_cal) = ${dataStart.getMonth() + 1} and year(data_cal) = ${dataStart.getFullYear()} and account_tk = '${TOKEN_CONT}' and ifnull(draft,0)!=1 and ifnull(anulat,0)!=1 `
        let _fetchAppoint =  this.WS.sqlCommand(`select`, selectAppoints)


        let selectUnconfirmedAppoints =  `select * from trc where month(data_cal) = ${dataStart.getMonth() + 1} and year(data_cal) = ${dataStart.getFullYear()} and account_tk = '${TOKEN_CONT}' and 
                                            tip = 'programare' and src = 'ca' and ifnull(draft,0)=1 and ifnull(anulat,0)!=1 `
        let _unconfirmedAppoints = this.WS.sqlCommand(`select`, selectUnconfirmedAppoints);

        // let selectServices = `select * from services where account_tk = '${TOKEN_CONT}' and ifnull(deleted,0)!=1 and ifnull(draft,0)!=1  `
        let selectServices = `select * from services where account_tk = '${TOKEN_CONT}'  `
        // 

        let _fecthServices =  this.WS.sqlCommand(`select`, selectServices)
        // 

        let selectPaymentsQuery = `select * from trc_payments `;
        let _paymentsInfo = this.WS.sqlCommand(`select`, selectPaymentsQuery);

        let [fetchAppoint, fecthServices, unconfirmedAppoints, paymentsInfo] = await Promise.all([_fetchAppoint, _fecthServices, _unconfirmedAppoints, _paymentsInfo])

        let serviceColorList = this.UT.getServicesColors(fecthServices.content);
        // 

        

        let elms = []
        let currentDayAppoints = []

        if(fetchAppoint.success && fecthServices.success && unconfirmedAppoints.success && paymentsInfo.success){

            const unconfirmedAppointsCalendar = unconfirmedAppoints.content.filter(uaEl => ( uaEl['clie_nume'] || '').length > 0);
            // const unconfirmedAppointsCalendar = unconfirmedAppoints.content.filter(uaEl => {

            //     if( (( uaEl['clie_nume'] || '').length > 0) && (new Date().getTime() <= this.M.sqlDateToJSDate(uaEl['data_cal']).getTime()) ){
    
            //         return uaEl;
            //     }
            // });
            const appointsFromClients = fetchAppoint.content;

            let mergedAppoints = appointsFromClients.concat(unconfirmedAppointsCalendar);

            mergedAppoints.sort( (prev, next) => {

                if(this.M.sqlDateToJSDate(prev['data_cal']).getDate() < this.M.sqlDateToJSDate(next['data_cal']).getDate()){

                    return -1;
                }
                else if(this.M.sqlDateToJSDate(prev['data_cal']).getDate() > this.M.sqlDateToJSDate(next['data_cal']).getDate()){

                    return 1;
                }
                else if(this.UT.convertToTime(prev['ora_cal']).getTime() < this.UT.convertToTime(next['ora_cal']).getTime()){

                    return -1;
                }
                else if(this.UT.convertToTime(prev['ora_cal']).getTime() < this.UT.convertToTime(next['ora_cal']).getTime()){

                    return 1;
                }
            })

            if(this.state.monthType){

                elms = mergedAppoints.map(el => {
    
                    return (
        
                        {   
                            id: el.id, 
                            token: el.token,
                            data: this.M.sqlDateToJSDate(el.data_cal), 
                            header: el.clie_nume + ' ' + el.clie_prenume, 
                            label: el.service_name, 
                            backgroundColor: this.UT.filterColors(true, serviceColorList, el.service_tk, el.tip, el.draft), 
                            color: this.UT.filterColors(false, serviceColorList, el.service_tk, el.tip, el.draft), 
                            imgSrc: '',
                            tip: el.tip,
                            breakDuration: (el['ora_cal'] || '').substr(0,5) + '-' + this.UT.addMinutes(this.UT.convertToTime(el['ora_cal']), el.duration).toTimeString().substr(0,5)
                        }
                    )           
                })
            }
            else if(this.state.listType){
    
                elms = mergedAppoints.map(el => {
    
                    return (
        
                        {   
                            id: el.id, 
                            token: el.token,
                            data: this.M.sqlDateToJSDate(el.data_cal), 
                            header: '', 
                            label: el.clie_nume + ' ' + el.clie_prenume, 
                            backgroundColor: this.UT.filterColors(true, serviceColorList, el.service_tk, el.tip, el.draft), 
                            color: this.UT.filterColors(false, serviceColorList, el.service_tk, el.tip, el.draft), 
                            imgSrc: '',
                            tip: el.tip, 
                        }
                    )           
                })  
            }

            // 

            this.setState({
    
                dataStart: dataStart,
                dataEnd: dataEnd,
                elms: elms,
                appointments: mergedAppoints,
                services: fecthServices.content,
                renderPageCount: this.state.renderPageCount + 1,
                payments: paymentsInfo.content 
                // appointments: currentDayAppoints
            }, 
    
                () => {
    
                    this.setReadableMonth()
                    this.getAppoinLocations(mergedAppoints)
                    this.getAppointColors(mergedAppoints, fecthServices.content)
                    this.getMobileAppointList(elms)
                    this.openReceivedAppointment(this.props.trc, this.M.sqlDateToJSDate(dataProgramarii))
                }
            );

        }
        else{

            this.setState({
                showToastPopupError: true,
                actionTx: 'Eroare preluare date calendar din server.'
            });
            throw new Error("Server response error: " + fetchAppoint.message + "\n" + fecthServices.message)
        }  

    }


    openReceivedAppointment = async (trToken, dataProgramariiJS) => {

        //select * from trc where token = trToken 
        //Luam data programarii --> luna si anul
    
        

        
        if(this.state.renderPageCount <= 1){

            this.onShowAppointsPopup(true, dataProgramariiJS, trToken);
            this.onShowAppointmentPopUpMobile(dataProgramariiJS, trToken, true);
        }
    }



    setReadableMonth = () => {

        const luna = this.UT.setHumanReadableMonth(this.state.dataStart.getMonth())

        this.setState({

            luna: luna
        })    
    }

    onSetCalendarType = (type) => {

        const calendarTypes = ['listType', 'monthType', 'weekType']
        let nonSelectedTypes = calendarTypes.filter(cEl => cEl !== type)

        // 
        this.setState({

            [type] : true,
            [nonSelectedTypes[0]]: false,
            [nonSelectedTypes[1]]: false
        },
            () => {

                this.initDate()
            }
        )

    }

    getAppoinLocations = (appoints) => {

        const locations = new Declarations().listaLocatiiIntalnire
        let appointLocationsList = []

        for(let i = 0; i < appoints.length; i++){

            for(let j = 0; j < locations.length; j++){

                if(appoints[i].location === locations[j].id){

                    appointLocationsList.push(
                        {
                            order: appoints[i].id,
                            ...locations[j]
                        }
                    )
                }
            }
        }

        // 

        this.setState({
            appointLocations: appointLocationsList
        })

    }

    getAppointColors = (appoint, services) => {

        const colors = new Declarations().listaCulori
        let appointList = []
        let appointColorList = []

        for(let i = 0; i < appoint.length; i++){

            for(let j = 0; j < services.length; j++){

                if(appoint[i]['service_tk'] === services[j].token){

                    appointList.push(
                        {
                            id: appoint[i].id,
                            color: services[j].color
                        }
                    )
                }
            }
        }

       for(let i = 0; i < appointList.length; i++){

            for(let j = 0; j < colors.length; j++){

                if(appointList[i].color === colors[j].val){

                    appointColorList.push(
                        {
                            id: appointList[i].id,
                            val: appointList[i].color,
                            col: colors[j].col
                        }
                    )
                }
            }
       }

        this.setState({
            appointColors: appointColorList
        })

        
    }

    onHideScrollBar = (isHidden) => {

        if(isHidden){

            document.body.style.overflow = 'hidden';
        }
        else{

            document.body.style.overflow = 'auto';
        }
    }

    onShowAppointsPopup = (isShown, dataCal, token) => {

        let appointDayList = []

        this.onHideScrollBar(true);

        

        if(isShown){

            appointDayList = this.state.appointments.filter(aEl => this.M.sqlDateToJSDate(aEl['data_cal']).getTime() === dataCal.getTime());
            
        }

        this.setState({

            showAppointsPopup: isShown,
            popupAppointList: appointDayList,
            popupData: dataCal,
            tokenPopupClient: token || ''
        })
    }

    onShowClientWeekly = (list, token, dataCal) => {    

        // 

        this.onHideScrollBar(true);

        if(dataCal !== undefined){

            list = list.filter(lEl => this.M.sqlDateToJSDate(lEl['data_cal']).getTime() === this.M.sqlDateToJSDate(dataCal).getTime())
        }

        this.setState({

            showAppointsPopup: true,
            popupAppointList: list,
            popupData: this.M.sqlDateToJSDate(list[0]['data_cal']),
            tokenPopupClient: token || ''
        })
    }

    getMobileAppointList = (appointList) => {

        let appointListNoDuplicates = []

        if(appointList.length > 1){

            appointListNoDuplicates = appointList.reduce((unique, o) => {
                
                if(!unique.some( obj => obj.data.getTime() === o.data.getTime() )) {
                    unique.push(o);
                  }
                  return unique;

            }, [])

            

        }
        else if(appointList.length === 1){

            appointListNoDuplicates = appointList
        }

        this.setState({

            mobileAppointList: appointListNoDuplicates
        })
    }

    onJumpToAppointLocation = (location) => {

        
        document.getElementById(location).scrollIntoView({behavior: 'smooth'})

        
    }

    onShowAppointmentPopUpMobile = (dataCal, token, isShown) => {

        let appointDayList = []

        this.onHideScrollBar(true);

        

        if(isShown){

            appointDayList = this.state.appointments.filter(aEl => this.M.sqlDateToJSDate(aEl['data_cal']).getTime() === dataCal.getTime())
            
        }

        this.setState({

            showAppointsPopupMobile: isShown,
            popupAppointList: appointDayList,
            popupData: dataCal,
            tokenPopupClient: token
        })
    }

    onCloseAppointmentPopUpMobile = () => {

        this.onHideScrollBar(false);

        this.setState({

            showAppointsPopupMobile: false,
        })
    }

    onTriggerCreateAppointPopup = () => {

        this.onHideScrollBar(true);

        this.setState({

            popupData: null,
            showCreateAppointPopup: !this.state.showCreateAppointPopup
        })
    }

    onCloseCreateAppointPopup = () => {

        this.onHideScrollBar(false);

        this.setState({

            popupData: null,
            showCreateAppointPopup: false 
        })
    }

    onCreateDayAppointment = (day) => {

        this.onCloseAppointsPopup();
        // this.onTriggerCreateAppointPopup();

        this.setState({

            popupData: day
        })

    }

    onCloseAppointsPopup = () => {

        this.onHideScrollBar(false);

        this.setState({

            showAppointsPopup: false
        })
    }

    onUpdateCal = () => {

        this.setState({

            updateCal: true
        })
    }

    onCloseToastPopup = (type) => {

        this.setState({

            [type]: false
        });
    }


    render(){
 
        return(

            <div
            
            >
                {
                    this.state.showToastPopupError && (

                        <ErrorToast 
                            action={this.state.actionTx}
                            onClosePopup = {this.onCloseToastPopup}
                        />
                    )
                }
                <BrowserView>

                {
                    this.state.showAppointsPopup && (

                        <CalAppointsPopup 
                            onShowAppointsPopup={this.onShowAppointsPopup}
                            onCloseAppointsPopup = {this.onCloseAppointsPopup}
                            appointmentList = {this.state.popupAppointList}
                            serviceList = {this.state.services}
                            popupData = {this.state.popupData}
                            tokenAppointClicked = {this.state.tokenPopupClient}
                            onCreateDayAppointment = {this.onCreateDayAppointment}
                            payments = {this.state.payments}
                        />
                    ) 
                }

                {
                    this.state.showCreateAppointPopup && (

                        <CreateAppointPopup 
                            servicesList={this.state.services}
                            onCloseCreateAppointPopup={this.onCloseCreateAppointPopup}
                            dayCal = {this.state.popupData}
                            onUpdateCal = {this.onUpdateCal}
                        />
                    )
                }
            
                <div 
                    className="wrapper-dashboard"
                    style={{
                        margin: '20px auto',
                    }}
                >

                    <div 
                        className="dashboard-calendar-card"
                        // style={{
                        //     padding: this.state.listType ? '30px' : ''
                        // }}
                    >

                        <div className="dashboard-calendar-header">

                           <div className="calendar-header-title">

                                <h3 
                                    className="title"
                                    style={{
                                        textAlign: 'left',
                                        margin: 0,
                                        marginLeft: 30
                                    }}
                                >
                                    
                                    <p 
                                        style={{
                                                display: 'inline-block', 
                                                position: 'relative', 
                                                top: 4, 
                                                left: 0, 
                                                margin: 0,
                                                color: 'rgb(255, 199, 39)',
                                                fontSize: '22px'
                                            }}
                                    >
                                        <RiCalendar2Fill /> 

                                    </p>

                                    <p 
                                        style={{
                                            display: 'inline-block', 
                                            position: 'relative', 
                                            left: 10,
                                            margin: 0,
                                            color: 'rgb(116, 131, 139)',
                                            fontWeight: '500'
                                        }}
                                    >
                                        Calendarul Meu
                                    </p>
                                    
                                </h3>

                           </div>

                           <div className="header-appoint">
                                    
                                <div 
                                    className="add-to-calendar"
                                    // onClick={this.UT.onAddAppointment}
                                    onClick={this.onTriggerCreateAppointPopup}
                                >

                                    <ImPlus 
                                        style={{
                                            position: 'relative',
                                            width: '10px',
                                            color: '#fff',
                                            fontSize: 10
                                        }}
                                        
                                    />
                                    
                                </div>

                           </div>

                            <div className="date-changer">

                                {   
                                    !this.state.weekType &&
                                    <div className="calendar-date-change">

                                        <IoIosArrowBack 

                                            style={{
                                                display: 'inline-block',
                                                position: 'relative',
                                                margin: 0,
                                                top: 5,
                                                fontSize: '22px',
                                                color: '#A6A6A6',
                                                cursor: 'pointer'
                                            }}

                                            onClick={
                                                () => {
                        
                                                    // setTimeout(() => this.nextMonth(true), 1000)
                                                    this.nextMonth(true)
                                                }
                                            }
                                        
                                        />

                                        <h3
                                            style={{
                                                display: 'inline-block',
                                                position: 'relative',
                                                margin: 0,
                                                fontSize: '18px',
                                                padding: '0px 10px 0 10px'
                                            }}
                                        
                                        >
                                        {this.state.luna} {this.state.dataStart.getFullYear()}
                                        </h3>

                                        <IoIosArrowForward 
                                            style={{
                                                display: 'inline-block',
                                                position: 'relative',
                                                margin: 0,
                                                top: 5,
                                                fontSize: '22px',
                                                color: '#A6A6A6',
                                                cursor: 'pointer'
                                            }}

                                            onClick={
                                                () => {
                        
                                                    // setTimeout(() => this.nextMonth(false), 1000)
                                                    this.nextMonth(false)
                                                }
                                            }
                                        
                                        />

                                    </div>
                                }
                                
                                
                            </div>

                            <div className="calendar-type">

                                <p 
                                    className={
                                        this.state.monthType === true ? "calendar-type-select c-selected" : "calendar-type-select"
                                    }
                                    onClick={
                                        () => {

                                            this.onSetCalendarType('monthType')
                                        }
                                    }
                                >
                                    Luna
                                </p>

                                <p 
                                    className={
                                        this.state.weekType === true ? "calendar-type-select c-selected" : "calendar-type-select"
                                    }
                                    onClick={
                                        () => {

                                            this.onSetCalendarType('weekType')
                                        }
                                    }
                                >
                                    {/* Saptamana */} Zi
                                </p>

                                <p 
                                    className={
                                        this.state.listType === true ? "calendar-type-select c-selected" : "calendar-type-select"
                                    }
                                    onClick={
                                        () => {

                                            this.onSetCalendarType('listType')
                                        }
                                    }
                                >
                                    Lista
                                </p>

                            </div>

                        </div>

                        <br />

                        <div 
                            className="row dashboard-cal-row"
                            style={{
                                padding: this.state.listType ? '30px' : '0px'
                            }}
                        >

                            {
                                this.state.monthType &&

                                <div className="col-l-12 col-m-12 col-s-12">

                                    <IntervalView 

                                        dataStart = {this.state.dataStart}
                                        dataEnd= {this.state.dataEnd}
                                        elements={this.state.elms}
                                        isListView={false}
                                        onShowAppointsPopup={this.onShowAppointsPopup}
                                    />

                                </div>

                            }

                            {
                                this.state.listType &&(
                                <React.Fragment>

                                <div 
                                    className="col-l-8 col-m-8 col-s-12"
                                    style={{

                                        paddingRight: 30,
                                        borderRight: '1px solid #EBEBEB'
                                    }}
                                >

                                    <IntervalView 

                                        dataStart = {this.state.dataStart}
                                        dataEnd= {this.state.dataEnd}
                                        elements={this.state.elms}
                                        isListView={true}
                                        onShowAppointsPopup={this.onShowAppointsPopup}
                                    />

                                </div>

                                <div 
                                    className="col-l-4 col-m-4 col-s-12"
                                    style={{
                                        paddingTop: '30px',
                                        height: '820px',
                                        overflow: 'auto'
                                    }}
                                >

                                    {
                                        this.state.appointments.length === 0 && (

                                            <div>

                                                <p
                                                    style={{
                                                        position: 'relative',
                                                        top: '60px',
                                                        margin: 0,
                                                        fontWeight: '400',
                                                        fontSize: '16px',
                                                        color: '#455A64'
                                                    }}
                                                >
                                                    Nu ai nicio programare in luna {this.state.luna}
                                                </p>

                                            </div>
                                        )
                                    }

                                    {
                                        this.state.appointments &&  
                                        this.state.appointments.length > 0 &&
                                        this.state.appointments.map((aEl, idx) => {

                                            if( (aEl.tip || '') == 'pauza' ){

                                                return(

                                                    <div    
                                                        key={'calendar-appoint-'+idx}
                                                        className="current-day-appointment"
                                                    >
    
                                                        <div className="row">
    
                                                            <div className="col-l-2 col-m-2 col-s-2">
    
                                                                <p
                                                                    style={{
                                                                        margin: 0,
                                                                        fontSize: '14px',
                                                                        color: '#A6A6A6'
                                                                    }}
                                                                >
                                                                    {this.state.luna.substr(0,3).toUpperCase()}
                                                                </p>
    
                                                                <h3
                                                                    style={{
                                                                        margin: 0,
                                                                        fontSize: '18px',
                                                                        color: '#A6A6A6'
                                                                    }}
                                                                >
                                                                    {
                                                                        this.M.sqlDateToJSDate(aEl['data_cal']).getDate()
                                                                    }
                                                                </h3>
    
                                                            </div>
    
                                                            <div className="col-l-10 col-m-10 col-s-10">
    
                                                                <div className="row">
    
                                                                    <div className="col-l-6 col-m-6 col-s-6">
    
                                                                        <h3
                                                                            style={{
    
                                                                                margin: 0,
                                                                                color: '#455A64',
                                                                                fontSize: '16px',
                                                                                textAlign: 'left',
                                                                            }}
                                                                        >
                                                                            {/* {aEl.clie_nume + ' ' + aEl.clie_prenume} */}
                                                                            {aEl['clie_nume']}
                                                                        </h3>

                                                                        <h3 
                                                                            className="title"
                                                                            style={{
                                                                                margin: 0,
                                                                                textAlign: 'left',
                                                                            }}
                                                                        >
                                                                            
                                                                            <p 
                                                                                style={{
                                                                                        display: 'inline-block', 
                                                                                        position: 'relative', 
                                                                                        top: 3, 
                                                                                        left: 0, 
                                                                                        margin: 0,
                                                                                        color: '#A6A6A6',
                                                                                        fontSize: '18px'
                                                                                        
                                                                                    }}
                                                                            >
                                                                                <MdAlarm />
                                                                            </p>
    
                                                                            <p 
                                                                                style={{
                                                                                    display: 'inline-block', 
                                                                                    position: 'relative', 
                                                                                    top: 0,
                                                                                    left: 6,
                                                                                    margin: 0,
                                                                                    color: '#455A64',
                                                                                    fontSize: '14px',
                                                                                    fontWeight: '400'  
                                                                                }}
                                                                            >
                                                                                {aEl['ora_cal'].substr(0,5)} 
                                                                                
                                                                                {' '} - {' '}
    
                                                                                {this.UT.addMinutes(this.UT.convertToTime(aEl['ora_cal'].substr(0,5)), aEl.duration).toTimeString().substr(0,5)}
                                                                            </p>
                                                                            
                                                                        </h3>
    
                                                                    </div>
    
                                                                    <div 
                                                                        className="col-l-6 col-m-6 col-s-6"
                                                                        style={{
                                                                            textAlign: 'right'
                                                                        }}
                                                                    >
    
                                                                       
                                                                        
                                                                    </div>
    
                                                                </div>
    
                                                                
    

                                                                
                                                            </div>
    
                                                        </div>
    
                                                        <div
                                                            style={{
                                                                position: 'relative',
                                                                marginTop: 30,
                                                                marginBottom: 30,
                                                                left: '50%',
                                                                transform: 'translateX(-50%)',
                                                                width: '90%',
                                                                height: '1px',
                                                                background: '#EBEBEB',
                                                            }}
                                                        >
                                                        </div>
    
                                                    </div>
                                                )
                                            }
                                            else{

                                                return(

                                                    <div    
                                                        key={'calendar-appoint-'+idx}
                                                        className="current-day-appointment"
                                                    >
    
                                                        <div className="row">
    
                                                            <div className="col-l-2 col-m-2 col-s-2">
    
                                                                <p
                                                                    style={{
                                                                        margin: 0,
                                                                        fontSize: '14px',
                                                                        color: '#A6A6A6'
                                                                    }}
                                                                >
                                                                    {this.state.luna.substr(0,3).toUpperCase()}
                                                                </p>
    
                                                                <h3
                                                                    style={{
                                                                        margin: 0,
                                                                        fontSize: '18px',
                                                                        color: '#A6A6A6'
                                                                    }}
                                                                >
                                                                    {
                                                                        this.M.sqlDateToJSDate(aEl['data_cal']).getDate()
                                                                    }
                                                                </h3>
    
                                                            </div>
    
                                                            <div className="col-l-10 col-m-10 col-s-10">
    
                                                                <div className="row">
    
                                                                    <div className="col-l-6 col-m-6 col-s-6">
    
                                                                        <h3
                                                                            style={{
    
                                                                                margin: 0,
                                                                                color: '#455A64',
                                                                                fontSize: '16px',
                                                                                textAlign: 'left',
                                                                            }}
                                                                        >
                                                                            {aEl.clie_nume + ' ' + aEl.clie_prenume}
                                                                        </h3>
    
                                                                    </div>
    
                                                                    <div 
                                                                        className="col-l-6 col-m-6 col-s-6"
                                                                        style={{
                                                                            textAlign: 'right'
                                                                        }}
                                                                    >
    
                                                                        <h3 
                                                                            className="title"
                                                                            style={{
                                                                                position: 'relative',
                                                                                left: -30,
                                                                                margin: 0,
                                                                                // textAlign: 'right',
                                                                            }}
                                                                        >
                                                                            
                                                                            <p 
                                                                                style={{
                                                                                        display: 'inline-block', 
                                                                                        position: 'relative', 
                                                                                        top: 0, 
                                                                                        left: -2, 
                                                                                        margin: 0,
                                                                                        color: '#263238',
                                                                                        fontSize: '16px',
                                                                                        fontWeight: '400'
                                                                                    }}
                                                                            >
                                                                                {aEl.price} Lei
                                                                            </p>
    
                                                                            <p 
                                                                                style={{
                                                                                    display: 'inline-block', 
                                                                                    position: 'relative', 
                                                                                    top: 4,
                                                                                    left: 4,
                                                                                    margin: 0,
                                                                                    color: '#263238',  
                                                                                }}
                                                                            >
                                                                                <IoReceiptOutline />
                                                                            </p>
                                                                            
                                                                        </h3>     
    
                                                                    </div>
    
                                                                </div>
    
                                                                <div 
                                                                    className="row"
                                                                    style={{
                                                                        marginTop: 5
                                                                    }}
                                                                >
    
                                                                    <div className="col-l-1 col-m-1 col-s-1">
                                                                        {
                                                                            this.state.appointColors.map((cEl, idx) => {
    
                                                                                if(cEl.id === aEl.id){
    
                                                                                    return(
    
                                                                                        <div 
                                                                                            key={'calendar-service-color-'+idx}
                                                                                            style={{
                                                                                                position: 'relative',
                                                                                                width: '14px',
                                                                                                height: '14px',
                                                                                                background: aEl['src'] === 'ca' && aEl['draft'] === 1 ? '#455A64' : cEl['col'],
                                                                                                borderRadius: '50%'
                                                                                            }}
                                                                                        >   
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            })
                                                                        }
                                                                        
    
                                                                    </div>
    
                                                                    <div 
                                                                        className="col-l-11 col-m-11 col-s-11"
                                                                    >
    
                                                                        <p 
                                                                            style={{
                                                                                    margin: 0,
                                                                                    position: 'relative',
                                                                                    width: '90%',
                                                                                    top: -2,
                                                                                    fontSize: '14px',
                                                                                    fontWeight: '400',
                                                                                    textAlign: 'left'
                                                                                }}
                                                                        >
                                                                            {
                                                                                aEl.service_name
                                                                            }
                                                                        </p>
    
                                                                    </div>
    
                                                                </div>
    
    
                                                                <div className="row">
    
                                                                    <div className="col-l-5 col-m-5 col-s-5">
                                                                        
                                                                        <h3 
                                                                            className="title"
                                                                            style={{
                                                                                margin: 0,
                                                                                textAlign: 'left',
                                                                            }}
                                                                        >
                                                                            
                                                                            <p 
                                                                                style={{
                                                                                        display: 'inline-block', 
                                                                                        position: 'relative', 
                                                                                        top: 3, 
                                                                                        left: 0, 
                                                                                        margin: 0,
                                                                                        color: '#A6A6A6',
                                                                                        fontSize: '18px'
                                                                                        
                                                                                    }}
                                                                            >
                                                                                <MdAlarm />
                                                                            </p>
    
                                                                            <p 
                                                                                style={{
                                                                                    display: 'inline-block', 
                                                                                    position: 'relative', 
                                                                                    top: 0,
                                                                                    left: 6,
                                                                                    margin: 0,
                                                                                    color: '#455A64',
                                                                                    fontSize: '14px',
                                                                                    fontWeight: '400'  
                                                                                }}
                                                                            >
                                                                                {aEl['ora_cal'].substr(0,5)} 
                                                                                
                                                                                {' '} - {' '}
    
                                                                                {this.UT.addMinutes(this.UT.convertToTime(aEl['ora_cal'].substr(0,5)), aEl.duration).toTimeString().substr(0,5)}
                                                                            </p>
                                                                            
                                                                        </h3>
                                                                    
                                                                    </div>
    
                                                                    <div className="col-l-7 col-m-7 col-s-7">
    
                                                                        <h3 
                                                                            className="title"
                                                                            style={{
                                                                                position: 'relative',
                                                                                margin: 0,
                                                                                textAlign: 'right',
                                                                                right: '20px'
                                                                            }}
                                                                        >
                                                                            
                                                                            <p 
                                                                                style={{
                                                                                        display: 'inline-block', 
                                                                                        position: 'relative', 
                                                                                        top: 3, 
                                                                                        left: 0, 
                                                                                        margin: 0,
                                                                                        color: '#A6A6A6',
                                                                                        fontSize: '18px'
                                                                                        
                                                                                    }}
                                                                            >
                                                                                {
                                                                                    aEl.location === 'zoom' && (
                                                                                        <ZoomIcon 
                                                                                            style={{
                                                                                                position: 'relative',
                                                                                                top: '-4px',
                                                                                                width: '18px',
                                                                                                height: '9px'
                                                                                            }}
                                                                                        />
                                                                                    )
                                                                                }
    
                                                                                {
                                                                                    aEl.location === 'skype' && (
                                                                                        <FaSkype />
                                                                                    )
                                                                                }
    
                                                                                {
                                                                                    aEl.location === 'office' && (
                                                                                        <LocIcon 
                                                                                            className="loc-icon-desktop-card"
                                                                                            style={{
                                                                                                position: 'relative',
                                                                                                top: '1px',
                                                                                                left: '-2px',
                                                                                                width: '20px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        />
                                                                                    )
                                                                                }
    
                                                                                {
                                                                                    aEl.location === 'gmeet' && (
                                                                                        <GMeetIcon 
                                                                                            className="gmeet-services"
                                                                                                style={{
                                                                                                    position: 'relative',
                                                                                                    width: '20px',
                                                                                                    height: '20px'
                                                                                                }}
                                                                                        />
                                                                                    )
                                                                                }
                                                                            </p>
    
                                                                            {
                                                                                this.state.appointLocations.map((alEl, idx) => {
    
                                                                                    if(alEl.order === aEl.id && aEl.location === alEl.id){
    
                                                                                        return(
    
                                                                                            <p 
                                                                                                key={'appoint-location-desc'+idx}
                                                                                                style={{
                                                                                                    display: 'inline-block', 
                                                                                                    position: 'relative', 
                                                                                                    top: 0,
                                                                                                    left: 6,
                                                                                                    margin: 0,
                                                                                                    color: '#455A64',
                                                                                                    fontSize: '14px',
                                                                                                    fontWeight: '400'  
                                                                                                }}
                                                                                            >
                                                                                                {alEl.descClient}
                                                                                            </p>
    
                                                                                        )
                                                                                    }
                                                                                })
                                                                            }
    
                                                                            
                                                                            
                                                                        </h3>
    
                                                                    </div>
    
                                                                </div>
    
                                                                <p
                                                                    style={{
                                                                        position: 'relative',
                                                                        top: 10,
                                                                        margin: 0,
                                                                        textAlign: 'left',
                                                                        color: '#44A5FF',
                                                                        cursor: 'pointer'
                                                                    }}
    
                                                                    onClick={
    
                                                                        () => {
    
                                                                            this.onShowAppointsPopup(true, this.M.sqlDateToJSDate(aEl['data_cal']), aEl.token)
                                                                        }
                                                                    }
                                                                >
                                                                    <u>Detalii</u>
                                                                </p>
    
                                                                
                                                            </div>
    
                                                        </div>
    
                                                        <div
                                                            style={{
                                                                position: 'relative',
                                                                marginTop: 30,
                                                                marginBottom: 30,
                                                                left: '50%',
                                                                transform: 'translateX(-50%)',
                                                                width: '90%',
                                                                height: '1px',
                                                                background: '#EBEBEB',
                                                            }}
                                                        >
                                                        </div>
    
                                                    </div>
                                                )
                                            }
                                            
                                        })
                                    }
                                    

                                </div>

                                </React.Fragment>

                                )
                            }

                        </div>

                        {
                            this.state.weekType &&
                            <CalendarSaptamanal 
                               onShowClientWeekly={this.onShowClientWeekly}
                            />
                        }

                    </div>

                </div>

              

                <div
                    style={{
                        width: '100%',
                        height: 300
                    }}
                >
                    <img 
                        src={CalendarBg} 
                        style={{
                            width: '400px',
                            marginTop: 40
                        }}
                    />
                </div>

                </BrowserView>

                <MobileView>

                    {
                        this.state.showAppointsPopupMobile && (

                            <MAppointmentPopUp 

                                appointmentList = {this.state.popupAppointList}
                                serviceList = {this.state.services}
                                popupData = {this.state.popupData}
                                tokenAppointClicked ={this.state.tokenPopupClient}
                                onCloseAppointmentPopUpMobile={this.onCloseAppointmentPopUpMobile}
                                payments = {this.state.payments}
                            />
                        )
                    }

                    <div
                        style={{
                            paddingTop: 20
                        }}
                    >

                        <div className="row">

                            <div className="col-s-3">

                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        textAlign: 'center',
                                        position: 'relative',
                                        margin: 'auto',
                                        width: '35px',
                                        height: '35px',
                                        borderRadius: '50%',
                                        background: '#31A81D'
                                    }}

                                    onClick={() => {document.location.href = '/#/ca/creare-programare'}}
                                >
                                    <ImPlus 
                                        style={{
                                            position: 'absolute',
                                            margin: 0,
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translateY(-50%) translateX(-50%)',
                                            color: '#fff',
                                            fontSize: '10px'
                                        }}
                                    />
                                </div>

                            </div>

                            <div 
                                className="col-s-9"
                                style={{
                                    paddingRight: '20px',
                                    textAlign: 'right'
                                }}
                            >

                                <IoIosArrowBack 

                                    style={{
                                        display: 'inline-block',
                                        position: 'relative',
                                        margin: 0,
                                        top: 7,
                                        fontSize: '26px',
                                        color: '#A6A6A6',
                                        cursor: 'pointer'
                                    }}

                                    onClick={
                                        () => {
                
                                            this.nextMonth(true)
                                        }
                                    }
                                
                                />

                                <h3
                                    style={{
                                        display: 'inline-block',
                                        position: 'relative',
                                        margin: 0,
                                        fontSize: '18px',
                                        padding: '0px 10px 0 10px'
                                    }}
                                
                                >
                                {this.state.luna} {this.state.dataStart.getFullYear()}
                                </h3>

                                <IoIosArrowForward 
                                    style={{
                                        display: 'inline-block',
                                        position: 'relative',
                                        margin: 0,
                                        top: 7,
                                        fontSize: '26px',
                                        color: '#A6A6A6',
                                        cursor: 'pointer'
                                    }}

                                    onClick={
                                        () => {
                
                                            this.nextMonth(false)
                                        }
                                    }
                                
                                />

                            </div>

                        </div>

                        <div 
                            className="dashboard-calendar-card"
                            style={{
                                padding: '35px 10px 25px 10px',
                                marginTop: 20
                            }}
                        >

                            <MIntervalView

                                dataStart = {this.state.dataStart}
                                dataEnd= {this.state.dataEnd}
                                elements={this.state.elms}   
                                onJumpToAppointLocation={this.onJumpToAppointLocation}      
                            />

                        </div>

                        <div 
                            style={{
                                marginTop: 20
                            }}
                        >

                            {
                                this.state.mobileAppointList.length === 0 && (

                                    <p
                                        style={{
                                            margin: 0,
                                            paddingBottom: '20px',
                                            fontWeight: '400',
                                            fontSize: '16px',
                                            color: '#C1C1C1'
                                        }}
                                    >
                                        Nu ai nicio programare in luna {this.state.luna}
                                    </p>
                                ) 
                            }

                            {   
                                this.state.mobileAppointList.length > 0 &&
                                this.state.mobileAppointList.map((emEl, idx) => {

                                    let dayAppoints = this.state.appointments.filter(daEl => {

                                        if(this.M.sqlDateToJSDate(daEl['data_cal']).getTime() === emEl.data.getTime()){

                                            return daEl
                                        }
                                    })  

                                    return(
                                        <div key={'m-calendar-appoints-'+idx}>
                                        <div
                                            className="row"
                                        >
                                        
                                            <div 
                                                className="col-s-3"
                                                id={`data_${emEl['data'].getMonth()}_${emEl['data'].getDate()}`}
                                            >
                                                <p
                                                    style={{
                                                        margin: 0,
                                                        color: '#A6A6A6',
                                                        fontSize: '18px'
                                                    }}
                                                >
                                                    {this.state.luna.substr(0,3).toUpperCase()}
                                                </p>

                                                <h3
                                                    style={{
                                                        position: 'relative',
                                                        top: -2,
                                                        margin: 0,
                                                        color: '#A6A6A6',
                                                        fontSize: '18px'
                                                    }}
                                                >
                                                    {emEl['data'].getDate()}
                                                </h3>

                                            </div>
                                          
                                            <div 
                                                className="col-s-9"
                                                style={{
                                                    textAlign: 'left'
                                                }}
                                            >

                                                {   
                                                    this.state.appointLocations && 
                                                    this.state.appointColors &&
                                                    dayAppoints.length > 0 &&
                                                    dayAppoints.map((daEl, idx) => {

                                                        let appointColor = this.state.appointColors.filter(cEl => cEl.id === daEl.id) 
                                                        let appointLocation = this.state.appointLocations.filter(lEl => {

                                                            if(lEl.order === daEl.id && (daEl.location || '').trim() === lEl.id){

                                                                return lEl
                                                            }
                                                        })

                                                        if( (daEl.tip || '') == 'pauza'){

                                                            return(

                                                                <div key={'mobile-day-appoints-'+idx}>
    
                                                                <h3
                                                                    style={{
                                                                        position: 'relative',
                                                                        top: 2,
                                                                        margin: 0,
                                                                        color: '#455A64',
                                                                        fontSize: '14px'
                                                                    }} 
                                                                >
                                                                    {/* {daEl['clie_nume'] + ' ' + daEl['clie_prenume']} */}
                                                                    {daEl['clie_nume']}
                                                                </h3>
    
                                                                <h3 
                                                                    className="title"
                                                                    style={{
                                                                        margin: 0,
                                                                        textAlign: 'left',
                                                                    }}
                                                                >
                                                                    
                                                                    <p 
                                                                        style={{
                                                                                display: 'inline-block', 
                                                                                position: 'relative', 
                                                                                top: 3, 
                                                                                left: 0, 
                                                                                margin: 0,
                                                                                color: '#A6A6A6',
                                                                                fontSize: '16px'
                                                                                
                                                                            }}
                                                                    >
                                                                        <MdAlarm />
                                                                    </p>
    
                                                                    <p 
                                                                        style={{
                                                                            display: 'inline-block', 
                                                                            position: 'relative', 
                                                                            top: 0,
                                                                            left: 6,
                                                                            margin: 0,
                                                                            color: '#455A64',
                                                                            fontSize: '12px',
                                                                            fontWeight: '400'  
                                                                        }}
                                                                    >   
                                                                        {daEl['ora_cal'].substr(0,5)} 
                                                                                
                                                                        {' '} - {' '}
    
                                                                        {this.UT.addMinutes(this.UT.convertToTime(daEl['ora_cal'].substr(0,5)), daEl.duration).toTimeString().substr(0,5)}
                                                                    </p>
                                                                    
                                                                </h3>
    
                                                                {
                                                                    dayAppoints.length > 0 &&
                                                                    dayAppoints.length - 1 > idx && (
    
                                                                        <div
                                                                            style={{
                                                                                marginTop: 20,
                                                                                marginBottom: 20,
                                                                                width: '94%',
                                                                                height: '1px',
                                                                                background: '#EBEBEB'
                                                                            }}
                                                                        >
                                                                            
                                                                        </div>
                                                                    )
                                                                }
    
                                                                
    
                                                                </div>
                                                            )
                                                        }
                                                        else{

                                                            return(

                                                                <div 
                                                                    key={'mobile-day-appoints-'+idx}
                                                                >
                                                                <div
                                                                    onClick={
                                                                        () => {

                                                                            this.onShowAppointmentPopUpMobile(this.M.sqlDateToJSDate(daEl['data_cal']), daEl['token'], true)
                                                                        }
                                                                    }
                                                                >
                                                                <h3
                                                                    style={{
                                                                        position: 'relative',
                                                                        top: 2,
                                                                        margin: 0,
                                                                        color: '#455A64',
                                                                        fontSize: '14px'
                                                                    }} 
                                                                >
                                                                    {daEl['clie_nume'] + ' ' + daEl['clie_prenume']}
                                                                </h3>
    
                                                                <div 
                                                                    className="row"
                                                                    style={{
                                                                        marginTop: 10
                                                                    }}
                                                                >
    
                                                                    <div className="col-s-1">
                                                                        
                                                                        <div
                                                                            style={{
                                                                                position: 'relative',
                                                                                left: '3px',
                                                                                width: '10px',
                                                                                height: '10px',
                                                                                borderRadius: '50%',
                                                                                background: daEl['draft'] === 1 && daEl['src'] === 'ca' ? '#455A64' : appointColor[0].col
                                                                            }}
                                                                        >
    
                                                                        </div>
    
                                                                    </div>
    
                                                                    <div className="col-s-11">
    
                                                                        <p
                                                                            style={{
                                                                                position: 'relative',
                                                                                top: -3,
                                                                                margin: 0,
                                                                                fontSize: '12px'
                                                                            }}
                                                                        >
                                                                            {daEl['service_name']}
                                                                        </p>
    
                                                                    </div>
    
                                                                </div>
    
                                                                <h3 
                                                                    className="title"
                                                                    style={{
                                                                        margin: 0,
                                                                        textAlign: 'left',
                                                                    }}
                                                                >
                                                                    
                                                                    <p 
                                                                        style={{
                                                                                display: 'inline-block', 
                                                                                position: 'relative', 
                                                                                top: 3, 
                                                                                left: 0, 
                                                                                margin: 0,
                                                                                color: '#A6A6A6',
                                                                                fontSize: '16px'
                                                                                
                                                                            }}
                                                                    >
                                                                        <MdAlarm />
                                                                    </p>
    
                                                                    <p 
                                                                        style={{
                                                                            display: 'inline-block', 
                                                                            position: 'relative', 
                                                                            top: 0,
                                                                            left: 6,
                                                                            margin: 0,
                                                                            color: '#455A64',
                                                                            fontSize: '12px',
                                                                            fontWeight: '400'  
                                                                        }}
                                                                    >   
                                                                        {daEl['ora_cal'].substr(0,5)} 
                                                                                
                                                                        {' '} - {' '}
    
                                                                        {this.UT.addMinutes(this.UT.convertToTime(daEl['ora_cal'].substr(0,5)), daEl.duration).toTimeString().substr(0,5)}
                                                                    </p>
                                                                    
                                                                </h3>
                                                                </div>
    
                                                                <div className="row">
    
                                                                    <div 
                                                                        className="col-s-8"
                                                                        onClick={
                                                                            () => {
    
                                                                                this.onShowAppointmentPopUpMobile(this.M.sqlDateToJSDate(daEl['data_cal']), daEl['token'], true)
                                                                            }
                                                                        }
                                                                    >
    
                                                                        <h3 
                                                                            className="title"
                                                                            style={{
                                                                                margin: 0,
                                                                                textAlign: 'left',
                                                                            }}
                                                                        >
                                                                            
                                                                            <p 
                                                                                style={{
                                                                                        display: 'inline-block', 
                                                                                        position: 'relative', 
                                                                                        top: 3, 
                                                                                        left: 0, 
                                                                                        margin: 0,
                                                                                        color: '#A6A6A6',
                                                                                        fontSize: '16px'
                                                                                        
                                                                                    }}
                                                                            >
                                                                                {
                                                                                    daEl.location === 'zoom' && (
                                                                                        <ZoomIcon
                                                                                            style={{
                                                                                                position: 'relative',
                                                                                                top: '-3px',
                                                                                            }}
                                                                                        />
                                                                                    )
                                                                                }
    
                                                                                {
                                                                                    daEl.location === 'skype' && (
                                                                                        <FaSkype />
                                                                                    )
                                                                                }
    
                                                                                {
                                                                                    daEl.location === 'office' && (
                                                                                        <LocIcon 
                                                                                            className="loc-icon-desktop-card"
                                                                                            style={{
                                                                                                position: 'relative',
                                                                                                top: '1px',
                                                                                                left: '-2px',
                                                                                                width: '20px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        />
                                                                                    )
                                                                                }
    
                                                                                {
                                                                                    daEl.location === 'gmeet' && (
    
                                                                                        <GMeetIcon 
                                                                                            className="gmeet-services"
                                                                                                style={{
                                                                                                    position: 'relative',
                                                                                                    top: 1,
                                                                                                    width: '16px',
                                                                                                    height: '16px'
                                                                                                }}
                                                                                        />
                                                                                    )
                                                                                }
                                                                            </p>
    
                                                                            <p 
                                                                                style={{
                                                                                    display: 'inline-block', 
                                                                                    position: 'relative', 
                                                                                    top: 0,
                                                                                    left: 6,
                                                                                    margin: 0,
                                                                                    color: '#455A64',
                                                                                    fontSize: '12px',
                                                                                    fontWeight: '400'  
                                                                                }}
                                                                            >
                                                                                { 
                                                                                    daEl.location === 'office' ? (appointLocation[0].descClient || '').substr(0,18) : appointLocation[0].descClient 
                                                                                }
                                                                            </p>
                                                                            
                                                                        </h3>
    
                                                                    </div>
    
                                                                    <div className="col-s-4">
    
                                                                        <a
    
                                                                            href={daEl['host_meeting_link']}
                                                                            style={{
                                                                                display: 'block',
                                                                                position: 'relative',
                                                                                top: 6,
                                                                                right: 20,
                                                                                margin: 0,
                                                                                color: '#2577C2',
                                                                                fontSize: '12px',
                                                                                textAlign: 'right',
                                                                                textDecoration: 'none',
                                                                            }}
                                                                            
                                                                            // onClick={
                                                                            //     () => {
    
                                                                            //         this.onShowAppointmentPopUpMobile(this.M.sqlDateToJSDate(daEl['data_cal']), daEl['token'], true)
                                                                            //     }
                                                                            // }
                                                                        >
                                                                            {
                                                                                daEl.location !== 'office' && (
    
                                                                                    <u>Acceseaza</u>
                                                                                )
                                                                            }
                                                                        </a>
    
                                                                    </div>
    
                                                                </div>
    
                                                                {
                                                                    dayAppoints.length > 0 &&
                                                                    dayAppoints.length - 1 > idx && (
    
                                                                        <div
                                                                            style={{
                                                                                marginTop: 20,
                                                                                marginBottom: 20,
                                                                                width: '94%',
                                                                                height: '1px',
                                                                                background: '#EBEBEB'
                                                                            }}
                                                                        >
                                                                            
                                                                        </div>
                                                                    )
                                                                }
    
                                                                
    
                                                                </div>
                                                            )
                                                        }

                                                        
                                                    })
                                                }


                                            </div>

                                        

                                        
                                                
                                    </div>

                                    <div
                                        style={{
                                            marginTop: 20,
                                            marginBottom: 20,
                                            width: '100%',
                                            height: '1px',
                                            background: '#EBEBEB'
                                        }}
                                    ></div> 

                                    
                                    </div> 
                                    )

                                })
                            }
                            
                        </div>

                    </div>

                </MobileView>

            </div>
        );
    }
}