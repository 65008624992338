import React from 'react';
import Masks from '../../providers/Masks';
import Utils from '../../providers/Utils';
import Ws from '../../providers/Ws';

import RegisterHeader from './RegisterHeader';

import Image from '../../assets/Businesswoman-cuate 1.png';
import { BrowserView, MobileView } from 'react-device-detect';

import CanaryPlantImage from '../../assets/canary_plant_bg.png';
import MBirdAndPlantImage from '../../assets/bird_and_plant_mobile.png';
import LocalStorage from '../../providers/LocalStorage';

export default class EmailValidation extends React.Component{

    constructor(props){

        super(props);
        this.state = {
            email: ''
        };
    }

    WS = new Ws();
    M = new Masks();
    UT = new Utils();

    componentDidMount(){

        // this.onGetEmailValidatResponse();
    }

    onGetEmailValidatResponse = async () => {

        let isEmailValidat = await this.UT.onCheckIfUserHasWrkToken();

        if(isEmailValidat.success){

            window.location.href = "/";
        }
        else{

            let ud = new LocalStorage().GetUserData();

            this.setState({

                email: ud["userData"][0]['email']
            });
        }
    }

    render(){

        return(
            <>
                <BrowserView>

                    <div className='register-container-desktop'>

                        <RegisterHeader />

                        <div className='service-details-card' style={{marginTop: '20px'}}>

                            <div className='register-center-tx-wrapper'>

                                <p 
                                    className='register-tx register-title'
                                    style={{
                                        fontSize: '18px'
                                    }}
                                >
                                        Email de Verificare
                                </p>

                                <p 
                                    className='register-tx'
                                    style={{
                                        marginTop: '20px'
                                    }}
                                >
                                    Am trimis un e-mail de verficare pe adresa <u>{this.props.email}</u>. 
                                    Te rugam acceseaza email-ul tau si apasa linkul de confirmare.
                                </p>

                                <img
                                    className='register-image'
                                    src={Image}
                                    alt="Planary"
                                />

                            </div>



                        </div>

                        <img 
                            className='bottom-pg-img'
                            src={CanaryPlantImage}
                            alt = 'Planary...'
                            style={{
                                marginTop: '20px'
                            }}
                        />

                    </div>

                </BrowserView>

                <MobileView>

                    <div 
                        style={{
                            padding: '20px',
                            minHeight: '850px',
                            background: `url(${MBirdAndPlantImage}) bottom no-repeat`
                        }}
                    >
                        
                        <RegisterHeader />
                        
                        <div className="m-edit-s-card" style={{marginTop: '20px'}}>
                            
                            <p 
                                className='register-tx register-title'
                                style={{
                                    fontSize: '18px'
                                }}
                            >
                                    Email de Verificare
                            </p>

                            <p 
                                className='register-tx'
                                style={{
                                    marginTop: '20px'
                                }}
                            >
                                Am trimis un e-mail de verficare pe adresa <u>getsergiu@gmail.com</u>. 
                                Te rugam acceseaza email-ul tau si apasa linkul de confirmare.
                            </p>

                            <img
                                className='register-image'
                                src={Image}
                                alt="Planary"
                            />

                        </div>

                    </div>

                </MobileView>

            </>
        )
    }
}