import React from 'react';

import Ws from '../../providers/Ws';
import Utils from '../../providers/Utils';

import { Link } from 'react-router-dom';

import ButtonForm from '../../commons/ButtonForm';
import { thisExpression } from '@babel/types';
import { BrowserView, MobileView } from 'react-device-detect';
import CompanyAvatar from '../../assets/foxStat.jpeg';

export default class RutaIncompleta extends React.Component{

    constructor(props){

        super(props)


        this.state = {

            errorTitle: '',
            errorTx: '',
            serviceExists: false
        }
    }

    WS = new Ws();
    UT = new Utils();

    componentDidMount(){

        this.getServiceData()
    }

    componentWillUnmount(){

    }

    getServiceData = async () => {

        const selectServices = `select * from services where account_tk = ( select token from accounts where web_link = '${this.props.match.params.company_web_link}' ) 
                                                            and ifnull(deleted,0)!=1 and ifnull(draft,0)!=1`;

        const selectService = `select * from services where account_tk = ( select token from accounts where web_link = '${this.props.match.params.company_web_link}' ) 
                                                          and web_link = '${this.props.match.params.service_web_link}' `;

        const service = await this.WS.sqlCommand(`select`, selectService);
        const services = await this.WS.sqlCommand(`select`, selectServices);

        if(service.content.length === 0 && services.content.length === 0){

          
            this.setState({

                errorTitle: 'Ooh... Compania este inexistenta sau link-ul este gresit',
                errorTx: `Aceasta companie <u>nu exista</u> Incearca sa introduci link-ul din nou.`,
                serviceExists: false
            })
            
        }
        else{

            this.setState({

                errorTitle: 'Ooh... Serviciul este inexistent sau link-ul este gresit',
                errorTx: `Acest serviciu <u>este inexistent</u>. Apasa butonul de mai jos 
                pentru a fi condus la pagina cu toate serviciile existente Foxstat SRL`,
                serviceExists: true
            })
            
        }
       
    }



    render(){

        return(

            <React.Fragment>
                <BrowserView>
                <div className="client-center">

                <div className="client-wrapper">

                    <div className="client-card">

                        <div className="card-wrapper">

                            <h4 
                                style={{
                                    margin: 0,
                                    marginTop: -20,
                                    color: '#455A64',
                                    fontSize: '16px',
                                }}
                                
                            >
                                {this.state.errorTitle}
                            </h4>

                            <p 
                                style={{
                                    margin:0,
                                    marginTop: 40,
                                    fontSize: '16px'
                                }}
                                dangerouslySetInnerHTML={{__html: this.state.errorTx}}
                            >
                                    {/* {this.state.errorTx} */}
                            </p>

                    
                        </div>

                        {
                            this.state.serviceExists && 

                            <Link to={"/" + this.props.match.params.company_web_link}>
                            <ButtonForm 
                                className="client-schedule-btn"
                                tx="Toate Serviciile"
                                style={{
                                    marginTop: 40,
                                    width: '20%'
                                }}
                                
                            />
                            </Link>
                        }

                    </div>

                </div> 

            </div>
            </BrowserView>

            <MobileView>

                {
                    this.state.serviceExists &&
    
                    <div className="m-client-header">

                        <div className="company-avatar-outer mobile">

                            <div className="company-avatar-inner">
                                <img src={CompanyAvatar} alt="Avatar companie" />
                            </div>

                        </div>

                        <h4
                            className="title"
                        >
                            Chameleon Coaching
                        </h4>

                    </div>
                    
                }

                <div className="m-client-services-card">

                    <p
                        style={{
                            margin: 0,
                            fontSize: 16,
                            color: '#777777'
                        }}
                    >
                       {this.state.errorTitle}
                    </p>
                    
                    <br /> <br />

                    <p
                        style={{
                            margin: 0,
                            fontSize: 16,
                        }}
                        dangerouslySetInnerHTML={{__html: this.state.errorTx}}
                    >
                       
                    </p>

                    {
                        this.state.serviceExists &&

                        <Link to={"/" + this.props.match.params.company_web_link}>
                        <ButtonForm 
                            className="client-schedule-btn"
                            tx="Toate Serviciile"
                            style={{
                                marginTop: 40,
                                width: '100%'
                            }}
                            
                        />
                        </Link>
                    }

                </div>

                <p
                    style={{
                        
                        paddingTop: 10,
                        paddingBottom: 20,
                        fontSize: 14,
                        color: '#455A64'
                    }}
                >
                    Programari prin  <a 
                                        href="#"
                                        style={{
                                            color: '#455A64'
                                        }}
                                    >
                                        Planary.ro
                                    </a>
                        
                </p>


            </MobileView>

        
        </React.Fragment>
        )
    }
}