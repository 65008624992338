import React from 'react';
import AppBar from '../../commons/AppBar';
import DashboardNav from '../../commons/DashboardNav';
import Declarations from '../../providers/Declarations';
import Calendar from './Calendar';
import MDetaliiServiciu from './mobile/MDetaliiServiciu';
import Servicii from './Servicii';
import Serviciu from './Serviciu';

import { RiFolderReduceFill } from 'react-icons/ri';

import {
    BrowserView,
    MobileView,

} from "react-device-detect";
import MServiceNav from '../../commons/MServiceNav';
import CalendarDirect from './CalendarDirect';
import Profil from './Profil';
import ServiceSchedule from './ServiceSchedule';
import Integrari from './Integrari';
import Echipa from './team/Echipa';
import Subscriptions from './subscriptions/Subscriptions';
import Security from './Security';
import PaymentHistory from './PaymentHistory';
import AppointmentsHistory from './AppointmentsHistory';
import Statistics from './statistics/Statistics';


export default class Dashboard extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            showCalendar: true,
            showServicii: false,
            showDetaliiServiciu: false,
            showCalendarAndOpen: false,
            showProfile: false,
            showEditSchedule: false,
            showIntegrari: false,
            showEchipa: false,
            showAbonamente: false,
            showSecuritate: false,
            showIstoricPlati: false,
            showIstoricProgramari: false,
            showRapoarte: false,
            prevScreen: ['showCalendar'],
            tokenServiciuSelectat: '',
            pathServiciuSelectat: '',
            durataPathServiciu: '',
            color: '',
            scheduleTk: '',
            componentsQueue: [],
            updateServiceList: false,

            showCalendarZIndex: 1,
            showServiciiZIndex: -1,
            showDetaliiServiciuZIndex: -1,
            showCalendarAndOpenZIndex: -1,
            showProfileZIndex: -1,
            showEditScheduleZIndex: -1,
        }
    }

    localData = {

        screenShowStates: ['showCalendar', 'showCalendarAndOpen', 'showDetaliiServiciu', 
        'showServicii', 'showEditSchedule', 'showProfile', 'showIntegrari', 'showEchipa',
        'showAbonamente', 'showSecuritate', 'showIstoricPlati', 'showIstoricProgramari',
        'showRapoarte'
        ]
    };

    componentDidMount(){

        if(this.props.match.params.trc){

            

            if(this.props.match.params.trc.length > 10){

                

                const prevScreen = this.onSetPrevScreen();

                this.setState({

                    showCalendar: true,
                    // showCalendarAndOpen: true,
                    prevScreen: prevScreen
                });
            }
        }
    }

    onShowCalendar = () => {

        const prevScreen = this.onSetPrevScreen();

        this.setState({

            showCalendar: true, //DISPLAYING THIS PAGE
            showServicii: false,
            showDetaliiServiciu: false,
            showCalendarAndOpen: false,
            showEditSchedule: false,
            showProfile: false,
            showAbonamente: false,
            showIntegrari: false,
            showEchipa: false,
            showSecuritate: false,
            showIstoricPlati: false,
            showIstoricProgramari: false,
            showRapoarte: false,
            prevScreen: prevScreen
        })

    }

    onShowServicii = () => {

        const prevScreen = this.onSetPrevScreen();

        this.setState({

            showCalendar: false,
            showDetaliiServiciu: false,
            showServicii: true, //DISPLAYING THIS PAGE
            showCalendarAndOpen: false,
            showEditSchedule: false,
            showProfile: false,
            showIntegrari: false,
            showEchipa: false,
            showAbonamente: false,
            showSecuritate: false,
            showIstoricPlati: false,
            showIstoricProgramari: false,
            showRapoarte: false,
            prevScreen: prevScreen
        })
    }

    onShowProfile = () =>{

        const prevScreen = this.onSetPrevScreen();

        this.setState({

            showCalendar: false,
            showDetaliiServiciu: false,
            showServicii: false,
            showEditSchedule: false,
            showCalendarAndOpen: false,
            showIntegrari: false,
            showProfile: true, //DISPLAYING THIS PAGE,
            showEchipa: false,
            showAbonamente: false,
            showSecuritate: false,
            showIstoricPlati: false,
            showIstoricProgramari: false,
            showRapoarte: false,
            prevScreen: prevScreen
        })
    }

    onShowDetaliiServiciu = (tk, path, time) => {


        // const listaDurata = new Declarations().listaDurata;

        // for(let i = 0; i < listaDurata.length; i++){

        //     if(listaDurata[i].val === )
        // }

        const prevScreen = this.onSetPrevScreen();
        this.setState({

            showCalendar: false,
            showServicii: false,
            showDetaliiServiciu: true, //DISPLAYING THIS PAGE
            showCalendarAndOpen: false,
            showEditSchedule: false,
            showIntegrari: false,
            showEchipa: false,
            showAbonamente: false,
            showSecuritate: false,
            showIstoricPlati: false,
            showIstoricProgramari: false,
            showRapoarte: false,
            tokenServiciuSelectat: tk,
            pathServiciuSelectat: path,
            durataPathServiciu: time ? time : '30 Minute',
            prevScreen: prevScreen
        })
    }

    onShowEditSchedule = (scheduleTk) => {

        const prevScreen = this.onSetPrevScreen();

        this.setState({

            showCalendar: false,
            showDetaliiServiciu: false,
            showServicii: false,
            showEditSchedule: true, //DISPLAYING THIS PAGE
            showCalendarAndOpen: false,
            showProfile: false,
            showIntegrari: false,
            showEchipa: false,
            showAbonamente: false,
            showSecuritate: false,
            showIstoricPlati: false,
            showIstoricProgramari: false,
            showRapoarte: false,
            scheduleTk: scheduleTk || '',
            prevScreen: prevScreen
        });
    }

    onShowIntegrari = () => {

        const prevScreen = this.onSetPrevScreen();

        this.setState({

            showIntegrari: true, //DISPLAYING THIS PAGE
            showCalendar: false,
            showDetaliiServiciu: false,
            showServicii: false,
            showEditSchedule: false,
            showCalendarAndOpen: false,
            showProfile: false,
            showEchipa: false,
            showAbonamente: false,
            showSecuritate: false,
            showIstoricPlati: false,
            showIstoricProgramari: false,
            showRapoarte: false,
            prevScreen: prevScreen
        });
    }

    onShowEchipa = () => {

        const prevScreen = this.onSetPrevScreen();

        this.setState({

            showIntegrari: false, 
            showCalendar: false,
            showDetaliiServiciu: false,
            showServicii: false,
            showEditSchedule: false,
            showCalendarAndOpen: false,
            showProfile: false,
            showAbonamente: false,
            showSecuritate: false,
            showIstoricPlati: false,
            showIstoricProgramari: false,
            showRapoarte: false,
            showEchipa: true, //DISPLAYING THIS PAGE
            prevScreen: prevScreen
        });
    }

    onShowAbonamente = () => {

        const prevScreen = this.onSetPrevScreen();

        this.setState({

            showIntegrari: false, 
            showCalendar: false,
            showDetaliiServiciu: false,
            showServicii: false,
            showEditSchedule: false,
            showCalendarAndOpen: false,
            showProfile: false,
            showAbonamente: true,  //DISPLAYING THIS PAGE
            showEchipa: false,
            showSecuritate: false,
            showIstoricPlati: false,
            showRapoarte: false,
            showIstoricProgramari: false,
            prevScreen: prevScreen
        });
    }

    onShowSecuritate = () => {

        const prevScreen = this.onSetPrevScreen();

        this.setState({

            showIntegrari: false, 
            showCalendar: false,
            showDetaliiServiciu: false,
            showServicii: false,
            showEditSchedule: false,
            showCalendarAndOpen: false,
            showProfile: false,
            showAbonamente: false,  
            showEchipa: false,
            showIstoricPlati: false,
            showIstoricProgramari: false,
            showRapoarte: false,
            showSecuritate: true, //DISPLAYING THIS PAGE
            prevScreen: prevScreen
        });
    }

    onShowIstoricPlati = () => {

        const prevScreen = this.onSetPrevScreen();

        this.setState({

            showIntegrari: false, 
            showCalendar: false,
            showDetaliiServiciu: false,
            showServicii: false,
            showEditSchedule: false,
            showCalendarAndOpen: false,
            showProfile: false,
            showAbonamente: false,  
            showEchipa: false,
            showIstoricPlati: true, //DISPLAYING THIS PAGE
            showSecuritate: false, 
            showIstoricProgramari: false,
            showRapoarte: false,
            prevScreen: prevScreen
        });
    }

    onShowIstoricProgramari = () => {

        const prevScreen = this.onSetPrevScreen();

        this.setState({

            showIntegrari: false, 
            showCalendar: false,
            showDetaliiServiciu: false,
            showServicii: false,
            showEditSchedule: false,
            showCalendarAndOpen: false,
            showProfile: false,
            showAbonamente: false,  
            showEchipa: false,
            showIstoricPlati: false, 
            showSecuritate: false, 
            showIstoricProgramari: true, //DISPLAYING THIS PAGE
            showRapoarte: false,
            prevScreen: prevScreen
        });
    }

    onShowRapoarte = () => {

        const prevScreen = this.onSetPrevScreen();

        this.setState({

            showIntegrari: false, 
            showCalendar: false,
            showDetaliiServiciu: false,
            showServicii: false,
            showEditSchedule: false,
            showCalendarAndOpen: false,
            showProfile: false,
            showAbonamente: false,  
            showEchipa: false,
            showIstoricPlati: false, 
            showSecuritate: false, 
            showIstoricProgramari: false, 
            showRapoarte: true, //DISPLAYING THIS PAGE
            prevScreen: prevScreen
        });
    }

    onChangePathServiciu = (path) => {

        this.setState({

            pathServiciuSelectat: path
        })
    }

    onChangePathDurata = (time) => {

        this.setState({

            durataPathServiciu: time
        })
    }

    moveElement = (array,initialIndex,finalIndex) => {

        array.splice(finalIndex,0,array.splice(initialIndex,1)[0])
        
        return array;
    }

    onSetPrevScreen = () => {

        let prevScreen = this.state.prevScreen;

        
        
        for(let i = 0; i < this.localData.screenShowStates.length; i++){

            const showStateName = this.localData.screenShowStates[i];

            if((this.state[showStateName] === true) ){  //( !prevScreen.includes(showStateName) )

                if( prevScreen.includes(showStateName) ){

                    // 
                    prevScreen = this.moveElement(prevScreen, prevScreen.indexOf(showStateName) ,prevScreen.length - 1);
                }
                else{

                    prevScreen.push(showStateName);
                }
                
            }   
            
        }

        

        return prevScreen;
        
    }

    onGoBackToPrevScreen = () => {

        let lastIdx = this.state.prevScreen.length - 1;
        let showScreens = this.localData.screenShowStates.filter(scrEl => scrEl !== this.state.prevScreen[lastIdx]);
     
        let popBackScreen = this.state.prevScreen;

        
        

        this.setState({

            [showScreens[0]]: false,
            [showScreens[1]]: false,
            [showScreens[2]]: false,
            [showScreens[3]]: false,
            [showScreens[4]]: false,
            [showScreens[5]]: false,
            [showScreens[6]]: false,
            [showScreens[7]]: false,
            [showScreens[8]]: false,
            [showScreens[9]]: false,
            [showScreens[10]]: false,
            [showScreens[11]]: false,
            [this.state.prevScreen[lastIdx]]: true,
            
        },
            () => {

                popBackScreen.pop();
                this.setState({

                    prevScreen: popBackScreen
                })
            }
        )
    }

    onUpdateServiceList = (canUpdate) => {

        this.setState({

            updateServiceList: canUpdate
        });
    }

    render(){

        return(
            
            <div className="rootable-page">
                
                <AppBar 
                    onShowProfile = {this.onShowProfile}
                    onShowEditSchedule = {this.onShowEditSchedule}
                    onShowIntegrari = {this.onShowIntegrari}
                    onShowEchipa = {this.onShowEchipa}
                    onShowAbonamente = {this.onShowAbonamente}
                    onShowSecuritate = {this.onShowSecuritate}
                    onShowIstoricPlati = {this.onShowIstoricPlati}
                    onShowIstoricProgramari = {this.onShowIstoricProgramari}
                    onShowRapoarte = {this.onShowRapoarte}
                /> 
                
                <DashboardNav 
                    onShowCalendar={this.onShowCalendar}
                    onShowServicii={this.onShowServicii}
                    goAddServiciu={this.onShowDetaliiServiciu}
                    isCalendarActive={this.state.showCalendar}
                    isProfileShown={this.state.showProfile}
                    showEditSchedule={this.state.showEditSchedule}
                    showCalendarAndOpen={this.state.showCalendarAndOpen}
                    isServiciiActive={this.state.showServicii}
                    showIntegrari = {this.state.showIntegrari}
                    showEchipa = {this.state.showEchipa}
                    showSecuritate = {this.state.showSecuritate}
                    showAbonamente = {this.state.showAbonamente}
                    showIstoricPlati = {this.state.showIstoricPlati}
                    showIstoricProgramari = {this.state.showIstoricProgramari}
                    onShowDetaliiServiciu={this.state.showDetaliiServiciu}
                    showRapoarte = {this.state.showRapoarte}
                    pathServiciu={this.state.pathServiciuSelectat}
                    pathDurata={this.state.durataPathServiciu}
                    onGoBackToPrevScreen={this.onGoBackToPrevScreen}
                />    

                <div
                    style={{
                        display: this.state.showEditSchedule ? 'block' : 'none'
                    }}
                >

                    <ServiceSchedule 
                        scheduleTk={this.state.scheduleTk}
                    />

                </div>


                <div
                    style={{
                        display: this.state.showCalendar ? 'block' : 'none'
                    }}
                >

                    <Calendar 
                        trc = {this.props.match.params.trc}
                        updateServiceList = {this.state.updateServiceList}
                        onUpdateServiceList = {this.onUpdateServiceList}
                    />

                </div>

                {/* <div
                    style={{
                        display: this.state.showCalendarAndOpen ? 'block' : 'none'
                    }}
                >

                    <CalendarDirect 
                        trc = {this.props.match.params.trc}
                        updateServiceList = {this.state.updateServiceList}
                        onUpdateServiceList = {this.onUpdateServiceList}
                    />

                </div> */}

                <div
                    style={{
                        display: this.state.showServicii ? 'block' : 'none'
                    }}
                >
                    <Servicii 
                        goDetaliiServiciu={this.onShowDetaliiServiciu}
                        goMServiciu={this.onShoWMServiciu}
                        onUpdateServiceList = {this.onUpdateServiceList}
                        updateServiceList = {this.state.updateServiceList}
                    />
                    
                </div>

                <div
                    style={{
                        display: this.state.showDetaliiServiciu ? 'block' : 'none'
                    }}
                >
                    <Serviciu 
                        onShowEditSchedule = {this.onShowEditSchedule}
                        tokenServiciuSelectat={this.state.tokenServiciuSelectat}
                        onChangePathServiciu={this.onChangePathServiciu}
                        onChangePathDurata={this.onChangePathDurata}
                        onShowServicii={this.onShowServicii}
                        onUpdateServiceList = {this.onUpdateServiceList}
                    />
                    
                </div>

                <div
                    style={{
                        display: this.state.showProfile ? 'block' : 'none'
                    }}
                >

                    <Profil />

                </div>

                <div
                    style={{
                        display: this.state.showIntegrari ? 'block' : 'none'
                    }}
                >

                    <Integrari />

                </div>

                <div
                    style={{
                        display: this.state.showEchipa ? 'block' : 'none'
                    }}
                >

                    <Echipa />

                </div>

                <div
                    style={{
                        display: this.state.showAbonamente ? 'block' : 'none'
                    }}
                >

                    <Subscriptions />

                </div>

                <div
                    style={{
                        display: this.state.showSecuritate ? 'block' : 'none'
                    }}
                >

                    <Security />

                </div>

                <div
                    style={{
                        display: this.state.showIstoricPlati ? 'block' : 'none'
                    }}
                >

                    <PaymentHistory />

                </div>

                <div
                    style={{
                        display: this.state.showIstoricProgramari ? 'block' : 'none'
                    }}
                >

                    <AppointmentsHistory 
                        onShowRapoarte = {this.onShowRapoarte}
                    />

                </div>

                <div
                    style={{
                        display: this.state.showRapoarte ? 'block' : 'none'
                    }}
                >

                    <Statistics />

                </div>


                {/* {
                    this.state.showEditSchedule && (

                        <ServiceSchedule 
                            scheduleTk={this.state.scheduleTk}
                        />
                    )
                }

                {
                    this.state.showCalendarAndOpen && (

                        <CalendarDirect 
                            trc = {this.props.match.params.trc}
                        />
                    )
                }

                {   
                    this.state.showCalendar && (

                        <Calendar />
                    )
                }

                {
                    this.state.showServicii && (

                        <Servicii 
                            goDetaliiServiciu={this.onShowDetaliiServiciu}
                            goMServiciu={this.onShoWMServiciu}
                        />
                    )
                }

                {

                    this.state.showDetaliiServiciu && (

                        <Serviciu 
                            onShowEditSchedule = {this.onShowEditSchedule}
                            tokenServiciuSelectat={this.state.tokenServiciuSelectat}
                            onChangePathServiciu={this.onChangePathServiciu}
                            onChangePathDurata={this.onChangePathDurata}
                            onShowServicii={this.onShowServicii}
                        />
                    )
                    
                }

                {
                    this.state.showProfile && (

                        <Profil />
                    )   
                }   */}

                {/* {
                    this.state.showMServiciu && 
                    <MDetaliiServiciu 
                        tokenServiciuSelectat={this.state.tokenServiciuSelectat}
                        onShowServicii={this.onShowServicii}
                    />

                }
            */}

            </div>
        );
    }
}