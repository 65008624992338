import React from 'react';
import ScheduleManagerFuncs from '../../module/logic/ScheduleManagerFuncs';
import Masks from '../../providers/Masks';
import Utils from '../../providers/Utils';
import Ws from '../../providers/Ws';
import ScheduleInterval from '../ScheduleInterval';

export default class ScheduleConfig extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            interval: this.SMF.setIntervalOrar(),
            weekDays: this.SMF.setScheduleWeekDays(),

            luStart: '08:00:00',
            luEnd: '20:00:00',
            maStart: '08:00:00',
            maEnd: '20:00:00',
            miStart: '08:00:00',
            miEnd: '20:00:00',
            joStart: '08:00:00',
            joEnd: '20:00:00',
            viStart: '08:00:00',
            viEnd: '20:00:00',
            saStart: null,
            saEnd: null,
            duStart: null,
            duEnd: null,

            luStartTmp: '08:00:00',
            luEndTmp: '20:00:00',
            maStartTmp: '08:00:00',
            maEndTmp: '20:00:00',
            miStartTmp: '08:00:00',
            miEndTmp: '20:00:00',
            joStartTmp: '08:00:00',
            joEndTmp: '20:00:00',
            viStartTmp: '08:00:00',
            viEndTmp: '20:00:00',
            saStartTmp: null,
            saEndTmp: null,
            duStartTmp: null,
            duEndTmp: null,
        };
    }

    SMF = new ScheduleManagerFuncs();
    WS = new Ws();
    UT = new Utils();
    M = new Masks();

    componentDidMount(){

        if(this.props.selectedSchedule){

            if(Object.keys(this.props.selectedSchedule).length > 0){

                this.onSetSelectedSchedule();
            }
        }
    }

    componentDidUpdate(prevProps, _){

        if(prevProps['selectedSchedule'] !== this.props['selectedSchedule'] ||
            prevProps['triggerSave'] !== this.props['triggerSave']
        ){

            if(Object.keys(this.props.selectedSchedule).length > 0){

                this.onSetSelectedSchedule();
            }
            // else{

            //     this.onResetScheduleInputs();
            // }
        }
    }   

    onSetSelectedSchedule = () => {

        this.setState({

            luStart: this.props.selectedSchedule[0]['lu_start'],
            luEnd: this.props.selectedSchedule[0]['lu_end'],
            maStart: this.props.selectedSchedule[0]['ma_start'],
            maEnd: this.props.selectedSchedule[0]['ma_end'],
            miStart: this.props.selectedSchedule[0]['mi_start'],
            miEnd: this.props.selectedSchedule[0]['mi_end'],
            joStart: this.props.selectedSchedule[0]['jo_start'],
            joEnd: this.props.selectedSchedule[0]['jo_end'],
            viStart: this.props.selectedSchedule[0]['vi_start'],
            viEnd: this.props.selectedSchedule[0]['vi_end'],
            saStart: this.props.selectedSchedule[0]['sa_start'],
            saEnd: this.props.selectedSchedule[0]['sa_end'],
            duStart: this.props.selectedSchedule[0]['du_start'],
            duEnd: this.props.selectedSchedule[0]['du_end'],

            luStartTmp: this.props.selectedSchedule[0]['lu_start'],
            luEndTmp: this.props.selectedSchedule[0]['lu_end'],
            maStartTmp: this.props.selectedSchedule[0]['ma_start'],
            maEndTmp: this.props.selectedSchedule[0]['ma_end'],
            miStartTmp: this.props.selectedSchedule[0]['mi_start'],
            miEndTmp: this.props.selectedSchedule[0]['mi_end'],
            joStartTmp: this.props.selectedSchedule[0]['jo_start'],
            joEndTmp: this.props.selectedSchedule[0]['jo_end'],
            viStartTmp: this.props.selectedSchedule[0]['vi_start'],
            viEndTmp: this.props.selectedSchedule[0]['vi_end'],
            saStartTmp: this.props.selectedSchedule[0]['sa_start'],
            saEndTmp: this.props.selectedSchedule[0]['sa_end'],
            duStartTmp: this.props.selectedSchedule[0]['du_start'],
            duEndTmp: this.props.selectedSchedule[0]['du_end'],
        });
    }

    onTriggerResetSchedule = () => {

        this.setState({

            luStart: this.state.luStartTmp,
            luEnd: this.state.luEndTmp,
            maStart: this.state.maStartTmp,
            maEnd: this.state.maEndTmp,
            miStart: this.state.miStartTmp,
            miEnd: this.state.miEndTmp,
            joStart: this.state.joStartTmp,
            joEnd: this.state.joEndTmp,
            viStart: this.state.viStartTmp,
            viEnd: this.state.viEndTmp,
            saStart: this.state.saStartTmp,
            saEnd: this.state.saEndTmp,
            duStart: this.state.duStartTmp,
            duEnd: this.state.duEndTmp,
        },
            () => {

                if(this.props.resetStatus){

                    this.props.resetStatus();
                }  
            }
        )
    }

    onChangeScheduleHourStart = (ev) => {

        let endHour = this.state[`${ev.target.name.substr(0,2)}End`];
        let doSave = true;

        if(ev.target.value === 'Indisp'){

            endHour = null;
        }
        else if((endHour === null || endHour === 'Indisp') && ev.target.value !== 'Indisp'){

            endHour = '20:00:00';
        }

        if(ev.target.value === (this.state[ev.target.name + 'Tmp'] || 'Indisp') ){

            doSave = false;
        }

        if(endHour !== null && ev.target.value !== null){

            if(this.UT.convertToTime(ev.target.value).getTime() >= this.UT.convertToTime(endHour).getTime() ){

                const errorMsg = 'Ora de inceput trebuie sa fie mai mica ca ora de sfarsit!';

                this.props.showToastError(errorMsg);
    
                return;
            }
        }

        this.setState({

            [ev.target.name]: ev.target.value,
            [`${ev.target.name.substr(0,2)}End`]: endHour,
        },
        
            () => {

                this.props.inputStatus(doSave, `${ev.target.name.substr(0,2)}StStatus`, ev.target.name, ev.target.value);
                this.props.inputStatus(doSave, `${ev.target.name.substr(0,2)}EndStatus`, `${ev.target.name.substr(0,2)}End`, endHour);
            }
        );
    }

    onChangeScheduleHourEnd = (ev) => {

        let startHour = this.state[`${ev.target.name.substr(0,2)}Start`];
        let doSave = true;

        if(ev.target.value === 'Indisp'){

            startHour = null;
        }
        else if((startHour === null || startHour === 'Indisp') && ev.target.value !== 'Indisp'){

            startHour = '08:00:00';
        }

        if(ev.target.value === (this.state[ev.target.name + 'Tmp'] || 'Indisp') ){

            doSave = false;
        }

        if(startHour !== null && ev.target.value !== null){

            if(this.UT.convertToTime(ev.target.value).getTime() <= this.UT.convertToTime(startHour).getTime() ){

                const errorMsg = 'Ora de sfarsit trebuie sa fie mai mare ca ora de inceput!';

                this.props.showToastError(errorMsg);
    
                return;
            }

            // if(startHour === 'Indisp'){

            //     startHour = '08:00:00';
            // }
        }

        /* daca intervalul de date sunt completate/salvate si modific data de inceput, nu apare save button */
       
        this.setState({

            [ev.target.name]: ev.target.value,
            [`${ev.target.name.substr(0,2)}Start`]: startHour,
        },
        
            () => {

                this.props.inputStatus(doSave, `${ev.target.name.substr(0,2)}EndStatus`, ev.target.name, ev.target.value);
                this.props.inputStatus(doSave, `${ev.target.name.substr(0,2)}StStatus`, `${ev.target.name.substr(0,2)}Start`, startHour);
            }
        );
    }

    onCheckAndSave = () => {

        this.props.onCheckAndSave(false)
    }

    render(){

        return(

            <div>

                <ScheduleInterval 
                                
                    weekDays = {this.state.weekDays}
                    interval = {this.state.interval}
                    onChangeScheduleHourStart = {this.onChangeScheduleHourStart}
                    onChangeScheduleHourEnd = {this.onChangeScheduleHourEnd}
                    days = {

                        {
                            luStart: this.state.luStart,
                            luEnd: this.state.luEnd,
                            maStart: this.state.maStart,
                            maEnd: this.state.maEnd,
                            miStart: this.state.miStart,
                            miEnd: this.state.miEnd,
                            joStart: this.state.joStart,
                            joEnd: this.state.joEnd,
                            viStart: this.state.viStart,
                            viEnd: this.state.viEnd,
                            saStart: this.state.saStart,
                            saEnd: this.state.saEnd,
                            duStart: this.state.duStart,
                            duEnd: this.state.duEnd,
                        }
                    }
                />

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                >
                    <p
                        style={{
                            margin: 0,
                            marginRight: '6px',
                            fontSize: '16px',
                            textAlign: 'center',
                            fontWeight: '400',
                            color: '#44A5FF',
                            cursor: 'pointer'
                        }}
                        onClick={
                            () => {

                                this.onTriggerResetSchedule(false)
                            }
                        }
                    >
                        Reseteaza
                    </p>

                    {
                        this.props.showSave && (

                            <p
                                style={{
                                    margin: 0,
                                    marginLeft: '6px',
                                    fontSize: '16px',
                                    textAlign: 'center',
                                    fontWeight: '400',
                                    color: '#44A5FF',
                                    cursor: 'pointer'
                                }}
                                onClick={
                                    () => {

                                        this.onCheckAndSave(false)
                                    }
                                }
                            >
                                Salveaza
                            </p>
                        )
                    }

                </div>

            </div>
        )
    }
}