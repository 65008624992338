import React from 'react';
import { BrowserView } from 'react-device-detect';

import AccountCircleIcon from '../../assets/account_circle.svg';
import AccountSupervisorIcon from '../../assets/supervisor_account.svg';
import HistoryIcon from '../../assets/history.svg';
import AnalyticsIcon from '../../assets/analytics.svg';
import FurnitureImg from '../../assets/Dulap Perete Gol.png';
import BusinessCenterIcon from '../../assets/business_center.svg';
import SecurityIcon from '../../assets/security.svg';
import IntegrationsIcon from '../../assets/settings_input_component.svg';
import HelpIcon from '../../assets/support.svg';
import LogoutIcon from '../../assets/power_settings_new.svg';
import ZoomIcon from '../../assets/Zoom Icon.svg';
import BusinessCenterIconGray from '../../assets/business_center_ gray.svg';
import { RiArrowUpSFill } from 'react-icons/ri';
import NotificationContainer from './NotificationContainer';
import NotificationsFuncs from './NotificationsFuncs';
import Masks from '../../providers/Masks';

export default class Notifications extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            notifications: [],
            erorrTx: ''
        }
    }

    M = new Masks();
    NF = new NotificationsFuncs();

    componentDidMount(){

        // this.getNotifications();
    }

    getNotifications = async () => {

        const notifications = await this.NF.selectAccountNotifications();

        if(notifications.err === null){

            // if(notifications.data.length > 0){

            //     const seenNotifications = notifications.data.filter(nEl => nEl['is_seen'])
            // }

            this.setState({

                notifications: notifications.data
            });
        }
        else{

            this.setState({

                errorTx: notifications.err
            });
        }
    }

    render(){

        return(

            <BrowserView>
        
                <div 
                    className="calendar-popup-card"
                    style={{
                        position: 'absolute',
                        top: 100,
                        right: 8,
                        width: '400px',
                        // padding: '20px',
                        padding: '6px 6px 6px 0px',
                        borderRadius: '10px 0px 10px 10px',
                        // overflowY: 'auto'
                    }}
                >
                    <RiArrowUpSFill 
                        style={{
                            position: 'absolute',
                            // margin: 0,
                            top: -23,
                            right: 30,
                            fontSize: '40px',
                            color: '#fff',
                        }}
                    />

                    <div
                        style={{
                            maxHeight: '300px',
                            overflowY: 'auto',
                            padding: '20px',
                        }}
                    >

                        {
                            this.props.errorTx.length === 0 &&
                            this.props.notifications.length > 0 && 
                            this.props.notifications.map( (nEl, idx) => {

                                const notificationData = this.NF.getNotificationType(nEl['tip']);
                                const time = this.NF.getNotificationTime(this.NF.convertSqlDateWithTimeToJSDate(nEl['cand']));

                                return (

                                    <div key = {`notificare-${idx}`}>
                                        <NotificationContainer 
                                            
                                            type = {notificationData['type']}
                                            iconType = {notificationData['iconType']}
                                            title = {notificationData['title']}
                                            message = {nEl['mesaj']}
                                            time = {time}
                                        />

                                        {
                                            this.props.notifications.length > 0 &&
                                            this.props.notifications.length - 1 > idx && (

                                                <div
                                                    style={{
                                                        marginTop: 6,
                                                        marginBottom: 10,
                                                        width: '100%',
                                                        height: '1px',
                                                        background: '#EBEBEB'
                                                    }}
                                                >
                                                </div> 
                                            )
                                        }
                                        
                                    </div>
                                )

                            })
                        }

                        {   
                            this.props.errorTx.length === 0 &&
                            this.props.notifications.length === 0 && (

                                <p
                                    style={{
                                        margin: 0,
                                        textAlign: 'center',
                                        fontSize: '16px',
                                        fontWeight: 400,
                                        color: '#263238'
                                    }}
                                >
                                    Nu ai nici o notifcare.
                                </p>
                            )
                        }

                        {
                            this.props.errorTx.length > 0 && (

                                <p
                                    style={{
                                        margin: 0,
                                        textAlign: 'center',
                                        fontSize: '16px',
                                        fontWeight: 400,
                                        color: '#DD6A57'
                                    }}
                                >
                                    {this.props.errorTx}
                                </p>
                            )
                        }


                    </div>
                    
                    
                </div>


            </BrowserView>
        )
    }
}