import React from "react";

import { BiItalic } from 'react-icons/bi';
import { AiOutlineUnderline } from 'react-icons/ai';
import { BsTypeBold } from 'react-icons/bs';

export default class TextareaDescription extends React.Component{

    constructor(props){

        super(props);
    }

    onChangeToBold = () => {

        let text = document.getElementById(this.props.id).textContent
        let selection = window.getSelection();
        let boldText = "<b>" + selection + "</b>"
        document.getElementById(this.props.id).innerHTML = text.replace(selection, boldText) //use innerhtml instead of innertext
    }

    render(){

        const classes = 'service-description' + ' ' + this.props.className; 

        return(

            <div>
                <div style={{position: 'relative'}}>
                
                    {/* <textarea
                        // contentEditable
                        className={classes}
                        onChange={this.props.onChange}
                        value={this.props.value}
                        style={this.props.style}
                        placeholder={this.props.placeholder}
                    >

                    </textarea> */}

                    <div
                        className={classes}
                        style={this.props.style}
                    >

                        <textarea
                            id={this.props.id}
                            onChange={this.props.onChange}
                            value={this.props.value}
                            placeholder={this.props.placeholder}
                            style={{
                                position: 'relative',
                                width: '100%',
                                height: '100%',
                                overflow: 'hidden',
                                outline: 'none',
                                border: 'none',
                                resize: 'none',
                                background: 'none',
                                fontSize: '16px',
                                fontFamily: 'Arial, Helvetica, sans-serif'
                            }}
                        >
                        </textarea>
                       
                    </div>
                    
                    
                    {/* <BsTypeBold 
                        className="service-description-icon bold-icon" 
                        onClick={this.onChangeToBold}
                    />
                    <BiItalic className="service-description-icon italic-icon" />
                    <AiOutlineUnderline className="service-description-icon underline-icon" /> */}
                    
                </div>

                <p 
                    className="service-description-limit"
                    style={{
                        textAlign: 'left',
                        marginTop: -2
                    }}
                >
                    {this.props.textLenght}/500 Caractere
                </p>
                

            </div>
        );
    }
}