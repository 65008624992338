import React from 'react';

import { HiCurrencyDollar } from 'react-icons/hi';
import { ImLocation2 } from 'react-icons/im';
import { MdAlarm } from 'react-icons/md';
import { RiCalendar2Fill } from 'react-icons/ri';
import { ReactComponent  as GMeetIcon} from '../../../assets/Google_Meet-Logo.wine.svg';
import {ReactComponent as ZoomIcon } from '../../../assets/Zoom Icon.svg';
import { ReactComponent as LocIcon } from '../../../assets/pin_drop.svg';
import { FaSkype } from 'react-icons/fa';

import Masks from '../../../providers/Masks';
import Utils from '../../../providers/Utils';
import Ws from '../../../providers/Ws';
import Declarations from '../../../providers/Declarations';
import HourInterval from '../../../commons/HourInterval';
import IconText from '../../../commons/IconText';
import LocationDesc from '../../../commons/LocationDesc';

export default class CrAppoInfo extends React.Component{
    
    constructor(props){

        super(props);
    }

    WS = new Ws();
    UT = new Utils();
    M = new Masks();

    render(){

        return(

            <div>

                <p
                    style={this.props.titleStyle}
                >
                    {this.props.serviceName}
                </p>

                <br />

                <div>

                    <p
                        style={{
                            display: 'inline-block',
                            position: 'relative',
                            top: 6,
                            margin: 0,
                            fontSize: '22px',
                            color: '#31A81D'
                        }}
                    >
                        <RiCalendar2Fill />
                    </p>

                    <p
                        style={{
                            display: 'inline-block',
                            position: 'relative',
                            margin: 0,
                            fontWeight: '400',
                            fontSize: '14px',
                            color: '#31A81D'
                        }}
                    >
                        &nbsp; {


                            this.props.date && (

                                this.props.hour.substr(0,5) + '-' + 
                                this.UT.addMinutes(this.UT.convertToTime( this.props.hour.substr(0,5)), this.props.duration).toTimeString().substr(0,5)
                                + ', ' +
                                this.M.dayOfWeek(this.M.sqlDateToJSDate(this.props.date).getDay()) + ' ' +
                                this.M.sqlDateToJSDate(this.props.date).getDate() + ' ' +
                                this.M.monthName(this.M.sqlDateToJSDate(this.props.date).getMonth()).substr(0,3) 
                            )
                        } 
                            
                    </p>

                </div>

                {/* <div
                    style={{
                        marginTop: 5
                    }}
                >

                    <p
                        style={{
                            display: 'inline-block',
                            position: 'relative',
                            top: 4,
                            margin: 0,
                            fontSize: '21px',
                            color: '#777777'
                        }}
                    >
                        <MdAlarm />
                    </p>

                    <p
                        style={{
                            display: 'inline-block',
                            position: 'relative',
                            margin: 0,
                            fontSize: '14px',
                            color: '#455A64'
                        }}
                    >
                        &nbsp; {this.props.duration} Minute
                    </p>

                </div> */}

                <HourInterval 
                    durata = {this.props.duration}
                    justifyContent = 'none'
                    marginTop = '5px'
                    iconSize = '21px'
                    iconCol = '#777777'
                    topIcon = '4px'
                    txSize = '14px'
                    txCol = '#455A64'
                    topTx = '2px'
                    leftTx = '8px'
                />
{/* 
                <div
                    style={{
                        marginTop: 5
                    }}
                >

                    <p
                        style={{
                            display: 'inline-block',
                            position: 'relative',
                            top: 4,
                            margin: 0,
                            fontSize: '21px',
                            color: +this.props.price === 0 ? '#60D84C' : '#777777',
                        }}
                    >
                        <HiCurrencyDollar />
                    </p>

                    <p
                        style={{
                            display: 'inline-block',
                            position: 'relative',
                            top: -1,
                            margin: 0,
                            fontSize: '14px',
                            color: +this.props.price === 0 ? '#60D84C' : '#455A64'
                        }}
                    >
                        &nbsp;  {+this.props.price === 0 ? 'Fara Tarif' : this.props.price + ' Lei'}
                    </p>

                </div> */}

                <IconText 
                    icon = {

                        <HiCurrencyDollar 
                            style={{
                                margin: 0,
                                color: +this.props.price === 0 ? '#60D84C' : '#777777',
                                fontSize: '22px'
                            }}
                        /> 
                    }
                    
                    tx = {+this.props.price === 0 ? 'Fara Tarif' : this.props.price + ' Lei'}
                    containerTop = '5px'
                    txCol = {+this.props.price === 0 ? '#60D84C' : '#455A64'}
                    txSize = '14px'
                />

                {/* <div
                    style={{
                        marginTop: 5
                    }}
                >

                    <p
                        style={{
                            display: 'inline-block',
                            position: 'relative',
                            top: 4,
                            margin: 0,
                            fontSize: '21px',
                            color: '#777777'
                        }}
                    >
                        {
                            (this.props.location || '').trim() === 'zoom' && (

                                <ZoomIcon 
                                    style={{
                                        position: 'relative',
                                        top: 2,
                                    }}
                                />
                            )
                        }

                        {
                            (this.props.location || '').trim() === 'skype' && (

                                <FaSkype /> 
                            )
                        }

                        {
                            (this.props.location || '').trim() === 'office' && (

                                <LocIcon 
                                    className="loc-icon-dark"
                                    style={{
                                        position: 'relative',
                                        left: '-2px'
                                    }}
                                />
                            )
                        }

                        {
                            (this.props.location || '').trim() === 'gmeet' && (

                                <GMeetIcon 
                                    className="gmeet-services"
                                        style={{
                                            position: 'relative',
                                            width: '24px',
                                            height: '24px'
                                        }}
                                />
                            )
                        }  

                    </p>
                        
                

                    <p
                        style={{
                            display: 'inline-block',
                            position: 'relative',
                            top: -2,
                            margin: 0,
                            fontSize: '14px',
                            color: '#455A64',
                            
                        }}
                    >
                        &nbsp; {this.props.locationName}
                    </p>

                </div> */}

                <LocationDesc 
                    location = {(this.props['location'] || '').trim()}
                    locationDesc = { this.props.locationName }
                    // distance = '22px'
                    iconCls = 'loc-icon-dark'
                    marginTop = '5px'
                    zoomW = '20px' zoomH = '10px'
                    officeW = '22px' officeH = '22px'       
                    googleW = '24px' googleH = '24px' 
                    skypeSize = '22px'  
                    leftOfffice = '-1px'               
                    txSize = '14px'
                />

            </div>
        )
    }
}