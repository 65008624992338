import React from 'react';

import { IoIosArrowForward, IoIosArrowBack, IoMdThumbsDown } from 'react-icons/io';
import { MdAlarm, MdPayment, MdClear, MdStayPrimaryPortrait, MdThumbDown, MdThumbUp } from 'react-icons/md';
import { IoVideocam } from 'react-icons/io5';
import { FaSkype, FaRegMoneyBillAlt } from 'react-icons/fa';
import { ImLocation2 } from 'react-icons/im';
import { ImPlus } from 'react-icons/im';
import { AiOutlineMail } from 'react-icons/ai';

import CompanyAvatar from '../../assets/foxStat.jpeg';
import { ReactComponent  as GMeetIcon} from '../../assets/Google_Meet-Logo.wine.svg';
import { ReactComponent  as ArticleIcon} from '../../assets/articleIcon.svg';
import { ReactComponent as ZoomIcon } from '../../assets/zoom_icon.svg';
import { ReactComponent as LocIcon } from '../../assets/pin_drop.svg';

import Declarations from '../../providers/Declarations';
import Masks from '../../providers/Masks';
import Utils from '../../providers/Utils';
import Ws from '../../providers/Ws';
import HourInterval from '../../commons/HourInterval';
import LocationDesc from '../../commons/LocationDesc';
import IconText from '../../commons/IconText';
import PopupAppointElem from '../../commons/PopupAppointElem';
import PopupAppointDetails from '../../commons/PopupAppointDetails';
import { BrowserView, MobileView } from 'react-device-detect';
import LocalStorage from '../../providers/LocalStorage';

export default class CalAppointsPopup extends React.Component{

    constructor(props){
        
        super(props)

        this.state ={

            currentPayment: {},
            lunaCurenta: '',
            ziCurenta: '',
            currentAppoint: {},
            users: [],
            currentAppointToken: '',
            currentServiceColor: '',
            appointLocation: '',
            appointDateEnd: null,
            meetingId: '',
            meetingPass: '',
            showAppointsList: true,
            showAppoint: false,
            member: []
        }
    }

    WS = new Ws();
    M = new Masks();
    UT = new Utils();

    localData = {

        listaLocatii: new Declarations().listaLocatiiIntalnire,
        listaLuniAn: new Declarations().listaLuni,
        listaCulori: new Declarations().listaCulori
    }
    
    componentDidMount(){
        
        this.setLunaZiCurenta();
        this.setUsers();

        if(this.props.tokenAppointClicked){

            if(this.props.tokenAppointClicked.length > 10){

                this.onShowCurrentAppointModal(this.props.tokenAppointClicked)
                // this.getMeetingData()
            }
        }
        
    }

    setUsers = async () => {

        const accountToken = new LocalStorage().GetAccountToken();
        const q = `select * from users where account_id = '${accountToken}' `;
        let users = await this.WS.sqlCommand(`select`, q);

        this.setState({

            users: users.content
        });
    }

    getMeetingData = async (token) => {

        const tokenClicked = this.props.tokenAppointClicked || token;

        let selectMeetingData = `select * from trc_links where trc_token = '${tokenClicked}'    `;
        // 
        let selectPayment = `select * from trc_payments where trc_token = '${tokenClicked}'  `;

        let meetingData = await this.WS.sqlCommand(`select`, selectMeetingData);

        let meetingIdPlatform = "";

        let meetingPassPlatform = "";

        if(meetingData.content.length > 0 && (meetingData.content[0]['zoom_meeting_id'] !== null) ){

            meetingIdPlatform = meetingData.content[0]['zoom_meeting_id'].substr(0,3) + " " + meetingData.content[0]['zoom_meeting_id'].substr(3,4) 
            + " " + meetingData.content[0]['zoom_meeting_id'].substr(7,9);

            meetingPassPlatform = meetingData.content[0]['zoom_password'];
        }


        if(meetingData.success){

            this.setState({

                meetingId: meetingIdPlatform,
                meetingPass: meetingPassPlatform,

            });
        }
        else{

            throw new Error("Meeting error response: " + meetingData.message );
        }
    }

    setLunaZiCurenta = () => {

       const lunaCurentaAppoint = this.localData.listaLuniAn.filter(llEl => llEl.idx === this.props.popupData.getMonth())
       
        this.setState({
            lunaCurenta: lunaCurentaAppoint[0].luna,
            ziCurenta: this.M.dayOfWeek(this.props.popupData.getDay()),
        })
    }

    onClosePopup = (ev) => {

        if( ev.target.id === 'modal-popup' ||  ev.target.id === 'close-modal-btn'){

            this.props.onCloseAppointsPopup()
        }
        
    }


    onShowCurrentAppointModal = async (token) => {

       let selectedAppoint = this.props.appointmentList.filter(aEl => aEl.token === token)
       
       const selectedAppointService = this.props.serviceList.filter(saEl => saEl.token === selectedAppoint[0]['service_tk'])
       const culoareServiciu = this.localData.listaCulori.filter(cEl => cEl.val === selectedAppointService[0].color)

       const locatieCurenta = this.localData.listaLocatii.filter(lEl => lEl.id === selectedAppoint[0].location);
       let tokenForPayment = token;

        const accountToken = new LocalStorage().GetAccountToken();
        const q = `select * from users where account_id = '${accountToken}' `;
        let users = await this.WS.sqlCommand(`select`, q);

       if((selectedAppoint[0]['replaned_trc_tk'] || '').length > 10){

            tokenForPayment = selectedAppoint[0]['replaned_trc_tk'];
       }

       const paymentTrc = this.props.payments.filter(pEl => {

            if( (pEl['trc_token'] === tokenForPayment) && (+pEl.succes === 1) ){

                return pEl;
            }
       });

       selectedAppoint = selectedAppoint.map(sEl => {

            return (

                {
                    ...sEl,
                    paymentSuccess: paymentTrc.length > 0 ? paymentTrc[0].succes : 0
                }
            )
       });
       
        this.setState({

            showAppoint: true,
            showAppointsList: false,
            currentAppoint: selectedAppoint[0],
            currentServiceColor: culoareServiciu[0].col,
            appointLocation: locatieCurenta[0],
            appointDateEnd: this.UT.setDisponibility(selectedAppointService[0]),
            member: users.content.filter( (member) => member.token === selectedAppoint[0].user_asignat_token)
            // currentPayment: paymentTrc[0]
        })

        await this.getMeetingData(token);
    }

    onGoBackToAppoints = () => {

        this.setState({

            showAppoint: false,
            showAppointsList: true
        })
    }

    onCreateDayAppointment = () => {

        this.props.onCreateDayAppointment(this.props.popupData);
    }

    render(){

        return(
            <>

            <BrowserView>

            <div 
                id="modal-popup"
                className="calendar-modal-container"
                onClick={(ev) => {this.onClosePopup(ev)}}
            >

                <div 
                    className="calendar-popup-card"
                    style={{
                        paddingBottom: '40px'
                    }}
                >
                    
                    <MdClear 
                        id="close-modal-btn"
                        style={{
                            position: 'absolute',
                            top: 10,
                            right: 10,
                            fontSize: '24px',
                            color: '#323232',
                            cursor: 'pointer'
                        }}

                        onClick={(ev) => {this.onClosePopup(ev)}}
                    />

                    <div 
                        style={{
                            position: 'absolute',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            left: 105,
                            width: '1px',
                            height: '90%',
                            background: '#EBEBEB'
                        }}
                    >
                    </div>

                    <div 
                        className="row"
                    >

                        <div 
                            className="col-l-2 col-m-2 col-s-2"
                            // style={{
                            //     height: this.state.showAppointsList ? '600px' : '384px',
                            //     borderRight: '1px solid #EBEBEB',
                            // }}
                        >

                            <h5
                                style={{
                                    margin: 0,
                                    marginTop: 20,
                                    fontSize: '14px',
                                    textAlign: 'center'
                                }}
                            >
                                {this.state.ziCurenta}
                            </h5>

                            <h1
                                style={{
                                    margin: 0,
                                    fontSize: '50px',
                                    textAlign: 'center',
                                    color: '#A6A6A6'
                                }}
                            >
                                {this.props.popupData.getDate()}
                            </h1>

                            <p
                                style={{
                                    margin: 0,
                                    fontSize: '14px',
                                    textAlign: 'center'
                                }}
                            >
                                {
                                    this.state.lunaCurenta
                                }
                            </p>

                        </div>

                        <div
                            className="col-l-10 col-m-10 col-s-10"
                            style={{
                                marginTop: 20,
                                paddingLeft: '20px',
                            }}
                        >

                            {
                                this.props.appointmentList.length === 0 &&
                                (
                                    <div>
                                        <h3
                                            style={{
                                                margin: 0,
                                                color: '#455A64',
                                                fontSize: '18px',
                                                textAlign: 'left'
                                            }}
                                        >
                                            Nu ai nicio programare in aceasta zi
                                        </h3>

                                        <br />

                                        <div className="popup-appoint-title">

                                            <div className="popup-appoint-title-left">

                                                <p
                                                    style={{
                                                        margin:0, 
                                                        textAlign: 'left'
                                                    }}
                                                >
                                                    Invita-ti clientii sa inregistreze o programare
                                                    in calendarul tau oferindu-le link-ul tau 
                                                    personailzat sau link-ul unui serviciu sau 
                                                    adauga tu o programare.
                                                </p>

                                            </div>

                                            <div className="popup-appoint-title-right">

                                                

                                            </div>

                                        </div>

                                        <div
                                            style={{
                                                marginTop: 20,
                                                width: '96%',
                                                height: '1px',
                                                background: '#EBEBEB'
                                            }}
                                        >
                                        </div>

                                    </div>
                                )
                            }

                            {
                            this.props.appointmentList && this.state.showAppointsList && this.props.payments &&
                            
                            this.props.appointmentList.map((alEl, idx) => {

                                let descClient = [];
                                let currentService = [];
                                let serviceColor = [];
                                let payments = [];

                                if( (alEl.tip || '' ) != 'pauza'){

                                    descClient = this.localData.listaLocatii.filter(lEl => {

                                        if(lEl.id === alEl.location){
    
                                            return lEl.descClient
                                        }
                                    })
    
                                    currentService = this.props.serviceList.filter(sEl => sEl.token === alEl['service_tk']);
                                    serviceColor = this.localData.listaCulori.filter(cEl => cEl.val === currentService[0].color);


                                    let tokenForPayment = alEl['token'];

                                    if((alEl['replaned_trc_tk'] || '' ).length > 10 ){

                                        tokenForPayment = alEl['replaned_trc_tk']; 
                                    }
                                    payments = this.props.payments.filter(pEl => {

                                        if(( pEl['trc_token'] === tokenForPayment ) && (+pEl.succes === 1)){

                                            return pEl;
                                        }
                                    });
                                }

                                const teamMember = new LocalStorage().GetUserData().userData[0]['token'] === this.state.users[0]?.token ? 
                                    this.state.users.filter( (member) => member.token === alEl.user_asignat_token) : []; 

                                if( (alEl.tip || '') == 'pauza' ){

                                    return(
                                    
                                        <div key={'popup-appoints-'+idx}>
    
                                            <div className="popup-appoint-title">
    
                                                <div className="popup-appoint-title-left">
    
                                                    <h3
                                                        style={{
                                                            margin: 0,
                                                            fontSize: '18px',
                                                            color: '#455A64',
                                                            textAlign: 'left'
                                                        }}
                                                    >
                                                        {alEl['clie_nume'] + ' ' + alEl['clie_prenume']}
                                                    </h3>
    
    
                                                        
                                                </div>
    
                                            </div>
    
                                            <div
                                                style={{
                                                    width: '100%',   
                                                }}
                                            >

                                                <HourInterval 
                                                    ora = {alEl['ora_cal']}
                                                    durata = {alEl['duration']}
                                                    justifyContent = 'none'
                                                    marginTop = '0px'
                                                    iconWidth = '32px'
                                                    iconSize = '22px'
                                                    txSize = '16px'
                                                    topTx = '-1px'
                                                    leftTx = '10px'
                                                />
    
                                            </div>

                                            {
                                                teamMember ? (

                                                    teamMember.length > 0 ? (

                                                        <div
                                                            className="row"
                                                            style={{
                                                                marginTop: 8
                                                            }}
                                                            // style={{
                                                            //     display: 'flex',
                                                            //     width: '100%',
                                                            //     marginTop: 8   
                                                            // }}
                                                        >
                                                            <div
                                                                className="col-l-1 col-m-1 col-s-1"
                                                            >
                                        
                                                                <div
                                                                    style={{
                                                                        position: 'relative',
                                                                        left: -5,
                                                                        width: '32.5px',
                                                                        height: '32.5px',
                                                                        borderRadius: '50%',
                                                                        background: 'lightblue',
                                                                        overflow: 'hidden'
                                                                    }}
                                                                >
                                                                    <img 
                                                                        // src={CompanyAvatar} 
                                                                        alt="Avatar client" 
                                                                        style={{
                                                                            width: '100%',
                                                                            height: '100%'
                                                                        }}
                                                                    />
                                        
                                                                </div>
                                        
                                                            </div>
                                        
                                                            <div
                                                                // style={{
                                                                //     width: '90%'
                                                                // }}
                                                                className="col-l-11 col-m-11 col-s-11"
                                                            >
                                        
                                                                <p
                                                                    style={{
                                                                        margin: 0,
                                                                        fontSize: '16px',
                                                                        color: '#263238',
                                                                        textAlign: 'left'
                                                                    }}
                                                                >
                                                                    {teamMember[0].nume}
                                                                </p>
                                        
                                                                <p
                                                                    style={{
                                                                        margin: 0,
                                                                        fontSize: '9px',
                                                                        color: '#777777',
                                                                        textAlign: 'left'
                                                                    }}
                                                                >
                                                                    {teamMember[0].functie}
                                                                </p>
                                        
                                                            </div>
                                        
                                                        </div>
                                                    ) : null

                                                ) : null
                                            }

                                            <div
                                                style={{
                                                    marginTop: 10,
                                                    marginBottom: 10,
                                                    width: '96%',
                                                    height: '1px',
                                                    background: '#EBEBEB'
                                                }}
                                            >
                                            </div>
    
    
                                        </div>
                                    )
                                }
                                else{

                                    return(
                                    
                                       <PopupAppointElem 
                                            appointElem = {alEl}
                                            svColor = {serviceColor[0].col}
                                            descClient = {descClient[0].descClient}
                                            payments = {payments}
                                            onShowCurrentAppointModal = {this.onShowCurrentAppointModal}
                                            member = {teamMember}
                                       />
                                    )
                                }
                               
                            })
                        }

                        {
                            this.state.showAppoint && this.state.currentAppoint && (

                                <React.Fragment>


                                <PopupAppointDetails 
                                
                                    currentAppoint = {this.state.currentAppoint}
                                    appointDateEnd = {this.state.appointDateEnd}
                                    meetingId = {this.state.meetingId}
                                    meetingPass = {this.state.meetingPass}
                                    appointLocation = {this.state.appointLocation.descClient}
                                    currentServiceColor = {this.state.currentServiceColor}
                                    member = {
                                        new LocalStorage().GetUserData().userData[0]['token'] === this.state.users[0]?.token ? 
                                        this.state.member : []
                                    }
                                />

                                <h3 
                                    className="title"
                                    style={{
                                        position: 'absolute',
                                        paddingBottom: '41px',
                                        bottom: 0,
                                        left: 15,
                                        margin: 0,
                                        textAlign: 'left',
                                        cursor: 'pointer'
                                    }}

                                    onClick={this.onGoBackToAppoints}
                                >
                                    
                                    <p 
                                        style={{
                                                display: 'inline-block', 
                                                position: 'relative', 
                                                top: 3, 
                                                left: 0, 
                                                margin: 0,
                                                color: '#44A5FF',
                                                fontSize: '22px'
                                            }}
                                    >
                                        <IoIosArrowBack /> 

                                    </p>

                                    <p 
                                        style={{
                                            display: 'inline-block', 
                                            position: 'relative', 
                                            top: -2,
                                            left: 6,
                                            margin: 0,
                                            color: '#44A5FF',
                                            fontWeight: '400',
                                            fontSize: '16px'
                                        }}
                                    >
                                        Inapoi
                                    </p>
                                    
                                </h3>

                            </React.Fragment>

                            )
                          
                        }
                        
                    </div>         

                    </div>

                    {
                        this.state.showAppointsList &&
                       

                        <div 
                            style={{
                                width: '100%',
                                height: '50px',
                                position:'relative',
                                top: 10,
                                left: 110,
                                cursor: 'pointer'
                            }}
                            onClick={this.UT.onAddAppointment}
                        >
                            <div className="row" onClick={this.onCreateDayAppointment}>

                                <div className="col-l-1 col-m-1 col-s-1">

                                    <div
                                        style={{
                                            position: 'relative',
                                            width: '35px',
                                            height: '35px',
                                            background: '#31A81D',
                                            borderRadius: '50%', 
                                        }}
                                    >
                                        <ImPlus 
                                            style={{
                                                position: 'relative',
                                                top: 6,
                                                margin: 0,
                                                color: '#fff',
                                                fontSize: '10px',
                                                
                                            }}
                                        />
                                        
                                    </div>

                                </div>

                                <div className="col-l-11 col-m-11 col-s-11">

                                    <p
                                        style={{
                                            position: 'relative',
                                            top: 6,
                                            textAlign: 'left',
                                            margin: 0,
                                            color: '#31A81D'
                                        }}
                                    >
                                        Adauga o programare
                                    </p>
                                    
                                </div>
                            
                            </div>

                        </div>
                    }

                </div>

            </div>

            </BrowserView >

            <MobileView>

                <div 
                    id="modal-popup"
                    className="calendar-modal-container"
                    onClick={(ev) => {this.onClosePopup(ev)}}
                >

                    <div 
                        className="calendar-popup-card"
                        style={{
                            width: '100%',
                            // height: '320px',
                            padding: '30px 20px',
                            // overflowY: 'auto'
                            textAlign: 'left'
                        }}
                    >   

                        <MdClear 
                            id="close-modal-btn"
                            style={{
                                position: 'absolute',
                                top: 10,
                                right: 10,
                                fontSize: '24px',
                                color: '#323232',
                                cursor: 'pointer'
                            }}

                            onClick={(ev) => {this.onClosePopup(ev)}}
                        />

                        {
                            this.state.currentAppoint && 
                            Object.keys(this.state.currentAppoint).length > 0 &&
                            (


                                <div>

                                        <h5
                                            style={{
                                                position: 'relative',
                                                left: '4px',
                                                margin: 0,
                                                fontSize: '14px',
                                                color: '#455A64'
                                            }}
                                        >
                                            {this.state.currentAppoint['clie_nume'] + ' ' + this.state.currentAppoint['clie_prenume']}
                                        </h5>

                                        <div
                                            // className="row"
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                marginTop: 8
                                            }}
                                        >

                                            <div 
                                                // className="col-s-1"
                                                style={{
                                                    width: '22px',
                                                    display: 'flex',
                                                    justifyContent: 'center'
                                                }}
                                            >

                                                <div 
                                                                                                
                                                    style={{
                                                        position: 'relative',
                                                        width: '14px',
                                                        height: '14px',
                                                        // left: '4px',
                                                        background: +this.state.currentAppoint['draft'] === 1 && this.state.currentAppoint['src'] === 'ca' ? '#455A64' : this.state.currentServiceColor,
                                                        borderRadius: '50%'
                                                    }}
                                                >
                                                    
                                                </div>

                                            </div>

                                            <div>

                                                <p
                                                    style={{
                                                        position: 'relative',
                                                        margin: 0,
                                                        top: 0,
                                                        left: '10px',
                                                        textAlign: 'left',
                                                        fontSize: '14px'
                                                    }} 
                                                >
                                                    {this.state.currentAppoint['service_name']}
                                                </p>

                                            </div>

                                        </div>

                                        <HourInterval 
                                            zi = {this.state.ziCurenta}
                                            data = {this.props.popupData.getDate()}
                                            luna = {this.state.lunaCurenta}
                                            ora = {this.state.currentAppoint['ora_cal']}
                                            durata = {this.state.currentAppoint['duration']}
                                            txSize = '14px'
                                        />

                                        {   
                                                this.state.appointDateEnd &&
                                                this.state.appointDateEnd.getTime() !== new Date(2021, 0, 1).getTime ||
                                                this.state.appointDateEnd.getTime() > new Date().getTime() && (

                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            marginTop: '2px',
                                                            marginBottom: '2px',
                                                            width: '100%',
                                                        }}
                                                    >
                                                        <p
                                                            style={{
                                                                position: 'relative',
                                                                left: '30px',
                                                                margin: 0,
                                                                fontSize: '14px',
                                                                fontWeight: '400',
                                                                color: '#D3766A',
                                                                
                                                            }}
                                                        >
                                                            Depasit
                                                        </p>

                                                    </div>
                                                )
                                            } 

                                        <div 
                                            // className="row"
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                        >

                                            <div 
                                                //className="col-l-6 col-m-6 col-s-6"
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    width: '50%'
                                                }}
                                            >

                                                <div 
                                                    // className="title"
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        width: '22px'
                                                    }}
                                                >
                                                    
                                                    <p 
                                                        style={{
                                                                // display: 'inline-block', 
                                                                position: 'relative', 
                                                                top: 2, 
                                                                left: 0, 
                                                                margin: 0,
                                                                color: +this.state.currentAppoint.price > 0 ? '#A6A6A6' : '#31A81D',
                                                                fontSize: '22px'
                                                            }}
                                                    >
                                                        <FaRegMoneyBillAlt />

                                                    </p>

                                                </div>

                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    <p 
                                                        style={{
                                                            // display: 'inline-block', 
                                                            position: 'relative', 
                                                            // top: -2,
                                                            left: '10px',
                                                            margin: 0,
                                                            color: +this.state.currentAppoint.price > 0 ? '#455A64' : '#31A81D',
                                                            fontWeight: '400',
                                                            fontSize: '14px'
                                                        }}
                                                    >
                                                        {+this.state.currentAppoint.price > 0 ? this.state.currentAppoint.price + " Lei" : "Fara Tarif"} 
                                                    </p>

                                                </div>

                                            </div>

                                            <div 
                                                // className="col-l-6 col-m-6 col-s-6"
                                                style={{

                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-end',
                                                    width: '50%'
                                                }}
                                            >

                                                <h3 
                                                    className="title"
                                                    style={{
                                                        paddingRight: '10px',
                                                        textAlign: 'right',
                                                        margin: 0
                                                    }}
                                                >
                                                    
                                                    <p 
                                                        style={{
                                                                display: 'inline-block', 
                                                                position: 'relative', 
                                                                top: +this.state.currentAppoint.draft === 0 ? 3 : 5, 
                                                                left: 0, 
                                                                margin: 0,
                                                                color: +this.state.currentAppoint.draft === 0 ? '#31A81D' : '#D3766A',
                                                                fontSize: '22px'
                                                            }}
                                                    >
                                                        {
                                                            +this.state.currentAppoint.draft === 0  && +this.state.currentAppoint.paymentSuccess === 0 && (

                                                                <MdThumbUp />
                                                            )
                                                        }

                                                        {
                                                            +this.state.currentAppoint.draft === 0 && +this.state.currentAppoint.paymentSuccess === 1 && (

                                                                <MdPayment />
                                                            )
                                                        }

                                                        {
                                                            +this.state.currentAppoint.draft === 1 && (

                                                                <MdThumbDown />
                                                            )
                                                        }

                                                    </p>

                                                    <p 
                                                        style={{
                                                            display: 'inline-block', 
                                                            position: 'relative', 
                                                            top: -2,
                                                            left: 10,
                                                            margin: 0,
                                                            color: +this.state.currentAppoint.draft === 0 ? '#31A81D' : '#D3766A',
                                                            fontWeight: '400',
                                                            fontSize: '14px'
                                                        }}
                                                    >
                                                        { (+this.state.currentAppoint.draft === 0 && +this.state.currentAppoint.paymentSuccess === 0) ? "Confirmat" : (+this.state.currentAppoint.draft === 0 && +this.state.currentAppoint.paymentSuccess === 1 ) ? 'Platit' : "Neconfirmat"} 
                                                    </p>
                                                    
                                                </h3>

                                            </div>

                                        </div>

                                        <LocationDesc 
                                            location = {this.state.currentAppoint.location}
                                            locationDesc = {this.state.appointLocation.descClient}
                                            officeW = '24px' officeH = '24px'       
                                            skypeSize = '20px'                 
                                            txSize = '14px'
                                        />

                                        <a
                                            href={this.state.currentAppoint['host_meeting_link']}
                                            style={{
                                                display: 'block',
                                                position: 'relative',
                                                margin: 0,
                                                marginLeft: 32, 
                                                marginTop: 10, 
                                                fontSize: '12px',
                                                textDecoration: 'none',
                                                color: '#2577C2',
                                            }}
                                        >
                                            {this.state.currentAppoint['host_meeting_link']}
                                        </a>
                                            
                                        {
                                            this.state.currentAppoint.location === 'zoom' &&
                                            ( this.state.currentAppoint['host_meeting_link'] || '').length > 0 &&
                                            (
                                            <React.Fragment>
                                                <p
                                                    style={{
                                                        position: 'relative',
                                                        margin: 0,
                                                        marginLeft: 32,
                                                        marginTop: 8,
                                                        textAlign: 'left',
                                                        color: '#2577C2',
                                                        fontSize: '12px',
                                                        cursor: 'pointer'
                                                    }}
                                                >
                                                    <u>
                                                        Meeting ID
                                                        {   
                                                            " " + this.state.meetingId
                                                        }
                                                        
                                                    </u>
                                                </p>

                                                <p
                                                    style={{
                                                        position: 'relative',
                                                        margin: 0,
                                                        marginLeft: 32,
                                                        marginTop: 4,
                                                        textAlign: 'left',
                                                        color: '#2577C2',
                                                        fontSize: '12px',
                                                        cursor: 'pointer'
                                                    }}
                                                >
                                                    <u>
                                                        Passcode:
                                                        {
                                                            " " + this.state.meetingPass 
                                                        }
                                                    
                                                    </u>
                                                </p>
                                                
                                            </React.Fragment>
                                            )
                                        }

                                    {
                                        this.state.member ? (

                                            this.state.member.length > 0 ? (

                                                <div
                                                    className="row"
                                                    style={{
                                                        marginTop: 8
                                                    }}
                                                    // style={{
                                                    //     display: 'flex',
                                                    //     width: '100%',
                                                    //     marginTop: 8   
                                                    // }}
                                                >
                                                    <div
                                                        className="col-l-1 col-m-1 col-s-1"
                                                    >

                                                        <div
                                                            style={{
                                                                position: 'relative',
                                                                left: -5,
                                                                width: '32.5px',
                                                                height: '32.5px',
                                                                borderRadius: '50%',
                                                                background: 'lightblue',
                                                                overflow: 'hidden'
                                                            }}
                                                        >
                                                            <img 
                                                                // src={CompanyAvatar} 
                                                                alt="Avatar client" 
                                                                style={{
                                                                    width: '100%',
                                                                    height: '100%'
                                                                }}
                                                            />

                                                        </div>

                                                    </div>

                                                    <div
                                                        // style={{
                                                        //     width: '90%'
                                                        // }}
                                                        className="col-l-11 col-m-11 col-s-11"
                                                    >

                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                fontSize: '16px',
                                                                color: '#263238',
                                                                textAlign: 'left'
                                                            }}
                                                        >
                                                            {this.state.member[0]['nume']}
                                                        </p>

                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                fontSize: '9px',
                                                                color: '#777777',
                                                                textAlign: 'left'
                                                            }}
                                                        >
                                                        {this.state.member[0]['functie']}
                                                        </p>

                                                    </div>

                                                </div>
                                            ) : null
                                            
                                        ) : null
                                    }

                                        <div
                                            style={{
                                                marginTop: 20,
                                                marginBottom: 10,
                                                width: '100%',
                                                height: '1px',
                                                background: '#EBEBEB'
                                            }}
                                        >
                                        </div>

                                        <div 
                                            // className="row"
                                            style={{
                                                display: 'flex',
                                                // alignItems: 'center'
                                            }}
                                        >
                                            
                                            <div 
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    width: '22px'
                                                }}
                                            >

                                                <p 
                                                    style={{
                                                            position: 'relative', 
                                                            top: 2, 
                                                            left: 0, 
                                                            margin: 0,
                                                            color: '#A6A6A6',
                                                            fontSize: '20px'
                                                        }}
                                                >
                                                    <ArticleIcon 
                                                        className="article-icon"
                                                    />  

                                                </p>

                                            </div>

                                            <div>

                                                <p 
                                                    style={{
                                                        position: 'relative', 
                                                        top: 4,
                                                        left: '10px',
                                                        margin: 0,
                                                        color: '#455A64',
                                                        fontWeight: '400',
                                                        fontSize: '14px'
                                                    }}
                                                >
                                                    {this.state.currentAppoint['clie_detalii'] || 'n/a'}
                                                </p>

                                            </div>
                                            
                                        </div>

                                        <div 
                                            //className="row"
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                        >
                                            
                                            <div 
                                                // className="col-s-1"
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    width: '22px'
                                                }}
                                            >

                                                <p 
                                                    style={{
                                                            position: 'relative', 
                                                            top: 2, 
                                                            left: 0, 
                                                            margin: 0,
                                                            color: '#A6A6A6',
                                                            fontSize: '20px'
                                                        }}
                                                >
                                                    <AiOutlineMail />

                                                </p>

                                            </div>

                                            <div>

                                                <p 
                                                    style={{
                                                        position: 'relative', 
                                                        // top: 4,
                                                        left: '10px',
                                                        margin: 0,
                                                        color: '#455A64',
                                                        fontWeight: '400',
                                                        fontSize: '14px'
                                                    }}
                                                >
                                                    {this.state.currentAppoint['clie_email']}
                                                </p>

                                            </div>
                                            
                                        </div>

                                        <div 
                                            // className="row"
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                        >
                                            
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    width: '22px'
                                                }}
                                            >

                                                <p 
                                                    style={{
                                                            position: 'relative', 
                                                            top: 2, 
                                                            left: 0, 
                                                            margin: 0,
                                                            color: '#A6A6A6',
                                                            fontSize: '20px'
                                                        }}
                                                >
                                                    <MdStayPrimaryPortrait />

                                                </p>

                                            </div>

                                            <div>

                                                <p 
                                                    style={{
                                                        position: 'relative', 
                                                        // top: 4,
                                                        left: '10px',
                                                        margin: 0,
                                                        color: '#455A64',
                                                        fontWeight: '400',
                                                        fontSize: '14px',
                                                    }}
                                                >
                                                    {this.state.currentAppoint['clie_tf']}
                                                </p>

                                            </div>
                                            
                                        </div>

                                    </div>
                            )
                        }

                        
                        
                    </div>

                </div>

                </MobileView>

            </>

        )
    }
}