import React from 'react';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
import { MdCheckCircle, MdReportProblem, MdTrendingDown, MdTrendingUp } from 'react-icons/md';
import IconText from '../../../commons/IconText';
import StatisticsLogic from './StatisticsLogic';

export default class Bounce extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            trendingIcon: new StatisticsLogic().setTrendingIcon(this.props.trendingRate),
            bounceRate: this.setBounceRate()
        };
    }


    componentDidMount(){


    }

    setBounceRate = () => {

        let bounceRate = {};

        if(+this.props.rate > 15){

            bounceRate = {

                icon: (
                    <MdReportProblem
                        style={{
                            color: '#323232',
                            fontSize: '22px'
                        }}
                    />
                ),

                bgColor: '#FFC727',

                title: 'Rata mare de Bounce?',
    
                txColor: '#000000',

                tx: (
                        'O rata de Bounce de peste 20% inseamna ca prezentarea serviciilor tale ar putea fi imbunatatita.' +   
                        '\n\nExperimenteaza! Verifica disponibilitatea ta, mai lucreaza la descrieri, ajusteaza pretul.' 
                    )
            };
        }
        else{

            bounceRate = {

                icon: (
                    <IoMdCheckmarkCircleOutline
                        style={{
                            color: '#ffffff',
                            fontSize: '22px'
                        }}
                    />
                ),

                bgColor: '#31A81D',

                title: 'Rata Bounce OK',
    
                txColor: '#ffffff',

                tx: (
                        'Te incadrezi in media rezonabila de bounce a utilizatorilor Planary, dar poti aduce mereu imbunatatiri.'   
                    )
            };
        }

        return bounceRate;
    }

    render(){

        return(

            <div style={this.props.style}>

                <p className='statistics-tx'>RATA DE BOUNCE</p>

                <p className='statistics-tx stat'>{this.props.rate}%</p>

                <IconText 
                    icon = {this.state.trendingIcon['icon']}
                    containerTop = '10px'
                    containerW = 'auto'
                    txW = '80px'
                    tx = {this.state.trendingIcon['tx']}
                    txSize = '16px'
                    txWeight = '500'
                    txCol = {this.state.trendingIcon['txColor']}
                    side = 'center'
                    direction = 'row-reverse'
                />

                <p className='statistics-subsolTx' style={{marginTop: '10px'}}>
                    Utilizatori care au ajuns pe pagina de programare dar 
                    nu au finalizat
                </p>

                <div 
                    className='bounce-wrapper'
                    style={{backgroundColor: this.state.bounceRate['bgColor']}}
                >
                    <IconText 
                        icon = {this.state.bounceRate['icon']}
                        tx = {this.state.bounceRate['title']}
                        txSize = '14px'
                        txWeight = '500'
                        txCol = {this.state.bounceRate['txColor']}
                        side = 'center'
                    />

                    <p 
                        className='statistics-subsolTx'
                        style={{
                            marginTop: '16px',
                            color: this.state.bounceRate['txColor'],
                            whiteSpace: 'pre-line'
                        }}
                    >
                        {this.state.bounceRate['tx']}
                    </p>

                </div>

            </div>
        );
    }
}