import React from 'react';
import ErrorMessage from '../../commons/ErrorMessage';
import LocalStorage from '../../providers/LocalStorage';

import Masks from '../../providers/Masks';
import Utils from '../../providers/Utils';
import Ws from '../../providers/Ws';

export default class ZoomAuthentication extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            authenticationCode: '',
            message: ''
        }
    }

    WS = new Ws();
    M = new Masks();
    UT = new Utils();

    componentDidMount(){

        // this.getAuthenticationCode();
        this.sendCodeToServer();
    }

    getAuthenticationCode = () => {

        const authenticationCodeFromURL = this.props.location.search;

        const authenticationCodeForZoom = authenticationCodeFromURL.substring(6, authenticationCodeFromURL.length - 1);

        this.setState({

            authenticationCode: authenticationCodeForZoom
        });

        return authenticationCodeForZoom;
    }

    extractZoomCode = () => {

        return this.props.location.search.substring(6, this.props.location.search.length - 1);
    }

    sendCodeToServer = async () => {

        const userToken = new LocalStorage().GetAccountToken();

        let ss = await this.WS.getData('zoomauth/getcomerciantdata', {userToken: userToken, zoomCode: this.extractZoomCode() });

        if(ss.success){

            this.props.history.push('/');
        }
        else{

            this.setState({

                message: 'Autentificarea cu Zoom a esuat! Va rugam reincercati'
            })
        }

        
    }

    render(){

        return(

            <div className="m-edit-s-card">
                {/* Integrare zoom cu cod autentificare... */}

                {
                    this.state.message.length < 1 ? ('Autentificare cu Zoom in curs. Va rugam asteptati...') : (

                        <ErrorMessage 
                            eroareTx = {this.state.message}
                        />
                    )
                }
              
                {/* <p>{this.props.location.search}</p>
                <p>{this.state.authenticationCode}</p> */}

            </div>
        )
    }
}