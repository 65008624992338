import React from 'react';

import { BrowserView, MobileView } from 'react-device-detect';
import { GoCheck, GoVerified } from 'react-icons/go';
import { MdPower, MdSettingsInputComponent } from 'react-icons/md';
import { ReactComponent as ZoomIcon } from '../../assets/zoom_icon.svg';
import { ReactComponent as LocIcon } from '../../assets/pin_drop.svg';
import { ReactComponent  as GMeetIcon} from '../../assets/Google_Meet-Logo.wine.svg';
import { ReactComponent  as SmartBillIcon} from '../../assets/Icon Smart Bill.svg';
import { IoMdRefresh } from 'react-icons/io';
import LaptopAndMugImg from '../../assets/laptop_and_mug.png';

import MServiceNav from '../../commons/MServiceNav';

import Masks from '../../providers/Masks';
import Utils from '../../providers/Utils';
import Ws from '../../providers/Ws';

import { FaAngleRight, FaMoneyBill, FaSkype } from 'react-icons/fa';
import IntegrationsCard from '../../commons/IntegrationsCard';
import LocalStorage from '../../providers/LocalStorage';
import ErrorMessage from '../../commons/ErrorMessage';
import IconText from '../../commons/IconText';
import { Link } from 'react-router-dom';
import ModalSmartbill from '../../commons/integrations/ModalSmartbill';
import ModalSkype from '../../commons/integrations/ModalSkype';

export default class Integrari extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            canSave: false,
            // accountIntegrations: [],
            // isZoomSynced: false,
            // isSkypeSynced: false,
            // isGoogleSynced:false,
            // isNetopiaSynced: false,
            // isSmartBillSynced: false,
            errorTx: '',
            integrationsList: [],
            smartBillIntegration: [],
            callIntegrations: [],
            showSmartBill: false,
            showSkype: false
        }; 
    }

    WS = new Ws();
    UT = new Utils();
    M = new Masks();


    componentDidMount(){

        this.getIntegrationsData();
    }

    checkForUserIntegration = (integrationsData, type) => {

        const extractedIntegration = integrationsData.filter(intEl => intEl['tip'] === type);

        if(extractedIntegration.length > 0){

            if( ( extractedIntegration[0]['value'] || '').length > 0 ){

                return true;
            }
            else{

                return false;
            }
        }
        else{

            return false;
        }
    }

    checkForPaymentsIntegration = (integrationsData, type) => {

        const extractPaymentIntegration = integrationsData.filter(intEl => +intEl[type] === 1);

        if(extractPaymentIntegration.length > 0){

            return true;
        }
        else{

            return false;
        }
    }   

    getIntegrationsData = async () => {

        const ACC_TOKEN = new LocalStorage().GetAccountToken();
        const userToken = new LocalStorage().GetUserData().userData[0].token;

        let selectUsersContactsQuery = `select * from user_contacts where token_user = '${userToken}' `;
        let _usersContacts = this.WS.sqlCommand(`select`, selectUsersContactsQuery);

        let selectAccountIntegrationsQuery = `select * from accounts_integrations where account_token = '${ACC_TOKEN}' `;
        let _accountIntegrations = this.WS.sqlCommand(`select`, selectAccountIntegrationsQuery);

        let selectAccountPayments = `select * from accounts_payments_methods where account_token = '${ACC_TOKEN}' `;
        let _accountPayments = this.WS.sqlCommand(`select`, selectAccountPayments);

        let selectSmartBillIntegration = `select * from accounts_integrations where account_token = '${ACC_TOKEN}'
                                                and ifnull(activ,0)=1 `;

        let _smartBillIntegration = this.WS.sqlCommand(`select`, selectSmartBillIntegration);

        const qUsers = `select * from users where account_id = '${ACC_TOKEN}' `;
        let _users = this.WS.sqlCommand(`select`, qUsers);

        const qAccount = `select * from accounts where token = '${ACC_TOKEN}' `;
        let _account = await this.WS.sqlCommand(`select`, qAccount);

        let [usersContacts, accountIntegrations, accountPayments, smartBillIntegration, users, account]  = 
                        await Promise.all( [ _usersContacts, _accountIntegrations, _accountPayments, _smartBillIntegration, _users, _account ] );

        if(usersContacts.success && accountIntegrations.success && accountPayments.success && smartBillIntegration.success){
        
            // const allAccountIntegrations = [
            //     ...usersContacts.content, 
            //     ...accountIntegrations.content,
            //     ...accountPayments.content
            // ];

            

            const wrkToken = await this.WS.getUserToken();

            let newIntegrationsState = [

                {   
                    icon: ZoomIcon,
                    tip: 'zoom',
                    // isActive: usersContacts.content.findIndex(el => el['tip'] === 'zoom') > -1,
                    isActive: usersContacts.content.findIndex(el => el['tip'] === 'zoom' && (el['zoom_access_token'] || '').length > 0 ) > -1,
                    name: 'Zoom',
                    shortTx: 'Locatie - Apel video',
                    clsName: 'zoom',
                    link: 'https://zoom.us/oauth/authorize?client_id=cO4wbO9vSewzUEaKQxEvg&response_type=code&redirect_uri=https%3A%2F%2Fapp.planary.ro%2F%23%2Fca%2Fdashboard%2Fcab%2Fautentificare-zoom/'
                },

                {
                    icon: FaSkype,
                    tip: 'skype',
                    isActive: usersContacts.content.findIndex(el => el['tip'] === 'skype') > -1,
                    name: 'Skype',
                    shortTx: 'Locatie - Apel video',
                    clsName: 'skype',
                    link: '',
                    func: this.onOpenSkype
                },

                {
                    icon: GMeetIcon,
                    tip: 'google',
                    // isActive: usersContacts.content.findIndex(el => el['tip'] === 'google') > -1,
                    isActive: usersContacts.content.findIndex(el => el['tip'] === 'google' && (el['google_access_token'] || '').length > 0) > -1,
                    name: 'Google',
                    shortTx: 'Locatie - Apel video',
                    clsName: 'gmeet',
                    link: `https://gcal.planary.ro/auth.php?wrkToken=${wrkToken}`
                },

            ];

            const accountIntegrations = [

                {
                    icon: FaMoneyBill,
                    tip: 'netopia',
                    isActive: accountPayments.content.findIndex(el => +el['is_netopia'] === 1) > -1,
                    name: 'Netopia',
                    shortTx: 'Procesator Plata',
                    clsName: 'netopia',
                    link: '',
                },

                {
                    icon: SmartBillIcon,
                    tip: 'smartbill',
                    isActive: smartBillIntegration.content.findIndex(el => el['tip'] === 'smart_bill' && (el['smart_bill_api_key'] || '').length > 0) > -1,
                    name: 'Smart Bill',
                    shortTx: 'Facturare',
                    clsName: 'smartbill',
                    link: '',
                    func: this.onOpenSmartbill
                },
            ];

            newIntegrationsState = users.content[0]['token'] === userToken && ( account.content[0].abonament !== 'gratuit' &&
            account.content[0].abonament !== null )
            ?
            [...newIntegrationsState, ...accountIntegrations] : newIntegrationsState;

            newIntegrationsState.sort( (previousIntegration, nextIntegration) => {

                    if(+previousIntegration.isActive < nextIntegration.isActive){

                        return 1;
                    }
                    else{

                        return -1;
                    }

            });

            this.setState({

                integrationsList: newIntegrationsState,
                smartBillIntegration: smartBillIntegration.content,
                callIntegrations: usersContacts.content
            });
            
        }
        else{

            this.setState({

                errorTx: 'Integrarile nu au putut fi accesate de catre server.'
            }); 

            throw new Error("Error getting integrations => ", usersContacts.message, accountIntegrations.message, accountPayments.message);
        }
    }

    onOpenSmartbill = () => {

        this.setState({

            showSmartBill: true
        });
    }

    onOpenSkype = () => {

        this.setState({

            showSkype: true
        });
    }

    onClosePopup = (type) => {

        this.setState({

            [type]: false
        });
    }

    render(){

        return(
            <>

            {
                this.state.showSmartBill ? (

                    <ModalSmartbill 
                        onClosePopup = {this.onClosePopup}
                        onRefreshData = {this.getIntegrationsData}
                        smartBillIntegration = {this.state.smartBillIntegration}
                    />
                ) : null
            }

            {
                this.state.showSkype ? (

                    <ModalSkype 
                        onClosePopup = {this.onClosePopup}
                        onRefreshData = {this.getIntegrationsData}
                        callIntegrations = {this.state.callIntegrations}
                    />
                ) : null
            }
            
            <BrowserView>

                <div 
                    className="wrapper-dashboard integrari" 
                    style={{

                        margin:'30px auto',
                        minHeight: '800px',
                        background: `url(${LaptopAndMugImg}) bottom no-repeat`
                    }}
                >

                    <div className="service-details-card">

                        <IconText 
                            icon = {
                                <MdSettingsInputComponent 
                                    style={{
                                        color: 'rgb(255, 199, 39)',
                                        fontSize: '20px'
                                    }}
                                />
                            }
                            tx = 'Integrari'
                            txSize = '18px'
                            txWeight = '500'
                            txCol = '#74838B'
                        />

                        <br />

                        {
                            this.state.errorTx.length > 0 && (

                                <ErrorMessage 
                                    eroareTx = {this.state.errorTx}
                                />
                            )
                        }

                        {
                            this.state.errorTx.length === 0 && (

                                <>
                                
                                {
                                    this.state.integrationsList.map( (aiEl, idx) => {
                                        
                                        return(
                                                
                                                <div className="integration-row">
                                                    <IntegrationsCard 
                                                        key = {`integration-${idx}`}
                                                        icon = {
                                                            <aiEl.icon 
                                                                className={"integrations-icon " + aiEl.clsName}
                                                            />
                                                        }
                                                        middleTx = {aiEl.name}
                                                        downTx = {aiEl.shortTx}
                                                        isSynced = {aiEl.isActive}
        
                                                        style={{
                                                            marginTop: idx > 0 ? '8px' : '0px',
                                                        }}
                                                        onClick = {

                                                            () => {

                                                                if(!aiEl.isActive){

                                                                    if( (aiEl['link'] || '').length > 0 ){

                                                                        // window.open( aiEl['link'] );
                                                                        window.location.href = aiEl['link'];
                                                                    }
                                                                    else if(aiEl['func'] !== null && aiEl['func'] !== undefined){

                                                                        aiEl.func();
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    />  
                                                
                                                    {
                                                        aiEl['tip'] === 'google' && aiEl['isActive'] === true || 
                                                        (( aiEl['tip'] === 'skype' || aiEl['tip'] === 'smartbill') && aiEl['isActive'] === true )
                                                        ? (

                                                            // <div className='refresh-token-icon-container'>

                                                                <IoMdRefresh 
                                                                    className='refresh-token-icon'
                                                                    onClick = { () => {

                                                                        if(aiEl['tip'] === 'google'){

                                                                            window.location.href = aiEl['link']
                                                                        }
                                                                        else{

                                                                            aiEl.func();
                                                                        }
                                                                    }} 
                                                                />

                                                            // </div>

                                                        ) : null
                                                    }
                                                </div>                                              
                                        )

                                    },this)
                                }
                                
                                </>
                            )
                        }

                    </div>

                </div>
            
            </BrowserView>

            <MobileView>

                <div
                    style={{
                        position: 'relative'
                    }} 
                >

                    <MServiceNav 
                        icon={<MdSettingsInputComponent />}
                        title='Integrari'
                        path={'/ca/dashboard/cab'}
                    />

                    {
                        this.state.canSave &&
                        <GoCheck
                            className="m-add-service"
                            // onClick={this.saveToDb}
                        />
                    }
                </div>

                <div 
                    style={{
                        padding: 20
                    }}
                >

                    <div className="m-edit-s-card">

                        {
                            this.state.errorTx.length > 0 && (

                                <ErrorMessage 
                                    eroareTx = {this.state.errorTx}
                                />
                            )
                        }

                        {
                            this.state.errorTx.length === 0 && (

                                <>
                                
                                {
                                    this.state.integrationsList.map( (aiEl, idx) => {
                                        
                                        return(
                                                
                                                <IntegrationsCard 
                                                    key = {`integration-${idx}`}
                                                    icon = {
                                                        <aiEl.icon 
                                                            className={"integrations-icon " + aiEl.clsName}
                                                        />
                                                    }
                                                    middleTx = {aiEl.name}
                                                    downTx = {aiEl.shortTx}
                                                    isSynced = {aiEl.isActive}
    
                                                    style={{
    
                                                        marginTop: idx > 0 ? '8px' : '0px'
                                                    }}
                                                    onClick = {

                                                        () => {

                                                            if(!aiEl.isActive){

                                                                if( (aiEl['link'] || '').length > 0 ){

                                                                    window.open( aiEl['link'] );
                                                                }
                                                                else if(aiEl['func'] !== null && aiEl['func'] !== undefined){

                                                                    aiEl.func();
                                                                }
                                                            }
                                                        }
                                                    }
                                                />                                                
                                        )

                                    },this)
                                }
                                
                                </>
                            )
                        }



                    </div>

                </div>

            </MobileView>

            </>
        );
    }
}