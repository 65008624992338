import React from 'react';
import { BrowserView, MobileView } from 'react-device-detect';

import AccountCircleIcon from '../assets/account_circle.svg';
import AccountSupervisorIcon from '../assets/supervisor_account.svg';
import HistoryIcon from '../assets/history.svg';
import AnalyticsIcon from '../assets/analytics.svg';
import FurnitureImg from '../assets/Dulap Perete Gol.png';
import BusinessCenterIcon from '../assets/business_center.svg';
import SecurityIcon from '../assets/security.svg';
import IntegrationsIcon from '../assets/settings_input_component.svg';
import HelpIcon from '../assets/support.svg';
import LogoutIcon from '../assets/power_settings_new.svg';
import Ws from '../providers/Ws';
import LocalStorage from '../providers/LocalStorage';

export default class UserMenu extends React.Component{

    constructor(props){

        super(props);
        this.state = {

            tipAbonament: null
        }
    }

    WS = new Ws();

    componentDidMount(){

        this.onGetTipAbonament();
    }

    onGetTipAbonament = async () => {

        let accountToken = new LocalStorage().GetAccountToken();

        let q = `select * from accounts where token = '${accountToken}' `;
        let res = await this.WS.sqlCommand(`select`, q);

        if(res.success){

            this.setState({

                tipAbonament: res.content[0].abonament
            });
        }
    }

    onLogoutUser = () => {

        localStorage.clear();
        window.location.href = '/';    
    }

    onShowProfile = () => {

        this.props.onShowProfile();
    }

    onShowEditSchedule = () => {

        this.props.onShowEditSchedule();
    }

    onShowIntegrari = () => {

        this.props.onShowIntegrari();
    }

    onShowEchipa = () => {

        this.props.onShowEchipa();
    }
    
    onShowAbonamente = () => {

        this.props.onShowAbonamente();
    }

    onShowSecuritate = () => {

        this.props.onShowSecuritate();
    }

    onShowIstoricPlati = () => {

        this.props.onShowIstoricPlati();
    }

    onShowIstoricProgramari = () => {

        this.props.onShowIstoricProgramari();
    }

    render(){

        return(

            <>

            <BrowserView>
            

                <div 
                    className="calendar-popup-card"
                    style={{
                        position: 'absolute',
                        top: 100,
                        right: 0,
                        width: '600px',
                        padding: '20px',
                        borderRadius: '10px 0px 10px 10px'
                    }}
                >
                   
                    <div className="row">

                        <div 
                            className="col-l-6 col-m-6 col-s-6"
                            style={{
                                verticalAlign: 'top',
                                borderRight: '1px solid #EBEBEB'
                            }}
                        >

                            {
                                /*
                                //////////////////////////////////////
                                                Link 1
                                //////////////////////////////////////
                                */
                            }

                            <div 
                                className="row"
                                onClick={this.onShowProfile}
                                style={{
                                    cursor: 'pointer'
                                }}
                            >

                                <div className="col-l-1 col-m-1 col-s-1">

                                    <img 
                                        src={AccountCircleIcon}
                                    />
             
                                </div>

                                <div className="col-l-11 col-m-11 col-s-11">

                                    <p
                                        style={{
                                            position: 'relative',
                                            top: 2,
                                            margin: 0,
                                            textAlign: 'left',
                                            fontSize: '14px',
                                            fontWeight: 500,
                                            color: '#263238'
                                        }}
                                    >
                                        &nbsp; Profil
                                    </p>

                                </div>

                            </div>

                            {
                                /*
                                //////////////////////////////////////
                                                Link 2
                                //////////////////////////////////////
                                */
                            }

                            <div 
                                className="row" 
                                style={{
                                    marginTop: 15,
                                    cursor: 'pointer'
                                }}
                                onClick={this.onShowEditSchedule}
                            >

                                <div className="col-l-1 col-m-1 col-s-1">

                                    <div style={{width: '100%', height: '24px'}}></div>
                                        
                                </div>

                                <div className="col-l-11 col-m-11 col-s-11">

                                    <p
                                        style={{
                                            position: 'relative',
                                            top: 2,
                                            margin: 0,
                                            textAlign: 'left',
                                            fontSize: '14px',
                                            fontWeight: 400,
                                            color: '#263238'
                                        }}
                                    >
                                        &nbsp; Programe Personalizate
                                    </p>

                                </div>

                            </div>

                            {
                                /*
                                //////////////////////////////////////
                                                Link 3
                                //////////////////////////////////////
                                */
                            }

                           {
                                this.state.tipAbonament === 'anteprenor' ||
                                this.state.tipAbonament === 'premium' ? (

                                    <div 
                                        className="row" 
                                        style={{
                                            marginTop: 15,
                                            cursor: 'pointer'
                                        }}
                                        onClick={this.onShowEchipa}
                                    >
        
                                        <div className="col-l-1 col-m-1 col-s-1">
        
                                            <img 
                                                src={AccountSupervisorIcon}
                                            />
                                                
                                        </div>
        
                                        <div className="col-l-11 col-m-11 col-s-11">
        
                                            <p
                                                style={{
                                                    position: 'relative',
                                                    top: 2,
                                                    margin: 0,
                                                    textAlign: 'left',
                                                    fontSize: '14px',
                                                    fontWeight: 500,
                                                    color: '#263238'
                                                }}
                                            >
                                                &nbsp; Echipa
                                            </p>
        
                                        </div>
        
                                    </div>
                                ) : null
                           }

                            {
                                /*
                                //////////////////////////////////////
                                                Link 4
                                //////////////////////////////////////
                                */
                            }

                            <div 
                                className="row" 
                                style={{
                                    marginTop: 15,
                                    cursor: 'pointer'
                                }}
                                onClick={this.onShowIstoricProgramari}
                            >

                                <div className="col-l-1 col-m-1 col-s-1">

                                    <img 
                                        src={HistoryIcon}
                                    />
                                        
                                </div>

                                <div className="col-l-11 col-m-11 col-s-11">

                                    <p
                                        style={{
                                            position: 'relative',
                                            top: 2,
                                            margin: 0,
                                            textAlign: 'left',
                                            fontSize: '14px',
                                            fontWeight: 500,
                                            color: '#263238'
                                        }}
                                    >
                                        &nbsp; Istoric Programari
                                    </p>

                                </div>

                            </div>

                            {
                                /*
                                //////////////////////////////////////
                                                Link 5
                                //////////////////////////////////////
                                */
                            }

                            <div 
                                className="row" 
                                style={{marginTop: 15, cursor:'pointer'}}
                                onClick = {this.props.onShowRapoarte}
                            >

                                <div className="col-l-1 col-m-1 col-s-1">

                                    <img 
                                        src={AnalyticsIcon}
                                    />
                                        
                                </div>

                                <div className="col-l-11 col-m-11 col-s-11">

                                    <p
                                        style={{
                                            position: 'relative',
                                            top: 2,
                                            margin: 0,
                                            textAlign: 'left',
                                            fontSize: '14px',
                                            fontWeight: 500,
                                            color: '#263238'
                                        }}
                                    >
                                        &nbsp; Rapoarte si Analiza
                                    </p>

                                </div>

                            </div>

                            <br />

                            <img
                                src={FurnitureImg}
                                style={{
                                    display: 'block',
                                    margin: '0 auto'
                                }}
                            />

                        </div>

                        <div 
                            className="col-l-6 col-m-6 col-s-6" 
                            style={{
                               paddingLeft: 20
                            }}
                        >

                            {
                                /*
                                //////////////////////////////////////
                                                Link 1
                                //////////////////////////////////////
                                */
                            }

                            <div 
                                className="row" 
                                style={{
                                    position: 'relative',
                                    cursor: 'pointer'
                                }}
                                onClick={this.onShowAbonamente}
                            >

                                <div className="col-l-1 col-m-1 col-s-1">

                                    <img 
                                        src={BusinessCenterIcon}
                                        style={{
                                            display: 'block',
                                            position: 'relative',
                                            top: -1,
                                        }}
                                    />
                                        
                                </div>

                                <div className="col-l-11 col-m-11 col-s-11">

                                    <p
                                        style={{
                                            position: 'relative',
                                            top: 2,
                                            left: 4,
                                            margin: 0,
                                            textAlign: 'left',
                                            fontSize: '14px',
                                            fontWeight: 500,
                                            color: '#263238'
                                        }}
                                    >
                                        &nbsp; Abonament
                                    </p>

                                </div>

                            </div>

                            {
                                /*
                                //////////////////////////////////////
                                                Link 2
                                //////////////////////////////////////
                                */
                            }

                            <div 
                                className="row" 
                                style={{
                                    marginTop: 15,
                                    cursor: 'pointer'
                                }}
                                onClick={this.onShowIstoricPlati}
                            >

                                <div className="col-l-1 col-m-1 col-s-1">

                                    <div style={{width: '100%', height: '24px'}}></div>
                                        
                                </div>

                                <div className="col-l-11 col-m-11 col-s-11">

                                    <p
                                        style={{
                                            position: 'relative',
                                            top: 2,
                                            margin: 0,
                                            textAlign: 'left',
                                            fontSize: '14px',
                                            fontWeight: 400,
                                            color: '#263238'
                                        }}
                                    >
                                        &nbsp; Istoric Plati
                                    </p>

                                </div>

                            </div>

                            {
                                /*
                                //////////////////////////////////////
                                                Link 3
                                //////////////////////////////////////
                                */
                            }

                            <div 
                                className="row" 
                                style={{
                                    marginTop: 15,
                                    cursor: 'pointer'
                                }}
                                onClick={this.onShowSecuritate}
                            >

                                <div className="col-l-1 col-m-1 col-s-1">

                                    <img 
                                        src={SecurityIcon}
                                        style={{
                                            display: 'block',
                                            position: 'relative',
                                            top: -1,
                                        }}
                                    />
                                        
                                </div>

                                <div className="col-l-11 col-m-11 col-s-11">

                                    <p
                                        style={{
                                            position: 'relative',
                                            top: 2,
                                            left: 4,
                                            margin: 0,
                                            textAlign: 'left',
                                            fontSize: '14px',
                                            fontWeight: 500,
                                            color: '#263238'
                                        }}
                                    >
                                        &nbsp; Securitate si Notificari
                                    </p>

                                </div>

                            </div>

                            {
                                /*
                                //////////////////////////////////////
                                                Link 4
                                //////////////////////////////////////
                                */
                            }

                            <div 
                                className="row" 
                                style={{
                                    marginTop: 15,
                                    cursor: 'pointer'
                                }}
                                onClick={this.onShowIntegrari}
                            >

                                <div className="col-l-1 col-m-1 col-s-1">

                                    <img 
                                        src={IntegrationsIcon}
                                        style={{
                                            display: 'block',
                                            position: 'relative',
                                            top: -1,
                                        }}
                                    />
                                        
                                </div>

                                <div className="col-l-11 col-m-11 col-s-11">

                                    <p
                                        style={{
                                            position: 'relative',
                                            top: 2,
                                            left: 4,
                                            margin: 0,
                                            textAlign: 'left',
                                            fontSize: '14px',
                                            fontWeight: 500,
                                            color: '#263238'
                                        }}
                                    >
                                        &nbsp; Integrari
                                    </p>

                                </div>

                            </div>

                            <br />
                            <br />

                            <div 
                                style={{
                                    width: '100%',
                                    height: '1px',
                                    background: '#EBEBEB'
                                }}
                            >
                            </div>

                            <br />

                            {
                                /*
                                //////////////////////////////////////
                                                Link 5
                                //////////////////////////////////////
                                */
                            }

                            <div 
                                className="row"
                                onClick = {() => window.location.href = 'https://planary.ro/docs'}
                                style={{
                                    cursor: 'pointer'
                                }}
                            >

                                <div className="col-l-1 col-m-1 col-s-1">

                                    <img 
                                        src={HelpIcon}
                                        style={{
                                            display: 'block',
                                            position: 'relative',
                                            top: -1,
                                        }}
                                    />
                                        
                                </div>

                                <div className="col-l-11 col-m-11 col-s-11">

                                    <p
                                        style={{
                                            position: 'relative',
                                            top: 2,
                                            left: 4,
                                            margin: 0,
                                            textAlign: 'left',
                                            fontSize: '14px',
                                            fontWeight: 500,
                                            color: '#263238'
                                        }}
                                    >
                                        &nbsp; Ajutor
                                    </p>

                                </div>

                            </div>

                            {
                                /*
                                //////////////////////////////////////
                                                Link 6
                                //////////////////////////////////////
                                */
                            }

                            <div 
                                className="row" 
                                style={{
                                    marginTop: 15,
                                    cursor: 'pointer'
                                }}
                                onClick={this.onLogoutUser}
                            >

                                <div className="col-l-1 col-m-1 col-s-1">

                                    <img 
                                        src={LogoutIcon}
                                        style={{
                                            display: 'block',
                                            position: 'relative',
                                            top: -1,
                                        }}
                                    />
                                        
                                </div>

                                <div className="col-l-11 col-m-11 col-s-11">

                                    <p
                                        style={{
                                            position: 'relative',
                                            top: 2,
                                            left: 4,
                                            margin: 0,
                                            textAlign: 'left',
                                            fontSize: '14px',
                                            fontWeight: 500,
                                            color: '#263238'
                                        }}
                                    >
                                        &nbsp; Log Out
                                    </p>

                                </div>

                            </div>

                            
                            <p
                                style={{
                                    position: 'relative',
                                    top: '30px',
                                    margin: 0,
                                    // marginTop: '4px',
                                    fontSize: '12px',
                                    fontWeight: '400',
                                    color: '#EBEBEB',
                                    textAlign: 'left'
                                }}
                            >
                                Version Beta - 0.0.1
                            </p>

                        </div>

                    </div>

                </div>


            </BrowserView>

            </>
        )
    }
}