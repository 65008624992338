import React from 'react';

import { MdError } from 'react-icons/md';
import { GoVerified } from 'react-icons/go';

import {
    BrowserView,
    MobileView,
    
} from "react-device-detect";

import './Commons.css';

export default class AuthInput extends React.Component{

    constructor(props){

        super(props)
    }

    // onChangeContent = () =>{

    //     this.props.onChangeContent()
    // }

    render(){
        
        const mInputClass = 'm-form-input' + ' ' + this.props.className;

        return(

            <div>
                
                <input 
                    className={mInputClass} 
                    type={this.props.type}
                    value={this.props.value}
                    placeholder={this.props.mPlaceholder}
                    iserror={this.props.error}
                    errormsg={this.props.errormsg}
                    // onChange={this.props.onChange}
                    onChange={this.props.onChange}
                    // onKeyDown ={this.props.onKeyDown}
                    verified={this.props.verified}
                    style={this.props.style} 
                />
                
                {this.props.verified && <GoVerified className="status-icon verified-icon"/>}

                {this.props.iserror && <MdError className="status-icon error-icon"/>}

                {this.props.iserror &&
                
                <p className="input-error">
                    {this.props.errormsg}
                </p>
                
                }



            </div>

        );
    }
}