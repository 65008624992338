import React from 'react';
import Masks from '../../../providers/Masks';
import Utils from '../../../providers/Utils';
import Ws from '../../../providers/Ws';
import ServicesFuncs from '../../logic/ServicesFuncs';

import Image from '../../../assets/Businesswoman-cuate 1.png';
import '../Register.css'
import Declarations from '../../../providers/Declarations';
import UserDataInputs from '../../../commons/profile/UserDataInputs';
import AuthInput from '../../../commons/AuthInput';
import ButtonForm from '../../../commons/ButtonForm';
import { IoIosArrowForward } from 'react-icons/io';
import RegisterHeader from '../RegisterHeader';
import CompanyDataInputs from '../../../commons/profile/CompanyDataInputs';
import Avatar from '../../../commons/Avatar';
import LocalStorage from '../../../providers/LocalStorage';
import ErrorToast from '../../../commons/ErrorToast';
import SuccessSaveToast from '../../../commons/SuccessSaveToast';
import { BrowserView, MobileView } from 'react-device-detect';
import ButtonsNavRegister from '../../../commons/ButtonsNavRegister';

import CanaryPlantImage from '../../../assets/canary_plant_bg.png';
import MBirdAndPlantImage from '../../../assets/bird_and_plant_mobile.png';
import Loader from '../../../commons/Loader';

export default class AccAvatar extends React.Component{

    constructor(props){

        super(props);
        this.state = {

            avatarSrc: '',
            actionTx: '',
            showToastPopupError: false,
            showToastPopup: false,
            isLoading: true
        };
    }

    WS = new Ws();
    UT = new Utils();
    M = new Masks();
    SF = new ServicesFuncs();

    componentDidMount(){

        this.getAvatar();
    }

    onClosePopup = (type) => {

        this.setState({

            [type]: false
        });
    }   

    showSuccesUpload = () => {

        this.setState({

            showToastPopup: true,
            actionTx: 'Avatarul a fost uploadat cu succes.'
        },
        
            async () => {

                await this.getAvatar();
            }
        );
    }

    showErrorUpload = (tx) => {

        this.setState({

            showToastPopupError: true,
            actionTx: tx
        });
    }

    getAvatar = async () => {

        const TOKEN_CONT = new LocalStorage().GetAccountToken();

        let q = `select * from accounts where token = '${TOKEN_CONT}'  `;
        // 

        let accountData = await this.WS.sqlCommand(`select`, q);
        // 
        if(accountData.success){

            const accData = await this.UT.getAccountAvAndName(false, TOKEN_CONT);

            let avatarSource = accData['avatar'] !== null && accData['avatar'] !== undefined 
            ? 
            this.WS.serverAddress + 'staticprovider/getimg?filepath=Storage/Avatars/' + accData['avatar'] 
            : ''
            

            this.setState({
                
                avatarSrc: avatarSource,
                isLoading: false
            });

        }
        else{

            this.setState({

                showToastPopupError: true,
                actionTx: 'Eroare preluare date profil din server.'
            });

            throw new Error("Failed to get account data from server: " + accountData.message)
        }
    }

    
    render(){

        if(this.state.isLoading){

            return(

                <Loader 
                    fullPage = {true}
                />
            );
        }

        return(
            <>
                {
                    
                    this.state.showToastPopupError && (

                        <ErrorToast 
                            action={this.state.actionTx}
                            onClosePopup = {this.onClosePopup}
                        />
                    )
                
                }

                {
                    this.state.showToastPopup && (

                        <SuccessSaveToast 
                            onClosePopup = {this.onClosePopup}
                            saveName = 'Datele profilului'
                            action = {this.state.actionTx}
                        />
                    )
                }

                <BrowserView>

                    <div className='register-container-desktop'>

                        <RegisterHeader />

                        <div className='service-details-card' style={{marginTop: '20px', textAlign: 'left'}}>

                            <div className='row'>

                                <div className='col-l-6 col-m-6 col-s-6'>

                                    <Avatar 
                                        avatarSrc = {this.state.avatarSrc}
                                        showSuccesUpload = {this.showSuccesUpload}
                                        showErrorUpload = {this.showErrorUpload}
                                        accToken = {new LocalStorage().GetAccountToken()}
                                    />

                                </div>

                                <div className='col-l-6 col-m-6 col-s-6' style={{paddingLeft: '20px'}}>

                                    <p className='register-tx register-title'>
                                        Avatarul companiei tale.
                                    </p>

                                    <p className='register-tx' style={{marginTop: '16px'}}>
                                        Imaginea ta reprezentativa ce va aparea in comunicarea prin Planary. 
                                        Iti recomandam sa alegi un Logo-ul tau sau o imagine tip portret. 
                                        Apasa butonul Camera pentru a incepe
                                    </p>

                                    <ButtonForm 
                                        type='button'
                                        tx={
                                            <>
                                                <p style={{display: 'inline-block', position: 'relative', top: '-2px', margin: 0}}>
                                                    Continua &nbsp;
                                                </p> 
                                                <IoIosArrowForward className='register-next-arrow'/>
                                            </>
                                        }
                                        
                                        style={{
                                            marginTop: '20px',
                                            width: 'auto',
                                            height: '56px',
                                            background: '#F8BC05',
                                            fontSize: '16px',
                                            padding: '0px 35px 0px 35px'
                                        }}
                                        onClick={
                                            () => {

                                                this.props.history.push('/inregistrare/serviciu/program');
                                            }
                                        }
                                    />
                                    
                                </div>


                            </div>

                        </div>

                        <img 
                            className='bottom-pg-img'
                            src={CanaryPlantImage}
                            alt = 'Planary...'
                            style={{
                                marginTop: '20px'
                            }}
                        />

                    </div>

                </BrowserView>

                <MobileView>

                    <div 
                        style={{
                            padding: '20px',
                            minHeight: '850px',
                            background: `url(${MBirdAndPlantImage}) bottom no-repeat`
                        }}
                    >
                    
                        <RegisterHeader />

                        <div className="m-edit-s-card" style={{marginTop: '20px', textAlign: 'left'}}>

                            <p className='register-tx sm register-title bold'>
                                Avatarul companiei tale.
                            </p>

                            <p className='register-tx sm' style={{marginTop: '16px'}}>
                                Imaginea ta reprezentativa ce va aparea in comunicarea prin Planary. 
                                Iti recomandam sa alegi un Logo-ul tau sau o imagine tip portret. 
                                Apasa butonul Camera pentru a incepe
                            </p>

                        </div>
                        
                        <div className="m-edit-s-card" style={{marginTop: '20px', textAlign: 'left'}}>
                            
                            <p className='register-tx sm reigster-title bold'>
                                Imagine Avatar
                            </p>

                            <br />
                            
                            <Avatar 
                                avatarSrc = {this.state.avatarSrc}
                                showSuccesUpload = {this.showSuccesUpload}
                                showErrorUpload = {this.showErrorUpload}
                                accToken = ''
                            />
                            
                            <ButtonsNavRegister 
                                tx = 'Continua'
                                onPressBtn = {

                                    () => {

                                        this.props.history.push('/inregistrare/serviciu/program');
                                    }
                                }
                            />

                        </div>

                    </div>

                </MobileView>
            </>
        )
    }
}