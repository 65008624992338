import React from 'react';

import ErrorImage from '../assets/Feeling sorry-cuate 1.png';

import { BrowserView, MobileView } from 'react-device-detect';
import ButtonForm from './ButtonForm';
import { MdClear } from 'react-icons/md';

export default class ErrorToast extends React.Component{

    constructor(props){

        super(props);
    }

    onClosePopup = (ev) => {

        if( ev.target.id === 'modal-popup' ||  ev.target.id === 'close-modal-btn'){

            this.props.onClosePopup("showToastPopupError");

        }
    }

    render(){

        return(

            <>
                <BrowserView>
                <div 
                    id="modal-popup"
                    className="calendar-modal-container"
                    onClick={(ev) => {this.onClosePopup(ev)}}
                >

                    <div 
                        className="calendar-popup-card"
                        style={{
                            padding: '30px',
                            textAlign: 'left'
                        }}
                    >

                        <MdClear 
                            id="close-modal-btn"
                            style={{
                                position: 'absolute',
                                top: 10,
                                right: 10,
                                fontSize: '24px',
                                color: '#323232',
                                cursor: 'pointer'
                            }}

                            onClick={(ev) => {this.onClosePopup(ev)}}
                        />

                            {/* <FaCheckCircle 
                            style={{
                                display: 'block',
                                margin: '0 auto',
                                color: '#31A81D',
                                fontSize: '48px',
                            }}    
                        /> */}

                        <p
                            style={{
                                margin: 0,
                                fontSize: '14px',
                                fontWeight: '500',
                                color: '#DD6A57',
                                textAlign: 'center'
                            }}
                        >
                            Oooh... A aparut o eroare!
                        </p>

                        <br />

                        <img
                            src = {ErrorImage}
                            alt = "Error Image..."
                            style = {{
                                display: 'block',
                                margin: '0 auto'
                            }}
                        />

                        <br />

                        <p
                            style={{
                                margin: 0,
                                color: '#74838B',
                                fontWeight: '400',
                                textAlign: 'center',
                            }}
                        >
                            {this.props.action}
                        </p>
                        
                        <br />

                        <div style={{textAlign: 'center'}}>

                            <ButtonForm
                                id="close-modal-btn"
                                tx='OK'
                                type='button'
                                onClick={(ev) => {this.onClosePopup(ev)}}
                                style={{
                                    width: '150px',
                                    background: '#FFC727'
                                }}
                            />
                            
                        </div>

                    </div>        

                </div>

                </BrowserView>

                <MobileView>

                <div 
                    id="modal-popup"
                    className="calendar-modal-container"
                    onClick={(ev) => {this.onClosePopup(ev)}}
                >

                    <div 
                        className="calendar-popup-card"
                        style={{
                            width: '100%',
                            padding: '24px',
                            textAlign: 'left'
                        }}
                    >

                        <MdClear 
                            id="close-modal-btn"
                            style={{
                                position: 'absolute',
                                top: 10,
                                right: 10,
                                fontSize: '24px',
                                color: '#323232',
                                cursor: 'pointer'
                            }}

                            onClick={(ev) => {this.onClosePopup(ev)}}
                        />

                        <p
                            style={{
                                margin: 0,
                                fontSize: '14px',
                                fontWeight: '500',
                                color: '#DD6A57',
                                textAlign: 'center'
                            }}
                        >
                            Oooh... A aparut o eroare!
                        </p>

                        <br />

                        <img
                            src = {ErrorImage}
                            alt = "Error Image..."
                            style = {{
                                display: 'block',
                                margin: '0 auto'
                            }}
                        />
                        
                        <br />

                        <p
                            style={{
                                margin: 0,
                                color: '#74838B',
                                fontWeight: '400',
                                textAlign: 'center',
                            }}
                        >
                            {this.props.action}
                        </p>

                        <br />

                        <div style={{textAlign: 'center'}}>

                            <ButtonForm 
                                id="close-modal-btn"
                                tx='OK'
                                type='button'
                                onClick={(ev) => {this.onClosePopup(ev)}}
                                style={{
                                    width: '150px',
                                    background: '#FFC727'
                                }}
                            />
                            
                        </div>

                    </div>

                    </div>


                </MobileView>

            </>
        )
    }
}