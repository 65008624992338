import React from 'react';
import { MdSettingsInputComponent } from 'react-icons/md';
import ButtonForm from '../../../commons/ButtonForm';
import IconText from '../../../commons/IconText';

import Masks from '../../../providers/Masks';
import Utils from '../../../providers/Utils';
import Ws from '../../../providers/Ws';
import ServicesFuncs from '../../logic/ServicesFuncs';
import RegisterHeader from '../RegisterHeader';
import ConsentCheck from '../../../commons/ConsentCheck';
import IntegrationsCard from '../../../commons/IntegrationsCard';

import Image from '../../../assets/Connected-cuate 1.png';

import { IoIosArrowForward } from 'react-icons/io';

import { ReactComponent  as SmartBillIcon} from '../../../assets/Icon Smart Bill.svg';
import {  FaMoneyBill } from 'react-icons/fa';
import { BrowserView, MobileView } from 'react-device-detect';
import ButtonsNavRegister from '../../../commons/ButtonsNavRegister';

import CanaryPlantImage from '../../../assets/canary_plant_bg.png';
import MBirdAndPlantImage from '../../../assets/bird_and_plant_mobile.png';
import LocalStorage from '../../../providers/LocalStorage';

import Loader from '../../../commons/Loader';

export default class AccIntegrPay extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            isButtonDisabled: true,
            acceptTerms: false,
            integrationsList: [],
            isLoading: true
        }
    }

    WS = new Ws();
    UT = new Utils();
    M = new Masks();
    SF = new ServicesFuncs();

    componentDidMount(){

        this.getIntegrationsData();
    }

    getIntegrationsData = async () => {

        const accToken = new LocalStorage().GetAccountToken();
        let integrations = await this.SF.getIntegrationsData(false, accToken);

        const qAccount = `select * from accounts where token = '${accToken}' `;
        let account = await this.WS.sqlCommand(`select`, qAccount);

        if(integrations.err === null){

            let newIntegrationsState = [

                {
                    icon: FaMoneyBill,
                    tip: 'netopia',
                    isActive: integrations.data.accountPayments.findIndex(el => +el['is_netopia'] === 1) > -1,
                    name: 'Netopia',
                    shortTx: 'Procesator Plata',
                    clsName: 'netopia',
                    link: ''
                },
    
                {
                    icon: SmartBillIcon,
                    tip: 'smartbill',
                    isActive: integrations.data.smartBillIntegration.findIndex(el => el['tip'] === 'smart_bill' && (el['smart_bill_api_key'] || '').length > 0) > -1,
                    name: 'Smart Bill',
                    shortTx: 'Facturare',
                    clsName: 'smartbill',
                    link: ''
                },
            ];

            if(account.content[0].abonament === 'gratuit' || account.content[0].abonament=== null){

                this.props.history.push('/inregistrare/serviciu/integrari-plati');
            }
            else{

                newIntegrationsState.sort( (previousIntegration, nextIntegration) => {
    
                    if(+previousIntegration.isActive < nextIntegration.isActive){
    
                        return 1;
                    }
                    else{
    
                        return -1;
                    }
    
                });
    
                this.setState({
        
                    integrationsList: newIntegrationsState,
                },
                    () => {

                        this.setState({

                            isLoading: false
                        })
                    }
                );
            }
        }

        
    }

    onAcceptTerms = () => {

        this.setState({

            acceptTerms: !this.state.acceptTerms,
            isButtonDisabled: !this.state.isButtonDisabled
        });
    }

    render(){

        if(this.state.isLoading){

            return(

                <Loader 
                    fullPage = {true}
                />
            )
        }

        return(
            <>
            <BrowserView>

                <div className='register-container-desktop'>

                    <RegisterHeader isServiceStep = {true} />

                    <div className='service-details-card' style={{marginTop: '20px', textAlign: 'left'}}>

                        <div className='row'>

                            <div className='col-l-6 col-m-6 col-s-6'>

                                <IconText 
                                    icon = {
                                        <MdSettingsInputComponent 
                                            style={{
                                                color: 'rgb(255, 199, 39)',
                                                fontSize: '20px'
                                            }}
                                        />
                                    }
                                    tx = 'Integrari'
                                    txSize = '16px'
                                    txWeight = '500'
                                    txCol = '#455A64'
                                />

                                <p className='register-tx' style={{marginTop: '16px'}}>

                                    Legalitatea este un aspect importanta pentru orice afacere. 
                                    <br /> 
                                    <br /> 
                                    Integrarile Planary te conecteaza cu serviciul Smart Bill, dar si cu procesatorul de 
                                    lata cu card bancar EuPlatesc, pentru a putea emite facturi fiscale si a 
                                    incasa plata pentru serviciile tale.
                                    <br /> 
                                    <br /> 
                                    Daca ai deja conturi cu acesti furnizori, conecteaza-te acum.
                                    
                                </p>

                                <img
                                    className='register-image'
                                    src={Image}
                                    alt="Planary"
                                />

                            </div>

                            <div className='col-l-6 col-m-6 col-s-6' style={{paddingLeft: '20px'}}>

                                <div style={{marginTop: '36px'}}>

                                {
                                    this.state.integrationsList.map( (aiEl, idx) => {
                                        
                                        return(
                                                
                                                <IntegrationsCard 
                                                    key = {`integration-${idx}`}
                                                    icon = {
                                                        <aiEl.icon 
                                                            className={"integrations-icon " + aiEl.clsName}
                                                        />
                                                    }
                                                    middleTx = {aiEl.name}
                                                    downTx = {aiEl.shortTx}
                                                    isSynced = {aiEl.isActive}

                                                    style={{

                                                        marginTop: idx > 0 ? '8px' : '0px'
                                                    }}
                                                    onClick = {

                                                        () => {

                                                            if(!aiEl.isActive){

                                                                if( (aiEl['link'] || '').length > 0 ){

                                                                    // window.open( aiEl['link'] );
                                                                    window.location.href = aiEl['link'];
                                                                }
                                                            }
                                                        }
                                                    }
                                                />                                                
                                            )

                                        },this)
                                    }

                                </div>

                                <ConsentCheck 
                                    tx = 'Avanseaza configurarea fara aceste integrari. Le poti activa oricand din meniul Utilizator.'
                                    onAcceptTerms={this.onAcceptTerms}
                                />

                                <ButtonForm 
                                    type='button'
                                    tx={
                                        <>
                                            <p style={{display: 'inline-block', position: 'relative', top: '-2px', margin: 0}}>
                                                Continua &nbsp;
                                            </p> 
                                            <IoIosArrowForward className='register-next-arrow'/>
                                        </>
                                    }
                                    
                                    style={{
                                        marginTop: '20px',
                                        width: 'auto',
                                        height: '56px',
                                        background: this.state.isButtonDisabled ? '#EBEBEB' : '#F8BC05',
                                        fontSize: '16px',
                                        padding: '0px 35px 0px 35px',
                                        cursor: this.state.isButtonDisabled ? 'default' : 'pointer'
                                    }}
                                    onClick={
                                        () => {

                                            if(!this.state.isButtonDisabled){

                                                this.props.history.push('/inregistrare/cont/succes-creare-cont')
                                            }
                                            
                                        }
                                    }
                                />
                                
                            </div>


                        </div>

                    </div>

                    <img 
                        className='bottom-pg-img'
                        src={CanaryPlantImage}
                        alt = 'Planary...'
                        style={{
                            marginTop: '20px'
                        }}
                    />

                </div>

            </BrowserView>

            <MobileView>

                <div 
                    style={{
                        padding: '20px',
                        minHeight: '1100px',
                        background: `url(${MBirdAndPlantImage}) bottom no-repeat`
                    }}
                >
                
                    <RegisterHeader isServiceStep = {true} />
                    
                    <div className="m-edit-s-card" style={{marginTop: '20px', textAlign: 'left'}}>
                        
                        <IconText 
                            icon = {
                                <MdSettingsInputComponent 
                                    style={{
                                        color: 'rgb(255, 199, 39)',
                                        fontSize: '20px'
                                    }}
                                />
                            }
                            tx = 'Integrari'
                            txSize = '16px'
                            txWeight = '500'
                            txCol = '#455A64'
                        />

                        <p className='register-tx sm' style={{marginTop: '16px'}}>

                            Legalitatea este un aspect importanta pentru orice afacere. 
                            Integrarile Planary te conecteaza cu serviciul Smart Bill, dar si cu procesatorul de 
                            lata cu card bancar EuPlatesc, pentru a putea emite facturi fiscale si a 
                            incasa plata pentru serviciile tale.
                            Daca ai deja conturi cu acesti furnizori, conecteaza-te acum.
                            
                        </p>

                        <img
                            className='register-image'
                            src={Image}
                            alt="Planary"
                        />

                    </div>

                    <div className="m-edit-s-card" style={{marginTop: '20px', textAlign: 'left'}}>
                        
                        <p className='register-tx sm register-title bold'>
                            Integreaza-te cu aceste servicii.
                        </p>

                        <div style={{marginTop: '16px'}}>

                            {
                                this.state.integrationsList.map( (aiEl, idx) => {
                                    
                                    return(
                                            
                                        <IntegrationsCard 
                                            key = {`integration-${idx}`}
                                            icon = {
                                                <aiEl.icon 
                                                    className={"integrations-icon " + aiEl.clsName}
                                                />
                                            }
                                            middleTx = {aiEl.name}
                                            downTx = {aiEl.shortTx}
                                            isSynced = {aiEl.isActive}

                                            style={{

                                                marginTop: idx > 0 ? '8px' : '0px'
                                            }}
                                            onClick = {

                                                () => {

                                                    if(!aiEl.isActive){

                                                        if( (aiEl['link'] || '').length > 0 ){

                                                            // window.open( aiEl['link'] );
                                                            window.location.href = aiEl['link'];
                                                        }
                                                    }
                                                }
                                            }
                                        />                                                
                                    )

                                },this)
                            }

                        </div>

                        <ConsentCheck 
                            tx = 'Avanseaza configurarea fara aceste integrari. Le poti activa oricand din meniul Utilizator.'
                            onAcceptTerms={this.onAcceptTerms}
                        />
                        
                        <ButtonsNavRegister 
                            tx = 'Continua'
                            style = {{

                                background: this.state.isButtonDisabled ? '#EBEBEB' : '#F8BC05',
                            }}
                            onPressBtn ={
                                () => {

                                    if(!this.state.isButtonDisabled){

                                        this.props.history.push('/inregistrare/cont/succes-creare-cont')
                                    }
                                    
                                }
                            }
                            onBack = {this.props.history.goBack}
                        />

                    </div>

                </div>

            </MobileView>

            </>
        );
    }
}