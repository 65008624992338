import React from 'react';

import Masks from '../../providers/Masks';
import Utils from '../../providers/Utils';
import Ws from '../../providers/Ws';
import LocalStorage from '../../providers/LocalStorage';
import { BrowserView } from 'react-device-detect';
import { MdClear } from 'react-icons/md';

export default class TestPauza extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            serviceDetails: {},
            nume: null,
            prenume: null,
            email: null,
            telefon: null,
            dateSelected: '',
            confirmHour: '',
            durataPauza: 0
        };
    }

    WS = new Ws();
    M = new Masks();
    UT = new Utils();

    componentDidMount(){

        // this.generateDemoState();
    }   

    generateDemoState = async () => {


        const ACC_TOKEN = new LocalStorage().GetAccountToken();
        const webLinkService = 'serviciu-skype'

        let selectService = `select * from services where account_tk = '${ACC_TOKEN}' 
                                                    and web_link = '${webLinkService}' and ifnull(deleted,0)!=1 and ifnull(draft,0)!=1       `;

                                                    

        let serviceData = await this.WS.sqlCommand(`select`, selectService);
        

        if(serviceData.success){

            this.setState({

                serviceDetails: serviceData.content[0],
                dateSelected: '2021-10-07',
                confirmHour: '12:00',
                durataPauza: 30
            })
        }
        else{

            throw new Error("Server response error: " + serviceData.message)
        }

        
    }

    onCreateInvite = async () => {

        const tranToken = this.UT.generateToken();
        const insertQueryTran = ` insert into trc (token, cand_creat, account_tk, 
                                     service_tk, service_name, service_description,
                                     duration, location, price, 
                                     data_cal, ora_cal, draft, clie_nume, clie_prenume,
                                     clie_email, clie_tf, src,
                                     screen, tip
                                ) 

                                select '${tranToken}', NOW(), '${this.state.serviceDetails.account_tk}',
                                        ${null}, ${null}, ${null},
                                        ${this.state.durataPauza}, '${null}', 0,
                                        '${this.state.dateSelected}', '${this.state.confirmHour}', 0, 
                                        'Pauza', 'Pauza', ${null},
                                        ${null}, 'ca',
                                        'popup', 'pauza'
                                    
                                    `

        

        const createTran = await this.WS.sqlCommand(`insert`, insertQueryTran)
        
        

        if(createTran.success){

            alert("Succes!");

            // let ss = await this.WS.getData('/invite/create', {trcToken: tranToken});
            // 
        
    
        }
        else{

            throw new Error("Server response error: " + createTran.message)
        }
    }

    onCancelMeeting = async (trcToken) => {

        let ss = await this.WS.getData('pay/cancelmeeting', {trToken: 'JojbZldPxKlFRsH9oLECBiMh3qhyGZNK'});
        
    }

    sendEmailConfirmation = async (trcToken) => {

        let ss = await this.WS.getData('pay/SendEmailConfirmation', {trToken: 'JojbZldPxKlFRsH9oLECBiMh3qhyGZNK'});
        
    }

    render(){

        return(

        <BrowserView>

            {/* <button
                type="button"
                onClick={this.onCreateInvite}
            >
                Creaza Pauza
            </button> */}

             <button
                type="button"
                onClick={this.onCancelMeeting}
            >
                Anuleaza Intalnirea
            </button>

            <button
                type="button"
                onClick={this.sendEmailConfirmation}
            >
                Trimite Mail Confirmare
            </button>
          
        </BrowserView>
        )
    }
}