import React from 'react';

import { RiCalendar2Fill } from 'react-icons/ri';

export default class CrAppoAddBreakSuccess extends React.Component{

    constructor(props){

        super(props);
    }

    componentDidMount(){

        this.props.onUpdateCal();
    }

    onCloseCreateAppointPopup = () => {

        this.props.onCloseCreateAppointPopup();
    }

    render(){

        return(

            <div style={{textAlign: 'center'}}>

                <p
                    style={{
                        margin: 0,
                        fontWeight: '700',
                        fontSize: '18px',
                        color: '#60D84C'
                    }}
                >
                    Felicitari!
                </p>

                <br />

                <p
                    style={{
                        margin: 0,
                        fontWeight: '400',
                        fontSize: '14px',
                        color: '#263238'
                    }}
                >
                    Ai inregistrat cu succes pauza de <b>{this.props.breakDuration} Minute</b> pentru:
                </p>

                <br />

                <div>

                    <p
                        style={{
                            display: 'inline-block',
                            position: 'relative',
                            top: 4,
                            margin: 0,
                            fontSize: '18px',
                            color: '#263238'
                        }}
                    >
                        <RiCalendar2Fill />
                    </p>

                    <p
                        style={{
                            display: 'inline-block',
                            position: 'relative',
                            margin: 0,
                            fontWeight: '400',
                            fontSize: '14px',
                            color: '#263238'
                        }}
                    >
                        &nbsp; {this.props.data}
                    </p>

                </div>

            </div>
        )
    }
}