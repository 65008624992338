import React from 'react';

import Masks from '../../../providers/Masks';
import Utils from '../../../providers/Utils';
import Ws from '../../../providers/Ws';
import Declarations from '../../../providers/Declarations';
import ServicesFuncs from '../../logic/ServicesFuncs';
import RegisterHeader from '../RegisterHeader';
import AddressDataInputs from '../../../commons/profile/AddressDataInputs';

import CanaryPlantImage from '../../../assets/canary_plant_bg.png';
import MBirdAndPlantImage from '../../../assets/bird_and_plant_mobile.png';
import ButtonForm from '../../../commons/ButtonForm';
import { IoIosArrowForward } from 'react-icons/io';
import LocalStorage from '../../../providers/LocalStorage';
import Loader from '../../../commons/Loader';

export default class CompanyAddress extends React.Component{

    constructor(props){

        super(props);

        this.state = {
            
            data: {

                oras: '',
                adresa: ''
            },

            triggerSave: false,
            isLoading: true
        }
    }
    
    componentsData = new Declarations().profileData['adresa'];

    componentsStatus = {
        
        isAddressDataOk: false,
    };

    componentDidMount(){

        this.onGetAdresaCompanie();
    }

    WS = new Ws();
    UT = new Utils();
    M = new Masks();
    SF = new ServicesFuncs();

    onGetAdresaCompanie = async () => {

        const TOKEN_CONT = new LocalStorage().GetAccountToken();

        let queryDateCompanie = `select * from accounts where token = '${TOKEN_CONT}' `;
        let dateCompanie = await this.WS.sqlCommand(`select`, queryDateCompanie);

        if(dateCompanie.success){

            

            this.componentsData.adresa = dateCompanie.content[0]['adresa'] || '';
            this.componentsData.oras = dateCompanie.content[0]['oras'] || '';

            this.setState({

                data: {

                    oras: dateCompanie.content[0]['oras'] || '',
                    adresa: dateCompanie.content[0]['adresa'] || '',
                },
                isLoading: false
            });
        }
    }

    inputStatus = (status, statusName, inputName, data, isVerify = false) => {

        if(isVerify){

            this.componentsStatus[statusName] = data;
        }
        else{

            this.componentsData[inputName] = data;

            
    
            this.setState({
                
                [statusName]: status
            },
                () => {
    
                    // this.checkSaveButtonStatus();
                }
            );
        }
    }

    onCheckAndSave = async () => {

        const result = Object.values(this.componentsStatus).every(item => item === true);;
        const TOKEN_CONT = new LocalStorage().GetAccountToken();

        
        
        if(result){

            // const queryObject = new Declarations().generateQuerySet('profile', this.componentsData);
            // const queryArr = new Declarations().onGenerateQueryArray(queryObject);

            let queryUpdateAccountData = `update accounts set oras = '${this.componentsData.oras}',
                                            adresa = '${this.componentsData.adresa}'
                                where token = '${TOKEN_CONT}'
            `;

            let updateAccountData = await this.WS.sqlCommand(`update`, queryUpdateAccountData);

            if(updateAccountData.success){

                // this.props.history.push('');
                this.props.history.push('/inregistrare/date/avatar');
            }
        }
        else{

            this.setState({

                triggerSave: false
            })
        }
    }

    onTriggerSave = () => {

        this.setState({

            triggerSave: true
        },
        
            () => {

                this.onCheckAndSave();
            }
        )
    }

    render(){

        if(this.state.isLoading){

            return(

                <Loader 
                    fullPage = {true}
                />
            )
        }

        return(

            <div className='register-container-desktop'>

                <RegisterHeader />

                <div className='service-details-card' style={{marginTop: '20px', textAlign: 'left'}}>

                    <p className='register-tx register-title'>
                        Datele companiei tale!
                    </p>

                    <p className='register-tx' style={{marginTop: '16px'}}>
                        Defineste clar locatia pe harta a sediului tau, unde vei primi vizite de la clientii are au facut programari. 
                        Selecteaza orasul si completeaza adresa, apoi plaseaza locatia exact pe harta.
                    </p>

                    <AddressDataInputs 
                        triggerSave = {this.state.triggerSave}
                        data = {this.state.data}
                        inline = {true}
                        inputStatus = {this.inputStatus}

                        style={{
                            marginTop: '20px'
                        }}
                    />

                    <div style={{textAlign: 'center'}}>

                        <ButtonForm 
                            type='button'
                            tx={
                                <>
                                    <p style={{display: 'inline-block', position: 'relative', top: '-2px', margin: 0}}>
                                        Continua &nbsp;
                                    </p> 
                                    <IoIosArrowForward className='register-next-arrow'/>
                                </>
                            }
                            
                            style={{
                                marginTop: '20px',
                                width: 'auto',
                                height: '56px',
                                background: '#F8BC05',
                                fontSize: '16px',
                                padding: '0px 35px 0px 35px'
                            }}
                            onClick={
                                () => {

                                    this.onTriggerSave()
                                }
                            }
                        />
                    </div>

                </div>

                <img 
                    className='bottom-pg-img'
                    src={CanaryPlantImage}
                    alt = 'Planary...'
                    style={{
                        marginTop: '20px'
                    }}
                />

            </div>
        )
    }
}