import React from 'react';

import { MobileView } from 'react-device-detect';
import { IoIosArrowForward } from 'react-icons/io';
import { RiCalendar2Fill } from 'react-icons/ri';

import CanaryImage from '../../../../assets/bird_and_plant_mobile.png';
import MServiceNav from '../../../../commons/MServiceNav';
import MCrAppoServiceInfo from './MCrAppoServiceInfo';
import ErrorImage from '../../../../assets/Feeling sorry-cuate 1.png';

import Masks from '../../../../providers/Masks';
import Utils from '../../../../providers/Utils';
import Ws from '../../../../providers/Ws';
import Declarations from '../../../../providers/Declarations';
import LocalStorage from '../../../../providers/LocalStorage';
import { Link } from 'react-router-dom';
import ErrorMessage from '../../../../commons/ErrorMessage';


export default class MCrAppoMain extends React.Component{

    constructor(props){

        super(props);
        this.state = {

            servicesList: [],
            eroareTx: ''
        };
    }

    
    localData = {

        locatiiIntalnire: new Declarations().listaLocatiiIntalnire
    }

    WS = new Ws();
    M = new Masks();
    UT = new Utils()


    componentDidMount(){

        this.getServicesData();
    }

    getServicesData = async () => {

        const TOKEN_CONT = new LocalStorage().GetAccountToken();

        let selectServicesQuery = `select * from services where account_tk = '${TOKEN_CONT}' and ifnull(deleted,0)!=1 and ifnull(draft,0)!=1  `;
        let services = await this.WS.sqlCommand(`select`, selectServicesQuery);

        if(services.success){

            this.setState({

                servicesList: services.content
            })
        }
        else{

            this.setState({

                eroareTx: 'Server-ul nu a putut prelua datele serviciilor.'
            });
            throw new Error("Can't get services: " + services.message);
        }
    }

    render(){

        return(
            <MobileView>

            <MServiceNav
                icon={<RiCalendar2Fill />}
                title='Adauga in calendar'
                path={'/ca/dashboard/cab'}
            />

            <div
                style={{
                    padding: '20px'
                }}
            >

                <div 
                    className="m-create-appointment-card"
                    style={{
                        textAlign: 'left'
                    }}
                >

                    {
                        this.state.eroareTx.length > 0 && (

                            <ErrorMessage 
                                eroareTx = {this.state.eroareTx}
                            />
                        )
                    }

                    {
                        this.state.eroareTx.length === 0 && (

                            <div>

                                <div>
                                    <RiCalendar2Fill 
                                    
                                        style={{

                                            display: 'inline-block',
                                            position: 'relative',
                                            top: 4,
                                            margin: 0,
                                            fontSize: '20px',
                                            color: '#FFC727'
                                        }}
                                    />
                                    <p
                                        style={{
                                            display: 'inline-block',
                                            position: 'relative',
                                            margin: 0,
                                            fontSize: '14px',
                                            fontWeight: '500',
                                            color: '#455A64'
                                        }}
                                    >
                                        &nbsp; Selecteaza Serviciu
                                    </p>

                                </div>

                                <br />

                                <div
                                    style={{
                                        width: '100%',
                                        height: '1px',
                                        background: '#EBEBEB'
                                    }}
                                ></div>
                                
                                <br />
                                
                                <Link
                                    to={'/ca/dashboard/cab/adauga-pauza/'+this.props.match.params.intervalDate}
                                    style={{
                                        textDecoration: 'none'
                                    }}
                                >
                                <div 
                                    style={{
                                        
                                        display: 'flex'
                                    }}
                                >

                                    <div
                                        style={{
                                            width: '91.66%'
                                        }}
                                    >
                                        <p
                                            style={{
                                                position: 'relative',
                                                top: '50%',
                                                transform: 'translateY(-50%)',
                                                margin: 0,
                                                fontSize: '14px',
                                                fontWeight: '700',
                                                color: '#000'
                                            }}
                                        >
                                            Adauga Pauza
                                        </p>
                                    </div>

                                    <div
                                        style={{
                                            position: 'relative',
                                            width: '8.33%'
                                        }}
                                    >   

                                        <IoIosArrowForward 
                                            style={{
                                                position: 'relative',
                                                top: '50%',
                                                transform: 'translateY(-50%)',
                                                right: 0,
                                                margin: 0,
                                                fontSize: 32,
                                                color: '#323232',
                                                cursor: 'pointer'
                                            }}
                                        
                                        />

                                    </div>

                                </div>
                                </Link>

                                <br />

                                <div
                                    style={{
                                        width: '100%',
                                        height: '1px',
                                        background: '#EBEBEB'
                                    }}
                                ></div>

                                {
                                    this.state.servicesList &&
                                    this.state.servicesList.length > 0 &&
                                    this.state.servicesList.map( (sEl, idx) => {

                                        let locationName = this.localData.locatiiIntalnire.filter(liEl => liEl.id === (sEl.location || '').trim())

                                        return(

                                            <Link
                                                key={`service-no-${idx}`}
                                                to={'/ca/dashboard/cab/adauga-in-calendar/'+sEl['web_link']+'/'+this.props.match.params.intervalDate}
                                                style={{
                                                    textDecoration: 'none'
                                                }}
                                            >

                                            <div>

                                                <MCrAppoServiceInfo 
                                            
                                                    name = {sEl.name}
                                                    duration = {sEl.duration}
                                                    location = {sEl.location}
                                                    locationName = {locationName[0].descClient}
                                                    price = {sEl.price}
                                                    description = {sEl.description}
                                                />

                                                {
                                                    idx < this.state.servicesList.length - 1 && (

                                                        <div
                                                            style={{
                                                                width: '100%',
                                                                height: '1px',
                                                                background: '#EBEBEB'
                                                            }}
                                                        ></div>
                                                    )
                                                }

                                            </div>
                                            </Link>
                                        )
                                    })
                                }
                            </div>
                        )
                    }
                    
                </div>

                <img 
                    src={CanaryImage}
                    alt="Canary image"
                    style={{
                        display: 'block',
                        marginTop: '20px'
                    }}
                />

            </div>

           

            </MobileView>
        );
    }
}