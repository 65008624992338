import React from 'react';

import Ws from '../../providers/Ws';
import Utils from '../../providers/Utils';
import Declarations from '../../providers/Declarations';

import { Link } from 'react-router-dom';

import ButtonForm from '../../commons/ButtonForm';
import { BrowserView, MobileView } from 'react-device-detect';

import CompanyAvatar from '../../assets/foxStat.jpeg';

export default class ServiciuIndisponibil extends React.Component{

    constructor(props){

        super(props)

        this.state = {

            nume: ''
        }
    }

    WS = new Ws();
    UT = new Utils();

    componentDidMount(){

        this.getServiceData()
    }

    getServiceData = async () => {

        let selectService = `select * from services where account_tk = ( select token from accounts where web_link = '${this.props.match.params.company_web_link}' ) 
                                                          and web_link = '${this.props.match.params.service_web_link}' `;

        let _service = this.WS.sqlCommand(`select`, selectService);

        let [service] = await Promise.all([_service])


        if(service.success){

            this.setState({

                nume: service.content[0].name,
            })

        }
        else{

            throw new Error("Server response error: " + service.message)
        }
    }


    render(){

        return(

            <React.Fragment>

            <BrowserView>
            <div className="client-center">

                <div className="client-wrapper">

                    <div className="client-card">

                        <div className="card-wrapper">

                            <div className="company-avatar-outer">

                                <div className="company-avatar-inner">
                                    <img src={CompanyAvatar} alt="Avatar companie" />
                                </div>

                            </div>

                            <h4 
                                style={{
                                    margin: 0,
                                    marginTop: -20,
                                    color: '#455A64',
                                    fontSize: '16px',
                                }}
                            >
                                Ooh... Serviciul este indisponibil
                            </h4>

                            <h1
                                style={{
                                    margin: 0,
                                    marginTop: 40,
                                    color: '#455A64',
                                    fontSize: '36px'
                                }}
                            >
                                {this.state.nume}
                            </h1>

                            <p 
                                style={{
                                    margin:0,
                                    marginTop: 40,
                                    fontSize: '16px'
                                }}
                            >
                                    Acest serviciu <u>nu mai este disponibil</u>. Apasa butonul de mai jos 
                                    pentru a fi condus la pagina cu toate serviciile active Foxstat SRL
                            </p>

                    
                        </div>

                        <Link to={"/" + this.props.match.params.company_web_link}>
                        <ButtonForm 
                            className="client-schedule-btn"
                            tx="Toate Serviciile"
                            style={{
                                marginTop: 40,
                                marginBottom: 40,
                                width: '20%'
                            }}
                            
                        />
                        </Link>

                    </div>

                </div> 

            </div>

            </BrowserView>

            <MobileView>

                <div className="m-client-header">

                    <div className="company-avatar-outer mobile">

                        <div className="company-avatar-inner">
                            <img src={CompanyAvatar} alt="Avatar companie" />
                        </div>

                    </div>

                    <h4
                        className="title"
                    >
                        Chameleon Coaching
                    </h4>

                </div>

                <div className="m-client-services-card">

                    <p
                        style={{
                            margin: 0,
                            fontSize: 16,
                            color: '#777777'
                        }}
                    >
                       Ooh, ...serviciul este indisponibil
                    </p>
                    
                    <br />
                    
                    <h3
                        style={{
                            margin: 0,
                            fontSize: 18
                        }}
                    >

                        Sedinta de Consiliere

                    </h3>
                    
                    <br />

                    <p
                        style={{
                            margin: 0,
                            fontSize: 16,
                            fontWeight: 400
                        }}
                        
                    >
                       
                       Acest serviciu <span style={{color: '#DD6A57'}}>nu mai este disponibil</span>. Apasa 
                       butonul de mai jos pentru a fi condus la 
                       pagina cu toate serviciile active Cameleon 
                       Coaching

                    </p>

                    <Link to={"/" + this.props.match.params.company_web_link}>
                        <ButtonForm 
                            className="client-schedule-btn"
                            tx="Toate Serviciile"
                            style={{
                                marginTop: 40,
                                width: '100%'
                            }}
                            
                        />
                    </Link>

                </div>

                <p
                    style={{
                        
                        paddingTop: 10,
                        paddingBottom: 20,
                        fontSize: 14,
                        color: '#455A64'
                    }}
                >
                    Programari prin  <a 
                                        href="#"
                                        style={{
                                            color: '#455A64'
                                        }}
                                    >
                                        Planary.ro
                                    </a>
                        
                </p>

            </MobileView>

            </React.Fragment>

        )
    }
}