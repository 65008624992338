import React from 'react';

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
  } from "react-device-detect";

export default class TestRes extends React.Component{

    constructor(props){

        super(props)
    }

    render(){

        return(

            <div>
                <h1>Test page for react device detect</h1>

                <BrowserView>
                    <h1> This is rendered only in browser </h1>
                </BrowserView>
                
                <MobileView>
                    <h1> This is rendered only on mobile </h1>
                </MobileView>
            </div>
        )
    }
}