import React from 'react';
import Masks from '../../providers/Masks';
import Utils from '../../providers/Utils';
import Ws from '../../providers/Ws';

import RegisterHeader from './RegisterHeader';

import Image from '../../assets/Followers-cuate 1.png';
import { IoIosArrowForward } from 'react-icons/io';
import ButtonForm from '../../commons/ButtonForm';
import { BrowserView, MobileView } from 'react-device-detect';
import ButtonsNavRegister from '../../commons/ButtonsNavRegister';

import CanaryPlantImage from '../../assets/canary_plant_bg.png';
import MBirdAndPlantImage from '../../assets/bird_and_plant_mobile.png';
import LocalStorage from '../../providers/LocalStorage';
import Loader from '../../commons/Loader';

export default class AccountDataCompleted extends React.Component{

    constructor(props){

        super(props);
        this.state = {
            
            programTk: '',
            programName: '',
            isLoading: true
        };
    }

    WS = new Ws();
    M = new Masks();
    UT = new Utils();

    componentDidMount(){

        this.onCheckIfHasService();
    }

    onCheckIfHasService = async () => {

        const TOKEN_CONT = new LocalStorage().GetAccountToken();

        let querySelectService = `select * from services where account_tk = '${TOKEN_CONT}' `;
        let service = await this.WS.sqlCommand(`select`, querySelectService);

        if(service.success){

            if(service.content !== null && service.content !== undefined){

                if(service.content.length > 0){

                    this.setState({

                        isLoading: false
                    },
                        () => {

                            window.location.href = '/#/inregistrare/serviciu/configurare-serviciu';
                        }
                    )
                }
                else{

                    await this.onGerScheduleData();
                }
                
            }
            
        }
    }

    onGerScheduleData = async () => {

        const TOKEN_CONT = new LocalStorage().GetAccountToken();

        let querySelectSchedule = `select * from programs where account_tk = '${TOKEN_CONT}' `
        let scheduleFromDb = await this.WS.sqlCommand(`select`, querySelectSchedule);

        if(scheduleFromDb.success){

            this.setState({

                programTk: scheduleFromDb.content[0]['token'],
                programName: scheduleFromDb.content[0]['name'],
            },
                () => {

                    this.setState({

                        isLoading: false
                    })

                    
                }
            );
        }
        else{

            this.setState({

                isLoading: false
            })
        }
    }

    onCreateNewService = async () => {

        const token = this.UT.generateToken(); //used only when creating a service
        const TOKEN_CONT = new LocalStorage().GetAccountToken();
        const emptyString = '';

        let dataStartCreation = new Date();
        dataStartCreation.setHours(0,0,0,0);

        let createServiceQuery = `insert into services(token, account_tk, 
            break_after, break_before, color, dispo_nr_zile, dispo_tip_zile,
            draft, duration, has_cancel_policy, has_recall, location, 
            price, program_tk, recall_nr_zile, recall_tip_zile, review_cere,
            schedule_descr, dispo_data_start, review_text, recall_text,
            cancel_policy_text

            ) 
            
            select '${token}', '${TOKEN_CONT}',
            15, 0, 'red', 30, 'work-days', 
            1, 60, 0, 0, 'office',
            0, '${this.state.programTk}', 20, 'calendar-days', 0,
            '${this.state.programName}', '${this.M.specificDateToDateField(dataStartCreation)}',
            '${emptyString}', '${emptyString}', '${emptyString}'

            `
        

        let createService = await this.WS.sqlCommand(`insert`, createServiceQuery);
        

        if(createService.success){

            this.props.history.push('/inregistrare/serviciu/configurare-serviciu');
        }
        else {


            throw new Error('Eroare Server Response:  ' + createService.message)
        }
    }

    render(){

        if(this.state.isLoading){

            return(

                <Loader 
                    fullPage = {true}
                />
            )
        }

        return(
            <>

            <BrowserView>

                <div className='register-container-desktop'>

                    <RegisterHeader isServiceStep = {true} />

                    <div className='service-details-card' style={{marginTop: '20px'}}>
                        

                        <div className='register-center-tx-wrapper success-acc'>

                            <p 
                                className='register-tx register-title'
                                style={{
                                    fontSize: '18px'
                                }}
                            >
                                Felicitari!
                            </p>

                            <p 
                                className='register-tx'
                                style={{
                                    marginTop: '20px'
                                }}
                            >
                                Ai acoperit informatiile necesare pentru functionalitatile de baza Planary. 
                                Gasesti toate aceste setari si chiar mai multe in meniul Utilizator din 
                                partea dreapta sus a ecranului.

                            </p>

                            <div className='register-center-tx-wrapper' style={{marginTop: '20px'}}>

                                <img
                                    className='register-image'
                                    src={Image}
                                    alt="Planary"
                                />
                            </div>

                            <p 
                                className='register-tx'
                                style={{
                                    marginTop: '20px'
                                }}
                            >
                                Hai sa trecem mai departe la crearea primului tau serviciu
                            </p>

                            <ButtonForm
                                type='button'
                                tx={
                                    <>
                                        <p style={{display: 'inline-block', position: 'relative', top: '-2px', margin: 0}}>
                                            Creaza Serviciu &nbsp;
                                        </p> 
                                        <IoIosArrowForward className='register-next-arrow'/>
                                    </>
                                }
                                
                                style={{
                                    marginTop: '20px',
                                    width: 'auto',
                                    height: '56px',
                                    background: '#F8BC05',
                                    fontSize: '16px',
                                    padding: '0px 35px 0px 35px'
                                }}
                                onClick={
                                    this.onCreateNewService
                                }
                            />

                            

                        </div>

                    </div>

                    <img 
                        className='bottom-pg-img'
                        src={CanaryPlantImage}
                        alt = 'Planary...'
                        style={{
                            marginTop: '20px'
                        }}
                    />

                </div>

            </BrowserView>

            <MobileView>

                <div 
                    style={{
                        padding: '20px',
                        minHeight: '850px',
                        background: `url(${MBirdAndPlantImage}) bottom no-repeat`
                    }}
                >
                    
                    <RegisterHeader isServiceStep = {true} />
                    
                    <div className="m-edit-s-card" style={{marginTop: '20px', textAlign: 'left'}}>

                        <p className='register-tx sm register-title bold'>
                            Felicitari!
                        </p>

                        <p 
                            className='register-tx sm'
                            style={{
                                marginTop: '16px'
                            }}
                        >
                            Ai acoperit informatiile necesare pentru functionalitatile de baza Planary.
                            <br />
                            <br />
                            Gasesti toate aceste setari si chiar mai multe in meniul Utilizator din partea dreapta sus a ecranului.
                            <br />
                            <br />
                            Putem sa trecem mai departe la crearea primului tau serviciu.

                        </p>

                        <img
                            className='register-image'
                            src={Image}
                            alt="Planary"
                        />

                        <ButtonsNavRegister 
                            tx = 'Continua'
                            onPressBtn = {this.onCreateNewService}
                            onBack = {this.props.history.goBack}
                        />

                    </div>
                    
                </div>

            </MobileView>

            </>
        )
    }
}