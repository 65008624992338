import React from 'react';
import Masks from '../../providers/Masks';
import Utils from '../../providers/Utils';
import Ws from '../../providers/Ws';

import RegisterHeader from './RegisterHeader';

import Image from '../../assets/Connected-cuate 1.png';
import ButtonForm from '../../commons/ButtonForm';
import { IoIosArrowForward } from 'react-icons/io';
import { BrowserView, MobileView } from 'react-device-detect';

import CanaryPlantImage from '../../assets/canary_plant_bg.png';
import MBirdAndPlantImage from '../../assets/bird_and_plant_mobile.png';

export default class AuthFinal extends React.Component{

    constructor(props){

        super(props);
        this.state = {
            
        };
    }

    WS = new Ws();
    M = new Masks();
    UT = new Utils();

    componentDidMount(){


    }

    render(){

        return(

            <>

            <BrowserView>

                <div className='register-container-desktop'>

                    <RegisterHeader isServiceStep = {true} />

                    <div className='service-details-card' style={{marginTop: '20px'}}>

                        <div className='register-center-tx-wrapper success-acc'>

                            <p 
                                className='register-tx register-title'
                                style={{
                                    fontSize: '18px'
                                }}
                            >
                                    Felicitari!
                            </p>

                            <p 
                                className='register-tx'
                                style={{
                                    marginTop: '20px'
                                }}
                            >
                                Ti-ai activat contul si ai creat primul tau serviciu! 
                                <br />
                                <br /> 
                                Urmatorii pasi sunt sa copiezi Linkul generat si sa il faci disponibil clientilor tai. 
                                Distribuie-l pe canalele tale de marketing, in semnatura de mail, in Social Media, sau prin QR Coduri pe materiale printate.
                            </p>

                            <br />

                            <div className='register-center-tx-wrapper'>

                                <img
                                    className='register-image'
                                    src={Image}
                                    alt="Planary"
                                />

                            </div>

                            <ButtonForm 
                                type='button'
                                tx={
                                    <>
                                        <p style={{display: 'inline-block', position: 'relative', top: '-2px', margin: 0}}>
                                            Mergi la Calendar &nbsp;
                                        </p> 
                                        <IoIosArrowForward className='register-next-arrow'/>
                                    </>
                                }
                                
                                style={{
                                    marginTop: '20px',
                                    width: 'auto',
                                    height: '56px',
                                    background: '#F8BC05',
                                    fontSize: '16px',
                                    padding: '0px 35px 0px 35px'
                                }}
                                onClick={
                                    () => {

                                        // this.onCheckAndSave(true)
                                    }
                                }
                            />

                        </div>

                    </div>

                    <img 
                        className='bottom-pg-img'
                        src={CanaryPlantImage}
                        alt = 'Planary...'
                        style={{
                            marginTop: '20px'
                        }}
                    />

                </div>

            </BrowserView>

            <MobileView>
                <div 
                    style={{
                        padding: '20px',
                        minHeight: '850px',
                        background: `url(${MBirdAndPlantImage}) bottom no-repeat`
                    }}
                >
                
                    <RegisterHeader isServiceStep = {true}/>
                    
                    <div className="m-edit-s-card" style={{marginTop: '20px', textAlign: 'left'}}>
                        
                        <p className='register-tx sm register-title bold'>
                            Felicitari!
                        </p>

                        <p className='register-tx sm register-title' style={{marginTop: '16px'}}>
                                Ti-ai activat contul si ai creat primul tau serviciu! 
                                <br />
                                <br /> 
                                Urmatorii pasi sunt sa copiezi Linkul generat si sa il faci disponibil clientilor tai. 
                                Distribuie-l pe canalele tale de marketing, in semnatura de mail, in Social Media, sau prin QR Coduri pe materiale printate.
                        </p>

                        <img
                            className='register-image'
                            src={Image}
                            alt="Planary"
                        />

                        <div 
                            style={{
                                textAlign: 'center'
                            }}
                        >
                            <ButtonForm
                                type='button'
                                tx={
                                    <>
                                        <p style={{display: 'inline-block', position: 'relative', top: '-2px', margin: 0}}>
                                            Mergi la Calendar &nbsp;
                                        </p> 
                                        <IoIosArrowForward className='register-next-arrow'/>
                                    </>
                                }
                                
                                style={{
                                    marginTop: '20px',
                                    width: '100%',
                                    height: '56px',
                                    background: '#F8BC05',
                                    fontSize: '16px',
                                    padding: '0px 35px 0px 35px'
                                }}
                                onClick={
                                    () => {

                                        // this.onCheckAndSave(true)
                                    }
                                }
                            />
                        </div>

                    </div>

                </div>

            </MobileView>

            </>
        )
    }
}