import React from 'react';
import LocalStorage from '../../providers/LocalStorage';

import Ws from '../../providers/Ws';

export default class SecretContact extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            userList: [],
            tip: '',
            value: ''
        }
    }

    WS = new Ws();

    componentDidMount(){

        this.getContacts()
    }

    getContacts = async () => {

        const ACC_TOKEN = await this.WS.getUserToken();
        

        let sq = `select * from user_contacts where token_user = ( select token from users where wrk_token = '${ACC_TOKEN}' )   `;
        

        let eq = await this.WS.sqlCommand(`select`, sq);
        

        if(eq.success){

            this.setState({

                userList: eq.content
            })
        }
        else{

            throw new Error("Error getting user list: " + eq.message);
        }

    }


    render(){
        
        return(

            <div>
                
                
                <table>
                    <tr>
                        <th>Id</th>
                        <th>Tip</th>
                        <th>Value</th>
                    </tr>
                    {
                        this.state.userList.map((uEl, idx) => {

                            return(

                                <tr key={'row-'+idx}>
                                    <td>{uEl.id}</td>
                                    <td>
                                        {uEl.tip}
                                    </td>

                                    <LinieContact 
                                        value={uEl.value}
                                        id={uEl.id}
                                    />

                                </tr>
                            )
                        })
                    }
                    
                </table>

            </div>
        );
    }
}

class LinieContact extends React.Component{

    constructor(props){

        super(props);
        
        this.state = {

            value: this.props.value,
            label: ''
        };
    }

    WS = new Ws();

    onChangeValue = (ev) => {

        this.setState({

            value: ev.target.value
        })
    }

    onSaveToDb = async () => {

        const ACC_TOKEN = await this.WS.getUserToken();

        let uq = `update user_contacts set value = '${this.state.value}' where id = ${this.props.id}   `;
        

        let updateValue = await this.WS.sqlCommand(`update`, uq);
        

        if(updateValue.success){

            this.setState({

                label: 'succes salvare'
            })
        }
        else{

            this.setState({

                label: 'err: ' + updateValue.message
            })
        }
        
        setTimeout( () => {
            
            this.setState({

                label: ''
            })
        }, 3000);
    }
    
    render(){

        return(
            
            <td>

                <input 
                    type="text" 
                    value={this.state.value}
                    onChange={this.onChangeValue}

                />
                
                <button 
                    type='button'
                    onClick={this.onSaveToDb}
                >
                        Save
                </button>

                <label>{this.state.label}</label>

            </td>
        )
    }
}