import React from 'react';
import { MobileView } from 'react-device-detect';
import { RiArrowUpSFill } from 'react-icons/ri';
import AppBar from '../AppBar';

import ZoomIcon from '../../assets/zoom_notifications_m.svg';
import BusinessCenterIcon from '../../assets/business_center_ gray_m.svg';
import IntegrationsIcon from '../../assets/integrations_notif_m.svg';
import NotificationsFuncs from './NotificationsFuncs';
import NotificationContainer from './NotificationContainer';
import Loader from '../Loader';

export default class MNotifications extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            notifications: [],
            errorTx: '',
            isLoading: true
        }
    }

    NF = new NotificationsFuncs();

    componentDidMount(){

        // this.getNotifications();
    }

    getNotifications = (notifications, errorTx, isLoading) => {

        setTimeout( () => {

            this.setState({

                notifications: notifications,
                errorTx: errorTx,
                isLoading: isLoading
            });
        }, 500);
    }

    render(){

        return(

            <MobileView>

            <AppBar
                isMenuShown = {false}
                isNotificationsShown = {true}
                isDashboardPage={true}
                getNotifications = {this.getNotifications}
            />

            <div
                style={{
                    padding: '0px 20px 0px 20px'
                }}
            >
                <div 
                    style={{
                        position: 'relative',
                        width: '100%',
                        background: '#fff',
                        borderRadius: '10px 0px 10px 10px',
                        boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)',
                        padding: '20px'
                    }}
                >
                    <RiArrowUpSFill
                        style={{
                            position: 'absolute',
                            top: -23,
                            right: 30,
                            fontSize: '40px',
                            color: '#fff',
                        }}
                    />

                    {
                        this.state.isLoading && (

                            <Loader 
                                containerH = '180px'
                            />
                        )
                    }

                    {
                        !this.state.isLoading && (

                            <div>

                                {
                                    this.state.errorTx.length === 0 &&
                                    this.state.notifications.length > 0 && 
                                    this.state.notifications.map( (nEl, idx) => {

                                        const notificationData = this.NF.getNotificationType(nEl['tip']);
                                        const time = this.NF.getNotificationTime(this.NF.convertSqlDateWithTimeToJSDate(nEl['cand']));

                                        return (

                                            <div key = {`notificare-${idx}`}>
                                                <NotificationContainer 
                                                    
                                                    type = {notificationData['type']}
                                                    iconType = {notificationData['iconType']}
                                                    title = {notificationData['title']}
                                                    message = {nEl['mesaj']}
                                                    time = {time}
                                                />

                                                {
                                                    this.state.notifications.length > 0 &&
                                                    this.state.notifications.length - 1 > idx && (

                                                        <div
                                                            style={{
                                                                marginTop: 6,
                                                                marginBottom: 10,
                                                                width: '100%',
                                                                height: '1px',
                                                                background: '#EBEBEB'
                                                            }}
                                                        >
                                                        </div> 
                                                    )
                                                }
                                                
                                            </div>
                                        )

                                    })
                                }

                                {   
                                    this.state.notifications &&
                                    this.state.errorTx.length === 0 &&
                                    this.state.notifications.length === 0 && (

                                        <p
                                            style={{
                                                margin: 0,
                                                textAlign: 'center',
                                                fontSize: '16px',
                                                fontWeight: 400,
                                                color: '#263238'
                                            }}
                                        >
                                            Nu ai nici o notifcare.
                                        </p>
                                    )
                                }

                                {
                                    this.state.errorTx.length > 0 && (

                                        <p
                                            style={{
                                                margin: 0,
                                                textAlign: 'center',
                                                fontSize: '16px',
                                                fontWeight: 400,
                                                color: '#DD6A57'
                                            }}
                                        >
                                            {this.state.errorTx}
                                        </p>
                                    )
                                }
                                

                            </div>
                        )
                    }

                </div>

            </div>

            </MobileView>
        )
    }
}