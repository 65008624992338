import React from 'react';
import './Commons.css';

import { IoNotifications } from 'react-icons/io5';
import { FaUser } from 'react-icons/fa';

import CompanyAvatar from '../assets/foxStat.jpeg';
import AvatarAccount from '../commons/AvatarAccount';

import Ws from '../providers/Ws';
import LocalStorage from '../providers/LocalStorage';
import CopiedLinkPopUp from './CopiedLinkPopUp';
import UserMenu from './UserMenu';
import Notifications from './notifications/Notifications';
import MUserMenu from './MUserMenu';
import { BrowserView, MobileView } from 'react-device-detect';
import { Link } from 'react-router-dom';
import Utils from '../providers/Utils';
import ErrorToast from './ErrorToast';
import NotificationsFuncs from './notifications/NotificationsFuncs';
import Masks from '../providers/Masks';

export default class AppBar extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            copiedLink: '',
            isCardShown: false,
            isMenuShown: false,
            isNotificationsShown: false,
            displayName: '...',
            avatarSrc: '',
            showToastPopupError: false,
            actionTx: '',
            notifications: [],
            isNewNotifications: false,
            errorTx: '',
            user: null
        }
    }

    WS = new Ws();
    M = new Masks();
    UT = new Utils();
    NF = new NotificationsFuncs();

    componentDidMount(){

        this.onSetAccData();
    }

    onSetAccData = async () => {

        const ACC_TOKEN = new LocalStorage().GetAccountToken();

        const accData = await this.UT.getAccountAvAndName(false, ACC_TOKEN);
        const verifiyAccData = this.UT.extractCompanyData(accData);

        const notifications = await this.NF.selectAccountNotifications();

        // const qUser = `select * from users where token = '${new LocalStorage().GetUserData().userData[0]['token']}' `;
        const qUsers = `select * from users where account_id = '${ACC_TOKEN}'`;
        let _users = this.WS.sqlCommand(`select`, qUsers);

        const qAccount = `select * from accounts where token = '${ACC_TOKEN}' `;
        let _account = this.WS.sqlCommand(`select`, qAccount);

        let [users, account] = await Promise.all([_users, _account]);


        if(notifications.err === null && verifiyAccData.err === null){

            let seenNotifications = [];
            let isNewNotification = false;

            let accountNotifications = [];

            if(notifications.data.length > 0){

                const localStorageSettings = JSON.parse(localStorage.getItem("userSettings"));
                let userSettings = {};

                if(localStorageSettings && localStorageSettings !== null){

                    userSettings = localStorageSettings;
                }
                
                let todayDate = new Date();
                todayDate.setHours(0, 0, 0, 0);
                const weekDateDiff = todayDate - (1000 * 60 * 60 * 24 * 7);

                seenNotifications = notifications.data.filter(nEl => {

                    if(( nEl['seen'] === null ) && ( this.NF.convertSqlDateWithTimeToJSDate(nEl['cand']) > weekDateDiff ) ){

                        return nEl;
                    }
                });
                accountNotifications = notifications.data;

                
                // 
                accountNotifications.sort( (prev, next) => {

                    if(this.NF.convertSqlDateWithTimeToJSDate(prev['cand']).getTime() < this.NF.convertSqlDateWithTimeToJSDate(next['cand']).getTime()){
    
                        return 1;
                    }
                    else if(this.NF.convertSqlDateWithTimeToJSDate(prev['cand']).getTime() > this.NF.convertSqlDateWithTimeToJSDate(next['cand']).getTime()){
    
                        return -1;
                    }

                });

                accountNotifications = accountNotifications.filter(anEl => {

                    if(this.NF.convertSqlDateWithTimeToJSDate(anEl['cand']) > weekDateDiff){

                        return anEl;
                    }
                });

                // 

                if(account.content[0].abonament === 'premium' && 
                    users.content[0].token !== new LocalStorage().GetUserData().userData[0].token
                ){

                    accountNotifications = accountNotifications.filter(notification => notification.token_user === new LocalStorage().GetUserData().userData[0].token);
                }

                if(Object.keys(userSettings).length > 0){

                    let newAppointNotifications = accountNotifications.filter(notification => {

                        // 
                        if(userSettings.newAppointmentNotifications === true && notification.tip.includes("programare_noua")){

                            return true;
                        }
                        else{

                            return false;
                        }

                        
                    })
                    
                    let rescheduleAppointNotifications = accountNotifications.filter(notification => {

                        if(userSettings.rescheduleNotifications === true && notification.tip.includes("reprogramare")){

                            return true;
                        }
                        else{

                            return false;
                        }
                    })
                    
                    let cancelAppointNotifications = accountNotifications.filter(notification => {

                        if(userSettings.cancelNotifications === true && notification.tip.includes("programare_anulata")){

                            return true;
                        }
                        else{

                            return false;
                        }
                    });

                    accountNotifications = [...newAppointNotifications, ...rescheduleAppointNotifications, ...cancelAppointNotifications];
                }

                if(seenNotifications.length > 0){

                    isNewNotification = true;
                }

            }

            // 

            const user = users.content.filter( user => user.token === new LocalStorage().GetUserData().userData[0]['token']);

            const isAnotherUser = user[0]['token'] !== users.content[0]['token'];

            const displayName = isAnotherUser ? user[0]['nume'] : accData['name'];

            this.setState({

                notifications: accountNotifications,
                isNewNotifications: isNewNotification,
                displayName: displayName || '...',
                avatarSrc: this.WS.serverAddress + 'staticprovider/getimg?filepath=Storage/Avatars/' + accData['avatar'],
                user: isAnotherUser ? user[0] : null
                
            },
            
                () => {

                    if(this.props.isNotificationsShown){

                        this.getNotifications();
                    }
                }
            );
        }
        else{

            this.setState({

                errorTx: notifications.err,
                showToastPopupError: true,
                actionTx: verifiyAccData.err
            });
        }

        // if(verifiyAccData.err !== null){

        //     this.setState({

        //         showToastPopupError: true,
        //         actionTx: verifiyAccData.err
        //     })
        // }
        // else{

        //     const avatarPath = this.WS.storageAddres + accData['avatar']

        //     this.setState({

        //         displayName: accData['name'] || '...',
        //         avatarSrc: this.WS.serverAddress + 'staticprovider/getimg?filepath=Storage/Avatars/' + accData['avatar']
        //     });
        // }  
    }

    onCopyLinkToClipBoard = async () => {
        
        const TOKEN_CONT = new LocalStorage().GetAccountToken();

        let selectWebLinkQuery = `select web_link from accounts where token = '${TOKEN_CONT}'  `;
        // 

        let webLink = await this.WS.sqlCommand(`select`, selectWebLinkQuery);
        // 

        if(webLink.success){

            // const link = "https://app.planary.ro/#/"+webLink.content[0]['web_link'];
            const accLink = this.state.user !== null ? `${webLink.content[0]['web_link']}/${this.state.user['web_link']}` : webLink.content[0]['web_link'];
            const link = "https://planary.ro/preview/"+accLink
            navigator.clipboard.writeText(link);
            this.setState({
                copiedLink: link,
                isCardShown: true
            })
        }
        else{

            throw new Error("Erorr getting web_link from server: " + webLink.message);
        }
        
    }

    onClosePopup = (close) => {

        this.setState({

            isCardShown: close
        })
    }

    onShowMenu = () => {

        this.setState({

            isMenuShown: !this.state.isMenuShown,
            isNotificationsShown: false
        })
    }

    onShowNotifications = async () => {

        this.setState({
            isNotificationsShown: !this.state.isNotificationsShown,
            isMenuShown: false,
        },
        
            () => {

                this.markNotificationsAsSeen()
            }
        )
    }

    onCloseMenu = () => {

        this.setState({

            isMenuShown: false
        })
    }

    onCloseNotifications = () => {

        this.setState({

            isNotificationsShown: false
        })
    }

    onShowProfile = () => {

        this.props.onShowProfile();
    }

    onShowEditSchedule = () => {

        this.props.onShowEditSchedule();
    }

    onShowIntegrari = () => {

        this.props.onShowIntegrari();
    }

    onShowEchipa = () => {

        this.props.onShowEchipa();
    }

    onShowAbonamente = () => {

        this.props.onShowAbonamente();
    }

    onShowSecuritate = () => {

        this.props.onShowSecuritate();
    }

    onShowIstoricPlati = () => {

        this.props.onShowIstoricPlati();
    }

    onShowIstoricProgramari = () => {

        this.props.onShowIstoricProgramari();
    }

    onGoToDashboard = () => {

        window.location.href = "/#/ca/dashboard/cab";
    }

    getAvatar = () => {

        let x = this.WS.serverAddress + 'staticprovider/getimg?filepath=Storage/Avatars/armnuc8ss91povyp1p2yyux67xog2ucg.jpeg';

        // 

        return x;
    }

    markNotificationsAsSeen = async () => {

        let isNotificationsSeen = false;

        const ACC_TOKEN = new LocalStorage().GetAccountToken();
        
        const qUsers = `select * from users where account_id = '${ACC_TOKEN}' `;
        let _users = this.WS.sqlCommand(`select`, qUsers);

        const qAccount = `select * from accounts where token = '${ACC_TOKEN}' `;
        let _account = this.WS.sqlCommand(`select`, qAccount);

        let [users, account] = await Promise.all([_users, _account]);

        const abonament = account.content[0]['abonament'];
        const userToken = users.content[0].token !== new LocalStorage().GetUserData().userData[0].token ?
                            new LocalStorage().GetUserData().userData[0].token : null

        if(this.state.isNewNotifications){

            const setNotificationsAsViewed = await this.NF.markNotificationsAsSeen(abonament, userToken);

            if(setNotificationsAsViewed.err === null){

                isNotificationsSeen = false;
            }
            else{

                isNotificationsSeen = true;
            }
        }

        this.setState({

            isNewNotifications: isNotificationsSeen
        });
    }

    getNotifications = () => {

        this.props.getNotifications(this.state.notifications, this.state.errorTx, false);
    }

    render(){

        return(

            <header className="appbar-container">

                {/* {
                    this.state.isMenuShown &&
                    <MUserMenu />
                } */}

                {/* <MUserMenu /> */}

                {/* {
                    this.state.isNotificationsShown &&
                    <Notifications />    
                } */}

                {
                    this.state.isNotificationsShown &&
                    <div
                        className="cancel-bg"
                        onClick={this.onCloseNotifications}
                    >

                    </div>
                }
                
                {
                    this.state.isMenuShown &&
                    <div
                        className="cancel-bg"
                        onClick={this.onCloseMenu}
                    >

                    </div>
                }

                {
                    this.state.isCardShown &&
                    <CopiedLinkPopUp 
                        link = {this.state.copiedLink}
                        onClosePopup = {this.onClosePopup}
                    />
                }

                {
                    this.state.showToastPopupError && (

                        <ErrorToast 
                            action = {this.state.actionTx}
                            onClosePopup = {this.onCloseErrorToast}
                        />
                    )
                }

                <div className="wrapper-dashboard">

                    <BrowserView>
                    
                        <br />
                        <br />
                    
                    </BrowserView>

                    <MobileView>

                        <div style={{height: 10}}></div>

                    </MobileView>

                    <div className="header-dashboard">

                        <div className="left">

                            {/* <div className="header-av">

                                <img 
                                    src={this.state.avatarSrc}
                                    alt="Avatar companie"
                                    style={{
                                        width: '50px',
                                        height: '50px',
                                        borderRadius: '50%'
                                    }}
                                />

                            </div> */}

                            <div className="header-av">

                                <AvatarAccount 
                                    isNotLogged = {false}
                                    accToken = {new LocalStorage().GetAccountToken()}
                                    margin = '0px'
                                    width = '50px'
                                    height = '50px'
                                />

                            </div>

                            <div className="header-acc">

                                <span
                                    className="header-name"
                                    style={{
                                        margin: 0,
                                        fontSize: '24px',
                                        fontWeight: '500',
                                        color: '#000'
                                    }}
                                >
                                    {this.state.displayName}
                                </span>

                                {
                                    !this.props.isDashboardPage && (

                                        <span
                                            className="header-copy"
                                            style={{
                                                margin: 0,
                                                fontSize: '16px',
                                                fontWeight: '400',
                                                color: '#44A5FF',
                                                cursor: 'pointer'
                                            }}
                                            onClick={this.onCopyLinkToClipBoard}
                                        >
                                            Copiaza Link
                                        </span>
                                    )
                                }

                                {
                                    this.props.isDashboardPage && (

                                        <span
                                            className="header-copy"
                                            style={{
                                                margin: 0,
                                                fontSize: '16px',
                                                fontWeight: '400',
                                                color: '#777777'
                                            }}
                                            onClick={this.onGoToDashboard}
                                        >
                                            Dasboard
                                        </span>
                                    )
                                }

                            </div>

                        </div>

                        <div className="right">

                            <BrowserView>
                                
                                <div className="header-icons">

                                    <p className="icon" >
                                        {
                                            this.state.isNewNotifications && (

                                                <span 
                                                    style={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        right: 0,
                                                        width: '5px',
                                                        height: '5px',
                                                        borderRadius: '50%',
                                                        background: '#EE6B34'
                                                    }}
                                                >
                                                </span>  
                                            )
                                        }

                                        <IoNotifications 
                                            onClick={this.onShowNotifications}
                                            style={{
                                                color: this.state.isNotificationsShown ? '#44A5FF' : 'rgb(69, 90, 100)'
                                            }}
                                        />
                                    </p>

                                    


                                    <p className="icon" style={{position: 'relative'}}>
                                        
                                        <FaUser 
                                            onClick={this.onShowMenu}
                                            style={{
                                                color: this.state.isMenuShown ? '#44A5FF' : 'rgb(69, 90, 100)'
                                            }}
                                        />
                                    </p>

                                </div>

                            </BrowserView>

                            <MobileView>
                            <div className="header-icons">
                            
                                <p className="icon" >

                                    {
                                        this.state.isNewNotifications && (

                                            <span 
                                                style={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    right: 0,
                                                    width: '5px',
                                                    height: '5px',
                                                    borderRadius: '50%',
                                                    background: '#EE6B34'
                                                }}
                                            >
                                            </span> 
                                        )
                                    }

                                    <Link
                                        to = {this.props.isNotificationsShown ? '/ca/dashboard/cab' : '/ca/dashboard/cab/notificari'}
                                        style={{
                                            margin: 0,
                                            padding: 0
                                        }}
                                    >
                                    <IoNotifications
                                        onClick={this.markNotificationsAsSeen} 
                                        style={{
                                            color: this.props.isNotificationsShown ? '#44A5FF' : 'rgb(69, 90, 100)'
                                        }}
                                    />
                                    </Link>
                                </p>
                                
                                
                                <p className="icon" style={{position: 'relative'}}>
                                    
                                    <Link 
                                        to = {this.props.isMenuShown ? '/ca/dashboard/cab' : '/ca/dashboard/cab/meniu'}
                                        style={{
                                            margin: 0,
                                            padding: 0
                                        }}
                                    >
                                    <FaUser     
                                        style={{
                                            color: this.props.isMenuShown ? '#44A5FF' : 'rgb(69, 90, 100)'
                                        }}
                                    />
                                    </Link>
                                </p>

                            </div>
                            

                            </MobileView>

                            
                            {
                                this.state.isMenuShown &&
                                <UserMenu 
                                    onShowProfile={this.onShowProfile}
                                    onShowEditSchedule = {this.onShowEditSchedule}
                                    onShowIntegrari = {this.onShowIntegrari}
                                    onShowEchipa = {this.onShowEchipa}
                                    onShowAbonamente = {this.onShowAbonamente}
                                    onShowSecuritate = {this.onShowSecuritate}
                                    onShowIstoricPlati = {this.onShowIstoricPlati}
                                    onShowIstoricProgramari = {this.onShowIstoricProgramari}
                                    onShowRapoarte = {this.props.onShowRapoarte}
                                />
                            }

                            {
                                this.state.isNotificationsShown &&
                                <Notifications 
                                    notifications = {this.state.notifications}
                                    errorTx = {this.state.errorTx}
                                />    
                            }

                        </div>

                    </div>
                    
                    {/* <div className="row">

                        <div 
                            className="col-l-8 col-m-8 col-s-9"
                            // style={{
                            //     display: 'inline-flex',
                            //     alignItems: 'center'
                            // }} 
                        >
                            <div className="row">

                                <div className="col-l-06 col-m-07 col-s-barav">

                                    <div className="appbar-avatar">

                                        <img 
                                            src={CompanyAvatar}
                                            alt="Avatar companie"
                                            style={{
                                                width: '100%',
                                                height: '100%'
                                            }}
                                        />

                                    </div>

                                </div>

                                <div className="col-l-94 col-m-93 col-s-bartx">

                                    <div className="appbar-text">

                                        <h2 
                                            style={{
                                                textAlign: 'left', 
                                                margin: 0
                                            }}>
                                                FoxStat
                                            </h2>

                                        {
                                            !this.props.isDashboardPage && (

                                                <p 
                                                    onClick={this.onCopyLinkToClipBoard}
                                                    style={{
                                                        margin: 0,
                                                        textAlign: 'left'
                                                    }}
                                                >
                                                    Copiaza Link
                                                </p>
                                            )
                                        }

                                        {
                                            this.props.isDashboardPage && (

                                                <p 
                                                    onClick={this.onGoToDashboard}
                                                    style={{
                                                        margin: 0,
                                                        textAlign: 'left'
                                                    }}
                                                >
                                                    Dashboard
                                                </p>
                                            )

                                        }

                                    </div>

                                </div>

                            </div>
   
                            
                        </div>


                        <div 
                            className="col-l-4 col-m-4 col-s-3" 
                            style={{
            
                                textAlign: 'right'
                                
                            }}
                        >
                            
                            <div className="appbar-icons" style={{position:'relative'}}>

                                  
                                <BrowserView>
                                
                                <p className="icon" >
                                    <span 
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            right: 0,
                                            width: '5px',
                                            height: '5px',
                                            borderRadius: '50%',
                                            background: '#EE6B34'
                                        }}
                                    >
                                    </span>  

                                    <IoNotifications 
                                        onClick={this.onShowNotifications}
                                        style={{
                                            color: this.state.isNotificationsShown ? '#44A5FF' : 'rgb(69, 90, 100)'
                                        }}
                                    />
                                </p>

                                


                                <p className="icon" style={{position: 'relative'}}>
                                    
                                    <FaUser 
                                        onClick={this.onShowMenu}
                                        style={{
                                            color: this.state.isMenuShown ? '#44A5FF' : 'rgb(69, 90, 100)'
                                        }}
                                    />
                                </p>
                                
                                </BrowserView>

                                <MobileView>

                                
                                <p className="icon" >
                                    <span 
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            right: 0,
                                            width: '5px',
                                            height: '5px',
                                            borderRadius: '50%',
                                            background: '#EE6B34'
                                        }}
                                    >
                                    </span>  
                                    <Link
                                        to = {this.props.isNotificationsShown ? '/ca' : '/ca/notificari'}
                                        style={{
                                            margin: 0,
                                            padding: 0
                                        }}
                                    >
                                    <IoNotifications 
                                        style={{
                                            color: this.props.isNotificationsShown ? '#44A5FF' : 'rgb(69, 90, 100)'
                                        }}
                                    />
                                    </Link>
                                </p>
                                
                                

                                
                                <p className="icon" style={{position: 'relative'}}>
                                    
                                    <Link 
                                        to = {this.props.isMenuShown ? '/ca' : '/ca/meniu'}
                                        style={{
                                            margin: 0,
                                            padding: 0
                                        }}
                                    >
                                    <FaUser     
                                        style={{
                                            color: this.props.isMenuShown ? '#44A5FF' : 'rgb(69, 90, 100)'
                                        }}
                                    />
                                    </Link>
                                </p>
                                

                                </MobileView>

                              
                                {
                                    this.state.isMenuShown &&
                                    <UserMenu 
                                        onShowProfile={this.onShowProfile}
                                    />
                                }

                                {
                                    this.state.isNotificationsShown &&
                                    <Notifications />    
                                }

                            </div>

                        </div>


                    </div> */}

                </div>
                <br />

            </header>
        );
    }
}