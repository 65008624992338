import React from "react";
import { IoIosArrowBack } from "react-icons/io";
import { IoCloseCircle } from "react-icons/io5";
import { MdSettingsInputComponent } from "react-icons/md";
import { ReactComponent as ZoomIcon } from '../../assets/zoom_icon.svg';
import IconText from "../../commons/IconText";
import IntegrationsCard from "../../commons/IntegrationsCard";
import LocalStorage from "../../providers/LocalStorage";
import Masks from "../../providers/Masks";
import Utils from "../../providers/Utils";
import Ws from "../../providers/Ws";

export default class ZoomIntegrationDev extends React.Component {

    constructor(props){

        super(props);

        this.state = {

            zoom: {},
            title: 'Integrare cu Zoom',
            isDev: false
        };
    }

    WS = new Ws();
    UT = new Utils();
    M = new Masks();

    componentDidMount(){

        this.getIntegrationsData();
    }

    getIntegrationsData = async () => {

        const ACC_TOKEN = new LocalStorage().GetAccountToken();
        let zoomLink = window.location.href

        

        let selectUsersContactsQuery = `select * from user_contacts where token_user = ( select token from users where account_id = '${ACC_TOKEN}' LIMIT 1 ) `;
        let usersContacts = await this.WS.sqlCommand(`select`, selectUsersContactsQuery);

        if(usersContacts.success){

            

            let zoomCard = {   
                // icon: ZoomIcon,
                tip: 'zoom',
                // isActive: usersContacts.content.findIndex(el => el['tip'] === 'zoom') > -1,
                isActive: usersContacts.content.findIndex(el => el['tip'] === 'zoom' && (el['zoom_access_token'] || '').length > 0 ) > -1,
                // isActive: true,
                name: 'Zoom',
                shortTx: 'Locatie - Apel video',
                clsName: 'zoom',
                link: 'https://zoom.us/oauth/authorize?client_id=cO4wbO9vSewzUEaKQxEvg&response_type=code&redirect_uri=https%3A%2F%2Fapp.planary.ro%2F%23%2Fca%2Fdashboard%2Fcab%2Fautentificare-zoom/'
            };

            this.setState({

                zoom: zoomCard,
                title: zoomLink.includes("dev") ? 'Development Zoom Integration' : 'Integrare cu Zoom',
                isDev: zoomLink.includes("dev")
            });
        }
    }

    onDisableZoomIntegration = async () => {

        const ACC_TOKEN = new LocalStorage().GetAccountToken();
        let disableZoomIntegrationQuery = `update user_contacts set zoom_access_token = null, zoom_refresh_token = null where 
                    token_user = ( select token from users where account_id = '${ACC_TOKEN}' LIMIT 1 ) and tip = 'zoom' `;

        let disableZoomIntegrationRes = await this.WS.sqlCommand(`update`, disableZoomIntegrationQuery);

        if(disableZoomIntegrationRes.success){

            await this.getIntegrationsData();
        }
    }

    render(){

        return(

            <div 
                className="wrapper-dashboard integrari" 
                style={{

                    margin:'30px auto',
                    minHeight: '800px',
                    // background: `url(${LaptopAndMugImg}) bottom no-repeat`
                }}
            >
                <a 
                    onClick={() => window.location.href = "/#/"}
                    className="selected"
                    style={{
                        display: 'block',
                        cursor: 'pointer',
                        textAlign: 'left'
                    }}
                >
    
                    <p 
                        style={
                            {
                                display: 'inline-block', 
                                position: 'relative', 
                                top: 6, 
                                right: 10,
                                margin: 0,
                                fontSize: '22px',
                        
                            }
                        }
                    >
                        <IoIosArrowBack />
                    </p>

                    <p 
                        style={
                            {
                                display: 'inline-block', 
                                position: 'relative', 
                                left: 0,
                                margin: 0,
                                fontWeight: '500'
                            }
                        }
                    >
                        Inapoi
                    </p>
                    
                </a>

                <div 
                    className="service-details-card"
                    style={{
                        marginTop: 30
                    }}
                >
                    <IconText 
                        icon = {
                            <MdSettingsInputComponent 
                                style={{
                                    color: 'rgb(255, 199, 39)',
                                    fontSize: '20px'
                                }}
                            />
                        }
                        tx = {this.state.title}
                        txSize = '18px'
                        txWeight = '500'
                        txCol = '#74838B'
                    />

                    {
                        this.state.isDev ? (

                            <p>Development page for testing Zoom Integration.</p>

                        ) : (

                            <div
                                style={{
                                    textAlign: 'left'
                                }}
                            >
                                <p>Benefiile integrarii cu Zoom:</p>

                                <ul>
                                    <li>Gestionati apelurile video mai usor si complet automatizat</li>

                                    <li style={{marginTop: 10}}>Stabiliti primiti detaliile meeting-ului instant dupa programarea clientului</li>

                                    <li style={{marginTop: 10}}>Programarile cu Zoom sunt mult mai usoare datorita automatizarii</li>
                                </ul>

                                <p>Cerinte pentru integrarea cu Zoom:</p>

                                <ul>
                                    <li>Un cont de Zoom functional.</li>

                                    <li style={{marginTop: 10}}>Trebuie sa acordati acces aplicatiei Zoom.</li>
                                </ul>

                            </div>
                        )
                    }

                    <div 
                        className="integration-row"
                        style={{
                            marginTop: 12
                        }}
                    >
                        
                        <IntegrationsCard 
                            icon = {
                                <ZoomIcon
                                    className={"integrations-icon " + this.state.zoom.clsName}
                                />
                            }
                            middleTx = {this.state.zoom.name}
                            downTx = {this.state.zoom.shortTx}
                            isSynced = {this.state.zoom.isActive}
                            onClick = {

                                () => {

                                    if(!this.state.zoom.isActive){

                                        if( (this.state.zoom['link'] || '').length > 0 ){

                                            // window.open( aiEl['link'] );
                                            window.location.href = this.state.zoom['link'];
                                        }
                                        else if(this.state.zoom['func'] !== null && this.state.zoom['func'] !== undefined){

                                            this.state.zoom.func();
                                        }
                                    }
                                }
                            }
                        />  

                        {
                            this.state.zoom.isActive ? (

                                <p
                                    className='cancel-integration-icon'
                                    onClick={this.onDisableZoomIntegration}
                                >
                                    Disconnect
                                </p>
                            ) : <div></div>
                        }
                    
                    </div>

                </div>

            </div>
        )
    }
}