import React from 'react';

import {
    BrowserView,
    MobileView,

} from "react-device-detect";

import { IoMdArrowRoundBack } from 'react-icons/io';
import { Link } from 'react-router-dom';
import history from "../skeleton/history";

export default class MServiceNav extends React.Component{

    constructor(props){

        super(props)
    }

    render(){

        return(
            <MobileView>

                <div className="m-serv-nav-container">

        
{/*                        
                    <Link to={this.props.path}>
                        <IoMdArrowRoundBack 
                            className="back-btn"
                        />
                    </Link> */}

                    <IoMdArrowRoundBack 
                        className="back-btn"
                        onClick={history.goBack}
                    />
        

                    <h6
                        style={this.props.titleStyle}
                    >
                        <p 
                            style={
                                {
                                    display: 'inline-block', 
                                    position: 'relative', 
                                    top: this.props.iconTop || 5, 
                                    left: 0, 
                                    margin: 0,
                                    fontSize: this.props.title.length < 18 ? '20px' : '18px',
                                    color: '#FFC727'
                            
                                }
                            }
                        >
                            {this.props.icon}
                        </p>

                        <p 
                            style={
                                {
                                    display: 'inline-block', 
                                    position: 'relative', 
                                    top: this.props.titleTop || '0',
                                    left: 10,
                                    margin: 0,
                                    fontSize: this.props.fontSize || '14px',
                                    fontWeight: '500',
                                    color: 'rgb(69, 90, 100)'
                                }
                            }
                        >
                            {this.props.title}
                        </p>
                        
                    </h6>

                </div>

            </MobileView>
        )
    }
}