import React from 'react';
import DashboardNav from '../../commons/DashboardNav';
import LocalStorage from '../../providers/LocalStorage';
import Ws from '../../providers/Ws';
import ReactGA from 'react-ga';

ReactGA.initialize('G-X2B3H689RR')
export default class Svs extends React.Component{

    constructor(props){

        super(props);

        this.state = {

            listaServicii: [],
            cui: '',
            oras: '',
            telefon: ''
        }
    }

    WS = new Ws();

    componentDidMount(){

        
        this.getAccountData();
        ReactGA.pageview(window.location.pathname + window.location.search);

        ReactGA.event({
            category: 'User',
            action: 'Sent message'
        });
    }



    getAccountData = async () => {

        const TOKEN_CONT = new LocalStorage().GetAccountToken();

        let q = `select * from accounts where token = '${TOKEN_CONT}'  `;
        

        let accountData = await this.WS.sqlCommand(`select`, q);
        

    }

    onUpdateAccountData = async () => {

        const TOKEN_CONT = new LocalStorage().GetAccountToken();

        let uq = `update accounts set cui = '${this.state.cui}', oras = '${this.state.oras}', telefon = '${this.state.telefon}' where token = '${TOKEN_CONT}' `; 
        let updateData = await this.WS.sqlCommand(`update`, uq);

        if(updateData.success){

            // alert("Succes update!")
        }
        else{

            // alert("Fail update!")
        }
    }

    onModifyInput = (ev) => {

        this.setState({

            [ev.target.name] : ev.target.value
        })

        // 
        
    }

    render(){
        
        return(

            <div>
                <input
                    type='text'
                    value={this.state.cui}
                    onChange={this.onModifyInput}
                    placeholder = 'C.U.I'
                    name = 'cui'
                />

                <input
                    type='text'
                    value={this.state.oras}
                    onChange={this.onModifyInput}
                    placeholder = 'Oras'
                    name = 'oras'
                />
                
                <input
                    type='text'
                    value={this.state.telefon}
                    onChange={this.onModifyInput}
                    placeholder = 'Telefon'
                    name = 'telefon'
                />

                <button 
                    type="button"
                    onClick={this.onUpdateAccountData}
                >
                    Test
                </button>

            </div>
        );
    }
}